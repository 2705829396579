import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from 'components/Header';
import { Content } from './Multiplayer.styles';
import useGlobalFeature from 'hooks/useGlobalFeature';
import useQuery from 'helpers/useQuery';
import { withSubjectCheck } from 'helpers/hoc';
import {
  MultiplayerChallengeList,
  NewChallenge,
  IncomingChallenges,
  ChallengeHistory,
} from '..';
const Multiplayer = () => {
  const { isEnabled } = useGlobalFeature('pc');
  const query = useQuery();
  return (
    <div>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={[
                '/multiplayer',
                '/multiplayer/peer-challenge/new-challenge',
                '/multiplayer/peer-challenge/incoming',
                '/multiplayer/peer-challenge/history',
              ]}
              to="/maintenance-redirect"
            />
          )}
          <Route
            exact
            path="/multiplayer"
            component={MultiplayerChallengeList}
          />
          <Route
            path="/multiplayer/peer-challenge/new-challenge"
            component={() => {
              const Result = () => {
                const redirect = query.get('redirect');
                if (redirect === '') {
                  const CheckedComponent = withSubjectCheck(NewChallenge);
                  return <CheckedComponent />;
                }
                return <NewChallenge />;
              };
              return <Result />;
            }}
          />
          <Route
            exact
            path="/multiplayer/peer-challenge/incoming"
            component={IncomingChallenges}
          />
          <Route
            exact
            path="/multiplayer/peer-challenge/history"
            component={ChallengeHistory}
          />
        </Switch>
      </Content>
    </div>
  );
};

export default Multiplayer;
