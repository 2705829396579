import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Mascot from 'assets/img/events/event-result-mascot.svg';
import CPIcon from 'assets/img/icon-challenge-point.svg';

import { CongratsText } from 'components/Assignment';
import { Spinner, Button } from 'components/Shared';

import {
  Container,
  ClaimCPContainer,
  PlusCPContainer,
  MascotContainer,
  CloseBtn,
  Wrapper,
  ErrorText,
} from './ClaimedCPModal.styles';

const ClaimCPs = ({ CPBalance, isLoading }) => {
  const { t } = useTranslation(['sundayMCResult']);

  return (
    <ClaimCPContainer>
      <div className="claim">
        <p>{t('sundayMCResult:claimedModal.earnCP', 'You have earned')}</p>
        <img src={CPIcon} alt="CP" />
        <p className="amount">
          {isLoading ? (
            <Spinner small />
          ) : (
            <Trans i18nKey="sundayMCResult:claimedModal.cp" t={t}>
              <span>{{ number: CPBalance }} </span>
              CPs
            </Trans>
          )}
        </p>
        <p>{t('sundayMCResult:claimedModal.today', 'today')}</p>
      </div>
    </ClaimCPContainer>
  );
};

const PlusCPs = ({ number }) => {
  return (
    <PlusCPContainer>
      <p className="fade-in">+{number}</p>
    </PlusCPContainer>
  );
};

const ClaimedCPModal = ({ setShowClaimed }) => {
  const history = useHistory();
  const { claimCPResult, isClaiming, claimCPError } = useSelector(
    (state) => state.event
  );
  const { t } = useTranslation('sundayMCResult');
  return (
    <Wrapper>
      <MascotContainer className="mascot">
        <img src={Mascot} alt="mascot" />
      </MascotContainer>
      <Container>
        {isClaiming && <Spinner />}
        {!isClaiming && claimCPError === null && claimCPResult && (
          <>
            <div className="congrats-text">
              <CongratsText />
              <CloseBtn
                onClick={() => {
                  setShowClaimed(false);
                }}
              >
                X
              </CloseBtn>
            </div>
            <p className="success-text">
              {t('claimedModal.claimedSuccess', 'CPs claimed successfully!')}
            </p>
            <PlusCPs number={claimCPResult.ClaimedCPPoints} />
            <ClaimCPs
              CPBalance={claimCPResult.TodaysCP + claimCPResult.ClaimedCPPoints}
              isLoading={isClaiming}
            />
            <Button
              fontSize="22px"
              variant="primary"
              textShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
              width="270px"
              onClick={() => {
                setShowClaimed(false);
                history.push('/leaderboard');
              }}
            >
              {t('claimedModal.leaderboard', 'Go to leaderboard')}
            </Button>
          </>
        )}
        {!isClaiming && claimCPError && (
          <ErrorText>
            <h3>Error</h3>
            <h4>{claimCPError}</h4>
          </ErrorText>
        )}
      </Container>
    </Wrapper>
  );
};

export default ClaimedCPModal;
