import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import useGlobalFeature from 'hooks/useGlobalFeature';
import Header from 'components/Header';
import {
  AssignmentIntermediate,
  ParentAssign,
  AssignmentSolution,
  StartAssignment,
  AssignmentList,
} from '..';
import { Page, Content } from './Assignment.styles';

const Assignment = () => {
  const { featuresHashmap } = useGlobalFeature(null, [
    'assignment',
    'parentassignment',
  ]);
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!featuresHashmap.assignment.isEnabled && (
            <Redirect
              exact
              from={[
                '/assignment/assignment-list',
                '/assignment/start/:id',
                '/assignment/solution/:assignmentID/:assignmentSubmissionID',
              ]}
              to="/maintenance-redirect"
            />
          )}
          {!featuresHashmap.parentassignment.isEnabled && (
            <Redirect
              exact
              from="/assignment/parent-assign"
              to="/maintenance-redirect"
            />
          )}
          <Route exact path="/assignment" component={AssignmentIntermediate} />
          <Route
            exact
            path="/assignment/assignment-list"
            component={AssignmentList}
          />
          <Route
            exact
            path="/assignment/parent-assign"
            component={ParentAssign}
          />
          <Route
            exact
            path="/assignment/start/:id"
            component={StartAssignment}
          />
          <Route
            exact
            path="/assignment/solution/:assignmentID/:assignmentSubmissionID"
            component={AssignmentSolution}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default Assignment;
