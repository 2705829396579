import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'ramda';
import {
  getUserCurriculums,
  getUserReport,
  resetCurriculums,
  createAssignment,
  resetParentError,
} from 'store/assignment/assignmentParentSlice';

import { featureToggle } from 'constants/index';
import ageGroups from 'constants/ageGroups';
import { NewHOTsCurriculumID } from 'constants/mission';
import { CANCEL_ERROR } from './constants';
import { SUBJECTS } from 'constants/products';
import useDefaultAgeGroup from 'hooks/useDefaultAgeGroup';
import useIsMobile from 'hooks/responsive/useIsMobile';
import useIsTablet from 'hooks/responsive/useIsTablet';

import formatChineseNumber from 'helpers/formatChineseNumber';
import useQuery from 'helpers/useQuery';
import { withPlanCheck } from 'helpers/hoc';

import IconSuccessModal from './imgs/icon-success-modal.svg';

import {
  DropdownMenu,
  BackButton,
  Spinner,
  Button,
  ModalContentXButton,
  ErrorModal,
  ModalContentAlert,
} from 'components/Shared';
import TopicList from 'components/Mission/TopicList';
import MobileSidebar from 'components/Sidebar/MobileSidebar';
import {
  AssignModal,
  ModalContentIcon,
  ResultModal,
  SkillRow,
  SkillPanel,
  ProficiencyLegend,
  FilterModal,
} from './components';

import {
  Container,
  TopBar,
  SyllabusDropdown,
  QuestionDetails,
  RightSide,
  Main,
  MainContent,
  Sidebar,
  Content,
  StyledSpinner,
  BottomMenu,
  FilterWrapper,
} from './ParentAssign.styles';

const ParentAssign = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t, i18n } = useTranslation([
    'leaderboard',
    'parentAssign',
    'selfPractice',
    'common',
  ]);
  const currentLanguage = i18n.language;
  const isEnglish = currentLanguage.toLowerCase().includes('en');
  const history = useHistory();
  const dispatch = useDispatch();

  const { groupId, studentDetails } = useSelector(
    (state) => state.studentDetails
  );
  const {
    curriculums,
    curriculumsLoading,
    curriculumsError,
    report,
    isLoading,
    isCreating,
    createdAssignment,
    isCreateError,
    userReportError,
    createdAssignmentParams,
  } = useSelector((state) => state.assignmentParent);
  const { isLoading: isProductsLoading, products } = useSelector(
    (state) => state.plan
  );
  const { ageGroupIndex } = useDefaultAgeGroup();
  const query = useQuery();
  const curriculumId = Number(query.get('curriculumId'));
  const topicId = query.get('topicId');
  const levelId = query.get('levelId');

  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [preSelected, setPreSelected] = useState(null);
  const [dropDownType, setDropDownType] = useState('');

  const [topics, setTopics] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const [showSkillDetails, setShowSkillDetails] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [paramsError, setParamsError] = useState(null);
  const userId = studentDetails && studentDetails.UserId;
  const isv2Hots = selectedSyllabus?.CurriculumID === NewHOTsCurriculumID;

  const selectedQnsData = topics
    .filter((item) => !isNil(item.totalQuestionNumber))
    .map((item) => item.Skills)
    .flat()
    .filter((item) => item.questionNumber > 0);

  const skillsBodyData = selectedQnsData.map((item) => {
    return {
      skillId: item.SkillId,
      numberOfQuestion: item.questionNumber,
    };
  });

  const levelsTemplate = (levelID) => {
    if (levelID < 7) {
      // Format numbers for chinese languages
      const formattedValue = formatChineseNumber({
        value: levelID,
        language: currentLanguage,
      });
      if (isMobile && isEnglish) {
        return `P${formattedValue}`;
      }
      return `${t(
        `selfPractice:sidebar.primary`,
        'Primary'
      )} ${formattedValue}`;
    }
    const findLevel = years.filter((item) => item.ID === levelID);
    return findLevel[0].Name;
  };
  const ageGroupTemplate = (levelID, index) => {
    const levelIndex = isNil(index)
      ? years.findIndex((level) => level.ID === levelID)
      : index;
    if (levelIndex === -1) return levelsTemplate(levelID);
    const ageGroup = ageGroups[levelIndex];
    if (isNil(ageGroup)) return levelsTemplate(levelID);
    return t(
      ageGroup.i18nKey,
      { minAge: ageGroup.minAge, maxAge: ageGroup.maxAge },
      ageGroup.name
    );
  };

  const closeModal = () => {
    setShowAssignModal(false);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const closeChangeModal = () => {
    setShowChangeModal(false);
  };

  const displayCurriculumError = () => {
    setParamsError(
      t(
        'parentAssign:errorModal.curriculum',
        "This user doesn't have access to the curriculum."
      )
    );
  };

  const changeTopic = (id) => {
    const result = topics.find((topic) => topic.TopicId === id);
    setSelectedTopic(result);
    setShowSkillDetails(false);
  };

  const updateSyllabus = (result) => {
    setSelectedSyllabus(result);
    setYears(result.Levels);
    if (result.Levels.length > 0) {
      const isNewCurriculumHOTs = result.CurriculumID === NewHOTsCurriculumID;
      const parsedLevelId = parseInt(levelId, 10);
      const currentUserLevel =
        isNewCurriculumHOTs && !parsedLevelId
          ? result.Levels[ageGroupIndex]
          : result.Levels.find(
              (level) => level.ID === (parsedLevelId || result.CurrentUserLevel)
            );
      if (isNil(currentUserLevel)) {
        setSelectedYear(result.Levels[0]);
      } else {
        setSelectedYear(currentUserLevel);
      }
    } else {
      setSelectedYear('');
    }
  };

  const changeSyllabus = (value) => {
    const result = curriculums.find(
      (curriculum) => curriculum.curriculumName === value
    );
    if (
      totalQuestions > 0 &&
      result?.CurriculumID !== selectedSyllabus?.CurriculumID
    ) {
      setShowChangeModal(true);
      setPreSelected(result);
      setDropDownType('syllabus');
    } else {
      updateSyllabus(result);
    }
  };

  const changeYear = (id) => {
    const result = years.find((year) => year.ID === id);
    if (totalQuestions > 0 && result?.ID !== selectedYear?.ID) {
      setShowChangeModal(true);
      setPreSelected(result);
      setDropDownType('year');
    } else {
      setSelectedYear(result);
    }
  };

  const yesHandle = () => {
    if (dropDownType === 'syllabus') {
      setSelectedSyllabus(preSelected);
      updateSyllabus(preSelected);
      setTotalQuestions(0);
    } else {
      setSelectedYear(preSelected);
      setTotalQuestions(0);
    }
    setShowSkillDetails(false);
    setShowChangeModal(false);
  };
  const searchAction = (signal) => {
    const params =
      featureToggle.science === true
        ? { filterSubject: true, signal }
        : { signal };
    dispatch(getUserCurriculums(params));
  };
  const searchReportAction = () => {
    const obj = {
      userId,
      classId: groupId,
      curriculumId: selectedSyllabus.CurriculumID,
      levelId: selectedYear.ID ? selectedYear.ID : '',
    };
    dispatch(getUserReport(obj));
  };
  useEffect(() => {
    const controller = new AbortController();

    if (featureToggle.science !== true) {
      searchAction(controller.signal);
    } else if (products !== null) {
      searchAction(controller.signal);
    }
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, [products]);
  useEffect(() => {
    if (isCreateError) {
      setShowSuccessModal(false);
    }
  }, [isCreateError]);
  useEffect(() => {
    const getDefaultCurriculum = () => {
      if (curriculumId) {
        const newCurriculum = curriculums.find(
          (curriculum) => curriculum.CurriculumID === parseInt(curriculumId, 10)
        );
        if (!newCurriculum) {
          displayCurriculumError();
          return curriculums[0];
        }
        return newCurriculum;
      }
      return curriculums[0];
    };
    if (!isEmpty(curriculums)) {
      updateSyllabus(getDefaultCurriculum());
    }
    // eslint-disable-next-line
  }, [dispatch, curriculums]);

  useEffect(() => {
    const controller = new AbortController();
    if (!isNil(selectedYear) && !isNil(selectedSyllabus) && !isNil(userId)) {
      const obj = {
        userId,
        classId: groupId,
        curriculumId: selectedSyllabus.CurriculumID,
        levelId: selectedYear.ID ? selectedYear.ID : '',
        signal: controller.signal,
      };
      dispatch(getUserReport(obj));
    }
    return () => {
      controller.abort();
    };
  }, [dispatch, selectedYear, selectedSyllabus, userId, groupId]);
  useEffect(() => {
    const getDefaultTopic = (topicList) => {
      const newTopic = topicList.find(
        (topic) => topic.TopicId === parseInt(topicId, 10)
      );
      return newTopic || topicList[0];
    };

    if (!isNil(report) && !isEmpty(report.Topics)) {
      setTotalQuestions(0);
      setTopics(report.Topics);
      setSelectedTopic(getDefaultTopic(report.Topics));
    }
  }, [report, topicId]);

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(resetCurriculums());
      dispatch(resetParentError());
    };
  }, [dispatch]);
  const reloadHandler = () => {
    if (curriculumsError) {
      const controller = new AbortController();
      searchAction(controller.signal);
    }
    if (userReportError) {
      searchReportAction();
    }
    if (isCreateError) {
      dispatch(createAssignment(createdAssignmentParams));
      setShowSuccessModal(true);
    }
  };

  const totalQuestionAndAssignbuttonComponent = (
    <>
      <QuestionDetails qnNo={totalQuestions === 0}>
        <div className="qn-details">
          <div className="total">{t('parentAssign:header.total', 'Total')}</div>
          <div className="no-question">{totalQuestions}</div>
          <div className="qn">{t('parentAssign:header.qn', 'Qn(s)')}</div>
        </div>
        {totalQuestions === 50 && (
          <div className="validation-text">
            {t('parentAssign:header.validation', 'maximum 50 questions')}
          </div>
        )}
      </QuestionDetails>
      <Button
        className="assign-button"
        type="button"
        variant="primary"
        width="130px"
        fontSize="19px"
        onClick={() => setShowAssignModal(true)}
        isDisabled={totalQuestions > 50 || totalQuestions === 0}
        disableColor="#C4D9EA"
      >
        {t('parentAssign:header.assign', 'Assign')}
      </Button>
    </>
  );

  const getDropdownComponent = () => {
    if (!curriculumsLoading && !isProductsLoading) {
      if (!isMobile) {
        return (
          <SyllabusDropdown>
            <p className="label">
              {t('parentAssign:header.sylLabel', 'Question from')}
            </p>
            {curriculums && (
              <span className="syllabus">
                <DropdownMenu
                  width="max-content"
                  maxWidth={isTablet ? '200px' : '300px'}
                  menuWidth="max-content"
                  selectedValue={
                    selectedSyllabus && selectedSyllabus.curriculumName
                  }
                  valueKey="curriculumName"
                  values={curriculums}
                  setValue={({ curriculumName }) =>
                    changeSyllabus(curriculumName)
                  }
                />
              </span>
            )}
            {years && years.length > 0 && (
              <span className="year">
                <DropdownMenu
                  width="max-content"
                  maxWidth="max-content"
                  menuWidth="max-content"
                  selectedValue={
                    isv2Hots
                      ? ageGroupTemplate(selectedYear.ID)
                      : levelsTemplate(selectedYear.ID)
                  }
                  valueKey="ID"
                  values={years}
                  valueTemplate={isv2Hots ? ageGroupTemplate : levelsTemplate}
                  setValue={({ ID }) => changeYear(ID)}
                />
              </span>
            )}
          </SyllabusDropdown>
        );
      }

      return (
        <FilterWrapper>
          {curriculums && (
            <FilterModal
              type="box"
              selectedValue={
                selectedSyllabus && selectedSyllabus.curriculumName
              }
              valueKey="curriculumName"
              values={curriculums}
              setValue={({ curriculumName }) => changeSyllabus(curriculumName)}
            />
          )}
          {years && years.length > 0 && (
            <FilterModal
              type="circle"
              selectedValue={
                isv2Hots
                  ? ageGroupTemplate(selectedYear.ID)
                  : levelsTemplate(selectedYear.ID)
              }
              valueKey="ID"
              values={years}
              valueTemplate={isv2Hots ? ageGroupTemplate : levelsTemplate}
              setValue={({ ID }) => changeYear(ID)}
            />
          )}
        </FilterWrapper>
      );
    }

    return <div />;
  };

  return (
    <>
      <Container>
        <ModalContentXButton
          showModal={showAssignModal}
          closeModal={closeModal}
          backdrop="static"
          containerMinWidth={isMobile ? '200px' : '530px'}
        >
          <AssignModal
            closeModal={closeModal}
            setShowSuccessModal={setShowSuccessModal}
            totalQn={totalQuestions}
            data={skillsBodyData}
          />
        </ModalContentXButton>
        {!isCreateError && (
          <ModalContentIcon
            showModal={showSuccessModal}
            closeModal={closeSuccessModal}
            backdrop="static"
            successBtnText={t('parentAssign:resultModal.ok', 'OK')}
            successIcon={IconSuccessModal}
            skipSuccessTitle
            data={createdAssignment}
            loading={isCreating}
            containerMinWidth={isMobile ? '200px' : '515px'}
            onClick={() => {
              closeSuccessModal();
              if (!isNil(createdAssignment)) {
                history.push('/assignment/assignment-list');
              }
            }}
          >
            <ResultModal />
          </ModalContentIcon>
        )}

        <ModalContentAlert
          showModal={showChangeModal}
          closeModal={closeChangeModal}
          backdrop="static"
          header={t('parentAssign:changeModal.header', 'Alert')}
          desc={t(
            'parentAssign:changeModal.desc',
            'The current selection will not be saved if you change the syllabus or level. Do you want to proceed?'
          )}
          noHandle={closeChangeModal}
          yesHandle={() => yesHandle()}
        />
        <TopBar>
          <BackButton
            backText={t('leaderboard:buttons.back', 'Back')}
            btnBgColor="rgba(81,172,248, 0.61)"
            padding="0.3rem 1.2rem"
            onClick={() => history.replace('/assignment')}
            isResponsive={isMobile}
          />
          {getDropdownComponent()}
          {!isMobile && (
            <RightSide>{totalQuestionAndAssignbuttonComponent}</RightSide>
          )}
        </TopBar>

        {!isMobile && (
          <div className="proficiency-legend">
            <ProficiencyLegend />
          </div>
        )}

        <Main showSkillDetails={showSkillDetails}>
          {isLoading && (
            <StyledSpinner>
              <Spinner />
            </StyledSpinner>
          )}
          {!isLoading && (
            <MainContent>
              <Sidebar>
                {topics && (
                  <TopicList
                    data={topics}
                    selectedTopic={selectedTopic}
                    changeTopic={changeTopic}
                    variant="blueLight"
                    page="parentAssign"
                  />
                )}
              </Sidebar>
              <Content className="content">
                {selectedTopic && (
                  <>
                    <div className="titleWrapper">
                      <div className="iconSidebarWrapper">
                        <MobileSidebar
                          withTab={false}
                          topics={topics}
                          color="soft-blue"
                          selectedTopic={selectedTopic}
                          changeTopic={changeTopic}
                        />
                      </div>
                      <h4 className="title">{selectedTopic.TopicName}</h4>
                    </div>
                    {selectedTopic.Skills.map(
                      (
                        {
                          SkillId,
                          Proficiency,
                          ProficiencyStatus,
                          SkillName,
                          questionNumber,
                        },
                        index
                      ) => (
                        <SkillRow
                          id={SkillId}
                          key={SkillId}
                          percentage={Proficiency}
                          status={ProficiencyStatus}
                          description={SkillName}
                          questionNumber={questionNumber}
                          topics={topics}
                          setTopics={setTopics}
                          selectedTopic={selectedTopic}
                          setSelectedTopic={setSelectedTopic}
                          selectedSkill={selectedSkill}
                          setSelectedSkill={setSelectedSkill}
                          showSkillDetails={showSkillDetails}
                          setShowSkillDetails={setShowSkillDetails}
                          totalQuestions={totalQuestions}
                          setTotalQuestions={setTotalQuestions}
                          order={index + 1}
                        />
                      )
                    )}
                  </>
                )}
              </Content>
              {showSkillDetails && (
                <SkillPanel
                  selectedSkill={selectedSkill}
                  setSelectedSkill={setSelectedSkill}
                  showSkillDetails={showSkillDetails}
                  setShowSkillDetails={setShowSkillDetails}
                  totalQuestions={totalQuestions}
                  selectedTopic={selectedTopic}
                  setSelectedTopic={setSelectedTopic}
                  topics={topics}
                  setTopics={setTopics}
                  setTotalQuestions={setTotalQuestions}
                />
              )}
            </MainContent>
          )}
        </Main>
      </Container>
      {(curriculumsError && curriculumsError !== CANCEL_ERROR) ||
        (userReportError && userReportError !== CANCEL_ERROR) ||
        isCreateError ||
        (paramsError && (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={
              curriculumsError ||
              userReportError ||
              isCreateError ||
              paramsError
            }
          />
        ))}
      {isMobile && (
        <BottomMenu>{totalQuestionAndAssignbuttonComponent}</BottomMenu>
      )}
    </>
  );
};

export default withPlanCheck(
  ParentAssign,
  Object.values(SUBJECTS).map((s) => s.toLowerCase()),
  'B2C'
);
