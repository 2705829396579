import { SUBJECTS, subjectNames } from 'constants/products';

export const BRAIN_GAME_ROUTES = Object.values(SUBJECTS).reduce(
  (acc, subjectName) => {
    acc = {
      ...acc,
      [subjectNames[subjectName]]: `/brain-game/${subjectName.toLowerCase()}`,
    };
    return acc;
  },
  {}
);
export const STORY_ROUTES = Object.values(SUBJECTS).reduce(
  (acc, subjectName) => {
    acc = {
      ...acc,
      [subjectNames[subjectName]]: `/story/${subjectName.toLowerCase()}`,
    };
    return acc;
  },
  {}
);
export const GIFT_BOXES = Array(8).fill(1);
