import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useSuperheroChallengeLoader from 'store/superheroChallenge/superheroChallengeLoader';
import { sendEventTracking } from 'helpers/UserEventTracking';

import { PopupModal, Spinner } from 'components/Shared';
import { ConfirmModal } from './components';

import {
  Content,
  HistoryButton,
  IntroText,
  RuleTitleText,
  RulesText,
  Thumbnail,
  TodayScore,
  StartButton,
  StyledLoaderWrapper,
} from './SuperVision.styles';

const SuperVision = () => {
  const { t } = useTranslation(['superVision', 'superSpeed']);
  const history = useHistory();
  const { superVision } = useSelector((state) => state.superheroChallenge);
  const { points } = useSelector((state) => state.superVision);
  const { superVisionStatus, loading } = useSuperheroChallengeLoader(
    'supervision'
  );
  const completed = superVisionStatus === 2;
  const { BaseImg } = superVision;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const startChallenge = useCallback(
    (e) => {
      e.preventDefault();
      history.push(`/superhero/supervision-game`);
      sendEventTracking('super_vision', 'challenge_start');
    },
    [history]
  );

  const openConfirmModal = () => {
    if (completed) {
      history.push(`/superhero/supervision-game`);
    } else {
      setShowConfirmModal(true);
    }
  };

  const goToHistory = useCallback(() => {
    history.push('/superhero/history');
  }, [history]);
  if (superVisionStatus === undefined) {
    return [];
  }
  return (
    <Content completed={completed}>
      {loading && (
        <StyledLoaderWrapper>
          <div>
            <Spinner />
          </div>
        </StyledLoaderWrapper>
      )}
      <PopupModal show={showConfirmModal} centered backdrop="static">
        <ConfirmModal
          header={t(
            'superVision:confirmModal.header',
            `Super Vision Challenge`
          )}
          message={t(
            'superVision:confirmModal.message',
            `You can only attempt this challenge once per day. Are you ready?`
          )}
          onAccept={startChallenge}
          onReject={closeConfirmModal}
        />
      </PopupModal>
      <HistoryButton onClick={goToHistory}>
        {t('superSpeed:welcome.scoreHistory', 'SCORE HISTORY')}
      </HistoryButton>
      <IntroText>
        {completed
          ? t(
              'superVision:introText.completedText',
              `You have already done the challenge for today. Click the link below to view the challenge, or come back tomorrow to challenge again`
            )
          : t(
              'superVision:introText.defaultText',
              `Welcome to Super Vision Challenge! Observe the patterns and spot the differences between the left and right images.`
            )}
      </IntroText>
      {!completed && (
        <>
          <RuleTitleText>{t('superVision:rules.title', `Rules`)}</RuleTitleText>
          <RulesText>
            <li>
              {t(
                'superVision:rules.rule1',
                `Available from Monday to Saturday`
              )}
            </li>
            <li>
              {t('superVision:rules.rule2', `5 differences per challenge`)}
            </li>
            <li>{t('superVision:rules.rule3', `Maximum 5 wrong attempts`)}</li>
            <li>
              {t('superVision:rules.rule4', `10 points per correct answer`)}
            </li>
            <li>{t('superVision:rules.rule5', `Time limit: 5 minutes`)}</li>
          </RulesText>
        </>
      )}
      {BaseImg && (
        <Thumbnail
          completed={completed}
          src={`data:image/png;base64,${BaseImg}`}
          alt="thumbnail"
        />
      )}
      {completed && <TodayScore>{points * 10}</TodayScore>}
      <StartButton completed={completed} onClick={openConfirmModal}>
        {completed
          ? t('superVision:btn.view', 'View Challenge')
          : t('superVision:btn.start', 'Start Challenge')}
      </StartButton>
    </Content>
  );
};

export default React.memo(SuperVision);
