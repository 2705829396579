import handleResponse from 'helpers/handleAPIResponse';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_DOMAIN;
const USER_BASE_URL = process.env.REACT_APP_USER_SERVICE_URL;
const DIRECT_PAYMENT_BASE_URL =
  process.env.REACT_APP_DIRECT_PAYMENT_SERVICE_URL;

export const fetchParentDetails = (parentID) => {
  return axios
    .get(`${USER_BASE_URL}Parents/ParentDetails/${parentID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postCancelSubscription = (body) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    data: body,
  };
  return axios
    .delete(
      `${DIRECT_PAYMENT_BASE_URL}Subscriptions/cancel/paymentsubscription/${body.paymentSubscriptionId}`,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(
        err?.message ??
          'An error occurred while processing your request. Please contact our support to resolve this issue.'
      );
    });
};

export const postParentProfile = ({ parentId, rawBody }) => {
  return fetch(`${baseUrl}Parent/UpdateParentProfile/${parentId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update parent profile failed'); // eslint-disable-line
    });
};

export const postParentPassword = (rawBody) => {
  return fetch(`${baseUrl}Profile/UpdatePassword`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update parent password failed'); // eslint-disable-line
    });
};

export const postChildPassword = (rawBody) => {
  return fetch(`${baseUrl}Parent/UpdateChildPassword`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.IsSuccessful;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update child password failed'); // eslint-disable-line
    });
};

export const postChildDisplayName = (rawBody) => {
  return fetch(`${baseUrl}Parent/UpdateProfileDetail`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.IsSuccessful;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update child display name failed'); // eslint-disable-line
    });
};

export const postChildLoginId = (rawBody) => {
  return fetch(`${baseUrl}Parent/UpdateChildLoginID`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.IsSuccessful;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Update child login id failed'); // eslint-disable-line
    });
};

export const fetchParentsChildren = (parentId) => {
  return axios
    .get(`${DIRECT_PAYMENT_BASE_URL}Parents/${parentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get parent's children failed"); // eslint-disable-line
    });
};

export const fetchChildSubs = (childId) => {
  return axios
    .get(
      `${DIRECT_PAYMENT_BASE_URL}Students/${childId}/Subscriptions/WithRenewalDetails`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get child subscription failed'); // eslint-disable-line
    });
};
