import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HeaderTime from 'assets/img/superhero/superhero-challenge-header-time.svg';
import HeaderMask from 'assets/img/superhero/superhero-challenge-header-mask.png';

import Header from 'components/Header';
import { BackButton, QnViewHeader } from 'components/Shared';
import { HistoryTable } from './components';

import { Content, Heading, StyledTime } from './SuperHeroHistory.styles';

function SuperHeroHistory() {
  const history = useHistory();
  const { t } = useTranslation(['challengeList', 'topicSelection']);

  return (
    <div style={{ marginTop: '-20.5px' }}>
      <Header />
      <QnViewHeader
        page
        bgColor="linear-gradient(to left, #18BCFB, #2EE4FD)"
        headerBg={HeaderMask}
      >
        <BackButton
          backText={t('topicSelection:backBtn', 'Back')}
          btnBgColor="#10CAFF"
          padding="5px 20px"
          onClick={() => history.goBack()}
          styles={{ position: 'absolute', left: '3%' }}
        />
        <StyledTime
          className="super-hero-challenge-time"
          src={HeaderTime}
          alt="super-hero-challenge-time"
        />
        <Heading>
          {t(
            'challengeList:SuperHeroChallengeHistory',
            'Super Hero Challenge History'
          )}
        </Heading>
      </QnViewHeader>
      <Content>
        <HistoryTable />
      </Content>
    </div>
  );
}

export default React.memo(SuperHeroHistory);
