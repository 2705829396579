import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import KokoCredit from 'assets/img/kooquiz/icon-koko-credit.svg';

import { Button } from 'components/Shared';
import ConfettiWrapper from 'components/Shared/ConfettiWrapper';
import ClaimKoKoModal from '../ClaimKoKoModal';

import {
  Container,
  StyledTotalScore,
  StyledButton,
  StyledSvgMiko,
  StyledEffortText,
} from './MissionPlusBanner.styles';

const DisplayTotalScore = ({ progress }) => {
  return (
    <StyledTotalScore>
      <Trans
        i18nKey="selfPracticeQnView:missionPlus.totalAnswered"
        values={{ progress }}
      >
        <span>{progress}</span> out of 10 correct
      </Trans>
    </StyledTotalScore>
  );
};

const MissionPlusBanner = ({
  topicSkillId,
  stageTypeId,
  progress,
  inProgress,
  subject,
}) => {
  const { t, i18n } = useTranslation(['selfPracticeQnView, assignmentQnView']);
  const history = useHistory();
  const [openClaim, setOpenClaim] = useState(false);
  const handleClaimReward = () => {
    setOpenClaim(true);
  };

  const handleTryAgain = () => {
    history.goBack();
  };

  if (progress >= 8)
    return (
      <>
        <ClaimKoKoModal
          open={openClaim}
          setOpen={setOpenClaim}
          topicSkillId={topicSkillId}
          stageTypeId={stageTypeId}
          canGetOneTimeReward
          subject={subject}
        />
        <Container progress={progress}>
          <div className="layer-bg">
            <ConfettiWrapper />
            <StyledSvgMiko alt="miko-badge" stageTypeId={stageTypeId} />
            <DisplayTotalScore progress={progress} inProgress={inProgress} />
            <StyledButton
              variant="primary"
              width="250px"
              onClick={handleClaimReward}
            >
              {t(
                'selfPracticeQnView:reward.claimMyRewards',
                'Claim My Rewards'
              )}{' '}
              <img src={KokoCredit} alt="koko-credit" className="koko" />
            </StyledButton>
          </div>
        </Container>
      </>
    );

  if (progress > 0 && progress < 8) {
    const displayText = t(
      'selfPracticeQnView:missionPlus.goodEffort',
      'Good effort!'
    );
    return (
      <>
        <ClaimKoKoModal
          open={openClaim}
          setOpen={setOpenClaim}
          topicSkillId={topicSkillId}
          stageTypeId={stageTypeId}
          subject={subject}
        />
        <Container progress={progress}>
          <div className="layer-bg">
            <StyledEffortText language={i18n.language}>
              <span title={displayText}>{displayText}</span>
            </StyledEffortText>
            <DisplayTotalScore progress={progress} inProgress={inProgress} />
            <StyledButton
              variant="primary"
              width="250px"
              onClick={handleClaimReward}
            >
              {t(
                'selfPracticeQnView:reward.claimMyRewards',
                'Claim My Rewards'
              )}{' '}
              <img src={KokoCredit} alt="koko-credit" className="koko" />
            </StyledButton>
          </div>
        </Container>
      </>
    );
  }

  return (
    <Container progress={progress}>
      <div className="layer-bg">
        <DisplayTotalScore progress={progress} inProgress={inProgress} />
        <Button variant="primary" width="250px" onClick={handleTryAgain}>
          {t('assignmentQnView:main.tryAgain', 'Try Again')}
        </Button>
      </div>
    </Container>
  );
};

export default MissionPlusBanner;
