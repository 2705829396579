import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, useStore } from 'react-redux';
import ModalVideo from 'react-modal-video';
import { isNil, isEmpty } from 'ramda';
import moment from 'moment-timezone';
import { useMediaQuery } from 'react-responsive';

import { clearVoiceoverPlaying } from 'store/voiceover/voiceoverSlice';
import {
  setActiveQuestion,
  saveAnswer,
  checkAnswer,
  finishAssignment,
  reset,
  openAssignment,
  resetAssignmentViewError,
  getHwSubmission,
} from 'store/assignment/assignmentSlice';
import {
  setActiveEventQuestion,
  saveEventAns,
  submitEventAssignment,
  resetEvent,
  resetEventSubmitting,
} from 'store/event/eventSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { setLastSavedTime, setTimeLeft } from 'store/timer/timerSlice';
import { populateBarModel, clearAll } from 'store/assignment/barModelSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

import NextIcon from 'assets/img/assignment/back-icon.svg';
import PrevIcon from 'assets/img/assignment/back-icon-blue.svg';

import {
  assignmentTypeIDs,
  submissionTypeIDs,
  questionTypeIDs,
} from 'constants/index';

import answersEqualsChecker from 'helpers/validation/answersEqualsChecker';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import newfinishAssignmentHandler from 'helpers/finishAnswerHandler';
import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import { getWorkings } from 'helpers/getWorkings';
import { sendEventTracking } from 'helpers/UserEventTracking';

import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import QnSideBar from 'components/Assignment/AssignmentView/QnSideBar';
import QuestionContent from 'components/Question';
import {
  PopupModal,
  ErrorModal,
  SubmitModal,
  Spinner,
  Canvas,
  QuestionFeedbackLink,
} from 'components/Shared';
import {
  TimeUpModalBody,
  TimeUpEventModalBody,
  AssignmentHeader,
} from './components';
import {
  Container,
  Main,
  NotebookContainer,
  Notebook,
  NoteBookTop,
  NotebookMiddle,
  NotebookBottom,
  QnButton,
  QnText,
} from './AssignmentView.styles';

// example data to populate bar model
// const barModelString =
//   '{"objects":[{"id":"a409016e-f17f-4eeb-b264-5bd05c495ed3","type":"bar","size":100,"extra":{"color":"#00d084","unit":"2"},"x":0,"y":236,"zIndex":1},{"id":"434bc836-a442-4228-9a0f-55abcd068a82","type":"brace","size":100,"x":0,"y":156,"zIndex":2},{"id":"14faac96-3e28-4755-b492-e204276dc24f","type":"iBrace","size":100,"x":0,"y":133,"zIndex":3},{"id":"d70a1c33-7a19-46a6-9076-d940c1a9ea9f","type":"iBrace","size":100,"x":0,"y":169,"zIndex":4},{"id":"8eca2450-7206-4b8a-a3df-e685d75ff3ae","type":"vBrace","size":100,"x":0,"y":21,"zIndex":5},{"id":"3025ac35-5e87-4131-bb8d-6e46ab31a8af","type":"arrow","size":100,"x":0,"y":197,"zIndex":6},{"id":"3c5ac40a-58e5-4689-afc7-d80011aa7b12","type":"text","size":115,"extra":{"text":"a","height":107},"x":18.9998779296875,"y":18.000015258789062,"zIndex":7},{"id":"dd727ccf-fe66-4cbf-a303-012ee1b1153a","type":"bar","size":100,"extra":{"color":"#EB144C","unit":1},"x":186,"y":236,"zIndex":8}],"width":350,"height":450}';

const AssignmentView = () => {
  const { t } = useTranslation(['assignmentQnView', 'sundayMCQnVIew']);
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 846px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 502px)' });
  const showPrevButtonBreakPoint = useMediaQuery({
    query: '(min-width: 649px)',
  });
  const eventsQs = location.pathname === '/events/open';
  const { data: barModelData } = useSelector((state) => state.barModel);
  const {
    startAssignmentInfo,
    assignmentSubmissionID,
    questions,
    questionAnswers,
    submissions,
    savedAnswers,
    localSavedAnswers,
    activeQuestion,
    isOpening,
    isLoading,
    error,
    showTimesUpModal,
    workings,
    openAssignmentError,
    checkAnswerError,
    checkAnswerParams,
    isSaving,
    isChecking,
  } = useSelector((state) => state.assignment);
  const {
    eventSubmissionID,
    activeQuestionEvent,
    eventQuestions,
    eventQsAnswers,
    eventSubmissions,
    eventSavedAns,
    eventLocalSavedAns,
    eventLocalSavedWorkings,
    eventIsOpening,
    eventIsLoading,
    eventError,
    showEventTimesUpModal,
    isEventSubmitting,
    isEventSubmittingLoading,
    assignmentEventSubmissionError,
    assignmentEventSubmissionParams,
    isEventSaving,
  } = useSelector((state) => state.event);
  const submissionType = eventsQs
    ? submissionTypeIDs.MiniChallenge
    : submissionTypeIDs.Homework;
  let assignmentType;
  let setActive;
  let activeQn;
  let submissionId;
  let allQs;
  let allQsAns;
  let allSubmission;
  let savedAns;
  let localSavAns;
  let localSavWorkings;
  let opening;
  let loading;
  let isErr = null;
  let saving;
  let checking;

  if (eventsQs) {
    assignmentType = 'sundayMC';
    setActive = setActiveEventQuestion;
    activeQn = activeQuestionEvent;
    submissionId = eventSubmissionID;
    allQs = eventQuestions;
    allQsAns = eventQsAnswers;
    allSubmission = eventSubmissions;
    savedAns = eventSavedAns;
    localSavAns = eventLocalSavedAns;
    localSavWorkings = eventLocalSavedWorkings;
    opening = eventIsOpening;
    loading = eventIsLoading;
    isErr = eventError;
    saving = isEventSaving;
  } else {
    assignmentType = assignmentTypeIDs[startAssignmentInfo.Type];
    setActive = setActiveQuestion;
    activeQn = activeQuestion;
    submissionId = assignmentSubmissionID;
    allQs = questions;
    allQsAns = questionAnswers;
    allSubmission = submissions;
    savedAns = savedAnswers;
    localSavAns = localSavedAnswers;
    localSavWorkings = workings;
    opening = isOpening;
    loading = isLoading;
    isErr = error;
    saving = isSaving;
    checking = isChecking;
  }
  // Loading state for Open Assignment & Get Assignment Qns API
  const isPending = opening || loading;
  // Required params for saving question submission
  const { userID } = useSelector((state) => state.login);
  // COMPONENT STATES
  // Submit modal state
  const [submitModal, setSubmitModal] = useState(false);
  // Finish modal state
  const [finishModal, setFinishModal] = useState(false);
  // Video modal
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    // EVENT TRACKING: onpage load
    if (assignmentType === 'sundayMC') {
      sendEventTracking('sunday_mini_challenge', 'question_view', {
        sub: 1, // because only math has SMC for now
      });
    } else {
      sendEventTracking('homework', 'question_view', {
        hi: startAssignmentInfo.Id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // EVENT HANDLERS
  const dispatchSaveAction = (
    fetchNextQn,
    setAssignmentDuration,
    elapsedTime
  ) => {
    if (eventsQs) {
      return (params) => {
        dispatch(saveEventAns(params));
      };
    }
    return (params) => {
      const saveParams = {
        ...params,
      };
      if (fetchNextQn) saveParams.fetchNextQn = true;
      if (setAssignmentDuration && elapsedTime) {
        saveParams.setAssignmentDuration = true;
        saveParams.elapsedTime = elapsedTime;
      }
      if (answersEqualsChecker(saveParams, allSubmission)) {
        dispatch(
          getHwSubmission({
            assignmentSubmissionID: params.rawBody?.homeworkSubmissionID,
            questionId: allQs[params.activeQuestion - 1]?.Id,
            activeQuestion: activeQn,
          })
        );
        return;
      }
      dispatch(saveAnswer(saveParams));
    };
  };
  const dispatchCheckAnswerAction = (fetchNextQn) => {
    if (!eventsQs) {
      return (params) => {
        const saveParams = {
          ...params,
        };
        if (fetchNextQn) saveParams.fetchNextQn = true;
        dispatch(checkAnswer(saveParams));
      };
    }
  };
  const dispatchFinishAssignmentAction = (params) => {
    dispatch(finishAssignment(params));
  };
  const dispatchFinishEventAction = (params) => {
    dispatch(submitEventAssignment(params));
  };

  // USEEFFECTS
  // Populate bar model data based on current active question
  useEffect(() => {
    if (
      allSubmission[activeQn - 1]?.SubmissionModel !== null &&
      parseWorkingsBarModel(
        allSubmission[activeQn - 1]?.SubmissionModel.Solution
      ).barModel !== null
    ) {
      dispatch(
        populateBarModel(
          parseWorkingsBarModel(
            allSubmission[activeQn - 1]?.SubmissionModel.Solution
          ).barModel
        )
      );
    }
  }, [activeQn, allSubmission, dispatch]);
  useEffect(() => {
    if (assignmentType === 'mocktest' || assignmentType === 'test') {
      const currentDateTime = moment();
      const deadline = moment(startAssignmentInfo.EndDate);
      const timeLeftInMs = moment
        .duration(deadline.diff(currentDateTime))
        .as('milliseconds');
      dispatch(setTimeLeft(timeLeftInMs));
    }
  }, [assignmentType, dispatch, startAssignmentInfo]);

  // Cleanup when page is unmounted
  useEffect(() => {
    return () => {
      dispatch(reset());
      dispatch(resetEvent());
      dispatch(resetAssignmentViewError());
    };
  }, [dispatch]);
  useEffect(() => {
    if (isEventSubmitting) {
      setSubmitModal(false);
      dispatch(resetEventSubmitting());
      history.push('/events/submitted/result-release');
    }
  }, [dispatch, history, isEventSubmitting]);

  const openModal = () => {
    dispatch(clearVoiceoverPlaying());
    if (eventsQs) {
      setSubmitModal(true);
    } else {
      setFinishModal(true);
    }
  };

  const closeModal = () => {
    if (eventsQs) {
      setSubmitModal(false);
    } else {
      setFinishModal(false);
    }
  };

  const saveAnswerBE = (params) => {
    const {
      fetchNextQn = false,
      setAssignmentDuration = false,
      elapsedTime = 0,
    } = params ?? {};
    const now = Date.now();
    newSaveAnswerHandler({
      assignmentSubmissionID: submissionId,
      assignmentType,
      questionType: questionTypeIDs[allQs[activeQn - 1].QuestionType],
      submissionType,
      questionAnswer: allQsAns[activeQn - 1],
      saveAnswerAction: dispatchSaveAction(
        fetchNextQn,
        setAssignmentDuration,
        elapsedTime
      ),
      checkAnswerAction: dispatchCheckAnswerAction(fetchNextQn),
      activeQnNo: activeQn,
      currentQn: allQs[activeQn - 1],
      currentLocalSavedAnswer: localSavAns[activeQn - 1],
      currentSubmission: allSubmission[activeQn - 1].SubmissionModel,
      timeElapsed: now - store.getState().timer.lastSavedTime,
      userID,
      workings: getWorkings(
        allSubmission[activeQn - 1],
        localSavWorkings[activeQn - 1]
      ),
      barModel: barModelData.objects.length > 0 ? barModelData : null,
    });
  };
  const finishAssignmentModal = async () => {
    closeModal();
    const now = Date.now();
    if (assignmentType === 'homework') {
      newfinishAssignmentHandler({
        assignmentSubmissionID: submissionId,
        questionType: questionTypeIDs[allQs[activeQn - 1].QuestionType],
        submissionType,
        questionAnswer: allQsAns[activeQn - 1],
        activeQnNo: activeQn,
        currentQn: allQs[activeQn - 1],
        currentLocalSavedAnswer: localSavAns[activeQn - 1],
        currentSubmission: allSubmission[activeQn - 1].SubmissionModel,
        userID,
        timeElapsed: now - store.getState().timer.lastSavedTime,
        finishAssignmentAction: dispatchFinishAssignmentAction,
        workings: getWorkings(
          allSubmission[activeQn - 1],
          localSavWorkings[activeQn - 1]
        ),
        barModel: barModelData.objects.length > 0 ? barModelData : null,
      });
    } // Don't call Finish homework until student clicks submit else answers will be marked
    else {
      // Mock test/test assignment
      saveAnswerBE({
        fetchNextQn: false,
        setAssignmentDuration: true,
        elapsedTime: store.getState().timer.timeElapsed,
      });
    }
    history.push(`/assignment/finish`);
  };

  const reloadHandler = () => {
    if (openAssignmentError) {
      dispatch(setActiveQuestion(1));
      dispatch(openAssignment(startAssignmentInfo.Id));
    }
    if (checkAnswerError) {
      dispatch(checkAnswer(checkAnswerParams));
    }
    if (assignmentEventSubmissionError) {
      dispatchFinishEventAction(assignmentEventSubmissionParams);
    }
  };
  const submitAssignmentModal = () => {
    // saveAnswerBE();
    const now = Date.now();
    dispatch(setLastSavedTime(now));
    newSaveAnswerHandler({
      assignmentSubmissionID: submissionId,
      assignmentType,
      questionType: questionTypeIDs[allQs[activeQn - 1].QuestionType],
      submissionType,
      questionAnswer: allQsAns[activeQn - 1],
      saveAnswerAction: dispatchFinishEventAction,
      checkAnswerAction: dispatchCheckAnswerAction(false),
      activeQnNo: activeQn,
      currentQn: allQs[activeQn - 1],
      currentLocalSavedAnswer: localSavAns[activeQn - 1],
      currentSubmission: allSubmission[activeQn - 1].SubmissionModel,
      timeElapsed: now - store.getState().timer.lastSavedTime,
      userID,
      workings: getWorkings(
        allSubmission[activeQn - 1],
        localSavWorkings[activeQn - 1]
      ),
      barModel: barModelData.objects.length > 0 ? barModelData : null,
    });
    // EVENT TRACKING: submit answer for sunday mini challenge
    // eslint-disable-next-line array-callback-return
    allQs.map((item) => {
      sendEventTracking('sunday_mini_challenge', 'question_submit', {
        sub: 1,
        qi: item.Id,
      });
    });
    dispatch(setClearDraw(true));
    // dispatch(submitEventAssignment(eventSubmissionID));
  };
  const renderErrorModal = () => {
    if (!isPending) {
      if (isErr || checkAnswerError || assignmentEventSubmissionError) {
        return (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={
              checkAnswerError || assignmentEventSubmissionError || isErr
            }
          />
        );
      }
    }
  };

  if (!isPending && isEmpty(allQsAns)) {
    return (
      <ErrorModal
        backPage="/dashboard"
        errorMessage={t(
          'assignmentQnView:errors.noQuestion',
          'No question retrieved'
        )}
      />
    );
  }

  return (
    <Container>
      <ModalVideo
        channel="youtube"
        isOpen={showVideo}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={allQs[activeQn - 1]?.VideoCode}
        onClose={() => setShowVideo(false)}
      />
      {assignmentType !== 'homework' && assignmentType !== 'sundayMC' && (
        <PopupModal show={showTimesUpModal} backdrop="static">
          <TimeUpModalBody />
        </PopupModal>
      )}
      {assignmentType === 'sundayMC' && (
        <PopupModal show={showEventTimesUpModal} backdrop="static">
          <TimeUpEventModalBody />
        </PopupModal>
      )}
      <PopupModal show={submitModal} backdrop="static">
        <SubmitModal
          type={t('sundayMCQnVIew:submitModal.important', 'Important!')}
          header={t(
            'sundayMCQnVIew:submitModal.submitChallenge',
            'Submit Challenge'
          )}
          title={t(
            'sundayMCQnVIew:submitModal.confirmSubmit',
            'Are you sure you want to submit now?'
          )}
          desc={t(
            'sundayMCQnVIew:submitModal.submitDesc',
            'Please make sure you do not leave any question(s) unanswered. You will not be able to do change your answers after submission.'
          )}
          noHandle={closeModal}
          yesHandle={submitAssignmentModal}
          loading={isEventSubmittingLoading}
          isV2Options={eventsQs}
        />
      </PopupModal>
      <PopupModal show={finishModal} backdrop="static">
        <SubmitModal
          type={t('assignmentQnView:finishModal.important', 'Important!')}
          header={t(
            'assignmentQnView:finishModal.confirmSubmission',
            'Confirm Submission'
          )}
          title={t(
            'assignmentQnView:finishModal.confirmSubmit',
            'Are you sure you want to submit?'
          )}
          desc={t(
            'assignmentQnView:finishModal.submitDesc',
            'Please make sure you do not leave any question(s) unanswered.'
          )}
          noHandle={closeModal}
          yesHandle={finishAssignmentModal}
          isV2Options={eventsQs}
        />
      </PopupModal>
      <Canvas
        bgColor="rgb(165, 255, 246, 0.21)"
        feature={eventsQs ? 'sunday-mini-challenge' : 'assignment'}
        showSideBar={!isMobileOrTablet}
      >
        <AssignmentHeader
          assignmentTitle={
            eventsQs
              ? t('assignmentQnView:header.smc', 'Sunday Mini Challenge')
              : startAssignmentInfo.Title
          }
          assignmentId={eventsQs ? null : startAssignmentInfo.Id}
          assignmentType={assignmentType}
          questions={allQs}
          submissions={allSubmission}
          submissionType={submissionType}
          submissionId={submissionId}
          activeQn={activeQn}
          setActiveQn={setActive}
          currentSavedAnswer={savedAns[activeQn - 1]}
          currentLocalSavedAnswer={localSavAns[activeQn - 1]}
          currentAnswerKeys={allQsAns[activeQn - 1]}
          localSavedAnswers={localSavAns}
          localSavedWorkings={localSavWorkings}
          openModal={openModal}
          isLoading={isPending}
          isError={isErr}
        />
        {!isMobileOrTablet && !isPending && isErr === null && (
          <QnSideBar
            assignmentType={assignmentType}
            localSavedAnswers={localSavAns}
            questions={allQs}
            activeQuestion={activeQn}
            answersEqualsChecker={answersEqualsChecker}
          />
        )}
        <Main assignmentType={assignmentType}>
          <NotebookContainer>
            <Notebook>
              <NoteBookTop>
                {!isPending && isErr === null && (
                  <>
                    <div className="text">
                      <QnText>Question</QnText>
                      <p className="qn-number">{activeQn}</p>
                      <p className="qn-id">
                        {t('assignmentQnView:main.qnId', 'Qn ID')}{' '}
                        {allQs[activeQn - 1].Id}
                      </p>
                    </div>
                    <div className="action-btn">
                      {showPrevButtonBreakPoint && activeQn > 1 && (
                        <QnButton
                          type="prev"
                          onClick={() => {
                            const now = Date.now();
                            dispatch(setActive(activeQn - 1));
                            saveAnswerBE({ fetchNextQn: true });
                            dispatch(setLastSavedTime(now));
                            dispatch(hideAllTools());
                            dispatch(clearAll());
                            clearValues();
                          }}
                          disabled={saving || checking?.[activeQn - 1]}
                        >
                          <img src={PrevIcon} alt="Previous Qn" />
                          {t('assignmentQnView:main.previous', 'Previous Qn')}
                        </QnButton>
                      )}
                      <QnButton
                        type="next"
                        onClick={() => {
                          if (activeQn < allQs.length) {
                            const now = Date.now();
                            dispatch(setActive(activeQn + 1));
                            saveAnswerBE({ fetchNextQn: true });
                            dispatch(setLastSavedTime(now));
                            dispatch(hideAllTools());
                            dispatch(clearAll());
                            clearValues();
                            dispatch(setClearDraw(true));
                          } else {
                            openModal();
                          }
                        }}
                        disabled={saving || checking?.[activeQn - 1]}
                      >
                        {activeQn === allQs.length ? (
                          t('assignmentQnView:header.submit', 'Submit')
                        ) : (
                          <>
                            {isMobile
                              ? t('assignmentQnView:main.next2', 'Next')
                              : t(
                                  'assignmentQnView:main.next',
                                  'Next Question'
                                )}{' '}
                            <img src={NextIcon} alt="Next Question" />
                          </>
                        )}
                      </QnButton>
                    </div>
                  </>
                )}
              </NoteBookTop>
              <NotebookMiddle id="note-book">
                {isPending && (
                  <Spinner
                    color="#00A49A"
                    top="35%"
                    position="absolute"
                    left="52%"
                  />
                )}
                {renderErrorModal()}
                {!isPending && isErr === null && (
                  <QuestionContent
                    page={
                      assignmentType === 'sundayMC'
                        ? assignmentType
                        : 'assignment'
                    }
                    submissionType={submissionType}
                    questionType={
                      questionTypeIDs[allQs[activeQn - 1].QuestionType]
                    }
                    qnID={allQs[activeQn - 1].Id}
                    xml={
                      allSubmission[activeQn - 1].SubmissionModel !== null
                        ? isNil(
                            allSubmission[activeQn - 1].SubmissionModel.Payload
                          )
                          ? allQs[activeQn - 1].Payload
                          : allSubmission[activeQn - 1].SubmissionModel.Payload
                        : allQs[activeQn - 1].Payload
                    }
                    showCheckAnswer={assignmentType === 'homework'}
                    showSolution={false}
                    showSubmissions={
                      allSubmission[activeQn - 1].SubmissionModel !== null
                    }
                    assignmentType={assignmentType}
                    setShowVideoModal={setShowVideo}
                    videoUrl={allQs[activeQn - 1]?.VideoCode}
                  />
                )}
              </NotebookMiddle>
              <NotebookBottom>
                {assignmentType !== 'sundayMC' && (
                  <QuestionFeedbackLink
                    qnID={allSubmission[activeQn - 1]?.Question?.Id}
                    skillID={allSubmission[activeQn - 1]?.Question?.SkillId}
                    margin="0"
                  />
                )}
              </NotebookBottom>
            </Notebook>
          </NotebookContainer>
        </Main>
      </Canvas>
    </Container>
  );
};

export default AssignmentView;
