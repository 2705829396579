import React from 'react';
import { PictureType } from 'components/Assignment';
import { SMCDetails } from '../components';
import { Container } from '../SMCResult/SMCResult.styles';

const Expired = () => {
  return (
    <Container>
      <PictureType type="sundayMC" />
      <SMCDetails status="expired" />
    </Container>
  );
};

export default Expired;
