/**
 *  Only for B2C user,
 *  Validation the level is lock
 * @param {string} levelName - level name
 * @param {number} currentUserLevel - max CHallenge level
 * @returns
 */
export const checkIsLock = (levelName, currentUserLevel) => {
  if (
    (levelName === 'Elementary' && currentUserLevel >= 0) ||
    (levelName === 'Intermediate' && currentUserLevel >= 3) ||
    (levelName === 'Advanced' && currentUserLevel >= 5) ||
    (levelName === 'Master' && currentUserLevel >= 10)
  ) {
    return false;
  }
  return true;
};
