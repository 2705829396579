import React from 'react';
import useSuperheroChallengeLoader from 'store/superheroChallenge/superheroChallengeLoader';

import Header from 'components/Header';
import { Spinner } from 'components/Shared';
import { SuperHeroBanner } from '../components';
import { Welcome } from './components';

import SuperVisionBanner from 'assets/img/superhero/superspeed-banner.png';

import useDashboard from 'hooks/useDashboard';

import { Content, StyledLoaderWrapper } from './SuperSpeed.style';

function MainSuperSpeedPage() {
  const { loading, superSpeedStatus } = useSuperheroChallengeLoader(
    'superspeed'
  );
  const { isDashboardv2 } = useDashboard();
  return (
    <>
      <Header />
      <SuperHeroBanner
        banner={SuperVisionBanner}
        gradientType="blue"
        bgColor="rgba(0, 115, 214, 0.61)"
        backDestination={isDashboardv2 ? '/superhero' : '/challenges'}
      />
      <Content>
        {loading && (
          <StyledLoaderWrapper>
            <div>
              <Spinner />
            </div>
          </StyledLoaderWrapper>
        )}
        <Welcome disabled={superSpeedStatus !== 3} />
      </Content>
    </>
  );
}

export default React.memo(MainSuperSpeedPage);
