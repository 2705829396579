import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { postCancelSubscription } from 'services/parentSettings';
import { getChildSubs } from 'store/parent/parentSettingsSlice';

import { TRIAL_CANCEL_REASONS, PAID_CANCEL_REASONS } from '../../constants';

import { compareValues } from 'helpers/compareValue';

import { DropdownMenu } from 'components/Shared';

import { Container } from './ReasonSelection.styles';

const ReasonSelection = ({ isTrial, closeModal, paymentSubsId, childId }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState('');
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancellationError, setCancellationError] = useState('');
  const options = (optionArr) =>
    optionArr
      .filter((reason) => reason.isActive === true)
      .sort(compareValues('displayOrder', 'asc'));
  const description = (id) => {
    switch (id) {
      case 1:
        return (
          <div className="text">
            <p>
              <strong>
                Perhaps your child has not seen our interactive animations?
              </strong>{' '}
              These videos are readily available in each question to help your
              child quickly understand math concepts. Many students find these
              animations engaging, fun and easy-to-understand. In fact, some
              students voluntarily seek out these videos to fill their own
              learning gaps!
            </p>
            <p>
              <strong>
                Most students also find it fun after they have built a learning
                habit in KooBits.
              </strong>
              This is done through the Daily Challenge. It’s a quick 20 minute
              assignment that encourages your child to practice math daily. Each
              question is designed around every child’s learning ability, that’s
              why students find Daily Challenge a joy to use every day.
            </p>
          </div>
        );
      case 2:
        return (
          <div className="text">
            <p>
              If your child finds KooBits{' '}
              <strong>
                too easy, you can let your child attempt tougher questions
              </strong>{' '}
              under “Missions” (The more chili padi there is, the harder the
              question.)
            </p>
            <p>
              But if it’s too difficult? You can choose easier questions under
              “Missions” as well. This helps to build confidence in your child,
              and motivate them to continue solving math problems. Before you
              know it, they will be able to solve even the hardest questions in
              KooBits!
            </p>
          </div>
        );
      case 3:
        return (
          <div className="text">
            <p>
              <strong>
                The standard plan works out to be a mere $0.46 a day, or a cup
                of kopi a day.
              </strong>
              Compared to other options, KooBits is much more affordable. In
              fact, to match the giant question database in KooBits, you’d have
              to buy over 700 assessment books. That is more than 10X the cost
              of our yearly subscription!
            </p>
          </div>
        );
      case 4:
        return (
          <textarea
            className="comment-field"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Please write your comment here."
          />
        );
      case 5:
        return (
          <div className="text">
            <p>
              Premium plan gives you extra features NOT available in the school
              account. For example, KooClass, top school exam questions and
              Higher-Order Thinking Skills questions. These are excellent ways
              to add more variety to your child’s education. Would you like to
              give them a try again?
            </p>
          </div>
        );
      case 6:
        return (
          <div className="text">
            <p>
              Could you let us know more about it? You can send email to{' '}
              <a href="mailto:support@koobits.com">support@koobits.com</a> or
              WhatsApp us at + 65 8484 6624. Once we have more information,
              we’ll do our best to give you a smoother KooBits experience.
            </p>
          </div>
        );
      case 7:
        return (
          <div className="text">
            <p>
              We offer a yearly subscription because it encourages consistent
              usage. Instead of using KooBits during peak periods (for example,
              before exams), we want students to build a practice habit every
              month. This reduces stress due to last-minute exam preparation. A
              yearly subscription helps motivate our users to continuously use
              KooBits throughout the year.
            </p>
          </div>
        );
      case 8:
        return (
          <div className="text">
            <p>
              If it is too difficult, select a more appropriate level under
              “Missions”. This helps to build confidence in your child, and
              motivate them to continue solving math problems. Before you know
              it, they will be able to solve even the hardest questions in
              KooBits. Would you like to give it a try?
            </p>
          </div>
        );
      case 9:
        return (
          <div className="text">
            <p>
              If the questions are too easy, let your child attempt tougher
              questions under “Missions”. Pick questions with more chilis. The
              more chilis there are, the harder the question. Would you like to
              give it a try?
            </p>
          </div>
        );
      case 10:
        return (
          <div className="text">
            <p>
              KooBits uses Singapore’s MOE math syllabus. It’s globally
              recognised as one of the best in the world. The longer your child
              uses it, the more he or she will benefit from the syllabus. Would
              you like to give it a try again?
            </p>
          </div>
        );
      case 11:
        return (
          <div className="text">
            <p>
              As busy parents ourselves, we understand this is a problem! You
              may sign up for another trial using another email to give KooBits
              another try.
            </p>
          </div>
        );
      case 12:
        return (
          <div className="text">
            <p>
              Could you let us know which features you have questions about? You
              can let us know through email (
              <a href="mailto:support@koobits.com">support@koobits.com</a>) or
              WhatsApp us at + 65 8484 6624. If you prefer, you can request for
              a chat with our education advisor, who is more than happy to
              answer your questions.
            </p>
          </div>
        );
      case 13:
        return (
          <div className="text">
            <p>
              Could you let us know which features you have questions about? You
              can let us know through email (
              <a href="mailto:support@koobits.com">support@koobits.com</a>) or
              WhatsApp us at + 65 8484 6624. We’re happy to guide you!
            </p>
          </div>
        );
      case 14:
        return (
          <div className="text">
            <p>
              Did you know that we can merge your school and individual Premium
              account?
            </p>
            <ul>
              <li>
                Your child can access all functions from a single account.
              </li>
              <li>
                Choose to retain data from either School or Premium Account.
              </li>
              <li>
                Entitled to a discount coupon upon renewal of Maths or Bundle
                plan (
                <a
                  href="https://www.koobits.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  contact our support team
                </a>{' '}
                to get the coupon)
              </li>
            </ul>
            <p>
              <u>How is KooBits School different from the Premium Plans?</u>
            </p>
            <p>
              KooBits School is built to help teachers assign homework and
              monitor class progress.
            </p>
            <p>
              The individual Premium plan is designed to help kids become
              self-directed learners. It has important resources such as
              top-school exam papers, fun classes on real-life applications,
              exciting parent-child games, and mobile access to empower children
              to take charge of their own learning, while at home or on-the-go.
            </p>
            <p>
              If you want to merge accounts instead, please click on “Keep
              Auto-renewal” and{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our team
              </a>{' '}
              who will process your request ASAP.
            </p>
          </div>
        );
      case 15:
        return (
          <div className="text">
            <p>
              We understand that you would like to maintain control over the
              decision to continue KooBits for another year.
            </p>
            <p>
              By remaining on Auto-renew, you will be entitled to the best rate
              for renewal, even if prices were to change during your
              subscription period.
            </p>
            <p>
              We send 3 reminders at 30, 7 and 3 days before your account
              expires so that you have ample time to consider and make your
              decision when the time comes.
            </p>
            <p>Would you like to remain on auto-renew?</p>
          </div>
        );
      case 16:
        return (
          <div className="text">
            <p>Thank you for staying with us!</p>
            <p>
              Some customers like to retain their subscription to revise
              foundational concepts when they go to the next grade level. If you
              would like to retain your subscription, please click on “Keep
              Auto-renewal”.{' '}
            </p>
            <p>
              We wish your child all the best in the next phase of their
              journey!
            </p>
          </div>
        );
      case 17:
        return (
          <div className="text">
            <p>
              We’re sorry to hear that KooBits did not help your child to
              improve.{' '}
            </p>
            <p>
              We would love to offer you a complimentary call with our education
              consultant who can guide you through the best ways to use KooBits
              to ensure your child improves.
            </p>
            <p>
              If you’d like to schedule a call, please click on “Keep
              Auto-renewal” and{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our team
              </a>{' '}
              who will process your request ASAP.
            </p>
          </div>
        );
      case 18:
        return (
          <div className="text">
            <p>
              We’re sorry to hear that your child was not motivated to use
              KooBits on their own.{' '}
            </p>
            <p>
              Just like adults, building a habit goes through ups and downs. We
              would love to offer you a complimentary call with our education
              consultant who can guide you through actionable strategies to help
              your child stay motivated to learn.{' '}
            </p>
            <p>
              If you’d like to schedule a call, please click on “Keep
              Auto-renewal” and{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our team
              </a>{' '}
              who will process your request ASAP.
            </p>
          </div>
        );
      case 19:
        return (
          <div className="text">
            <p>We’re sorry to hear that you found KooBits difficult to use. </p>
            <p>
              We would love to offer you a complimentary call with our education
              consultant who can understand your specific needs and guide you
              through the best ways to use KooBits.{' '}
            </p>
            <p>
              If you’d like to schedule a call, please click on “Keep
              Auto-renewal” and{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our team
              </a>{' '}
              who will process your request ASAP.
            </p>
          </div>
        );
      case 20:
        return (
          <div className="text">
            <p>We’re sorry to hear that you find KooBits too expensive. </p>
            <p>
              We aim to keep our services affordable while continuously updating
              our features and content.{' '}
            </p>
            <p>
              As a renewing customer, staying on Auto-renew also ensures that
              you automatically get our best rates of less than S$16/month.{' '}
            </p>
            <p>
              If you find that KooBits did not help your child, we’d love to
              offer you a complementary call with our education consultant to
              understand your specific needs and guide you through the best ways
              to use KooBits.{' '}
            </p>
            <p>
              If you’d like to schedule a call, please click on “Keep
              Auto-renewal” and{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our team
              </a>{' '}
              who will process your request ASAP.
            </p>
          </div>
        );
      case 21:
        return (
          <div className="text">
            <p>
              We would love to offer you a call with our education consultant or
              support team to quickly rectify any curriculum-related or
              technical issues for your specific case.{' '}
            </p>
            <p>
              If you’d like to schedule a call, please click on “Keep
              Auto-renewal” and{' '}
              <a
                href="https://www.koobits.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact our team
              </a>{' '}
              who will process your request ASAP.
            </p>
          </div>
        );
      default:
        return '';
    }
  };

  // Event Handlers
  const handleCancel = async () => {
    // Validate if reason remarks is provided
    if (reason.id === 4 && comment.trim().length <= 0) {
      setCancellationError('Please fill in the Others reason');
      return;
    }
    setIsCancelling(true);
    setCancellationError('');
    try {
      const body = {
        paymentSubscriptionId: paymentSubsId,
        reasonId: reason.id,
        remark: reason.id === 4 ? comment.trim() : '',
      };
      await postCancelSubscription(body);
      dispatch(getChildSubs(childId));
      closeModal();
    } catch (err) {
      setCancellationError(err.message);
    }
    setIsCancelling(false);
  };
  return (
    <Container>
      <DropdownMenu
        selectedValue={
          reason === null ? 'Please choose the reason' : reason.option
        }
        values={options(isTrial ? TRIAL_CANCEL_REASONS : PAID_CANCEL_REASONS)}
        setValue={setReason}
        valueKey="option"
        name="reasonInput"
        width="100%"
        indexKey="id"
        borderColor="#DDDDDD"
        bgColor="#ffffff"
        containerWidth="100%"
        maxWidth="100%"
      />
      <div className="desc">
        {description(reason && reason.id)}
        {isTrial && reason !== null && (
          <div className="text">
            <strong>Would you like to give KooBits a try again?</strong>
          </div>
        )}
      </div>
      {reason !== null && (
        <div className="btn-container">
          <p className="error">{cancellationError}</p>
          <button
            className="btn orange"
            onClick={closeModal}
            type="button"
            disabled={isCancelling}
          >
            {isTrial
              ? 'I’ve changed my mind about cancelling'
              : 'Keep Auto-renewal'}
          </button>
          <button
            className="btn white"
            onClick={handleCancel}
            disabled={isCancelling}
            type="button"
          >
            {isTrial ? 'Proceed with my cancellation →' : 'Continue to cancel'}
          </button>
        </div>
      )}
    </Container>
  );
};

export default ReasonSelection;
