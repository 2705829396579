import styled from 'styled-components';

export const Page = styled.div`
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: rgb(255, 252, 230);
`;
export const StyledDesc = styled.div`
  position: relative;
  max-width: 892px;
  width: 100%;
  left: -6%;
  svg {
    position: absolute;
    right: -17%;
    bottom: 0;
  }
  .desc {
    padding: 52px 83px 43px;
    background: white;
    border-radius: 20px;
    p {
      margin-bottom: 0;
      font-size: 22px;
    }
  }
`;
export const StyledDashboard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
StyledDashboard.Item = styled.div`
  position: relative;

  img {
    max-width: 369px;
    width: 100%;
    box-shadow: ${({ subject }) =>
      subject === 'English' ? '0 3px 6px rgba(0, 0, 0, 0.16)' : 'none'};
  }
  p {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 12px;
    background: rgb(254, 252, 230);
    background: linear-gradient(
      0deg,
      rgba(254, 252, 230, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;
