import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  peerChallengeResult,
  submissionTypeIDs,
  questionTypeIDs,
} from 'constants/index';
import { peerChallengeResultStatus } from 'constants/multiplayer';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';

import QuestionContent from 'components/Question';
import QnText from 'components/Assignment/AssignmentView/QnText';

import { Button, ConfettiWrapper } from 'components/Shared';
import ResultBoard from '../ResultBoard';

import {
  Container,
  Result,
  QuestionContainer,
  ContinueSection,
} from './PeerChallengeResultNoteBookMiddle.styles';

// Helper
const getCurrentUserData = (data, currentUserID) => {
  if (!data?.Challengers) return {};
  const currentUserData = data.Challengers.find(
    (challenger) => challenger.UserId === currentUserID
  );
  if (!currentUserData) return {};
  return currentUserData;
};

const PeerChallengeResultNoteBookMiddle = ({
  data,
  userID,
  page,
  subjectName,
  firstTry,
}) => {
  const { t } = useTranslation(['peerChallengeQnView', 'assignmentQnView']);
  const history = useHistory();
  const currentUserChallengerData = getCurrentUserData(data, userID);
  const resultValue = peerChallengeResult[currentUserChallengerData.Result];
  const submissionType = submissionTypeIDs.PeerChallenge;

  // responsive
  const qnTextSize = useResponsiveValue({
    desktop: '45px',
    mobile: '28px',
    tablet: '38px',
  });
  const qnTextMargin = useResponsiveValue({
    desktop: '-3.9rem',
    mobile: '-2.6rem',
    tablet: '-3.3rem',
  });

  const redirectToChallengeList = () =>
    history.replace(
      `/multiplayer/peer-challenge/new-challenge?subject=${subjectName}`
    );

  return (
    <>
      {resultValue === 'win' && firstTry && <ConfettiWrapper />}
      <Container data-cy="result-container">
        {data && (
          <>
            <Result result={resultValue}>
              <QnText
                text={t(
                  `peerChallengeQnView:result.${resultValue}`,
                  `${peerChallengeResultStatus[resultValue]?.text ?? ''}`
                )}
                color={peerChallengeResultStatus[resultValue]?.color}
                borderColor={peerChallengeResultStatus[resultValue]?.color}
                fontSize={qnTextSize}
                margin={qnTextMargin}
              />
            </Result>
            <ResultBoard
              data={data}
              userID={userID}
              subjectName={subjectName}
            />
            <ContinueSection>
              <Button
                dataCy="continue-btn"
                width="135px"
                onClick={redirectToChallengeList}
                variant="primary"
              >
                {t('peerChallengeQnView:result.continue')}
              </Button>
              <p />
            </ContinueSection>
            {data.UserQuestionSubmissions.map((qn, index) => (
              <QuestionContainer
                key={qn.Question.Id}
                isLastQn={data.UserQuestionSubmissions.length === index + 1}
              >
                <p className="qn-no">
                  {t('peerChallengeQnView:main.qn', 'Question')} {index + 1}
                  <span className="qn-id">
                    ({t('assignmentQnView:main.qnId', 'Qn ID')}{' '}
                    {qn && qn.Question.Id})
                  </span>
                </p>
                <QuestionContent
                  page={
                    page === 'peer-challenge-result'
                      ? 'solution'
                      : 'peer-challenge'
                  }
                  submissionType={submissionType}
                  questionType={questionTypeIDs[qn.Question.QuestionType]}
                  qnID={qn.Question.Id}
                  xml={qn.SubmissionModel.Payload}
                  submissionModel={qn.SubmissionModel}
                  solutionString={qn.SubmissionModel.Solution}
                  showSolution
                  showSubmissions
                  qnIndex={index}
                  assignmentType="peer-challenge"
                  showWorking
                  skillID={qn.Question.SkillId}
                  showFeedbackButton
                />
              </QuestionContainer>
            ))}
          </>
        )}
      </Container>
    </>
  );
};

export default PeerChallengeResultNoteBookMiddle;
