import React from 'react';
import Timer from 'components/Assignment/Timer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TimerText } from './AssignmentHeader.styles';

const ONE_DAY_IN_MILLISECONDS = 86400000;

const TimerDisplay = ({ assignmentType, styles = {} }) => {
  const { t } = useTranslation(['common', 'assignmentQnView']);
  const { timeLeft } = useSelector((state) => state.timer);
  const { startAssignmentDuration, startAssignmentInfo } = useSelector(
    (state) => state.assignment
  );
  const { startEventInfo } = useSelector((state) => state.event);
  const assignmentDeadline =
    assignmentType === 'sundayMC'
      ? startEventInfo?.EventEndDate
      : startAssignmentInfo?.EndDate;
  const isMockOrTest =
    assignmentType === 'mocktest' || assignmentType === 'test';
  if (assignmentType === 'sundayMC') {
    return (
      <Timer
        type="event-card"
        isCountdown
        isInvisible={false}
        endTime={startEventInfo?.EventEndDate}
        useTimezone
      />
    );
  }
  if (assignmentType === 'homework') {
    return (
      <span style={styles}>
        <TimerText>
          {t('assignmentQnView:header.totalTime', 'Total Time')}
        </TimerText>{' '}
        <Timer
          startOffset={startAssignmentDuration}
          isCountdown={false}
          isInvisible={false}
        />
      </span>
    );
  }
  if (isMockOrTest && timeLeft <= ONE_DAY_IN_MILLISECONDS) {
    return (
      <span style={styles}>
        <TimerText>
          {t('assignmentQnView:header.timeLeft', 'Time Left')}
        </TimerText>{' '}
        <Timer
          startOffset={startAssignmentDuration}
          isCountdown
          isInvisible={false}
        />
      </span>
    );
  }
  if (isMockOrTest && timeLeft > ONE_DAY_IN_MILLISECONDS) {
    return (
      <span style={styles}>
        <TimerText>
          {t('assignmentQnView:header.deadline', 'Deadline')}
        </TimerText>
        {` ${moment(assignmentDeadline).format('DD/MM/YYYY, hh:mm a')}`}
        <Timer
          startOffset={startAssignmentDuration}
          isCountdown={false}
          isInvisible
        />
      </span>
    );
  }
  return null;
};

export default TimerDisplay;
