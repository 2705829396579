import styled from 'styled-components';
import { featureToggle } from 'constants/index';

export const HeaderNav = styled.nav`
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 59px;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  background-color: #4a4947;
  box-shadow: 0px 1px 4px rgba(#000, 0.16);
  z-index: 100;
  align-items: center;
  font-family: ${({ isParentAccount }) => isParentAccount && 'Muli'};
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  .navbar-brand {
    display: flex;
    align-items: ${featureToggle.science ? 'center' : 'flex-end'};
    .logo {
      cursor: pointer;
      @media (max-width: 768px) {
        width: 63px;
      }
    }
  }
  .parent-portal {
    color: #ffab64;
    font-family: 'Linotte';
    font-size: 15px;
    margin-top: 1rem;
    margin-left: 0.4rem;
    @media (max-width: 768px) {
      margin-top: 0.5rem;
    }
  }
`;
