import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as School } from 'assets/img/plan-toggle/icon-school.svg';
import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';

import { PRODUCT_TYPE, subjectIDs } from 'constants/products';
import { StyledToggle, Arrow } from './ToggleV2.styles';

const ToggleBtn = forwardRef(({ onClick, plan, subjectId }, ref) => {
  const { t } = useTranslation(['common']);

  const subjectName = subjectIDs[subjectId];
  return (
    <StyledToggle
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {plan === PRODUCT_TYPE.SCHOOL ? (
        <School width="76px" height="23px" />
      ) : (
        <Premium width="76px" height="23px" />
      )}
      {plan !== PRODUCT_TYPE.SCHOOL && (
        <p className="subject-name">
          {t(`common:subjects.${subjectName}`, subjectName)}
        </p>
      )}
      <Arrow subjectId={subjectId} />
    </StyledToggle>
  );
});
ToggleBtn.displayName = 'ToggleBtn';
export default ToggleBtn;
