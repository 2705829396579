import React, { useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty } from 'ramda';

import currencyLocaleFormatter from 'helpers/currencyLocaleFormatter';
import { Overlay } from 'react-bootstrap';

import { Button } from 'components/Shared';
import { ReactComponent as IconInfo } from 'assets/img/trial/icon-info.svg';

import {
  StyledOrderSummary,
  StyledOriginalPrice,
  StyledDiscountPrice,
  StyledTooltip,
  SubTotal,
  StyledTax,
  StyledGrandTotal,
} from './Summary.styles';

const Summary = ({
  data,
  submitHandler,
  noOfSubject,
  everGreenDiscount,
  b2bCoupon,
  remainingAmount,
  hasTrial,
}) => {
  const { t } = useTranslation(['trial', 'common']);
  const { timezone } = useSelector((state) => state.login);
  const ref = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  return (
    <StyledOrderSummary>
      <h6 className="title">
        {t('trial:results.title', 'Your Order Summary')}
      </h6>
      <StyledOriginalPrice>
        <div className="base-prices">
          {data?.singleSubjectPrices?.map((item) => {
            return (
              <div key={item.subjectName} className="price-item">
                <p className="price-subject">
                  {t(
                    'trial:results.subjects',
                    { subjectName: item.subjectName },
                    `${item.subjectName} Premium Annual Price`
                  )}
                </p>
                <p className="price-amount">
                  {data?.currency}{' '}
                  {
                    currencyLocaleFormatter(data?.currency, item.basePrice)
                      .formattedString
                  }
                </p>
              </div>
            );
          })}
        </div>
        <SubTotal>
          {data?.currency}{' '}
          {
            currencyLocaleFormatter(data?.currency, data?.basePrice)
              .formattedString
          }
        </SubTotal>
      </StyledOriginalPrice>
      <StyledDiscountPrice>
        <div className="discount-prices">
          {everGreenDiscount && (
            <div className="discount-breakdown">
              <p>
                {noOfSubject} {t('trial:results.discount', 'subjects discount')}{' '}
                <span className="orange">{everGreenDiscount?.description}</span>
              </p>
              <p className="orange discount-amount">
                - {data?.currency}{' '}
                {
                  currencyLocaleFormatter(
                    data?.currency,
                    everGreenDiscount.amount
                  ).formattedString
                }
                {everGreenDiscount.isHighestDiscount && (
                  <span className="orange">
                    {t('trial:results.highest', `Highest renewal discount`)}
                  </span>
                )}
              </p>
            </div>
          )}
          {remainingAmount && (
            <div className="discount-breakdown">
              <p>
                {t(
                  'trial:results.remaining',
                  'Unused amount from existing sub.'
                )}
              </p>
              <div className="orange existing-amount">
                <IconInfo
                  ref={ref}
                  className="info"
                  onClick={() => setShowInfo(true)}
                />
                <Overlay
                  target={ref.current}
                  show={showInfo}
                  rootClose
                  placement="top"
                  onHide={() => setShowInfo(false)}
                >
                  <StyledTooltip>
                    {remainingAmount.prorationItems.map((subject) => (
                      <div key={subject.bundleId} className="desc">
                        <p className="bold">
                          {subject.bundleName} {t('common:plan', 'Plan')}:
                        </p>
                        <Trans i18nKey="trial:results.expired">
                          Expires on{' '}
                          {{
                            date: moment(subject.expiryDate).format(
                              'DD/MMM/YYYY'
                            ),
                          }}{' '}
                          <span className="bold">
                            ({' '}
                            {{
                              days: subject.remainingDays,
                            }}{' '}
                            days left )
                          </span>
                        </Trans>
                      </div>
                    ))}
                    <a
                      className="detail"
                      href="https://support.koobits.com/hc/en-gb/articles/33602006171161-Understanding-how-value-of-unused-amount-is-calculated"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('trial:results.view', 'View details')}
                    </a>
                  </StyledTooltip>
                </Overlay>
                - {data?.currency}{' '}
                {
                  currencyLocaleFormatter(
                    data?.currency,
                    remainingAmount.amount
                  ).formattedString
                }
              </div>
            </div>
          )}
          {b2bCoupon && (
            <div className="discount-breakdown">
              <p>{t('trial:results.schoolCoupon', 'School User Coupon')}</p>
              <p className="orange discount-amount">
                - {data?.currency}{' '}
                {
                  currencyLocaleFormatter(data?.currency, b2bCoupon.amount)
                    .formattedString
                }
              </p>
            </div>
          )}
        </div>
        <SubTotal>
          {data?.currency}{' '}
          {
            currencyLocaleFormatter(data?.currency, data?.subTotal)
              .formattedString
          }
        </SubTotal>
      </StyledDiscountPrice>
      {data?.taxAmount > 0 && (
        <StyledTax>
          <p>{t('trial:results.tax', 'Applicable tax')}</p>
          <p className="tax">{`${data?.currency} ${
            currencyLocaleFormatter(data?.currency, data?.taxAmount)
              .formattedString
          }`}</p>
        </StyledTax>
      )}
      <StyledGrandTotal>
        <div className="total-price">
          {t('trial:results.todayTotal', "Today's total")}{' '}
          <span className="orange">{data?.currency} </span>
          <span className="orange amount">
            {
              currencyLocaleFormatter(data?.currency, data?.total)
                .formattedString
            }
          </span>
        </div>
        <div>
          <p className="start-date">
            <Trans i18nKey="trial:results.start">
              New subscription will start from{' '}
              <span>
                today to{' '}
                {{
                  date: !isEmpty(timezone)
                    ? moment(data?.newSubscriptionEndDate)
                        .tz(timezone)
                        .format('DD MMM YYYY')
                    : moment(data?.newSubscriptionEndDate).format(
                        'DD MMM YYYY'
                      ),
                }}
              </span>
            </Trans>
          </p>
          {hasTrial && (
            <p className="trial-notice">
              {t(
                'trial:results.trialNote',
                '*The trial will be automatically cancelled after payment.'
              )}
            </p>
          )}
        </div>
        <Button
          variant="primary"
          width="163px"
          borderRadius="2px"
          fontSize="16px"
          onClick={submitHandler}
        >
          {t('trial:results.proceed', 'Proceed')}
        </Button>
      </StyledGrandTotal>
    </StyledOrderSummary>
  );
};

export default Summary;
