import store from 'store/index';
import { history } from 'Routes';
import { isNil } from 'ramda';
import Cookies from 'js-cookie';

export default (redirect) => {
  // clear sessionStorage
  sessionStorage.clear();
  // clear tokens from localStorage
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('language');
  localStorage.removeItem('pcRandomBot');
  Cookies.remove('sp_access_token', {
    path: '/',
    domain: 'koobits.com',
  });
  Cookies.remove('sp_refresh_token', {
    path: '/',
    domain: 'koobits.com',
  });

  store.dispatch({ type: 'reset-store' });
  // logout by navigate to login screen
  let loginRoute =
    process.env.REACT_APP_USE_LOCAL_LOGIN === 'true' ||
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? '/login'
      : '/members-login';
  if (!isNil(redirect)) {
    loginRoute += `?redirect=${redirect}`;
  }
  // add callback for flutter app logout
  const hasFlutter = window.flutter_inappwebview;
  if (hasFlutter) hasFlutter.callHandler('logOutFunc');
  history.push(loginRoute);
};
