import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  background: #fffef7;
  width: 882px;
  display: flex;
  border-radius: 8px;
  min-height: 389px;
  margin: 0 auto;
  position: relative;
  align-items: ${({ isLoading }) => (isLoading ? 'center' : 'stretch')};
  .close-trial {
    position: absolute;
    top: -22px;
    right: -24px;
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    width: auto;
  }
  @media (max-width: 1185px) {
    ${({ isPopup }) =>
      !isPopup &&
      css`
        display: flex;
        flex-direction: column;
      `}
  }
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSelection = styled.div`
  padding: 18px 32px 30px;
  font-weight: bold;
  flex-grow: 1;
  font-family: 'Muli';
  display: flex;
  flex-direction: column;
  .title {
    font-weight: bold;
    margin-bottom: 0;
  }
  .skeleton-container {
    height: 88.5px;
  }
  .coupon {
    background: #ffeddb;
    border-radius: 8px;
    font-size: 11px;
    font-weight: 700;
    padding: 6px 20px 6px 8px;
    margin: auto 0 0;
    width: fit-content;
  }
  .desc-not-selected {
    color: #606060;
    margin-bottom: 14px;
    font-weight: 500;
    font-size: 12px;
  }
  .new-label {
    background: #ffc619;
    border-radius: 6px;
    font-weight: 600;
    color: #000000;
    font-size: 11px;
    width: fit-content;
    padding: 0.25rem 0.8rem;
    margin-bottom: 0.5rem;
  }
`;

export const StyledSubject = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const SUBJECT_COLORS = {
  math: {
    background: '#A48CF0',
    border: '#8F66FC',
    desc: '#38149A',
  },
  science: {
    background: '#00D089',
    border: '#1AAC7A',
    desc: '#006543',
  },
  english: {
    background: '#FEC404',
    border: '#D9A700',
    desc: '#AA5C00',
  },
};
StyledSubject.Item = styled.div`
  border: 1px solid ${({ checked }) => (checked ? '#FF5C02' : '#c6c6c6')};
  box-shadow: 0 0 0 1px
    ${({ checked }) => (checked ? '#FF5C02' : 'transparent')};
  border-radius: 10px;
  width: 135px;
  text-align: center;
  overflow: hidden;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  .top {
    text-align: left;
    position: relative;
    .existing {
      position: absolute;
      font-size: 12px;
      top: 0;
      right: 0;
      background: #ff5c02;
      color: white;
      padding: 2px 6px 4px;
      line-height: 1;
      border-bottom-left-radius: 10px;
    }
    .checkbox-container {
      display: inline-block;
    }
    .container-checkbox {
      input {
        cursor: inherit;
        &:disabled ~ .checkmark {
          background-color: #d6d6d6;
          border-color: #afafaf;
          cursor: inherit;
        }
      }
      .checkmark {
        border-radius: 4px;
        :after {
          width: 8px;
          height: 14px;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  .bottom {
    background: ${({ subject }) => SUBJECT_COLORS[subject].background};
    border: 1px solid ${({ subject }) => SUBJECT_COLORS[subject].border};
    padding: 8px 0 12px;
    .title {
      font-size: 1rem;
      color: white;
      margin-bottom: 2px;
      font-family: 'Muli';
      font-weight: 900;
      text-shadow: 0 1px rgba(49, 34, 34, 0.36);
    }
    .subject-item-desc {
      color: ${({ subject }) => SUBJECT_COLORS[subject].desc};
      font-size: 12px;
      font-family: Linotte;
      font-weight: bold;
      margin-bottom: 3px;
    }
    .extend {
      color: #ffda3e;
      font-size: 17px;
      font-weight: 900;
      background: rgba(26, 26, 26, 0.61);
      position: absolute;
      height: 66px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 134px;
      margin-left: -1px;
    }
  }
`;

export const StyledResult = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-shrink: 0;
  border-bottom-right-radius: 8px;
  border-tops-right-radius: 8px;
  padding: ${({ isResult }) =>
    isResult ? `32px 30px 38px 32px` : '1rem 1.5rem'};
  @media (max-width: 1185px) {
    ${({ isPopup }) =>
      !isPopup &&
      css`
        width: 100%;
      `}
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const StyledFloatBtn = styled.div`
  position: absolute;
  bottom: ${({ isPopup }) => (isPopup ? '-4.5rem' : '40%')};
  right: ${({ isPopup }) => (isPopup ? '0rem' : '-10rem')};
  @media (max-width: 1185px) {
    position: ${({ isPopup }) => (isPopup ? 'absolute' : 'fixed')};
    right: ${({ isPopup }) => (isPopup ? '0rem' : '0.8rem')};
    bottom: ${({ isPopup }) => (isPopup ? '4-4.5rem' : '1rem')};
  }
`;
