import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 852px;
  height: 420px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: ${({ background }) => `url('${background}')`};
`;

export const HeaderContainer = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: #89cff0;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 16px;
  font-family: Arial, sans-serif;
  max-width: 500px;
  color: #282828;
  line-height: 1.5;
  margin-bottom: 28px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > button:first-child {
    margin-right: 18px;
  }
`;

export const Button = styled.button`
  border: none;
  margin: auto;
  border-radius: 24px;
  color: ${({ textColor }) => textColor};
  display: inline-block;
  height: ${({ small }) => (small ? `34px` : `44px`)};
  line-height: ${({ small }) => (small ? `34px` : `44px`)};
  font-size: 22px;
  padding: ${({ small }) => (small ? `0 1em` : `0 2em`)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: Arial, sans-serif;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ textColorHover, textColor }) => textColorHover || textColor};
    background-color: ${({ backgroundColorHover, backgroundColor }) =>
      backgroundColorHover || backgroundColor};
  }
  &[disabled] {
    background-color: #c3c3c3;
    cursor: not-allowed;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: #86d4fa;
  width: 852px;
  height: 45px;
  justify-content: flex-end;
  & > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > .timerDescription {
      font-size: 12px;
      padding: 0px 20px;
      color: #fff;
    }
    & > .timer {
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      padding-right: 20px;
    }
    & > .score {
      display: inline-block;
      width: 50px;
      font-size: 18px;
      text-align: center;
      color: #fff;
      font-weight: 700;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-left: 1px solid #6cc3f0;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
    & > .point {
      height: 26px;
      width: 38px;
    }
  }
`;
