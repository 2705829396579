import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import currencyLocaleFormatter from 'helpers/currencyLocaleFormatter';
import IconHands from 'assets/img/trial/hand.gif';

import {
  StyledContainer,
  StyledPointer,
  StyledPricingExample,
  StyledCoupon,
} from './Bundles.styled';

const Bundles = ({ bundleList, hasActiveSubs, isPopup, isB2B }) => {
  const { t } = useTranslation(['trial', 'common']);

  const priceDisplay = (currency, amount) => {
    return currency?.toLowerCase() === 'idr'
      ? currencyLocaleFormatter(currency, amount).noDecimalFormat
      : amount;
  };

  return (
    <StyledContainer>
      <StyledPointer isPopup={isPopup}>
        <img src={IconHands} alt="" />
        {t(
          'trial:results.orderSummaryPlaceholder',
          'Select the subjects to see your order summary.'
        )}
      </StyledPointer>
      <StyledPricingExample hasExisting={hasActiveSubs.length > 0}>
        {bundleList.map((p) => {
          const originalPrice = priceDisplay(p.currency, p.basePrice);
          if (p.bundleTypeName === '1') {
            return (
              <p key={p.bundleTypeId}>
                {p.bundleTypeName}{' '}
                {t('trial:results.startingAt', 'Subject Start at')}{' '}
                <span className="delete">
                  {p.currency}
                  {originalPrice}
                </span>{' '}
                <span className="discount">{p.discountDisplayText}</span>
              </p>
            );
          }
          return (
            <p key={p.bundleTypeId}>
              <Trans i18nKey="trial:results.orderSummaryExample">
                {{ number: p.bundleTypeName }}-Subject Bundle{' '}
                <span className="delete">
                  {{ currency: p.currency }} {{ original: originalPrice }}
                </span>{' '}
                <span className="discount">
                  {{ discount: p.discountDisplayText }}
                </span>
              </Trans>
            </p>
          );
        })}
      </StyledPricingExample>
      {isB2B && (
        <StyledCoupon>
          <Trans i18nKey="trial:selector.school">
            *School User Coupon will be automatically added if you buy with{' '}
            <span>Math Premium.</span>
          </Trans>
        </StyledCoupon>
      )}
    </StyledContainer>
  );
};

export default Bundles;
