import styled from 'styled-components';
import Bg from './bg-watch-and-learn.svg';
import Bubble from './yellow-bubble.svg';

export const Container = styled.div`
  height: 100%;
  background: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  display: ${(props) => (props.showVideo ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .watch-btn {
    width: 25%;
  }
  .mascot {
    position: absolute;
    left: 10%;
    bottom: 7%;
    width: 20%;
    z-index: 1;
    @media (max-width: 1440px) {
      left: 7%;
      bottom: 10%;
    }
    @media (max-width: 1024px) {
      left: 7%;
      bottom: 10%;
      width: 21%;
    }
  }
`;

export const BubbleContainer = styled.div`
  background: url(${Bubble});
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  z-index: 2;
  .desc {
    color: #0b0b0b;
    margin: 8% 7% 5% 8%;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    max-height: 130px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: 1440px) {
    width: 50%;
    p {
      margin: 8% 7.5% 5% 8%;
    }
  }
  @media (max-width: 1024px) {
    width: 65%;
    height: 35%;
  }
`;
