import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getSuperHeroChallengeStatus,
  getSuperHeroChallengeDataWithoutImage,
} from 'store/dailyChallenge/dailyChallengeSlice';

import { Spinner, BackButton, QnViewHeader } from 'components/Shared';
import { CustomSMCModal, SuperHeroList } from './components';

import HeaderIcon from 'assets/img/superhero/superhero-challenge-header-icon.png';
import HeaderMask from 'assets/img/superhero/superhero-challenge-header-mask.png';
import { Container, Main, Heading } from './IntermediateSuperHero.styles';

const IntermediateSuperHero = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['challengeList', 'common', 'topicSelection']);
  const { timezone } = useSelector((state) => state.login);

  const {
    superHeroChallengeIsLoading,
    superHeroChallengeWithoutImageIsLoading,
  } = useSelector((state) => state.dailyChallenge);

  const isPageLoading =
    superHeroChallengeIsLoading || superHeroChallengeWithoutImageIsLoading;

  useEffect(() => {
    dispatch(getSuperHeroChallengeDataWithoutImage());
    dispatch(getSuperHeroChallengeStatus());
  }, [dispatch]);

  return (
    <Container>
      <QnViewHeader
        page
        bgColor="linear-gradient(to left, #18BCFB, #2EE4FD)"
        headerBg={HeaderMask}
      >
        <BackButton
          backText={t('topicSelection:backBtn', 'Back')}
          btnBgColor="#10CAFF"
          padding="5px 20px"
          onClick={() => history.push('/dashboard')}
          styles={{ position: 'absolute', left: '3%' }}
        />

        <Heading>
          {t('challengeList:SuperHeroChallenge', 'Super Hero Challenge')}
        </Heading>
        <img
          className="super-hero-challenge-icon"
          src={HeaderIcon}
          alt="super-hero-challenge-icon"
        />
      </QnViewHeader>
      <Main>
        {isPageLoading && <Spinner />}
        {!isPageLoading && <CustomSMCModal timezone={timezone} />}
        {!isPageLoading && <SuperHeroList t={t} timezone={timezone} />}
      </Main>
    </Container>
  );
};

export default IntermediateSuperHero;
