import * as React from 'react';
import SideNavIcon from 'assets/img/assignment/assignment-view/icon-sidenav.svg';
import CloseIcon from 'assets/img/assignment/assignment-view/icon-close.png';
import useClickOutside from 'hooks/useClickOutside';
import { SideNav } from 'components/Shared';
import { AssignmentSideNavHelpers } from 'components/Assignment/AssignmentView/AssignmentSideNav';
import {
  SideNavButton,
  CloseButton,
  StyledHeader,
  StyledNavBar,
  HideOnMobile,
} from './SideNavigation.styles';

const SideNavigation = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const sideNavClickHandler = () => setShow((s) => !s);
  const navBarRef = React.useRef();

  const closeSideBar = () => {
    if (show) setShow(false);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setShow, showIcon: false });
    }
    return child;
  });

  useClickOutside(navBarRef, closeSideBar);

  return (
    <>
      <SideNavButton onClick={sideNavClickHandler}>
        <img src={SideNavIcon} alt="Side Nav" />
      </SideNavButton>
      <SideNav show={show}>
        <StyledNavBar ref={navBarRef}>
          <StyledHeader>
            <CloseButton onClick={closeSideBar}>
              <img src={CloseIcon} alt="Close" />
            </CloseButton>
          </StyledHeader>
          <HideOnMobile>
            <AssignmentSideNavHelpers setShow={setShow} />
          </HideOnMobile>
          {childrenWithProps}
        </StyledNavBar>
      </SideNav>
    </>
  );
};

export default SideNavigation;
