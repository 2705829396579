import styled from 'styled-components';
import { iconScale } from 'constants/dashboardv2';

export const StyledContainer = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  cursor: pointer;
  :hover {
    > div {
      transform: ${({ subject }) => `scale(${iconScale[subject]})`};
    }
  }
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .monsterImage {
      height: 209px;
      padding: 0px 8px;
      width: ${({ subject }) => (subject === 'english' ? '101.5%' : '100%')};
    }
  }
`;
export const StyledContent = styled.div`
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
  color: white;
  left: calc(0.02595529 * 100vw);
  display: flex;
`;
export const StyledDescription = styled.div`
  padding-left: 1.2vw;
  padding-top: 1.4vw;
  h3 {
    // font-size 26px,1387px(UI width)  => 26 / 1387 = 0.0187454938
    font-size: calc(0.0187454938 * 100vw);
    margin-bottom: 0.5vw;
    line-height: 1;
    font-weight: 900;
  }
  span {
    // font-size 14px,1387px(UI width) => 14 / 1387 = 0.010093727
    color: #b27824;
    font-weight: 700;
    font-size: calc(0.010093727 * 100vw);
  }
  p {
    margin-bottom: 0;
    font-size: calc(0.0158615717 * 100vw);
    color: black;
    font-weight: 900;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    h3,
    p {
      font-size: 24px;
    }
    span {
      font-size: 14px;
    }
  }
`;
export const StyledKoKoMonster = styled.div`
  background-size: cover;
  width: calc(0.0865176 * 100vw);
  height: calc(0.0865176 * 100vw);
  border-radius: 50%;
  border: calc(0.0025234318 * 100vw) solid white;
  position: relative;
  box-shadow: 0px calc(0.00648882 * 100vw) 0px 0px #c3a529;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fcf08c;
  position: relative;
  .change {
    display: none;
  }
  &:hover {
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.35);
      position: absolute;
      width: calc(0.0865176 * 100vw);
      height: calc(0.0865176 * 100vw);
      border-radius: 50%;
      border: calc(0.0025234318 * 100vw) solid white;
      z-index: 11;
    }
    .change {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 11;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        margin-bottom: 0;
        font-weight: 900;
        line-height: 1.2;
        font-size: calc(0.0115356885 * 100vw);
      }
    }
  }
  img {
    display: block;
    max-width: 100%;
  }
  > div {
    height: 90%;
    width: 90%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100px;
    height: 100px;
  }
`;
