import { featureToggle } from 'constants/index';

export const NAV_ITEMS = [
  {
    name: 'Home',
    to: '/dashboard',
    icon: 'home',
    featureConfigKey: 'dashboard',
    isFeatureReady: true,
    isExternalLink: false,
    mobileOnly: true,
  },
  {
    name: 'Report',
    to: '/report/proficiency',
    icon: 'report',
    featureConfigKey: 'report',
    isFeatureReady: true,
    isExternalLink: false,
  },
  {
    name: 'Leaderboard',
    to: '/leaderboard',
    icon: 'leaderboard',
    featureConfigKey: 'leaderboard',
    isFeatureReady: featureToggle.leaderboard,
    isExternalLink: false,
  },
  {
    name: 'Friends',
    to: '/friends',
    icon: 'friends',
    featureConfigKey: 'friends',
    isFeatureReady: true,
    isExternalLink: false,
  },
  {
    name: 'Help',
    to: 'https://support.koobits.com/hc',
    icon: 'help',
    featureConfigKey: 'help',
    isFeatureReady: true,
    isExternalLink: true,
  },
];
export const PARENT_NAV_ITEMS = [
  {
    name: 'Parent App',
    to: '/parent/parent-app',
    icon: 'parent-app',
    isFeatureReady: true,
    isExternalLink: false,
  },
  {
    name: 'Settings',
    to: '/parent/settings/bill-subscription',
    icon: 'settings',
    isFeatureReady: true,
    isExternalLink: false,
  },
];

export const NAVS_PREMIUM = {
  math: [
    {
      name: 'Home',
      to: '/dashboard',
      icon: 'home',
      featureConfigKey: 'dashboard',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Report',
      to: '/report/proficiency',
      icon: 'report',
      featureConfigKey: 'report',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Leaderboard',
      to: '/leaderboard',
      icon: 'leaderboard',
      featureConfigKey: 'leaderboard',
      isFeatureReady: featureToggle.leaderboard,
      isExternalLink: false,
    },
    {
      name: 'Achievement',
      to: '/challenges/daily-challenge/hero-badge',
      icon: 'achivement',
      featureConfigKey: 'achievement',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Help',
      to: 'https://support.koobits.com/hc',
      icon: 'help',
      featureConfigKey: 'help',
      isFeatureReady: true,
      isExternalLink: true,
    },
  ],
  science: [
    {
      name: 'Home',
      to: '/dashboard',
      icon: 'home',
      featureConfigKey: 'dashboard',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Report',
      to: '/report/proficiency',
      icon: 'report',
      featureConfigKey: 'report',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Leaderboard',
      to: '/leaderboard',
      icon: 'leaderboard',
      featureConfigKey: 'leaderboard',
      isFeatureReady: featureToggle.leaderboard,
      isExternalLink: false,
    },
    {
      name: 'Help',
      to: 'https://support.koobits.com/hc',
      icon: 'help',
      featureConfigKey: 'help',
      isFeatureReady: true,
      isExternalLink: true,
    },
  ],
  english: [
    {
      name: 'Home',
      to: '/dashboard',
      icon: 'home',
      featureConfigKey: 'dashboard',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Report',
      to: '/report/proficiency',
      icon: 'report',
      featureConfigKey: 'report',
      isFeatureReady: true,
      isExternalLink: false,
    },
    {
      name: 'Leaderboard',
      to: '/leaderboard/hero-of-the-day/english',
      icon: 'leaderboard',
      featureConfigKey: 'leaderboard',
      isFeatureReady: featureToggle.leaderboard,
      isExternalLink: false,
    },
    {
      name: 'Help',
      to: 'https://support.koobits.com/hc',
      icon: 'help',
      featureConfigKey: 'help',
      isFeatureReady: true,
      isExternalLink: true,
    },
  ],
};
