import React from 'react';
import { useTranslation } from 'react-i18next';
import GiftBox from 'assets/img/icon-gift-box.svg';
import GiftBoxOpen from 'assets/img/icon-gift-box-open.svg';
import GiftBag from 'assets/img/icon-gift-bag.svg';
import { KOOQUIZ_PAPERS } from 'constants/kooQuiz';
import { Container } from './GiftBarHeader.styles';

const GiftBarHeader = ({ selectedPaper, giftObj }) => {
  const { t } = useTranslation(['kooQuizBookShelf']);
  const giftType = KOOQUIZ_PAPERS?.find((item) => item.id === selectedPaper)
    ?.gift;

  return (
    <Container data-cy="gift-header">
      {giftObj && giftObj.IsClaimedByCurrentUser ? (
        <>
          <img
            src={giftType === 'Gift Bag' ? GiftBag : GiftBoxOpen}
            alt="open-gift"
          />
          <p>
            {t(
              'kooQuizBookShelf:giftHeader.unlocked',
              {
                type:
                  giftType === 'Gift Bag'
                    ? t('kooQuizBookShelf:giftHeader.bag', 'bag')
                    : t('kooQuizBookShelf:giftHeader.box', 'box'),
              },
              `You have unlocked this week’s gift ${
                giftType === 'Gift Bag' ? 'bag' : 'box'
              }. Come back next week!`
            )}
          </p>
        </>
      ) : (
        <>
          {giftObj && giftObj.CurrentUserStatus !== 2 && (
            <img
              src={giftType === 'Gift Bag' ? GiftBag : GiftBox}
              alt="close-gift"
            />
          )}
          <p>
            {t(
              'kooQuizBookShelf:giftHeader.locked',
              {
                type:
                  giftType === 'Gift Bag'
                    ? t('kooQuizBookShelf:giftHeader.bag', 'bag')
                    : t('kooQuizBookShelf:giftHeader.box', 'box'),
              },
              ` Complete a KooQuiz each week to unlock a Koko Gift ${
                giftType === 'Gift Bag' ? 'bag' : 'box'
              }.`
            )}{' '}
            {t(
              'kooQuizBookShelf:giftHeader.creditsNo',
              {
                number: giftType === 'Gift Bag' ? '5' : '20',
              },
              `There are up to ${
                giftType === 'Gift Bag' ? '5' : '20'
              } Koko Credits to be won!`
            )}
          </p>
        </>
      )}
    </Container>
  );
};

export default GiftBarHeader;
