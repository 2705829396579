import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateUserPreferences } from 'store/userPreferences/userPreferencesSlice';

import ErrorModal from 'components/Shared/ErrorModal';
import { setCurrentProduct } from 'store/plan/planSlice';
import useQuery from 'helpers/useQuery';

const PLAN = {
  B2B: 'school',
  B2C: 'premium',
};
const withPlanCheck = (
  WrappedComponent,
  chosenSubject,
  chosenPlan,
  noNeedChange
) => {
  const HOC = () => {
    const { t } = useTranslation('common');
    const query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const planParam =
      query.get('plan')?.toUpperCase() || chosenPlan?.toUpperCase();
    const [error, setError] = useState(false);
    const queryPlan = PLAN[planParam];
    const { plan, products, isRedirect, subject } = useSelector(
      (state) => state.plan
    );
    useEffect(() => {
      if (!queryPlan) {
        history.replace(`/dashboard`);
        return;
      }
      if (products && isRedirect) {
        if (Array.isArray(chosenSubject)) {
          const findCurrentPlan = chosenSubject.filter((subjectName) =>
            products?.find(
              ({ nonExpiredSubscriptionProducts, subject: productSubject }) =>
                productSubject?.toLowerCase().trim() ===
                  subjectName?.toLowerCase().trim() &&
                nonExpiredSubscriptionProducts.find(
                  ({ productType }) => productType.toLowerCase() === queryPlan
                )
            )
          );
          if (findCurrentPlan?.length === 0 || !findCurrentPlan) {
            setError(true);
            return;
          }
          // eslint-disable-next-line consistent-return
          return;
        }
        const findSubject = products?.find(
          ({ subject: productSubject }) =>
            chosenSubject === productSubject?.toLowerCase().trim()
        );
        if (!findSubject) {
          setError(true);
          return;
        }
        const findPlan = findSubject.nonExpiredSubscriptionProducts?.find(
          ({ productType }) => productType.toLowerCase() === queryPlan
        );
        if (!findPlan) {
          setError(true);
          return;
        }
        if (plan !== queryPlan || subject !== chosenSubject) {
          if (!noNeedChange) {
            dispatch(updateUserPreferences, chosenSubject.productId);
            dispatch(setCurrentProduct(findPlan));
          }
        }
      }
    }, [products, dispatch, history, plan, queryPlan, isRedirect, subject]);
    if (error) {
      return (
        <ErrorModal
          errorMessage={t(
            'common:noAccess',
            `You don't have access to this feature`
          )}
          closeHandler={() => history.replace(`/dashboard`)}
        />
      );
    }
    return products ? <WrappedComponent /> : null;
  };
  return HOC;
};

export default withPlanCheck;
