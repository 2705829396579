// Images && Icon
import WarningIcon from 'assets/img/dailychallengev2/warning.svg';
import KoKoIcon from './imgs/koko.svg';
import { ReactComponent as IconCP } from './imgs/icon-cp.svg';
import LockedIcon from './imgs/locked.svg';
import PointingHandeIcon from './imgs/pointing-hand.svg';
import MissionCheck from './imgs/mission-check.svg';
import DailyChallengeText from './imgs/daily-challenge-text.svg';
import { ReactComponent as Runway } from './imgs/runway.svg';
import { ReactComponent as RunwayResponsive } from './imgs/runway-responsive.svg';
import { ReactComponent as EnglishFlag } from './components/Main/imgs/english/icon-flag.svg';
import { ReactComponent as EnglishBook } from './components/Main/imgs/english/icon-book.svg';
// Images && Icon end

export const IMAGES = {
  KoKoIcon,
  IconCP,
  LockedIcon,
  WarningIcon,
  MissionCheck,
  PointingHandeIcon,
  DailyChallengeText,
  Runway,
  RunwayResponsive,
  EnglishFlag,
  EnglishBook,
};
export const mappingUnderline = {
  Elementary: {
    width: 100,
  },
  Intermediate: {
    width: 114,
  },
  Advanced: {
    width: 90,
  },
  Master: {
    width: 72,
  },
};
