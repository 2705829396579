/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { clearVoiceoverPlaying } from 'store/voiceover/voiceoverSlice';
import { populateBarModel, clearAll } from 'store/assignment/barModelSlice';
import {
  setKooClassActiveQuestion,
  clearKooClassSaveError,
  saveKooClassAnswer,
  // kooClassCheckAnswer,
  finishKooClass,
  reset,
  resetFinish,
  // startActivity,
  resetRetry,
  clearFinishError,
} from 'store/kooClass/kooClassSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

import NextIcon from 'assets/img/assignment/back-icon.svg';
import PrevIcon from 'assets/img/assignment/back-icon-blue.svg';

import Header from 'components/Assignment/AssignmentView/Header';
import QnSideBar from 'components/Assignment/AssignmentView/QnSideBar';
import QuestionContent from 'components/Question';
import QnText from 'components/Assignment/AssignmentView/QnText';
import {
  SubmitModal,
  PopupModal,
  Spinner,
  ErrorModal,
  QuestionFeedbackLink,
  Canvas,
} from 'components/Shared';

import {
  SubmissionResultTypes,
  submissionTypeIDs,
  questionTypeIDs,
} from 'constants/index';

import { saveKooClassAnsHandler } from 'helpers/saveAnswerHandler';
import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import useQuery from 'helpers/useQuery';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';

import { Container, Main, QnButton, Error } from './KooClassQnView.styles';

// HELPER
export const getWorkings = (currentSubmission, localSavedWorkings) => {
  if (!isNil(localSavedWorkings)) {
    return localSavedWorkings;
  }
  if (!isNil(currentSubmission.SubmissionModel)) {
    return parseWorkingsBarModel(currentSubmission.SubmissionModel.solution)
      .workings;
  }
  return null;
};

const KooClassQnView = () => {
  const { t } = useTranslation(['assignmentQnView', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const courseIdParam = query.get('courseId');
  const { data: barModelData } = useSelector((state) => state.barModel);
  const {
    // paperById,
    kooClassSubmissionID,
    kooClassQuestions,
    kooClassQuestionAnswers,
    kooClassSubmissions,
    kooClassSavedAnswers,
    kooClassLocalSavedAnswers,
    kooClassActiveQuestion,
    isKooClassOpening,
    isLoading,
    kooClassSaveError,
    error,
    kooClassWorkings,
    kooClassQnTitle,
    isFinish,
    retryActivity,
    checkAnswerError,
    isErrFinish,
    isKooClassFinishing,
    isKooClassSaving,
    isKooClassChecking,
  } = useSelector((state) => state.kooClass);
  const submissionType = submissionTypeIDs.Homework;
  const assignmentType = 'KooClass';
  const setActive = setKooClassActiveQuestion;
  const clearErr = clearKooClassSaveError;
  const activeQn = kooClassActiveQuestion;
  const submissionId = kooClassSubmissionID;
  const allQs = kooClassQuestions;
  const allQsAns = kooClassQuestionAnswers;
  const allSubmission = kooClassSubmissions;
  const savedAns = kooClassSavedAnswers;
  const localSavAns = kooClassLocalSavedAnswers;
  const localSavWorkings = kooClassWorkings;
  const opening = isKooClassOpening;
  const loading = isLoading;
  const isSaveErr = kooClassSaveError;
  const isErr = error;
  const saving = isKooClassSaving;
  const checking = isKooClassChecking;

  const { activityId, lessonId, topicId, activityType } = useParams();

  const isPending = opening || loading;
  const { userID } = useSelector((state) => state.login);
  const [finishModal, setFinishModal] = useState(false);

  // EVENT HANDLERS
  const dispatchSaveAction = (params) => {
    dispatch(saveKooClassAnswer(params));
  };
  const dispatchFinishAssignmentAction = (params) => {
    dispatch(finishKooClass(params));
  };

  // USEEFFECTS
  // Populate bar model data based on current active question
  useEffect(() => {
    if (
      allSubmission[activeQn - 1]?.SubmissionModel !== null &&
      parseWorkingsBarModel(
        allSubmission[activeQn - 1]?.SubmissionModel.solution
      ).barModel !== null
    ) {
      dispatch(
        populateBarModel(
          parseWorkingsBarModel(
            allSubmission[activeQn - 1]?.SubmissionModel.solution
          ).barModel
        )
      );
    }
  }, [activeQn, allSubmission, dispatch]);

  useEffect(() => {
    if (isFinish) {
      history.push(
        `/kooClass/finish/${activityId}/${topicId}/${lessonId}/${activityType}?courseId=${courseIdParam}`
      );
      dispatch(resetFinish());
    }
    // eslint-disable-next-line
  }, [isFinish]);
  // Cleanup when page is unmounted
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const saveAnswerBE = (isSaveBtn, isBack) => {
    dispatch(hideAllTools());
    dispatch(clearAll());
    clearValues();
    if (isBack) {
      const currentSubmission = allSubmission[activeQn - 1].SubmissionModel;
      const submissionResult =
        currentSubmission !== null
          ? SubmissionResultTypes[currentSubmission.submissionResult]
          : null;
      if (
        currentSubmission !== null &&
        (submissionResult === 'correct' || submissionResult === 'wrong')
      ) {
        return;
      }
    }
    saveKooClassAnsHandler(
      isSaveBtn,
      submissionId,
      questionTypeIDs[allQs[activeQn - 1].questionType],
      allQsAns[activeQn - 1],
      dispatchSaveAction,
      activeQn,
      allQs[activeQn - 1],
      localSavAns[activeQn - 1],
      allSubmission[activeQn - 1].SubmissionModel,
      userID,
      getWorkings(allSubmission[activeQn - 1], localSavWorkings[activeQn - 1]),
      barModelData.objects.length > 0 ? barModelData : null,
      activityType
    );
  };
  const finishAssignmentModal = () => {
    saveKooClassAnsHandler(
      true,
      submissionId,
      questionTypeIDs[allQs[activeQn - 1].questionType],
      allQsAns[activeQn - 1],
      dispatchFinishAssignmentAction,
      activeQn,
      allQs[activeQn - 1],
      localSavAns[activeQn - 1],
      allSubmission[activeQn - 1].SubmissionModel,
      userID,
      getWorkings(allSubmission[activeQn - 1], localSavWorkings[activeQn - 1]),
      barModelData.objects.length > 0 ? barModelData : null,
      activityType
    );
    setFinishModal(false);
    if (!isNil(retryActivity)) {
      dispatch(resetRetry());
    }
  };

  const openModal = () => {
    setFinishModal(true);
    dispatch(clearVoiceoverPlaying());
  };

  const closeModal = () => {
    setFinishModal(false);
  };

  const errorMsg = () => {
    if (!isNil(isSaveErr)) {
      return isSaveErr;
    }
    if (!isNil(checkAnswerError)) {
      return checkAnswerError;
    }
    if (!isNil(isErrFinish)) {
      return isErrFinish;
    }
  };

  return (
    <Container>
      <PopupModal show={finishModal} backdrop="static">
        <SubmitModal
          type={t('assignmentQnView:finishModal.important', 'Important!')}
          header={t(
            'assignmentQnView:finishModal.confirmSubmission',
            'Confirm Submission'
          )}
          title={t(
            'assignmentQnView:finishModal.confirmSubmit',
            'Are you sure you want to submit?'
          )}
          desc={t(
            'assignmentQnView:finishModal.submitDesc',
            'Please make sure you do not leave any question(s) unanswered.'
          )}
          noHandle={closeModal}
          yesHandle={finishAssignmentModal}
          okBtnColor="#ff6701"
          loading={isKooClassFinishing}
        />
      </PopupModal>
      <Canvas bgColor="rgb(0, 65, 78, 0.21)" feature="kooClass">
        <>
          <Header
            isLoading={isPending}
            type={assignmentType}
            assignmentTitle={kooClassQnTitle}
            userID={userID}
            currentQuestion={allQs[activeQn - 1]}
            currentAnswerKeys={allQsAns[activeQn - 1]}
            currentLocalSavedAnswer={localSavAns[activeQn - 1]}
            currentSavedAnswer={savedAns[activeQn - 1]}
            openModal={openModal}
            saveHandler={saveAnswerBE}
            isSaving={saving}
            saveErr={isSaveErr}
            isErr={isErr}
            isChecking={checking}
            activeQuestion={activeQn}
          />
          {!isPending && isErr === null && (
            <QnSideBar
              assignmentType="KooClass"
              localSavedAnswers={localSavAns}
              questions={allQs}
              activeQuestion={activeQn}
              saveAns={saveAnswerBE}
            />
          )}
          <Main assignmentType="kooClass">
            <div className="note-book-wrap">
              <div className="note-book">
                <div className="notebook-top">
                  {!isPending && isErr === null && (
                    <>
                      <div className="text">
                        <QnText />
                        <p className="qn-number">{activeQn}</p>
                        <p className="qn-id">
                          {t('assignmentQnView:main.qnId', 'Qn ID')}{' '}
                          {allQs[activeQn - 1].id}
                        </p>
                      </div>
                      <div className="action-btn">
                        {activeQn > 1 && (
                          <QnButton
                            data-cy="prev-qn-btn"
                            type="prev"
                            onClick={() => {
                              saveAnswerBE();
                              dispatch(setActive(activeQn - 1));
                            }}
                            disabled={saving || checking[activeQn - 1]}
                          >
                            <img src={PrevIcon} alt="Previous Qn" />
                            {t('assignmentQnView:main.previous', 'Previous Qn')}
                          </QnButton>
                        )}
                        <QnButton
                          data-cy="next-qn-btn"
                          type="next"
                          onClick={() => {
                            if (activeQn < allQs.length) {
                              saveAnswerBE();
                              dispatch(setActive(activeQn + 1));
                              dispatch(setClearDraw(true));
                            } else {
                              openModal();
                            }
                          }}
                          disabled={saving || checking[activeQn - 1]}
                        >
                          {activeQn === allQs.length ? (
                            t('assignmentQnView:header.submit', 'Submit')
                          ) : (
                            <>
                              {t('assignmentQnView:main.next', 'Next Question')}{' '}
                              <img src={NextIcon} alt="Next Question" />
                            </>
                          )}
                        </QnButton>
                      </div>
                    </>
                  )}
                </div>
                <div className="notebook-middle">
                  {isPending && (
                    <Spinner
                      color="#00A49A"
                      top="35%"
                      position="absolute"
                      left="52%"
                    />
                  )}
                  {!isPending && isErr !== null && (
                    <Error>
                      <h3>Error</h3>
                      <h4>{isErr}</h4>
                    </Error>
                  )}
                  {!isPending && isErr === null && (
                    <QuestionContent
                      page={assignmentType}
                      submissionType={submissionType}
                      questionType={
                        questionTypeIDs[allQs[activeQn - 1].questionType]
                      }
                      qnID={allQs[activeQn - 1].id}
                      xml={
                        allSubmission[activeQn - 1].SubmissionModel !== null
                          ? allSubmission[activeQn - 1].SubmissionModel.payload
                          : allQs[activeQn - 1].payload
                      }
                      showCheckAnswer
                      showSolution={false}
                      showSubmissions={
                        allSubmission[activeQn - 1].SubmissionModel !== null
                      }
                      assignmentType={assignmentType}
                    />
                  )}
                </div>
                <div className="notebook-bottom">
                  <QuestionFeedbackLink
                    qnID={allQs[activeQn - 1]?.id}
                    skillID={allQs[activeQn - 1]?.skillId}
                    margin="1rem 0 0 0"
                  />
                </div>
              </div>
            </div>
          </Main>
        </>
      </Canvas>

      {(!isNil(isSaveErr) ||
        !isNil(checkAnswerError) ||
        !isNil(isErrFinish)) && (
        <ErrorModal
          errorMessage={errorMsg()}
          closeHandler={() => {
            if (!isNil(isSaveErr)) {
              dispatch(clearErr());
            }
            if (!isNil(isErrFinish)) {
              dispatch(clearFinishError());
            }
          }}
        />
      )}
    </Container>
  );
};

export default KooClassQnView;
