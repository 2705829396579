import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import axios from 'axios';
import { isNil } from 'ramda';

const baseUrl = process.env.REACT_APP_PRODUCTS_API_DOMAIN;
const DIRECT_PAYMENT_BASE_URL =
  process.env.REACT_APP_DIRECT_PAYMENT_SERVICE_URL;
export const fetchUserProducts = () => {
  return fetch(`${baseUrl}User/Products`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? `Get user's products failed`);
    });
};

export const fetchFeaturesBySubscriptionProductID = (productID) => {
  if (isNil(productID)) {
    throw new Error('Product ID undefined');
  }
  return fetch(`${baseUrl}Product/${productID}/Features`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? `Get product by ID failed`);
    });
};

export const fetchGlobalFeatureConfig = () => {
  return fetch(
    `${process.env.REACT_APP_CONTINGENCY_CONFIG_URL}?hash=${Date.now()}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((myJson) => {
      return myJson;
    });
};

export const fetchUserActiveSubscriptions = ({ userID }) => {
  return axios
    .get(`${DIRECT_PAYMENT_BASE_URL}Students/${userID}/Subscriptions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err?.message ?? `Get active subscriptions failed`);
    });
};
