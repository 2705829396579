import React, { useState } from 'react';
import { isNil } from 'ramda';
import GiftBoxImg from './giftbox.png';
import OpenedKoKo from './kokoCreditsOpened.png';
import { OpenedKoKoContainer } from './GiftBox.styles';

const GiftBox = ({ hasOpened, claimReward, kokoPoints, isClaiming }) => {
  const [isOpened, setIsOpened] = useState(false);
  const onClickHandler = () => {
    if (!hasOpened) {
      setIsOpened(true);
      claimReward();
    }
  };
  return (
    <>
      {!isOpened && (
        <div className="giftbox-container">
          <img
            src={GiftBoxImg}
            alt="Gift Box"
            onClick={onClickHandler}
            className="giftbox"
          />
        </div>
      )}
      {isOpened && (
        <OpenedKoKoContainer>
          <p>
            {!isNil(kokoPoints) && !isClaiming ? `${kokoPoints} KoKo` : '...'}
          </p>
          <img src={OpenedKoKo} alt="KoKo Credits" />
        </OpenedKoKoContainer>
      )}
    </>
  );
};

export default GiftBox;
