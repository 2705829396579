import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupModal } from 'components/Shared';
import { Container } from './ModalContentAlert.styles';

const ModalContentAlert = ({
  showModal,
  backdrop,
  hide,
  size,
  header,
  type,
  title,
  desc,
  noHandle,
  yesHandle,
  hideYesButton,
  hideNoButton,
  showOkButton,
  okBtnColor,
}) => {
  const { t } = useTranslation(['sundayMCQnVIew']);
  return (
    <PopupModal
      show={showModal}
      backdrop={backdrop}
      keyboard={false}
      onHide={hide}
      size={size}
    >
      <Container okBtn={okBtnColor} data-cy="modal-container">
        <div className="header-section">{header}</div>
        <div className="body-section">
          {type && <div className="warning-title">{type}</div>}
          <div className="modal-title">{title}</div>
          <div className="modal-desc">{desc}</div>
          <div className="modal-buttons">
            {!hideNoButton && (
              <button className="no-btn btns" onClick={noHandle}>
                {t('sundayMCQnVIew:submitModal.no', 'No')}
              </button>
            )}
            {!hideYesButton && (
              <button className="yes-btn btns" onClick={yesHandle}>
                {t('sundayMCQnVIew:submitModal.yes', 'Yes')}
              </button>
            )}
            {showOkButton === true && (
              <button className="ok-btn btns" onClick={yesHandle}>
                OK
              </button>
            )}
          </div>
        </div>
      </Container>
    </PopupModal>
  );
};

export default ModalContentAlert;
