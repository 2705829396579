import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  mockLevelsData,
  mappingChallengeLevelCodeInToolkit,
} from 'constants/dailyChallengev2';
import { OverlayTrigger } from 'react-bootstrap';
import { checkIsLock } from '../../helpers';
import { IMAGES } from '../../constants';
import {
  HeaderLevelContainer,
  LevelItem,
  LevelTooltip,
} from './HeaderLevel.styles';

const HeaderLevel = ({ currentLevel, onChangeLevel, currentUserLevel }) => {
  const { t } = useTranslation(['dailyChallengev2']);
  return (
    <HeaderLevelContainer>
      {mockLevelsData.map((level, index) => {
        const isLock = checkIsLock(level.name, currentUserLevel);
        const isActive = currentLevel === level.name;

        return (
          <React.Fragment key={level.levelCode}>
            {isLock && index > 0 ? (
              <OverlayTrigger
                transition={false}
                placement="bottom"
                overlay={
                  <LevelTooltip id="level-overlay">
                    <div className="dc-tooltip">
                      {t('dailyChallengev2:toolkit.reach', 'Reach')}{' '}
                      <span className="dc-tooltip-badge">
                        Lvl{' '}
                        {
                          mappingChallengeLevelCodeInToolkit[level.levelCode]
                            .unlockLevel
                        }
                      </span>{' '}
                      {t('dailyChallengev2:toolkit.toUnlock', 'to unlock')}
                    </div>
                  </LevelTooltip>
                }
              >
                {({ ref, ...triggerHandler }) => (
                  <LevelItem
                    {...triggerHandler}
                    currentLevel={currentLevel}
                    locked={isLock}
                    active={isActive}
                    onClick={() => onChangeLevel(level, currentLevel)}
                  >
                    <div className="dc-levels">
                      <img className="dc-level-icon" src={level.icon} alt="" />
                      {isLock && (
                        <img
                          className="dc-locked"
                          src={IMAGES.LockedIcon}
                          alt=""
                        />
                      )}
                    </div>
                    <p ref={ref}>
                      {isActive && <span className="dc-dot">&#8226;</span>}
                      {t(`dailyChallengev2:header.${level.name}`, level.name)}
                    </p>
                  </LevelItem>
                )}
              </OverlayTrigger>
            ) : (
              <LevelItem
                currentLevel={currentLevel}
                locked={isLock}
                active={isActive}
                onClick={() => onChangeLevel(level, currentLevel)}
              >
                <div className="dc-levels">
                  <img className="dc-level-icon" src={level.icon} alt="" />
                  {isLock && (
                    <img className="dc-locked" src={IMAGES.LockedIcon} alt="" />
                  )}
                </div>
                <p>
                  {isActive && <span className="dc-dot">&#8226;</span>}
                  {t(`dailyChallengev2:header.${level.name}`, level.name)}
                </p>
              </LevelItem>
            )}
            {index !== 3 && <span />}
          </React.Fragment>
        );
      })}
    </HeaderLevelContainer>
  );
};
export default HeaderLevel;
