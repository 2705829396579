import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary } from 'react-error-boundary';
import Cookies from 'js-cookie';
import store from 'store/index';
import CPSection from 'components/Login/CPSection/CPSection';
import LoginSection from 'components/Login/LoginSection';
import CPSectionFallback from 'components/Login/CPSection/CPSectionFallback';
import { LoginPage } from './Login.styles';

export default function Login() {
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)',
  });

  useEffect(() => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('language');
    localStorage.removeItem('pcRandomBot');
    Cookies.remove('sp_access_token', {
      path: '/',
      domain: 'koobits.com',
    });
    Cookies.remove('sp_refresh_token', {
      path: '/',
      domain: 'koobits.com',
    });

    store.dispatch({ type: 'reset-store' });
  }, []);
  return (
    <LoginPage>
      {isMobile ? (
        <LoginSection isMobile={isMobile} />
      ) : (
        <>
          <ErrorBoundary FallbackComponent={CPSectionFallback}>
            <CPSection isMobile={isMobile} />
          </ErrorBoundary>
          <LoginSection isMobile={isMobile} />
        </>
      )}
    </LoginPage>
  );
}
