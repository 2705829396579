import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { resetStoryWorld } from 'store/story/storySlice';

import checkProductSubscription from 'helpers/Intermediate/checkProductSubscription';
import { compareValues } from 'helpers/compareValue';

import StoryMath from './imgs/story-math.png';
import StoryScience from './imgs/story-science.png';
import StoryEnglish from './imgs/story-english.svg';
import BGText from './imgs/story-text.png';
import BooksSVG from './imgs/books.svg';
import { Button, QnViewHeader, BackButton, Spinner } from 'components/Shared';

import {
  Container,
  ProductItem,
  ProductListContainer,
  StyledHeader,
} from './IntermediatePage.styles';

const IntermediatePage = () => {
  const { t } = useTranslation(['storyIntermediate', 'topicSelection']);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isLoading, subject, products, plan } = useSelector(
    (state) => state.plan
  );
  const [options, setOptions] = useState([]);
  const isFromShortCut = location.state?.quickAccess;

  const items = [
    {
      id: 1,
      subject: 'Math',
      name: t('storyIntermediate:math.title', 'StoryMath'),
      description: t(
        'storyIntermediate:math.description',
        'The Adventures of Miko the Fox'
      ),
      imageUrl: StoryMath,
      redirectUrl: `/story/math`,
      totalBooks: 31,
    },
    {
      id: 2,
      subject: 'Science',
      name: t('storyIntermediate:science.title', 'StoryScience'),
      description: t(
        'storyIntermediate:science.description',
        'Follow unique characters on their short adventures'
      ),
      imageUrl: StoryScience,
      redirectUrl: `/story/science`,
      totalBooks: 20,
    },
    {
      id: 3,
      subject: 'English',
      name: t('storyIntermediate:english.title', 'StoryEnglish'),
      description: t(
        'storyIntermediate:english.description',
        'Diverse, exciting tales with unique characters'
      ),
      imageUrl: StoryEnglish,
      redirectUrl: `/story/english`,
      totalBooks: 181,
    },
  ];

  useEffect(() => {
    const getList = () => {
      if (isNil(subject) || isNil(products)) return;
      // eslint-disable-next-line prefer-const
      let arrayList = [];
      products.forEach((product) => {
        const obj = checkProductSubscription(product, 'Story');
        if (isNil(obj)) return;
        if (obj.isEnabled === false) return;
        const itemIndex = items.findIndex(
          (item) => item.subject === product.subject
        );
        if (isNil(itemIndex)) return;
        if (items[itemIndex].subject.toLowerCase() === subject) {
          arrayList = [items[itemIndex], ...arrayList];
        } else {
          arrayList = [...arrayList, items[itemIndex]];
        }
      });
      setOptions(arrayList.sort(compareValues('id', 'asc')));
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, products, t]);

  useEffect(() => {
    dispatch(resetStoryWorld());
  }, [dispatch]);

  const backHandler = () => {
    const destination =
      plan === 'premium'
        ? isFromShortCut
          ? null
          : '/dashboard/play'
        : '/dashboard';
    if (destination === null) {
      history.goBack();
      return;
    }
    history.push(destination);
  };

  const renderDetails = () => {
    if (isLoading) {
      return <Spinner animation="border" style={{ marginTop: '25px' }} />;
    }

    return (
      <ProductListContainer>
        {options.map((option) => (
          <ProductItem key={option.id}>
            <img
              className="left-content"
              src={option.imageUrl}
              alt={option.name}
              width="208px"
              height="204px"
            />
            <div className="mid-content">
              <div className="mid-main-content">
                <h4 className="open-title">{option.name}</h4>
                <p className="open-description">{option.description}</p>
              </div>
              <div className="bottom-content">
                <div>
                  <h6>{t('storyIntermediate:totalBooks', 'Total Books')}</h6>
                  <div className="content">
                    <img className="books-img" alt="books" src={BooksSVG} />
                    <span>{option.totalBooks}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-content">
              <Button
                variant="primary"
                width="200px"
                onClick={() => history.push(option.redirectUrl)}
              >
                {t('storyIntermediate:read', 'Read')}
              </Button>
            </div>
          </ProductItem>
        ))}
      </ProductListContainer>
    );
  };

  return (
    <Container>
      <StyledHeader>
        <QnViewHeader page bgColor="#FFD217">
          <BackButton
            backText={t('topicSelection:backBtn', 'Back')}
            btnBgColor="#D5AB00"
            padding="5px 20px"
            onClick={backHandler}
            styles={{ position: 'absolute', left: '3%' }}
          />
          <img
            className="bg-text"
            src={BGText}
            alt="brain-game"
            width="208px"
          />
        </QnViewHeader>
      </StyledHeader>
      {renderDetails()}
    </Container>
  );
};

export default IntermediatePage;
