export const SUBJECTS = {
  MATH: 'Math',
  SCIENCE: 'Science',
  ENGLISH: 'English',
};

export const PRODUCT_TYPE = {
  PREMIUM: 'premium',
  STANDARD: 'standard',
  SCHOOL: 'school',
};
export const productTypeHierarchy = {
  [PRODUCT_TYPE.PREMIUM]: 1,
  [PRODUCT_TYPE.STANDARD]: 3,
  [PRODUCT_TYPE.SCHOOL]: 4,
};

export const subjectIDs = {
  1: SUBJECTS.MATH,
  2: SUBJECTS.SCIENCE,
  3: SUBJECTS.ENGLISH,
};

export const subjectNames = {
  [SUBJECTS.MATH.toLowerCase()]: 1,
  [SUBJECTS.SCIENCE.toLowerCase()]: 2,
  [SUBJECTS.ENGLISH.toLowerCase()]: 3,
  [SUBJECTS.MATH]: 1,
  [SUBJECTS.SCIENCE]: 2,
  [SUBJECTS.ENGLISH]: 3,
};

export const ELIGIBILITY_TYPE_IDS = {
  OTP: 1,
  CC: 2,
};

export const SG_COUNTRY_ID = 192;
