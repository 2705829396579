import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { isNil } from 'ramda';
import { useMediaQuery } from 'react-responsive';
import { useTranslation, Trans } from 'react-i18next';

import { getEventsProgress } from 'store/event/eventV2Slice';

import useLocalStorage from 'hooks/useLocalStorage';

import { BackButton, Spinner, PopupModal } from 'components/Shared';
import {
  BrainMasterProgressBar,
  HowToWinModal,
  MilestonePopup,
} from './components';

import { BMTitle, BMMiko } from './img';

import {
  Container,
  TitleSection,
  TitleWrapper,
  Title,
  Duration,
  ProgressSection,
  HowToWin,
  Period,
} from './BrainMaster.styles';

const BrainMaster = () => {
  const { t } = useTranslation(['brainMaster', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const hideElements = useMediaQuery({ maxWidth: '996px' });
  const [showHowToWinModal, setShowHowToWinModal] = useState(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [currentAward, setCurrentAward] = useState('');

  const { userID } = useSelector((state) => state.login);
  const { data: eventsList2, loading } = useSelector(
    (state) => state.eventsList.events
  );
  const { data: countData, loading: countDataLoading } = useSelector(
    (state) => state.eventV2.eventProgress
  );

  const brainMasterData = eventsList2.find(
    (item) => item.name === 'Brain Master'
  );
  const remainingDays =
    moment(brainMasterData?.endDate).diff(moment(), 'days') <= 0
      ? 0
      : moment(brainMasterData?.endDate).diff(moment(), 'days');
  const isEventEnd = moment().isAfter(brainMasterData?.endDate);

  const milestoneAchieveNumber = brainMasterData?.milestones?.map(
    (item) => item.achievedAt
  );

  const distinctMilestone = milestoneAchieveNumber
    ? Math.max(...milestoneAchieveNumber)
    : 0;

  const meritMilestone = brainMasterData?.milestones?.find(
    (item) => item.name === 'Merit Award'
  )?.achievedAt;

  const getMessage = (count) => {
    if (count === 0) {
      return t(
        'brainMaster:progress.notStart',
        'You can see the progress here. Keep Going!'
      );
    } else if (count >= distinctMilestone) {
      return t(
        'brainMaster:progress.finish',
        'Congratulations, you won the Brain Master Distinction Award!'
      );
    } else {
      return (
        <Trans i18nKey="brainMaster:progress.inProgress" count={count}>
          You have answered <span>{{ count }}</span> questions correctly. Keep
          up the great work!
        </Trans>
      );
    }
  };

  // seen award pop up handling
  const [seenMeritAward, setSeenMeritAward] = useLocalStorage(
    'seenMeritAward',
    {},
    { validateUserId: true }
  );
  const [seenDistinctAward, setSeenDistinctAward] = useLocalStorage(
    'seenDistinctAward',
    {},
    { validateUserId: true }
  );

  const awardModalHandler = () => {
    if (currentAward === 'merit') {
      setSeenMeritAward({ status: true, userID });
      // handling if user have not see both award popup
      if (countData?.count >= distinctMilestone && !seenDistinctAward.status) {
        setCurrentAward('distinction');
      } else {
        setShowMilestoneModal(false);
        setCurrentAward('');
      }
    }
    if (currentAward === 'distinction') {
      setSeenDistinctAward({ status: true, userID });
      setShowMilestoneModal(false);
      setCurrentAward('');
    }
  };
  // auto show pop up
  useEffect(() => {
    if (countData?.count >= meritMilestone) {
      if (countData?.count >= distinctMilestone) {
        if (!seenDistinctAward.status) {
          setCurrentAward('distinction');
          setShowMilestoneModal(true);
        }
      }
      if (!seenMeritAward.status) {
        setCurrentAward('merit');
        setShowMilestoneModal(true);
      }
    }
    // eslint-disable-next-line
  }, [countData]);

  // redirection handling
  if (isNil(brainMasterData)) {
    history.push('/events');
  }

  useEffect(() => {
    dispatch(getEventsProgress(state?.id));
  }, [dispatch, state]);

  return (
    <Container>
      <PopupModal show={showHowToWinModal} backdrop="static">
        <HowToWinModal click={() => setShowHowToWinModal(false)} />
      </PopupModal>
      <MilestonePopup
        open={showMilestoneModal}
        milestone={currentAward}
        closeHandler={awardModalHandler}
      />
      <TitleSection>
        <BackButton
          styles={{
            position: 'absolute',
            left: '2rem',
            top: '1.5rem',
            padding: '4px 15px',
          }}
          btnBgColor="rgba(98, 37, 9, 0.72)"
          backText={t('common:back', 'Back')}
          onClick={() => history.push('/events')}
        />
        <TitleWrapper>
          <Title>
            <img src={BMTitle} alt="Brain Master" />
            <p>
              {t(
                'brainMaster:title.desc',
                'Answer the Questions and Win Trophy.'
              )}
            </p>
          </Title>
          <Period>
            <Duration>
              <div className="nod">{remainingDays}</div>
              <div className="unit-period">
                <span className="unit">
                  {t('brainMaster:title.duration.remaining', 'Days Left')}
                </span>
                <div className="period">
                  {t('brainMaster:title.duration.period', 'Event Period:')}{' '}
                  {loading
                    ? '...'
                    : `${moment(brainMasterData?.startDate).format(
                        'D MMM YYYY'
                      )} - ${moment(brainMasterData?.endDate).format(
                        'D MMM YYYY'
                      )}
                  `}
                </div>
              </div>
            </Duration>
            {isEventEnd && (
              <div className="end-flag">
                {t('brainMaster:title.duration.end', 'This event has ended.')}
              </div>
            )}
          </Period>
        </TitleWrapper>
        <img src={BMMiko} alt="miko" className="miko-award" />
        {!hideElements && (
          <HowToWin onClick={() => setShowHowToWinModal(true)}>
            {t('brainMaster:howToWinModal.btn', 'How to win?')}
          </HowToWin>
        )}
      </TitleSection>
      {countDataLoading ? (
        <Spinner />
      ) : (
        <ProgressSection>
          <div>{getMessage(countData?.count)}</div>
          <BrainMasterProgressBar
            milestones={brainMasterData?.milestones}
            now={countData?.count}
            maxMilestone={distinctMilestone}
          />
        </ProgressSection>
      )}
    </Container>
  );
};

export default BrainMaster;
