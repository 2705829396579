import styled, { keyframes } from 'styled-components';

const defaultStyle = {
  leftBgColour: '#FF9921',
  rightBgColour: '#21A1FF',
};
const VersusSubjectStyles = {
  math: {
    leftBgColour: '#FF9921',
    rightBgColour: '#21A1FF',
  },
  science: {
    leftBgColour: '#FF9921',
    rightBgColour: '#6FC316',
  },
};
const fadeInVsIcon = keyframes`
  0% { opacity: 0; transform: translate(-50%, -50%) scale(0.5); }
  10% { opacity: 1; transform: translate(-50%, -50%) scale(1); }
  90% { opacity: 1; transform: translate(-50%, -50%) scale(1); }
  100% { opacity: 0; transform: translate(-50%, -50%) scale(0.5); }
`;
const slideInRight = keyframes`
  0%{
    right: -60%;
  }
  10%{
    right: 0%;
  }
  82%{
    right: 0%;
  }
  100% {
    right: -60%;
  }
`;
const slideInLeft = keyframes`
  0%{
    left: -60%;
  }
  10%{
    left: 0%;
  }
  82%{
    left: 0%;
  }
  100% {
    left: -60%;
  }
`;
const DELAY = 700;
const PLAY = 400;
const STOP = 2800;
export const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.89);
  z-index: 1051;
  color: #fff;
  visibility: ${(props) => (props.shouldDisplay ? 'visible' : 'hidden')};
  transition: ${(props) =>
    props.shouldDisplay ? 'none' : 'visibility 0s 0.2s, opacity 0.2s linear'};
  opacity: ${(props) => (props.shouldDisplay ? '1' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  .c-icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    animation-delay: ${DELAY}ms;
    animation: ${fadeInVsIcon} ${DELAY + PLAY + STOP + PLAY}ms forwards;
  }

  .c-left,
  .c-right {
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .c-left {
    width: 60%;
    left: -60%;
    background-color: ${({ subject }) =>
      VersusSubjectStyles[subject]?.leftBgColour ?? defaultStyle.leftBgColour};
    clip-path: polygon(0 0%, 100% 0, 67% 50%, 100% 50%, 67% 100%, 0 100%);
    justify-content: flex-start;
    padding-right: 10%;
    padding-top: 5%;
    animation-delay: ${DELAY}ms;
    animation: ${slideInLeft} ${DELAY + PLAY + STOP + PLAY}ms forwards;
  }

  .c-right {
    right: -60%;
    width: 60%;
    background-color: ${({ subject }) =>
      VersusSubjectStyles[subject]?.rightBgColour ??
      defaultStyle.rightBgColour};
    clip-path: polygon(33% 0, 100% 0, 100% 100%, 0 100%, 33% 50%, 0 50%);
    justify-content: flex-end;
    padding-left: 10%;
    padding-bottom: 5%;
    animation-delay: ${DELAY}ms;
    animation: ${slideInRight} ${DELAY + PLAY + STOP + PLAY}ms forwards;
  }

  .c-left.show {
    left: 0;
  }

  .c-right.show {
    right: 0;
  }

  .c-avatar {
    margin-bottom: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: solid 6px white;
    }
  }

  .c-name {
    color: #ffffff;
    max-width: 50%;
    text-align: center;
    height: 60px;
    display: none;
  }

  .c-country {
    font-size: 12px;
    display: flex;
    align-items: center;
    max-width: 70%;
    img {
      width: 24px;
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      .c-country {
        max-width: 100%;
        font-size: 16px;

        img {
          width: 38px;
        }
      }
      .c-name {
        display: block;
      }
      .c-avatar {
        margin-bottom: 20px;
        img {
          width: 130px;
          height: 130px;
        }
      }
      .c-left {
        width: 55%;
        left: -55%;
        clip-path: polygon(0 0%, 100% 0, 82% 50%, 100% 50%, 82% 100%, 0 100%);
      }

      .c-right {
        right: -55%;
        width: 55%;
        clip-path: polygon(18% 0, 100% 0, 100% 100%, 0 100%, 18% 50%, 0 50%);
      }
      .c-left,
      .c-right {
        justify-content: center;
        padding: 0px;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      .c-left {
        right: -53%;
        width: 53%;
        clip-path: polygon(0 0%, 100% 0, 89% 50%, 99% 50%, 89% 100%, 0 100%);
      }

      .c-right {
        right: -53%;
        width: 53%;
        clip-path: polygon(11% 0, 100% 0, 100% 100%, 0 100%, 10% 50%, 0 50%);
      }
    }
  }
`;

export const AvatarShadow = styled.div`
  width: 267px;
  height: 30px;
  background-image: radial-gradient(#e5e9f3 0%, white 80%);
  mix-blend-mode: multiply;
`;

export const LevelBadge = styled.div`
  position: relative;
  line-height: 0;
  padding: 8px 0px;
  margin-top: -8px;
  color: ${(p) => {
    const numberOfLevel = parseInt(p.level, 10);
    if ((numberOfLevel > 40 && numberOfLevel <= 50) || numberOfLevel === 100)
      return '#000';
    return '#fff';
  }};

  .level-badge {
    position: absolute;
    bottom: 50%;
    margin: 0;
    font-size: 12px;
    width: 100%;
    font-weight: 700;
    text-align: center;
    span {
      font-family: 'Linotte-Heavy', 'Linotte';
      font-size: 14px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      margin-top: 0px;
      padding: 32px 0px;
      .level-badge {
        font-size: 16px;
        span {
          font-size: 20px;
        }
      }
    }
  }
`;
