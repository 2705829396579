import styled from 'styled-components';
import { multiplayerListStyles } from 'constants/multiplayer';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0px;
  }
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  position: relative;
  background: ${(props) =>
    `url(${
      multiplayerListStyles[props.subject.toLowerCase().trim()].headerBgImg
    })`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 140px;
  justify-content: center;
  .btn-section {
    position: absolute;
    left: 2%;
    top: 21%;
  }
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.error ? 'center' : 'flex-end')};
    align-items: center;
    z-index: 10;
    .main-title {
      font-size: 32px;
      font-weight: 600;
      color: white;
      text-shadow: 0px 3px 6px rgb(0, 0, 0, 0.16);
    }
    .sub-title {
      color: rgb(33, 33, 33, 0.74);
      margin-bottom: 1rem;
    }
    .remaining-cards {
      display: flex;
      align-items: flex-end;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .title {
      .main-title {
        font-size: 30px;
      }
      .sub-title {
        font-size: 14px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .btn-section {
      left: 4%;
    }
  }
`;

export const Mascot = styled.img`
  position: absolute;
  left: ${({ space, position }) => position === 'left' && space};
  right: ${({ space, position }) => position === 'right' && space};
  bottom: 0px;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    left: 6%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  position: absolute;
  align-self: center;
  right: 10%;
  color: rgba(33, 33, 33, 0.7);
  font-size: 14px;
  pointer-events: auto;
  .incoming {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    img {
      height: 60px;
      margin-bottom: 4px;
    }
    .red-dot {
      width: 8px;
      height: 8px;
      background: #ff4040;
      position: absolute;
      border-radius: 100%;
      margin-left: 3.5rem;
    }
  }
  .history {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-right: 2rem;
    img {
      height: 60px;
      margin-bottom: 4px;
    }
  }
  .rules {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: rgba(33, 33, 33, 0.7);
    img {
      height: 60px;
      margin-bottom: 4px;
    }
  }
  .btn-right-section {
    display: none;
  }
  &.popup {
    position: static;
    width: 100%;
    justify-content: space-around;
    p {
      color: #fff;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .incoming,
    .history,
    .rules {
      margin-right: 1rem;
      img {
        height: 36px;
      }
    }
    right: 2%;
    top: 21%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    right: 4%;
    .incoming,
    .history,
    .rules {
      display: ${(props) => (props.isPopup ? 'flex' : 'none')};
      img {
        height: 50px;
      }
    }
    .btn-right-section {
      display: block;
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 500px;
  .title {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #0a0a0a;
    margin: 1rem 0rem;

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      font-size: 22px;
      margin: 0.5rem 0rem;
    }
  }
  .opponent-container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 20px;
    margin: 0px;
    flex-direction: column;
  }
  .opponent-side {
    flex-basis: 0px;
    .content {
      display: none;
    }
  }
  .opponent-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .btn-section {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    button {
      font-size: 25px;
    }

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      margin-top: 0.5rem;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      max-width: 750px;
      .opponent-side {
        flex-basis: auto;
        .content {
          display: block;
        }
      }

      .opponent-container {
        margin: 1.5rem 0rem;
      }
      .opponent-center {
        flex-direction: row;
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      max-width: none;
      .opponent-side {
        flex-basis: 240px;
      }
      .opponent-container {
        flex-direction: row;
      }
    }
  }
`;

export const RecentOpponents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem;
`;
export const OpponentList = styled.div`
  margin: 0rem 1rem;
  cursor: pointer;
  text-align: center;
  img {
    width: 112px;
    height: 112px;
    border-radius: 100%;
    border: ${(props) => props.active && '4px solid #ff8316'};
  }
  .opponent-name {
    text-align: center;
  }
`;

export const MobileBottomBar = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row-reverse;
  background: #ededed;
  position: relative;
  bottom: 0;
  &.pc {
    & > div:nth-child(1) {
      & > div {
        color: #fff;
      }
    }
    & > div:nth-child(2) {
      &.pc-curriculum span {
        color: #fff;
      }
      &.pc-curriculum img {
        visibility: hidden;
      }
    }
  }
  button {
    display: none;
  }
  & > div:nth-child(2) {
    width: 80px;
    & > div {
      background: transparent;
      padding-right: 0px;
      &::before {
        background-color: transparent;
      }
    }
  }
  & > div:nth-child(1) {
    position: static;
    margin: 0px;
    flex-grow: 1;
    span {
      color: #0a0a0a;
      flex-grow: 1;
      line-height: 2;
      text-align: center;
      margin-right: 0px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      display: none;
    }
  }
  @media (min-height: 800px) {
    position: fixed;
  }
`;
