import scienceDashboard from 'assets/img/expired/science-dashboard.png';
import englishDashboard from 'assets/img/expired/english-dashboard.png';
import mathDashboard from 'assets/img/expired/math-dashboard.png';

export const trialExpiredConfig = {
  math: {
    title: 'Your Access to KooBits Math has Expired',
    translationKey: 'mathTrial',
    backBtnColor: 'rgba(164, 140, 240, 0.6)',
    img: mathDashboard,
  },
  science: {
    title: 'Your Access to KooBits Science has Expired',
    translationKey: 'scienceTrial',
    backBtnColor: 'rgba(111, 204, 7, 0.8)',
    img: scienceDashboard,
    boxShadow: '0 5px 10px rgba(120, 165, 129, 0.78)',
  },
  english: {
    title: 'Your Access to KooBits English has Expired',
    translationKey: 'englishTrial',
    backBtnColor: 'rgba(254, 113, 32, 0.6)',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    img: englishDashboard,
  },
};
