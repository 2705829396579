import React from 'react';
import { ButtonPlay } from 'components/Shared';
import { Container } from './TopicListItem.styles';

const TopicListItem = ({ topic, onClick }) => {
  return (
    <Container onClick={onClick} className="topic-list-item">
      <ButtonPlay variant="secondary" size="20px" />
      <p>{topic}</p>
    </Container>
  );
};

export default TopicListItem;
