import Stage1 from 'assets/img/mission/missionplus/stage-1.svg';
import Stage2 from 'assets/img/mission/missionplus/stage-2.svg';
import Stage3 from 'assets/img/mission/missionplus/stage-3.svg';
import Stage1Gold from 'assets/img/mission/missionplus/stage-1-gold.svg';
import Stage2Gold from 'assets/img/mission/missionplus/stage-2-gold.svg';
import Stage3Gold from 'assets/img/mission/missionplus/stage-3-gold.svg';

import {
  ScienceMissionPlusBanner,
  EnglishMissionPlusBanner,
  SelfPracticeBanner,
} from 'assets/img/mission';

export const stageIcons = {
  1: {
    name: 'easy',
    icon: Stage1,
    iconGold: Stage1Gold,
  },
  2: {
    name: 'medium',
    icon: Stage2,
    iconGold: Stage2Gold,
  },
  3: {
    name: 'hard',
    icon: Stage3,
    iconGold: Stage3Gold,
  },
};

export const stageStatus = {
  0: {
    name: 'not-start',
  },
  1: {
    name: 'start',
    className: 'btn-start',
    i18nKey: 'common:start',
    label: 'Start',
  },
  2: {
    name: 'progress-bar-continue',
    className: 'btn-start',
    i18nKey: 'common:continue',
    label: 'Continue',
  },
  3: {
    name: 'continue',
    className: 'btn-start',
    i18nKey: 'common:continue',
    label: 'Continue',
  },
  4: {
    name: 'retry',
    className: 'btn-retry',
    i18nKey: 'common:retry',
    label: 'Retry',
  },
};

export const progressBarColor = {
  1: '#ABF0A8',
  3: '#BDF05B',
  5: '#FFDA40',
  7: '#FFB134',
  9: '#FF8C58',
};
export const MISSION_PLUS_CURRICULUM_IDS = {
  96: 'science',
  97: 'science',
  106: 'english',
};

export const MISSION_PLUS_STYLES = {
  science: {
    headerImg: ScienceMissionPlusBanner,
    headerBgColor: 'linear-gradient(#00c4c4, #1bdd7c, #35eb60)',
    backBtn: '#00B592',
    headerTextShadow: '#10ba92',
    headerSpanColor: '#ffd800',
    dropDownBorderColor: '#94bea2',
    dropDownBgColor: '#e9f7f2',
    totalSkillTextColor: '#ffffff',
    progressBorder: 'none',
  },
  english: {
    headerImg: EnglishMissionPlusBanner,
    headerBgColor: 'linear-gradient(#FFC100, #F5A000)',
    backBtn: 'rgba(239, 156, 0, 0.6)',
    headerTextShadow: '#A78000',
    headerSpanColor: '#ffffff',
    dropDownBorderColor: '#efdebe',
    dropDownBgColor: '#fef3bf',
    totalSkillTextColor: '#A88D64',
    progressBorder: '2px solid rgb(255, 255, 255, 0.66)',
  },
  math: {
    headerImg: SelfPracticeBanner,
    headerBgColor: 'linear-gradient(#0239d8)',
    backBtn: '#1471c7',
    headerTextShadow: '#ffffff',
    headerSpanColor: '#ffffff',
    dropDownBorderColor: '#b9cbf5',
    dropDownBgColor: '#e0e9ff',
    totalSkillTextColor: '#ffffff',
    progressBorder: 'none',
  },
};
