import styled from 'styled-components';
import { AWARD } from '../../constants';

export const MainBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    margin-left: 3rem;
  }
`;

export const StartPoint = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: ${({ isActive }) => (isActive ? '#4CCE00' : '#e6e6e6')};
  z-index: 1;
`;

export const MilestoneProgress = styled.div`
  display: flex;
  align-items: center;
  .brain-master-progress {
    width: 5.65rem;
    position: relative;

    background: #e6e6e6;
    .progress-bar {
      background: #4cce00;
    }
  }
  .mile-icon {
    position: relative;
  }
  &:nth-child(2) {
    .brain-master-progress {
      width: 3.5rem;
      left: -0.5rem;
    }
    .mile-icon {
      left: -0.5rem;
    }
  }
  &:nth-child(3) {
    .brain-master-progress {
      width: 6.15rem;
      left: -1rem;
    }
    .mile-icon {
      left: -1rem;
    }
  }
  &:nth-child(4) {
    .brain-master-progress {
      width: 6.15rem;
      left: -1.5rem;
    }
    .mile-icon {
      left: -1.5rem;
    }
  }
  &:nth-child(5) {
    .brain-master-progress {
      width: 6.15rem;
      left: -2rem;
    }
    .mile-icon {
      left: -2rem;
    }
  }
  &:nth-child(6) {
    .brain-master-progress {
      width: 6.15rem;
      left: -2.5rem;
    }
    .mile-icon {
      left: -3.2rem;
    }
  }

  @media (max-width: 1100px) {
    &:nth-child(2) {
      .brain-master-progress {
        width: 2rem;
      }
    }
    &:nth-child(3) {
      .brain-master-progress {
        width: 5rem;
      }
    }
    &:nth-child(4) {
      .brain-master-progress {
        width: 5rem;
      }
    }
    &:nth-child(5) {
      .brain-master-progress {
        width: 5rem;
      }
    }
    &:nth-child(6) {
      .brain-master-progress {
        width: 5rem;
      }
    }
  }
  @media (max-width: 996px) {
    &:nth-child(2) {
      .brain-master-progress {
        width: 2rem;
      }
    }
    &:nth-child(3) {
      .brain-master-progress {
        width: 2rem;
      }
    }
    &:nth-child(4) {
      .brain-master-progress {
        width: 2rem;
      }
    }
    &:nth-child(5) {
      .brain-master-progress {
        width: 2rem;
      }
    }
    &:nth-child(6) {
      .brain-master-progress {
        width: 2rem;
      }
      .mile-icon {
        left: -2.5rem;
      }
    }
  }
`;

export const Arrow = styled.div`
  line-height: 1;
  width: 1rem;
  height: 1rem;
  border-top-right-radius: 20%;
  background-color: ${({ active }) => (active ? '#4cce00' : '#e6e6e6')};
  text-align: left;
  display: inline-block;
  margin: 0.5rem;
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
  transform-origin: 45% 75%;
  position: relative;
  left: -3.5rem;
  top: -0.15rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20%;
  }

  &::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  &::after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;

export const MilestoneIcon = styled.div`
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.4rem;
  background: ${({ isAchieved }) => (isAchieved ? '#4CCE00' : '#efefef')};
  color: ${({ isAchieved }) => (isAchieved ? '#ffffff' : '#D5D5D5')};
  border-radius: 30px;
  padding: 0.3rem 0.5rem;
  border: 3px solid #ffffff;
  min-width: ${({ hasAward }) => (hasAward ? '135px' : '90px')};
  height: 58px;
  box-shadow: 0px 4px rgba(122, 122, 122, 0.36);
  position: relative;
  left: -0.3rem;
  z-index: 1;
  .number-detail {
    display: flex;
    gap: ${({ awardName }) =>
      awardName === 'Distinction Award' ? '0' : '0.5rem'};
    p {
      margin-bottom: 0;
      span {
        font-family: 'Baloo Chettan';
        font-size: 1.625rem;
      }
    }
  }
  @media (max-width: 765px) {
    min-width: ${({ hasAward }) => (hasAward ? '135px' : '75px')};
  }
  @media (max-width: 725px) {
    min-width: ${({ hasAward }) => (hasAward ? '135px' : '65px')};
    align-items: center;
    .number-detail {
      align-items: center;
      p {
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
`;

export const AwardIcon = styled.img`
  position: relative;
  top: ${({ top }) => top ?? '-0.1rem'};
  @media (max-width: 725px) {
    top: 0;
  }
`;

export const RibbonIcon = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Linotte';
  position: absolute;
  color: ${({ type, isAchieved }) =>
    isAchieved ? AWARD[type].color : '#ffffff'};
  background: url(${({ isAchieved, type }) =>
    AWARD[type][isAchieved ? 'activeRibbon' : 'defaultRibbon']});
  background-repeat: no-repeat;
  background-position: center;
  width: ${({ type }) => AWARD[type].ribbonWidth ?? '135px'};
  text-align: center;
  bottom: -0.6rem;
  left: ${({ type }) => AWARD[type].ribbonLeft ?? '0'};
`;
