import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { setVideoIsFullScreen } from 'store/kooClass/kooClassSlice';

import { sendEventTracking } from 'helpers/UserEventTracking';
import useQuery from 'helpers/useQuery';

import ErrorResponse from 'components/Shared/ErrorResponse';

import { Container } from './VideoPlayer.styles';

const VideoPlayer = ({ videoUrl, showVideo }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { currentProduct } = useSelector((state) => state.plan);
  const query = useQuery();
  const activityId = query.get('activityId');
  const videoId = videoUrl?.split('https://youtu.be/')[1] ?? '';

  useEffect(() => {
    const videoRef = ref.current;
    const handleFullscreenChange = (e) => {
      dispatch(
        setVideoIsFullScreen(
          document.fullscreenElement === e.target ||
            document.webkitFullscreenElement === e.target
        )
      );
    };
    if (videoRef) {
      videoRef.addEventListener('fullscreenchange', handleFullscreenChange);
      videoRef.addEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      );
    }
    return () => {
      if (videoRef) {
        videoRef.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange
        );
        videoRef.removeEventListener(
          'webkitfullscreenchange',
          handleFullscreenChange
        );
      }
    };
  }, [dispatch]);
  return (
    <>
      <Container showVideo={showVideo} data-cy="video-container" ref={ref}>
        {!isNil(videoUrl) ? (
          <ReactPlayer
            width="100%"
            height="calc(100vh - 59px)"
            url={videoUrl}
            controls
            loop={false}
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                },
              },
            }}
            onStart={() => {
              // EVENT TRACKING: watch video
              sendEventTracking('kooclass', 'video_view', {
                sub: currentProduct?.subjectId,
                vi: videoId,
                ai: activityId,
              });
            }}
          />
        ) : (
          <ErrorResponse error="Sorry, there is no url available" />
        )}
      </Container>
    </>
  );
};

export default VideoPlayer;
