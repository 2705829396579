import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getParentDetails,
  getChildrenList,
} from 'store/parent/parentSettingsSlice';

import { MockTimeTool } from 'components/Shared';
import {
  BillV2,
  Sidebar,
  ChildAccountDetail,
  ParentAccountDetail,
} from './components';

import { Container, Content } from './Settings.styles';

const Settings = ({ match }) => {
  const dispatch = useDispatch();
  const activeTab = match.params.tab;
  const { userID } = useSelector((state) => state.login);

  useEffect(() => {
    if (userID) {
      if (activeTab === 'bill-subscription') {
        dispatch(getChildrenList(userID));
      } else {
        dispatch(getParentDetails(userID));
      }
    }
  }, [dispatch, userID, activeTab]);

  return (
    <Container>
      <MockTimeTool />
      <Sidebar activeTab={activeTab} />
      <Content>
        {activeTab === 'child-detail' && <ChildAccountDetail />}
        {activeTab === 'bill-subscription' && <BillV2 />}
        {activeTab === 'parent-detail' && <ParentAccountDetail />}
      </Content>
    </Container>
  );
};

export default Settings;
