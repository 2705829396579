import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { StyledTopUpAmountTooLow } from './ErrorDisplay.styled';

const ErrorDisplay = () => {
  const { t } = useTranslation(['trial']);
  return (
    <StyledTopUpAmountTooLow>
      <p className="title">{t('trial:sorry.title', 'Sorry...')}</p>
      <p className="desc">
        {t(
          'trial:sorry.desc',
          'As your top-up amount is too low, please contact our Support team for help.'
        )}
      </p>
      <div className="email">
        <p className="email-title">
          <Trans i18nKey="trial:sorry.email">
            Email us at{' '}
            <a href="email:support@koobits.com">support@koobits.com</a> &
            provide us with
          </Trans>
        </p>
        <ol>
          <li>{t('trial:sorry.loginId', 'Child Login ID')}</li>
          <li>
            {t('trial:sorry.subjects', 'Subjects you want to upgrade with')}
          </li>
        </ol>
      </div>
    </StyledTopUpAmountTooLow>
  );
};

export default ErrorDisplay;
