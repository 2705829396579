import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { isNil, isEmpty } from 'ramda';
import { isJsonStringValid } from 'helpers/checkIfJsonStringValid';
import checkEnv from 'helpers/checkEnv';

import { Spinner, Button } from 'components/Shared';
import KooClassPictureType from '../KooClassPictureType';
import BgIcon from './icon-bg.svg';

import { IVMTitle, BrainGameTitle, Description } from './KooClassGame.styles';

const KooClassGame = ({ type }) => {
  const { t } = useTranslation(['activities']);
  const { currentActivity, currentActivityLoading } = useSelector(
    (state) => state.kooClass
  );
  const [showError, setShowError] = useState(false);

  const activityPayload =
    currentActivity && isJsonStringValid(currentActivity.payload)
      ? JSON.parse(currentActivity.payload)
      : '';
  const IVMGameId = !isEmpty(activityPayload)
    ? type === 'IVM'
      ? activityPayload.Id
      : activityPayload.Url
    : undefined;

  const IVMUrl =
    checkEnv === 'dev'
      ? 'https://ivm.koobits.com/index.html'
      : process.env.REACT_APP_IVM_URL;

  const titleIVM = (
    <IVMTitle>
      <p className="title-ivm">
        {t('activities:handsOnAct.title', 'Hands-On Activity')}
      </p>
      <p className="sub-title-ivm">
        <Trans i18nKey="activities:handsOnAct.koobitsIVM">
          <span className="text-shadow">KooBits </span>
          <span className="orange">I</span>
          <span className="yellow">V</span>
          <span className="blue">M</span>
        </Trans>
      </p>
    </IVMTitle>
  );
  const titleBrainGame = (
    <BrainGameTitle>
      <img src={BgIcon} alt="brain-game" />
      <div>
        <p className="title-bg">
          {t('activities:playAndLearn.title', 'Play and Learn')}
        </p>
        <p className="sub-title-bg">
          {t('activities:playAndLearn.brainGame', 'Brain Game')}
        </p>
      </div>
    </BrainGameTitle>
  );
  return (
    <>
      <KooClassPictureType
        type={type === 'IVM' ? 'kooClassIVM' : 'kooClassBrainGame'}
        title={type === 'IVM' ? titleIVM : titleBrainGame}
        margin="15%"
      />
      <Description>
        {currentActivityLoading && <Spinner />}
        {!currentActivityLoading && currentActivity && (
          <>
            <div className="desc">
              {!showError && (
                <p>{currentActivity && currentActivity.description}</p>
              )}
              {showError && <p>Sorry, current activity Id is not available</p>}
            </div>
            {!showError && (
              <div className="play-btn">
                <Button
                  variant="primary"
                  width="100%"
                  fontSize="26px"
                  fontWeight="bold"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isNil(IVMGameId)) {
                      if (type === 'Game') {
                        const sessionSecret = sessionStorage.getItem('secret');
                        const kooClassGameUrl = isNil(sessionSecret)
                          ? `/kooClass/brain-game/${IVMGameId}`
                          : `/kooClass/brain-game/${IVMGameId}?secret=${sessionSecret}`;
                        window.open(kooClassGameUrl, '_blank');
                      } else {
                        window.open(`${IVMUrl}?vmID=${IVMGameId}`);
                      }
                    } else {
                      setShowError(true);
                    }
                  }}
                >
                  {t('activities:shared.playBtn', 'Play')}
                </Button>
              </div>
            )}
          </>
        )}
      </Description>
    </>
  );
};

export default KooClassGame;
