import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';

const baseUrl = process.env.REACT_APP_HOMEWORK_API_DOMAIN;

export const fetchPapers = ({ type, subjectId }) => {
  return fetch(`${baseUrl}KooQuiz/GetRevisionPapers/${type}/${subjectId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get papers failed'); // eslint-disable-line
    });
};

export const fetchPapersV2 = ({ courseId, subjectId }) => {
  return fetch(
    `${baseUrl}KooQuiz/v2/GetRevisionPapers/${courseId}/${subjectId}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get papers failed'); // eslint-disable-line
    });
};

export const fetchCurrentGiftsSubject = (subjectId) => {
  return fetch(`${baseUrl}KooQuiz/GetCurrentGiftsBySubjectId/${subjectId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current gifts by subject  failed'); // eslint-disable-line
    });
};

export const fetchRevisionId = (ID) => {
  return fetch(`${baseUrl}KooQuiz/GetRevisionById/${ID}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get revision failed'); // eslint-disable-line
    });
};

export const fetchRevisionIdV2 = ({ subjectId, paperId, courseIds }) => {
  const searchParams = new URLSearchParams();
  courseIds.forEach((courseId) => {
    searchParams.append('courseIds', courseId);
  });
  return fetch(
    `${baseUrl}KooQuiz/v2/Subjects/${subjectId}/Revisions/${paperId}?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get revision v2 failed'); // eslint-disable-line
    });
};

export const postAssignGifts = ({ paperId, subjectId }) => {
  return fetch(`${baseUrl}KooQuiz/AssignGift/${paperId}/${subjectId}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'assign gift failed'); // eslint-disable-line
    });
};

export const postAssignGiftsV2 = ({ paperId, subjectId, rewardId }) => {
  return fetch(
    `${baseUrl}KooQuiz/v2/Subjects/${subjectId}/Papers/${paperId}/AssignGift/${rewardId}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'assign gift failed'); // eslint-disable-line
    });
};

export const fetchCurrentGifts = () => {
  return fetch(`${baseUrl}KooQuiz/GetCurrentGifts`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current gifts failed'); // eslint-disable-line
    });
};

export const fetchCurrentGiftsV2 = ({ subjectId, courseIds }) => {
  const searchParams = new URLSearchParams();
  courseIds.forEach((courseId) => {
    searchParams.append('courseIds', courseId);
  });
  return fetch(
    `${baseUrl}KooQuiz/v2/Subjects/${subjectId}/CurrentGifts?${searchParams.toString()}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get current gifts failed'); // eslint-disable-line
    });
};

export const postClaimGift = (submissionId) => {
  return fetch(`${baseUrl}KooQuiz/ClaimRevisionGift/${submissionId}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Claim revision gifts failed'); // eslint-disable-line
    });
};

export const postClaimGiftV2 = ({ subjectId, submissionId, rawBody }) => {
  return fetch(
    `${baseUrl}KooQuiz/v2/Subjects/${subjectId}/RevisionSubmission/${submissionId}/ClaimGift`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Claim revision gifts failed'); // eslint-disable-line
    });
};

export const fetchTotalRevisionKoko = () => {
  return fetch(`${baseUrl}KooQuiz/GetUsersTotalRevisionKokoCredits`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get all revision koko credits failed'); // eslint-disable-line
    });
};

export const postOpenKooQuiz = (paperId) => {
  return fetch(`${baseUrl}KooQuiz/OpenKooquiz/${paperId}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Open KooQuiz failed'); // eslint-disable-line
    });
};

export const saveKooQuizSubmission = (rawBody) => {
  return fetch(`${baseUrl}KooQuiz/Save`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Save KooQuiz submission failed'); // eslint-disable-line
    });
};

export const checkKooQuizAnswer = (rawBody) => {
  return fetch(`${baseUrl}KooQuiz/CheckAnswer`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Check KooQuiz answer submission failed'); // eslint-disable-line
    });
};

// For Finish button
export const closeKooQuiz = (kooQuizSubmissionID) => {
  return fetch(`${baseUrl}KooQuiz/CloseKooquiz/${kooQuizSubmissionID}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Close KooQuiz failed'); // eslint-disable-line
    });
};

export const submitKooQuiz = (id) => {
  return fetch(`${baseUrl}KooQuiz/HomeworkSubmitToTeacher/${id}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit KooQuiz failed');
    });
};

export const fetchkooQuizSolutions = (assignmentSubmissionID) => {
  return fetch(
    `${baseUrl}KooQuiz/GetSolutionBySubmissionId/${assignmentSubmissionID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get kooQuiz solutions failed');
    });
};

export const fetchMultipleCourseRevisionPapers = ({ subjectId, rawBody }) => {
  return fetch(
    `${baseUrl}KooQuiz/v2/GetMultipleCourseRevisionPapers/${subjectId}`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Get multiple course revision paper failed'
      );
    });
};

export const fetchQuestionVideoCode = (questionId) => {
  return fetch(`${baseUrl}KooQuiz/Questions/${questionId}/VideoCode`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get kooQuiz solutions failed');
    });
};
