import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { subjectNames } from 'constants/products';
import {
  status,
  kooQuizTypes,
  KOO_QUIZ_PAPER_TYPE_ID,
  KOO_QUIZ_PAPER,
} from 'constants/kooQuiz';
import KokoIcon from 'assets/img/icon-koko-credits.svg';
import PrintIcon from 'assets/img/kooquiz/print.svg';
import { BOOK_GIFT } from './constants';
import { Container } from './Book.styles';

const Book = ({
  paperVal,
  item,
  icon,
  submitStatus,
  selectedPaper,
  id,
  giftObj,
}) => {
  const { t } = useTranslation(['kooQuizBookShelf']);
  const history = useHistory();
  const { subject } = useParams();
  const fullScore = item.RightAttempts === item.TotalQuestions;
  const percentage = Math.floor(
    (item.totalquestionsAttempted / item.TotalQuestions) * 100
  );
  const showGift = () => {
    if (
      giftObj &&
      giftObj.CurrentUserStatus === 2 &&
      item.rewardId === giftObj.Id
    ) {
      return BOOK_GIFT[selectedPaper];
    }
    return null;
  };
  const viewSolution = (kooQuizId, kooQuizSubmissionID) => {
    const subjectUrl = kooQuizTypes[subjectNames[subject]];
    history.push(
      `/kooQuiz/solution-preview/${subjectUrl}/${paperVal}/${kooQuizId}/${kooQuizSubmissionID}`
    );
  };
  const handleToStartPage = () => {
    history.push(`/kooQuiz/start/${subjectNames[subject]}/${paperVal}/${id}`);
  };

  const isShowPrintIcon =
    item.OfflinePDF &&
    (KOO_QUIZ_PAPER_TYPE_ID[selectedPaper] === KOO_QUIZ_PAPER.HOTS ||
      KOO_QUIZ_PAPER_TYPE_ID[selectedPaper] === KOO_QUIZ_PAPER.OED);

  return (
    <Container
      data-cy={id}
      isNeedAdjust={
        KOO_QUIZ_PAPER_TYPE_ID[selectedPaper] !== KOO_QUIZ_PAPER.TopPapers
      }
      fullScore={fullScore}
      submitStatus={submitStatus}
      giftImg={showGift()}
    >
      {status[submitStatus] === 'Completed' && (
        <>
          <div className="completed-sign">
            {t('kooQuizBookShelf:book.completed', 'Completed')}
          </div>
          <div className="score-sign">
            <p className="score-text">
              {t('kooQuizBookShelf:book.score', 'Score')}
            </p>
            <p className="score">
              <span>{item.RightAttempts}</span> / {item.TotalQuestions}
            </p>
          </div>
        </>
      )}
      <div className="book">
        {isShowPrintIcon && (
          <div className="print-section">
            <img src={PrintIcon} alt="" />
          </div>
        )}
        <img className="paper" src={icon} alt="paper" loading="lazy" />
        <div className="pending-gift" />
        {submitStatus === 2 && (
          <div
            className="overlay-open"
            onClick={() => viewSolution(item.Id, item.HomeworkSubmissionId)}
          />
        )}
        {(submitStatus === 0 || submitStatus === 1) && (
          <div className="overlay-open" onClick={handleToStartPage}>
            <div className="button">
              {submitStatus === 0 && t('kooQuizBookShelf:book.open', 'Open')}
              {submitStatus === 1 &&
                t('kooQuizBookShelf:book.continue', 'Continue')}
            </div>
          </div>
        )}
      </div>
      <div className="line">
        <div className="koko-credits">
          {status[submitStatus] === 'In progress' && (
            <ProgressBar variant="green" now={percentage} />
          )}
          {status[submitStatus] === 'Completed' && (
            <div>
              <img className="koko-icon" src={KokoIcon} alt="koko-credits" />+
              {item.KoKoCredits}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Book;
