import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { searchBFFByCodeOrId } from 'services/multiplayer';
import { fetchWrapper } from 'services/login';

import SearchIcon from 'assets/img/multiplayer/icon-search.png';
import DefaultSchoolLogo from 'assets/img/default-school-logo.svg';
import AvatarPlaceholder from 'assets/img/avatar.png';
import {
  StyledContainer,
  StyledInput,
  SearchButton,
  StyledForm,
  StyledResult,
  EmptyDiv,
} from './BFFSearchBar.styles';

const SearchResult = ({ data, onClickHandler, selectedOpponent }) => {
  const { t } = useTranslation(['newChallenge']);
  return (
    <StyledResult
      onClick={onClickHandler(data.Userid)}
      isSelected={selectedOpponent === data.Userid}
    >
      <img
        src={data.UserAvatarImage ?? AvatarPlaceholder}
        alt=""
        onError={(e) => {
          e.target.src = AvatarPlaceholder;
        }}
      />
      <div className="result-details">
        <p
          className={
            !isNil(data.Fullname) && data.Fullname.length > 31
              ? 'result-details-name long-text'
              : 'result-details-name'
          }
        >
          {data.Fullname ?? ''}
        </p>
        <p className="result-details-bffcode">{`${t(
          'newChallenge:bff.myBffcode',
          'My BFF Code'
        )}: ${data.BFFCode ?? ''}`}</p>
        <div className="result-details-school">
          <img
            src={data.SchoolLogoImage}
            alt="school-logo"
            onError={(e) => {
              e.target.src = DefaultSchoolLogo;
            }}
          />
          <p>{data.SchoolName ?? ''}</p>
        </div>
      </div>
    </StyledResult>
  );
};

const BFFSearchBar = ({
  opponentType = '4',
  setOpponentType,
  selectedOpponent,
  setIsShowingSearchResult,
  setNewSelectedOpponentId,
}) => {
  const { register, errors: formErrors, handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });
  const { userID } = useSelector((state) => state.login);
  const { t } = useTranslation(['newChallenge']);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [searchError, setSearchError] = useState(null);

  const hasError = formErrors['bff-search'] || searchError;

  const fetchBFFSearchResult = async (searchString) => {
    try {
      const res = await fetchWrapper(searchBFFByCodeOrId, searchString);
      setSearchResult(res);
      setIsShowingSearchResult(true);
      // Validate if search result is current logged in user
      if (res?.Userid === userID)
        throw new Error('You can only challenge another user');
      if (!isNil(res.Userid)) {
        setOpponentType(opponentType);
        setNewSelectedOpponentId(opponentType, res.Userid);
      }
    } catch (err) {
      setSearchError(err.message);
    }
    setIsLoading(false);
  };
  const searchHandler = (data) => {
    setIsLoading(true);
    setSearchResult(null);
    setNewSelectedOpponentId(opponentType, '');
    setIsShowingSearchResult(false);
    setSearchError(null);
    fetchBFFSearchResult(data['bff-search']);
  };
  const selectOpponentHandler = (id) => {
    return () => {
      setOpponentType(opponentType);
      setNewSelectedOpponentId(opponentType, id);
    };
  };
  const onSearchValueChangeHandler = (e) => {
    if (e.target.value.length <= 0) {
      setSearchResult(null);
      setNewSelectedOpponentId(opponentType, '');
      setIsShowingSearchResult(false);
      setSearchError(null);
    }
  };
  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit(searchHandler)}>
        <StyledInput
          type="search"
          id="bff-search"
          name="bff-search"
          placeholder={t(
            'newChallenge:bff.inputPlaceholder',
            'Search BFF code or ID'
          )}
          disabled={isLoading}
          ref={register({
            required: t('newChallenge:bff.emptyError', 'Search BFF code or ID'),
            validate: {
              notBlank: (value) =>
                (!isNil(value) && value.trim().length > 0) ||
                t('newChallenge:bff.emptyError', 'Search BFF code or ID'),
            },
          })}
          onChange={onSearchValueChangeHandler}
        />
        <SearchButton type="submit" disabled={isLoading}>
          <img src={SearchIcon} alt="Search" />
        </SearchButton>
      </StyledForm>
      {!hasError && searchResult && (
        <SearchResult
          data={searchResult}
          onClickHandler={selectOpponentHandler}
          selectedOpponent={selectedOpponent}
        />
      )}
      {hasError && (
        <p
          style={{
            width: '175px',
            color: '#FF3B3B',
            fontSize: '0.875rem',
          }}
        >
          {formErrors['bff-search']?.message || searchError}
        </p>
      )}
      {!hasError && !searchResult && <EmptyDiv />}
    </StyledContainer>
  );
};

export default BFFSearchBar;
