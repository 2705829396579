import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { resetActivity } from 'store/kooClass/kooClassSlice';

import { Button, Spinner } from 'components/Shared';
import CheckIcon from 'assets/img/icon-correct.svg';
import KokoCredit from 'assets/img/icon-koko-credits.svg';
import Mascot from './mascot-self-check.svg';

import { Container, Left, Right, BubbleContainer } from './SelfCheck.styles';

const SelfCheck = ({ courseId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['activities']);
  const { topicId } = useParams();
  const {
    currentLesson,
    currentActivity,
    currentActivityLoading,
  } = useSelector((state) => state.kooClass);

  const desc = currentActivity && currentActivity.description;
  const arrayData = desc ? desc.split('[Tick] ').slice(1) : [];

  return (
    <Container>
      <Left>
        <div className="kooClass-info">
          {currentLesson && (
            <>
              <h4>{t('activities:selfCheck.title', 'Self-Check')}</h4>
              <p className="lesson">{currentLesson.name}</p>
              <p className="lesson-desc">{currentLesson.description}</p>
            </>
          )}
          <hr />
          <div className="lessons-list">
            {currentActivityLoading && <Spinner />}
            {!currentActivityLoading &&
              arrayData.map((item, index) => (
                <div className="lesson-learned" key={index}>
                  <img src={CheckIcon} alt="tick" />
                  <p className="lesson-details">{item}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="lessons-btn">
          <Button
            variant="primary"
            onClick={() => {
              history.push(`/kooClass/lessons/${topicId}?courseId=${courseId}`);
              dispatch(resetActivity());
            }}
            dataCy="all-lesson-btn"
          >
            {t('activities:selfCheck.btn', 'All Lessons')}
          </Button>
        </div>
      </Left>
      <Right>
        <BubbleContainer>
          <p>
            <Trans i18nKey="activities:selfCheck.mission">
              If you think you need more practice, hop over to
              <span
                className="mission-text"
                onClick={() => {
                  history.push('/mission/curriculum-list');
                }}
              >
                Mission
              </span>
              . You also can earn more
              <img src={KokoCredit} alt="koko-credit" />
              KoKo there. See you!
            </Trans>
          </p>
        </BubbleContainer>
        <img
          className="mascot"
          src={Mascot}
          alt="mascot"
          onClick={() => {
            history.push('/mission/curriculum-list');
          }}
        />
      </Right>
    </Container>
  );
};

export default SelfCheck;
