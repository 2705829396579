import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const InfoTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 14px;
    padding: 14px 22px;
    font-size: 14px;
    font-weight: 700;
    font-family: Linotte;
    display: inline-block;
  }
`;
