import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import {
  claimCP,
  setActiveEventQuestion,
  resetClaimCP,
} from 'store/event/eventSlice';

import Wrong from 'assets/img/icon-wrong.svg';
import Correct from 'assets/img/icon-correct.svg';
import CPIcon from 'assets/img/icon-challenge-point.svg';

import useDashboard from 'hooks/useDashboard';
import { eventSubmissionStatus, submissionResultIDs } from 'constants/index';

import { SubmitModal, Button, PopupModal } from 'components/Shared';
import QnResultStatus from '../QnResultStatus';
import ClaimedCPModal from '../ClaimedCPModal';
import B2CClaimedCPModal from '../B2CClaimedCPModal';

import { Container, ClaimedText } from './ResultDetail.styles';

const ResultDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['sundayMCResult', 'eventSolution']);
  const {
    eventSolution,
    eventSubmissions,
    isClaiming,
    claimCPError,
    claimCPResult,
  } = useSelector((state) => state.event);
  const { isDashboardv2 } = useDashboard();

  // modal states
  const [showModal, setShowModal] = useState(false);
  const [showClaimed, setShowClaimed] = useState(false);
  const [showCPWindowErrorModal, setShowCPWindowErrorModal] = useState(false);
  const openModal = () => {
    if (isClaiming) {
      return;
    }
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const claimCPs = () => {
    setShowModal(false);
    if (isClaiming) {
      return;
    }
    dispatch(claimCP(eventSolution.SMCSubId));
  };
  const closeCPWindowErrorModal = () => {
    setShowCPWindowErrorModal(false);
  };

  const cpToWin = eventSolution?.CorrectAnswers * 2;

  useEffect(() => {
    if (!isClaiming && claimCPError === null && claimCPResult !== null) {
      setShowClaimed(true);
    } else if (!isClaiming && claimCPError !== null) {
      setShowCPWindowErrorModal(true);
    }
  }, [claimCPError, claimCPResult, dispatch, isClaiming]);
  useEffect(() => {
    return () => {
      dispatch(resetClaimCP());
    };
  }, [dispatch]);
  return (
    <>
      <PopupModal show={showModal} backdrop="static">
        <SubmitModal
          header={t(
            'eventSolution:claimModal.claimPoints',
            'Claim Challenge Points'
          )}
          title={t(
            'eventSolution:claimModal.sureClaim',
            'Are you sure want to claim now?'
          )}
          desc={t(
            'eventSolution:claimModal.claimDesc',
            'You may claim you CPs between 6 am to 10 pm from Monday to Friday'
          )}
          noHandle={closeModal}
          yesHandle={claimCPs}
        />
      </PopupModal>
      <PopupModal show={showClaimed} backdrop="static">
        {isDashboardv2 ? (
          <B2CClaimedCPModal setShowClaimed={setShowClaimed} />
        ) : (
          <ClaimedCPModal setShowClaimed={setShowClaimed} />
        )}
      </PopupModal>
      <PopupModal show={showCPWindowErrorModal} backdrop="static">
        <SubmitModal
          header={t(
            'sundayMCResult:confirmModal.header',
            'Claim Challenge Points'
          )}
          title={claimCPError}
          desc=""
          noHandle={closeCPWindowErrorModal}
          hideYesButton
        />
      </PopupModal>
      <Container>
        {eventSolution && (
          <>
            <div className="qn-details">
              {eventSubmissions.map((item, index) => (
                <QnResultStatus
                  key={index}
                  icon={
                    submissionResultIDs[
                      item.SubmissionModel.SubmissionResult
                    ] === 'correct'
                      ? Correct
                      : Wrong
                  }
                  text={t(
                    'sundayMCResult:mainPage.qn',
                    {
                      number: index + 1,
                    },
                    `Question ${index + 1}`
                  )}
                />
              ))}
            </div>
            <div className="btn-section">
              <Button
                dataCy="smc-solution"
                variant="secondary"
                fontSize="21px"
                width="200px"
                margin="0 0 1.5rem"
                onClick={() => {
                  history.push('/events/solution');
                  dispatch(setActiveEventQuestion(1));
                }}
              >
                {t('sundayMCResult:mainPage.viewDetails', 'View Details')}
              </Button>
              {eventSubmissionStatus[eventSolution.SubmissionStatus] ===
                'Claimed' && (
                <ClaimedText>
                  {t(
                    'sundayMCResult:mainPage.hasClaimed',
                    'Your CPs has been claimed'
                  )}
                </ClaimedText>
              )}
              {eventSubmissionStatus[eventSolution.SubmissionStatus] ===
                'Submitted' &&
                eventSolution.CanBeClaimed === true && (
                  <div className="claim-section">
                    <p className="text">
                      <Trans
                        i18nKey="sundayMCResult:mainPage.claimResult"
                        values={{ cp: cpToWin }}
                        t={t}
                      >
                        {`You can claim `}
                        <img src={CPIcon} alt="icon" />{' '}
                        <span>{{ cp: cpToWin }}</span>
                        <span style={{ fontSize: '8px' }}> CPs</span> before
                        Fri, 10pm
                      </Trans>
                    </p>
                    <Button
                      dataCy="smc-claim"
                      fontSize="28px"
                      fontWeight="bold"
                      textShadow=" 0px 3px 6px rgba(0, 0, 0, 0.16)"
                      width="300px"
                      onClick={openModal}
                      variant="primary"
                      isDisabled={isClaiming}
                    >
                      {t('sundayMCResult:mainPage.claimMyCP', 'Claim My CPs')}
                    </Button>
                  </div>
                )}
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default ResultDetail;
