/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import {
  getDailyChallengeStatusBySubjectID,
  getDailyChallengeDetailsBySubjectID,
} from 'store/dailyChallenge/dailyChallengeSlice';
import useDashboard from 'hooks/useDashboard';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import LeaderboardButton from 'assets/img/dailychallenge/science/dc-leaderboard.png';
import DCScienceTitle from 'assets/img/dailychallenge/science/dc-science-title.png';
import CPPoints from 'assets/img/dailychallenge/science/cp-points.svg';
import { ScienceQnStatus } from 'constants/dailyChallenge';
import { CardItem } from './components';
import {
  EarnMoreCPsModal,
  ErrorModal,
  Spinner,
  Button,
  BackButton,
} from 'components/Shared';
import {
  StyledPage,
  CardSection,
  CardContainer,
  CPContainer,
  CPSection,
  HeaderContainer,
  HeaderContent,
} from './DCScience.styles';

const subjectId = 2;
const isScienceDailyChallengeClosed = (
  isDailyChallengeStatusError,
  dailyChallengeStatusErr,
  currDailyChallengeErr,
  currDailyChallenge
) => {
  if (
    !isNil(isDailyChallengeStatusError) ||
    !isNil(currDailyChallengeErr) ||
    !isNil(dailyChallengeStatusErr)
  ) {
    return {
      isClosed: true,
      errorMessage: isDailyChallengeStatusError || dailyChallengeStatusErr,
    };
  }
  if (currDailyChallenge?.IsClosed === true) {
    return {
      isClosed: true,
      errorMessage: 'Please come back between 06:00 AM and 10:00 PM',
    };
  }
  return {
    isClosed: false,
    errorMessage: '',
  };
};

const DCScience = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallenge', 'common']);
  const history = useHistory();
  const [showEarnMoreCPsModal, setShowEarnMoreCPsModal] = useState(false);
  const handleHideModal = () => setShowEarnMoreCPsModal(false);
  const isDesktop = useIsDesktop();
  const {
    isLoading,
    dailyChallengeStatusErr,
    dailyChallengeSubmissions,
    dailyChallengeStatus,
    currDailyChallenge,
    currDailyChallengeLoading,
    isErr: isDailyChallengeStatusError,
    currDailyChallengeErr,
  } = useSelector((state) => state.dailyChallenge);
  const { isDashboardv2 } = useDashboard();
  const [initialDailyChallengeStatus] = useState(dailyChallengeStatus);
  const { isClosed, errorMessage } = isScienceDailyChallengeClosed(
    isDailyChallengeStatusError,
    dailyChallengeStatusErr,
    currDailyChallengeErr,
    currDailyChallenge
  );
  // USEEFFECTS
  useEffect(() => {
    if (
      isNil(initialDailyChallengeStatus) ||
      initialDailyChallengeStatus?.subjectID !== subjectId
    ) {
      dispatch(getDailyChallengeStatusBySubjectID(subjectId)).then((result) => {
        if (!result.type.endsWith('fulfilled')) return;
        dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
      });
    } else {
      dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
    }
  }, [initialDailyChallengeStatus, dispatch]);

  return (
    <StyledPage>
      {!isLoading && !currDailyChallengeLoading && isClosed && (
        <ErrorModal
          backPage="/dashboard?view=koochallenge"
          errorMessage={errorMessage}
        />
      )}
      <Modal show={showEarnMoreCPsModal} onHide={handleHideModal} centered>
        <EarnMoreCPsModal subject={subjectId} onHide={handleHideModal} />
      </Modal>
      <HeaderContainer>
        {!isDesktop && <div className="miko-image" />}
        <HeaderContent>
          <div className="left">
            <BackButton
              backText={t('common:back', 'Back')}
              btnBgColor="#00dd9b"
              padding="0.3rem 1.2rem"
              isResponsive
              onClick={() =>
                isDashboardv2
                  ? history.push('/dashboard?view=koochallenge')
                  : history.goBack()
              }
            />
          </div>
          <div className="center">
            <img src={DCScienceTitle} alt="science-title" width="450px" />
          </div>
          <div className="right">
            <div
              onClick={() =>
                history.push({
                  pathname: '/leaderboard',
                  search: '?subject=science',
                })
              }
              className="nav-item"
              aria-hidden
            >
              <img src={LeaderboardButton} alt="Leaderboard" />
              <span>
                {t('dailyChallenge:banner.leaderboards', 'Leaderboards')}
              </span>
            </div>
          </div>
        </HeaderContent>
      </HeaderContainer>
      <CPSection>
        <CPContainer>
          <div className="cp-desc">
            <span className="cp-title">
              {t('dailyChallenge:science.myPoints', 'My Point(s)')}
            </span>
            <div className="cp-score-container">
              <img className="cp-img" src={CPPoints} alt="cp-points" />
              <span className="cp-score">
                {currDailyChallenge.TodayDpPoint}
              </span>
              <span className="cp-total">{` / 9 CP`}</span>
            </div>
          </div>
          <div>
            <Button
              className="cp-button"
              onClick={() => setShowEarnMoreCPsModal(true)}
            >
              {t('dailyChallenge:science.earnMoreCp', 'How to earn more CPs')}
            </Button>
          </div>
        </CPContainer>
      </CPSection>
      <CardSection>
        <CardContainer>
          {currDailyChallengeLoading && <Spinner />}
          {!currDailyChallengeLoading &&
            !isClosed &&
            dailyChallengeSubmissions.map((item, index) => (
              <CardItem
                key={item.Question.Id}
                number={index + 1}
                item={item}
                status={ScienceQnStatus[item.QuestionStatus]}
                nextArrow={dailyChallengeSubmissions.length !== index + 1}
              />
            ))}
        </CardContainer>
      </CardSection>
    </StyledPage>
  );
};

export default DCScience;
