import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AnimatedMiko from 'assets/img/loader/MIKO pump 400.gif';
import { ProgressBar } from 'react-bootstrap';
import {
  setHasSeenLoader,
  setHasScreenLoaded,
} from 'store/dashboard/studentDetailsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import { Container } from './Loader.styles';

const Loader = ({ isAPILoading, showLoader, setShowLoader }) => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const history = useHistory();
  const isBelowTablet = useIsBelowTablet();
  const { isLoading } = useSelector((state) => state.studentDetails);
  const { preferences } = useSelector((state) => state.userPreferences);
  const { isRead, directToNotifPage, pageNotif } = useSelector(
    (state) => state.notification
  );
  const { language } = useSelector((state) => state.login);
  const { currentSubjectProduct, getProductsError, countryEligibility } =
    useSelector((state) => state.plan);
  // Component States
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const hideLoader = useCallback(
    (redirectToNotif) => {
      setShowLoader(false);
      dispatch(setHasScreenLoaded(true));
      if (redirectToNotif === true) {
        if (!isBelowTablet) {
          history.push({ pathname: '/notification', state: { from: 'login' } });
        }
      } else {
        setTimeout(() => {
          dispatch(setHasSeenLoader(true));
        }, 2000);
      }
    },
    [setShowLoader, dispatch, isBelowTablet, history]
  );

  useEffect(() => {
    if (loadingPercentage < 50) {
      setTimeout(() => {
        setLoadingPercentage(loadingPercentage + 10);
      }, 250);
    } else if (!isAPILoading && loadingPercentage < 100) {
      setTimeout(() => {
        setLoadingPercentage(loadingPercentage + 10);
      }, 250);
    } else if (!isAPILoading && loadingPercentage === 100) {
      if (getProductsError !== null || countryEligibility.error) {
        return;
      }
      const shouldHide = !isNil(pageNotif) && !isRead && !directToNotifPage;
      if (currentSubjectProduct?.hasPremiumSchool !== true) {
        hideLoader(shouldHide);
      } else if (!isNil(preferences?.Value)) {
        hideLoader(shouldHide);
      }
    }
  }, [
    currentSubjectProduct,
    directToNotifPage,
    getProductsError,
    hideLoader,
    isAPILoading,
    isRead,
    loadingPercentage,
    pageNotif,
    setLoadingPercentage,
    preferences,
    countryEligibility.error,
  ]);

  return (
    <Container toFadeout={!showLoader}>
      {!isEmpty(language) && !isLoading && (
        <p className="welcome text">
          <Trans i18nKey="dashboard:loader.welcome">
            Welcome <span>to</span> KooBits
          </Trans>
        </p>
      )}
      <img src={AnimatedMiko} alt="Miko" height="150px" width="150px" />
      <ProgressBar variant="koobits" now={loadingPercentage} max={100} />
      {!isEmpty(language) && !isLoading && (
        <p className="loading text">
          {t('dashboard:loader.loading', 'Loading...')}
        </p>
      )}
    </Container>
  );
};

export default Loader;
