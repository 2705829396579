import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Header from 'components/Header';
import { Scene } from './components';
import { SuperHeroBanner } from '../components';

import useSuperheroChallengeLoader from 'store/superheroChallenge/superheroChallengeLoader';
import SuperVisionBanner from 'assets/img/superhero/superspeed-banner.png';

import { Content } from './SuperSpeed.style';

function SuperSpeedGame({ practice }) {
  const history = useHistory();
  const { superSpeedStatus } = useSuperheroChallengeLoader('superspeed');
  useEffect(() => {
    if (!practice && superSpeedStatus !== 3) {
      history.replace('/superhero');
    }
  }, [history, practice, superSpeedStatus]);
  return (
    <>
      <SuperHeroBanner
        banner={SuperVisionBanner}
        gradientType="blue"
        bgColor="rgba(0, 115, 214, 0.61)"
        backDestination="/superhero/superspeed"
      />
      <Header />
      <Content>
        <Scene practice={practice} />
      </Content>
    </>
  );
}

export default React.memo(SuperSpeedGame);
