import React from 'react';
import SideNavIcon from 'assets/img/assignment/assignment-view/icon-sidenav.svg';
import { PrintButton } from 'components/Assignment/AssignmentView/Header.styles';
import PrintIcon from 'assets/img/assignment/print-icon.svg';
import { SideNavButton } from './AssignmentHeader.styles';

const HeaderRight = ({
  isTablet,
  assignmentType,
  printUrl,
  sideNavClickHandler,
}) => {
  if (isTablet) {
    return (
      <SideNavButton onClick={sideNavClickHandler}>
        <img src={SideNavIcon} alt="Side Nav" />
      </SideNavButton>
    );
  }
  return (
    <>
      {printUrl.length > 0 && (
        <PrintButton
          assignmentType={assignmentType}
          data-cy="print-icon"
          rel="noopener noreferrer"
          target="_blank"
          href={printUrl}
        >
          <img src={PrintIcon} alt="Print" />
        </PrintButton>
      )}
    </>
  );
};

export default HeaderRight;
