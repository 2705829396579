import styled from 'styled-components';
import { isNil } from 'ramda';

const resultStyles = {
  win: {
    color: '#4A9F1A',
    bgColor: '#E8F9E3',
  },
  lose: {
    color: '#FF4B4B',
    bgColor: '#F8DED3',
  },
  draw: {
    color: '#4A9F1A',
    bgColor: '#E8F9E3',
  },
  pending: {
    color: '#444444',
    bgColor: '#ffffff',
  },
  rejected: {
    color: '#FF4B4B',
    bgColor: '#F8DED3',
  },
};

const headerColor = [
  { borderColor: '#E8B818', bgColor: '#FFE692' },
  { borderColor: '#E8B818', bgColor: '#FFE692' },
];

export const Row = styled.tr``;

export const TableHeader = styled.thead``;

export const HeaderRow = styled.tr`
  border: 1px solid ${(props) => headerColor[props.challengeType].borderColor};
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    th:first-child {
      padding-left: 20px;
    }
  }
`;

export const HeaderCell = styled.th`
  font-size: 0.875rem;
  font-weight: normal;
  color: #2e2e2e;
  background: ${(props) => headerColor[props.challengeType].bgColor};
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    text-align: left;
    vertical-align: middle !important;
  }
`;

export const Cell = styled.td`
  vertical-align: middle !important;
  border-top: none !important;
  background: ${(props) =>
    isNil(props.result) ? '#ffffff' : resultStyles[props.result].bgColor};
  .result {
    color: ${(props) =>
      isNil(props.result) ? 'inherit' : resultStyles[props.result].color};
    text-transform: capitalize;
  }
  .respond-date {
    font-size: 0.8125rem;
    color: rgba(20, 19, 19, 0.54);
  }
  .cps,
  .cps-earned-date {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.5rem;
  }
  .cps-earned-date {
    color: #ff3d3d;
  }
  .disable-result {
    color: #d0d0d0;
  }
  .check-result {
    font-size: 0.875rem;
  }
  a {
    color: #ff7121;
    font-weight: 600;
    text-decoration: underline;
    :hover {
      color: #ff8d4d;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin-left: 0.25rem;
    .cps-earned-date {
      margin-left: 0;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .cps-earned-date {
      margin-left: 0;
    }
  }
`;

export const Container = styled.div`
  .table-bordered {
    box-shadow: 0 3px 3px #dedede;
  }
  .table thead th {
    border: none;
  }
  .first-row {
    text-align: left;
    padding-left: 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .first-row {
      padding-left: 8px;
    }
  }
`;

export const EmptyChallengeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 150px;
  h1 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #141313;
  }
  img {
    margin-bottom: 3rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex-direction: column;
    margin-left: auto;
    img {
      order: 1;
    }
    div {
      order: 2;
    }
  }
`;

export const StyledCenter = styled.span`
  display: flex;
  justify-content: center;
`;

export const StyledTableFirstData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .challenger-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #ffffff;
  }

  .challenger-name {
    margin-left: 0.5rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    text-align: left;
    vertical-align: middle !important;
  }
`;

export const StyledMobileTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  background: ${(props) =>
    isNil(props.result) ? '#ffffff' : resultStyles[props.result].bgColor};
  .result {
    color: ${(props) =>
      isNil(props.result) ? 'inherit' : resultStyles[props.result].color};
    text-transform: capitalize;
  }
  .respond-date {
    font-size: 0.8125rem;
    color: rgba(20, 19, 19, 0.54);
  }
  .cps,
  .cps-earned-date {
    font-weight: 600;
    font-size: 0.875rem;
    margin-left: 0.5rem;
  }
  .cps-earned-date {
    color: #ff3d3d;
  }
  .disable-result {
    color: #d0d0d0;
  }
  .check-result {
    font-size: 0.875rem;
  }
  a {
    color: #ff7121;
    font-weight: 600;
    text-decoration: underline;
    :hover {
      color: #ff8d4d;
    }
  }
  .border-none {
    border: none;
  }
`;

export const StyledMobileTable = styled.div`
  td: nth-child(1) {
    border-left: none !important;
  }
  td: nth-child(3) {
    border-right: none !important;
  }
`;
