import React from 'react';
import { TypeIndicator } from 'components/Assignment';
import { StyledTitleText } from './AssignmentHeader.styles';

const assignmentTypesWithIndicator = ['test', 'mocktest'];

const HeaderMiddle = ({ isMobile, assignmentType, assignmentTitle }) => {
  const showIndicator = assignmentTypesWithIndicator.includes(assignmentType);
  if (isMobile) return null;
  return (
    <>
      {showIndicator && <TypeIndicator type={assignmentType} />}
      <StyledTitleText>{assignmentTitle}</StyledTitleText>
    </>
  );
};

export default HeaderMiddle;
