export const CATEGORIES = {
  1: { title: 'speed' },
  2: { title: 'attention' },
  3: { title: 'flexibility' },
  4: { title: 'adventure' },
  5: { title: 'memory' },
  6: { title: 'problem-solving' },
  201: { title: 'problem-solving2' },
  202: { title: 'energy' },
  203: { title: 'systems' },
  204: { title: 'life-science' },
  205: { title: 'interaction' },
  301: { title: 'word-woods', btnColor: '#FF741D' },
  302: { title: 'super-spells', btnColor: '#B63E8B' },
  303: { title: 'little-letter', btnColor: '#009B71' },
  304: { title: 'mystery-manor', btnColor: '#5C4FE8' },
  305: { title: 'tacky-typer', btnColor: '#1D7DF2' },
};
