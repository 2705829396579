import React from 'react';
import HTMLReactParser from 'html-react-parser';
import convertTagsToHtml from 'helpers/convertTagsToHtml';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveAnswerLocally,
  clearSavedAnswer,
} from 'store/assignment/assignmentSlice';
import {
  saveDailyChallengeAnsLocally,
  clearSavedDailyChallengeAnswer,
} from 'store/dailyChallenge/dailyChallengeSlice';
import {
  clearSavedSelfPracticeAns,
  saveSelfPracticeAnsLocally,
} from 'store/mission/missionSlice';
import {
  saveKooQuizAnswerLocally,
  clearKooQuizSavedAnswer,
} from 'store/kooQuiz/kooQuizSlice';
import {
  saveEventAnsLocally,
  clearEventSavedAnswer,
} from 'store/event/eventSlice';
import {
  savePeerChallengeAnsLocally,
  clearSavedPeerChallengeAnswer,
} from 'store/multiplayer/multiplayerSlice';
import {
  saveKooClassAnswerLocally,
  clearKooClassSavedAnswer,
} from 'store/kooClass/kooClassSlice';
import { submissionResultIDs } from 'constants/index';
import MCQCorrectIcon from 'assets/img/question/mcqCorrect.svg';
import MCQWrongIcon from 'assets/img/question/mcqWrong.svg';
import { isNil, isEmpty } from 'ramda';
import { Container, TextOption, ImgOption } from './MCQOptions.style';
import { FILTER_SUBJECT_STATUS } from 'constants/kooQuiz';
// Helper for showing whether mcq option is correct or wrong
const showCorrectWrong = (isQnCorrect, currentOption, isSelected) => {
  if (isSelected === true) {
    if (isQnCorrect === true) {
      return true;
    }
    if (isQnCorrect === false) {
      return currentOption.isCorrect === true;
    }
    return null;
  }
  return null;
};

const qnCorrectStatus = (submissionResult) => {
  switch (submissionResult) {
    case 'correct':
      return true;
    case 'wrong':
      return false;
    default:
      return null;
  }
};

// Event handler for selecting option
const selectMultiOption = (options, currentOption) => {
  const selectedOptionsCopy = [...options];
  // Check if current option is selected
  const currentOptionIndex = selectedOptionsCopy.findIndex(
    (option) => option === currentOption
  );
  // If selected option is already selected, remove it from array
  if (currentOptionIndex !== -1) {
    selectedOptionsCopy.splice(currentOptionIndex, 1);
  } // If selected option is not selected, add it to the array
  else {
    selectedOptionsCopy.push(currentOption);
  }
  return selectedOptionsCopy;
};
const checkCurrentSubmission = (
  correctAnswer,
  currentSubmission,
  assignmentType
) => {
  let subKeyName = 'SubmissionKeyValuePairs';
  let keyName = 'Key';
  if (assignmentType === 'KooClass') {
    subKeyName = 'submissionKeyValuePairs';
    keyName = 'key';
  }
  if (correctAnswer !== undefined) {
    if (correctAnswer === 'unknown') {
      return [];
    }
    return currentSubmission[subKeyName].map((answerKey) => answerKey[keyName]);
  }
  return currentSubmission[subKeyName].map((answerKey) => answerKey[keyName]);
};

// data is the mcqOptions object as parsed by the xml parser and contains the following properties:
// - correctOptions (array of correct mcq options for qn)
// - hasImgOption (true/false)
// - isMultiSelect (true/false)
// - isOrdered (true/false)
// - options (array of mcq options for qn)
const MCQOptions = ({
  data,
  isAnswerInputDisabled,
  page,
  assignmentType,
  submissionModel,
  correctAnswer,
  PCActiveQn,
}) => {
  const dispatch = useDispatch();
  // REDUX STATES
  const { activeQuestion, submissions, localSavedAnswers } = useSelector(
    (state) => state.assignment
  );
  const {
    activeQsDailyChallenge,
    dailyChallengeSubmissions,
    dailyChallengeLocalSavedAns,
  } = useSelector((state) => state.dailyChallenge);
  const { submitAns, selfPracticeLocalSavedAns } = useSelector(
    (state) => state.mission
  );
  const {
    kooQuizActiveQuestion,
    kooQuizSubmissions,
    kooQuizLocalSavedAnswers,
  } = useSelector((state) => state.kooQuiz);
  const {
    activeQuestionEvent,
    eventSubmissions,
    eventLocalSavedAns,
  } = useSelector((state) => state.event);
  const { peerChallengeLocalSavedAns, peerChallengeSubmissions } = useSelector(
    (state) => state.multiplayer
  );
  const {
    kooClassActiveQuestion,
    kooClassSubmissions,
    kooClassLocalSavedAnswers,
  } = useSelector((state) => state.kooClass);
  let activeQn;
  let localSavAns;
  let allSubmission;
  let clearAnswer;
  let saveAnswerLocal;
  if (page === 'daily-challenge') {
    activeQn = activeQsDailyChallenge;
    localSavAns = dailyChallengeLocalSavedAns;
    allSubmission = dailyChallengeSubmissions;
    clearAnswer = clearSavedDailyChallengeAnswer;
    saveAnswerLocal = saveDailyChallengeAnsLocally;
  } else if (page === 'self-practice') {
    activeQn = 1;
    allSubmission = [submitAns];
    localSavAns = selfPracticeLocalSavedAns;
    clearAnswer = clearSavedSelfPracticeAns;
    saveAnswerLocal = saveSelfPracticeAnsLocally;
  } else if (FILTER_SUBJECT_STATUS[page]) {
    activeQn = kooQuizActiveQuestion;
    allSubmission = kooQuizSubmissions;
    localSavAns = kooQuizLocalSavedAnswers;
    clearAnswer = clearKooQuizSavedAnswer;
    saveAnswerLocal = saveKooQuizAnswerLocally;
  } else if (page === 'sundayMC') {
    activeQn = activeQuestionEvent;
    localSavAns = eventLocalSavedAns;
    allSubmission = eventSubmissions;
    clearAnswer = clearEventSavedAnswer;
    saveAnswerLocal = saveEventAnsLocally;
  } else if (page === 'peer-challenge') {
    activeQn = PCActiveQn;
    localSavAns = peerChallengeLocalSavedAns;
    allSubmission = peerChallengeSubmissions;
    clearAnswer = clearSavedPeerChallengeAnswer;
    saveAnswerLocal = savePeerChallengeAnsLocally;
  } else if (page === 'KooClass') {
    activeQn = kooClassActiveQuestion;
    localSavAns = kooClassLocalSavedAnswers;
    allSubmission = kooClassSubmissions;
    clearAnswer = clearKooClassSavedAnswer;
    saveAnswerLocal = saveKooClassAnswerLocally;
  } else {
    activeQn = activeQuestion;
    localSavAns = localSavedAnswers;
    allSubmission = submissions;
    clearAnswer = clearSavedAnswer;
    saveAnswerLocal = saveAnswerLocally;
  }

  let subResName = 'SubmissionResult';
  if (assignmentType === 'KooClass') {
    subResName = 'submissionResult';
  }
  const currentSubmission =
    page !== 'solution'
      ? page === 'self-practice'
        ? allSubmission[activeQn - 1]?.QuestionSubmissionModel
        : allSubmission[activeQn - 1]?.SubmissionModel
      : submissionModel;

  const selectedAnswers =
    isNil(localSavAns[activeQn - 1]) !== true && page !== 'solution'
      ? localSavAns[activeQn - 1]
      : !isNil(currentSubmission) && !isEmpty(currentSubmission)
      ? checkCurrentSubmission(correctAnswer, currentSubmission, assignmentType)
      : [];
  const isQnCorrect =
    !isNil(currentSubmission) && !isEmpty(currentSubmission)
      ? qnCorrectStatus(submissionResultIDs[currentSubmission[subResName]])
      : null;
  // Event Handlers
  const onSelectHandler = (option) => {
    dispatch(clearAnswer(activeQn - 1));
    // Multi option select mcq
    if (data.isMultiSelect === true) {
      const newSelectedOptions = selectMultiOption(
        selectedAnswers,
        option.refId
      );
      dispatch(
        saveAnswerLocal({
          index: activeQn - 1,
          answers: newSelectedOptions,
        })
      );
    }
    // Single option select mcq
    else if (option.refId !== selectedAnswers[0]) {
      dispatch(
        saveAnswerLocal({
          index: activeQn - 1,
          answers: [option.refId],
        })
      );
    }
  };
  return (
    <Container hasImgOption={data.hasImgOption} data-cy="mcq-options">
      {data.options.map((option) => {
        // Remove non-breaking space entities
        const filteredHTML = option.content.replace(/&nbsp;/g, ' ');
        const isSelected =
          selectedAnswers.findIndex((opts) => opts === option.refId) !== -1;

        const isCorrect =
          isAnswerInputDisabled === true
            ? showCorrectWrong(isQnCorrect, option, isSelected)
            : null;
        return data.hasImgOption ? (
          <ImgOption
            data-cy={`option-img-${option.refId}`}
            className="mcq-img-option"
            key={option.refId}
            isAnswerInputDisabled={isAnswerInputDisabled}
            isQnCorrect={isQnCorrect}
            isCorrect={isCorrect}
            selected={isSelected}
            onClick={() => {
              if (isAnswerInputDisabled === false) onSelectHandler(option);
            }}
          >
            {HTMLReactParser(convertTagsToHtml(option.content))}
            {(isCorrect === true || isCorrect === false) && (
              <img
                className={`is-correct-icon ${
                  isCorrect
                    ? `correct-icon-${option.refId}`
                    : `wrong-icon-${option.refId}`
                }`}
                src={isCorrect ? MCQCorrectIcon : MCQWrongIcon}
                alt={isCorrect ? 'Correct' : 'Wrong'}
              />
            )}
          </ImgOption>
        ) : (
          <TextOption
            data-cy={`option-${option.refId}`}
            className="mcq-text-option"
            key={option.refId}
            isAnswerInputDisabled={isAnswerInputDisabled}
            isQnCorrect={isQnCorrect}
            isCorrect={isCorrect}
            selected={isSelected}
            onClick={() => {
              if (isAnswerInputDisabled === false) onSelectHandler(option);
            }}
          >
            <p>{HTMLReactParser(convertTagsToHtml(filteredHTML))}</p>
            {(isCorrect === true || isCorrect === false) && (
              <img
                className={`is-correct-icon ${
                  isCorrect
                    ? `correct-icon-${option.refId}`
                    : `wrong-icon-${option.refId}`
                }`}
                src={isCorrect === true ? MCQCorrectIcon : MCQWrongIcon}
                alt={isCorrect === true ? 'Correct' : 'Wrong'}
              />
            )}
          </TextOption>
        );
      })}
    </Container>
  );
};

export default MCQOptions;
