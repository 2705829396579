import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { newLoginRequest, newRefreshTokenRequest } from 'services/login';
import { history } from 'Routes';
import logout from 'helpers/logout';
import { isNil } from 'ramda';
import jwt_decode from 'jwt-decode';
import { v4 } from 'uuid';
import Cookies from 'js-cookie';

// HELPERS
const checkIsParentAccount = (roles) => {
  return !isNil(roles.find((role) => role.Id === 8));
};

const initialState = {
  user: null,
  isParentAccount: null,
  userID: null,
  sessionID: null,
  isLoading: false,
  error: null,
  timezone: 'Asia/Singapore',
  mySecret: '',
  language: [],
};

export const getToken = createAsyncThunk('login/getToken', async (params) => {
  const { username, password, redirect } = params;
  try {
    const response = await newLoginRequest(username, password);
    localStorage.setItem('access_token', response.access_token);
    localStorage.setItem('refresh_token', response.refresh_token);
    if (isNil(redirect)) {
      history.push('/dashboard');
    } else {
      history.push(`/${redirect}`);
    }
    return response;
  } catch (err) {
    throw new Error(err?.message ?? 'Login failed');
  }
});

export const refreshToken = createAsyncThunk(
  'login/refreshToken',
  async (params) => {
    const { redirect } = params;
    try {
      const response = await newRefreshTokenRequest();
      if (!isNil(response.access_token)) {
        localStorage.setItem('access_token', response.access_token);
        Cookies.set('sp_access_token', response.access_token, {
          path: '/',
          'max-age': response.expires_in.toString(),
          domain: 'koobits.com',
        });
      }
      if (!isNil(response.refresh_token)) {
        localStorage.setItem('refresh_token', response.refresh_token);
        Cookies.set('sp_refresh_token', response.refresh_token, {
          path: '/',
          'max-age': response.expires_in.toString(),
          domain: 'koobits.com',
        });
      }

      return response;
    } catch (err) {
      logout(redirect);
      throw new Error(err?.message ?? 'Refresh token failed');
    }
  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearErr: (state) => {
      state.error = null;
    },
    setUserId: (state, action) => {
      state.userID = action.payload;
    },
    setErr: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: {
    [getToken.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getToken.fulfilled]: (state, action) => {
      const decoded = jwt_decode(action.payload.access_token);
      const { role, myLocale } = decoded;
      const roles = JSON.parse(role);
      state.user = decoded;
      state.isParentAccount = checkIsParentAccount(roles);
      state.userID = parseInt(decoded.user_id, 10);
      const sessionId = localStorage.setItem('sessionId', v4());
      state.sessionID = sessionId;
      state.isLoading = false;
      state.timezone = decoded.myTimeZone;
      state.mySecret = decoded.mySecret;
      if (!isNil(myLocale)) {
        state.language = JSON.parse(myLocale);
      }
    },
    [getToken.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [refreshToken.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [refreshToken.fulfilled]: (state, action) => {
      const decoded = jwt_decode(action.payload.access_token);
      const { role, myLocale } = decoded;
      const roles = JSON.parse(role);
      state.user = decoded;
      state.isParentAccount = checkIsParentAccount(roles);
      state.userID = parseInt(decoded.user_id, 10);
      // unique session for event tracking purpose
      const sessionId = localStorage.getItem('sessionId') || v4();
      state.sessionID = sessionId;
      state.isLoading = false;
      state.timezone = decoded.myTimeZone;
      state.mySecret = decoded.mySecret;
      if (!isNil(myLocale)) {
        state.language = JSON.parse(myLocale);
      }
    },
    [refreshToken.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = loginSlice;
export const { clearErr, setUserId, setErr } = actions;
export default reducer;
