import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import postLocalePreferences from 'services/localePreferences';
import { postUserPreferences } from 'services/userPreferences';

import { fetchWrapper } from 'services/login';

export const updateLocalePreferences = createAsyncThunk(
  'userPreferences/updateLocalePreferences',
  async (params) => {
    try {
      const res = await fetchWrapper(postLocalePreferences, params);
      return res;
    } catch (error) {
      throw new Error(
        error?.message ?? 'Update user locale preferences failed'
      );
    }
  }
);

export const updateUserPreferences = createAsyncThunk(
  'userPreferences/updateUserPreferences',
  async (value) => {
    try {
      const res = await fetchWrapper(postUserPreferences, value);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Update user preferences failed');
    }
  }
);

const initialState = {
  preferences: null,
  updatePreferences: null,
  isLoading: false,
  error: null,
  data: [],
  localPreferences: {
    currentLocale: 'en',
    isLoading: false,
    error: null,
    updateLocale: null,
  },
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    setUserPreferences: (state, action) => {
      let preferences = null;
      let localPreferencesValue = 'en';
      action.payload.forEach((preference) => {
        const lowerName = preference.Name.toLowerCase().trim();
        if (lowerName === 'subscription_default') {
          preferences = preference;
        } else if (lowerName === 'currentlocale') {
          localPreferencesValue = preference.Value;
        }
      });
      state.data = action.payload;
      state.preferences = preferences;
      state.localPreferences.currentLocale = localPreferencesValue;
    },
  },
  extraReducers: {
    [updateUserPreferences.pending]: (state) => {
      state.isLoading = true;
      state.updatePreferences = null;
      state.error = null;
    },
    [updateUserPreferences.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updatePreferences = action.payload;
      state.error = null;
    },
    [updateUserPreferences.rejected]: (state, action) => {
      state.isLoading = false;
      state.updatePreferences = null;
      state.error = action.error.message;
    },
    [updateLocalePreferences.pending]: (state) => {
      state.localPreferences.isLoading = true;
      state.localPreferences.updateLocale = null;
      state.localPreferences.error = null;
    },
    [updateLocalePreferences.fulfilled]: (state, action) => {
      state.localPreferences.isLoading = false;
      state.localPreferences.updateLocale = action.payload;
    },
    [updateLocalePreferences.rejected]: (state, action) => {
      state.localPreferences.isLoading = false;
      state.localPreferences.error = action.error.message;
    },
  },
});

const { reducer, actions } = userPreferencesSlice;
export const { setUserPreferences } = actions;
export default reducer;
