import styled from 'styled-components';

export const SkillDetailsContainer = styled.div`
  background: white;
  width: 360px;
  height: 90%;
  position: fixed;
  top: 7.8rem;
  right: 0;
  overflow-y: auto;
  border-left: solid 1px rgba(112, 112, 112, 0.29);
  @media (max-width: 1024px) {
    width: 330px;
  }
`;

export const SkillDetailsHeader = styled.div`
  border-bottom: solid 1px rgba(112, 112, 112, 0.29);
  padding: 1rem 0.75rem;
  .close-panel {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    cursor: pointer;
    opacity: 0.2;
    &:hover {
      opacity: 1;
    }
  }
  .skill-name {
    font-size: 0.8rem;
  }
  .skill-number {
    font-size: 0.875rem;
    margin-right: 1rem;
  }
  .skill-description {
    font-size: 1rem;
    color: #828282;
  }
  .student-percentage {
    font-size: 1.125rem;
    margin-right: 2rem;
    color: ${(props) => props.statusColor.percentageColor};
  }
  .class-avg-title {
    font-size: 0.625rem;
    margin-right: 0.5rem;
  }
  .class-avg-percentage {
    font-size: 0.9375rem;
    color: #060606;
  }
  p,
  span {
    font-family: 'Nunito';
    color: #202020;
  }
`;

export const SkillBadge = styled.span`
  font-size: 11px;
  background: ${({ statusColor }) => statusColor.bg};
  border-radius: 12px;
  border: ${({ statusColor }) => `solid 1px ${statusColor.borderColor}`};
  color: ${({ statusColor }) => statusColor.textColor} !important;
  padding: 0.1rem 0.5rem;
`;

export const QuestionContainer = styled.div`
  color: #202020;
  font-family: 'Nunito';
  padding: 1rem 0.75rem;
  .question-title {
    font-size: 0.875rem;
  }
  .question-content {
    font-weight: 600;
    font-size: 1rem;
  }
  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    overflow-y: auto;
    height: 70%;
  }
`;

export const SolutionContainer = styled.div`
  margin-bottom: 2rem;
  color: #202020;
  font-weight: 600;
  font-family: 'Nunito';
  padding: 0rem 0.75rem 1rem;
  .solution-title {
    color: #5e925c;
    font-size: 0.875rem;
  }
  .solution-content {
    font-size: 1rem;
  }
  .fraction-wrap {
    display: inline-block;
    vertical-align: middle;
  }
  .fraction-whole-part {
    display: inline-block;
    vertical-align: baseline;
  }
  .fraction-numerator-denominator-part {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.25em;
  }
  .fraction-numerator {
    display: block;
    border-bottom: 1px solid black;
    padding: 0.25em;
    text-align: center;
  }
  .fraction-denominator {
    display: block;
    padding: 0.25em;
    text-align: center;
  }
`;

export const ButtonBox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 27px;
  font-family: 'Nunito';
  border: 1px solid #d9d9d9;
  background-color: ${(props) => (props.disabled ? '#F8F8F8' : '#ffffff')};
  color: ${(props) => (props.disabled ? '#D8D8D8' : '#aaaaaa')};
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    background-color: ${(props) => (props.disabled ? '#F8F8F8' : '#ffffff')};
    color: ${(props) => (props.disabled ? '#D8D8D8' : '#83d5f4')};
    border-color: ${(props) => (props.disabled ? '#D9D9D9' : '#c8dee3')};
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0;
  }
`;

export const QuestionColumn = styled.div`
  display: flex;
  flex-direction: row;

  .question {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => (props.number ? '#FF5B00' : '#BCBCBC')};
  }

  .question-text {
    width: 40px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: rgba(93, 85, 85, 0.55);
  }
`;
