import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: 1.5rem;
`;

export const StyledGenderCheckBox = styled.div`
  margin-bottom: 2.5rem;
  margin-left: 2rem;

  .error-gender {
    text-align: center;
    color: #ff0000;
    font-size: 0.75rem;
    margin-top: -1rem;
  }
`;

export const StyledInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;

  .inp-title {
    font-weight: 600;
    width: 30%;
    text-align: right;
    margin-right: 1.5rem;
  }

  .number {
    margin-top: -2rem;
  }

  .phone-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
    width: 70%;
  }
  .phone-container-box {
    display: flex;
    flex-direction: row;
    width: 110%;
  }
  .verification-msg {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    font-size: 12px;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  padding-bottom: 2rem;
  margin-top: 1rem;
`;

export const StyledErrMsg = styled.p`
  color: red;
  text-align: center;
  width: 500px;
  word-wrap: break-word;
`;

export const StyledForm = styled.form`
  width: 500px;
  .input-container {
    margin-bottom: 1rem;
  }
  .error-input {
    text-align: center;
    color: #ff0000;
    font-size: 0.75rem;
    margin-left: -15%;
  }
  .error-name-input {
    margin-left: -9%;
  }
  .error-phone-input {
    color: #ff0000;
    font-size: 0.75rem;
    align-self: flex-start;
  }

  .check-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: -1rem;
    margin-bottom: 0.5rem;
    p {
      font-size: 14px;
      margin-bottom: 0px;
      color: #0d0d0d;
    }
  }
`;
