import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(p) => (p.opacity ? p.opacity : '0.5')});
  display: flex;
  justify-content: center;

  .dc-alert-modal {
    position: relative;
    width: 470px;
    height: 237px;
    background-color: #ffffff;
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      content: '!';
      display: inline-block;
      background-color: #ff6100;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      color: #fff;
      font-size: 40px;
      line-height: 62px;
      position: absolute;
      top: -31px;
      left: calc(50% + 2px);
      transform: translateX(-50%);
    }
  }

  p {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  button {
    border: none;
    background-color: #ff6100;
    width: 96px;
    height: 33px;
    line-height: 33px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 600;
  }
`;

export const Loader = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  & {
    margin: 30vh auto;
    font-size: 10px;
    position: relative;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
