import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { unlockGame } from 'store/brainGame/brainGameSlice';

import { sendEventTracking } from 'helpers/UserEventTracking';
import { subjectNames } from 'constants/products';

import IconKoKo from './KoKo.svg';
import IconTick from './tick.svg';

import { PopupModal, NotEnoughKokoModal } from 'components/Shared';
import { PopupTimeout } from '../../../components';
import { UnlockGameModal, PopupError } from '..';
import { Card, CardImg, WrapUnlock2 } from './CardGame.style';

const CardGame = ({
  gameId,
  name,
  img,
  game,
  unlock,
  price,
  subject,
  categoryBtnColor,
}) => {
  const dispatch = useDispatch();

  const { timeRemaining, error, isLoading } = useSelector(
    (state) => state.brainGame
  );

  const { t } = useTranslation(['brainGames']);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [showTimeOut, setShowTimeOut] = useState(false);
  const [showKokoError, setShowKokoError] = useState(false);

  const closeTimeOutModal = useCallback(() => {
    setShowTimeOut(false);
  }, []);

  const closeUnlockModal = useCallback(() => {
    setShowUnlockModal(false);
  }, []);

  const closeKokoErrorModal = useCallback(() => {
    setShowKokoError(false);
  }, []);

  const openUnlockModal = useCallback(() => {
    if (isLoading) {
      return;
    }
    if (timeRemaining <= 0) {
      setShowTimeOut(true);
    } else {
      setShowUnlockModal(true);
    }
  }, [isLoading, timeRemaining]);

  const history = useHistory();
  const goPlay = useCallback(() => {
    if (!unlock) {
      dispatch(unlockGame(gameId)).then((data) => {
        setShowUnlockModal(false);
        if (unlockGame.fulfilled.toString() === data.type) {
          history.push(`/brain-game/play/${game}?subject=${subject}`);
          sendEventTracking('brain_games', 'game_unlock', {
            sub: subjectNames[subject?.toLowerCase()],
            bgi: gameId,
          });
        } else {
          setShowKokoError(data.error.message === 'BGM003' ? 'BGM003' : true);
        }
      });
    } else {
      history.push(`/brain-game/play/${game}?subject=${subject}`);
    }
  }, [dispatch, game, gameId, history, subject, unlock]);

  const checkInsufficientKoKoError = () => {
    return showKokoError === 'BGM003';
  };

  return (
    <>
      <PopupModal
        show={showUnlockModal}
        onHide={closeUnlockModal}
        centered
        backdrop="static"
        size="lg"
      >
        <UnlockGameModal
          img={img}
          onUnlock={goPlay}
          onClose={closeUnlockModal}
          unlock={unlock}
        />
      </PopupModal>
      <PopupModal
        show={showTimeOut}
        onHide={closeTimeOutModal}
        centered
        backdrop="static"
      >
        <PopupTimeout onClose={closeTimeOutModal} />
      </PopupModal>
      {showKokoError &&
        (checkInsufficientKoKoError() ? (
          <PopupModal show backdrop="static">
            <NotEnoughKokoModal yesHandle={closeKokoErrorModal} />
          </PopupModal>
        ) : (
          <PopupModal
            show={showKokoError}
            onHide={closeKokoErrorModal}
            centered
            backdrop="static"
          >
            <PopupError onClose={closeKokoErrorModal} text={error} />
          </PopupModal>
        ))}
      <Card>
        <CardImg src={img} alt={name} onClick={openUnlockModal} />
        <WrapUnlock2
          onClick={openUnlockModal}
          categoryBtnColor={categoryBtnColor}
          unlock={unlock}
        >
          {unlock ? (
            <div>
              <span className="unlock-text">
                {t('brainGames:cardGame.play', 'Play')}
              </span>
              <div className="tick">
                <img src={IconTick} alt="tick" />
              </div>
            </div>
          ) : (
            <div>
              <span className="unlock-text">
                {t('brainGames:cardGame.unlock', 'Unlock & Play')}
              </span>
              <img src={IconKoKo} alt="koko" className="koko" />
              <span className="koko-text">{price}</span>
            </div>
          )}
        </WrapUnlock2>
      </Card>
    </>
  );
};

export default React.memo(CardGame);
