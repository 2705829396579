import React from 'react';
import moment from 'moment';
import { isNil } from 'ramda';

import {
  SUBS_DISPLAY_STATUS,
  PAYMENT_PROVIDER,
  BUNDLE_TYPE_SUBJECT_COUNT,
} from '../../constants';

import currencyLocaleFormatter from 'helpers/currencyLocaleFormatter';

import { ReactComponent as CC } from './imgs/icon-credit-card.svg';

import {
  SubsBottom,
  SubsTop,
  SubjectPlanLabel,
  StatusLabel,
} from './SubscriptionV2.styles';

const TrialContent = ({ sub, setSelectedSub, setShowCancelModal, userId }) => {
  const clickHandler = () => {
    window.location.href = `${process.env.REACT_APP_PAYMENT_V2_URL}pay/?campaignCode=ParentPortal&userId=${userId}&bundleId=${sub.bundleId}`;
  };

  return (
    <>
      <SubsTop
        active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
      >
        <div className="bundle-info">
          <p className="bundle-name">
            {BUNDLE_TYPE_SUBJECT_COUNT[sub.bundleTypeId] !== '1' && (
              <SubjectPlanLabel>
                {BUNDLE_TYPE_SUBJECT_COUNT[sub.bundleTypeId]} subjects Plan
              </SubjectPlanLabel>
            )}
            {sub.bundleName}{' '}
            <StatusLabel
              active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
            >
              {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'
                ? '7-Day Free Trial'
                : 'Expired'}
            </StatusLabel>
          </p>
          <p className="bundle-period">
            Free trial{' '}
            {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired'
              ? 'expired'
              : 'expires'}{' '}
            on {moment(sub.endDate).format('DD MMM YYYY')}
          </p>
        </div>
        {sub.isAutoRenewalOn ? (
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              setSelectedSub(sub);
              setShowCancelModal(true);
            }}
          >
            {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired'
              ? 'Cancel Auto-Renewal'
              : 'Cancel Free-Trial'}
          </button>
        ) : (
          <div>
            {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired' ? (
              <button
                className="renew-btn"
                type="button"
                onClick={clickHandler}
              >
                Buy Now
              </button>
            ) : (
              <p className="cancelled-text">Cancelled Free Trial</p>
            )}
          </div>
        )}
      </SubsTop>
      <SubsBottom
        active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
      >
        <div className="bundle-price">
          {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] === 'expired' ? (
            ''
          ) : sub.isAutoRenewalOn ? (
            <>
              <div className="payment-details">
                Payment Method:{' '}
                {PAYMENT_PROVIDER[sub.paymentProviderId] === 'stripe' && <CC />}{' '}
                **************{sub.paymentMethodText} (Upcoming payment:{' '}
                {sub?.renewalCurrency}{' '}
                {!isNil(sub.renewalCurrency) &&
                  !isNil(sub?.renewalAmount) &&
                  currencyLocaleFormatter(
                    sub?.renewalCurrency,
                    sub?.renewalAmount
                  ).formattedString}{' '}
                on {moment(sub.endDate).format('DD MMM YYYY')}）
              </div>
            </>
          ) : (
            <div className="period-date">
              This account still can be used until free trial end.
            </div>
          )}
        </div>
      </SubsBottom>
    </>
  );
};

export default TrialContent;
