import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackIcon from 'assets/img/assignment/back-icon.svg';
import LeftIcon from 'assets/img/mission/hotsv2/hots-mobile-bg-left.svg';
import RightIcon from 'assets/img/mission/hotsv2/hots-mobile-bg-right.svg';
import { HotsInfoModal, Hotsv2TopicsChart } from './components';

import {
  StyledHeader,
  StyledContent,
  StyledButton,
  StyledInfoButton,
} from './HotsIntermediatev2.styles';

const HotsIntermediatev2 = () => {
  const history = useHistory();
  const { t } = useTranslation(['hotsIntermediate']);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const openModalHandler = () => {
    setShowInfoModal(true);
  };
  return (
    <>
      <StyledHeader>
        <HotsInfoModal
          show={showInfoModal}
          setShowInfoModal={setShowInfoModal}
        />
        <StyledButton onClick={() => history.push('/mission/curriculum-list')}>
          <img src={BackIcon} alt="" />
          <span>{t('hotsIntermediate:topbar.back', 'Back')}</span>
        </StyledButton>
        <span className="title desktop">
          Higher-Order Thinking Skills (HOTS)
        </span>
        <span className="title mobile">HOTS</span>
        <StyledInfoButton onClick={openModalHandler}>?</StyledInfoButton>
        <img src={LeftIcon} alt="mobile left icon" className="left-icon" />
        <img src={RightIcon} alt="mobile right icon" className="right-icon" />
      </StyledHeader>
      <StyledContent>
        <Hotsv2TopicsChart />
      </StyledContent>
    </>
  );
};

export default HotsIntermediatev2;
