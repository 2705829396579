import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger } from 'react-bootstrap';
import { getPCCurrentCurriculum } from 'services/multiplayerv2';
import { fetchWrapper } from 'services/login';
import { getUserAvailableCurriculumList } from 'store/curriculum/curriculumSlice';

import WarningIcon from 'assets/img/dailychallengev2/warning.svg';

import {
  StyledContainer,
  HideOnMobile,
  InfoTooltip,
} from './AssignedCurriculum.styled';
// Helper
const checkIfUserHasMathB2CSubscription = (products) => {
  if (!products) return false;
  const mathProduct = products.find(
    (product) => product.subject.toLowerCase().trim() === 'math'
  );
  if (!mathProduct) return false;
  const b2cMathSubscription = mathProduct.nonExpiredSubscriptionProducts.find(
    (subscription) => subscription.productType.toLowerCase().trim() !== 'school'
  );
  return !!b2cMathSubscription;
};

const AssignedCurriculum = ({ subject, hasFirstLoad, setHasFirstLoad }) => {
  const { t } = useTranslation(['dailyChallengev2']);
  const { userID } = useSelector((state) => state.login);
  const { products } = useSelector((state) => state.plan);
  const dispatch = useDispatch();
  const { userAvailableCurriculumList } = useSelector(
    (state) => state.curriculum
  );
  const hasMathB2CSubscription = checkIfUserHasMathB2CSubscription(products);
  const [pcCurriculum, setPCCurriculum] = useState({
    status: 'idle',
    error: null,
    data: null,
  });
  useEffect(() => {
    if (
      isEmpty(userAvailableCurriculumList.curriculumList) &&
      isNil(userAvailableCurriculumList.error)
    ) {
      dispatch(getUserAvailableCurriculumList());
    }
    // eslint-disable-next-line
  }, [
    userAvailableCurriculumList.curriculumList,
    userAvailableCurriculumList.error,
  ]);
  // Get Math PC Curriculum
  useEffect(() => {
    let isMounted = true;
    if (!userID || subject !== 'math' || !hasMathB2CSubscription) return;
    if (hasFirstLoad) return;
    if (isEmpty(userAvailableCurriculumList.curriculumList)) return;
    fetchWrapper(getPCCurrentCurriculum, { userID })
      .then((res) => {
        if (isMounted) {
          setPCCurriculum({
            status: 'fulfilled',
            data: userAvailableCurriculumList.curriculumList.find(
              (curriculum) => curriculum.CurriculumID === res
            ),
            error: null,
          });
          setHasFirstLoad(true);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setPCCurriculum({
            status: 'rejected',
            data: null,
            error: err.message,
          });
        }
      });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userID,
    subject,
    hasMathB2CSubscription,
    hasFirstLoad,
    userAvailableCurriculumList.curriculumList,
  ]);
  if (pcCurriculum.status !== 'fulfilled') return null;

  return (
    <StyledContainer>
      <span>{pcCurriculum.data?.curriculumName}</span>
      <HideOnMobile>
        <OverlayTrigger
          transition={false}
          overlay={
            <InfoTooltip id="difficultyInfo">
              {t(
                `dailyChallengev2:toolkit.curriculumInfo`,
                'Go to setting page to change curriculum.'
              )}
            </InfoTooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...triggerHandler}>
              <img ref={ref} width="18" height="18" src={WarningIcon} alt="" />
            </div>
          )}
        </OverlayTrigger>
      </HideOnMobile>
    </StyledContainer>
  );
};

export default AssignedCurriculum;
