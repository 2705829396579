import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import useSound from 'use-sound';
import RobotAvatar from 'assets/img/multiplayer/matching/Mini-Robot-PP-03.png';
import FoundSound from 'assets/audio/selected.mp3';
import FireworksAnimation from './98350-fireworks.json';
import { StyledContainer } from './OpponentFoundScene.styles';

const Fireworks = () => {
  const options = {
    animationData: FireworksAnimation,
    loop: false,
    autoplay: true,
  };
  return (
    <Lottie options={options} isStopped={false} isPaused={false} width={400} />
  );
};

const OpponentFoundScene = ({
  randomOpponents = [],
  selectedOpponentIndex = 0,
  onFinishAnimation,
}) => {
  const foundOpponent = randomOpponents[selectedOpponentIndex] ?? {};
  const soundOptions = {
    interrupt: true,
  };
  const [play, { stop }] = useSound(FoundSound, soundOptions);

  useEffect(() => {
    play();
    setTimeout(() => {
      onFinishAnimation();
    }, 2500);
    return () => {
      stop();
    };
  }, [onFinishAnimation, play, stop]);
  return (
    <StyledContainer>
      <div className="fireworks">
        <Fireworks />
      </div>
      <img
        src={foundOpponent?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar;
        }}
        alt=""
        width="132px"
        height="132px"
      />
      <p className="opponent-name">{foundOpponent.FullName ?? ''}</p>
    </StyledContainer>
  );
};

export default OpponentFoundScene;
