import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import i18n from 'i18n';
import { useHistory, Link } from 'react-router-dom';
import Icon1 from 'assets/img/multiplayer/peer-challenge-card-1.svg';
import Icon2 from 'assets/img/multiplayer/peer-challenge-card-2.svg';

import { multiplayerListStyles } from 'constants/multiplayer';
import { rwd } from 'Theme';

import { Card, DetailsInfo, Button } from 'components/Shared';
import {
  MainContainer,
  LeftContent,
  SubCardContainer,
  RightContent,
  HideAtMobile,
  ShowAtMobile,
  RightContainer,
} from './PeerChallengeCard.styles';

const ChallengeEnergy = ({
  remaining,
  lowercaseSubject,
  totalEnergy,
  isMobile = false,
}) => {
  const keyName = isMobile
    ? 'multiplayerChallengeList:card.remainingChallengesMobile'
    : 'multiplayerChallengeList:card.remainingChallenges';
  const usedEnergy = totalEnergy - remaining;
  const energy = () => {
    if (remaining <= 0 || remaining === null) {
      return Array(totalEnergy).fill('empty');
    }
    const remainingEnergy = remaining > 0 ? Array(remaining).fill('full') : [];
    const used = usedEnergy > 0 ? Array(usedEnergy).fill('empty') : [];
    const result = [...remainingEnergy, ...used];
    return result;
  };

  return (
    <div className="challenge-energy">
      <div className="challenge-icon">
        {energy(remaining).map((item, index) => (
          <img
            key={index}
            src={
              item === 'full'
                ? multiplayerListStyles[lowercaseSubject].energyIcon.full
                : multiplayerListStyles[lowercaseSubject].energyIcon.empty
            }
            alt="energy"
          />
        ))}
      </div>
      <div className="remaining">
        <Trans i18nKey={keyName} remaining={remaining < 0 ? 0 : remaining}>
          <p>{{ remaining }}</p>
          <span>Challenges remaining for today</span>
        </Trans>
      </div>
    </div>
  );
};

const PeerChallengeCard = ({
  id,
  name,
  newChallenge,
  challengeRemaining,
  challengeStatus,
  subject = 'Math',
  totalEnergyPerDay,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['multiplayerChallengeList']);
  const lowercaseSubject = subject.toLowerCase();
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  // card left side contents
  const leftSide = () => {
    return (
      <LeftContent>
        <SubCardContainer subject={lowercaseSubject}>
          <img
            src={challengeStatus === 'close' ? Icon2 : Icon1}
            alt="peer-challenge"
          />
          <div className="text">
            <p>
              {`${t(
                'multiplayerChallengeList:card.peerChallenge',
                'Peer Challenge'
              )}`}
            </p>
            {challengeStatus === 'close' && (
              <p className="text-closed">
                {t('multiplayerChallengeList:card.closed', 'Closed')}
              </p>
            )}
          </div>
        </SubCardContainer>
        <div className="info-section">
          <div className="challenge-name">
            {`${t(
              `multiplayerChallengeList:card.peerChallenge`,
              'Peer Challenge'
            )} - ${t(`common:subjects.${lowercaseSubject}`)}`}
          </div>
          <div className="open-hour">
            <DetailsInfo
              direction="column"
              category="Opening Hours"
              info={t(
                'multiplayerChallengeList:card.schedule',
                '6am to 10pm Daily'
              )}
            />
          </div>
          <HideAtMobile>
            <ChallengeEnergy
              remaining={challengeRemaining}
              totalEnergy={totalEnergyPerDay}
              lowercaseSubject={lowercaseSubject}
            />
          </HideAtMobile>
        </div>
      </LeftContent>
    );
  };

  const getButtonFontSize = () => {
    // there are mediaqueries at Button component with calc font-size function. Button Component is a shared component so it is not changed. '19.6px' will yield 18px;
    let size = '22px';
    if (isTablet) {
      size = '19px';
    }
    if (isMobile) {
      size = '15.5px';
    }
    return size;
  };

  // card right side contents
  const rightSide = (challengeStatus, newChallenge) => {
    const contentRight = (challengeStatus) => {
      if (challengeStatus === 'open') {
        return {
          button: (
            <Button
              dataCy={challengeStatus}
              fontSize={getButtonFontSize()}
              variant="primary"
              onClick={() =>
                history.push(
                  `/multiplayer/peer-challenge/new-challenge?subject=${subject}`
                )
              }
            >
              {t('multiplayerChallengeList:card.newChallenge', 'New Challenge')}
            </Button>
          ),
        };
      }
      if (challengeStatus === 'view') {
        return {
          text: (
            <div className="view-text">
              <Trans
                i18nKey="multiplayerChallengeList:card.newChallengeNotification"
                newChallenge={newChallenge}
              >
                You have <span>{{ newChallenge }}</span> new challenge(s)
              </Trans>
            </div>
          ),
          button: (
            <Button
              width={isTablet ? '130px' : '185px'}
              fontSize={getButtonFontSize()}
              variant="primary"
              onClick={() => {
                history.push(
                  `multiplayer/peer-challenge/incoming?subject=${subject}`
                );
              }}
              dataCy={challengeStatus}
            >
              {t('multiplayerChallengeList:card.view', 'View')}
            </Button>
          ),
        };
      }
      if (challengeStatus === 'close') {
        return {
          text: (
            <>
              <p className="closed">
                {t('multiplayerChallengeList:card.closed', 'Closed')}
              </p>
              <p className="open-hour">
                {t(
                  'multiplayerChallengeList:card.scheduleOpen',
                  'Open 6am to 10pm Daily'
                )}
              </p>
            </>
          ),
          button: (
            <Button
              fontSize={getButtonFontSize()}
              variant="disable"
              dataCy={challengeStatus}
              isDisabled={challengeStatus === 'close'}
            >
              {t('multiplayerChallengeList:card.newChallenge', 'New Challenge')}
            </Button>
          ),
        };
      }
      if (challengeStatus === 'history') {
        return {
          button: (
            <Button
              fontSize={getButtonFontSize()}
              variant="primary"
              onClick={() =>
                history.push(
                  `multiplayer/peer-challenge/history?subject=${subject}`
                )
              }
              dataCy={challengeStatus}
            >
              {t('multiplayerChallengeList:card.viewHistory', 'View History')}
            </Button>
          ),
        };
      }
    };
    return (
      <RightContainer language={i18n.language}>
        <ShowAtMobile>
          <ChallengeEnergy
            remaining={challengeRemaining}
            totalEnergy={totalEnergyPerDay}
            lowercaseSubject={lowercaseSubject}
            isMobile
          />
        </ShowAtMobile>
        <RightContent>
          <div className="text">{contentRight(challengeStatus).text}</div>
          {contentRight(challengeStatus).button}
          {challengeStatus === 'view' && (
            <Link
              to={`/multiplayer/peer-challenge/new-challenge?subject=${subject}`}
            >
              <p className="new-challenge">
                {t(
                  'multiplayerChallengeList:card.newChallenge',
                  'New Challenge'
                )}
              </p>
            </Link>
          )}
        </RightContent>
      </RightContainer>
    );
  };

  return (
    <MainContainer key={id}>
      <Card
        rightWidth={isMobile ? '100%' : '25%'}
        leftWidth={isMobile ? '100%' : '85%'}
        infoSection={leftSide()}
        buttonSection={rightSide(challengeStatus, newChallenge)}
      />
    </MainContainer>
  );
};

export default PeerChallengeCard;
