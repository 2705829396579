import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import { getParentInfo } from 'store/profileSettings/profileSettingsSlice';
import { getActiveSubscriptions, getProducts } from 'store/plan/planSlice';
import { PRODUCT_TYPE } from 'constants/products';

import Button from 'components/Shared/Button';
import MockTimeTool from 'components/Shared/MockTimeTool';
import ModalContentXButton from 'components/Shared/ModalContentXButton';
import LoginModal from 'components/ProfileSettings/LoginModal';
import ResetPasswordModal from 'components/ProfileSettings/ResetPasswordModal';
import Spinner from 'components/Shared/Spinner';
import ErrorModal from 'components/Shared/ErrorModal';
import useCheckCurrentChinese from 'helpers/useCheckCurrentChinese';
import { subscriptionPlan } from 'constants/profileSettings';
import { Container, SubsContainer } from './index.styles';

const MySubscription = () => {
  const { t } = useTranslation(['myProfile', 'common', 'mySubs']);
  const dispatch = useDispatch();
  const {
    products,
    userProducts,
    activeSubscriptions,
    isLoading: productsIsLoading,
    getProductsError,
  } = useSelector((state) => state.plan);
  const { isLoading: parentInfoLoading, parentInfoErr } = useSelector(
    (state) => state.profileSettings
  );
  const { userID } = useSelector((state) => state.login);
  const isChinese = useCheckCurrentChinese();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openResetPassModal, setOpenResetPassModal] = useState(false);
  const allLoading =
    productsIsLoading || activeSubscriptions.isLoading || parentInfoLoading;
  const allError =
    getProductsError || activeSubscriptions.error || parentInfoErr;
  const isPureB2B =
    userProducts?.findIndex(
      (item) => item.productType.toLowerCase() === PRODUCT_TYPE.PREMIUM
    ) < 0;

  const filteredSchool =
    products
      ?.find((item) => item.hasPremiumSchool || item.hasSchoolOnly)
      ?.nonExpiredSubscriptionProducts?.find(
        (plan) => plan.productType.toLowerCase() === 'school'
      ) ?? null;
  useEffect(() => {
    if (userID) {
      dispatch(getParentInfo(userID));
      dispatch(getActiveSubscriptions());
    }
  }, [userID, dispatch]);

  const handleReload = () => {
    if (getProductsError) {
      dispatch(getProducts());
    }
    if (activeSubscriptions.error) {
      dispatch(getActiveSubscriptions());
    }
    if (parentInfoErr) {
      dispatch(getParentInfo(userID));
    }
  };
  return (
    <>
      <ModalContentXButton
        showModal={openLoginModal}
        closeModal={() => setOpenLoginModal(false)}
        backdrop="static"
        borderRadius="12px"
      >
        <LoginModal
          forgotPassHandler={() => {
            setOpenLoginModal(false);
            setOpenResetPassModal(true);
          }}
        />
      </ModalContentXButton>
      <ModalContentXButton
        showModal={openResetPassModal}
        closeModal={() => setOpenResetPassModal(false)}
        backdrop="static"
        borderRadius="12px"
      >
        <ResetPasswordModal closeModal={() => setOpenResetPassModal(false)} />
      </ModalContentXButton>
      <Container>
        <MockTimeTool />
        {allLoading && <Spinner />}
        {!allLoading && !isNil(allError) && (
          <ErrorModal errorMessage={allError} reloadAction={handleReload} />
        )}
        {!allLoading && isNil(allError) && (
          <>
            {activeSubscriptions.data?.map((subs) => {
              return (
                <SubsContainer key={subs.bundleId}>
                  <p className="plan">
                    {t(`common:bundleType.${subs.bundleName}`, subs.bundleName)}
                    <span>
                      <img src={subscriptionPlan.premium.icon} alt="plan" />
                    </span>
                  </p>
                  <p className="period">
                    {t('myProfile:subscription.Valid', 'Valid period')}
                    {moment(subs.startDate).format(
                      isChinese ? 'YYYY/MM/DD' : 'DD/MM/YYYY'
                    )}{' '}
                    -
                    {moment(subs.endDate).format(
                      isChinese ? 'YYYY/MM/DD' : 'DD/MM/YYYY'
                    )}
                  </p>
                </SubsContainer>
              );
            })}
            {products && (
              <>
                {!isNil(filteredSchool) && (
                  <SubsContainer>
                    <p className="plan">
                      {`KooBits ${t(
                        `common:plansType.School Plan`,
                        `School Plan`
                      )}`}
                      <span>
                        <img
                          src={
                            subscriptionPlan[
                              filteredSchool.productType.toLowerCase()
                            ].icon
                          }
                          alt="plan"
                        />
                      </span>
                    </p>
                    <p className="period">
                      {t('myProfile:subscription.Valid', 'Valid period')}
                      {moment(filteredSchool.startDate).format(
                        isChinese ? 'YYYY/MM/DD' : 'DD/MM/YYYY'
                      )}{' '}
                      -
                      {filteredSchool.productType.toLowerCase() !== 'school'
                        ? moment(filteredSchool.endDate).format(
                            isChinese ? 'YYYY/MM/DD' : 'DD/MM/YYYY'
                          )
                        : moment(filteredSchool.endDate).format(
                            isChinese ? 'YYYY/MM/DD' : 'DD/MM/YYYY'
                          )}
                      <span className="desc">
                        {t(
                          'myProfile:subscription.SchoolRenew',
                          'Only your school can renew the school plan for you.'
                        )}
                      </span>
                    </p>
                  </SubsContainer>
                )}
                {!isPureB2B && (
                  <Button
                    fontSize="1.25rem"
                    variant="orange4"
                    onClick={() => setOpenLoginModal(true)}
                    margin="1rem 0 0"
                  >
                    {t('mySubs:button', 'Manage my subscription')}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default MySubscription;
