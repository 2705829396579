import styled from 'styled-components';

export const StyledButton = styled.button`
  border: solid 1px #cee9f0;
  font-size: 0.875rem !important;
  color: #73c2d1;
  background: white;
  border-radius: 22px;
  padding: 0.25rem 0.5rem;
  margin: ${(props) => props.margin};
  align-self: center;
  :hover {
    color: #56c2d6;
  }
`;

export const DropdownContainer = styled.div`
  width: 100%;
  padding-left: 2.5rem;
  padding-top: 1rem;
`;

export const StyledIframe = styled.iframe`
  width: 640px;
  max-width: 95%;
  height: 80vh;
  margin: 1rem;
`;
