import styled from 'styled-components';
import { LeftContent } from '../ChallengeCard/ChallengeCard.styles';

export const StyledLeftContent = styled(LeftContent)`
  .detail-one {
    display: flex;
    gap: 1rem;
    margin-bottom: 20px;
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      margin-bottom: 0;
    }
  }
`;
export const StyledModal = styled.div`
  position: relative;
  padding: 20px 24px;
  display: flex;
  gap: 30px;
  .new {
    position: absolute;
    right: 40px;
    top: -8px;
  }
  iframe {
    max-width: 450px;
    width: 100%;
    height: auto;
  }
  .info-section {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .name {
    font-weight: 700;
  }
  .app-info {
    margin: 50px 0;
    display: flex;
    align-items: flex-end;
    gap: 26px;
    img {
      width: 76px;
    }
    span {
      margin-bottom: 1rem;
      font-weight: 700;
    }
  }
  .qr-code {
    width: 90px;
  }
  .store {
    margin-top: 12px;
    img {
      margin-right: 1rem;
    }
  }
`;
