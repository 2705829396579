import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger } from 'react-bootstrap';
import { InfoTooltip } from './Curriculum.styles';
import { IMAGES } from '../../constants';

const Curriculum = ({ currDailyChallenge }) => {
  const { t } = useTranslation(['dailyChallengev2']);

  return (
    <div className="dc-curriculum">
      <span>{currDailyChallenge?.CurriculumName}</span>
      <OverlayTrigger
        transition={false}
        overlay={
          <InfoTooltip id="difficultyInfo">
            {t(
              `dailyChallengev2:toolkit.curriculumInfo`,
              'Go to setting page to change curriculum.'
            )}
          </InfoTooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <div {...triggerHandler}>
            <img
              ref={ref}
              width="18"
              height="18"
              src={IMAGES.WarningIcon}
              alt=""
            />
          </div>
        )}
      </OverlayTrigger>
    </div>
  );
};

export default Curriculum;
