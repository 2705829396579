import styled from 'styled-components';

export const StepOneModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .modal-title {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
  }
`;

export const StepTwoModal = styled.div`
  .back-button {
    margin-left: 1rem;
    margin-top: 1rem;
    position: absolute;
    cursor: pointer;
  }
`;

export const StepTwoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
  padding-top: 2rem;

  .otp-title {
  }

  .otp-desc {
    color: #909090;
  }
`;
export const FinishedModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .finished-title {
    margin-top: 1.5rem;
    color: #ff8001;
  }

  .finished-description {
    margin-top: 0.5rem;
    word-break: break-word;
    width: 350px;
    text-align: center;
  }

  .check-mark {
    margin-top: -2.5rem;
  }

  .activate-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
export const StyledErrorResultModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;
  .info-mark {
    margin-top: -1.5rem;
  }
  .error-title {
    margin-top: 1.5rem;
    margin-bottom: 30px;
    color: #ff8001;
    font-size: 20px;
    font-weight: 600;
  }
  .error-desc {
    margin-bottom: 0;
    a {
      text-decoration: underline;
      color: #ff5c02;
    }
  }
`;
