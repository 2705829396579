import styled from 'styled-components';
import BGMath from 'assets/img/dashboard/daily-challenge/background-math.svg';
import title from 'assets/img/dashboard/daily-challenge/title.svg';
export const Border = styled.div`
  border-radius: 20px;
  border: ${(props) => (props.isPremium ? 'none' : 'solid 5px white')};
  height: ${(props) => (props.isPremium ? '158px' : '164px')};
  width: 743px;
  box-shadow: 0px 7px 0px 0px rgba(82, 75, 97, 0.28);
  flex-shrink: 0;
  margin: 1.5rem 0;
  background-color: #ffce09;
  background-image: url(${BGMath}), url(${title});
  background-repeat: no-repeat;
  background-position: center bottom, 11% 14%;
  background-size: 93%, 52.5%;
  align-self: center;
  position: relative;
  @media (min-width: 1700px) {
    width: 980px;
    height: 190px;
  }
  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1rem;
    height: 145px;
    background-position: center bottom, 7% 14%;
    background-size: 93%, 53.5%;
  }
  @media (max-width: 768px) {
    height: 152px;
    background-position: left center, center 10%;
    background-size: 700px, 326px;
    margin-top: 0;
  }
  @media (max-width: 413px) {
    background-position: left center, center 1%;
    background-size: 700px, 250px;
    height: 110px;
  }
  &:hover {
    /* filter: blur(4px); */
    backdrop-filter: blur(10px);
    overflow: hidden;
  }
  .container {
    height: 100%;
    position: relative;

    @media (min-width: 1700px) {
      height: 180px;
    }
    @media (max-width: 990px) {
      max-width: none;
    }
    @media (max-width: 768px) {
      width: 326px;
    }
    @media (max-width: 413px) {
      width: 270px;
    }
  }
  .circle {
    position: absolute;
    height: 155px;
    top: -7px;
    right: 8%;
  }
  .trophy {
    position: absolute;
    bottom: 0;
    height: 188px;
    right: 10%;
    @media (min-width: 1700px) {
      height: 220px;
    }
    @media (max-width: 1023px) {
      height: 170px;
      right: 8%;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
  .sub-title {
    margin-bottom: 0;
    color: #9e6400;
    position: absolute;
    top: 61%;
    font-size: 1rem;
    left: 7%;
    @media (min-width: 1700px) {
      top: 7rem;
      font-size: 1.3rem;
      width: 400px;
    }
    @media (max-width: 1023px) {
      top: 67%;
      font-size: 1.1rem;
      left: 5%;
    }
    @media (max-width: 768px) {
      top: 62%;
      font-size: 13px;
      left: 10px;
      width: 100%;
    }
    @media (max-width: 413px) {
      top: 60%;
      font-size: 10px;
      left: 0px;
      transform: scale(0.9);
    }
  }
`;

export const StartButton = styled.button`
  display: ${(props) => props.hover && 'none'};
  width: 100px;
  height: 39px;
  background: #ff8001;
  border: none;
  border-radius: 50px;
  color: white;
  position: absolute;
  font-size: 1.3rem;
  top: 50%;
  transform: translateY(-50%);
  right: 6%;
  @media (min-width: 1700px) {
    width: 120px;
    height: 45px;
    font-size: 1.5rem;
  }
  @media (max-width: 1023px) {
    right: 3%;
  }
  @media (max-width: 768px) {
    top: 61%;
    width: 100px;
    height: 33px;
    right: 56.5%;
    transform: translateX(197%);
  }
  @media (max-width: 413px) {
    top: 64%;
    width: 82px;
    height: 28px;
    right: 62%;
    font-size: 15px;
  }
`;

export const OverlayContainer = styled.div`
  padding: 0 1rem;
  border-radius: 15px;
  backdrop-filter: blur(4px);
  background: ${(props) =>
    props.isFeatureReady ? 'rgba(93, 93, 93, 0.3)' : 'rgba(30, 30, 30, 0.67)'};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isFeatureReady ? 'pointer' : 'not-allowed')};
  text-align: center;
  p {
    font-size: 1.2rem;
    color: white;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    @media (min-width: 2560px) {
      font-size: 2.5rem;
    }
    @media (max-width: 414px) {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
  button {
    border: none;
    background: white;
    height: 40px;
    color: #c9a309;
    font-size: 1.5rem;
    border-radius: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 5px 25px;
    @media (min-width: 2560px) {
      font-size: 2.8rem;
      padding: 30px;
    }
    @media (max-width: 414px) {
      height: 32px;
      font-size: 1.3rem;
    }
  }
`;
