import styled from 'styled-components';
import { $colors } from 'constants/dashboardv2';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    cursor: pointer;
  }
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
    .sidebar & {
      display: flex;
    }
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  margin-bottom: 26px;
  width: 70px;
  height: 70px;
  box-shadow: 0px 0px 0px 6px #fff,
    0px 5px 0px 8px ${(p) => $colors.dashboard[p.subject]?.fourth};
`;

export const AvatarPhoto = styled.img`
  display: inline-block;
  max-width: 100%;
  border-radius: 50%;
`;

export const UserName = styled.h3`
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
  max-width: 140px;
  max-height: 60px;
  overflow: hidden;
  word-break: break-all;
`;

export const Level = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 122px;
  height: 31px;
  border-radius: 50px;
  background: ${(p) => $colors.dashboard[p.subject]?.level};
  color: ${(p) => {
    const numberOfLevel = parseInt(p.level, 10);
    if ((numberOfLevel > 40 && numberOfLevel <= 50) || numberOfLevel === 100)
      return '#000';
    return '#fff';
  }};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};

  & > img {
    width: 22px;
  }

  & .level-badge {
    font-size: 14px;
    display: inline-block;
    background: url(${(p) => p.bg});
    background-repeat: no-repeat;
    background-size: 100% 95%;
    background-position: 0px 1px;
    position: relative;
    width: 76px;
    text-align: center;
  }

  & .level-badge {
    font-weight: 800;
  }
`;
