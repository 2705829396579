import React, { useState } from 'react';
import { Spinner } from 'components/Shared';
import { Container } from './BrainGameEnglish.style';

const BrainGameEnglish = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Container>
      {isLoading && <Spinner />}
      <iframe
        src="https://koobits-edu.github.io/koobits-go/#koobits-go/game-selection/"
        title="Dart Application"
        style={{
          width: '100%',
          height: 'calc(100vh - 59px)',
          border: 'none',
        }}
        onLoad={() => setIsLoading(false)}
      />
    </Container>
  );
};

export default BrainGameEnglish;
