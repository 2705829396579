import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEventDetails } from 'store/event/eventSlice';

import { PictureType } from 'components/Assignment';
import { EventDetails } from './components';

import { Container, ErrorMsg } from './StartEvent.styles';

const StartEvent = ({ match }) => {
  const eventId = parseInt(match.params.id, 10);
  const dispatch = useDispatch();
  const { eventIsLoading, eventError } = useSelector((state) => state.event);

  useEffect(() => {
    dispatch(getEventDetails(eventId));
  }, [dispatch, eventId]);
  return (
    <Container>
      {!eventIsLoading && eventError !== null && (
        <ErrorMsg>
          <h4>Error</h4>
          <p>{eventError}</p>
        </ErrorMsg>
      )}
      <PictureType type="sundayMC" />
      <EventDetails />
    </Container>
  );
};

export default StartEvent;
