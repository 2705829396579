import React, { useState, useEffect } from 'react';
import { isNil } from 'ramda';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useSound from 'use-sound';
import {
  claimDailyChallengeKoko,
  setShowMysteryKokoModal,
} from 'store/dailyChallenge/dailyChallengeSlice';

import ClaimKokoSound from 'assets/audio/claim-koko.mp3';
import ModalBgImg from 'assets/img/level/levelup-modal-bg.png';
import HeaderImg from 'assets/img/dailychallenge/mystery-koko/not-claimed-header-bg.png';
import BrainGames from 'assets/img/sidepanel/braingames.svg';
import StoryMath from 'assets/img/sidepanel/storymath.svg';

import useDashboard from 'hooks/useDashboard';
import { BRAIN_GAME_ROUTES, STORY_ROUTES, GIFT_BOXES } from './constants';
import { subjectIDs, SUBJECTS } from 'constants/products';
import { Spinner, PopupModal } from 'components/Shared';

import { AwesomeButton } from '../LevelUpModal/LevelUpModal.styles';
import MysteryGiftBox from './MysteryGiftBox';
import {
  ModalContainer,
  MysteryContainer,
  HeaderSection,
  MainSection,
  Ribbon,
  ModalBg,
  ShortcutsContainer,
} from './MysteryKoKoModal.styles';

const MysteryKoKoModal = ({ questionCount = 0, boxImg, subjectID }) => {
  const dispatch = useDispatch();
  const [play, { stop }] = useSound(ClaimKokoSound, {
    volume: 0,
    playbackRate: 4,
    interrupt: true,
  });
  const history = useHistory();
  const { t } = useTranslation([
    'dailyChallengeQnView',
    'mysteryKoko',
    'common',
  ]);
  const {
    showMysteryKokoModal,
    isClaimingDCKoko,
    claimedMysteryKokoAmt,
    claimMysteryKokoError,
    currDailyChallenge,
  } = useSelector((state) => state.dailyChallenge);
  const canTakeMysterySubject =
    subjectIDs[subjectID] === SUBJECTS.SCIENCE ||
    subjectIDs[subjectID] === SUBJECTS.ENGLISH;
  const mysteryKokoAmt = canTakeMysterySubject
    ? claimedMysteryKokoAmt
    : currDailyChallenge?.TotalKokoClaimed;
  const { rewards } = useSelector((state) => state.rewards);
  const { isDashboardv2 } = useDashboard();
  const totalKoko = rewards?.TotalKoko ?? '';
  const [hasClaimed, setHasClaimed] = useState(false);
  // Event Handlers
  const continueHandler = () => {
    dispatch(setShowMysteryKokoModal(false));
    history.replace(
      isDashboardv2 ? '/dashboard?view=koochallenge' : '/challenges'
    );
  };
  const navigateToHandler = (destination) => {
    if (!destination) return;
    history.replace(destination);
  };
  const reloadHandler = () => {
    dispatch(claimDailyChallengeKoko());
  };
  // Preload sound
  useEffect(() => {
    play();
    return () => {
      stop();
    };
  }, [play, stop]);
  // Claim Koko
  useEffect(() => {
    if (canTakeMysterySubject && showMysteryKokoModal && !hasClaimed) {
      dispatch(claimDailyChallengeKoko());
    }
  }, [dispatch, hasClaimed, canTakeMysterySubject, showMysteryKokoModal]);
  return (
    <PopupModal show={showMysteryKokoModal}>
      <ModalContainer data-cy="claim-koko-modal">
        {!hasClaimed && <img src={HeaderImg} alt="" className="header-img" />}
        {hasClaimed && (
          <Ribbon>
            <h3>
              {t(
                'dailyChallengeQnView:allCorrectModal.congrats',
                'Congratulations!'
              )}
            </h3>
          </Ribbon>
        )}
        <MysteryContainer>
          <HeaderSection>
            {!hasClaimed && (
              <>
                <p className="main-text">
                  {t(
                    'dailyChallengeQnView:claimKokoModal.header1',
                    { qnCount: questionCount },
                    `You have completed ${questionCount} challenges today!`
                  )}
                </p>
                <p className="sub-text">
                  {t(
                    'mysteryKoko:tapToOpen',
                    'Tap to open and find out what treasure lies inside'
                  )}
                </p>
              </>
            )}
            {hasClaimed && (
              <>
                <p className="main-text">
                  You got <span>{`${mysteryKokoAmt ?? ''} KoKo`}</span> rewards
                </p>
                <p className="white-subtext">
                  <Trans i18nKey="mysteryKoko:youHave">
                    You have
                    <span>{{ number: totalKoko }}</span>
                    in total
                  </Trans>
                </p>
              </>
            )}
          </HeaderSection>
          <MainSection
            isLoading={isClaimingDCKoko || !isNil(claimMysteryKokoError)}
          >
            <div className="giftbox-container">
              {GIFT_BOXES.map((_, index) => (
                <MysteryGiftBox
                  key={index}
                  kokoAmt={mysteryKokoAmt}
                  hasClaimed={hasClaimed}
                  setHasClaimed={setHasClaimed}
                  isDisabled={isClaimingDCKoko}
                  boxImg={boxImg}
                />
              ))}
              {isClaimingDCKoko && (
                <Spinner position="absolute" left="inherit" color="white" />
              )}
            </div>
            {!isClaimingDCKoko && !isNil(claimMysteryKokoError) && (
              <>
                <p style={{ color: '#ff6701' }}>{claimMysteryKokoError}</p>
                <AwesomeButton onClick={reloadHandler}>
                  {t('common:reload', 'Reload')}
                </AwesomeButton>
              </>
            )}
            {hasClaimed && (
              <AwesomeButton onClick={continueHandler}>
                {t('common:continue', 'Continue')}
              </AwesomeButton>
            )}
          </MainSection>
        </MysteryContainer>
        {hasClaimed && <ModalBg src={ModalBgImg} alt="" />}
      </ModalContainer>
      <ShortcutsContainer show={hasClaimed}>
        <span>{t('mysteryKoko:jumpLink', 'Jump to Story or Brain Games')}</span>
        <img
          src={StoryMath}
          alt="Story Math"
          onClick={() =>
            navigateToHandler(
              isDashboardv2 ? STORY_ROUTES[subjectID] : '/story'
            )
          }
          width="43.86px"
          style={{ margin: '0 1.25rem' }}
        />
        <img
          src={BrainGames}
          alt="Brain Games"
          width="43.41px"
          onClick={() =>
            navigateToHandler(
              isDashboardv2 ? BRAIN_GAME_ROUTES[subjectID] : '/brain-game'
            )
          }
        />
      </ShortcutsContainer>
    </PopupModal>
  );
};

export default MysteryKoKoModal;
