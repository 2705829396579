import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import {
  getKooQuizSolutions,
  submitClaimKooQuiz,
} from 'store/kooQuiz/kooQuizSlice';
import useQuery from 'helpers/useQuery';

import DetailsInfo from 'components/Assignment/StartPage/DetailsInfo';
import { ErrorModal, Spinner } from 'components/Shared';
import { Header } from '../components';
import { SubmittedContent } from './components';
import { Container, Main, AssignerSection } from './KooQuizSubmitted.styles';

const KooQuizSubmitted = () => {
  const query = useQuery();
  const hasPrint = query.get('print')?.toLowerCase() !== 'false';
  const dispatch = useDispatch();
  const {
    paperById,
    kooQuizType,
    isKooQuizSubmitting,
    kooQuizSubmissionError,
    kooQuizSolutions,
    isSolutionsLoading,
    kooQuizSubmissionID,
    kooQuizDuration,
    error,
  } = useSelector((state) => state.kooQuiz);

  const assignmentType = kooQuizType;

  useEffect(() => {
    dispatch(getKooQuizSolutions(kooQuizSubmissionID));
  }, [dispatch, kooQuizSubmissionID]);

  const scoreCalculation = kooQuizSolutions
    ? Math.floor(
        (kooQuizSolutions.CorrectAnswers / kooQuizSolutions.TotalQuestions) *
          100
      )
    : 0;

  const reloadHandler = () => {
    dispatch(submitClaimKooQuiz(kooQuizSubmissionID));
    dispatch(getKooQuizSolutions(kooQuizSubmissionID));
  };

  return (
    <Container>
      <Header
        page="submitted"
        assignmentTitle={paperById && paperById.Name}
        isLoading={isKooQuizSubmitting}
        duration={kooQuizDuration}
        showDuration
        showPrint={hasPrint}
      />
      <Main assignmentType={assignmentType}>
        {!isKooQuizSubmitting &&
          (!isNil(kooQuizSubmissionError) || !isNil(error)) && (
            <ErrorModal
              reloadAction={reloadHandler}
              errorMessage={error || kooQuizSubmissionError}
            />
          )}
        {isKooQuizSubmitting && isSolutionsLoading && (
          <Spinner color="#ffffff" />
        )}
        {!isKooQuizSubmitting &&
          !isSolutionsLoading &&
          kooQuizSubmissionError === null &&
          kooQuizSolutions && (
            <>
              <div className="wrapper">
                <div className="summary-box">
                  <SubmittedContent
                    score={scoreCalculation}
                    correct={kooQuizSolutions.CorrectAnswers}
                    totalQn={kooQuizSolutions.TotalQuestions}
                    award={kooQuizSolutions.HWAllocatedKokoCredits}
                    subject={kooQuizType}
                    subId={kooQuizSubmissionID}
                    kooQuizId={paperById && paperById.Id}
                    hasPrint={hasPrint}
                  />
                </div>
              </div>
              <AssignerSection type={assignmentType}>
                <DetailsInfo
                  page="finish-hw"
                  category="Assigned by"
                  info="KooBits"
                />
              </AssignerSection>
            </>
          )}
      </Main>
    </Container>
  );
};

export default KooQuizSubmitted;
