// English img
import { subjectNames, SUBJECTS } from 'constants/products';
import EnglishMainSectionLeft from './imgs/english/bg-left.svg';
import EnglishMainSectionRight from './imgs/english/bg-right.svg';
import EnglishMainSectionCenterBg from './imgs/english/bg-center.svg';
import EnglishMainSectionCenterResponsiveBg from './imgs/english/bg-center-responsive.svg';

// Math img
import MathMainSectionBg1 from './imgs/math/bg1.svg';
import MathMainSectionBg2 from './imgs/math/bg2.svg';
import MathMainSectionBg3 from './imgs/math/bg3.svg';
import MathMainSectionBg4 from './imgs/math/bg4.svg';

// XL Background assets
import MathMainSectionXLBg1 from './imgs/math/bg1-xl.svg';
import MathMainSectionXLBg2 from './imgs/math/bg2-xl.svg';
import MathMainSectionXLBg3 from './imgs/math/bg3-xl.svg';
import MathMainSectionXLBg4 from './imgs/math/bg4-xl.svg';

// Large Background assets
import MathMainSectionLargeBg1 from './imgs/math/bg1-lg.svg';
import MathMainSectionLargeBg2 from './imgs/math/bg2-lg.svg';
import MathMainSectionLargeBg3 from './imgs/math/bg3-lg.svg';
import MathMainSectionLargeBg4 from './imgs/math/bg4-lg.svg';

// Mobile/Tablet Background assets
import MathMainSectionResponsiveBg1 from './imgs/math/bg1-responsive.png';
import MathMainSectionResponsiveBg2 from './imgs/math/bg2-responsive.png';
import MathMainSectionResponsiveBg3 from './imgs/math/bg3-responsive.png';
import MathMainSectionResponsiveBg4 from './imgs/math/bg4-responsive.png';

// Center Background
import MathMainSectionCenterBg1 from './imgs/math/bg1-center.svg';
import MathMainSectionCenterBg2 from './imgs/math/bg2-center.svg';
import MathMainSectionCenterBg3 from './imgs/math/bg3-center.svg';
import MathMainSectionCenterBg4 from './imgs/math/bg4-center.svg';

// Mobile/Tablet Center Background
import MathMainSectionCenterResponsiveBg1 from './imgs/math/bg1-center-responsive.svg';
import MathMainSectionCenterResponsiveBg2 from './imgs/math/bg2-center-responsive.svg';
import MathMainSectionCenterResponsiveBg3 from './imgs/math/bg3-center-responsive.svg';
import MathMainSectionCenterResponsiveBg4 from './imgs/math/bg4-center-responsive.svg';

import Reward1 from './imgs/reward1.svg';
import Reward2 from './imgs/reward2.svg';
import Reward3 from './imgs/reward3.svg';
import Reward4 from './imgs/reward4.svg';
import RewardClaimed1 from './imgs/rewardClaimed1.svg';
import RewardClaimed2 from './imgs/rewardClaimed2.svg';
import RewardClaimed3 from './imgs/rewardClaimed3.svg';
import RewardClaimed4 from './imgs/rewardClaimed4.svg';

export const mappingRewardStyleResponsive = {
  Elementary: {
    top: '85',
    right: '9',
    width: '12',
  },
  Intermediate: {
    top: '85',
    right: '9',
    width: '12',
  },
  Advanced: {
    top: '89',
    right: '9',
    width: '16',
  },
  Master: {
    top: '89',
    right: '9',
    width: '16',
  },
};
export const mappingCPIconActiveStyle = {
  [subjectNames[SUBJECTS.ENGLISH]]: '#FEB002',
  [subjectNames[SUBJECTS.MATH]]: '#00b9ff',
};
export const mappingRewardStyle = {
  Elementary: {
    top: '81',
    right: '-0.5',
    width: '5',
  },
  Intermediate: {
    top: '81',
    right: '-1',
    width: '6',
  },
  Advanced: {
    top: '82',
    right: '-2',
    width: '8',
  },
  Master: {
    top: '82',
    right: '-2',
    width: '8',
  },
};
export const mappingEnglishMissionStyle = {
  completed: {
    number: '#ffffff',
    inline: '#4BCE00',
    outline: '#ffffff',
    shadow: '#4E8760',
  },
  incompleted: {
    number: '#FEAA02',
    inline: '#ffffff',
    outline: '#CEDDDD',
    shadow: '#707070',
  },
  skipped: {
    number: '#999999',
    inline: '#CECECE',
    outline: '#ffffff',
    shadow: '#707070',
  },
};
export const mappingMathMissionStyle = {
  completed: {
    number: '#ffffff',
    inline: '#4bce2a',
    outline: '#ffffff',
    shadow: '#A8A8A8',
  },
  incompleted: {
    number: '#00a4ff',
    inline: '#ffffff',
    outline: '#CEDDDD',
    shadow: '#A8A8A8',
  },
  skipped: {
    number: '#999999',
    inline: '#CEDDDD',
    outline: '#ffffff',
    shadow: '#6E987C',
  },
};

export const mappingEnglishReward = {
  rewards: '5-10',
  icon: Reward3,
  iconClaimed: RewardClaimed3,
};
export const mappingMathReward = {
  Elementary: {
    rewards: '5',
    icon: Reward1,
    iconClaimed: RewardClaimed1,
  },
  Intermediate: {
    rewards: '10',
    icon: Reward2,
    iconClaimed: RewardClaimed2,
  },
  Advanced: {
    rewards: '15-20',
    icon: Reward3,
    iconClaimed: RewardClaimed3,
  },
  Master: {
    rewards: '20-50',
    icon: Reward4,
    iconClaimed: RewardClaimed4,
  },
};

export const mappingEnglishMainSectionStyle = {
  bgColor: '#F7F1D1',
  bgLeft: EnglishMainSectionLeft,
  bgLeftWidth: {
    default: '26vw',
    xl: '28vw',
    tablet: '28vw',
  },
  bgRight: EnglishMainSectionRight,
  bgRightWidth: {
    default: '26vw',
    xl: '26vw',
    tablet: '28vw',
  },
  centerBg: EnglishMainSectionCenterBg,
  centerResponsiveBg: EnglishMainSectionCenterResponsiveBg,
  bgPosition: 'left',
  center: {
    size: 63,
    position: {
      left: 66,
      bottom: 49,
    },
  },
  centerResponsive: {
    size: 71,
    position: {
      left: 48,
      bottom: 48,
    },
  },
  runway: {
    shadow: '#584F4D',
    bg: '#655644',
    line: '#E2E1C2 ',
  },
};
export const mappingMathMainSectionStyle = {
  Elementary: {
    bgColor: '#C8F774',
    bg: MathMainSectionBg1,
    XLBg: MathMainSectionXLBg1,
    largeBg: MathMainSectionLargeBg1,
    centerBg: MathMainSectionCenterBg1,
    responsiveBg: MathMainSectionResponsiveBg1,
    centerResponsiveBg: MathMainSectionCenterResponsiveBg1,
    bgPosition: 'center',
    center: {
      size: 70,
      position: {
        left: 71,
        bottom: 47,
      },
    },
    centerResponsive: {
      size: 70,
      position: {
        left: 71,
        bottom: 47,
      },
    },
    runway: {
      shadow: '#05432F',
      bg: '#005A3D',
      line: 'rgba(248, 255, 235, 0.81)',
    },
  },
  Intermediate: {
    bgColor: '#FFF064',
    bg: MathMainSectionBg2,
    XLBg: MathMainSectionXLBg2,
    largeBg: MathMainSectionLargeBg2,
    centerBg: MathMainSectionCenterBg2,
    responsiveBg: MathMainSectionResponsiveBg2,
    centerResponsiveBg: MathMainSectionCenterResponsiveBg2,
    bgPosition: 'center',
    center: {
      size: 71,
      position: {
        left: 73,
        bottom: 42,
      },
    },
    centerResponsive: {
      size: 71,
      position: {
        left: 73,
        bottom: 42,
      },
    },
    runway: {
      shadow: '#3E2F0A',
      bg: '#836315',
      line: '#F8FFEB ',
    },
  },
  Advanced: {
    bgColor: '#00BDFF',
    bg: MathMainSectionBg3,
    XLBg: MathMainSectionXLBg3,
    largeBg: MathMainSectionLargeBg3,
    centerBg: MathMainSectionCenterBg3,
    responsiveBg: MathMainSectionResponsiveBg3,
    centerResponsiveBg: MathMainSectionCenterResponsiveBg3,
    bgPosition: 'center',
    center: {
      size: 69,
      position: {
        left: 63,
        bottom: 41,
      },
    },
    centerResponsive: {
      size: 69,
      position: {
        left: 63,
        bottom: 41,
      },
    },
    runway: {
      shadow: '#164164',
      bg: '#1F5D90',
      line: '#F8FFEB ',
    },
  },
  Master: {
    bgColor: '#9280FF',
    bg: MathMainSectionBg4,
    XLBg: MathMainSectionXLBg4,
    largeBg: MathMainSectionLargeBg4,
    centerBg: MathMainSectionCenterBg4,
    responsiveBg: MathMainSectionResponsiveBg4,
    centerResponsiveBg: MathMainSectionCenterResponsiveBg4,
    bgPosition: 'center',
    center: {
      size: 50,
      position: {
        left: 75,
        bottom: 50,
      },
    },
    centerResponsive: {
      size: 50,
      position: {
        left: 75,
        bottom: 50,
      },
    },
    runway: {
      shadow: '#32298D',
      bg: '#4D3FA3',
      line: '#F8FFEB',
    },
  },
};
