import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '0.5rem')};
  flex-direction: ${(props) => props.direction === 'column' && 'column'};
`;

export const Category = styled.div`
  margin-right: 1rem;
  margin-bottom: 0rem;
  color: ${(props) => (props.color ? props.color : '#707070')};
  text-align: left;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '7rem')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: 300;

  @media (max-width: 1024px) {
    font-size: 12px;
    min-width: 4rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-right: 0.25rem;
  }
`;

export const Info = styled.div`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  text-align: left;
  margin-bottom: 0rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    font-size: 14px;
  }
`;
