import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import {
  getStaticUserNotification,
  getUserNotification,
} from 'store/notification/notificationSlice';
import { featureToggle } from 'constants/index';

const useGetUserNotification = () => {
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { userProducts } = useSelector((state) => state.plan);
  const dispatch = useDispatch();
  const { userNotification } = useSelector((state) => state.notification);

  useEffect(() => {
    if (
      !isNil(studentDetails) &&
      !isNil(userProducts) &&
      isNil(userNotification) &&
      !featureToggle.staticNotification
    ) {
      dispatch(getUserNotification());
    }
    if (featureToggle.staticNotification && isNil(userNotification)) {
      dispatch(getStaticUserNotification());
    }
  }, [dispatch, studentDetails, userNotification, userProducts]);
};
export default useGetUserNotification;
