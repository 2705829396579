import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startGame, dispose } from 'store/brainGame/brainGameSlice';
import { secondsToMinute } from '../../helpers';
import BackImg from './back-to-games.png';
import { PopupModal } from 'components/Shared';
import { PopupTimeout } from '..';
import {
  ItemHeader,
  TopHeader,
  BackButton,
  TimerInfo,
  TimerWrap,
  Time,
  TimerInfoSecondary,
  TimerLeft,
  TimerRow,
} from './TimerHeader.style';

const TimerHeader = () => {
  const dispatch = useDispatch();
  const [showTimeOut, setShowTimeOut] = useState(false);
  const { startTime, timeRemaining, isStarted, isEnded } = useSelector(
    (state) => state.brainGame
  );

  useEffect(() => {
    if (!isStarted && isEnded === false) {
      dispatch(startGame());
    }
  }, [dispatch, isEnded, isStarted]);

  useEffect(() => {
    if (isEnded) {
      setShowTimeOut(true);
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .then(() => console.log('Document Exited from Full screen mode'))
          .catch((err) => console.error(err));
      }
    }
  }, [isEnded]);

  // Clean up hook to clear running timer interval
  useEffect(() => {
    return () => {
      dispatch(dispose());
    };
  }, [dispatch]);

  const { t } = useTranslation(['brainGames']);
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const closeTimeOutModal = useCallback(() => {
    setShowTimeOut(false);
    history.goBack();
  }, [history]);

  return (
    <>
      <PopupModal
        show={showTimeOut}
        onHide={closeTimeOutModal}
        centered
        backdrop="static"
      >
        <PopupTimeout onClose={closeTimeOutModal} onGame />
      </PopupModal>
      <TopHeader className="container-fluid">
        <ItemHeader>
          <BackButton onClick={goBack}>
            <img src={BackImg} alt="back to game" />
          </BackButton>
        </ItemHeader>
        <ItemHeader>
          <TimerWrap>
            <TimerRow>
              <TimerInfo>
                {t(
                  'brainGames:timeHeader.startedTime',
                  'Time You Started Today'
                )}
              </TimerInfo>
              <Time>
                {startTime ? moment(startTime).format('HH:mm:ss') : '-'}
              </Time>
            </TimerRow>
            <TimerRow>
              <TimerInfo>
                {t('brainGames:timeHeader.timeLeft', 'Time Left')}
              </TimerInfo>
              <TimerLeft>
                {timeRemaining && timeRemaining > 0
                  ? secondsToMinute(timeRemaining)
                  : '-'}
                <TimerInfoSecondary>min</TimerInfoSecondary>
                <TimerInfoSecondary desc>
                  {t('brainGames:timeHeader.maxMin', '(max 25 min/day)')}
                </TimerInfoSecondary>
              </TimerLeft>
            </TimerRow>
          </TimerWrap>
        </ItemHeader>
        <ItemHeader />
      </TopHeader>
    </>
  );
};

export default React.memo(TimerHeader);
