import styled from 'styled-components';
import { PopupModal } from 'components/Shared';

export const StyledPopupModal = styled(PopupModal)`
  .modal-body {
    width: 100%;
  }
`;
export const StyledPage = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: #fefdf0;
  padding: 0 1rem;
`;
export const StyledTitle = styled.div`
  .title {
    font-size: 26px;
    font-weight: 900;
  }
  .desc {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  }
`;
export const StyledDashboard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
StyledDashboard.Item = styled.div`
  position: relative;
  .lock {
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    width: 100%;
    line-height: 1;
    font-weight: 600;
    svg {
      margin-bottom: 18px;
      width: 34px;
    }
  }
  img {
    max-width: 382px;
    width: 100%;
    box-shadow: ${({ subject }) =>
      subject === 'English' ? '0 3px 6px rgba(0, 0, 0, 0.16)' : 'none'};
    filter: ${({ isExpired }) => isExpired && 'brightness(0.5)'};
  }
  p {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 12px;
    background: rgb(254, 252, 230);
    background: linear-gradient(
      0deg,
      rgba(254, 252, 230, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;
