import React from 'react';
import Header from 'components/Header';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Play } from 'pages/BrainGame';
import { featureToggle } from 'constants/index';
import useGlobalFeature from 'hooks/useGlobalFeature';
import { withPlanCheck } from 'helpers/hoc';
import { Content } from './KooClass.styles';
import { Page } from 'pages/Dashboard/Dashboard.styles';
import { Lessons, Activities, IntermediatePage } from '..';

const KooClass = () => {
  const { isEnabled } = useGlobalFeature('kooclass');
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={['/kooclass', '/kooClass/lessons/:topicId']}
              to="/maintenance-redirect"
            />
          )}
          {featureToggle.science && (
            <Route exact path="/kooClass" component={IntermediatePage} />
          )}
          <Route exact path="/:feature/brain-game/:game" component={Play} />
          <Route exact path="/kooClass/lessons/:topicId" component={Lessons} />
          <Route
            exact
            path="/kooClass/:topicId/lesson/:lessonId/activity"
            component={Activities}
          />
          <Route
            exact
            path="/kooClass/:subject/:topicId/lesson/:lessonId/activity"
            render={({ match }) => {
              const ActivitiesWithSubjectCheck = withPlanCheck(
                Activities,
                match.params.subject,
                'B2C'
              );
              return <ActivitiesWithSubjectCheck />;
            }}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default KooClass;
