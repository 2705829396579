import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import EventIcon from 'assets/img/access-menu/events.svg';
import useDashboard from 'hooks/useDashboard';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import CongratsText from 'components/Assignment/AssignmentFinish/CongratsText';
import { Container, FindResult } from './ResultRelease.styles';

const ResultRelease = ({ resultDate, eventsQs, isMobile }) => {
  const { t, i18n } = useTranslation(['testSubmitted', 'common']);
  const history = useHistory();
  const { isDashboardv2 } = useDashboard();

  return (
    <Container>
      <div className="top-section">
        <CongratsText />
        <p>
          {t('testSubmitted:words.Submission Succes', 'Submission Successful!')}
        </p>
      </div>
      <div className="bottom-section">
        <p className="info-text">
          {t(
            'testSubmitted:words.ReleaseText',
            'Test results will be released on'
          )}
        </p>
        <p className="release-date">
          {moment(resultDate).format(
            formatI18nDateTime({
              format: 'DD MMM YYYY, LT',
              value: resultDate,
              time: true,
              language: i18n.language,
            })
          )}
        </p>
        {!eventsQs && (
          <p className="hint-text">
            {t(
              'testSubmitted:words.ViewText',
              '(Click on the Assignment icon to view the result.)'
            )}
          </p>
        )}
        {eventsQs && !isMobile && (
          <FindResult>
            <div className="text">
              {isDashboardv2 ? (
                <Trans i18nKey="testSubmitted:words.findResultv2">
                  <p className="small-text">
                    See the results in
                    <br />
                  </p>
                  Sunday Mini Challenge.
                </Trans>
              ) : (
                <Trans i18nKey="testSubmitted:words.findResult">
                  <p className="small-text">
                    See the results in
                    <br />
                  </p>
                  Sunday Mini Challenge,
                  <br />
                  <p> under “Events”.</p>
                </Trans>
              )}
            </div>
            <div className="event-icon">
              <img src={EventIcon} alt="Events" />
            </div>
          </FindResult>
        )}
        <div className="btn">
          <SubmitButton
            width={isMobile ? '100%' : '50%'}
            fontSize="26px"
            onClick={() =>
              eventsQs
                ? history.push(
                    isDashboardv2 ? '/dashboard?view=koochallenge' : '/events'
                  )
                : history.push('/assignment/assignment-list')
            }
          >
            {t('common:ok', 'OK')}
          </SubmitButton>
        </div>
      </div>
    </Container>
  );
};

export default ResultRelease;
