import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { cpIconsShadow } from 'constants/dashboardv2';
import { subjectIDs } from 'constants/products';

import {
  setActive,
  resetSubmitAns,
  setShowMysteryKokoModal,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';
import {
  pickWord,
  clearPickedWord,
} from 'store/dailyChallenge/randomWordsSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { clearAll } from 'store/assignment/barModelSlice';

import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import dailyChallengeSoundSprite from 'assets/audio/sprite.mp3';
import XPImg from 'assets/img/level/xp.png';
import KokoIcon from 'assets/img/icon-koko-credits.svg';

import { Button as SharedButton, ThatsRight } from 'components/Shared';
import { Container, AddCP, AddExp } from './CorrectAnswerSubmitted.styles';

const CorrectAnswerSubmitted = ({ expandQn, setExpandQn }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallengeQnView', 'selfPracticeQnView']);
  const {
    currDailyChallenge,
    submitAnsDailyChallenge,
    activeQsDailyChallenge,
    dailyChallengeQuestion,
  } = useSelector((state) => state.dailyChallenge);
  const { pickedWord } = useSelector((state) => state.randomWords);
  const addCP =
    submitAnsDailyChallenge.UserQuestionSubmissions[activeQsDailyChallenge - 1]
      .ChallengePoints;
  const addExp =
    submitAnsDailyChallenge.UserQuestionSubmissions[activeQsDailyChallenge - 1]
      .ExpClaimed;
  const isKokoClaimable = submitAnsDailyChallenge?.IsKokoClaimable === true;

  useEffect(() => {
    dispatch(pickWord());
    return () => {
      dispatch(clearPickedWord());
    };
  }, [dispatch]);
  return (
    <Container>
      <div className="top-section">
        <div>
          {pickedWord !== null && (
            <ThatsRight
              loop={false}
              text={pickedWord}
              hasSound
              soundSprite={dailyChallengeSoundSprite}
            />
          )}
        </div>
        <div className="info-section">
          <div className="cp-section">
            <AddCP>
              <span>
                <img
                  src={
                    cpIconsShadow[
                      subjectIDs[currDailyChallenge.SubjectID].toLowerCase()
                    ].icon
                  }
                  alt="CP"
                  width={
                    cpIconsShadow[
                      subjectIDs[currDailyChallenge.SubjectID].toLowerCase()
                    ].dcCorrect.width
                  }
                  height={
                    cpIconsShadow[
                      subjectIDs[currDailyChallenge.SubjectID].toLowerCase()
                    ].dcCorrect.height
                  }
                />
                +{addCP}
              </span>
            </AddCP>
          </div>
          <div className="exp-section">
            <AddExp>
              <span data-cy="exp-earned">
                <img src={XPImg} alt="XP" width="38px" height="37px" />+{addExp}
              </span>
            </AddExp>
          </div>
        </div>
        <div className="next-qn">
          {activeQsDailyChallenge < dailyChallengeQuestion.length &&
            !isKokoClaimable && (
              <Button
                variant="orange"
                onClick={() => {
                  dispatch(resetSubmitAns());
                  dispatch(setActive(activeQsDailyChallenge + 1));
                  setExpandQn(true);
                  dispatch(hideAllTools());
                  dispatch(clearAll());
                  clearValues();
                  dispatch(setClearDraw(true));
                }}
              >
                {t('dailyChallengeQnView:nextQn', 'Next Question')}
              </Button>
            )}
          {isKokoClaimable && (
            <SharedButton
              variant="primary"
              padding="0rem 1rem"
              width="250px"
              onClick={() => {
                dispatch(setShowMysteryKokoModal(true));
              }}
            >
              {t(
                'selfPracticeQnView:reward.claimMyRewards',
                'Claim My Rewards'
              )}
              <span>
                <img src={KokoIcon} alt="koko-icon" />
              </span>
            </SharedButton>
          )}
        </div>
      </div>
      <div className="bottom-section">
        <div className="qn-section">
          <p>{t('dailyChallengeQnView:qn', 'Question')}</p>
          <p className="expand-qn" onClick={() => setExpandQn(!expandQn)}>
            {t('dailyChallengeQnView:expand', 'Click to Expand')}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default CorrectAnswerSubmitted;
