import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_DOMAIN;
const EVENT_SERVICE = process.env.REACT_APP_EVENTS_SERVICE_URL;

export const fetchEventsList = () => {
  return fetch(`${baseUrl}Events/GetEvents`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get events list failed'); // eslint-disable-line
    });
};

export const fetchEvents = () => {
  return axios
    .get(`${EVENT_SERVICE}Events`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Get events failed');
    });
};
