import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStudentTopics,
  changeQuestion,
  resetChangeQnResult,
  clearSavedDailyChallengeWorkings,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { clearAll } from 'store/assignment/barModelSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import useIsMobile from 'hooks/responsive/useIsMobile';
import useIsTablet from 'hooks/responsive/useIsTablet';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { Spinner, ErrorModal, DropdownMenu } from 'components/Shared';

import { Container, TopicSelect, Button } from './ChangeQuestionModal.styles';

export const clearWorkingsValue = () => {
  // clearing uncontrolled component
  Array.from(document.querySelectorAll('#text-here')).forEach((input) => {
    input.value = '';
  });
};

const useDropdownResponsive = () => {
  // cannot use css for responsive because dropdown component is shared component
  let width = '250px';
  let containerWidth;
  let maxWidth;
  let menuWidth;

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  if (isTablet || isMobile) {
    width = '100%';
    containerWidth = '85%';
    maxWidth = 'none';
  }
  if (isMobile) {
    menuWidth = '100%';
  }
  return { width, containerWidth, maxWidth, menuWidth };
};

const ChangeQuestionModal = ({ onHide }) => {
  const dispatch = useDispatch();
  const {
    width,
    maxWidth,
    containerWidth,
    menuWidth,
  } = useDropdownResponsive();
  const { t } = useTranslation(['dailyChallengeQnView', 'common']);
  const {
    activeQsDailyChallenge,
    allTopics,
    allTopicsIsLoading,
    allTopicsIsErr,
    changeQnResult,
    isChangingQnErr,
    isChangingQn,
  } = useSelector((state) => state.dailyChallenge);
  const [topic, selectTopic] = useState('easy');
  const [newTopic, selectNewTopic] = useState(allTopics[0]);
  const searchStudentTopicsAction = () => {
    dispatch(getStudentTopics());
  };
  useEffect(() => {
    searchStudentTopicsAction();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (allTopics.length > 0) {
      selectNewTopic(allTopics[0]);
    }
  }, [allTopics]);

  useEffect(() => {
    if (changeQnResult) {
      onHide();
      dispatch(clearAll());
      clearValues();
      dispatch(clearSavedDailyChallengeWorkings(activeQsDailyChallenge - 1));
      clearWorkingsValue();
    }
  }, [activeQsDailyChallenge, changeQnResult, dispatch, onHide]);

  useEffect(() => {
    return () => {
      dispatch(resetChangeQnResult());
    };
  }, [dispatch]);
  const onClickHandler = () => {
    const params = {
      DisplayOrder: activeQsDailyChallenge,
    };
    if (topic === 'change') {
      params.NewTopicId = newTopic.ID;
    } else {
      params.Difficulity = topic === 'easy' ? 1 : 2;
    }
    dispatch(changeQuestion(params)).then((res) => {
      if (
        res.error &&
        res.error.message === 'Maximum 3 times changes have been reached'
      ) {
        onHide();
      }
    });
    dispatch(hideAllTools());
  };

  const onChangeTopic = (val) => {
    selectNewTopic(val);
  };
  const renderChangeTopicDropdown = () => {
    if (topic === 'change') {
      if (allTopicsIsLoading) {
        return <Spinner small top="-1.5rem" />;
      }
      if (allTopicsIsErr) {
        return (
          <ErrorModal
            reloadAction={searchStudentTopicsAction}
            errorMessage={allTopicsIsErr}
          />
        );
      }
      if (newTopic) {
        return (
          <DropdownMenu
            valueKey="Name"
            values={allTopics}
            selectedValue={newTopic.Name}
            setValue={onChangeTopic}
            // valueTemplate={showLevels}
            width={width}
            containerWidth={containerWidth}
            maxWidth={maxWidth}
            menuWidth={menuWidth}
            className="select-drop-down"
            // widthipad="200px"
            dataCy="topic-options"
          />
        );
      }
    }
  };

  return (
    <Container data-cy="change-question-modal">
      <div className="header">
        <span onClick={onHide}>x</span>
        <div className="header-title">
          {t(
            'dailyChallengeQnView:changeQnModal.tooDifficult',
            'Are the challenges too easy or difficult for you?'
          )}
        </div>
        <div className="header-desc">
          {t(
            'dailyChallengeQnView:changeQnModal.desc',
            'you can only edit the difficulty level up to 3 times a day'
          )}
        </div>
      </div>
      <div className="body">
        <TopicSelect
          active={topic === 'easy'}
          onClick={() => selectTopic('easy')}
          className="option-select"
        >
          {t('dailyChallengeQnView:changeQnModal.easier', 'Make it easier')}
        </TopicSelect>
        <TopicSelect
          active={topic === 'hard'}
          onClick={() => selectTopic('hard')}
          className="option-select"
        >
          {t('dailyChallengeQnView:changeQnModal.harder', 'Make it harder')}
        </TopicSelect>
        <TopicSelect
          active={topic === 'change'}
          onClick={() => selectTopic('change')}
          className="option-select"
          data-cy="change-topic-option"
        >
          {t('dailyChallengeQnView:changeQnModal.changeTopic', 'Change Topic')}
        </TopicSelect>

        {renderChangeTopicDropdown()}
        {!isChangingQn && isChangingQnErr !== null && (
          <ErrorModal errorMessage={isChangingQnErr} />
        )}
        <Button
          onClick={onClickHandler}
          disabled={
            isChangingQn ||
            (!!allTopicsIsErr && topic === 'change') ||
            (allTopicsIsLoading && topic === 'change')
          }
          data-cy="yes-btn"
        >
          {isChangingQn ? (
            <Spinner small color="white" top="-1.5rem" />
          ) : (
            `${t('dailyChallengeQnView:changeQnModal.ok', 'OK')}`
          )}
        </Button>
      </div>
    </Container>
  );
};

export default ChangeQuestionModal;
