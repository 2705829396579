import styled from 'styled-components';
import { $colors } from 'constants/dashboardv2';
import { Link } from 'react-router-dom';

export const NavList = styled.div`
  width: 100%;
  margin-top: 58px;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    margin: auto;
    height: 100%;
  }
`;

export const NavItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 50px 0px 0px 50px;
  height: 46px;
  font-size: 14px;
  background: ${(p) => p.active && $colors.dashboard[p.subject]?.bg};
  margin-left: 14px;
  cursor: pointer;

  .current-arrow {
    position: absolute;
    right: 20px;
    fill: currentColor;
    color: ${(p) => $colors.dashboard[p.subject]?.arrow};

    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    border-radius: 0;
    height: 100%;
    flex: 1;
    gap: 0px;
    margin-left: 0px;
    background: ${(p) =>
      p.active
        ? $colors.dashboard[p.subject]?.bg
        : $colors.dashboard[p.subject]?.secondary};
  }
`;

export const NavLinkItem = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 50px 0px 0px 50px;
  height: 46px;
  font-size: 14px;
  background: ${(p) => p.active && $colors.dashboard[p.subject]?.bg};
  margin-left: 14px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
`;

export const NavLinkContent = styled(Link)`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 85%;
  height: 25px;
  border-radius: 50px;
  padding-left: 10px;
  margin: auto;
  background: transparent;
  text-decoration: none;
  color: #fff;

  &:hover {
    z-index: 5;
    background: ${(p) => !p.hover && $colors.dashboard[p.subject]?.secondary};
    text-decoration: none;
    color: #fff;
  }
`;

export const NavContnet = styled.div`
  width: 85%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 14px;
  padding-left: 10px;
  margin: auto;
  background: transparent;
  height: 25px;
  color: ${(p) =>
    p.active
      ? $colors.dashboard[p.subject]?.textActive
      : $colors.dashboard[p.subject]?.text};

  &:hover {
    color: ${(p) => !p.active && $colors.dashboard[p.subject]?.textActive};
    z-index: 5;
    background: ${(p) => !p.active && $colors.dashboard[p.subject]?.hover};
  }

  svg {
    fill: currentColor;
    color: ${(p) =>
      p.active
        ? $colors.dashboard[p.subject]?.iconActive
        : $colors.dashboard[p.subject]?.icon};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: auto;
    height: 100%;
    justify-content: center;
  }
`;

export const NavItemTop = styled.div`
  position: absolute;
  width: 21px;
  height: 21px;
  background: ${(p) => $colors.dashboard[p.subject]?.bg};
  right: 0px;
  top: -19px;

  &::before {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${(p) => $colors.dashboard[p.subject]?.primary};
    right: 0px;
    top: -17px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const NavItemBottom = styled.div`
  position: absolute;
  width: 21px;
  height: 21px;
  background: ${(p) => $colors.dashboard[p.subject]?.bg};
  right: 0px;
  bottom: -14px;

  &::before {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${(p) => $colors.dashboard[p.subject]?.primary};
    right: 0px;
    bottom: -22px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const SubNavList = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  padding-top: 20px;
  background: ${(p) => $colors.dashboard[p.subject]?.sidePanelBottom};
  display: flex;
  flex-direction: column;
  gap: 14px;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const SubNavItem = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  color: ${(p) => $colors.dashboard[p.subject]?.secondary};

  .circle {
    width: 48.4px;
    height: 48.4px;
    border-radius: 100%;
    background: ${(p) => $colors.dashboard[p.subject]?.friendsIcon};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  > svg {
    z-index: 1;
  }

  .label {
    width: 125px;
    background: ${(p) => $colors.dashboard[p.subject]?.sidePanelBottom};
    border-radius: 40px;
    margin-left: -15px;
    padding-left: 1.7rem;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 14px;
    color: ${(p) => $colors.dashboard[p.subject]?.textActive};
  }

  &:hover {
    color: ${(p) => $colors.dashboard[p.subject]?.secondary};
    text-decoration: none;
    .circle {
      background: ${(p) => $colors.dashboard[p.subject]?.friendsIconHover};
    }
    .label {
      color: ${(p) => $colors.dashboard[p.subject]?.textActive};
      background: ${(p) => $colors.dashboard[p.subject]?.friendsLabelHover};
    }
  }
`;

export const PremiumSmallIcon = styled.img`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const EventItem = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  color: ${(p) => $colors.dashboard[p.subject]?.secondary};

  .circle {
    width: 48.4px;
    height: 48.4px;
    border-radius: 100%;
    background: ${(p) => $colors.dashboard[p.subject]?.friendsIcon};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .icon {
      width: 28.5px;
      margin-left: 3px;
    }
  }

  > svg {
    z-index: 1;
    margin-left: 2px;
  }

  .label {
    width: 125px;
    background: ${(p) => $colors.dashboard[p.subject]?.sidePanelBottom};
    border-radius: 40px;
    margin-left: -15px;
    padding-left: 1.7rem;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: ${(p) => $colors.dashboard[p.subject]?.textActive};
    .beta {
      background: #ff3b00;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 700;
      padding: 2px 8px;
      line-height: normal;
      margin-top: 1px;
      color: #ffffff;
      transition: transform 0.2s ease-in-out;
    }
    .beta.wobble {
      animation: wobble 1s ease-in-out;
    }

    @keyframes wobble {
      from {
        transform: translate3d(0, 0, 0);
      }
      15% {
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
      }
      30% {
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
      }
      45% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
      }
      60% {
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
      }
      75% {
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
      }
      to {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &:hover {
    color: ${(p) => $colors.dashboard[p.subject]?.secondary};
    text-decoration: none;
    .circle {
      background: ${(p) => $colors.dashboard[p.subject]?.friendsIconHover};
    }
    .label {
      color: ${(p) => $colors.dashboard[p.subject]?.textActive};
      background: ${(p) => $colors.dashboard[p.subject]?.friendsLabelHover};
    }
  }
`;
