import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import { AWARD } from '../../constants';

import {
  MainBar,
  StartPoint,
  MilestoneIcon,
  AwardIcon,
  RibbonIcon,
  MilestoneProgress,
  Arrow,
} from './BrainMasterProgressBar.styles';

const BrainMasterProgressBar = ({ milestones, now, maxMilestone }) => {
  const hideElements = useMediaQuery({ maxWidth: '996px' });

  return (
    <MainBar className="main-bar">
      <StartPoint isActive={now > 0} />
      {milestones?.map((item, i) => (
        <MilestoneProgress key={item.milestoneId}>
          <ProgressBar
            now={now}
            className="brain-master-progress"
            key={item.index + 1}
            max={item.achievedAt}
            min={i === 0 ? 0 : milestones[i - 1].achievedAt}
          />
          {item.achievedAt === maxMilestone && !hideElements && (
            <Arrow active={now >= maxMilestone} />
          )}
          <div key={item.milestoneId} className="mile-icon">
            <MilestoneIcon
              hasAward={item.hasAward}
              isAchieved={now >= item.achievedAt}
              awardName={item.name}
            >
              <div className="number-detail">
                <p>
                  <span>{item.achievedAt}</span> Qn
                </p>
                {item.hasAward && (
                  <AwardIcon
                    src={
                      AWARD[item.name][
                        now >= item.achievedAt ? 'active' : 'default'
                      ]
                    }
                    alt=""
                    className="award-icon"
                    top={AWARD[item.name].top}
                  />
                )}
                {item.hasAward && (
                  <RibbonIcon
                    type={item.name}
                    isAchieved={now >= item.achievedAt}
                  >
                    {item.name}
                  </RibbonIcon>
                )}
              </div>
            </MilestoneIcon>
          </div>
        </MilestoneProgress>
      ))}
    </MainBar>
  );
};

export default BrainMasterProgressBar;
