import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import BackButton from 'components/Shared/BackButton';
import KooBitsLogo from 'assets/img/koobits-logo.svg';
import { featureToggle } from 'constants/index';

import TranslationDropdown from 'components/Header/Navigations/TranslationDropdown';
import { isDemoAccount } from 'components/Header/Navigations/NavProfile';
import { resetRetry } from 'store/kooClass/kooClassSlice';
import { setLastSavedTime } from 'store/timer/timerSlice';
import {
  LogoContainer,
  Container,
  Section,
  ActionButton,
} from './Header.styles';

const Header = ({
  type,
  assignmentTitle,
  isLoading,
  page,
  currentSavedAnswer,
  openModal,
  noNavBar,
  saveHandler,
  showLogo,
  isErr,
  showPrint = true,
  isSaving,
  saveErr,
  isChecking,
  activeQuestion,
}) => {
  const { t } = useTranslation(['assignmentQnView', 'common']);
  const dispatch = useDispatch();

  const { retryActivity } = useSelector((state) => state.kooClass);

  const { user } = useSelector((state) => state.login);

  const history = useHistory();

  return (
    <Container page={page} assignmentType={type} noNavBar={noNavBar}>
      <Section>
        {page === 'solution' && showLogo && (
          <LogoContainer
            assignmentType={type}
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            <img src={KooBitsLogo} alt="KooBits" width="40px" />
          </LogoContainer>
        )}
        {page !== 'solution' && (
          <LogoContainer
            assignmentType={type}
            onClick={() => {
              history.replace('/dashboard');
            }}
          >
            <img src={KooBitsLogo} alt="KooBits" width="40px" />
          </LogoContainer>
        )}
        {type === 'KooClass' && (
          <BackButton
            styles={{
              marginLeft: '15px',
              padding: '5px 10px',
              width: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
            btnBgColor="rgba(1, 135, 152, 0.5)"
            backText={t('assignmentQnView:header.backKooQuiz', 'Back')}
            onClick={() => {
              history.goBack();
              if (!isNil(saveHandler)) {
                saveHandler(false, true);
              }
              if (!isNil(retryActivity)) {
                dispatch(resetRetry());
              }
            }}
          />
        )}
      </Section>
      {!isLoading && <span className="hw-title">{assignmentTitle}</span>}
      <Section page={page} type="actions" showPrint={showPrint}>
        {featureToggle.languageDropdown &&
          user !== null &&
          isDemoAccount(user.user_name) && <TranslationDropdown />}
        {!page && (
          <>
            <ActionButton
              data-cy="submit-btn"
              assignmentType={type}
              disabled={isLoading && isErr !== null}
              onClick={openModal}
            >
              {t('assignmentQnView:header.submit', 'Submit')}
            </ActionButton>

            <ActionButton
              data-cy="saving-btn"
              assignmentType={type}
              onClick={() => {
                const now = Date.now();
                saveHandler(true);
                dispatch(setLastSavedTime(now));
              }}
              isSaved={
                !isSaving && saveErr === null && currentSavedAnswer !== null
              }
              disabled={
                isSaving ||
                isChecking[activeQuestion - 1] ||
                isLoading ||
                isErr !== null ||
                currentSavedAnswer !== null
              }
            >
              {(isSaving || isChecking[activeQuestion - 1]) &&
                t('assignmentQnView:header.saving', 'Saving')}
              {!isSaving &&
                saveErr === null &&
                currentSavedAnswer !== null &&
                t('assignmentQnView:header.saved', 'Saved')}
              {!isSaving &&
                !isChecking[activeQuestion - 1] &&
                currentSavedAnswer === null &&
                t('assignmentQnView:header.save', 'Save')}
            </ActionButton>
          </>
        )}
      </Section>
    </Container>
  );
};

export default Header;
