import styled from 'styled-components';

export const OuterContainer = styled.div`
  width: ${(props) => props.width};
  .primary {
    background: #ff7121;
    color: #ffffff;
    border-color: #ff7121;
    &:hover {
      color: #ffffff;
      background: #ff5c00;
      border-color: #ff5c00;
    }
  }
  .orange2 {
    background: #ff8900;
    color: #ffffff;
    border-color: #ff8900;
    &:hover {
      color: #ffffff;
      background: #ff7600;
      border-color: #ff7600;
    }
  }
  .orange3 {
    background: #ff5b00;
    color: #ffffff;
    border-color: #eb5400;
    &:hover {
      color: #ffffff;
      background: #ed5500;
      border-color: #eb5400;
    }
  }
  .orange4 {
    background: #ff6701;
    color: #ffffff;
    border-color: #ff6701;
    &:hover {
      color: #ffffff;
      background: #ff5801;
      border-color: #ff5801;
    }
  }
  .linear-orange {
    background: linear-gradient(to right, #ff6c00, #ff4b00);
    color: #ffffff;
    border-color: linear-gradient(to right, #ff6c00, #ff4b00);
    &:hover {
      color: #ffffff;
      background: linear-gradient(to right, #ff6c00, #ff4b00);
      border-color: linear-gradient(to right, #ff6c00, #ff4b00);
    }
  }
  .secondary {
    background: #ffffff;
    color: #ff7121;
    border-color: #ff6701;
    &:hover {
      color: #ff5d01;
      background: #fffaf7;
    }
  }
  .secondary2 {
    background: #ffffff;
    color: #ff5b00;
    border-color: #e6ebe0;
    &:hover {
      color: #ff5d01;
      background: #fff7f7;
      border-color: #e6ebe0;
    }
  }
  .secondary3 {
    background: #ffffff;
    color: #ff6701;
    border-color: #ff6701;
    &:hover {
      color: #ff6701;
      background: #fffaf7;
      border-color: #ff6701;
    }
  }
  .secondary4 {
    background: #ffffff;
    color: #ff5c02;
    border-color: #eb5400;
    &:hover {
      color: #ff5c02;
      background: #fffaf7;
      border-color: #eb5400;
    }
  }
  .blue {
    background: #11bef0;
    color: #ffffff;
    border-color: #11bef0;
    &:hover {
      border-color: #11bef0;
      background: #00b2e5;
    }
  }
  .blue-secondary {
    background: #f7fdff;
    color: #4aa3b4;
    border-color: #c1ecf8;
    &:hover {
      background: #f9f9f9;
      border-color: #c1ecf8;
    }
  }
  .blue-pale {
    background: #9cbded;
    border: #9cbded;
    color: #ffffff;
    &:hover {
      background: #92b7ec;
      border: #92b7ec;
      color: #ffffff;
    }
  }
  .blue4 {
    background: #b7dfff;
    border: #b7dfff;
    color: #1c1c1c;
    &:hover {
      background: #91ceff;
      border: #91ceff;
      color: #1c1c1c;
    }
  }
  .blue5 {
    background: #ffffff;
    border: 1px solid #b1dce4;
    color: #1198b2;
    &:hover {
      background: #f2fdff;
      border: 1px solid #b1dce4;
      color: #1198b2;
    }
  }
  .yellow {
    background: #fff4cb;
    color: #d57b00;
    border-color: #fff4cb;
    &:hover {
      color: #e08100;
      background: #fff8dd;
      border-color: #fff8dd;
    }
  }
  .purple {
    background: #7e7eff;
    border: #7e7eff;
    &:hover {
      background: #7e7eff;
      border: #7e7eff;
    }
  }
  .purple-secondary {
    background: #7e7eff;
    border: #7e7eff;
    color: #ffffff;
    &:hover {
      background: #7e7eff;
      border: #7e7eff;
      color: #ffffff;
    }
  }
  .disable {
    background: ${(props) =>
      props.disableColor ? props.disableColor : '#e5e5e5'};
    color: #ffffff;
    border-color: ${(props) =>
      props.disableColor ? props.disableColor : '#e5e5e5'};
    &:hover {
      color: #ffffff;
      background: ${(props) =>
        props.disableColor ? props.disableColor : '#e5e5e5'};
      border-color: ${(props) =>
        props.disableColor ? props.disableColor : '#e5e5e5'};
    }
  }
`;

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '30px'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
  padding: ${(props) => (props.padding ? props.padding : '10px 20px')};
  align-self: center;
  width: ${(props) => (props.width ? props.width : 'none')};
  line-height: 1;
  text-align: center;
  text-shadow: ${(props) => (props.textShadow ? props.textShadow : 'none')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  p {
    margin-bottom: 0px;
    align-self: center;
  }
  span {
    margin-left: 5px;
    img {
      vertical-align: sub;
    }
    /**
      This is a shared button component.
      Media query was added by Yenny (October 30th, 2020 1:58 PM).
      It is commented because Icon need to be shown for "Claim My Rewards" Button at mobile screen.
      Search for components/DailyChallenge/CorrectAnswerSubmitted.js line 124
    */
    /* @media (max-width: 880px) {
      display: none;
    } */
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    border: 1px solid;
  }

  @media (max-width: 1367px) {
    font-size: ${(props) =>
      props.fontSize ? `calc(${props.fontSize} - 5%)` : 'calc(18px - 5%)'};
  }
  @media (max-width: 1040px) {
    font-size: ${(props) =>
      props.fontSize ? `calc(${props.fontSize} - 10%)` : 'calc(18px - 10%)'};
  }
  /* @media (max-width: 610px) {
    font-size: 10px;
  } */
`;
