import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { defaultLang } from 'helpers/localizationNames';
import { updateLocalePreferences } from 'store/userPreferences/userPreferencesSlice';
import { PopupModal, Button } from 'components/Shared';
import SettingItem from './SettingItem';
import { Container, Item } from './common.styles';

const defaultLanguage = (language) => {
  return defaultLang(
    language.TwoLetterIsolanguageName,
    language.LocaleName,
    language.NativeName
  );
};
const languageDisplayName = (language, selectedLang) => {
  const findLanguage = language.find(
    (lang) => defaultLanguage(lang).code === selectedLang
  );
  return findLanguage ? defaultLanguage(findLanguage).name : selectedLang;
};
const Language = () => {
  const { language } = useSelector((state) => state.login);
  const { i18n, t } = useTranslation(['mySettings']);
  const dispatch = useDispatch();
  const [showLangModal, setShowLangModal] = useState(false);
  const [selectedLang, setSelectedLang] = useState('');
  const [displayLang, setDisplayLang] = useState('');
  const changeLanguage = (code) => {
    setShowLangModal(false);
    i18n.changeLanguage(code);
    dispatch(updateLocalePreferences(code));
    setDisplayLang(code);
  };

  useEffect(() => {
    setSelectedLang(i18n.language);
    setDisplayLang(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <SettingItem
        name="Language"
        content={
          !isEmpty(language) && languageDisplayName(language, displayLang)
        }
        onClick={() => setShowLangModal(true)}
        showChange={!isEmpty(language) && language.length > 1}
      />
      <PopupModal show={showLangModal} hide={() => setShowLangModal(false)}>
        <Container>
          {language.map((lang) => {
            const langInfo = defaultLanguage(lang);
            return (
              <Item
                key={lang.Id}
                isSelected={selectedLang === langInfo.code}
                onClick={() => {
                  setSelectedLang(langInfo.code);
                }}
              >
                <div className="tick" />
                <div>{langInfo.name}</div>
              </Item>
            );
          })}
          <Button
            variant="primary"
            fontSize="16px"
            padding="0.5rem 1.5rem"
            margin="1rem"
            onClick={() => changeLanguage(selectedLang)}
          >
            {t('confirm', 'Confirm')}
          </Button>
        </Container>
      </PopupModal>
    </>
  );
};
export default Language;
