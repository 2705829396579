import styled from 'styled-components';
import StarBg from 'assets/img/sidepanel/star-bg.svg';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  min-width: 363px;
  min-height: 273.86px;
  background: white;
  border-radius: 18px;
`;

export const TopBg = styled.div`
  width: 100%;
  height: ${(props) => props.customHeight || '70px'};
  background: #ffea62 url(${StarBg}) space 0 0;
  background-repeat: repeat-x;
  background-size: 84.36px 54.83px;
  border-radius: 18px 18px 0 0;
`;

export const ModalContent = styled.div`
  padding: 1rem;
  text-align: center;
  .orange {
    color: #ff8001;
    font-size: 1.6875rem;
    font-weight: 600;
  }
  .error {
    color: #ff8001;
    font-size: 0.875rem;
  }
  .top-text {
    font-size: 0.875rem;
  }
  .eligible-amt {
    font-size: 0.9375rem;
  }
  .congrats {
    font-weight: 600;
    font-size: 1.25rem;
    color: #ff8001;
  }
  .has-claimed {
    font-size: 0.875rem;
  }
  .come-back-tmr {
    font-size: 0.875rem;
    color: #ff8001;
    font-weight: 700;
  }
  button {
    border: none;
    background: #ff8001;
    color: white;
    min-width: 161px;
    min-height: 36.72px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 1.25rem;
    :hover {
      background: #e65d01;
    }
  }
`;

export const IconContainer = styled.div`
  text-align: center;
  position: relative;
  top: 5rem;
  p {
    position: relative;
    bottom: 1rem;
    margin: 0.5rem 0.5rem 0 0;
    font-weight: 600;
    font-size: 0.9375rem;
  }
`;

export const Icon = styled.div`
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .daily-bonus-icon {
    margin: 0;
  }

  & > img {
    position: relative;
    top: 5px;
    margin: 0;
    max-width: 100%;
    animation: ${(p) => p.isClaimable && 'pulse 2s infinite'};
    border-radius: 1rem;
    aspect-ratio: 1 / 1;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 42px;
    margin-right: 12px;
    & > img {
      aspect-ratio: auto;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
`;
