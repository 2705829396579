import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import { kooFunIcons } from 'constants/dashboardv2';

import { StyledContainer } from './BrainGameCard.styles';

const BrainGameImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(
    kooFunIcons[subject].brainGame,
    kooFunIcons[subject].brainGameTablet
  );
  return <img src={imgSrc} alt={subject} />;
};

const BrainGameCard = () => {
  const { subject } = useSelector((state) => state.plan);
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const navigateToBrainGame = () => {
    history.push(`/brain-game/${subject}`);
  };
  return (
    <StyledContainer
      onClick={navigateToBrainGame}
      data-cy="brain-game-card"
      subject={subject}
    >
      <div>
        <BrainGameImage subject={subject} />
        <h3>{t('brainGame.name', 'BrainGame')}</h3>
      </div>
    </StyledContainer>
  );
};

export default BrainGameCard;
