import React from 'react';
import Congrats from 'assets/img/superhero/supervision-win.png';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TextInfo,
  WrapButton,
  OtherChallengeButton,
} from './index.styles';

const MessageLost = ({ score, onGoToOtherChallenge }) => {
  const { t } = useTranslation(['superVision']);
  return (
    <Container>
      <img src={Congrats} alt="Congratulations" />
      <TextInfo>
        {t(
          'superVision:game.winText',
          `You've completed the Vision challenge. Your score is `
        )}
        <strong>{score}</strong>
      </TextInfo>
      <WrapButton>
        <OtherChallengeButton onClick={onGoToOtherChallenge}>
          {t('game.goOther', 'Go to other challenges')}
        </OtherChallengeButton>
      </WrapButton>
    </Container>
  );
};

export default React.memo(MessageLost);
