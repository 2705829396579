import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Ribbon from 'components/Mission/Ribbon';
import { PopupModal, Button } from 'components/Shared';
import ModalBgImg from 'assets/img/level/levelup-modal-bg.png';

import { MILESTONE_POPUP } from '../../constants';
import {
  StyledModalContainer,
  StyledContainer,
  StyledModalBg,
} from './MilestonePopup.styles';

const MilestonePopup = ({ open, milestone, closeHandler }) => {
  const { t } = useTranslation(['common', 'brainMaster']);
  const currentMilestone = MILESTONE_POPUP[milestone];

  return (
    <>
      <PopupModal show={open} backdrop="static" hide={closeHandler}>
        <StyledModalContainer>
          <Ribbon text={t('common:congrats', 'Congratulations!')} />
          <StyledContainer>
            <div className="content">
              <p>
                <Trans
                  i18nKey={`brainMaster:${currentMilestone?.translateKey}`}
                  components={{
                    awardText: <span className="cp-text" />,
                  }}
                >
                  {currentMilestone?.desc}
                </Trans>
              </p>
              <img src={currentMilestone?.img} alt={milestone} />

              <Button
                onClick={closeHandler}
                variant="orange4"
                width="100%"
                margin="1rem 0"
              >
                {t('brainMaster:awesome', `Awesome!`)}
              </Button>
            </div>
            <StyledModalBg src={ModalBgImg} alt="" />
          </StyledContainer>
        </StyledModalContainer>
      </PopupModal>
    </>
  );
};

export default MilestonePopup;
