import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TopFifty from 'assets/img/leaderboard/top-fifty.svg';
import { CATEGORIES } from '../../constant';

import { CategoryStyle, CategoryType } from './Category.styles';

const Category = ({
  top50Available = false,
  width,
  children,
  categoryHandler,
  top50Handler,
  active,
  availableCategory = ['class', 'school', 'national', 'global'],
}) => {
  const { t } = useTranslation(['leaderboard']);
  const { subject } = useParams();

  const { country } = useSelector((state) => state.studentDetails);

  const availableFilter = CATEGORIES(country).filter((item) =>
    availableCategory.includes(item.key)
  );

  return (
    <CategoryStyle subject={subject} width={width}>
      <div className="categories-container">
        {availableFilter.map((item) => (
          <CategoryType
            key={item.value}
            onClick={() => categoryHandler(item.value)}
            active={active === item.value}
            subject={subject}
          >
            {t(
              `leaderboard:tabs.${item.translateKey}`,
              { name: country },
              item.name
            )}
          </CategoryType>
        ))}
      </div>
      <div className="right-container">
        {top50Available && (
          <div className="top-fifty" onClick={top50Handler} role="button">
            <img src={TopFifty} alt="top-50" />
            <CategoryType data-cy="top-fifty-button" subject={subject}>
              {t('leaderboard:tabs.showTop', 'Show top 50')}
            </CategoryType>
          </div>
        )}
        {children}
      </div>
    </CategoryStyle>
  );
};

export default Category;
