import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { setOTPRequestBody } from 'store/trialSignup/trialSignupSlice';
import { requestTrialConsentOTP } from 'services/trialSignup';
import validateEmail from 'helpers/validation/validateEmail';

import { subjectNames } from 'constants/products';

import { InputField, Button, CheckBox, DropdownMenu } from 'components/Shared';
import {
  StyledButton,
  StyledErrMsg,
  StyledInput,
  StyledForm,
  StyledContainer,
} from './ParentForm.styles';

const ParentForm = ({
  name,
  setName,
  email,
  setEmail,
  phone,
  setDropdownValue,
  setPhone,
  setCurrentStep,
  errMsg,
  setErrMsg,
  checkedConsent,
  setCheckedConsent,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['parentRegister', 'bundleTrial']);
  const { otpInfo } = useSelector((state) => state.trialSignup);
  const { userID } = useSelector((state) => state.login);
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { subjectName } = useParams();

  const { isLoading, isErr, countries } = useSelector(
    (state) => state.trialSignup
  );
  const [otpRequestErr, setOTPRequestErr] = useState(null);
  const [otpIsLoading, setOtpIsLoading] = useState(false);

  useEffect(() => {
    setErrMsg({});
  }, [setErrMsg]);

  const saveDetails = async (e) => {
    e.preventDefault();
    if (!checkedConsent) return;
    if (!isNil(otpRequestErr)) {
      setOTPRequestErr(null);
    }
    const errorDetail = {};
    if (name === '') {
      errorDetail.name = t('bundleTrial:stepOne.errorName', 'Name is required');
    }

    const e164FormatPhoneNumber = `+${phone.phoneCode}${phone.phoneNumber}`;
    if (
      !isValidPhoneNumber(e164FormatPhoneNumber) ||
      (phone.iso === 'SG' && phone.phoneNumber.length !== 8) ||
      (phone.iso === 'TW' && phone.phoneNumber.charAt(0) === '0')
    ) {
      errorDetail.phoneNumber = t(
        'bundleTrial:error.Invalid mobile number',
        'Invalid mobile number'
      );
    }
    if (isEmpty(phone.phoneNumber) || isNil(phone.phoneNumber)) {
      errorDetail.phoneNumber = t(
        'bundleTrial:stepOne.errorPhoneNumber',
        'Phone number is required'
      );
    }
    if (!validateEmail(email)) {
      errorDetail.email = t(
        'bundleTrial:stepOne.invalidEmail',
        'Invalid email address'
      );
    }
    if (email === '') {
      errorDetail.email = t(
        'bundleTrial:stepOne.errorEmail',
        'Email is required'
      );
    }

    if (isEmpty(errorDetail)) {
      const updatedPhoneCode = phone.phoneCode;
      const updatedPhoneRaw = phone.phoneNumber;
      const otpObj = {
        studentUserId: userID,
        parentUserId: otpInfo.parentUserId,
        e164FormatPhoneNumber: `+${updatedPhoneCode}${updatedPhoneRaw}`,
        parentName: name,
        parentEmail: email,
        countryId: studentDetails?.CountryId,
        subjectId: subjectNames[subjectName] || null,
      };
      try {
        setOtpIsLoading(true);
        const res = await requestTrialConsentOTP(otpObj);
        if (res.otpTrialRegistrationId) {
          dispatch(
            setOTPRequestBody({
              ...otpObj,
              otpTrialRegistrationId: res.otpTrialRegistrationId,
            })
          );
          setCurrentStep(2);
        } else {
          throw new Error('Failed to request OTP');
        }
      } catch (error) {
        setOTPRequestErr(error?.message ?? 'Failed to request OTP');
      } finally {
        setOtpIsLoading(false);
      }
    }
    setErrMsg(errorDetail);
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={saveDetails}>
        <div className="input-container">
          <StyledInput>
            <span className="inp-title name">
              {t('bundleTrial:stepOne.fullName', 'Parent Name')}
            </span>
            <InputField
              style={{
                marginLeft: '0.3rem',
                border: errMsg.name && '1px solid red',
                color: errMsg.name && '1px solid red',
              }}
              dataCy="name-input"
              type="text"
              val={name}
              placeholder={t(
                'bundleTrial:stepOne.inputName',
                'Please enter your name'
              )}
              changeVal={(e) => setName(e.target.value)}
              disabled={!isNil(otpInfo?.parentUserName)}
            />
          </StyledInput>
          {errMsg.name && (
            <p className="error-input error-name-input" data-cy="error-name">
              {errMsg.name}
            </p>
          )}
        </div>
        <div className="input-container">
          <StyledInput>
            <span className="inp-title number">
              {t('bundleTrial:stepOne.phoneNumber', 'Parent Name')}
            </span>
            <div className="phone-container">
              <div className="phone-container-box">
                <DropdownMenu
                  selectedValue={phone.phoneCode ? `+${phone.phoneCode}` : null}
                  values={countries.data || []}
                  setValue={setDropdownValue}
                  valueKey="printName"
                  indexKey="phonecode"
                  name="countryInput"
                  maxWidth="none"
                  containerWidth="auto"
                  width="auto"
                />
                <InputField
                  style={{
                    marginLeft: '0.3rem',
                    border: errMsg.phoneNumber && '1px solid red',
                    color: errMsg.phoneNumber && '1px solid red',
                  }}
                  dataCy="phone-input"
                  type="text"
                  val={phone.phoneNumber}
                  placeholder={t(
                    'bundleTrial:stepOne.inputPhoneNumber',
                    'Mobile number'
                  )}
                  changeVal={(e) =>
                    setPhone({ ...phone, phoneNumber: e.target.value })
                  }
                />
              </div>
              <p className="verification-msg">
                {t(
                  'bundleTrial:stepOne.verificationMsg',
                  'We will send a verification code to this number'
                )}
              </p>
              {errMsg.phoneNumber && (
                <p className="error-phone-input" data-cy="error-phone-number">
                  {errMsg.phoneNumber}
                </p>
              )}
            </div>
          </StyledInput>
        </div>
        <div className="input-container">
          <StyledInput>
            <span className="inp-title email">
              {t('bundleTrial:stepOne.email', 'Email Address')}
            </span>
            <InputField
              style={{
                marginLeft: '0.3rem',
                border: errMsg.email && '1px solid red',
                color: errMsg.email && '1px solid red',
              }}
              dataCy="email-input"
              type="email"
              val={email}
              disabled={!isNil(otpInfo?.emailAddress)}
              placeholder={t(
                'bundleTrial:stepOne.inputEmail',
                'Please enter your email'
              )}
              changeVal={(e) => setEmail(e.target.value)}
            />
          </StyledInput>
          {errMsg.email && (
            <p className="error-input" data-cy="error-email">
              {errMsg.email}
            </p>
          )}
        </div>
        <div className="check-box-container">
          <CheckBox
            color="#6AC8FA"
            id="consent-checkbox"
            checked={checkedConsent}
            onChange={(e) => setCheckedConsent(e.target.checked)}
          />
          <div>
            <p>
              {t(
                `bundleTrial:stepOne.checkbox`,
                `I agree to activate the KooBits 7-day trial for my child.`
              )}
            </p>
          </div>
        </div>
        {isErr && isErr !== '404 Error' && (
          <StyledErrMsg>
            {t(`bundleTrial:error.${isErr.replace('.', '')}`, isErr)}
          </StyledErrMsg>
        )}
        {otpRequestErr && <StyledErrMsg>{otpRequestErr}</StyledErrMsg>}
        <StyledButton>
          <Button
            dataCy="next"
            variant="primary"
            width="200px"
            isDisabled={
              !checkedConsent ||
              isLoading ||
              otpIsLoading ||
              (countries.isErr && !phone.phoneCode)
            }
          >
            {t('bundleTrial:stepOne.nextStep', 'Next')}
          </Button>
        </StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};

export default ParentForm;
