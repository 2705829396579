import React from 'react';
import { Container } from './CheckBox.styles';

const CheckBox = ({
  checked,
  disabled,
  onChange,
  id,
  value,
  label,
  color,
  className,
}) => {
  return (
    <Container color={color} className={className}>
      <div className="container-checkbox">
        <input
          type="checkbox"
          className="check-box"
          onChange={onChange}
          id={id}
          disabled={disabled}
          checked={checked}
          value={value}
        />
        <span className="checkmark" />
        <label htmlFor={id}>{label}</label>
      </div>
    </Container>
  );
};

export default CheckBox;
