import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import Cookies from 'js-cookie';

import store from 'store/index';
import { clearErr, setErr } from 'store/login/loginSlice';
import { setHasSeenLoader } from 'store/dashboard/studentDetailsSlice';
import { newLoginRequest } from 'services/login';

import emailMasking from 'helpers/emailMasking';

import Button from 'components/Shared/Button';

import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { Container, Title, Content, ContentItem } from './LoginModal.styles';

const WRONG_PASS_MSG = 'invalid_username_or_password';

const LoginModal = ({ forgotPassHandler }) => {
  const { t, i18n } = useTranslation(['common', 'mySubs']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { parentInfo } = useSelector((state) => state.profileSettings);
  const { isLoading, error } = useSelector((state) => state.login);
  const [password, setPassword] = useState('');

  const parseErrMsg = (msg) => {
    if (msg === WRONG_PASS_MSG)
      return t(
        'mySubs:loginModal.errMsg',
        'Incorrect password, please try again.'
      );

    return msg;
  };

  const switchPortalHandler = async () => {
    if (isEmpty(password))
      return dispatch(
        setErr(
          t('mySubs:loginModal.errMsg', 'Incorrect password, please try again.')
        )
      );
    try {
      const parentLogin = await newLoginRequest(
        parentInfo.EmailAddress,
        password
      );
      localStorage.setItem('access_token', parentLogin.access_token);
      localStorage.setItem('refresh_token', parentLogin.refresh_token);
      Cookies.set('sp_access_token', parentLogin.access_token, {
        path: '/',
        'max-age': parentLogin.expires_in.toString(),
        domain: 'koobits.com',
      });
      Cookies.set('sp_refresh_token', parentLogin.refresh_token, {
        path: '/',
        'max-age': parentLogin.expires_in.toString(),
        domain: 'koobits.com',
      });
      store.dispatch({ type: 'reset-store' });
      dispatch(setHasSeenLoader(true));
      history.push('/parent/settings/bill-subscription');
    } catch (err) {
      dispatch(setErr(err.message));
    }
  };

  return (
    <Container>
      <Title>
        <Logo className="logo" /> {t('mySubs:loginModal.title', 'Parent')}
      </Title>
      <Content>
        <ContentItem isBaLang={i18n.language === 'ba'}>
          <p className="label">{t('mySubs:loginModal.label1', 'Parent ID')}</p>
          <p className="value">{emailMasking(parentInfo?.EmailAddress)}</p>
        </ContentItem>
        <ContentItem isErr={!isNil(error)} isBaLang={i18n.language === 'ba'}>
          <p className="label">{t('mySubs:loginModal.label2', 'Password')}</p>
          <div className="input-section">
            <input
              name="password"
              value={password}
              onChange={(e) => {
                if (!isNil(error)) {
                  dispatch(clearErr());
                }
                setPassword(e.target.value);
              }}
              type="text"
            />
            {!isNil(error) && <p className="err-msg">{parseErrMsg(error)}</p>}
          </div>
        </ContentItem>
        <Button
          variant="orange4"
          width="100%"
          style={{ alignSelf: 'center' }}
          margin="1rem 0"
          fontSize="1.25rem"
          onClick={switchPortalHandler}
          isDisabled={isLoading || isNil(parentInfo?.EmailAddress)}
        >
          {t('common:proceed', 'Proceed')}
        </Button>
        <p className="forgot-password" onClick={forgotPassHandler}>
          {t('mySubs:loginModal.forgotPass', 'Forgot Password?')}
        </p>
      </Content>
    </Container>
  );
};

export default LoginModal;
