import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { Trans, useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import { Button } from 'components/Shared';

import {
  verifyTrialConsentOTP,
  requestTrialConsentOTP,
} from 'services/trialSignup';

import {
  STYLED_OTP_INPUT,
  RESEND_TIME,
  COUNT_BACKWARDS_NUMBER,
  RESEND_NUMBER,
  MAX_TIMES,
} from './constants';

import { StyledOTP, StyledOTPError } from './OTPForm.styles';

const OTPForm = ({ otp, setOtp, setCurrentStep }) => {
  const { t } = useTranslation(['bundleTrial']);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [interValId, setInterValId] = useState(null);
  const [resendTimes, setResendTimes] = useState(RESEND_NUMBER);
  const [countBackwards, setCountBackwards] = useState(COUNT_BACKWARDS_NUMBER);
  const [disableResend, setDisableResend] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isVerifyError, setIsVerifyError] = useState(null);
  const { isLoading, OTPCodeRequestBody } = useSelector(
    (state) => state.trialSignup
  );

  useEffect(() => {
    setOtp(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countBackwards === 0) {
      clearInterval(interValId);
      setCountBackwards(COUNT_BACKWARDS_NUMBER);
    }
  }, [countBackwards, interValId]);

  const handleOtpChange = (value) => {
    setOtp(value);
    setIsDisabled(value.length !== 6);
  };
  const handleVerifyOtp = async () => {
    setIsSubmitting(true);
    if (!isNil(isVerifyError)) {
      setIsVerifyError(null);
    }
    const verifyOTPBody = {
      otp,
      otpTrialRegistrationId: OTPCodeRequestBody.otpTrialRegistrationId,
    };
    try {
      const res = await verifyTrialConsentOTP(verifyOTPBody);
      if (res.otpTrialRegistrationId) {
        setCurrentStep(3);
      } else {
        throw new Error('Failed to verify OTP');
      }
    } catch (error) {
      console.log(error);
      setIsVerifyError(error?.message ?? 'Failed to verify OTP');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCountDown = () => {
    clearInterval(interValId);
    const interval = setInterval(() => {
      if (countBackwards > 0) {
        setCountBackwards((prev) => prev - 1);
      }
    }, 1000);
    setInterValId(interval);
    setTimeout(() => {
      setDisableResend(false);
    }, RESEND_TIME);
  };

  useEffect(() => {
    setDisableResend(true);
    handleCountDown();
    return () => {
      clearInterval(interValId);
    };
    // eslint-disable-next-line
  }, []);

  const handleResend = async () => {
    if (!disableResend) {
      setDisableResend(true);
      setIsError('');
      if (resendTimes < MAX_TIMES) {
        try {
          handleCountDown();
          const newResendTime = resendTimes + 1;
          await requestTrialConsentOTP(OTPCodeRequestBody);
          setResendTimes(newResendTime);
        } catch (err) {
          console.log(err);
          setIsError(err?.message ?? 'Failed to send request OTP');
        }
      }
    }
  };

  const renderResendOTP = () => {
    return (
      <>
        {disableResend ? (
          <p style={{ marginTop: '20px' }}>
            <Trans i18nKey="bundleTrial:stepTwo.notifyResend" t={t}>
              Resend a new OTP in {{ totalSeconds: countBackwards }} second(s)
            </Trans>
          </p>
        ) : (
          <button
            style={{ marginBottom: '20px' }}
            dataCy="resend-button"
            className="link"
            type="button"
            onClick={handleResend}
          >
            {t(
              'bundleTrial:stepTwo.resendCode',
              'Didn’t receive the OTP? Resend code'
            )}
          </button>
        )}
      </>
    );
  };

  return (
    <StyledOTP>
      <OtpInput
        value={otp}
        onChange={(value) => handleOtpChange(value)}
        numInputs={6}
        inputStyle={STYLED_OTP_INPUT}
        isDisabled={isSubmitting}
        isInputNum
      />
      {isError && <p className="error">{isError}</p>}
      {isVerifyError && (
        <StyledOTPError data-cy="otp-error">{isVerifyError}</StyledOTPError>
      )}
      <Button
        dataCy="verify-button"
        className="otp-button"
        variant="primary"
        width="250px"
        onClick={handleVerifyOtp}
        isDisabled={isDisabled || isLoading || isSubmitting}
      >
        {t('bundleTrial:stepTwo.verifyOtp', 'Submit')}
      </Button>
      {resendTimes >= MAX_TIMES ? (
        <p data-cy="contact-support" style={{ marginTop: '20px' }}>
          <Trans i18nKey="bundleTrial:stepTwo.contactSupport">
            <span>Having trouble?</span>
            <a href="https://support.koobits.com/">Contact Support.</a>
          </Trans>
        </p>
      ) : (
        renderResendOTP()
      )}
    </StyledOTP>
  );
};

export default OTPForm;
