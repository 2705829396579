import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchWrapper } from 'services/login';
import { requestCheckOTPStatus } from 'services/trialSignup';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { PER_RETRY_SEC, RESULT_IDS } from './constants';
import imgActive from './active_adjective.gif';
import { StepThreeContainer } from './StartingUp.styles';

const StartingUp = ({ setCurrentStep, setResultError }) => {
  const { t } = useTranslation(['bundleTrial']);
  const { OTPCodeRequestBody } = useSelector((state) => state.trialSignup);
  useEffect(() => {
    const fetchOTPStatus = async () => {
      try {
        const action = await fetchWrapper(requestCheckOTPStatus, {
          otpTrialRegistrationId: OTPCodeRequestBody.otpTrialRegistrationId,
        });
        const response = handleResponseV2(action);
        if (response.processStatusId === RESULT_IDS.COMPLETED) {
          setCurrentStep(4);
        } else if (response.processStatusId === RESULT_IDS.ERROR) {
          setResultError({ isError: true, message: response.errorCode });
          setCurrentStep(4);
        }
      } catch (e) {
        setResultError({ isError: true, message: e.message });
        setCurrentStep(4);
      }
    };
    fetchOTPStatus();
    const interValId = setInterval(fetchOTPStatus, PER_RETRY_SEC);
    return () => clearInterval(interValId);
  }, [
    OTPCodeRequestBody.otpTrialRegistrationId,
    setCurrentStep,
    setResultError,
  ]);
  return (
    <StepThreeContainer>
      <p>
        {t('bundleTrial:stepThree.title', 'We are activating your account.')}
      </p>
      <p>{t('bundleTrial:stepThree.title2', 'Please wait patiently.')}</p>
      <img src={imgActive} alt="active" />
    </StepThreeContainer>
  );
};

export default StartingUp;
