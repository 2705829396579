import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { postMarkBadgeAsSeen } from 'services/hodCalendar';
import { fetchWrapper } from 'services/login';
import { getUnseenBadges } from 'store/dailyChallenge/hodCalendarSlice';
import HoDMiniBanner from '../../imgs/hod-mini-banner.png';
import Ribbon from 'components/Mission/Ribbon';
import { Container, ModalContainer, Button } from './HeroOfTheDayModal.styles';

const HeroOfTheDayModal = ({
  onHide,
  badgeImage,
  isUnseen,
  unseenBadges,
  hideTopText,
  showHoDBanner,
  setMarkError,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallengeQnView']);
  // Component States
  const [badgesToShow, setBadgesToShow] = useState(unseenBadges);
  // Event Handler
  const btnClickHandler = async () => {
    if (isNil(unseenBadges)) {
      onHide();
    } else if (badgesToShow.length > 1) {
      const newBadgesToShow = [...badgesToShow];
      // TODO: Call mark badge as seen
      const badgeDateTime = moment(
        newBadgesToShow[0].uEntity.Date,
        'YYYY-MM-DDTHH:mm:ss'
      );
      const params = {
        year: badgeDateTime.format('YYYY'),
        month: badgeDateTime.format('MM'),
        day: badgeDateTime.format('DD'),
      };
      if (isUnseen) {
        try {
          await fetchWrapper(postMarkBadgeAsSeen, params);
        } catch (error) {
          setMarkError(error.message);
        }
      }
      newBadgesToShow.shift();
      setBadgesToShow(newBadgesToShow);
    } else {
      const badgeDateTime = moment(
        badgesToShow[0].uEntity.Date,
        'YYYY-MM-DDTHH:mm:ss'
      );
      const params = {
        year: badgeDateTime.format('YYYY'),
        month: badgeDateTime.format('MM'),
        day: badgeDateTime.format('DD'),
      };
      if (isUnseen) {
        try {
          await fetchWrapper(postMarkBadgeAsSeen, params);
          dispatch(getUnseenBadges());
        } catch (error) {
          setMarkError(error.message);
        }
      }
      onHide();
    }
  };
  return (
    <ModalContainer>
      <Ribbon
        text={t(
          'dailyChallengeQnView:allCorrectModal.congrats',
          'Congratulations!'
        )}
      />
      <Container>
        {hideTopText !== true && (
          <p className="cp-text">
            {isNil(unseenBadges)
              ? `${t(
                  'dailyChallengeQnView:hodBadge.earn',
                  'You have earned 15 CPs today.'
                )}`
              : `${t(
                  'dailyChallengeQnView:hodBadge.unlockBadgee',
                  'You just unlocked a new badge!'
                )}`}
          </p>
        )}
        <img
          src={
            isNil(unseenBadges)
              ? badgeImage
              : `data:image/jpeg;base64,${badgesToShow[0]?.Sticker}`
          }
          alt="Hero of the day"
          width="260px"
        />
        {!isNil(unseenBadges) && (
          <img
            src={HoDMiniBanner}
            alt="Hero of the Day"
            className="hod-banner"
            style={{
              visibility:
                badgesToShow[0]?.uEntity.Achievement_id === 1
                  ? 'normal'
                  : 'hidden',
            }}
          />
        )}
        {isNil(unseenBadges) ? (
          <div className="hod-footer">
            <Button
              onClick={btnClickHandler}
              hasUnseenBadges={!isNil(unseenBadges)}
              data-cy="modal-btn"
            >
              {t('dailyChallengeQnView:hodBadge.continue', 'Continue')}
            </Button>
          </div>
        ) : (
          <Button
            onClick={btnClickHandler}
            hasUnseenBadges={!isNil(unseenBadges)}
            data-cy="modal-btn"
          >
            {t('dailyChallengeQnView:hodBadge.awesome', `That's Awesome!`)}
          </Button>
        )}
      </Container>
    </ModalContainer>
  );
};

export default HeroOfTheDayModal;
