import React, { useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useClickOutside from 'hooks/useClickOutside';

import Overlay from 'react-bootstrap/Overlay';

import BackButton from 'components/Shared/BackButton';
import {
  Topbar,
  Container,
  ButtonContainer,
  MoreXP,
  MoreXPOverlay,
} from './ExpLevelHeader.styles';
import ExpLevelHeaderStudent from '../ExpLevelHeaderStudent/ExpLevelHeaderStudent';

const ExpLevelHeader = ({ currentLevel, totalExp, minExp, nextExp }) => {
  const { t, i18n } = useTranslation(['leaderboard', 'expLevel']);
  const history = useHistory();
  const overlayRef = useRef(null);
  const { plan, subject } = useSelector((state) => state.plan);
  const [showOverlay, setShowOverlay] = useState(false);
  const DC_LINKS = {
    math: '/challenges/daily-challenge',
    science: '/challenges/science',
    english: '/challenges/daily-challenge',
  };

  useClickOutside(overlayRef, () => setShowOverlay(false));

  const dcNavigation = () => {
    if (plan === 'school') {
      history.push('/challenges');
    } else {
      history.push(DC_LINKS[subject]);
    }
  };

  return (
    <Topbar>
      <ButtonContainer className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(53, 137, 216, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>

      <Container>
        <ExpLevelHeaderStudent
          currentLevel={currentLevel}
          totalExp={totalExp}
          minExp={minExp}
          nextExp={nextExp}
        />
        <MoreXP
          ref={overlayRef}
          onClick={() => setShowOverlay(!showOverlay)}
          isBaLang={i18n.language === 'ba'}
        >
          {t('expLevel:moreXP.button', 'Earn more XP?')}
        </MoreXP>
        <Overlay target={overlayRef.current} show={showOverlay} placement="top">
          <MoreXPOverlay id="more-xp-tooltip">
            <Trans i18nKey="expLevel:moreXP.overlay">
              Earn more XP by getting correct answers in
              <span
                className="dc-text"
                role="presentation"
                onClick={dcNavigation}
              >
                Daily Challenge
              </span>
            </Trans>
          </MoreXPOverlay>
        </Overlay>
      </Container>
    </Topbar>
  );
};

export default ExpLevelHeader;
