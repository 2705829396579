import { useEffect } from 'react';
import { isEmpty, isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { startGame, dispose } from 'store/brainGame/brainGameSlice';
// Custom hook for handling timer countdown display
export const useBrainGameTimer = () => {
  const dispatch = useDispatch();
  const { timeRemaining, isStarted, isEnded, startTime } = useSelector(
    (state) => state.brainGame
  );
  useEffect(() => {
    if (
      !isNil(startTime) &&
      !isEmpty(startTime) &&
      !isStarted &&
      isEnded === false
    ) {
      dispatch(startGame());
    }
  }, [dispatch, isEnded, isStarted, startTime]);
  // Clean up hook to clear running timer interval
  useEffect(() => {
    return () => {
      dispatch(dispose());
    };
  }, [dispatch]);
  return timeRemaining;
};
