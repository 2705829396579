import styled from 'styled-components';
import MainBg from 'assets/img/doodle-bg.svg';
import { COLORS } from './constants';

export const Container = styled.div`
  background: ${({ isMissionPlus, subject }) =>
    isMissionPlus ? COLORS.missionPlus[subject].main : COLORS.default.main};
  min-height: 100vh;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
`;
export const Main = styled.div`
  background: url(${MainBg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  width: 100vw;

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-top: 68px;
  }
`;

export const StyledIndicatorGift = styled.div`
  display: flex;
  align-items: flex-end;
  height: 65%;
  cursor: pointer;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    right: 30px;
    position: absolute;
  }
`;

export const StyledLegendMissionPlus = styled.div`
  position: absolute;
  right: 20px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
