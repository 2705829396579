import CPMathIcon from 'assets/img/dailychallenge/enable-cp.svg';
import CPScienceIcon from 'assets/img/dailychallenge/enable-cp-green.svg';
import CPEnglishIcon from 'assets/img/leaderboard/hod/english/english-cp-icon.svg';

export const LABEL_COUNTRY = [
  {
    name: 'Name',
    translateKey: 'name',
    flex: 2,
  },
  {
    name: 'School',
    translateKey: 'school',
    flex: 2,
  },
  {
    name: 'Challenge Point',
    translateKey: 'challengePoint',
    flex: 1,
  },
  {
    name: 'Achievement Time',
    translateKey: 'achivementTime',
    flex: 1,
  },
];

export const LABEL_SCHOOL = [
  {
    name: 'Name',
    translateKey: 'name',
    flex: 2,
  },
  {
    name: 'Class',
    translateKey: 'class',
    flex: 2,
  },
  {
    name: 'Challenge Point',
    translateKey: 'challengePoint',
    flex: 1,
  },
  {
    name: 'Achievement Time',
    translateKey: 'achivementTime',
    flex: 1,
  },
];

export const LABEL_CLASS = [
  {
    name: 'Class',
    translateKey: 'class',
    flex: 2,
  },
  {
    name: 'School',
    translateKey: 'school',
    flex: 2,
  },
  {
    name: 'CPs (Top 20)',
    translateKey: 'cpstop20',
    flex: 1,
  },
  {
    name: 'Achievement Time',
    translateKey: 'achivementTime',
    flex: 1,
  },
];

export const CP_ICON = {
  math: CPMathIcon,
  science: CPScienceIcon,
  english: CPEnglishIcon,
};
