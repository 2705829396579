import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { TypeIndicator } from 'components/Assignment';
import {
  HomeworkStatus,
  SubmissionStatus,
  scoreColorsMap,
} from 'constants/index';
import checkIfAssignmentExpired from 'helpers/checkIfAssignmentExpired';
import LockedIcon from './icon-lock.svg';
import {
  Container,
  InfoSection,
  ButtonSection,
  Button,
  Locked,
  AssignmentStatus,
  ScoreColor,
  StartDate,
  MobileInfoSection,
  MobileButtonSection,
  MobileBottomStyle,
  MobileTopStyle,
} from './AssignmentCard.styles';

const AssignmentCard = ({
  id,
  submissionID,
  title,
  type,
  assigner,
  assignedOn,
  deadline,
  submissionStatus,
  expired,
  homeworkStatus,
  totalQn,
  correct,
  qnAttempted,
  startDate,
  resultDate,
  refresh,
  startDateString,
  resultDateString,
}) => {
  const { t } = useTranslation(['assignmentList']);
  const history = useHistory();
  const submittedAssignment =
    SubmissionStatus[submissionStatus] === 'Submitted';
  const deletedAssignment = HomeworkStatus[homeworkStatus] === 'Deleted';
  const today = new Date();
  const dateStart = startDate.setDate(startDate.getDate());
  const dateResult = resultDate.setDate(resultDate.getDate());
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });

  const score = () => {
    if (qnAttempted > 0) {
      return Math.floor((correct / totalQn) * 100);
    }
    return 0;
  };

  const infoSectionTitle = () => {
    if (!submittedAssignment) {
      return t('assignmentList:card.deadline', 'Deadline');
    }
    if (type === 'test' && submittedAssignment && today < dateResult) {
      return t('assignmentList:card.deadline', 'Deadline');
    }
    if (type === 'test' && submittedAssignment && today > dateResult) {
      return t('assignmentList:card.score', 'Score');
    }
    if (submittedAssignment && score() !== null) {
      return t('assignmentList:card.score', 'Score');
    }
    return t('assignmentList:card.deadline', 'Deadline');
  };
  const infoSection = () => {
    if (!submittedAssignment) {
      return (
        <>
          <span className="date">
            {moment.utc(deadline).format('DD/MM/YYYY')}
          </span>
          <span className="date">{moment.utc(deadline).format('LT')}</span>
        </>
      );
    }
    if (type === 'test' && submittedAssignment && today < dateResult) {
      return (
        <>
          <span className="date">
            {moment.utc(deadline).format('DD/MM/YYYY')}
          </span>
          <span className="date">{moment.utc(deadline).format('LT')}</span>
        </>
      );
    }
    if (type === 'test' && submittedAssignment && today >= dateResult) {
      return (
        <ScoreColor
          score={scoreColorsMap(score())}
          deleted={deletedAssignment}
        >{`${score()}%`}</ScoreColor>
      );
    }
    if (submittedAssignment && score() !== null) {
      return (
        <ScoreColor
          score={scoreColorsMap(score())}
          deleted={deletedAssignment}
        >{`${score()}%`}</ScoreColor>
      );
    }
  };

  const buttonStatus = (id) => {
    const viewSolutionsHandler = () => {
      history.push(`/assignment/solution/${id}/${submissionID}`);
    };

    if (deletedAssignment) {
      return (
        <Locked status={deletedAssignment}>
          <p>{t('assignmentList:card.delete', 'Deleted by teacher')}</p>
        </Locked>
      );
    }
    if (expired === 1 && !submittedAssignment && type !== 'homework') {
      return '';
    }
    if (today < dateStart) {
      return (
        <StartDate>
          <p>{t('assignmentList:card.startFrom', 'Start from')}</p>
          <p className="date">
            {moment.utc(startDateString).format('DD/MM/YYYY LT')}
          </p>
        </StartDate>
      );
    }
    if (type === 'test' && submittedAssignment && dateResult === null) {
      return (
        <StartDate type="test">
          <p>{t('assignmentList:card.resultRelease', 'Result Release')}</p>
          <p className="date">
            {t('assignmentList:card.toBeAnnounced', 'To be announced')}
          </p>
        </StartDate>
      );
    }
    if (type === 'test' && submittedAssignment && today < dateResult) {
      return (
        <StartDate type="test">
          <p>{t('assignmentList:card.resultRelease', 'Result Release')}</p>
          <p className="date">
            {moment.utc(resultDateString).format('DD/MM/YYYY LT')}
          </p>
        </StartDate>
      );
    }
    if (type === 'test' && submittedAssignment && today >= dateResult) {
      return (
        <Button status={submittedAssignment} onClick={viewSolutionsHandler}>
          {t('assignmentList:card.view', 'View')}
        </Button>
      );
    }
    if (HomeworkStatus[homeworkStatus] === 'Locked') {
      return (
        <Locked>
          <img src={LockedIcon} alt="icon" />
          <p>{t('assignmentList:card.locked', 'Teacher Locked')}</p>
        </Locked>
      );
    }

    if (submittedAssignment) {
      return (
        <Button status={submittedAssignment} onClick={viewSolutionsHandler}>
          {t('assignmentList:card.view', 'View')}
        </Button>
      );
    }
    return (
      <Button
        status={SubmissionStatus[submissionStatus] === 'Unknown'}
        onClick={() => {
          const isExpired = checkIfAssignmentExpired(deadline);
          if (!isExpired && (type === 'test' || type === 'mocktest')) {
            history.push(`/assignment/start/${id}`);
          } else if (type === 'homework') {
            history.push(`/assignment/start/${id}`);
          } else if (type === 'factualfluency') {
            history.push(`/factual-fluency/ready/${id}`);
          } else {
            refresh();
          }
        }}
      >
        {t('assignmentList:card.startNow', 'Start Now')}
      </Button>
    );
  };

  const bgColor = () => {
    if (deletedAssignment) {
      return '#EEEEEE';
    }
    if (expired === 1 && !submittedAssignment && type !== 'homework') {
      return '#FFEEEE';
    }
    if (submittedAssignment) {
      return '#EEFCE4';
    }
    return '#ffffff';
  };

  const borderColor = () => {
    if (deletedAssignment) {
      return '#DBDBDB';
    }
    if (expired === 1 && !submittedAssignment && type !== 'homework') {
      return '#EFC8C8';
    }
    if (submittedAssignment) {
      return '#B2DCB3';
    }
    return '#dbdbdb';
  };

  const statusColors = () => {
    if (deletedAssignment && submittedAssignment) {
      return '#7B977D';
    }
    if (submittedAssignment) {
      return '#9ACC9A';
    }
    if (expired === 1 && !submittedAssignment && type !== 'homework') {
      return '#CBA8A8';
    }
  };

  if (isMobile) {
    return (
      <MobileInfoSection
        bgColors={bgColor()}
        borderColors={borderColor()}
        expired={expired === 1}
        status={submittedAssignment}
        deleted={deletedAssignment}
      >
        <MobileTopStyle>
          <div style={{ width: '80px' }}>
            <TypeIndicator type={type} deleted={deletedAssignment} />
          </div>
          <AssignmentStatus fontColor={statusColors()}>
            {submittedAssignment &&
              t('assignmentList:card.completed', 'Completed')}
            {expired === 1 &&
              !submittedAssignment &&
              type !== 'homework' &&
              !deletedAssignment &&
              t('assignmentList:card.expired', 'Expired')}
          </AssignmentStatus>
        </MobileTopStyle>
        <div className="details">
          <h5>{title}</h5>
          <p>{`${t(
            'assignmentList:topBar.AssignedBy',
            'Assigned by'
          )}: ${assigner} (${moment
            .utc(assignedOn)
            .format('DD/MM/YYYY')}, ${moment
            .utc(assignedOn)
            .format('LT')})`}</p>
        </div>
        <MobileBottomStyle>
          <div className="status-info">
            <div className="deadline">
              {infoSectionTitle()}
              {infoSection()}
            </div>
          </div>
          <MobileButtonSection bgColors={bgColor()}>
            {buttonStatus(id)}
          </MobileButtonSection>
        </MobileBottomStyle>
      </MobileInfoSection>
    );
  }

  return (
    <Container data-cy="assign-list">
      <InfoSection
        bgColors={bgColor()}
        borderColors={borderColor()}
        expired={expired === 1}
        status={submittedAssignment}
        deleted={deletedAssignment}
      >
        <div className="details">
          <TypeIndicator type={type} deleted={deletedAssignment} />
          <div style={{ alignSelf: 'center' }}>
            <h5>{title}</h5>
            <p>{`${t(
              'assignmentList:topBar.AssignedBy',
              'Assigned by'
            )}: ${assigner} (${moment
              .utc(assignedOn)
              .format('DD/MM/YYYY')}, ${moment
              .utc(assignedOn)
              .format('LT')})`}</p>
          </div>
        </div>
        <div className="status-info">
          <AssignmentStatus fontColor={statusColors()}>
            {submittedAssignment &&
              t('assignmentList:card.completed', 'Completed')}
            {expired === 1 &&
              !submittedAssignment &&
              type !== 'homework' &&
              !deletedAssignment &&
              t('assignmentList:card.expired', 'Expired')}
          </AssignmentStatus>
          <div className="deadline">
            {infoSectionTitle()}
            {infoSection()}
          </div>
        </div>
      </InfoSection>
      <ButtonSection bgColors={bgColor()} borderColors={borderColor()}>
        {buttonStatus(id)}
      </ButtonSection>
    </Container>
  );
};

export default AssignmentCard;
