import styled from 'styled-components';
import { BMBanner, IconWatch, CurtainLeft, CurtainRight } from './img';

export const Container = styled.div``;

export const TitleSection = styled.div`
  background-image: url(${CurtainLeft}), url(${CurtainRight}), url(${BMBanner});
  background-position: left, right, center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: contain, contain, cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    margin-bottom: -22px;
    margin-top: 1rem;
  }

  @media (max-width: 1190px) {
    .miko-award {
      width: 30%;
    }
  }
  @media (max-width: 996px) {
    .miko-award {
      margin-top: 0;
      margin-left: -5rem;
    }
    height: 10rem;
  }
  @media (max-width: 768px) {
    .miko-award {
      width: 35%;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  align-items: center;
  @media (max-width: 1190px) {
    gap: 1rem;
    margin-top: -2.5rem;
  }
  @media (max-width: 996px) {
    gap: 0.5rem;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0;
    margin-top: -0.5rem;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  @media (max-width: 1190px) {
    width: 80%;
    p {
      font-size: 1rem;
      margin-top: 0;
    }
  }
  @media (max-width: 996px) {
    width: 65%;
    p {
      font-size: 0.875rem;
    }
  }
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 1;

  .nod {
    background: url(${IconWatch});
    background-size: contain;
    background-repeat: no-repeat;
    width: 37.04px;
    height: 42.45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    font-size: 13px;
    font-weight: 700;
    color: #812900;
    z-index: 1;
    margin-bottom: 8px;
  }
  .unit-period {
    display: flex;
    background: rgba(149, 47, 0, 0.62);
    border-radius: 50px;
    gap: 8px;
    margin-left: -13px;
    font-size: 14px;
    .unit {
      background: rgba(98, 51, 10, 0.65);
      border-radius: 3px;
      padding: 3px 8px 3px 15px;
      font-weight: 600;
    }
    .period {
      border-radius: 50px;
      padding: 3px 20px 3px 0px;
    }
  }
  @media (max-width: 996px) {
    .nod {
      width: 30px;
      height: 30px;
      font-size: 0.5625rem;
      padding-right: 3px;
    }
    .unit-period {
      font-size: 0.625rem;
    }
  }
`;

export const Period = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .end-flag {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    position: absolute;
    top: 0.75rem;
    color: #ffffff;
    width: 100%;
    padding-top: 2rem;
    text-align: center;
    padding-bottom: 0.5rem;
  }

  @media (max-width: 996px) {
    .end-flag {
      font-size: 0.625rem;
      top: 1rem;
      padding-top: 1.1rem;
      padding-bottom: 0.2rem;
    }
  }
`;

export const HowToWin = styled.button`
  background: rgba(129, 41, 0, 0.25);
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  position: absolute;
  right: 3.5rem;
  bottom: 1.8rem;

  @media (max-width: 1024px) {
    right: 1.2rem;
  }
`;

export const ProgressSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;
  padding-top: 4rem;
  font-size: 19px;
  font-weight: 700;
  width: 100%;
  .progress-text {
    span {
      font-family: 'Baloo Chettan';
      font-size: 1.625rem;
    }
  }
`;
