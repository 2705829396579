import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentProduct } from 'store/plan/planSlice';
import { PRODUCT_TYPE } from 'constants/products';
import accessFeature from 'helpers/accessFeature';
import KooQuizQnView from './KooQuizQnView';

const KooQuizQnViewChecker = ({ history }) => {
  const dispatch = useDispatch();
  const {
    currentProduct,
    subject,
    allNonExpiredSubscriptionProducts,
  } = useSelector((state) => state.plan);

  if (history.action === 'POP') {
    if (currentProduct && !currentProduct.expired) {
      const { url, error } = accessFeature({
        type: 'kooquiz',
        subject,
        featuresConfig: currentProduct?.features,
      });
      if (!error) {
        return <Redirect to={{ pathname: url }} />;
      }
    }
    const findNonExpiredB2CProducts = allNonExpiredSubscriptionProducts?.find(
      (np) =>
        np.productType.toLowerCase() === PRODUCT_TYPE.PREMIUM.toLowerCase()
    );

    if (findNonExpiredB2CProducts) {
      const { url, error } = accessFeature({
        type: 'kooquiz',
        subject,
        featuresConfig: findNonExpiredB2CProducts?.features,
      });
      if (!error) {
        dispatch(setCurrentProduct(findNonExpiredB2CProducts));
        return <Redirect to={{ pathname: url }} />;
      }
    }
    return null;
  }
  return <KooQuizQnView />;
};

export default KooQuizQnViewChecker;
