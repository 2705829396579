import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWrapper } from 'services/login';
import { claimDailyKoKo } from 'services/dailyBonus';
import {
  getRewards,
  checkDailyBonusClaimed,
} from 'store/dashboard/rewardsSlice';
import claimedDailyBonusIcon from 'assets/img/sidepanel/claimedDailyBonus.svg';
import KokoGold from 'assets/img/dailychallenge/gold-koko.svg';
import DailyBonusIcon from 'assets/img/sidepanel/dailybonus.svg';

import {
  ModalContainer,
  IconContainer,
  Container,
  TopBg,
  ModalContent,
} from './ClaimDailyBonus.styles';

const ClaimDailyBonusModal = ({ onHide, setError }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard']);
  const { dailyBonusKoKoToBeClaimed } = useSelector((state) => state.rewards);
  // Component States
  const [isClaiming, setIsClaiming] = useState(false);
  const [hasClaimed, setHasClaimed] = useState(false);

  // Event Handler
  const claimKoKoHandler = async () => {
    setIsClaiming(true);
    setError(null);
    try {
      await fetchWrapper(claimDailyKoKo);
      setHasClaimed(true);
      dispatch(getRewards());
      dispatch(checkDailyBonusClaimed());
    } catch (err) {
      setError(err.message);
    }
    setIsClaiming(false);
  };
  const closeModal = () => {
    onHide();
  };

  return (
    <ModalContainer data-cy="daily-bonus-modal">
      <IconContainer>
        {!hasClaimed && (
          <img src={DailyBonusIcon} alt="" width="89.02px" height="85.44px" />
        )}
        {hasClaimed && (
          <img
            src={claimedDailyBonusIcon}
            alt=""
            width="96.4px"
            height="105.77px"
          />
        )}
        <p>{t(`dashboard:dailyBonusModal.title`, 'Daily Bonus')}</p>
      </IconContainer>
      <Container>
        <TopBg />
        <ModalContent>
          {!hasClaimed && (
            <>
              <p className="top-text">
                <span className="orange">
                  {t(`dashboard:dailyBonusModal.Yeah`, 'Yeah!')}
                </span>{' '}
                {t(
                  `dashboard:dailyBonusModal.mainText`,
                  'You are eligible for'
                )}
              </p>
              <p className="eligible-amt">
                {`${t(
                  `dashboard:dailyBonusModal.mainText2ndLinePt1`,
                  { koko: dailyBonusKoKoToBeClaimed },
                  ' free '
                )}`}
                <img
                  src={KokoGold}
                  alt="koko"
                  width="33.8px"
                  height="34.63px"
                />
                {t(
                  `dashboard:dailyBonusModal.mainText2ndLinePt2`,
                  ' KoKo today!'
                )}
              </p>
              <button
                type="button"
                disabled={isClaiming}
                onClick={claimKoKoHandler}
                data-cy="claim-bonus"
              >
                {isClaiming
                  ? t(
                      `dashboard:dailyBonusModal.loadingButtonText`,
                      'Claiming...'
                    )
                  : t(`dashboard:dailyBonusModal.buttonText`, 'Claim now')}
              </button>
            </>
          )}
          {hasClaimed && (
            <>
              <p className="congrats">
                {t(`dashboard:dailyBonusModal.Congrats`, 'Congratulations!')}
              </p>
              <p className="has-claimed">
                {t(
                  `dashboard:dailyBonusModal.claimedText`,
                  'You have claimed the Daily Bonus KoKo Credit.'
                )}
              </p>
              <p className="come-back-tmr">
                {t(
                  `dashboard:dailyBonusModal.claimedText2`,
                  'Please come back tomorrow for more!'
                )}
              </p>
              <button type="button" onClick={closeModal} data-cy="ok-btn">
                {t(`dashboard:dailyBonusModal.okButtonText`, 'OK')}
              </button>
            </>
          )}
        </ModalContent>
      </Container>
    </ModalContainer>
  );
};

export default ClaimDailyBonusModal;
