import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { finishSubmitAssignment } from 'store/assignment/assignmentSlice';
import { submitEventAssignment } from 'store/event/eventSlice';
import { assignmentTypeIDs } from 'constants/index';
import { Spinner, ErrorModal } from 'components/Shared';
import DetailsInfo from 'components/Assignment/StartPage/DetailsInfo';
import { Header } from '../components';
import { ResultRelease } from './components';

import { Container, Main, AssignerSection } from './TestSubmitted.styles';

const TestSubmitted = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const eventsQs = location.pathname === '/events/submitted/result-release';
  const {
    startAssignmentInfo,
    isSubmitting,
    finishSubmitAssignmentError,
    finishSubmitAssignmentParams,
  } = useSelector((state) => state.assignment);
  const {
    startEventInfo,
    isEventSubmitting,
    assignmentEventSubmissionError,
    assignmentEventSubmissionParams,
  } = useSelector((state) => state.event);
  let testResultDate;
  let submitting;
  let submissionErr;
  let assignmentType = null;

  const isMobile = useMediaQuery({ query: '(max-width: 502px)' });

  if (eventsQs) {
    testResultDate = startEventInfo.TestResultDate;
    submitting = isEventSubmitting;
    submissionErr = assignmentEventSubmissionError;
    assignmentType = 'sundayMC';
  } else {
    testResultDate = startAssignmentInfo?.ResultDate;
    submitting = isSubmitting;
    submissionErr = finishSubmitAssignmentError;
    assignmentType =
      assignmentTypeIDs[startAssignmentInfo && startAssignmentInfo.Type];
  }
  const reloadHandler = () => {
    if (finishSubmitAssignmentError) {
      dispatch(finishSubmitAssignment(finishSubmitAssignmentParams));
    }
    if (assignmentEventSubmissionError) {
      dispatch(submitEventAssignment(assignmentEventSubmissionParams));
    }
  };

  return (
    <Container>
      <Header
        page="finish"
        type={assignmentType}
        isLoading={submitting}
        assignmentTitle={
          eventsQs
            ? 'Sunday Mini Challenge'
            : startAssignmentInfo && startAssignmentInfo.Title
        }
        eventsQs={eventsQs}
        isMobile={isMobile}
      />
      <Main type={assignmentType}>
        {!submitting && submissionErr !== null && (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={submissionErr}
          />
        )}
        {submitting && <Spinner />}
        {!submitting && submissionErr === null && (
          <>
            <div className="wrapper">
              <div className="summary-box">
                <ResultRelease
                  resultDate={testResultDate}
                  eventsQs={eventsQs}
                  isMobile={isMobile}
                />
              </div>
            </div>
            {!eventsQs && (
              <AssignerSection type={assignmentType}>
                <DetailsInfo
                  page="finish-hw"
                  category="Assigned by"
                  info={startAssignmentInfo && startAssignmentInfo.AssignerName}
                />
                <DetailsInfo
                  page="finish-hw"
                  category="Deadline"
                  info={moment(
                    startAssignmentInfo && startAssignmentInfo.EndDate
                  ).format('DD-MMM-yy')}
                />
              </AssignerSection>
            )}
          </>
        )}
      </Main>
    </Container>
  );
};

export default TestSubmitted;
