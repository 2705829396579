import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PremiumPlanv2 from 'assets/img/plan-toggle/premium-plan.png';
import { BackButton } from 'components/Shared';
import { OverlayCard } from './components';

import CreateAssignmentIllustration from './imgs/create-assignment-illustration.svg';
import AssignmentBg from './imgs/assignments-bg.svg';
import AssignmentIllustration from './imgs/assignment-illustration.svg';
import PlusIcon from './imgs/icon-plus.svg';
import CreateAssignmentBg from './imgs/create-assignment-bg.svg';

import { Top, Cards } from './AssignmentIntermediate.styles';

const AssignmentIntermediate = () => {
  const { t } = useTranslation(['assignmentIntermediate']);
  const history = useHistory();
  const location = useLocation();
  const isFromShortCut = location.state?.quickAccess;

  return (
    <>
      <Top>
        <BackButton
          styles={{ position: 'absolute', left: '4%' }}
          backText={t('assignmentIntermediate:header.back', 'Back')}
          padding="0.3rem 1.2rem"
          btnBgColor="#8BD03E"
          onClick={() => {
            if (isFromShortCut) {
              history.goBack();
              return;
            }
            history.push('/dashboard?view=kootraining');
          }}
        />
        <p>{t('assignmentIntermediate:header.assignment', 'Assignment')}</p>
      </Top>
      <Cards>
        <div className="card-item card-parent-assign">
          <OverlayCard
            noOverlay
            heading={t(
              'assignmentIntermediate:main.card1',
              'Create Personalised Assignment'
            )}
            illustration={CreateAssignmentIllustration}
            bg={CreateAssignmentBg}
            onClick={() => history.push('/assignment/parent-assign')}
            icon={PlusIcon}
            plan={PremiumPlanv2}
          />
          <p className="card-details">
            {t(
              'assignmentIntermediate:main.details1',
              "Parents can create personalised homework to your child based on child's perfomance."
            )}
          </p>
        </div>
        <div className="card-item card-my-assign">
          <OverlayCard
            noOverlay
            heading={t('assignmentIntermediate:main.card2', 'My Assignment')}
            illustration={AssignmentIllustration}
            bg={AssignmentBg}
            onClick={() => history.push('/assignment/assignment-list')}
          />
          <p className="card-details">
            {t(
              'assignmentIntermediate:main.details2',
              'Click the button to check out your assignments.'
            )}
          </p>
        </div>
      </Cards>
    </>
  );
};

export default AssignmentIntermediate;
