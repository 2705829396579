import React, { useState } from 'react';
import ArrowDownIcon from 'assets/img/navigation/arrow-down1.svg';
import getTitleLabel from 'helpers/getTitleLabel';
import { BottomModal } from 'components/Shared';
import {
  SelectContainer,
  ButtonContainer,
  ModalTitle,
  ModalContent,
} from './FilterModal.styles';

const LevelFilterModal = ({
  type = 'box' || 'circle',
  values,
  setValue,
  selectedValue,
  valueTemplate,
  valueKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isCircle = type === 'circle';

  const handleModal = () => {
    setIsOpen((open) => !open);
  };

  const handleChangeValue = (value) => () => {
    handleModal();
    setValue(value);
  };

  return (
    <>
      <SelectContainer onClick={handleModal} isCircle={isCircle}>
        <p>{selectedValue}</p>
        <img src={ArrowDownIcon} alt="" />
      </SelectContainer>
      <BottomModal isOpen={isOpen} handleClose={handleModal}>
        <ModalTitle>Choose question from</ModalTitle>
        <ModalContent isCircle={isCircle}>
          {values.map((value, i) => {
            const label = getTitleLabel(value, i, valueTemplate, valueKey);

            return (
              <ButtonContainer
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                onClick={handleChangeValue(value)}
                className={isCircle ? 'circleContent' : 'boxContent'}
                isSelected={selectedValue === label}
                isCircle={isCircle}
              >
                <p>{label}</p>
              </ButtonContainer>
            );
          })}
        </ModalContent>
      </BottomModal>
    </>
  );
};
export default LevelFilterModal;
