import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { isEmpty } from 'ramda';
import moment from 'moment';

import { sidePanelNavigation, subNavData } from 'constants/dashboardv2';

import PremiumIcon from 'assets/img/dashboardv2/premium.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/dashboardv2/dashboard/arrow-icon.svg';
import { ReactComponent as EventIcon } from 'assets/img/events/icon-event.svg';

import { rwd } from 'Theme';
import {
  NavList,
  NavItem,
  NavContnet,
  NavItemTop,
  NavItemBottom,
  SubNavList,
  SubNavItem,
  PremiumSmallIcon,
  EventItem,
} from './SubpageNav.style';

export const useKooClassEnabledStatus = () => {
  const { currentProduct } = useSelector((state) => state.plan);
  const currentProductKooClassConfig = currentProduct?.features.find(
    (item) => item.feature.toLowerCase() === 'kooclass'
  );
  const kooclassLink =
    currentProduct && currentProductKooClassConfig
      ? `/dashboard?view=kooclass&courseid=${currentProductKooClassConfig?.parameters?.CourseId}`
      : '/';
  const kooClassIsEnabled =
    currentProductKooClassConfig?.parameters?.IsEnabled ?? false;
  return {
    kooclassLink,
    kooClassIsEnabled,
  };
};

const MainNavContent = ({
  activeView,
  subject,
  view,
  t,
  index,
  kooClassIsEnabled,
}) => {
  const isShowPremiumIcon = !kooClassIsEnabled && index === 2;
  return (
    <>
      <NavContnet active={activeView === view.id} subject={subject}>
        <view.icon />
        {t(`dashboard:sidepanel.${view.name}`, view.name)}
      </NavContnet>
      {isShowPremiumIcon && <PremiumSmallIcon src={PremiumIcon} />}
      {activeView === view.id && (
        <>
          <NavItemTop subject={subject} />
          <NavItemBottom subject={subject} />
          <ArrowIcon className="current-arrow" />
        </>
      )}
    </>
  );
};

const useGetMenuList = () => {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  if (isMobile) {
    return sidePanelNavigation.slice(0, 2);
  }
  return sidePanelNavigation;
};

const SubpageNav = ({ activeView }) => {
  const { t } = useTranslation(['dashboard', 'eventList']);
  const history = useHistory();
  const { subject, hasSeenLoaderSubjectChange } = useSelector(
    (state) => state.plan
  );
  const { userID } = useSelector((state) => state.login);
  const { hasSeenLoader } = useSelector((state) => state.studentDetails);
  const { kooclassLink, kooClassIsEnabled } = useKooClassEnabledStatus();
  const { data: eventsData } = useSelector((state) => state.eventsList.events);
  const list = useGetMenuList();

  const [isWobbling, setIsWobbling] = useState(false);
  const post1MonthDate =
    process.env.REACT_APP_DEV === 'true' ? '2024-10-24' : '2024-11-30';
  const isBrainMasterPost1Month = moment().isAfter(post1MonthDate);

  const handleWobble = () => {
    setIsWobbling(true);
    setTimeout(() => setIsWobbling(false), 1000);
  };

  const navigateToKooClassView = (route) => {
    return () => {
      history.push(route);
    };
  };

  useEffect(() => {
    if (hasSeenLoaderSubjectChange && hasSeenLoader) {
      handleWobble();
      const interval = setInterval(() => {
        handleWobble();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [hasSeenLoaderSubjectChange, hasSeenLoader]);

  const sendClickTracking = () => {
    window.gtag('event', 'event_tab_click', {
      category: 'Student Portal',
      action: 'Click Event Tab on Dashboard',
      label: `${userID}-Click Event Tab`,
    });
  };

  return (
    <>
      <NavList data-cy="sidepanel-navigation">
        {list.map((view, index) => {
          const isActive = activeView === view.id ? 1 : 0;
          return (
            <NavItem
              data-cy="sidepanel-navigation-item"
              key={view.id}
              onClick={navigateToKooClassView(
                kooClassIsEnabled && view.id === 3
                  ? kooclassLink
                  : `/dashboard?view=${view.name}`
              )}
              active={isActive}
              subject={subject}
            >
              <MainNavContent
                index={index}
                kooClassIsEnabled={kooClassIsEnabled}
                activeView={activeView}
                subject={subject}
                t={t}
                view={view}
              />
            </NavItem>
          );
        })}
      </NavList>
      <SubNavList subject={subject} data-cy="sidepanel-sub-navigation">
        {!isEmpty(eventsData) && (
          <EventItem
            subject={subject}
            to="/events"
            id="event"
            onClick={sendClickTracking}
          >
            <div className="circle">
              <EventIcon className="icon" />
            </div>
            <div className="label">
              {t('eventList:header.events', 'Events')}{' '}
              {!isBrainMasterPost1Month && (
                <div className={`${isWobbling ? 'wobble' : ''} beta`}>BETA</div>
              )}
            </div>
          </EventItem>
        )}
        {subNavData.map((sub) => {
          const Icon = sub.icon;
          return (
            <SubNavItem
              key={sub.id}
              to={sub.link}
              subject={subject}
              data-cy="sidepanel-sub-navigation-item"
            >
              <div className="circle">
                <Icon />
              </div>
              <div className="label">
                {t(`dashboard:sidepanel.${sub.name}`, sub.name)}
              </div>
            </SubNavItem>
          );
        })}
      </SubNavList>
    </>
  );
};

export default SubpageNav;
