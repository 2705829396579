import styled from 'styled-components';

export const StepThreeContainer = styled.div`
  text-align: center;
  padding: 27px 72px 18px;
  p {
    margin-bottom: 0;
    line-height: 22px;
  }
  img {
    width: 116px;
  }
`;
