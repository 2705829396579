import styled, { css } from 'styled-components';
import MathBg from 'assets/img/math/math-bg.png';

export const Page = styled.div`
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-height: 850px) {
    display: block;
    padding-top: 7rem;
  }
  ${({ subject }) => {
    if (subject === 'math') {
      return css`
        background-size: contain;
        background-position: bottom;
        background-image: url(${MathBg});
      `;
    }
    return css`
      ::after {
        content: '';
        display: block;
        position: absolute;
        background-color: ${() => {
          if (subject === 'science') {
            return '#f2ffe3';
          }
          if (subject === 'english') {
            return '#FEFDF0';
          }
        }};
        bottom: 0;
        height: 38%;
        left: 0;
        right: 0;
        z-index: -1;
      }
    `;
  }}
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 0 2rem;
  gap: 2.25rem;
  position: relative;
  bottom: 5rem;
  svg {
    margin-bottom: 12px;
  }
  .desc {
    max-width: 390px;
  }
  .new {
    background: #ffc619;
    color: #846600;
    font-family: 'Muli';
    font-size: 17px;
    font-weight: 900;
    padding: 7px 14px;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 4px;
    line-height: 1;
  }
  .main-title {
    font-weight: 900;
    font-family: 'Muli';
    font-size: 26px;
  }
  .main-desc {
    line-height: 28px;
    font-size: 1rem;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
    .highlight {
      background: #ffff00;
      width: fit-content;
    }
  }

  .boost {
    background: #ffd800;
    padding: 14px;
    margin-bottom: 20px;
    border-radius: 8px;
    font-weight: 600;
    display: inline-block;
    line-height: 1;
  }
  .cover {
    max-width: 564px;
  }
  @media (max-height: 850px) {
    bottom: 0;
    padding: 4rem 2rem 0 2rem;
  }
  ${({ subject }) => {
    if (subject === 'math') {
      return css`
        .main-title {
          margin-bottom: 20px;
        }
        .main-desc {
          margin-bottom: 12px;
        }
      `;
    }
    return css`
      .main-title {
        margin-bottom: 30px;
      }
      .main-desc {
        margin-bottom: 40px;
      }
    `;
  }}
`;
