import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { isNil } from 'ramda';
import { setHasSeenLoader } from 'store/dashboard/studentDetailsSlice';
import {
  getUserNotification,
  setDirectToNotifPage,
  markNotification,
  resetMarkNotification,
} from 'store/notification/notificationSlice';
import useGetUserNotification from 'hooks/useGetUserNotification';

import Header from 'components/Header';
import Button from 'components/Shared/Button';
import ErrorResponse from 'components/Shared/ErrorResponse';
import Spinner from 'components/Shared/Spinner';
import ParsedHtml from 'components/Notifications/ParsedHtml';
import ErrorModal from 'components/Shared/ErrorModal';
import EmptyIcon from 'assets/img/icon-empty-notification.svg';
import { Container, Top, EmptyContent, Content } from './index.styles';

const Notification = () => {
  const { t } = useTranslation(['notification']);
  const history = useHistory();
  const location = useLocation();
  const locationFromState = isNil(location.state) ? null : location.state.from;
  const dispatch = useDispatch();
  const {
    isLoadingUserNotification,
    isRead,
    pageNotif,
    isErrorUserNotification,
    isMarkError,
    markedNotification,
  } = useSelector((state) => state.notification);
  const { isLoading } = useSelector((state) => state.studentDetails);

  const loading = isLoading || isLoadingUserNotification;
  useGetUserNotification();
  useEffect(() => {
    if (locationFromState === 'login') {
      dispatch(setHasSeenLoader(true));
    }
  }, [dispatch, locationFromState]);

  const notifId = pageNotif && pageNotif.id;

  useEffect(() => {
    dispatch(setDirectToNotifPage(true));
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(markedNotification)) {
      dispatch(resetMarkNotification());
      history.push('/dashboard');
    }
  }, [dispatch, history, markedNotification]);

  const reloadNotif = () => {
    dispatch(getUserNotification());
  };

  const clearErrHandler = () => {
    if (!isNil(isMarkError)) {
      dispatch(resetMarkNotification());
    }
  };

  return (
    <div>
      <Header />
      <Container>
        <Top>{t('notification:header', 'Notification')}</Top>
        {loading && <Spinner />}
        {!isNil(pageNotif) && !loading && (
          <ErrorBoundary FallbackComponent={ErrorResponse}>
            <Content>
              <ParsedHtml type="page" />
            </Content>
          </ErrorBoundary>
        )}
        {isNil(pageNotif) && !loading && isNil(isErrorUserNotification) && (
          <EmptyContent>
            <img src={EmptyIcon} alt="empty-notification" />
            <h4>{t('notification:emptyTitle', 'No new notifications !')}</h4>
            <p>
              {t(
                'notification:emptyDesc',
                'Click this session for updates, new events and general notifications.'
              )}
            </p>
          </EmptyContent>
        )}
        {!loading && isNil(isErrorUserNotification) && (
          <div className="btn-container">
            <Button
              dataCy="mark-btn"
              variant="primary"
              width="200px"
              fontSize="22px"
              onClick={() => {
                if (!isRead && !isNil(pageNotif)) {
                  dispatch(markNotification(notifId));
                } else {
                  history.push('/dashboard');
                }
              }}
            >
              {t('notification:btn', 'Got It')}
            </Button>
          </div>
        )}
        {(!isNil(isErrorUserNotification) || !isNil(isMarkError)) && (
          <ErrorModal
            errorMessage={
              !isNil(isErrorUserNotification)
                ? isErrorUserNotification
                : isMarkError
            }
            reloadAction={!isNil(isErrorUserNotification) && reloadNotif}
            closeHandler={clearErrHandler}
          />
        )}
      </Container>
    </div>
  );
};

export default Notification;
