import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAssignmentDetails,
  resetStartAssignmentInfoError,
} from 'store/assignment/assignmentSlice';
import { assignmentTypeIDs } from 'constants/index';
import PictureType from 'components/Assignment/StartPage/PictureType';
import { AssignmentDetails } from './components';
import { Spinner, ErrorModal } from 'components/Shared';
import { Container } from './StartAssignment.styles';

const StartAssignment = ({ match }) => {
  const assignmentID = parseInt(match.params.id, 10);
  const dispatch = useDispatch();
  const {
    startAssignmentInfo,
    isLoading,
    startAssignmentInfoError,
  } = useSelector((state) => state.assignment);

  useEffect(() => {
    dispatch(getAssignmentDetails(assignmentID));
    return () => {
      dispatch(resetStartAssignmentInfoError());
    };
  }, [assignmentID, dispatch]);
  return (
    <Container>
      {!isLoading && startAssignmentInfoError && (
        <ErrorModal
          backPage="/assignment/assignment-list"
          errorMessage={startAssignmentInfoError}
        />
      )}
      {isLoading && <Spinner top="50px" />}
      {!isLoading &&
        startAssignmentInfo !== null &&
        startAssignmentInfoError === null &&
        (assignmentTypeIDs[startAssignmentInfo.Type] === 'factualfluency' ? (
          <Redirect to={`/factual-fluency/ready/${assignmentID}`} />
        ) : (
          <>
            <PictureType type={assignmentTypeIDs[startAssignmentInfo.Type]} />
            <AssignmentDetails />
          </>
        ))}
    </Container>
  );
};

export default StartAssignment;
