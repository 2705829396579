import styled from 'styled-components';

export const NotebookHeader = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  width: 97%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  p {
    margin-bottom: 0rem;
    color: #028ba0;
  }
  button {
    font-size: 16px;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 0rem;
    margin-bottom: 1rem;
    button {
      font-size: 18px;
    }
  }
`;
