import React from 'react';
import { DifficultyChillis } from 'components/Shared';
import { Container } from './DifficultyChillisInfo.styled';

const DifficultyChillisInfo = ({ t, question, containerClass = '' }) => {
  return (
    <Container className={`${containerClass}`}>
      <div className="qn-difficulty">
        <p>{t('selfPracticeQnView:content.difficulty', 'Difficulty')}:</p>
        <DifficultyChillis
          value={question && question.Difficulty.ActualValue}
        />
      </div>
      <div className="qn-id">
        {t('selfPracticeQnView:content.qnId', 'Qn ID')}{' '}
        {question && question.Id}
      </div>
    </Container>
  );
};

export default DifficultyChillisInfo;
