import { useEffect, useState } from 'react';
import { fetchWrapper } from 'services/login';
import { getRemainingPCAttempts } from 'services/multiplayerv2';

const usePeerChallengeAttempts = (userID, subjectID) => {
  const [remainingAttempts, setRemainingAttempts] = useState({
    status: 'idle',
    error: null,
    data: null,
  });
  const [reloadCount, setReloadCount] = useState(0);
  const reloadHandler = () => {
    setReloadCount((prevState) => prevState + 1);
  };
  useEffect(() => {
    let isMounted = true;
    if (!userID) return;
    fetchWrapper(getRemainingPCAttempts, { userID, subjectID })
      .then((res) => {
        if (isMounted) {
          setRemainingAttempts({
            status: 'fulfilled',
            data: res?.Result,
            error: null,
          });
        }
      })
      .catch((err) => {
        if (isMounted) {
          setRemainingAttempts({
            status: 'rejected',
            data: null,
            error: err.message,
          });
        }
      });
    return () => {
      isMounted = false;
    };
  }, [userID, subjectID, reloadCount]);
  return { remainingAttempts, reloadHandler };
};

export default usePeerChallengeAttempts;
