import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmissionResultTypes } from 'constants/index';
import {
  setActive,
  resetSubmitAns,
} from 'store/dailyChallenge/dailyChallengeSlice';
import {
  difficultyLevels,
  renderQnDifficultyLevels,
} from 'constants/dailyChallenge';
import QnEnd from 'components/Assignment/AssignmentView/QnSideBar/QnEnd';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { clearAll } from 'store/assignment/barModelSlice';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { ProficiencyProgress } from 'components/Shared';
import { Container, StyledSidebarWrap } from './QnSideBar.styles';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';
import QnNav from './QnNav';

const QnSideBar = ({
  questions,
  activeQuestion,
  page,
  setExpandQn,
  subjectID,
  setShow,
  showIcon = true,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallenge']);
  const { currDailyChallenge, dailyChallengeSubmissions } = useSelector(
    (state) => state.dailyChallenge
  );
  const { ChallengeLevel } = currDailyChallenge;
  // state for collapse sidebar
  const [collapse, setCollapse] = useState(false);
  const isCorrect = (index) => {
    const submissionModel = dailyChallengeSubmissions[index].SubmissionModel;
    if (submissionModel === null) {
      return 'incomplete';
    }
    if (SubmissionResultTypes[submissionModel.SubmissionResult] === 'correct') {
      return 'correct';
    }
    if (dailyChallengeSubmissions[index].QuestionStatus === 4) {
      return 'skipped';
    }
    return 'incomplete';
  };

  const countCorrectWrongSubmissions = () => {
    const result = {
      correct: 0,
      skipped: 0,
      incomplete: 0,
    };
    dailyChallengeSubmissions.forEach((submission) => {
      if (submission.SubmissionModel !== null) {
        if (
          SubmissionResultTypes[submission.SubmissionModel.SubmissionResult] ===
          'correct'
        ) {
          result.correct += 1;
        } else if (submission.QuestionStatus === 4) {
          result.skipped += 1;
        }
      }
    });
    return result;
  };

  return (
    <Container collapse={collapse} page={page}>
      <ProficiencyProgress
        totalQns={questions.length}
        proficiency={countCorrectWrongSubmissions()}
        collapse={collapse}
        setCollapse={setCollapse}
        showIcon={showIcon}
      />

      <StyledSidebarWrap>
        {questions.map((item, index) => (
          <QnNav
            key={item.Id}
            collapse={collapse}
            number={index + 1}
            totalQns={questions.length}
            status={isCorrect(index)}
            active={index + 1 === activeQuestion}
            clickHandler={() => {
              setExpandQn(true);
              dispatch(setActive(index + 1));
              dispatch(resetSubmitAns());
              dispatch(hideAllTools());
              dispatch(clearAll());
              clearValues();
              dispatch(setClearDraw(true));
              if (setShow) setShow(false); // prop use to close sidebar. Check file components/DailyChallenge/SideNavigation.js line 71;
            }}
            desc={
              currDailyChallenge?.SubjectID === 2 ||
              currDailyChallenge?.SubjectID === 3
                ? t(
                    `dailyChallenge:main.${
                      renderQnDifficultyLevels(index + 1).name
                    }`,
                    renderQnDifficultyLevels(index + 1).name
                  )
                : t(
                    `dailyChallenge:main.${difficultyLevels[ChallengeLevel].qnDifficultyLevels[index]?.name}`,
                    `${difficultyLevels[ChallengeLevel].qnDifficultyLevels[index]?.name}`
                  )
            }
            CP={dailyChallengeSubmissions[index].ChallengePoints}
            subjectID={subjectID}
          />
        ))}
        <QnEnd />
      </StyledSidebarWrap>
    </Container>
  );
};

export default QnSideBar;
