import styled, { css } from 'styled-components';
import { kooQuizTypes, KOOQUIZ_SUBJECT_ID } from 'constants/kooQuiz';
import { assignmentTypeStyles } from './constants';

export const LogoContainer = styled.div`
  background: ${({ assignmentType }) =>
    assignmentTypeStyles[assignmentType].logoBgColour};
  width: 78px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100vw;
  padding: 0 1rem 0 0;
  background: ${({ assignmentType }) =>
    assignmentTypeStyles[assignmentType].bgColour};
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  .hw-title {
    display: flex;
    font-weight: 600;
    font-size: 1.125rem;
    margin-left: ${({ page }) => (page === 'finish' ? 150 : 80)}px;
    @media (max-width: 850px) {
      font-size: 0.8rem;
    }
  }
`;

export const Button = styled.button`
  color: white;
  border: none;
  background: ${({ assignmentType }) =>
    assignmentTypeStyles[assignmentType].buttonBgColour};
  border-radius: ${(props) => (props.hideText === true ? '50%' : '1rem')};
  margin: 0 1rem;
  padding: ${(props) => (props.hideText ? '0.5rem 0.9rem' : '0.25rem 1rem')};
  font-weight: 600;
  font-size: 0.875rem;
  img {
    margin-right: ${(props) => (props.hideText === true ? '0' : '0.5rem')};
  }
  :hover {
    background: ${({ assignmentType }) =>
      assignmentTypeStyles[assignmentType].buttonHoverBgColour};
  }
  @media (max-width: 955px) {
    margin-right: 0.5rem;
  }
`;

export const ActionButton = styled.button`
  background: ${({ isSaved, assignmentType }) =>
    isSaved
      ? 'none'
      : assignmentTypeStyles[assignmentType].actionButtonBgColour};
  border: none;
  color: ${({ isSaved, assignmentType }) =>
    isSaved
      ? 'rgba(112,112,112,0.5)'
      : assignmentTypeStyles[assignmentType].actionButtonTextColour};
  font-size: 0.875rem;
  border-radius: 22px;
  padding: 0 0.7rem;
  :hover {
    cursor: ${({ disabled }) =>
      disabled === true ? 'not-allowed' : 'pointer'};
    background: ${({ isSaved, assignmentType }) =>
      isSaved
        ? null
        : assignmentTypeStyles[assignmentType].actionButtonHoverBgColour};
  }
`;

export const PrintButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-right: 5px;
  background: ${({ assignmentType }) =>
    assignmentTypeStyles[assignmentType].printButtonBgColour};
  :hover {
    background: ${({ assignmentType }) =>
      assignmentTypeStyles[assignmentType].printButtonHoverBgColour};
  }
`;

export const Section = styled.div`
  min-width: 200px;
  display: flex;
  align-items: center;

  height: 100%;
  margin-right: ${(props) => props.page && '1rem'};
  ${({ page, type, showPrint, assignmentType }) => {
    if (page) {
      return css`
        justify-content: flex-end;
      `;
    }
    if (type === 'actions') {
      return css`
        justify-content: space-evenly;
        border-left: ${showPrint &&
        assignmentType !== kooQuizTypes[KOOQUIZ_SUBJECT_ID.KooQuizEnglish] &&
        'solid 1px #82e5ff'};
      `;
    }
    return null;
  }}
  span {
    .text {
      color: #006681;
      @media (max-width: 955px) {
        font-size: 1rem;
      }
      @media (max-width: 850px) {
        font-size: 0.8rem;
      }
    }
    margin-left: 1rem;
    @media (max-width: 955px) {
      margin-left: 0.5rem;
    }
  }
`;
