import styled, { css } from 'styled-components';

const typePage = {
  parentAssign: {
    fontFamily: 'Nunito',
    activeColor: '#202020',
    activeWeight: 'bold',
    nonActiveWeight: 600,
    longLine: '#90C3ED',
    shortline: '2px solid #84BBE8',
  },
  missionPlus: {
    science: {
      fontFamily: 'Linotte',
      activeColor: '#0C7224',
      activeWeight: 'bold',
      nonActiveWeight: 600,
      longLine: '#0B6A21',
      shortline: '2px solid #368949',
      padding: '0.5rem 14px',
    },
    english: {
      fontFamily: 'Linotte',
      activeColor: '#FCA90A',
      activeWeight: 'bold',
      nonActiveWeight: 600,
      longLine: '#FCA90A',
      shortline: '6px solid #FCA90A',
      padding: '0.5rem 10px',
    },
  },
  default: {
    fontFamily: 'Arial',
    activeColor: '#2537a3',
    activeWeight: 'bold',
    nonActiveWeight: 'bold',
    longLine: '#90C3ED',
    shortline: '2px solid #84BBE8',
  },
};

export const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  ${({ page, subject }) => {
    if (typePage[page]) {
      if (typePage[page][subject]) {
        return css`
          font-family: ${typePage[page][subject].fontFamily};
          border-left: solid 1px ${typePage[page][subject].longLine};
        `;
      }
      return css`
        font-family: ${typePage[page].fontFamily};
        border-left: solid 1px ${typePage[page].longLine};
      `;
    }
    return css`
      font-family: ${typePage.default.fontFamily};
      border-left: solid 1px ${typePage.default.longLine};
    `;
  }};
  @media (max-width: 1025px) {
    font-size: 0.8rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    overflow-y: auto;
  }
`;

export const TopicItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  ${({ active, page, subject }) => {
    if (active) {
      if (typePage[page]) {
        if (typePage[page][subject]) {
          return css`
            color: ${typePage[page][subject].activeColor};
            font-weight: ${typePage[page][subject].activeWeight};
            border-left: ${typePage[page][subject].shortline};
            padding: ${typePage[page][subject].padding};
          `;
        }
        return css`
          color: ${typePage[page].activeColor};
          font-weight: ${typePage[page].activeWeight};
          border-left: ${typePage[page].shortline};
        `;
      }
      return css`
        color: ${typePage.default.activeColor};
        font-weight: ${typePage.default.activeWeight};
        border-left: ${typePage.default.shortline};
      `;
    }
    return css`
      color: black;
      font-weight: ${() => {
        if (typePage[page]) {
          if (typePage[page][subject]) {
            return typePage[page][subject].nonActiveWeight;
          }
          return typePage[page].nonActiveWeight;
        }
        return typePage.default.nonActiveWeight;
      }};
    `;
  }}
  @media (max-width: 1025px) {
    padding: 0.2rem 1rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 1rem;
  }
`;

export const TopicRow = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
