import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isNil } from 'ramda';

import {
  changeParentPassword,
  getParentDetails,
  changeChildPassword,
} from 'store/parent/parentSettingsSlice';

import {
  PopupModal,
  ResultModal,
  FormGroup,
  CheckBox,
  BackButton,
} from 'components/Shared';

import { Container, Header } from './ChangePassword.styles';

const ChangePassword = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { account } = match.params;
  const { userID } = useSelector((state) => state.login);
  const { updateParentPassword, updateChildPassword, isLoading, error } =
    useSelector((state) => state.parentSettings);

  useEffect(() => {
    dispatch(getParentDetails(userID));
  }, [dispatch, userID]);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const closeSuccessModal = () => {
    setSuccessModal(false);
    history.goBack();
  };

  const retypePasswordValidation = () => {
    if (newPassword !== retypeNewPassword && retypeNewPassword !== '') {
      return 'The two password you typed do not match';
    }
    return '';
  };

  const newPasswordValidation = () => {
    if (newPassword !== '' && newPassword.length < 6) {
      return 'Minimum 6 characters';
    }
    return '';
  };

  const changePassword = async () => {
    const rawBody = {
      OldPassword: currentPassword,
      NewPassword: newPassword,
      RetypeNewPassword: retypeNewPassword,
      KidID: !isNil(location.state) ? location.state.id : undefined,
    };
    if (account === 'parent') {
      dispatch(changeParentPassword(rawBody));
    }
    if (account === 'child') {
      dispatch(changeChildPassword(rawBody));
    }
  };

  useEffect(() => {
    if (updateParentPassword || updateChildPassword) {
      setCurrentPassword('');
      setNewPassword('');
      setRetypeNewPassword('');
    }
  }, [updateParentPassword, updateChildPassword]);
  return (
    <>
      <PopupModal show={successModal} hide={closeSuccessModal}>
        <ResultModal
          data={updateParentPassword || updateChildPassword}
          btnText="OK"
          desc="Your password has been changed successfully."
          error={error}
          errorBtn="OK"
          onClick={closeSuccessModal}
          loading={isLoading}
        />
      </PopupModal>
      <Header>
        <BackButton
          backText="Back"
          styles={{
            color: '#505050',
            fontWeight: '600',
            position: 'absolute',
            background: 'none',
            left: '5%',
          }}
          onClick={() => {
            history.goBack();
          }}
        />
        <div className="title">Change Password</div>
      </Header>
      <Container>
        <FormGroup
          label="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          placeholder="Please enter your current password"
          type="password"
        />
        <FormGroup
          label="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Minimum 6 characters"
          validationText={newPasswordValidation()}
          type={showPassword ? 'text' : 'password'}
          portal="parent"
        >
          <span className="check-box-container">
            <CheckBox
              color="#6AC8FA"
              id="new-password"
              onChange={(e) => {
                setShowPassword(e.target.checked);
              }}
            />
            <p>Show</p>
          </span>
        </FormGroup>
        <FormGroup
          label="Re-type New Password"
          value={retypeNewPassword}
          onChange={(e) => setRetypeNewPassword(e.target.value)}
          placeholder="Please retype password"
          validationText={retypePasswordValidation()}
          type={showPassword ? 'text' : 'password'}
          portal="parent"
        />
        <div className="btn-section">
          <button
            type="button"
            className="btn white"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn orange"
            onClick={() => {
              setSuccessModal(true);
              changePassword();
            }}
            disabled={
              newPassword.length < 6 ||
              newPassword !== retypeNewPassword ||
              currentPassword === ''
            }
          >
            Update
          </button>
        </div>
      </Container>
    </>
  );
};

export default ChangePassword;
