import React from 'react';
import { useTranslation } from 'react-i18next';
import useCheckCurrentChinese from 'helpers/useCheckCurrentChinese';
import { ReactComponent as FAQ } from './img/icon-faq.svg';
import { ButtonContainer } from './FaqButton.styles';

const FaqButton = ({ isPopup }) => {
  const { t } = useTranslation(['trial']);
  const isChinese = useCheckCurrentChinese();

  return (
    <div>
      <ButtonContainer
        onClick={() =>
          window.open(
            `https://support.koobits.com/hc/${
              isChinese ? 'zh-tw' : 'en-gb'
            }/categories/5247948973337-General-Enquiries`,
            '_blank'
          )
        }
        isPopup={isPopup}
      >
        <FAQ className="faq-icon" />
        <div className="faq-title">
          {t('trial:contact.title', 'Any Questions?')}
        </div>
      </ButtonContainer>
    </div>
  );
};

export default FaqButton;
