import React, { useState } from 'react';
import ProficiencyProgress from 'components/Shared/ProficiencyProgress';
import answersEqualsChecker from 'helpers/validation/answersEqualsChecker';

import QnNav from 'components/Assignment/AssignmentView/QnSideBar/QnNav';
import QnEnd from 'components/Assignment/AssignmentView/QnSideBar/QnEnd';
import {
  setActiveQuestion,
  saveAnswer,
  checkAnswer,
} from 'store/assignment/assignmentSlice';
import { setActiveEventQuestion, saveEventAns } from 'store/event/eventSlice';
import {
  saveKooQuizAnswer,
  setKooQuizActiveQuestion,
} from 'store/kooQuiz/kooQuizSlice';
import {
  saveKooClassAnswer,
  setKooClassActiveQuestion,
} from 'store/kooClass/kooClassSlice';

import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  submissionTypeIDs,
  SubmissionResultTypes,
  questionTypeIDs,
} from 'constants/index';
import { countCorrectWrongSubmissions } from 'helpers/countCorrectWrongSubmissions';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import { setLastSavedTime } from 'store/timer/timerSlice';
import { getWorkings } from 'helpers/getWorkings';
import { clearAll } from 'store/assignment/barModelSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { FILTER_SUBJECT_STATUS } from 'constants/kooQuiz';
import { isNil } from 'ramda';
import { useMediaQuery } from 'react-responsive';
import { Container } from './QnSideBar.styles';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

// HELPER
export const checkIfSubmissionIsCorrect = (submissionModel, assignmentType) => {
  let subResName = 'SubmissionResult';
  if (assignmentType === 'KooClass') {
    subResName = 'submissionResult';
  }
  if (submissionModel === null) {
    return null;
  }
  if (SubmissionResultTypes[submissionModel[subResName]] === 'correct') {
    return true;
  }
  if (SubmissionResultTypes[submissionModel[subResName]] === 'wrong') {
    return false;
  }
  return null;
};

export const checkIsQuestionAnswered = (
  localSavedAnswer,
  submission,
  questionType,
  assignmentType
) => {
  let joinedValues;
  if (localSavedAnswer !== null) {
    if (questionType !== 'mcq') {
      // Check if answer is blank
      joinedValues = Object.values(localSavedAnswer)
        .map((value) => value.trim())
        .join('');
    } else {
      joinedValues = localSavedAnswer.map((value) => value.trim()).join('');
    }
    return joinedValues.length > 0;
  }
  if (submission !== null) {
    let subName = 'SubmissionKeyValuePairs';
    let valName = 'Value';
    let keyName = 'Key';
    if (assignmentType === 'KooClass') {
      subName = 'submissionKeyValuePairs';
      valName = 'value';
      keyName = 'key';
    }
    if (questionType !== 'mcq') {
      // Check if answer is blank
      joinedValues = submission[subName]
        .map((keyValuePair) =>
          isNil(keyValuePair[valName]) ? '' : keyValuePair[valName].trim()
        )
        .join('');
    } else {
      joinedValues = submission[subName]
        .map((keyValuePair) =>
          isNil(keyValuePair[keyName]) ? '' : keyValuePair[keyName].trim()
        )
        .join('');
    }
    return joinedValues.length > 0;
  }
  return false;
};

const QnSideBar = ({
  assignmentType,
  questions,
  activeQuestion,
  localSavedAnswers,
  page,
  scrollToHandler,
  saveAns,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  // Redux states
  const {
    assignmentSubmissionID,
    questionAnswers,
    submissions,
    workings,
    isSaving,
    isChecking,
  } = useSelector((state) => state.assignment);
  const {
    eventSubmissionID,
    eventQsAnswers,
    eventSubmissions,
    eventLocalSavedWorkings,
    isEventSaving,
  } = useSelector((state) => state.event);
  const {
    kooQuizSubmissionID,
    kooQuizQuestionAnswers,
    kooQuizSubmissions,
    kooQuizSavedWorkings,
    isKooQuizSaving,
    isKooQuizChecking,
  } = useSelector((state) => state.kooQuiz);
  const {
    kooClassSubmissionID,
    kooClassQuestionAnswers,
    kooClassSubmissions,
    kooClassWorkings,
    isKooClassSaving,
    isKooClassChecking,
  } = useSelector((state) => state.kooClass);
  let submissionID;
  let qsAnswers;
  let allSubmissions;
  let localSavWorkings;
  let setActive = null;
  let saving;
  let checking;
  if (assignmentType === 'sundayMC') {
    submissionID = eventSubmissionID;
    qsAnswers = eventQsAnswers;
    allSubmissions = eventSubmissions;
    localSavWorkings = eventLocalSavedWorkings;
    setActive = setActiveEventQuestion;
    saving = isEventSaving;
  } else if (FILTER_SUBJECT_STATUS[assignmentType]) {
    submissionID = kooQuizSubmissionID;
    qsAnswers = kooQuizQuestionAnswers;
    allSubmissions = kooQuizSubmissions;
    localSavWorkings = kooQuizSavedWorkings;
    setActive = setKooQuizActiveQuestion;
    saving = isKooQuizSaving;
    checking = isKooQuizChecking.includes(true);
  } else if (assignmentType === 'KooClass') {
    submissionID = kooClassSubmissionID;
    qsAnswers = kooClassQuestionAnswers;
    allSubmissions = kooClassSubmissions;
    localSavWorkings = kooClassWorkings;
    setActive = setKooClassActiveQuestion;
    saving = isKooClassSaving;
    checking = isKooClassChecking.includes(true);
  } else {
    submissionID = assignmentSubmissionID;
    qsAnswers = questionAnswers;
    allSubmissions = submissions;
    localSavWorkings = workings;
    setActive = setActiveQuestion;
    saving = isSaving;
    checking = isChecking.includes(true);
  }
  let subResName = 'SubmissionResult';
  let qsName = 'QuestionType';
  if (assignmentType === 'KooClass') {
    subResName = 'submissionResult';
    qsName = 'questionType';
  }
  const { userID } = useSelector((state) => state.login);
  const { data: barModelData } = useSelector((state) => state.barModel);
  const submissionType =
    assignmentType === 'sundayMC'
      ? submissionTypeIDs.MiniChallenge
      : submissionTypeIDs.Homework;
  // state for collapse sidebar
  const isCollapseBreakpoint = useMediaQuery({ query: '(max-width: 1200px)' });
  const [collapseOption, setCollapse] = useState(isCollapseBreakpoint);
  const collapse = isCollapseBreakpoint || collapseOption;

  // EVENT HANDLERS
  const dispatchSaveAction = (params) => {
    let saveAnsAction = null;
    const saveParams = { ...params };
    if (assignmentType === 'sundayMC') saveAnsAction = saveEventAns;
    else if (FILTER_SUBJECT_STATUS[assignmentType]) {
      saveAnsAction = saveKooQuizAnswer;
      if (answersEqualsChecker(saveParams, allSubmissions)) {
        return;
      }
    } else if (assignmentType === 'KooClass') {
      saveAnsAction = saveKooClassAnswer;
    } else {
      saveAnsAction = saveAnswer;
      saveParams.fetchNextQn = true;
      if (answersEqualsChecker(saveParams, allSubmissions)) {
        return;
      }
    }
    dispatch(saveAnsAction(saveParams));
  };
  const dispatchCheckAnswerAction = (params) => {
    const checkAnswerParams = {
      ...params,
      fetchNextQn: true,
    };
    const isAssignment = ['homework', 'mocktest', 'test'].includes(
      assignmentType
    );
    if (!isAssignment) return;
    dispatch(checkAnswer(checkAnswerParams));
  };

  const isCorrect = (index, item) => {
    if (page !== 'solution') {
      if (
        assignmentType === 'homework' ||
        FILTER_SUBJECT_STATUS[assignmentType] ||
        assignmentType === 'KooClass'
      ) {
        return checkIfSubmissionIsCorrect(
          allSubmissions[index].SubmissionModel,
          assignmentType
        );
      }
      return null;
    }
    if (item.SubmissionModel === null) return false;
    return (
      SubmissionResultTypes[item.SubmissionModel[subResName]] === 'correct'
    );
  };
  return (
    <Container collapse={collapse} page={page}>
      {(assignmentType === 'homework' ||
        FILTER_SUBJECT_STATUS[assignmentType] ||
        assignmentType === 'KooClass') &&
        page !== 'solution' && (
          <ProficiencyProgress
            totalQns={questions.length}
            proficiency={countCorrectWrongSubmissions(
              allSubmissions,
              page,
              assignmentType
            )}
            collapse={collapse}
            setCollapse={setCollapse}
          />
        )}
      {page === 'solution' && (
        <ProficiencyProgress
          totalQns={questions.length}
          proficiency={countCorrectWrongSubmissions(questions, page)}
          collapse={collapse}
          setCollapse={setCollapse}
        />
      )}
      <div style={{ overflowY: 'auto' }}>
        {questions.map((item, index) => {
          return (
            <QnNav
              key={item.Id}
              collapse={collapse}
              number={index + 1}
              correct={isCorrect(index, item)}
              qnAnswered={
                page === 'solution'
                  ? true
                  : checkIsQuestionAnswered(
                      localSavedAnswers[index],
                      allSubmissions[index].SubmissionModel,
                      questionTypeIDs[item[qsName]],
                      assignmentType
                    )
              }
              active={index + 1 === activeQuestion}
              disabled={saving || checking}
              clickHandler={() => {
                if (saving || checking) {
                  return;
                }
                dispatch(setActive(index + 1));
                dispatch(setClearDraw(true));
                if (assignmentType === 'KooClass' && page !== 'solution') {
                  saveAns();
                  return;
                }
                if (page === 'solution') {
                  scrollToHandler(item.Question.Id);
                } else {
                  const now = Date.now();
                  newSaveAnswerHandler({
                    assignmentSubmissionID: submissionID,
                    assignmentType: FILTER_SUBJECT_STATUS[assignmentType]
                      ? 'homework'
                      : assignmentType,
                    questionType:
                      questionTypeIDs[
                        questions[activeQuestion - 1].QuestionType
                      ],
                    submissionType,
                    questionAnswer: qsAnswers[activeQuestion - 1],
                    saveAnswerAction: dispatchSaveAction,
                    checkAnswerAction: dispatchCheckAnswerAction,
                    activeQnNo: activeQuestion,
                    currentQn: questions[activeQuestion - 1],
                    currentLocalSavedAnswer:
                      localSavedAnswers[activeQuestion - 1],
                    currentSubmission:
                      allSubmissions[activeQuestion - 1].SubmissionModel,
                    timeElapsed: now - store.getState().timer.lastSavedTime,
                    userID,
                    workings: getWorkings(
                      allSubmissions[activeQuestion - 1],
                      localSavWorkings[activeQuestion - 1]
                    ),
                    barModel:
                      barModelData.objects.length > 0 ? barModelData : null,
                  });
                  dispatch(setLastSavedTime(now));
                  dispatch(hideAllTools());
                  clearValues();
                  dispatch(clearAll());
                }
              }}
            />
          );
        })}
        <QnEnd />
      </div>
    </Container>
  );
};

export default QnSideBar;
