import styled from 'styled-components';

export const StyledOverlayContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
