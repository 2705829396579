import 'assets/icons/icon.css';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  ButtonHeader,
  LeftNav,
  RightNav,
  TopBarFixed,
} from './FactualFluencyHeader.style';
import FactualFluencyTimer from '../FactualFluencyTimer';

const FactualFluencyHeader = ({
  timeType,
  time,
  intervalActive,
  limit,
  onMute,
  mute,
}) => {
  const history = useHistory();

  const onClose = useCallback(() => {
    history.push('/assignment');
  }, [history]);

  return (
    <TopBarFixed>
      <LeftNav>
        <FactualFluencyTimer
          timeType={timeType}
          time={time}
          intervalActive={intervalActive}
          limit={limit}
        />
      </LeftNav>
      <RightNav>
        <ButtonHeader
          className={`btn ${!mute ? 'active' : ''}`}
          onClick={onMute}
        >
          <i className="icon-volume" />
        </ButtonHeader>
        <ButtonHeader className="btn" onClick={onClose}>
          <i className="icon-close2" />
        </ButtonHeader>
      </RightNav>
    </TopBarFixed>
  );
};

export default React.memo(FactualFluencyHeader);
