import styled from 'styled-components';

export const Arrow = styled.button`
  all: unset;

  :focus {
    outline: none;
  }

  :disabled {
    opacity: 0.5;
  }
`;
