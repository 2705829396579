import styled from 'styled-components';
import { Button } from 'components/Shared';

export const Container = styled.div`
  padding: 0rem 0.5rem;
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
  border-radius: 10px;
  padding: 1rem 0rem 0rem 2rem;
  margin: 1rem 15rem;
  font-size: 16px;
  @media (max-width: 1366px) {
    margin: 1rem 10rem;
  }
  @media (max-width: 1024px) {
    margin: 1rem 6rem;
  }
  @media (max-width: 925px) {
    margin: 1rem 3rem;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    font-size: 14px;
    padding: 2rem 0.5rem;
    margin: 1rem;
  }
`;

export const KoKoCredits = styled.div`
  display: flex;
  p {
    margin-bottom: 0rem;
    align-self: center;
    margin-left: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ColumnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const StyledButton = styled(Button)`
  height: 3rem;
  font-size: 24px;
  font-weight: 700;
  min-width: 450px;

  @media (max-width: 768px) {
    min-width: 350px;
  }

  @media (max-width: 450px) {
    min-width: 300px;
  }
`;
