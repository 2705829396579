import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'ramda';
import { getIncomingPeerChallengeHistory } from 'services/multiplayerv2';
import { fetchWrapper } from 'services/login';
import { getUserInfos } from 'store/lookup/lookupSlice';

import useDashboard from 'hooks/useDashboard';

import useQuery from 'helpers/useQuery';

import { ChallengeBy } from 'constants/index';
import { multiplayerListStyles } from 'constants/multiplayer';
import { subjectNames } from 'constants/products';

import { DropdownMenu, BackButton, Pagination } from 'components/Shared';
import EmptyChallenge from 'assets/img/multiplayer/empty-challenge.svg';
import { ChallengesTable } from './components';

import { EmptyChallengeContainer } from './components/ChallengesTable/ChallengesTable.styles';
import {
  Topbar,
  Section,
  Content,
  StyledPagination,
} from './ChallengeHistory.styles';

const perPage = 5;
const ChallengeHistory = () => {
  const { t } = useTranslation(['peerChallengeHistory']);
  const dispatch = useDispatch();
  const query = useQuery();
  const subjectParam = query.get('subject');
  const lowercaseSubjectParam = isNil(subjectParam)
    ? 'math'
    : subjectParam.toLowerCase().trim();
  const subjectID = subjectNames[lowercaseSubjectParam];

  const tableHeaders = [
    t('peerChallengeHistory:table.attemptDate', 'Attempted Date'),
    t('peerChallengeHistory:table.myResult', 'My Result'),
    '',
    t('peerChallengeHistory:table.challenger', 'Challenger'),
    '',
  ];
  const datePeriods = [
    {
      ID: 1,
      name: t(
        'peerChallengeHistory:topbar.pastDays',
        { number: 7 },
        'Past 7 days'
      ),
      days: 7,
    },
    {
      ID: 2,
      name: t(
        'peerChallengeHistory:topbar.pastDays',
        { number: 14 },
        'Past 14 days'
      ),
      days: 14,
    },
    {
      ID: 3,
      name: t(
        'peerChallengeHistory:topbar.pastDays',
        { number: 30 },
        'Past 30 days'
      ),
      days: 30,
    },
    {
      ID: 4,
      name: t(
        'peerChallengeHistory:topbar.pastDays',
        { number: 90 },
        'Past 90 days'
      ),
      days: 90,
    },
  ];
  const history = useHistory();
  const [getPeerChallengeHistory, setGetPeerChallengeHistory] = useState({
    List: [],
    HasPreviousPage: false,
    HasNextPage: false,
    TotalCount: 0,
    PageIndex: 0,
    PageSize: 0,
    TotalPages: 0,
  });
  const { TotalPages: maxPage } = getPeerChallengeHistory;
  const { userID } = useSelector((state) => state.login);
  const { isDashboardv2 } = useDashboard();
  const challengeType = ChallengeBy['Challenges from me'];
  const [selectedDatePeriod, setSelectedDatePeriod] = useState(datePeriods[0]);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(true);

  const fetchHistory = useCallback(
    async (pageIndex, period) => {
      if (isNil(userID)) return;
      try {
        setIsLoading(true);
        const data = await fetchWrapper(getIncomingPeerChallengeHistory, {
          userID,
          subjectID,
          challengeType: 2,
          pageIndex: pageIndex - 1,
          pageSize: perPage,
          period,
        });
        if (isEmpty(data)) {
          setIsLoading(false);
          setNoDataFound(true);
          return;
        }
        const userIds = [userID];
        data.List.forEach((challenge) => {
          const opponent = challenge.Challengers.find(
            (challenger) => challenger.UserId !== userID
          );
          if (!opponent) return;
          userIds.push(opponent.UserId);
        });
        const uniqueOpponentIds = [...new Set(userIds)];
        // // Fetch user info
        const usersInfoAction = await dispatch(
          getUserInfos({
            userIDs: uniqueOpponentIds,
            pageIndex: 0,
            pageSize: uniqueOpponentIds.length,
            cache: true,
          })
        );
        unwrapResult(usersInfoAction);
        setGetPeerChallengeHistory(data);
        setIsLoading(false);
        setNoDataFound(false);
      } catch (err) {
        setIsLoading(false);
        setNoDataFound(true);
        throw new Error(err.message);
      }
    },
    [userID, subjectID, dispatch]
  );

  useEffect(() => {
    fetchHistory(page, selectedDatePeriod.days);
  }, [fetchHistory, selectedDatePeriod.days, page]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      const intPageNumber = parseInt(searchPage, 10);
      if (!intPageNumber || intPageNumber <= 0) return;
      setPage(intPageNumber);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [searchPage]);

  const onPageChange = (number) => {
    setPage(number);
    setSearchPage(number);
  };

  const onSearchPageChange = (pageNumber, currentMaxPage) => {
    if (pageNumber <= currentMaxPage) {
      setSearchPage(pageNumber);
    }
  };

  const onChangeDate = (val) => {
    setSelectedDatePeriod(val);
    setPage(1);
    setSearchPage(1);
  };

  const reloadHandler = () => {
    fetchHistory(page, selectedDatePeriod.days);
  };

  if (isNil(subjectParam))
    return (
      <Redirect
        to={isDashboardv2 ? '/dashboard?view=koochallenge' : '/multiplayer'}
      />
    );
  if (isNil(subjectNames[lowercaseSubjectParam]))
    return (
      <Redirect
        to={isDashboardv2 ? '/dashboard?view=koochallenge' : '/multiplayer'}
      />
    );
  return (
    <>
      <Topbar background={multiplayerListStyles[lowercaseSubjectParam].topBar}>
        <BackButton
          btnBgColor={
            multiplayerListStyles[lowercaseSubjectParam].backBtnBgColour
          }
          backText={t('peerChallengeHistory:header.back', 'Back')}
          onClick={() => history.goBack()}
          isResponsive
          padding="0.3rem 1.1rem"
        />
        <div className="main-title">
          <img
            src={multiplayerListStyles[lowercaseSubjectParam].titleIcon}
            alt="peer-challenge-icon"
          />
          <span className="peer-challenge-title">
            {t('peerChallengeHistory:header.title', 'Peer Challenge History')}
          </span>
        </div>
      </Topbar>
      <Content>
        <Section>
          <div className="selector">
            <span>
              {t('peerChallengeHistory:topbar.selectPeriod', 'Select Period')}
            </span>
            <DropdownMenu
              values={datePeriods}
              valueKey="name"
              selectedValue={selectedDatePeriod.name}
              setValue={onChangeDate}
              width="auto"
            />
          </div>
          <span>
            <img
              src={multiplayerListStyles[lowercaseSubjectParam].rulesIcon}
              alt="Rules"
              style={{ marginRight: '0.25rem' }}
            />
            <a
              href="https://support.koobits.com/hc/en-gb/articles/211244503-How-many-points-can-I-earn-in-Peer-Challenge-"
              target="_blank"
              rel="noopener noreferrer"
              className="rule"
            >
              {t('peerChallengeHistory:topbar.scoringRule', 'Rules')}
            </a>
          </span>
        </Section>
        <ChallengesTable
          headers={tableHeaders}
          data={getPeerChallengeHistory && getPeerChallengeHistory.List}
          challengeType={challengeType}
          noDataFound={noDataFound}
          loading={isLoading}
          reload={reloadHandler}
          subjectName={lowercaseSubjectParam}
        />
        {!isLoading && noDataFound && (
          <EmptyChallengeContainer data-cy="empty-data">
            <div>
              <h1>{t('table.nodata', 'Data not found for this period')}</h1>
              <p>{t('table.chooseAnother', 'Please choose another period')}</p>
            </div>
            <img
              style={{ marginTop: '1rem', marginLeft: '-2rem' }}
              src={EmptyChallenge}
              alt="No challenges"
              width="200.86px"
              height="140.5px"
            />
          </EmptyChallengeContainer>
        )}
        {!noDataFound && maxPage > 0 && (
          <StyledPagination>
            <Pagination
              maxPage={maxPage}
              onPageChange={onPageChange}
              onSearchPageChange={onSearchPageChange}
              page={page}
              searchPage={searchPage}
            />
          </StyledPagination>
        )}
      </Content>
    </>
  );
};

export default ChallengeHistory;
