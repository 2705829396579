import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';

import {
  getActivitiesByLessonId,
  resetEndActivity,
} from 'store/kooClass/kooClassSlice';
import useQuery from 'helpers/useQuery';
import { Button } from 'components/Shared';
import CheckIcon from 'assets/img/assignment/assignment-view/icon-check.svg';
import Plane from 'assets/img/assignment/assignment-view/paper-plane.svg';
import { ReactComponent as Scroll } from 'assets/img/icon-scroll.svg';
import { Container } from './SubmittedResult.styles';

const SubmittedResult = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['activities']);
  const { topicId, lessonId, activityId } = useParams();
  const query = useQuery();
  const courseIdParam = query.get('courseId');

  const { activities, kooClassSubmissions, endActivity } = useSelector(
    (state) => state.kooClass
  );

  // get total number of correct qn and total qn
  const totalCorrect =
    kooClassSubmissions.length > 0
      ? kooClassSubmissions.filter(
          (item) =>
            !isNil(item.SubmissionModel) &&
            item.SubmissionModel.submissionResult === 1
        ).length
      : 0;

  const totalQuestions = kooClassSubmissions ? kooClassSubmissions.length : 0;

  const score = kooClassSubmissions
    ? Math.floor((totalCorrect / totalQuestions) * 100)
    : 0;

  // get scroll
  const scroll = endActivity && endActivity.currentUserPoints;

  // onNextActivity onClick func
  const checkCurrentIndex =
    activities && activities.findIndex((el) => el.id === activityId);
  const nextItem =
    checkCurrentIndex + 1 < activities.length
      ? activities.find((el, index) => index === checkCurrentIndex + 1)
      : null;

  const onNextActivity = () => {
    history.push(
      `/kooClass/${topicId}/lesson/${lessonId}/activity?activityType=${nextItem.activityType}&activityId=${nextItem.id}&name=${nextItem.name}&courseId=${courseIdParam}`
    );
  };

  useEffect(() => {
    if (isEmpty(activities)) {
      dispatch(getActivitiesByLessonId(lessonId));
    }
    // eslint-disable-next-line
  }, [dispatch, lessonId]);

  useEffect(() => {
    return () => {
      dispatch(resetEndActivity());
    };
  }, [dispatch]);

  return (
    <Container>
      <div className="content-container">
        <div className="box">
          <img className="plane" src={Plane} alt="" />
          <div className="icon">
            <img src={CheckIcon} alt="" />
          </div>
          <div className="text-box">
            <p className="success-text">
              {t(
                'activities:doAndLearn.submitPage.text',
                'This activity has been submitted successfully!'
              )}
            </p>
            <div className="score">
              <p className="percentage">
                {score} <span>%</span>
              </p>
              <div className="score-detail">
                (<span> {totalCorrect} </span>
                <p>
                  {t('activities:doAndLearn.submitPage.outOf', ' out of ')}{' '}
                  <strong>{totalQuestions} </strong>
                </p>
                )
              </div>
            </div>
            <div className="scroll-reward">
              <Trans
                i18nKey="activities:doAndLearn.submitPage.youGot"
                scroll={scroll}
              >
                You got <span>{{ scroll }} Scroll</span> <Scroll />
              </Trans>
            </div>
            <div className="btn-section">
              {score !== 0 && (
                <button
                  className="solution-btn"
                  onClick={() => {
                    history.push(
                      `/kooClass/${topicId}/solution/${lessonId}/${activityId}?courseId=${courseIdParam}`
                    );
                  }}
                >
                  {t(
                    'activities:doAndLearn.submitPage.seeSolution',
                    'See Solution'
                  )}
                </button>
              )}
              <Button variant="primary" onClick={onNextActivity}>
                {t('activities:doAndLearn.submitPage.nextAct', 'Next Activity')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SubmittedResult;
