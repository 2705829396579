import axios from 'axios';

const BASE_URL = process.env.REACT_APP_USER_SERVICE_URL;

export const fetchMockTime = () => {
  return axios
    .get(`${BASE_URL}MockTime`)
    .then((res) => res)
    .catch((err) => err.response);
};
