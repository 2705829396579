import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import Header from 'components/Header';
import { BackButton, Button, TrialSelector } from 'components/Shared';
import { ReactComponent as IconLock } from 'assets/img/expired/locked-ui.svg';
import { OTPModal } from '../components';

import { ROUTES } from './constants';
import { PRODUCT_TYPE } from 'constants/products';
import { SUBJECTS } from '../constants';

import { StyledButtonContainer, StyledTrialPopupModal } from '../index.styles';
import {
  StyledPage,
  StyledTitle,
  StyledDashboard,
} from './BundleOTPTrial.styles';

const BundleOTPTrial = () => {
  const { t } = useTranslation(['bundleTrial', 'common']);
  const { type } = useParams();
  const history = useHistory();
  const {
    isLoading: planIsLoading,
    countryEligibility,
    allNonExpiredSubscriptionProducts,
  } = useSelector((state) => state.plan);
  const { OTPEligibility } = useSelector((state) => state.trialSignup);

  const { isLoading, studentDetails } = useSelector(
    (state) => state.studentDetails
  );

  const [showModal, setShowModal] = useState(false);
  const allLoading = isLoading || planIsLoading;
  const isExpired = type === 'expired';

  const handleClickBack = () => {
    history.push('/dashboard');
  };
  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (
      allNonExpiredSubscriptionProducts &&
      countryEligibility.data &&
      !OTPEligibility.isLoading
    ) {
      const findNonExpiredTrial = allNonExpiredSubscriptionProducts.find(
        (p) => p.isTrial
      );
      if (findNonExpiredTrial) {
        history.push(`/trial/block`);
      }
      const countryEligibilityToObj =
        countryEligibility?.data?.eligibleSubjects?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.subjectId]: false,
          };
        }, {});
      allNonExpiredSubscriptionProducts.forEach((p) => {
        if (
          p.subjectId in countryEligibilityToObj &&
          p.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM
        ) {
          countryEligibilityToObj[p.subjectId] = true;
        }
      });
      // check all subject has premium
      if (
        Object.values(countryEligibilityToObj).every((subjectExistPremium) =>
          Boolean(subjectExistPremium)
        )
      ) {
        history.push(`/dashboard`);
      }
      if (OTPEligibility.isEligible && isExpired) {
        history.push(`/trial/bundle`);
      } else if (!OTPEligibility.isEligible && !isExpired) {
        history.push(`/expired/bundle`);
      }
    }
  }, [
    allNonExpiredSubscriptionProducts,
    countryEligibility,
    OTPEligibility,
    isExpired,
    history,
  ]);

  if (ROUTES[type] && !planIsLoading && allNonExpiredSubscriptionProducts) {
    return (
      <>
        {/* need a dom to let popup modal have reference */}
        <div>
          {isExpired ? (
            <StyledTrialPopupModal
              show={showModal}
              onHide={closeModal}
              centered
              backdrop="static"
              size="xl"
            >
              <TrialSelector
                onClose={closeModal}
                userId={studentDetails?.UserId}
                campaignCode="ExpirePage"
              />
            </StyledTrialPopupModal>
          ) : (
            <OTPModal showModal={showModal} setShowModal={setShowModal} />
          )}
        </div>
        {!allLoading && (
          <StyledPage>
            <Header
              toggleMenu={false}
              toggleProfile={false}
              toggleAccessMenu={false}
            />
            <StyledButtonContainer>
              <BackButton
                btnBgColor="rgba(254, 113, 32, 0.6)"
                padding="0.4rem 1rem"
                backText={t('common:back', 'Back')}
                onClick={handleClickBack}
              />
            </StyledButtonContainer>
            <StyledTitle>
              <h3 className="title">
                {t(
                  'bundleTrial:title',
                  'Get Unlimited Access to All Subjects. 7-days FREE!'
                )}
              </h3>
              <p className="desc">
                {t(
                  'bundleTrial:description',
                  'More fun challenges, missions and skills to unlock. No credit card needed.'
                )}
              </p>
            </StyledTitle>
            <StyledDashboard>
              {countryEligibility.data?.eligibleSubjects
                ?.slice()
                .sort(
                  (a, b) =>
                    SUBJECTS[a.subjectId].order - SUBJECTS[b.subjectId].order
                )
                .map(({ subject, subjectId }, i) => (
                  <StyledDashboard.Item
                    subject={subject}
                    key={subjectId}
                    isExpired={isExpired}
                  >
                    <img src={SUBJECTS[subjectId].src} alt={subject} />
                    <p>
                      {t(`common:subjects.${subject}`, subject)}{' '}
                      {t('common:plans.premium', 'Premium')}
                    </p>
                    {i === 1 && isExpired && (
                      <div className="lock">
                        <IconLock />
                        {t(
                          'bundleTrial:expired.access',
                          'Your access has expired.'
                        )}
                      </div>
                    )}
                  </StyledDashboard.Item>
                ))}
            </StyledDashboard>
            <Button
              variant="primary"
              margin="66px 0 0 0"
              onClick={() => setShowModal(true)}
              fontWeight="700"
              fontSize="24px"
              padding={isExpired ? '0.8rem 4.5rem' : '0.8rem 2rem'}
            >
              {isExpired
                ? t('bundleTrial:buy', 'Buy Now')
                : t('bundleTrial:tryButton', 'Try All Subjects Now')}
            </Button>
          </StyledPage>
        )}
      </>
    );
  }
  return <div />;
};

export default BundleOTPTrial;
