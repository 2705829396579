import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { Container } from './CurrentDate.styles';

const CurrentDate = () => {
  const { timezone } = useSelector((state) => state.login);
  const { subject } = useParams();

  const day = moment().tz(timezone).format('DD');
  const month = moment().tz(timezone).format('MMM');
  const year = moment().tz(timezone).format('YYYY');

  const today = [day, month, year];

  return (
    <Container>
      {today.map((item, i) => (
        <div
          className={subject === 'math' ? 'date' : 'sc-date'}
          key={`date-${i}`}
        >
          {item}
        </div>
      ))}
    </Container>
  );
};

export default CurrentDate;
