import styled from 'styled-components';
import Bg from 'assets/img/header-numbers-bg.svg';

export const Container = styled.div`
  display: flex;
  background: ${(props) => props.bgColor};
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 4;
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    height: 68px;
  }
`;

export const KoobitsLogo = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${(props) => props.bgColor};
  padding: 0.8rem;
  display: flex;
  cursor: pointer;
  z-index: 10;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    position: initial;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  background: url(${({ headerBg, hasBgImg }) =>
    hasBgImg ? headerBg ?? Bg : 'none'});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: relative;
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100vw;
  }
`;

export const BackButton = styled.button`
  background-color: ${(props) => props.bgColor};
  color: #ffffff;
  border-radius: 2rem;
  border: none;
  font-weight: 600;
  position: absolute;
  left: 60px;
  transform: scale(1);
  padding: 0.25rem 1.2rem;
  display: flex;
  align-items: center;
  gap: 7px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 40px;
    transform: scale(0.7);
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    left: 92px;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    left: 0.75rem;
  }
`;
