import styled from 'styled-components';

export const StyledTopUpAmountTooLow = styled.div`
  font-family: 'Muli';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1185px) {
    width: 100%;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    padding-left: 13px;
    width: 100%;
  }
  .desc {
    font-size: 12px;
    padding-left: 13px;
    @media (max-width: 1185px) {
      width: 100%;
    }
  }
  .email {
    background: #fffaef;
    border: 1px solid #fdeac3;
    border-radius: 7px;
    padding: 10px;
    font-size: 12px;
    @media (max-width: 1185px) {
      width: 100%;
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: #ff5c02;
    }
    ol {
      padding-left: 14px;
      margin-top: 2px;
      li {
        font-weight: 700;
        margin-bottom: 2px;
      }
    }
  }
`;
