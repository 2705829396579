import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import { kooFunIcons } from 'constants/dashboardv2';

import { StyledContainer } from './StoryCard.styles';

const StoryImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(
    kooFunIcons[subject].story,
    kooFunIcons[subject].storyTablet
  );
  return <img src={imgSrc} alt={subject} />;
};

const StoryCard = () => {
  const { subject } = useSelector((state) => state.plan);
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();

  const navigateToStory = () => {
    history.push(`/story/${subject}`);
  };

  const NAME = {
    math: t('dashboardCards:story.math', 'StoryMath'),
    science: t('dashboardCards:story.science', 'StoryScience'),
    english: t('dashboardCards:story.english', 'StoryEnglish'),
  };
  return (
    <StyledContainer
      onClick={navigateToStory}
      data-cy="story-card"
      subject={subject}
    >
      <div>
        <StoryImage subject={subject} />
        <h3>{NAME[subject]}</h3>
      </div>
    </StyledContainer>
  );
};

export default StoryCard;
