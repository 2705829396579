import { isNil } from 'ramda';
import { superHeroChallengeStatusIDs } from 'constants/dailyChallenge';
import { submissionResultIDs } from 'constants/index';
import { compareValues } from 'helpers/compareValue';

export const checkErrMsgIsClosed = (
  getStatusErr,
  getCurrentDailyChallengeErr
) => {
  const closedErrMsgs = [
    'Please come back between 06:00 AM and 10:00 PM',
    'Current Request is restricted in the following days Sunday',
  ];
  const isGetStatusErrClosed = closedErrMsgs.find(
    (err) => err === getStatusErr
  );
  const isGetCurrentDailyChallengeErrClosed = closedErrMsgs.find(
    (err) => err === getCurrentDailyChallengeErr
  );
  if (
    !isNil(isGetStatusErrClosed) ||
    !isNil(isGetCurrentDailyChallengeErrClosed)
  ) {
    return true;
  }
  return false;
};

export const parseSuperHeroChallengeStatus = (
  challengeID,
  superHeroChallengeStatusData
) => {
  if (challengeID === 2) {
    if (superHeroChallengeStatusData.Supervision.IsClosed) {
      return 'closed';
    }
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Supervision.Status
    ];
  }
  if (challengeID === 3) {
    if (superHeroChallengeStatusData.Superspeed.IsClosed) {
      return 'closed';
    }
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Superspeed.Status
    ];
  }
  return null;
};
export const sortDailyChallenges = (challenges) => {
  if (isNil(challenges)) return [];
  return challenges
    .map((dc) => {
      return {
        ...dc,
        displayOrder: dc.subjectID ?? 99,
      };
    })
    .sort(compareValues('displayOrder', 'asc'));
};

export const challengeStatus = (
  dailyChallengeStatus,
  dailyChallengeDetails
) => {
  const stillOpen = dailyChallengeStatus && !dailyChallengeStatus.IsClosed;
  const checkSubmissionModel =
    dailyChallengeDetails.UserQuestionSubmissions &&
    dailyChallengeDetails.UserQuestionSubmissions.filter(
      (item) => item.QuestionStatus !== 4
    )
      .map((item) => item.SubmissionModel)
      .findIndex(
        (data) =>
          data === null ||
          submissionResultIDs[data?.SubmissionResult] === 'wrong' ||
          submissionResultIDs[data?.SubmissionResult] === 'unknown'
      );

  const challengeComplete = () => {
    if (checkSubmissionModel === null || isNil(checkSubmissionModel)) {
      return false;
    }
    if (checkSubmissionModel !== null) {
      if (checkSubmissionModel === -1) {
        return true;
      }
    }
    return false;
  };

  if (!stillOpen) {
    return 'close';
  }
  if (stillOpen && challengeComplete()) {
    return 'completed';
  }
  if (stillOpen) {
    return 'open';
  }
};
