import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import moment from 'moment';
import useSound from 'use-sound';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';

import {
  setShowHoDModal,
  setShowClaimKokoModal,
  resetErr,
  resetSubmitAns,
  submitDailyChallengeAnswer,
  resetSubmitDailyChallengeAnswerIsErr,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { populateBarModel } from 'store/assignment/barModelSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

import { fetchWrapper } from 'services/login';
import { postMarkBadgeAsSeen } from 'services/hodCalendar';

import parseWorkingsBarModel from 'helpers/parseWorkingsBarModel';
import {
  submissionTypeIDs,
  questionTypeIDs,
  featureToggle,
} from 'constants/index';
import { createAnswerKeyValuePairsByQuestionType } from 'helpers/createAnswerKeyValuePairs';
import { getWorkings } from 'helpers/getWorkings';

import dailyChallengeSoundSprite from 'assets/audio/sprite.mp3';
import { soundSpriteMap } from 'constants/dailyChallenge';
import useDCMysteryKoko from 'hooks/useDCMysteryKoko';
import { sendEventTracking } from 'helpers/UserEventTracking';

import { HeroOfTheDayModal } from '../components';
import { Canvas, PopupModal, Notebook, QnViewHeader } from 'components/Shared';
import {
  DifficultyIndicator,
  QnSideBar,
  ActionsRestrictModal,
  ClaimKokoModal,
  DailyChallengeNotebookBottom,
  DailyChallengeNotebookHeader,
  DailyChallengeNotebookMiddle,
  LevelUpModal,
  MysteryKoKoModal,
  SideNavigation,
} from './components';
import {
  Container,
  Main,
  HideOnTabletBelow,
} from './DailyChallengeQnView.styles';

const DailyChallengeQnView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const [play, { stop }] = useSound(dailyChallengeSoundSprite, {
    volume: 0,
    playbackRate: 4,
    interrupt: true,
    sprite: soundSpriteMap,
  });
  const { userID } = useSelector((state) => state.login);
  const {
    currDailyChallenge,
    currDailyChallengeLoading,
    activeQsDailyChallenge,
    dailyChallengeQuestion,
    dailyChallengeSubmissions,
    submitAnsDailyChallenge,
    showHoDModal,
    showClaimKokoModal,
    isChangingQnThreeTimes,
    isErr,
  } = useSelector((state) => state.dailyChallenge);
  const {
    dailyChallengeLocalSavedAns,
    dailyChallengeLocalSavedWorkings,
    isLoading,
  } = useSelector((state) => state.dailyChallenge);
  const { data: barModelData } = useSelector((state) => state.barModel);
  const { ChallengeLevel } = currDailyChallenge;
  const { t } = useTranslation(['dailyChallengeQnView']);
  // COMPONENT STATES
  const [correctAnswer, setCorrectAnswer] = useState('unknown');
  const [showSolution, setShowSolution] = useState(false);
  const [expandQn, setExpandQn] = useState(true);
  const [showActionRestrictModal, setShowActionRestrictModal] = useState(false);
  const [isSkipAnswer, setIsSKipAnswer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const mysteryKoko = useDCMysteryKoko({
    subjectID: currDailyChallenge?.SubjectID,
    challengeLevel: currDailyChallenge?.ChallengeLevel,
  });

  useEffect(() => {
    // EVENT TRACKING: onpage load
    sendEventTracking('daily_challenge', 'question_view', {
      sub: currDailyChallenge?.SubjectID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // USEEFFECTS
  // Preload sound
  useEffect(() => {
    play({ id: 'silencegap' });
    return () => {
      stop();
    };
  }, [play, stop]);
  // Populate bar model data based on current active question
  useEffect(() => {
    const submission = dailyChallengeSubmissions[activeQsDailyChallenge - 1];
    if (
      submission?.SubmissionModel !== null &&
      parseWorkingsBarModel(submission?.SubmissionModel.Solution).barModel !==
        null
    ) {
      dispatch(
        populateBarModel(
          parseWorkingsBarModel(submission?.SubmissionModel.Solution).barModel
        )
      );
    }
  }, [activeQsDailyChallenge, dailyChallengeSubmissions, dispatch]);
  useEffect(() => {
    if (!isEmpty(submitAnsDailyChallenge)) {
      // CLEAN DRAW
      const submission = dailyChallengeSubmissions[activeQsDailyChallenge - 1];
      if (submission.SubmissionModel.SubmissionResult === 1) {
        setCorrectAnswer('correct');
        setShowSolution(true);
        setExpandQn(false);
      } else if (submission.QuestionStatus === 4) {
        setCorrectAnswer('skipped');
        setShowSolution(true);
      } else if (submission.SubmissionModel.SubmissionResult === 2) {
        setCorrectAnswer('wrong');
      } else {
        setCorrectAnswer('unknown');
      }
    } else if (isEmpty(submitAnsDailyChallenge)) {
      const submission = dailyChallengeSubmissions[activeQsDailyChallenge - 1];
      if (
        dailyChallengeSubmissions.length > 0 &&
        submission.SubmissionModel !== null
      ) {
        if (submission.SubmissionModel.SubmissionResult === 1) {
          setCorrectAnswer('correct');
          setShowSolution(true);
        } else if (submission.QuestionStatus === 4) {
          setCorrectAnswer('skipped');
          setShowSolution(true);
        } else if (submission.SubmissionModel.SubmissionResult === 2) {
          setCorrectAnswer('unknown');
          setShowSolution(false);
        } else {
          setCorrectAnswer('unknown');
          setShowSolution(false);
        }
      } else {
        setShowSolution(false);
        setCorrectAnswer('unknown');
      }
    }
  }, [
    activeQsDailyChallenge,
    dailyChallengeSubmissions,
    submitAnsDailyChallenge,
  ]);

  useEffect(() => {
    const getBadgeTime = () => {
      try {
        return moment(
          currDailyChallenge.BadgeInfo.BadgeID,
          'YYYY-MM-DDTHH:mm:ss'
        );
      } catch {
        return moment();
      }
    };

    if (showHoDModal) {
      const badgeDateTime = getBadgeTime();
      const params = {
        year: badgeDateTime.format('YYYY'),
        month: badgeDateTime.format('MM'),
        day: badgeDateTime.format('DD'),
      };
      fetchWrapper(postMarkBadgeAsSeen, params);
    }
    // eslint-disable-next-line
  }, [showHoDModal]);

  useEffect(() => {
    if (isChangingQnThreeTimes) {
      setShowActionRestrictModal(true);
    }
  }, [isChangingQnThreeTimes]);

  // Cleanup UseEffect Hook
  useEffect(() => {
    return () => {
      dispatch(resetSubmitAns());
      dispatch(resetSubmitDailyChallengeAnswerIsErr());
    };
  }, [dispatch]);

  const resetErrModal = () => {
    setShowActionRestrictModal(false);
    dispatch(resetErr());
  };
  const hideModal = () => {
    dispatch(setShowHoDModal(false));
  };

  const openModal = () => {
    if (isLoading) {
      return;
    }
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const submitAnswer = (skipQn) => {
    if (isLoading) {
      setShowModal(false);
      return;
    }
    setIsSKipAnswer(skipQn);
    const rawBody = {
      challengeID: currDailyChallenge.ChallengeID,
      displayIndex: activeQsDailyChallenge,
      userID,
      questionID: dailyChallengeQuestion[activeQsDailyChallenge - 1].Id,
      type: submissionTypeIDs.DailyChallenge,
      questionType:
        dailyChallengeQuestion[activeQsDailyChallenge - 1].QuestionType,
      versionId: dailyChallengeQuestion[activeQsDailyChallenge - 1].Version,
      answerkeyValuePairs: skipQn
        ? []
        : createAnswerKeyValuePairsByQuestionType(
            questionTypeIDs[
              dailyChallengeQuestion[activeQsDailyChallenge - 1].QuestionType
            ],
            {
              studentAnswers:
                dailyChallengeLocalSavedAns[activeQsDailyChallenge - 1],
              questionAnswers: null,
              userID,
              qnID: null,
              keysToMarkRight: null,
            }
          ),
      isFinalSubmit: false,
      isSkipped: skipQn,
      subjectID: currDailyChallenge.SubjectID,
    };
    // Save workings/bar model data under property "solution"
    const workings = getWorkings(
      dailyChallengeSubmissions[activeQsDailyChallenge - 1],
      dailyChallengeLocalSavedWorkings[activeQsDailyChallenge - 1]
    );
    const barModel = barModelData.objects.length > 0 ? barModelData : null;
    if (!isNil(workings) || !isNil(barModel)) {
      const solutionObject = {
        workings: isNil(workings) ? null : workings,
        barModel: isNil(barModel) ? null : barModel,
      };
      // Stringify solution object as API only accepts value type string for solution
      rawBody.solution = JSON.stringify(solutionObject);
    }

    dispatch(
      submitDailyChallengeAnswer({
        rawBody,
        hasMysteryKoko: mysteryKoko.hasMysteryKoko,
      })
    );
    // EVENT TRACKING: submit answer
    sendEventTracking('daily_challenge', 'question_submit', {
      sub: currDailyChallenge?.SubjectID,
      qi: dailyChallengeQuestion[activeQsDailyChallenge - 1].Id,
    });
    dispatch(setClearDraw(true));
    if (skipQn) {
      closeModal();
    }
  };

  return (
    <Container>
      <PopupModal
        show={showHoDModal}
        backdrop="static"
        hide={() => {
          dispatch(setShowHoDModal(false));
        }}
      >
        <HeroOfTheDayModal
          badgeImage={currDailyChallenge?.BadgeInfo?.BadgeUrl}
          onHide={hideModal}
        />
      </PopupModal>
      <PopupModal
        show={showClaimKokoModal}
        backdrop="static"
        hide={() => {
          dispatch(setShowClaimKokoModal(false));
        }}
      >
        <ClaimKokoModal
          kokoNumber={currDailyChallenge?.TotalKokoClaimed}
          onHide={() => {
            dispatch(setShowClaimKokoModal(false));
          }}
        />
      </PopupModal>
      <LevelUpModal />
      {mysteryKoko.hasMysteryKoko && (
        <MysteryKoKoModal
          questionCount={currDailyChallenge?.UserQuestionSubmissions.length}
          boxImg={mysteryKoko.boxImg}
          subjectID={currDailyChallenge?.SubjectID}
        />
      )}
      <PopupModal show={showActionRestrictModal} hide={resetErrModal}>
        <ActionsRestrictModal onHide={resetErrModal} />
      </PopupModal>
      <Canvas
        bgColor="rgb(12, 65, 60, 0.21)"
        feature="daily-challenge"
        showSideBar={!isTablet}
        top={68}
        qnBg="#217a92"
      >
        <>
          <QnViewHeader
            bgColor="#1BBFE6"
            logoBgColor="#16AFD3"
            btnBgColor="rgb(22, 175, 211, 0.61)"
            backText={t('dailyChallengeQnView:back', 'Back')}
            onClick={() => history.goBack()}
          >
            {((featureToggle.science && currDailyChallenge.SubjectID === 1) ||
              !featureToggle.science) && (
              <DifficultyIndicator difficultyLevel={ChallengeLevel} />
            )}
            <SideNavigation>
              <QnSideBar
                questions={dailyChallengeQuestion}
                activeQuestion={activeQsDailyChallenge}
                setExpandQn={setExpandQn}
                subjectID={currDailyChallenge?.SubjectID}
              />
            </SideNavigation>
          </QnViewHeader>
          <HideOnTabletBelow>
            <QnSideBar
              questions={dailyChallengeQuestion}
              activeQuestion={activeQsDailyChallenge}
              setExpandQn={setExpandQn}
              subjectID={currDailyChallenge?.SubjectID}
            />
          </HideOnTabletBelow>
          <Main>
            <Notebook
              error={isErr}
              isLoading={currDailyChallengeLoading}
              spinnerColor="#16AFD3"
              topContent={<DailyChallengeNotebookHeader />}
              middleContent={
                <DailyChallengeNotebookMiddle
                  expandQn={expandQn}
                  setExpandQn={setExpandQn}
                  correctAnswer={correctAnswer}
                  setCorrectAnswer={setCorrectAnswer}
                  showSolution={showSolution}
                  submitAnswer={submitAnswer}
                  isSkipAnswer={isSkipAnswer}
                />
              }
              bottomContent={
                <DailyChallengeNotebookBottom
                  expandQn={expandQn}
                  setExpandQn={setExpandQn}
                  correctAnswer={correctAnswer}
                  setCorrectAnswer={setCorrectAnswer}
                  showSolution={showSolution}
                  submitAnswer={submitAnswer}
                  isSkipAnswer={isSkipAnswer}
                  showModal={showModal}
                  openModal={openModal}
                  closeModal={closeModal}
                />
              }
            />
          </Main>
        </>
      </Canvas>
    </Container>
  );
};

export default DailyChallengeQnView;
