import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownMenu } from 'components/Shared';
import { assignerEnum } from 'constants/index';
import { getAssignmentList } from 'store/assignment/assignmentListSlice';
import { RefreshButton, YearDropdown } from '..';
import { Container } from './AssignerCheckBox.styles';

const AssignerCheckBox = ({
  assigners,
  assignedBy,
  setAssignedBy,
  subStatus,
  year,
  years,
  setYear,
}) => {
  const { t } = useTranslation(['assignmentList']);
  const dispatch = useDispatch();

  return (
    <Container>
      <YearDropdown
        className="year-bottom"
        selectedValue={year}
        values={years}
        setValue={setYear}
      />
      <span className="assigned-by">
        <p>{t('assignmentList:topBar.AssignedBy', 'Assigned By')}</p>
        <DropdownMenu
          selectedValue={t(
            `assignmentList:topBar.${assignerEnum[assignedBy]}`,
            `${assignerEnum[assignedBy]}`
          )}
          valueKey={null}
          values={assigners}
          setValue={setAssignedBy}
          width="150px"
          valueTemplate={(value) =>
            t(
              `assignmentList:topBar.${assignerEnum[value]}`,
              `${assignerEnum[value]}`
            )
          }
        />
      </span>
      <RefreshButton
        onClick={() => {
          dispatch(
            getAssignmentList({
              assignedBy,
              submissionStatus: subStatus,
              assignedYear: year,
              pageSize: 30,
              pageIndex: 0,
            })
          );
        }}
      />
    </Container>
  );
};

export default AssignerCheckBox;
