import React, { useState } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { saveAnswer, checkAnswer } from 'store/assignment/assignmentSlice';
import { setLastSavedTime } from 'store/timer/timerSlice';
import { saveEventAns } from 'store/event/eventSlice';
import { getWorkings } from 'helpers/getWorkings';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import { questionTypeIDs } from 'constants/index';

import { ResponsiveHeader } from '../../../components';
import { SideNav } from 'components/Shared';
import AssignmentSideNav from 'components/Assignment/AssignmentView/AssignmentSideNav';

import HeaderMiddle from './HeaderMiddle';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';

const AssignmentHeader = ({
  assignmentTitle = '',
  assignmentId,
  assignmentType = '',
  openModal,
  isLoading,
  isError,
  currentSavedAnswer,
  questions,
  submissions,
  submissionType,
  submissionId,
  activeQn,
  currentLocalSavedAnswer,
  currentAnswerKeys,
  localSavedAnswers,
  localSavedWorkings,
  setActiveQn,
  showButtons = true,
}) => {
  const store = useStore();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: '(max-width: 846px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 545px)' });
  const { userID } = useSelector((state) => state.login);
  const { data: barModelData } = useSelector((state) => state.barModel);
  const { isSaving, isChecking, saveError } = useSelector(
    (state) => state.assignment
  );
  const { isEventSaving } = useSelector((state) => state.event);
  const [showSideNav, setShowSideNav] = useState(false);
  const currentQuestion = questions[activeQn - 1];
  const printUrl = assignmentId
    ? `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_SUBDOMAIN}${
        process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
      }assignment/print/${assignmentId}`
    : '';

  const sideNavClickHandler = () => {
    setShowSideNav((prevState) => !prevState);
  };
  const submitHandler = () => {
    setShowSideNav(false);
    openModal();
  };
  const dispatchSaveAction = (params) => {
    if (assignmentType === 'sundayMC') {
      dispatch(saveEventAns(params));
    } else {
      dispatch(saveAnswer(params));
    }
  };
  const dispatchCheckAnswerAction = (params) => {
    if (assignmentType !== 'sundayMC') dispatch(checkAnswer(params));
  };
  const saveHandler = (customSaveAction, customCheckAnsAction) => {
    const now = Date.now();
    newSaveAnswerHandler({
      assignmentSubmissionID: submissionId,
      assignmentType,
      questionType: questionTypeIDs[currentQuestion?.QuestionType],
      submissionType,
      questionAnswer: currentAnswerKeys,
      saveAnswerAction: customSaveAction ?? dispatchSaveAction,
      checkAnswerAction: customCheckAnsAction ?? dispatchCheckAnswerAction,
      activeQnNo: activeQn,
      currentQn: currentQuestion,
      currentLocalSavedAnswer,
      currentSubmission: submissions[activeQn - 1].SubmissionModel,
      timeElapsed: now - store.getState().timer.lastSavedTime,
      userID,
      workings: getWorkings(
        submissions[activeQn - 1],
        localSavedWorkings[activeQn - 1]
      ),
      barModel: barModelData.objects.length > 0 ? barModelData : null,
    });
    dispatch(setLastSavedTime(now));
  };
  return (
    <>
      <SideNav show={showSideNav}>
        <AssignmentSideNav
          setShow={setShowSideNav}
          activeQuestion={activeQn}
          setActiveQn={setActiveQn}
          questions={questions}
          submissions={submissions}
          onSubmit={submitHandler}
          onSave={saveHandler}
          printUrl={printUrl}
          isLoading={isLoading}
          isError={isError}
          currentSavedAnswer={currentSavedAnswer}
          localSavedAnswers={localSavedAnswers}
          assignmentType={assignmentType}
          showAssistTools={!isMobile}
          showProficiency={assignmentType === 'homework'}
          showButtons={showButtons}
        />
      </SideNav>
      <ResponsiveHeader
        assignmentType={assignmentType}
        showButtons={showButtons}
        left={
          <HeaderLeft
            isMobile={isMobile}
            isTablet={isTablet}
            assignmentType={assignmentType}
            showButtons={showButtons}
          />
        }
        middle={
          <HeaderMiddle
            isMobile={isMobile}
            assignmentType={assignmentType}
            assignmentTitle={assignmentTitle}
          />
        }
        right={
          <HeaderRight
            isTablet={isTablet}
            assignmentType={assignmentType}
            printUrl={printUrl}
            openModal={openModal}
            onSave={saveHandler}
            sideNavClickHandler={sideNavClickHandler}
            isLoading={isLoading}
            isError={isError}
            isSaving={isSaving || isEventSaving}
            currentSavedAnswer={currentSavedAnswer}
            isChecking={isChecking}
            saveError={saveError}
            activeQuestion={activeQn}
            showButtons={showButtons}
          />
        }
        styles={{
          leftSection: isMobile
            ? {
                minWidth: 'auto',
              }
            : {},
          rightSection: isTablet
            ? {
                borderLeft: 'none',
                justifyContent: 'flex-end',
                minWidth: isMobile ? '55%' : '200px',
              }
            : {},
          top: !showButtons && '59px',
        }}
      />
    </>
  );
};

export default AssignmentHeader;
