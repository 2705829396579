import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from 'components/Header';
import { BackButton } from 'components/Shared';
import { ReactComponent as IconMiko } from 'assets/img/trial/icon-miko.svg';
import { SUBJECTS } from '../constants';

import { StyledButtonContainer } from '../index.styles';
import { Page, StyledDashboard, StyledDesc } from './TrialBlock.styles';

const TrialBlock = () => {
  const { t } = useTranslation(['bundleTrial', 'common']);
  const { countryEligibility } = useSelector((state) => state.plan);
  const history = useHistory();
  const backHandler = () => {
    history.push('/dashboard');
  };
  return (
    <Page>
      <Header toggleMenu={false} toggleAccessMenu={false} />
      <StyledButtonContainer>
        <BackButton
          btnBgColor="rgba(254, 113, 32, 0.6)"
          padding="0.4rem 1rem"
          backText={t('common:back', 'Back')}
          onClick={backHandler}
        />
      </StyledButtonContainer>
      <StyledDesc>
        <div className="desc">
          <p>
            {t(
              'bundleTrial:block.first',
              'You still have an active trial account!'
            )}
          </p>
          <p>
            {t(
              'bundleTrial:block.second',
              'You can start another trial once your current trial has ended.'
            )}
          </p>
          <p>
            {t(
              'bundleTrial:block.three',
              'We look forward to seeing you soon!'
            )}
          </p>
        </div>
        <IconMiko />
      </StyledDesc>
      <StyledDashboard>
        {countryEligibility.data?.eligibleSubjects
          ?.slice()
          .sort(
            (a, b) => SUBJECTS[a.subjectId].order - SUBJECTS[b.subjectId].order
          )
          .map(({ subject, subjectId }) => (
            <StyledDashboard.Item subject={subject} key={subjectId}>
              <img src={SUBJECTS[subjectId].src} alt={subject} />
              <p>
                {t(`common:subjects.${subject}`, subject)}{' '}
                {t('common:plans.premium', 'Premium')}
              </p>
            </StyledDashboard.Item>
          ))}
      </StyledDashboard>
    </Page>
  );
};

export default TrialBlock;
