import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { getHasIncomingPeerChallenges } from 'store/multiplayer/multiplayerSlice';
import accessFeature from 'helpers/accessFeature';
import { subjectNames } from 'constants/products';
import { kooChallengeIcons } from 'constants/dashboardv2';
import { rwd } from 'Theme';

import IncomingIcon from 'assets/img/dashboardv2/IncomingIcon.svg';
import { StyledContainer } from './PeerChallengeCard.styles';

const CardImage = ({ subject }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc = kooChallengeIcons[subject].pc;
  if (isTablet) {
    imgSrc = kooChallengeIcons[subject].pcTablet;
  }
  if (isMobile) {
    imgSrc = kooChallengeIcons[subject].pc;
  }

  return <img src={imgSrc} alt="" className="bg" />;
};

const PeerChallengeCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const { userID } = useSelector((state) => state.login);
  const { subject, currentProduct } = useSelector((state) => state.plan);
  const { hasIncomingChallenges } = useSelector((state) => state.multiplayer);
  // Event Handlers
  const clickHandler = () => {
    const { url, error } = accessFeature({
      type: 'peerchallenge',
      subject,
      featuresConfig: currentProduct?.features,
    });
    if (!error) {
      history.push(url);
    }
  };
  useEffect(() => {
    if (!userID) return;
    dispatch(
      getHasIncomingPeerChallenges({
        userID,
        subjectID: subjectNames[subject],
      })
    );
  }, [dispatch, subject, userID]);
  return (
    <StyledContainer
      onClick={clickHandler}
      data-cy="peer-challenge"
      subject={subject}
    >
      <div>
        <CardImage subject={subject} />
        {hasIncomingChallenges && (
          <img src={IncomingIcon} alt="" className="incoming-icon" />
        )}
        <p>{t('peerChallenge.name', 'Peer Challenge')}</p>
      </div>
    </StyledContainer>
  );
};

export default PeerChallengeCard;
