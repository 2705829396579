export const trialContentConfig = {
  math: {
    title: 'Save Hours Struggling with Primary School Maths',
    desc: 'Learn 4x faster with our digital questions and animated tutorials in KooBits Math Premium. Go beyond the school version. No credit card needed.',
    secondDesc: 'Boost Your Math Today with a 7-day FREE Trial',
    videoUrl: 'https://www.youtube.com/watch?v=DZzaHWHl9u8',
    translationKey: 'mathTrial',
    backBtnColor: 'rgba(164, 140, 240, 0.6)',
    coverImage: null,
    showPremiumIcon: true,
    showNewBadge: false,
    showBoostBadge: true,
  },
  science: {
    title: 'Boost Your Science with KooBits 7-day FREE trial',
    desc: 'Get unlimited access to over 10,000 P1-6 digital MCQ and OEQ questions that are auto-marked with instant feedback. No credit card needed.',
    videoUrl: 'https://www.youtube.com/watch?v=053jj3j8hrQ',
    translationKey: 'scienceTrial',
    backBtnColor: 'rgba(111, 204, 7, 0.8)',
    coverImage: null,
    showPremiumIcon: false,
    showNewBadge: false,
    showBoostBadge: false,
  },
  english: {
    title: 'Be the first to try KooBits English for 7-day Free! ',
    desc: 'More fun challenges, missions and skills to unlock.',
    videoUrl: 'https://www.youtube.com/watch?v=ZSXYL8N4kVI',
    translationKey: 'englishTrial',
    backBtnColor: 'rgba(254, 113, 32, 0.6)',
    coverImage: null,
    showPremiumIcon: false,
    showNewBadge: true,
    showBoostBadge: false,
  },
};
export const ENGLISH_SELLING_POINT = [
  {
    key: 'point1',
    isSgOnly: false,
    text: '● Animated Vocab Cards✨',
  },
  {
    key: 'point2',
    isSgOnly: false,
    text: '● Curriculum-aligned stories📚',
  },
  {
    key: 'point3',
    isSgOnly: false,
    text: '● English BrainGames🎮',
  },
  {
    key: 'point4',
    isSgOnly: false,
    text: '● Earn More KoKo Credits and Rewards🏆',
  },
  {
    key: 'point5',
    isSgOnly: true,
    text: '● 🦊Limited Edition Miko Plushie Gift Box!🦊',
  },
];
