import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'ramda';

import {
  submitPeerChallengeV2,
  resetSubmitChallenge,
  resetChallenge,
} from 'store/multiplayer/multiplayerSlice';
import { clearVoiceoverPlaying } from 'store/voiceover/voiceoverSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

import {
  Notebook,
  Canvas,
  PopupModal,
  SubmitModal,
  QuestionFeedbackLink,
  ErrorModal,
  QnViewHeader,
} from 'components/Shared';
import { PeerChallengeVersus } from '../components';
import {
  VersusAnimation,
  PeerChallengeSubmitButton,
  PeerChallengeQnViewMiddle,
} from './components';

import { submissionTypeIDs, questionTypeIDs } from 'constants/index';
import { multiplayerListStyles } from 'constants/multiplayer';
import { subjectNames } from 'constants/products';

import { createAnswerKeyValuePairsByQuestionType } from 'helpers/createAnswerKeyValuePairs';
import useQuery from 'helpers/useQuery';
import { sendEventTracking } from 'helpers/UserEventTracking';
import { getChallengerInfo } from '../helpers';

import useLocalStorage from 'hooks/useLocalStorage';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import useIsMobile from 'hooks/responsive/useIsMobile';

import {
  Container,
  PeerChallengeHeader,
  Main,
} from './PeerChallengeQnView.styles';

const PeerChallengeQnView = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = useQuery();
  const isMobile = useIsMobile();
  const subjectParam = query.get('subject');
  const lowercaseSubjectParam = isNil(subjectParam)
    ? 'math'
    : subjectParam.toLowerCase().trim();
  const { t } = useTranslation(['peerChallengeQnView']);
  const { userID } = useSelector((state) => state.login);
  const [randomBot] = useLocalStorage(
    'pcRandomBot',
    {},
    { validateUserId: true }
  );
  // responsive
  const isDesktop = useIsDesktop();
  // get opponent id
  const peerChallengeId = query.get('id');
  const isRandomBotDataMatches =
    randomBot.subject === lowercaseSubjectParam &&
    randomBot.data.Id === parseInt(peerChallengeId, 10);

  const {
    peerChallengeOpen,
    isOpeningChallenge,
    errorOpeningChallenge,
    peerChallengeLocalSavedAns,
    peerChallengeLocalSavedWorkings,
    isLoading,
  } = useSelector((state) => state.multiplayer);
  const { multiBarModelData } = useSelector((state) => state.barModel);
  const { userInfoCache } = useSelector((state) => state.lookup);
  const animationData = location.state?.animation;
  const challengerInfo = useMemo(() => {
    return getChallengerInfo(animationData, userID, userInfoCache);
  }, [userInfoCache, userID, animationData]);
  const hasAnimation =
    location.state?.animation && !isEmpty(location.state?.animation);

  // modal state
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitErr, setSubmitErr] = useState(null);

  useEffect(() => {
    // EVENT TRACKING: onpage load
    sendEventTracking('peer_challenge', 'challenge_view', {
      sub: subjectNames[lowercaseSubjectParam],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = () => {
    dispatch(clearVoiceoverPlaying());
    // Check if there is pending submit api request
    if (isLoading === true) {
      return;
    }
    setShowSubmitModal(true);
  };
  const closeModal = () => {
    setShowSubmitModal(false);
  };
  const closeSubmitErrorModal = () => {
    setSubmitErr(null);
  };

  const submitHandler = async () => {
    setShowSubmitModal(false);
    // Check if there is pending submit api request
    if (isLoading === true) {
      return;
    }
    const rawBody = {
      UserId: userID,
      UserSubmissions: peerChallengeOpen.Questions.map((item, index) => {
        const submissionObj = {
          userID,
          questionID: item.Id,
          type: submissionTypeIDs.PeerChallenge,
          questionType: item.QuestionType,
          versionId: item.Version,
          answerkeyValuePairs: createAnswerKeyValuePairsByQuestionType(
            questionTypeIDs[item.QuestionType],
            {
              studentAnswers: peerChallengeLocalSavedAns[index],
              questionAnswers: null,
              userID,
              qnID: null,
              keysToMarkRight: null,
            }
          ),
          isFinalSubmit: false,
        };
        const workings = peerChallengeLocalSavedWorkings[index];
        const barModel =
          multiBarModelData[index]?.objects.length > 0
            ? multiBarModelData[index]
            : null;
        // Save workings/bar model data under property "solution"
        if (!isNil(workings) || !isNil(barModel)) {
          const solutionObject = {
            workings: isNil(workings) ? null : workings,
            barModel: isNil(barModel) ? null : barModel,
          };
          // Stringify solution object as API only accepts value type string for solution
          submissionObj.solution = JSON.stringify(solutionObject);
        }
        return submissionObj;
      }),
    };
    try {
      const submitAction = await dispatch(
        submitPeerChallengeV2({
          peerChallengeId: peerChallengeOpen?.PeerChallengeId,
          reqBody: rawBody,
        })
      );
      unwrapResult(submitAction);
      // EVENT TRACKING: submit answer
      // eslint-disable-next-line array-callback-return
      peerChallengeOpen.Questions.map((item) => {
        sendEventTracking('peer_challenge', 'challenge_submit', {
          sub: subjectNames[lowercaseSubjectParam],
          qi: item.Id,
        });
      });
      if (isRandomBotDataMatches) localStorage.removeItem('pcRandomBot');
      history.replace(
        `/multiplayer/peer-challenge/challenge-result/${peerChallengeOpen?.PeerChallengeId}/result?subject=${lowercaseSubjectParam}`
      );
      dispatch(resetSubmitChallenge());
      dispatch(setClearDraw(true));
    } catch (err) {
      setSubmitErr(err.message);
    }
  };

  if (
    !isOpeningChallenge &&
    isNil(peerChallengeOpen) &&
    isNil(errorOpeningChallenge)
  )
    return (
      <Redirect
        to={`/multiplayer/peer-challenge/new-challenge?subject=${subjectParam}`}
      />
    );
  return (
    <Canvas
      feature="peer-challenge"
      bgColor={
        multiplayerListStyles[lowercaseSubjectParam].qnView
          .helperSidebarBgColour
      }
      showSideBar={isDesktop}
      top={68}
    >
      <Container
        bgColour={
          multiplayerListStyles[lowercaseSubjectParam].qnView.mainBgColour
        }
      >
        {hasAnimation === true && (
          <VersusAnimation
            data={challengerInfo}
            clearLocationState
            subject={lowercaseSubjectParam}
          />
        )}
        {submitErr && (
          <ErrorModal
            errorMessage={submitErr}
            closeHandler={closeSubmitErrorModal}
          />
        )}
        <PopupModal show={showSubmitModal} backdrop="static">
          <SubmitModal
            type={t('peerChallengeQnView:submitModal.warning', 'Warning!')}
            header={t(
              'peerChallengeQnView:submitModal.header',
              'Submit Challenge'
            )}
            title={t(
              'peerChallengeQnView:submitModal.title',
              'Are you sure you want to submit now?'
            )}
            desc={t(
              'peerChallengeQnView:submitModal.desc',
              'Please make sure you do not leave any question(s) unanswered. You will not be able to do change your answers after submission.'
            )}
            noHandle={closeModal}
            yesHandle={submitHandler}
            isV2Options
          />
        </PopupModal>
        <QnViewHeader
          bgColor={multiplayerListStyles[lowercaseSubjectParam].qnView.bgColour}
          logoBgColor={
            multiplayerListStyles[lowercaseSubjectParam].qnView.logoBgColour
          }
          btnBgColor={
            multiplayerListStyles[lowercaseSubjectParam].qnView.btnBgColour
          }
          backText={t('peerChallengeQnView:header.back', 'Back')}
          onClick={() => {
            history.goBack();
            dispatch(resetChallenge());
          }}
          hasBgImg={
            multiplayerListStyles[lowercaseSubjectParam].qnView.hasBgImg
          }
        >
          <PeerChallengeHeader>
            <PeerChallengeVersus
              userData={challengerInfo.current}
              opponentData={challengerInfo.opponent}
            />
          </PeerChallengeHeader>
        </QnViewHeader>
        <Main>
          <Notebook
            page="peer-challenge"
            isLoading={isOpeningChallenge}
            spinnerColor="#FF8000"
            error={errorOpeningChallenge}
            topContent={
              <PeerChallengeSubmitButton
                dataCy="submit-btn"
                openModal={openModal}
                isLoading={isLoading}
                justify="flex-end"
              />
            }
            middleContent={
              <PeerChallengeQnViewMiddle
                questions={peerChallengeOpen && peerChallengeOpen.Questions}
              />
            }
            bottomContent={
              <PeerChallengeSubmitButton
                dataCy="submit-btn-two"
                openModal={openModal}
                isLoading={isLoading}
                justify={isMobile ? 'flex-end' : 'space-between'}
              >
                {peerChallengeOpen && !isMobile && (
                  <QuestionFeedbackLink
                    questionList={peerChallengeOpen?.Questions}
                  />
                )}
              </PeerChallengeSubmitButton>
            }
          />
        </Main>
      </Container>
    </Canvas>
  );
};

export default PeerChallengeQnView;
