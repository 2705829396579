import styled from 'styled-components';

export const DropdownContainer = styled.div`
  padding: 0 12px;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
    align-items: center;

    br {
      display: none;
    }
  }
`;
