export const assignmentTypeStyles = {
  KooQuizMaths: {
    bgColour: '#18214D',
    logoBgColour: '#273165',
    buttonBgColour: '#394EB1',
    buttonHoverBgColour: '#475FD0',
    actionButtonBgColour: '#394EB1',
    actionButtonHoverBgColour: '#475FD0',
    actionButtonTextColour: '#FFFFFF',
    printButtonBgColour: '#394EB1',
    printButtonHoverBgColour: '#475FD0',
    backBtnColor: '#394EB1',
  },
  KooQuizEnglish: {
    bgColour: '#FFBD29',
    logoBgColour: '#FFA701',
    buttonBgColour: '#ECA804',
    buttonHoverBgColour: '#F9A806',
    actionButtonBgColour: '#ECA804',
    actionButtonHoverBgColour: '#F9A806',
    actionButtonTextColour: '#FFFFFF',
    printButtonBgColour: '#EDA803',
    printButtonHoverBgColour: '#F9A806',
    backBtnColor: '#EF9C00',
  },
  KooQuizSci: {
    bgColour: '#153B4D',
    logoBgColour: '#00486A',
    buttonBgColour: '#153B4D',
    buttonHoverBgColour: '#0076a5',
    actionButtonBgColour: '#23C5A8',
    actionButtonHoverBgColour: '#39C9B0',
    actionButtonTextColour: '#FFFFFF',
    printButtonBgColour: '#25B59C',
    printButtonHoverBgColour: '#39C9B0',
    backBtnColor: '#227CA7',
  },
};
