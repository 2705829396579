import React, { useEffect } from 'react';
import { Link, scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import formatChineseNumber from 'helpers/formatChineseNumber';
import { Container, Level } from './LevelSelectionBar.styles';

const OFFSET = -180;
const LevelSelectionBar = ({
  selectedLevel,
  setSelectedLevel,
  defaultLevel,
  papers,
  isShowLevel,
  levels,
}) => {
  const { t, i18n } = useTranslation(['kooQuizBookShelf']);
  const currentLanguage = i18n.language;
  // default scroll to current user primary level
  useEffect(() => {
    scroller.scrollTo(selectedLevel, {
      offset: OFFSET,
    });
  }, [papers, selectedLevel]);
  return (
    <Container>
      {isShowLevel &&
        levels.map((level, index) => (
          <Link
            activeClass="active"
            to={`${level}`}
            spy
            offset={OFFSET}
            key={index}
          >
            <Level
              data-cy={level}
              className="level"
              selected={selectedLevel === level}
              onClick={() => {
                setSelectedLevel(level);
              }}
            >
              {defaultLevel === level && <span className="dot" />}
              {`${t(
                'kooQuizBookShelf:header.primary',
                'P'
              )}${formatChineseNumber({
                value: level,
                language: currentLanguage,
              })}`}
            </Level>
          </Link>
        ))}
    </Container>
  );
};

export default LevelSelectionBar;
