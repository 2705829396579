import styled, { css } from 'styled-components';
import { subjectNames, SUBJECTS } from 'constants/products';
import { ITooltip } from '../../DailyChallengeV2.styles';
import {
  mappingRewardStyleResponsive,
  mappingRewardStyle,
  mappingCPIconActiveStyle,
} from './constants';

export const StyledMissionCP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #161616;
  font-weight: 600;
  font-size: 12px;

  & > span {
    opacity: ${({ status }) => (status === 'completed' ? 1 : 0.3)};
  }
  svg {
    color: ${({ subjectId = [subjectNames[SUBJECTS.MATH]], status }) => {
      if (status === 'completed') {
        return mappingCPIconActiveStyle[subjectId];
      }
      return 'rgba(114,114,114,0.12)';
    }};
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    font-size: 14px;
  }
`;
export const MainContainer = styled.div`
  position: relative;
  background-color: ${({ mappingStyle }) => mappingStyle.bgColor};
  background-image: ${({ mappingStyle, subjectId }) =>
    subjectId === subjectNames[SUBJECTS.MATH]
      ? `url(${mappingStyle.bg})`
      : null};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 82%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ subjectId, mappingStyle }) => {
    if (subjectId === subjectNames[SUBJECTS.ENGLISH]) {
      return css`
        :before,
        :after {
          content: '';
          position: absolute;
          bottom: 0;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        :before {
          left: 0;
          background-image: url(${mappingStyle.bgLeft});
          background-size: auto ${mappingStyle.bgLeftWidth.default};
          background-position: left bottom;
        }
        :after {
          right: 0;
          background-image: url(${mappingStyle.bgRight});
          background-size: auto ${mappingStyle.bgRightWidth.default};
          background-position: right bottom;
        }
      `;
    }
  }}
  .dc-content {
    margin: 9vh auto 0 auto;
    position: relative;
    top: 2vh;
    width: 70%;
    aspect-ratio: 930 / 336;
    margin-bottom: 10vh;
    z-index: 3;
    background-image: url(${({ mappingStyle }) => mappingStyle.centerBg});
    background-repeat: no-repeat;
    background-size: ${({ mappingStyle }) => mappingStyle.center.size}%;
    background-position: bottom
      ${({ mappingStyle }) => mappingStyle.center.position.bottom}% left
      ${({ mappingStyle }) => mappingStyle.center.position.left}%;

    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      & {
        background-image: url(${({ mappingStyle }) =>
          mappingStyle.centerResponsiveBg});
      }
    }
  }

  .dc-runway {
    width: 100%;
    height: auto;
  }

  .runway-shadow {
    fill: ${({ mappingStyle }) => mappingStyle.runway.shadow};
  }

  .runway-bg {
    fill: ${({ mappingStyle }) => mappingStyle.runway.bg};
  }

  .runway-line {
    fill: ${({ mappingStyle }) => mappingStyle.runway.line};
    @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      & {
        fill: transparent;
        stroke: ${({ mappingStyle }) => mappingStyle.runway.line};
      }
    }
  }
  
  .english-icon{
    position: absolute;
    &.flag{
      right: 14.8%;
      bottom: 8%;
      width: calc(28.8 /1388 * 100vw);
      height: auto;
    };
    &.book{
      left: 32%;
      bottom: 3%;
      width: calc(121.1 / 1388* 100vw);
      height: auto;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      display: none;
    }
  };
  .dc-info {
    width: 100%;
    display: flex;
    gap: 2vw;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 3vh;
    position: relative;
    z-index: 2;
    & > div:nth-child(1) {
      width: 100%;
      display: flex;
      gap: 2vw;
      align-items: center;
      justify-content: center;
      @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
        margin-bottom: 1rem;
      }
    }

    .dc-curriculum {
      color: #66aec4;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-item: center;
      gap: 10px;

      > span {
        color: #403f3f;
      }
    }
  }

  @media (max-width: 1440px) {
    background-image: url(${({ mappingStyle }) => mappingStyle.XLBg});
    .dc-content {
      top: 2vh;
      left: -2vw;
      width: 80%;
    }
    ${({ subjectId, mappingStyle }) => {
      if (subjectId === subjectNames[SUBJECTS.ENGLISH]) {
        return css`
          :before {
            background-size: auto ${mappingStyle.bgLeftWidth.xl};
          }
          :after {
            background-size: auto ${mappingStyle.bgRightWidth.xl};
          }
        `;
      }
    }}

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    background-image: url(${({ mappingStyle }) => mappingStyle.responsiveBg});
  }

  @media (max-width: 1024px) {
    background-image: url(${({ mappingStyle }) => mappingStyle.largeBg});
    ${({ subjectId, mappingStyle }) => {
      if (subjectId === subjectNames[SUBJECTS.ENGLISH]) {
        return css`
          :before {
            background-size: auto ${mappingStyle.bgLeftWidth.tablet};
          }
          :after {
            background-size: auto ${mappingStyle.bgRightWidth.tablet};
          }
        `;
      }
    }};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    overflow-x: hidden;
    .dc-info {
      padding-bottom: 0px;
      flex-direction: column;
      margin-top: 4rem;
    }
    .dc-content {
      width: min(calc(100vw - 1rem), 85%);
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet - 1}px`}) {
    height: auto;
    flex-grow: 1;
    .dc-content {
      margin: auto;
      margin-top: 4rem;
      width: min(510px, 70%);
    }
  };
`;

export const StatusItem = styled.span`
  font-size: 14px;

  &:first-of-type {
    margin-left: 10vw;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
    margin-right: 5px;
    margin-bottom: -3px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    &:first-of-type {
      margin-left: 0px;
    }
  }
`;

export const MissionItem = styled.div`
  width: 15%;
  position: absolute;
  top: ${(p) => p.position.top}%;
  left: ${(p) => p.position.left}%;
  cursor: pointer;

  .mission-icon {
    position: relative;
    width: 100%;

    svg {
      width: 100%;
      height: auto;
    }

    img {
      max-width: 100%;
      width: 35%;
      position: absolute;
      right: -5%;
      bottom: 3%;
    }

    .mission-number {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: min(3.4vw, 60px);
      color: ${({ mappingStyle, status }) => mappingStyle[status]?.number};
      font-weight: 600;
      font-family: Baloo Chettan;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    &:nth-child(6) ${StyledMissionCP} {
      margin-left: 50px;
      span {
        white-space: nowrap;
      }
    }

    .mission-icon {
      width: 120%;
      .mission-number {
        font-size: min(8vw, 60px);
      }
    }
  }

  .mission-bg {
    .d {
      fill: ${({ mappingStyle, status }) => mappingStyle[status]?.shadow};
    }

    .e {
      fill: ${({ mappingStyle, status }) => mappingStyle[status]?.inline};
    }

    .f {
      stroke: ${({ mappingStyle, status }) => mappingStyle[status]?.outline};
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      width: 8%;
    }
  }
`;

export const RewardIcon = styled.div`
  position: absolute;
  top: ${(p) => mappingRewardStyle[p.currentLevel].top}%;
  right: ${(p) => mappingRewardStyle[p.currentLevel].right}%;
  width: ${(p) => mappingRewardStyle[p.currentLevel].width}%;

  & > img {
    width: 100%;
  }

  & > div > img {
    width: 100%;
    height: auto;
  }

  .dc-reward-container {
    position: relative;
    width: 100%;
  }

  .dc-reward-claimed {
    width: 50%;
    position: relative;

    img {
      width: 100%;
      max-width: 25px;
      position: absolute;
      right: -40px;
      bottom: -5px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    top: ${(p) => mappingRewardStyleResponsive[p.currentLevel].top}%;
    right: ${(p) => mappingRewardStyleResponsive[p.currentLevel].right}%;
    width: ${(p) => mappingRewardStyleResponsive[p.currentLevel].width}%;
  }
`;

export const PointingHand = styled.img`
  position: absolute;
  top: -3%;
  left: -4%;
  width: 15%;
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      top: 0;
      left: 0;
      width: 8%;
    }
  }
`;
export const RewardToolTip = styled(ITooltip)``;

export const MobileBottomBar = styled.div`
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  &.dc {
    background: rgba(0, 0, 0, 0.2);
    & > div:nth-child(1) {
      & > div {
        color: #fff;
        background-color: transparent;
        &::before {
          background-color: transparent;
        }
      }
    }
    & > div:nth-child(2) {
      &.dc-curriculum span {
        color: #fff;
      }
      &.dc-curriculum img {
        visibility: hidden;
        display: none;
      }
    }
  }
  button {
    display: none;
  }
  & > div:nth-child(2) {
    width: 100%;
    & > div {
      background: transparent;
      padding-right: 0px;
      &::before {
        background-color: transparent;
      }
    }
  }
  & > div:nth-child(1) {
    position: static;
    margin: 0px;
    span {
      color: #fff;
      flex-grow: 1;
      text-align: center;
      margin-right: 0px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      display: none;
    }
  }
`;
