import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Clock from 'assets/img/assignment/assignment-view/icon-clock.svg';
import { history } from 'Routes';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAssignmentDetails,
  saveQuestionSubmissionRequest,
  closeAssignmentRequest,
  submitAssignmentRequest,
} from 'services/assignment';
import isResultsReleased from 'helpers/checkIfResultsReleased';
import {
  assignmentTypeIDs,
  submissionTypeIDs,
  questionTypeIDs,
} from 'constants/index';
import newSaveAnswerHandler from 'helpers/saveAnswerHandler';
import { getWorkings } from 'helpers/getWorkings';
import { Container, ErrorText } from './TimeUpModalBody.styles';

const TimeUpModalBody = () => {
  const { t } = useTranslation(['assignmentQnView']);
  const dispatch = useDispatch();
  // Redux states
  const {
    startAssignmentInfo,
    assignmentSubmissionID,
    isSubmitting,
    assignmentSubmissionError,
    questionAnswers,
    activeQuestion,
    questions,
    localSavedAnswers,
    submissions,
    timeElapsed,
    workings,
  } = useSelector((state) => state.assignment);
  const { lastSavedTime } = useSelector((state) => state.timer);
  const { userID } = useSelector((state) => state.login);
  const { data: barModelData } = useSelector((state) => state.barModel);

  // Component states
  const [timeLeft, setTimeLeft] = useState(10);
  const assignmentType = assignmentTypeIDs[startAssignmentInfo.Type];
  const submissionType = submissionTypeIDs.Homework;
  // Save & Finish & Submit mock test/test assignment
  useEffect(() => {
    const getRawBody = (params) => {
      const { rawBody } = params;
      return rawBody;
    };
    const saveAndFinish = async () => {
      try {
        const now = Date.now();
        const rawBody = newSaveAnswerHandler({
          assignmentSubmissionID: assignmentSubmissionID,
          assignmentType,
          questionType:
            questionTypeIDs[questions[activeQuestion - 1].QuestionType],
          submissionType,
          questionAnswer: questionAnswers[activeQuestion - 1],
          saveAnswerAction: getRawBody,
          checkAnswerAction: getRawBody,
          activeQnNo: activeQuestion,
          currentQn: questions[activeQuestion - 1],
          currentLocalSavedAnswer: localSavedAnswers[activeQuestion - 1],
          currentSubmission: submissions[activeQuestion - 1].SubmissionModel,
          timeElapsed: now - lastSavedTime,
          userID,
          workings: getWorkings(
            submissions[activeQuestion - 1],
            workings[activeQuestion - 1]
          ),
          barModel: barModelData.objects.length > 0 ? barModelData : null,
        });
        // Save Answer
        await saveQuestionSubmissionRequest(rawBody);
        // Finish Assignment
        await closeAssignmentRequest(assignmentSubmissionID);
        // Submit Assignment
        await submitAssignmentRequest({
          assignmentID: startAssignmentInfo.Id,
          assignmentSubmissionID,
        });
      } catch (err) {
        console.log(err);
      }
    };
    saveAndFinish();
  }, [
    activeQuestion,
    assignmentSubmissionID,
    assignmentType,
    barModelData,
    dispatch,
    lastSavedTime,
    localSavedAnswers,
    questionAnswers,
    questions,
    startAssignmentInfo.Id,
    submissionType,
    submissions,
    timeElapsed,
    userID,
    workings,
  ]);

  useEffect(() => {
    const getReleaseDate = async () => {
      let redirectRoute;
      // Check if results are released
      try {
        const assignmentDetails = await fetchAssignmentDetails(
          startAssignmentInfo.Id
        );
        redirectRoute =
          isResultsReleased(assignmentDetails.ResultDate) === false &&
          assignmentType === 'test'
            ? '/assignment/submitted/result-release'
            : '/assignment/submitted/claimkoko/0';
        history.push(redirectRoute);
      } catch (err) {
        redirectRoute =
          isResultsReleased(startAssignmentInfo.ResultDate) === false &&
          assignmentType === 'test'
            ? '/assignment/submitted/result-release'
            : '/assignment/submitted/claimkoko/0';
        history.push(redirectRoute);
      }
    };
    let timerID;
    if (timeLeft > 0) {
      timerID = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else {
      getReleaseDate();
    }
    return () => {
      clearInterval(timerID);
    };
  }, [
    assignmentType,
    startAssignmentInfo.Id,
    startAssignmentInfo.ResultDate,
    timeLeft,
  ]);

  return (
    <Container>
      <div className="img-section">
        <img src={Clock} alt="icon" />
      </div>
      <div className="text-section">
        <p>{t('assignmentQnView:timesupModal.text1', "Sorry, time's up!")}</p>
        <p className="desc-text">
          {t(
            'assignmentQnView:timesupModal.text2',
            { time: timeLeft },
            `Your assignment will be automatically submitted in ${timeLeft} seconds.`
          )}
        </p>
        {!isSubmitting && assignmentSubmissionError !== null && (
          <ErrorText>{assignmentSubmissionError}</ErrorText>
        )}
      </div>
    </Container>
  );
};

export default TimeUpModalBody;
