import React, { useEffect, useCallback } from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  getSuperHeroChallengeStatus,
  getSuperHeroChallengeDataWithoutImage,
  getDailyChallengesList,
} from 'store/dailyChallenge/dailyChallengeSlice';

import useDashboard from 'hooks/useDashboard';

import DCText from 'assets/img/dailychallenge/daily-challenge-text.svg';

import { dailyChallengeList } from './constants';
import { SUBJECTS } from 'constants/products';

import {
  checkErrMsgIsClosed,
  parseSuperHeroChallengeStatus,
  sortDailyChallenges,
  challengeStatus,
} from './helpers';
import { ChallengeCard, ParentChallengeCard } from './components';
import {
  ErrorModal,
  BackButton,
  Spinner,
  QnViewHeader,
} from 'components/Shared';

import { Container, Main } from './ChallengesList.styles';

// Challenge List page component to support science product using v2 endpoints
const NewChallengeList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(['challengeList', 'common', 'topicSelection']);
  const isFromShortCut = location.state?.quickAccess;
  // REDUX STATES
  const {
    isLoading: isProductsLoading,
    products,
    currentProduct,
    getProductsError,
  } = useSelector((state) => state.plan);
  const hasProductsLoaded =
    !isProductsLoading && (!isNil(products) || !isNil(getProductsError));
  const hasEnglishProd = products?.some(
    (p) =>
      p.subject === SUBJECTS.ENGLISH &&
      p.nonExpiredSubscriptionProducts.length > 0
  );
  const {
    dailyChallenges,
    isDailyChallengesLoading,
    superHeroChallengeStatus,
    superHeroChallengeIsLoading,
    superHeroChallengeIsError,
  } = useSelector((state) => state.dailyChallenge);
  const { timezone } = useSelector((state) => state.login);
  const isPageLoading =
    isProductsLoading ||
    isDailyChallengesLoading ||
    superHeroChallengeIsLoading;

  const { isDashboardv2 } = useDashboard();
  const isSunday = moment().clone().tz(timezone)?.isoWeekday() === 7;
  // Check the account is registered through localized route

  // Component States

  const loadPageData = useCallback(() => {
    dispatch(getSuperHeroChallengeDataWithoutImage());
    dispatch(getSuperHeroChallengeStatus());
    dispatch(getDailyChallengesList());
  }, [dispatch]);
  const reloadSuperHeroChallengeData = () => {
    dispatch(getSuperHeroChallengeStatus());
  };
  // USEEFFECTS
  useEffect(() => {
    if (hasProductsLoaded) {
      loadPageData();
    }
  }, [hasProductsLoaded, loadPageData]);

  const renderSuperChallenge = () => {
    if (!isSunday) {
      if (
        !isPageLoading &&
        !superHeroChallengeIsError &&
        !isNil(superHeroChallengeStatus)
      ) {
        return dailyChallengeList.challenge
          .filter((chal) => chal.type !== 'daily-challenge')
          .map((item) => (
            <ChallengeCard
              key={item.id}
              challengeStatus={parseSuperHeroChallengeStatus(
                item.id,
                superHeroChallengeStatus
              )}
              challengeName={t(
                `challengeList:${item.nameTranslation}`,
                `${item.name}`
              )}
              challengeDesc={item.desc}
              challengeType={item.type}
              challengeOpenImg={item.imgOpen}
              challengeCloseImg={item.imgClose}
              challengeLink={item.link}
            />
          ));
      }
      if (
        !isPageLoading &&
        !isNil(superHeroChallengeIsError) &&
        superHeroChallengeIsError !== 'Unable to get super vision image'
      ) {
        return (
          <ErrorModal
            reloadAction={reloadSuperHeroChallengeData}
            errorMessage={superHeroChallengeIsError}
          />
        );
      }
    }
    return null;
  };
  const renderParentAppChallenge = () => {
    if (!isPageLoading && hasEnglishProd) {
      return dailyChallengeList.parentAppChallenge.map((data) => (
        <ParentChallengeCard data={data} key={data.id} />
      ));
    }
  };
  return (
    <Container>
      <QnViewHeader page bgColor="#FFC70B">
        <BackButton
          backText={t('topicSelection:backBtn', 'Back')}
          isResponsive
          btnBgColor="rgb(229,153,0,0.61)"
          padding="5px 20px"
          onClick={() => {
            if (isFromShortCut) {
              history.goBack();
              return;
            }
            const destination = isDashboardv2
              ? '/dashboard?view=koochallenge'
              : '/dashboard';
            history.push(destination);
          }}
          styles={{ position: 'absolute', left: '3%' }}
        />
        <img className="dc-text" src={DCText} alt="daily-challenge" />
      </QnViewHeader>
      <Main>
        {/* Render Daily Challenges */}
        {isPageLoading && <Spinner />}
        {!isPageLoading &&
          currentProduct &&
          sortDailyChallenges(dailyChallenges).map((dc) => {
            const hasDCError =
              !isNil(dc.status.error) || !isNil(dc.details.error);
            const isDCClosed = hasDCError
              ? checkErrMsgIsClosed(dc.status.error, dc.details.error)
              : false;
            const dcConstants = dailyChallengeList.challenge.find(
              (challenge) =>
                challenge.type === 'daily-challenge' &&
                challenge.subjectID === dc.subjectID
            );

            if (isNil(dcConstants)) {
              return null;
            }
            if (hasDCError && isDCClosed) {
              return (
                <ChallengeCard
                  key={dcConstants.id}
                  challengeStatus="close"
                  challengeName={t(
                    `challengeList:${dcConstants.nameTranslation}`,
                    `${dcConstants.name}`
                  )}
                  challengeDesc={
                    dcConstants.nameTranslation === 'ScienceDC'
                      ? dcConstants.scienceDesc
                      : dcConstants.desc
                  }
                  challengeType={dcConstants.type}
                  challengeOpenImg={dcConstants.imgOpen}
                  challengeCloseImg={dcConstants.imgClose}
                  challengeLink={dcConstants.link}
                  totalQns={dc.totalQns}
                  cpAmt={dcConstants.cpAmt}
                  subjectID={dc.subjectID}
                />
              );
            }
            if (hasDCError && !isDCClosed) {
              return (
                <ErrorModal
                  reloadAction={loadPageData}
                  errorMessage={dc.status.error ?? dc.details.error}
                />
              );
            }
            return (
              <ChallengeCard
                key={dcConstants.id}
                challengeStatus={challengeStatus(dc.status, dc.details)}
                challengeName={t(
                  `challengeList:${dcConstants.nameTranslation}`,
                  `${dcConstants.name}`
                )}
                challengeDesc={
                  dcConstants.nameTranslation === 'ScienceDC'
                    ? t(
                        'challengeList:scienceDesc',
                        `${dcConstants.scienceDesc}`
                      )
                    : t('challengeList:desc', `${dcConstants.desc}`)
                }
                challengeType={dcConstants.type}
                challengeOpenImg={dcConstants.imgOpen}
                challengeCloseImg={dcConstants.imgClose}
                challengeLink={dcConstants.link}
                totalQns={dcConstants.totalQns}
                cpAmt={dcConstants.cpAmt}
                subjectID={dc.subjectID}
              />
            );
          })}
        {renderSuperChallenge()}
        {renderParentAppChallenge()}
      </Main>
    </Container>
  );
};

export default NewChallengeList;
