import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSubmissionStatus } from 'constants/index';
import { submissionStatus } from './constants';
import { Container, Tab } from './HwStatusTabs.styles';

const HwStatusTabs = ({ status, setStatus, tabs }) => {
  const { t, i18n } = useTranslation(['assignmentList']);
  const selectedTab = submissionStatus[status];
  const isChinese = i18n.language.toLowerCase().includes('zh');
  return (
    <Container>
      {tabs.map(({ name, icon, iconWidth, fontSize }, index) => (
        <Tab
          data-cy={`tab-${name}`}
          key={name}
          index={index}
          active={name === selectedTab}
          onClick={(e) => {
            e.preventDefault();
            setStatus(FilterSubmissionStatus[name]);
          }}
          iconWidth={iconWidth}
          fontSize={fontSize}
          isChinese={isChinese}
        >
          {icon && <img src={icon} title={name} alt={name} />}
          <span>{t(`assignmentList:topBar.${name}`, `${name}`)}</span>
        </Tab>
      ))}
    </Container>
  );
};
export default HwStatusTabs;
