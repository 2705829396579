import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import { useGetAPIAfterStudentLoaded } from './AuthenticatedRoute';
import AuthenticatedRoute from 'components/Shared/AuthenticatedRoute';

const CheckHistoryPopRouter = ({ component, redirectPathname, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isParentAccount } = useSelector((state) => state.login);
  const { products } = useSelector((state) => state.plan);

  useEffect(() => {
    if (!isParentAccount && !products) {
      dispatch(getStudentDetails());
    }
  }, [dispatch, isParentAccount, products]);
  useGetAPIAfterStudentLoaded(Boolean(products));
  if (products) {
    if (history.action === 'POP') {
      return (
        <Redirect
          to={{
            pathname: redirectPathname,
          }}
        />
      );
    }
    return (
      <AuthenticatedRoute {...props} path={props.path} component={component} />
    );
  }
  return <div />;
};

export default CheckHistoryPopRouter;
