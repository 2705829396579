import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { getEventSolution } from 'store/event/eventSlice';

import { PictureType } from 'components/Assignment';
import { Spinner } from 'components/Shared';
import { SMCDetails } from '../components';

import { Container, ErrorText } from './SMCResult.styles';

const SMCResult = ({ match }) => {
  const dispatch = useDispatch();
  const smcId = parseInt(match.params.smcId, 10);

  const { isEventSolutionLoading, eventSolution, eventError } = useSelector(
    (state) => state.event
  );
  const { timezone } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(getEventSolution(smcId));
    // eslint-disable-next-line
  }, [dispatch]);

  const startDate = moment(eventSolution && eventSolution.StartDate)
    .tz(timezone)
    .format();

  // score calculation
  const correct = eventSolution && eventSolution.CorrectAnswers;
  const totalQn = eventSolution && eventSolution.TotalQuestions;
  const totalScore = Math.floor((correct / totalQn) * 100);

  return (
    <Container>
      {isEventSolutionLoading && <Spinner />}
      {!isEventSolutionLoading && eventError === null && eventSolution && (
        <>
          <PictureType
            type="sundayMC2"
            score={totalScore}
            correct={correct}
            date={moment(startDate).format('DD-MMM-yyyy')}
          />
          <SMCDetails />
        </>
      )}
      {!isEventSolutionLoading && eventError !== null && (
        <ErrorText>
          <h3>Error</h3>
          <h4>{eventError}</h4>
        </ErrorText>
      )}
    </Container>
  );
};

export default SMCResult;
