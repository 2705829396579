import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import moment from 'moment';
import { createAssignment } from 'store/assignment/assignmentParentSlice';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Button, FormGroup } from 'components/Shared';
import { Container } from './AssignModal.styles';

const AssignModal = ({ closeModal, setShowSuccessModal, totalQn, data }) => {
  const { t } = useTranslation(['parentAssign']);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [title, setTitle] = useState('');
  const [deadline, setDeadline] = useState('');
  const { userAgent } = navigator;
  const userLang = navigator.language;
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const isIPhoneOrIPad = useCallback(() => {
    const result =
      (/macintosh|mac os x/i.test(userAgent) &&
        window.screen.height > window.screen.width &&
        !userAgent.match(/(iPhone\sOS)\s([\d_]+)/)) ||
      userAgent.match(/(iPad).*OS\s([\d_]+)/);
    return Boolean(result);
  }, [userAgent]);
  const placeholderLanguage = useCallback(() => {
    const isChinese = userLang.includes('zh');
    if (isChinese) {
      return '年/月/日';
    }
    return 'YYYY/MM/DD';
  }, [userLang]);
  const titleValidation = () => {
    const titleFormat = /^[A-Za-z0-9-& ]+$/;
    if (isEmpty(title)) {
      return `${t(
        'parentAssign:assignModal.requiredVal',
        'This is a required field'
      )}`;
    }
    if (title && !title.match(titleFormat)) {
      return `${t(
        'parentAssign:assignModal.specialCharVal',
        'Homework title should not contain special characters'
      )}`;
    }
  };

  const assignHandler = () => {
    const body = {
      homeworkTitle: title,
      skills: data,
      deadline: `${deadline}T23:59:00.340Z`,
    };
    dispatch(createAssignment(body));
    closeModal();
    setShowSuccessModal(true);
  };

  return (
    <Container>
      <FormGroup
        className="input-title"
        label={t('parentAssign:assignModal.labelTitle', 'Homework Title')}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={t(
          'parentAssign:assignModal.placeholderTitle',
          'My Homework'
        )}
        type="text"
        direction="column"
        width={isMobile ? ' 100%' : '400px'}
        validationText={titleValidation()}
      />
      <div className="total-qns">
        <p className="total-text">
          {t('parentAssign:assignModal.total', 'Total')}
        </p>
        <p className="total-number">{totalQn}</p>
        <p className="qns">{t('parentAssign:assignModal.qn', 'Qn(s)')}</p>
      </div>
      <FormGroup
        className="input-date"
        label={t(
          'parentAssign:assignModal.labelDeadline',
          'Submission deadline of this homework'
        )}
        value={deadline}
        onChange={(e) => setDeadline(e.target.value)}
        type="date"
        direction="column"
        width={isMobile ? ' 100%' : '300px'}
        min={tomorrow}
        placeholder={placeholderLanguage()}
        isIPhoneOrIPad={isIPhoneOrIPad()}
      />
      <div className="btn-group">
        <Button
          variant="secondary"
          width={isMobile ? ' 100%' : '170px'}
          onClick={closeModal}
        >
          {t('parentAssign:assignModal.cancel', 'Cancel')}
        </Button>
        <Button
          className="assign-now"
          variant="primary"
          width={isMobile ? ' 100%' : '230px'}
          onClick={assignHandler}
          isDisabled={isEmpty(title) || isEmpty(deadline)}
        >
          {t('parentAssign:assignModal.assignNow', 'Assign Now')}
        </Button>
      </div>
    </Container>
  );
};

export default AssignModal;
