import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { sendEventTracking } from 'helpers/UserEventTracking';

import * as keys from 'assets/img/superherochallenge/superspeed';
import pointIcon from 'assets/img/superherochallenge/superspeed/point.png';
import pointChallengeIcon from 'assets/img/superherochallenge/superspeed/point-challenge.png';

import {
  dispose,
  startGame,
  setWillPaused,
  resumeGame,
  readyStart,
  inputAnswer,
  resetNeedSubmitScore,
  submitSuperspeedScore,
} from 'store/superSpeed/superSpeedSlice';

import useDashboard from 'hooks/useDashboard';

import Question from '../Question';
import BeforeStartChallenge from '../BeforeStartChallenge';
import Countdown from '../Countdown';
import Paused from '../Paused';
import Done from '../Done';

import {
  Container,
  DifficultyBadge,
  Header,
  KeyboardContainer,
  KeyboardKey,
  PauseButton,
  Footer,
  QuestionContainer,
} from './Scene.styles';

function secondsToMinute(seconds) {
  const minute = `${Math.floor(seconds / 60)}`.padStart(2, '0');
  const second = `${Math.floor(seconds % 60)}`.padStart(2, '0');
  return `${minute}:${second}`;
}

const keyboardKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'dot',
  'coma',
  'delete',
  'less',
  'more',
  'colon',
  'percent',
  'space',
  'equal',
  'plus',
  'min',
  'times',
  'divide',
];

const DifficultyMap = {
  1: 'Easy',
  2: 'Medium',
  3: 'Hard',
};

function Scene({ practice }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['superSpeed']);
  const history = useHistory();
  const {
    isStarted,
    isGoingToStart,
    isOver,
    timeRemaining,
    score,
    currentQuestionTimeRemaining,
    willPaused,
    isPaused,
    difficulty,
    submittingScore,
    needSubmitScore,
  } = useSelector((state) => state.superSpeed);
  const superSpeedStatus = useSelector(
    (state) => state.superheroChallenge.superSpeed.Status
  );
  const { isDashboardv2 } = useDashboard();

  useEffect(() => {
    return () => {
      dispatch(dispose());
    };
  }, [dispatch]);
  useEffect(() => {
    if (!submittingScore && needSubmitScore && !practice) {
      dispatch(submitSuperspeedScore(score));
      sendEventTracking('super_speed', 'challenge_submit');
    }
  }, [submittingScore, needSubmitScore, dispatch, score, practice]);
  const onReady = useCallback(() => {
    dispatch(readyStart());
  }, [dispatch]);
  const onPause = useCallback(() => {
    dispatch(setWillPaused(true));
  }, [dispatch]);
  const onUnpause = useCallback(() => {
    dispatch(resumeGame());
  }, [dispatch]);
  const onStart = useCallback(() => {
    dispatch(startGame());
    sendEventTracking('super_speed', 'challenge_start');
  }, [dispatch]);
  const onGoToChallenge = useCallback(() => {
    dispatch(resetNeedSubmitScore());
    history.push('/superhero/superspeed/challenge');
  }, [dispatch, history]);
  const onGoToOtherChallenge = useCallback(() => {
    history.push(isDashboardv2 ? '/superhero' : '/challenges');
  }, [history, isDashboardv2]);
  const onKeyDown = useCallback(
    (key) => () => {
      dispatch(inputAnswer(key));
    },
    [dispatch]
  );
  if (!isGoingToStart && !isOver) {
    return <BeforeStartChallenge practice={practice} onStart={onReady} />;
  }
  return (
    <Container>
      <Header>
        <div className="content">
          <img
            className="point"
            alt="points"
            src={practice ? pointIcon : pointChallengeIcon}
          />
          <span className="score">{score}</span>
          <span className="timerDescription">
            {t('superSpeed:game.timerDescription', 'Time Left')}
          </span>
          <span className="timer">{secondsToMinute(timeRemaining)}</span>
        </div>
      </Header>
      {isOver ? (
        <Done practice={practice} score={score} />
      ) : (
        <>
          {isPaused && <Paused />}
          {!isPaused && (
            <>
              <QuestionContainer>
                <DifficultyBadge>
                  {t(
                    `superSpeed:game.${DifficultyMap[difficulty]}`,
                    DifficultyMap[difficulty]
                  )}
                </DifficultyBadge>
                {isStarted ? (
                  <Question />
                ) : (
                  <Countdown onAfterCountdown={onStart} />
                )}
              </QuestionContainer>
              <KeyboardContainer>
                {keyboardKeys.map((k) => (
                  <KeyboardKey
                    onClick={onKeyDown(k)}
                    alt={k}
                    key={k}
                    src={keys[`key${k}`]}
                  >
                    <img alt={k} src={keys[`key${k}`]} />
                    <img className="hover" alt={k} src={keys[`key${k}Hover`]} />
                  </KeyboardKey>
                ))}
              </KeyboardContainer>
            </>
          )}
        </>
      )}
      <Footer>
        {!isOver && (
          <>
            {!isPaused && (
              <PauseButton disabled={!isStarted} onClick={onPause}>
                {willPaused
                  ? `${t(
                      'superSpeed:game.pausingText',
                      'Pausing in... '
                    )}${currentQuestionTimeRemaining}`
                  : t('superSpeed:game.pauseText', 'Pause the Game')}
              </PauseButton>
            )}
            {isPaused && (
              <PauseButton onClick={onUnpause}>
                {t('superSpeed:game.unpauseText', 'Resume the Game')}
              </PauseButton>
            )}
          </>
        )}
        {isOver &&
          (practice ? (
            <>
              <PauseButton onClick={onReady}>
                {t('superSpeed:game.tryAgainText', 'Try Again')}
              </PauseButton>
              {superSpeedStatus === 3 && (
                <PauseButton onClick={onGoToChallenge}>
                  {t('superSpeed:game.gotoChallenge', 'Go to Challenge')}
                </PauseButton>
              )}
            </>
          ) : (
            <PauseButton onClick={onGoToOtherChallenge}>
              {t(
                'superSpeed:game.gotoOtherChallenges',
                'Go to Other Challenges'
              )}
            </PauseButton>
          ))}
      </Footer>
    </Container>
  );
}

export default React.memo(Scene);
