import {
  MathQnBg,
  ScienceQnBg,
  EnglishQnBg,
} from 'assets/img/kooquiz/kooQuizQn';

export const assignmentTypeStyles = {
  KooQuizSci: {
    bgImage: ScienceQnBg,
    bgColor: '#176B7D',
    repeat: 'repeat',
    size: 'contain',
  },
  KooQuizEnglish: {
    bgImage: EnglishQnBg,
    bgColor: '#6E4E33',
    repeat: 'repeat',
    size: 'contain',
  },
  KooQuizMaths: {
    bgImage: MathQnBg,
    bgColor: '#38437b',
    repeat: 'repeat',
    size: 'contain',
  },
};
