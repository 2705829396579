import axios from 'axios';

const PAYMENT_BASE_URL = process.env.REACT_APP_DIRECT_PAYMENT_SERVICE_URL;

export const fetchBundleTypes = (id) => {
  return axios
    .get(`${PAYMENT_BASE_URL}Students/${id}/bundle-types`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const fetchPrices = (id) => {
  return axios
    .get(`${PAYMENT_BASE_URL}Students/${id}/Prices`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response.data;
    });
};
