import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { history } from 'Routes';

import { assignmentText } from 'constants/index';
import useDashboard from 'hooks/useDashboard';

import BackIcon from 'assets/img/assignment/back-icon.svg';

import SMCScoreBoard from './SMCScoreBoard';

import { Container, BackButton } from './PictureType.styles';

const PictureType = ({ type, score, date, correct, noBack }) => {
  const { t } = useTranslation(['startPage']);
  const { isDashboardv2 } = useDashboard();
  return (
    <Container className={type} imgType={type}>
      {!noBack && (
        <div className="back-btn">
          <BackButton
            type={type}
            onClick={() => {
              if (type === 'sundayMC' || type === 'sundayMC2') {
                history.push(
                  isDashboardv2
                    ? '/dashboard?view=koochallenge&hero=2'
                    : '/events'
                );
              } else {
                history.push('/assignment/assignment-list');
              }
            }}
          >
            <img className="back-icon" src={BackIcon} alt="Back" />
            <span className="button-text">
              {t('startPage:words.back', 'Back')}
            </span>
          </BackButton>
        </div>
      )}
      {type !== 'sundayMC' && type !== 'sundayMC2' && (
        <p className="text">
          {t(
            `startPage:words.${assignmentText[type]}`,
            `${assignmentText[type]}`
          )}
        </p>
      )}
      {type === 'sundayMC' && (
        <p className="text">
          <Trans i18nKey="startPage:words.sundayMC">
            <span>Sunday</span>
            <br />
            Mini Challenge
          </Trans>
        </p>
      )}
      {type === 'sundayMC2' && (
        <SMCScoreBoard score={score} date={date} correct={correct} />
      )}
    </Container>
  );
};

export default PictureType;
