import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    flex-direction: column;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width ?? '85%'};
  min-height: 72px;
  border: 1px solid
    ${({ missionPlus }) => (missionPlus ? '#EFDA8E' : '#dbdbdb')};
  background: ${({ isDisabled, missionPlus }) => {
    if (isDisabled) {
      return 'rgb(0,0,0, 0.07)';
    }
    if (missionPlus) {
      return '#FFFFCE';
    }
    return '#fff';
  }};
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-top-right-radius: ${(props) => (props.hideButton ? '10px' : '0px')};
  border-bottom-right-radius: ${(props) => (props.hideButton ? '10px' : '0px')};
  border-right: ${(props) =>
    props.hideButton ? '1px solid #dbdbdb;' : 'none'};
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    border-top-right-radius: 10px;
    border-bottom-left-radius: ${(props) =>
      props.hideButton ? '10px' : '0px'};
    border-right: 1px solid #dbdbdb;
    border-bottom: none;
    border-bottom-right-radius: ${(props) =>
      props.hideButton ? '10px' : '0px'};
    padding: 10px;
  }
`;

export const ButtonSection = styled.div`
  width: ${({ width }) => width || '15%'};
  min-height: 72px;
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px solid
    ${({ missionPlus }) => (missionPlus ? '#EFDA8E' : '#dbdbdb')};
  background: ${({ isDisabled, missionPlus }) => {
    if (isDisabled) {
      return 'rgb(0,0,0, 0.07)';
    }
    if (missionPlus) {
      return '#FFFFCE';
    }
    return '#fff';
  }};
  padding: 10px ${({ hideBorder }) => (hideBorder ? 0 : 10)}px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: ${({ hideBorder, missionPlus }) => {
    if (hideBorder) {
      return 'none';
    }
    if (missionPlus) {
      return '1px solid #EFDA8E';
    }
    return '1px solid #dbdbdb';
  }};
  ${({ hideBorder }) => {
    if (hideBorder) {
      return css`
        ::after {
          content: '';
          position: absolute;
          top: 0;
          left: -3px;
          width: 3px;
          height: 100%;
          background-color: #fff;
        }
      `;
    }
  }}
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
  }
`;
