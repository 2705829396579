import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import formatI18nDateTime from 'helpers/formatI18nDateTime';
import { convertToTimezone } from 'pages/Leaderboard/v2/helper';

import { LOADING } from 'pages/Leaderboard/v2/constant';

import Badge from 'assets/img/leaderboard/top-brain/badge.svg';
import TopBrainText from 'assets/img/leaderboard/top-brain/text-top-brain-term.svg';
import MathCP from 'assets/img/sidepanel/CP.png';
import CPWhite from 'assets/img/icon-cp-white.svg';

import BackButton from 'components/Shared/BackButton';
import { ChallengeType } from 'pages/Leaderboard/v2/components';
import TopBrainToggle from '../TopBrainToggle';
import TermDropdown from '../TermDropdown';

import {
  Container,
  ButtonContainer,
  CenterBar,
  SubTitle,
  Rule,
} from './HeaderMathTB.styles';

const HeaderMathTB = ({ term, setTerm }) => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const history = useHistory();
  const { isLoading } = useSelector((state) => state.leaderboard.terms);

  return (
    <Container>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(209, 122, 20, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard?subject=math')}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <CenterBar>
        <div className="term-images">
          <img height="98%" src={Badge} alt="badge" className="badge-img" />
        </div>
        <div className="middle-content">
          <div className="title">
            <img src={TopBrainText} alt="Top Brain Award" />
            <TopBrainToggle />
          </div>
          <div className="scoring-period">
            <div className="text">
              <img src={MathCP} alt="cp-icon" className="cp-icon" />
              <p>
                {t(
                  'leaderboard:topBrainBanner.scoringPeriod',
                  'Scoring Period'
                )}
                :{' '}
                {isLoading ? (
                  LOADING
                ) : (
                  <span>
                    {' '}
                    {t(
                      'leaderboard:topBrainBanner.term',
                      { number: term?.termId ?? '' },
                      `Term ${term?.termId}`
                    )}
                    ,{' '}
                    {t('leaderboard:topBrainBanner.dateRange', {
                      week: `${term?.startWeek} - ${term?.endWeek}`,
                      start: convertToTimezone(term?.startDate).format(
                        formatI18nDateTime({
                          language: i18n.language,
                          format: 'DD-MMM',
                          dateOnly: true,
                        })
                      ),
                      end: convertToTimezone(term?.endDate).format(
                        formatI18nDateTime({
                          language: i18n.language,
                          format: 'DD-MMM',
                          dateOnly: true,
                        })
                      ),
                    })}
                  </span>
                )}
              </p>
            </div>
            <TermDropdown setCurrentTerm={setTerm} currentTerm={term} />
          </div>
          <SubTitle>
            <div>
              <Trans i18nKey="leaderboard:topBrainBanner.finalResult">
                <img src={CPWhite} alt="cp" /> and Ranking finalised one day
                after the term ends.
              </Trans>
            </div>
            <a
              href={`${process.env.REACT_APP_OFFICIAL_WEB_URL}awards/topbrain/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Rule>{t('leaderboard:topBrainBanner.rules', 'Rules')}</Rule>
            </a>
          </SubTitle>
        </div>
      </CenterBar>
      <ChallengeType styles={{ top: '12%' }} />
    </Container>
  );
};

export default HeaderMathTB;
