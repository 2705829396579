import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
import BackButton from 'components/Shared/BackButton';
import KokoCredits from 'assets/img/icon-koko-credits-shadow.svg';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { KOOQUIZ_PAPERS } from 'constants/kooQuiz';
import PaperTypeTab from '../PaperTypeTab';
import { Container, KokoCredit } from './MainPageTopbar.styles';

const MainPageTopbar = ({
  kooQuizSelectedPaper,
  setSelectedPaper,
  clickHandle,
  selectedSubject,
}) => {
  const { t } = useTranslation(['kooQuizBookShelf', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { rewards, error } = useSelector((state) => state.rewards);

  const paperOptions = KOOQUIZ_PAPERS.filter((item) =>
    item.subjects.includes(selectedSubject.toLowerCase())
  );

  return (
    <Container>
      <BackButton
        backText={t('kooQuizBookShelf:header.back', 'Back')}
        btnBgColor="#346FC0"
        padding="0.2rem 1.2rem"
        styles={{ position: 'absolute', left: '3%' }}
        onClick={() => history.replace('/dashboard?view=kootraining')}
      />
      <p className="kooquiz">
        {t('kooQuizBookShelf:header.kooQuiz', 'KooQuiz')}
      </p>
      <PaperTypeTab
        status={kooQuizSelectedPaper}
        setStatus={setSelectedPaper}
        tabs={paperOptions}
        clickHandle={clickHandle}
      />
      <KokoCredit data-cy="koko">
        <img src={KokoCredits} alt="koko-credits" />
        {!isNil(error) ? (
          <BackButton
            noIcon
            backText={t('common:reload', 'Reload')}
            btnBgColor="rgb(94,210,255, 0.54)"
            padding="0.2rem 1.2rem"
            onClick={() => dispatch(getRewards())}
            data-cy="reload-btn"
          />
        ) : (
          rewards?.TotalKoko
        )}
      </KokoCredit>
    </Container>
  );
};

export default MainPageTopbar;
