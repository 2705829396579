import styled from 'styled-components';

// the container
export const Container = styled.div`
  .container-checkbox {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  /* //hide the browser's default check-box */
  .container-checkbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 10;
    margin-top: 2px;
    width: 15px;
    height: 15px;
  }
  /* //create a custom checkbox */
  .container-checkbox .checkmark {
    height: 20px;
    width: 20px;
    border-radius: 1px;
    border: 1px solid #9e9e9e;
    background-color: white;
    position: relative;
    left: -15px;
  }
  /* //on mouse hover */
  .container-checkbox:hover input ~ .checkmark {
    background-color: #9f9f9f;
    border-color: #9f9f9f;
    cursor: pointer;
  }
  /* //when checkbox is checked */
  .container-checkbox input:checked ~ .checkmark {
    background-color: ${(props) => (props.color ? props.color : '#646464')};
    border-color: ${(props) => (props.color ? props.color : '#707070')};
  }
  /* //create the checkmark/indicator (hidden when not checked) */
  .container-checkbox .checkmark:after {
    content: '';
    display: none;
    margin-left: 5px;
  }
  /* //show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  /* //style the checkmark/indicator */
  .container-checkbox .checkmark:after {
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .check-box {
    position: inherit;
  }
  label {
    margin-bottom: 0px;
    margin-left: -10px;
    margin-top: -2px;
  }
`;
