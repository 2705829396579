import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { compareValues } from 'helpers/compareValue';

import checkProductSubscription from 'helpers/Intermediate/checkProductSubscription';
import useDashboard from 'hooks/useDashboard';

import { Button, BackButton, Spinner, QnViewHeader } from 'components/Shared';
import { BRAIN_GAME_LIST } from './constants';

import BgIntermediate from './imgs/brain-game-background.png';
import BGText from './imgs/brain-games-text.png';
import PremiumPlanIcon from './imgs/premium-plan-icon.png';
import SchoolPlanIcon from './imgs/school-plan-icon.png';

import {
  Container,
  ProductItem,
  ProductListContainer,
  StyledHeader,
} from './IntermediatePage.styles';

const IntermediatePage = () => {
  const { t } = useTranslation(['brainGamesIntermediate', 'common']);
  const location = useLocation();
  const history = useHistory();
  const { isLoading, subject, products } = useSelector((state) => state.plan);
  const { isDashboardv2 } = useDashboard();
  const isFromShortCut = location.state?.quickAccess;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getList = () => {
      if (isNil(subject) || isNil(products)) return;
      // eslint-disable-next-line prefer-const
      let arrayList = [];
      products.forEach((product) => {
        const obj = checkProductSubscription(product, 'BrainGames');
        if (isNil(obj)) return;
        if (obj.parameters?.IsEnabled !== true) return;
        const itemIndex = BRAIN_GAME_LIST.findIndex(
          (item) => item.subject === product.subject
        );
        if (isNil(itemIndex)) return;
        if (BRAIN_GAME_LIST[itemIndex].subject.toLowerCase() === subject) {
          arrayList = [BRAIN_GAME_LIST[itemIndex], ...arrayList];
        } else {
          arrayList = [...arrayList, BRAIN_GAME_LIST[itemIndex]];
        }
      });
      setOptions(arrayList.sort(compareValues('id', 'asc')));
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, products, t]);

  const renderDetails = () => {
    if (isLoading) {
      return <Spinner animation="border" style={{ marginTop: '25px' }} />;
    }

    return (
      <ProductListContainer>
        {options.map((option) => {
          const lowerSubject = option.subject.toLowerCase();
          return (
            <ProductItem key={option.id}>
              <img
                className="left-content"
                src={option.imageUrl}
                alt={option.subject}
                width="208px"
                height="204px"
              />
              <div className="mid-content">
                <h4 className="open-title">
                  {t(
                    `brainGamesIntermediate:${lowerSubject}`,
                    `Brain Games - ${option.subject}`
                  )}
                </h4>
                <p className="open-hour-label">
                  {t('brainGamesIntermediate:openHours', 'Open Hours:')}
                </p>
                <div className="open-hour">
                  <p className="open-duration">
                    {t(
                      'brainGamesIntermediate:premiumDuration',
                      '8am to 8pm, Daily'
                    )}
                  </p>
                  <span className="open-plan">
                    ( <img src={PremiumPlanIcon} alt="premium-plan" />
                    {` ${t(
                      'brainGamesIntermediate:premiumPlan',
                      'Premium Plan'
                    )} `}
                    )
                  </span>
                </div>
                {!isNil(option.schoolDuration) && (
                  <div className="open-hour">
                    <p className="open-duration">
                      {t(
                        'brainGamesIntermediate:math.schoolDuration',
                        '8am to 6pm, Daily'
                      )}
                    </p>
                    <span className="open-plan">
                      ( <img src={SchoolPlanIcon} alt="school-plan" />{' '}
                      {t('brainGamesIntermediate:standardPlan', 'School Plan')}{' '}
                      )
                    </span>
                  </div>
                )}
              </div>
              <div className="right-content">
                <Button
                  variant="primary"
                  width="200px"
                  onClick={() => history.push(option.redirectUrl)}
                >
                  {t('brainGamesIntermediate:play', 'Play')}
                </Button>
              </div>
            </ProductItem>
          );
        })}
      </ProductListContainer>
    );
  };

  return (
    <Container>
      <StyledHeader>
        <QnViewHeader page bgColor="#000" headerBg={BgIntermediate}>
          <BackButton
            backText={t('common:back', 'Back')}
            btnBgColor="#0087CE"
            padding="5px 20px"
            onClick={() => {
              if (isFromShortCut) {
                history.goBack();
                return;
              }
              const destination = isDashboardv2
                ? '/dashboard?view=koofun'
                : '/dashboard';
              history.push(destination);
            }}
            styles={{ position: 'absolute', left: '3%' }}
          />
          <img
            className="bg-text"
            src={BGText}
            alt="brain-game"
            width="208px"
          />
        </QnViewHeader>
      </StyledHeader>
      {renderDetails()}
    </Container>
  );
};

export default IntermediatePage;
