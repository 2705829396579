import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';
import { subjectToPlanMapping, featureToggle } from 'constants/index';
import MobileToggleFeature from 'components/Shared/MobileToggleFeature';
import {
  SidePanel,
  DailyChallengeCard,
  DashboardCard,
  SwitchToTeacher,
  StudentDetails,
} from './components';
import { Content, Main, CardList, CardShowWarp } from '../Dashboard.styles';

const DashboardV1 = ({ isTeacherStudent }) => {
  const { plan, subject } = useSelector((state) => state.plan);
  const { cards } = subjectToPlanMapping(subject, plan);
  const isDesktop = useMediaQuery({ minWidth: rwd.tablet });
  const isTablet = useMediaQuery({
    minWidth: rwd.mobile,
    maxWidth: rwd.tablet,
  });

  return (
    <Content
      plan={plan}
      data-cy="dashboard"
      subject={subject}
      globeImg={subjectToPlanMapping(subject, plan).globeImg}
    >
      {isDesktop && (
        <>
          <SidePanel type="left" />
          <SidePanel type="right" />
        </>
      )}
      <Main>
        <StudentDetails />
        {!isDesktop && <SidePanel type="right" />}
        <CardShowWarp
          show={subjectToPlanMapping(subject, plan).cardLayoutType === 1}
        >
          <DailyChallengeCard isFeatureReady={featureToggle.dailyChallenge} />
        </CardShowWarp>
        <CardShowWarp
          show={subjectToPlanMapping(subject, plan).cardLayoutType === 1}
        >
          <CardList>
            {cards.map((card) => (
              <DashboardCard
                key={card.name}
                name={card.name}
                background={card.bg}
                description={card.description}
                link={card.link}
                isFeatureReady={card.isFeatureReady}
              />
            ))}
            {/* {isMobile && <SidePanel type="left" />} */}
          </CardList>
        </CardShowWarp>
        {isTablet && <SidePanel type="left" overPhoneSize />}
      </Main>
      {isTeacherStudent && <SwitchToTeacher />}

      <MobileToggleFeature plan="B2B" />
    </Content>
  );
};

export default DashboardV1;
