import MasteryIcon from 'assets/img/mission/mastery.png';
import CompetentIcon from 'assets/img/mission/competent.png';
import BeginningIcon from 'assets/img/mission/beginning.png';
import IncompleteIcon from 'assets/img/mission/incomplete.png';
import GradeAIcon from 'assets/img/mission/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/mission/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/mission/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/mission/icon-report-high-score-d.svg';

import {
  MathCurrListHeader,
  ScienceCurrListHeader,
  EnglishCurrListHeader,
  ScienceMissionPlusCard,
  EnglishMissionPlusCard,
  SchoolCard,
  HotsCard,
} from 'assets/img/mission';

export const ProficiencyIcons = {
  mastery: MasteryIcon,
  competent: CompetentIcon,
  beginning: BeginningIcon,
  incomplete: IncompleteIcon,
};

export const HighScoreIcons = {
  A: GradeAIcon,
  B: GradeBIcon,
  C: GradeCIcon,
  D: GradeDIcon,
  Incomplete: null,
};

export const MasteryLevel = (level) => {
  if (level >= 3) {
    return 'A';
  }
  if (level === 2) {
    return 'B';
  }
  if (level === 1) {
    return 'C';
  }
  if (level === 0) {
    return 'D';
  }
  return 'Incomplete';
};

export const HighscoreStars = (level) => {
  switch (level) {
    case 'A':
      return 3;
    case 'B':
      return 2;
    case 'C':
      return 1;
    case 'D':
      return 0;
    default:
      return 0;
  }
};

export const CompetencyLevel = {
  0: 'incomplete',
  1: 'beginning',
  2: 'developing',
  3: 'competent',
  4: 'mastery',
};

export const ProgressStatus = {
  0: 'not-started',
  1: 'completed',
  2: 'in-progress',
};

export const NewHOTsCurriculumID = 92;

export const MISSION_B2B = [
  {
    id: 1,
    subject: 'math',
  },
  {
    id: 2,
    subject: 'science',
  },
  {
    id: 3,
    subject: 'english',
  },
];

export const MISSION_STYLES = {
  premium: {
    math: {
      backBtn: 'rgba(66,49,219,0.6)',
      listHeaderColor: 'linear-gradient(180deg, #8456ff, #475df3)',
      listHeaderImg: MathCurrListHeader,
    },
    science: {
      backBtn: 'rgba(0,181,146,0.8)',
      listHeaderColor: 'linear-gradient(180deg, #08CCAC, #1BDD7E)',
      listHeaderImg: ScienceCurrListHeader,
    },
    english: {
      backBtn: 'rgba(293,156,0,0.62)',
      listHeaderColor: 'linear-gradient(90deg, #FFC100, #F5A000)',
      listHeaderImg: EnglishCurrListHeader,
    },
  },
  school: {
    math: {
      backBtn: 'rgba(66,49,219,0.6)',
      listHeaderColor: 'linear-gradient(180deg, #8456ff, #475df3)',
      listHeaderImg: MathCurrListHeader,
    },
    science: {
      backBtn: 'rgba(66,49,219,0.6)',
      listHeaderColor: 'linear-gradient(180deg, #8456ff, #475df3)',
      listHeaderImg: MathCurrListHeader,
    },
    english: {
      backBtn: 'rgba(66,49,219,0.6)',
      listHeaderColor: 'linear-gradient(180deg, #8456ff, #475df3)',
      listHeaderImg: MathCurrListHeader,
    },
  },
};

export const MISSION_CARDS = {
  missionPlus: {
    science: ScienceMissionPlusCard,
    english: EnglishMissionPlusCard,
  },
  school: SchoolCard,
  hots: HotsCard,
};
