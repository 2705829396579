import styled from 'styled-components';
import { KOOQUIZ_STYLES } from 'constants/kooQuiz';
import { subjectIDs, SUBJECTS } from 'constants/products';

export const Page = styled.div`
  overflow: hidden;
  visibility: visible;
`;

export const OnlineOfflineContainer = styled.div`
  padding-top: 59px;
  background: #f5f5f5;
  min-height: 100vh;
`;

export const OnlineOfflineContentContainer = styled.div`
  padding-bottom: 74px;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  position: relative;

  .gift-box {
    position: absolute;
    width: 140px;
    left: 0;
    bottom: 15px;
    z-index: 3;
  }
`;

export const ScoreHeading = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  color: #30a8bb;
  text-transform: uppercase;
`;

export const Score = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  & .score-per {
    color: #48b500;
    font-size: 29px;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 7px;
    font-family: Pacifico;

    & > span {
      font-size: 47px;
      margin-bottom: 0px;
      font-weight: 300;
    }

    & > div {
      position: relative;
      top: 13px;
    }
  }

  & > div {
    color: #95c9d2;
    font-size: 14px;
    white-space: nowrap;
  }

  & > div > span {
    font-weight: 700;
  }
`;

export const ScoreFooter = styled.div`
  color: rgba(255, 255, 225, 0.68);
`;

export const StyledPaper = styled.div`
  position: relative;
  min-width: 296px;
  img {
    width: 318px;
    position: absolute;
    bottom: 59px;
    left: ${(p) => (p.onlineOffline ? '23px' : '0px')};
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    height: 71px;
    background: linear-gradient(
      180deg,
      ${({ subject }) => KOOQUIZ_STYLES[subject]?.assetColor},
      rgba(255, 255, 255, 0) 100%
    );
    bottom: 0;
    left: ${({ paperType }) => paperType.paperAlignment.preview.shadowLeft};
    width: ${({ paperType }) => paperType.paperAlignment.preview.shadowWidth};
    ${({ onlineOffline }) =>
      onlineOffline && {
        width: '235px',
      }};
  }
  .hots-questions-text {
    color: #fff;
    position: absolute;
    bottom: 15px;
    z-index: 2;
    left: 50px;
    font-size: 1rem;
  }
`;

export const StyledBackground = styled.div`
  background-color: ${({ subject }) => KOOQUIZ_STYLES[subject]?.bgColor};
  background-image: url(${({ subject }) =>
    `${KOOQUIZ_STYLES[subject]?.bgImg}`});
  background-repeat: repeat;
  padding: 1rem 2.3rem 0;
  box-shadow: 1px -74px 0px -1px
    ${({ subject }) => KOOQUIZ_STYLES[subject]?.boxShadow} inset;
  min-height: 445px;
`;
export const StyledContent = styled.div`
  margin: 0 auto;
  max-width: 880px;
  display: flex;
  padding-top: 44px;
  justify-content: center;
`;
export const StyledPencil = styled.div`
  padding-top: 132px;
  padding-bottom: 55px;
  margin-right: 1rem;
  position: relative;
  img {
    z-index: 2;
    position: relative;
    bottom: 12px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    height: 72px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      ${({ subject }) => KOOQUIZ_STYLES[subject]?.assetColor},
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const HOTSContainer = styled.div`
  padding-bottom: 74px;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  position: relative;

  .gift-box {
    position: absolute;
    width: 140px;
    left: 0;
    bottom: 15px;
    z-index: 3;
  }
`;

export const HOTSCard = styled.div`
  width: ${(p) => p.width};
  height: 280px;
  font-size: 16px;
  position: relative;
  bottom: -1px;

  &::after {
    content: '';
    position: absolute;
    bottom: -72px;
    left: 0;
    right: 0;
    display: block;
    height: 73px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      ${({ subject }) => KOOQUIZ_STYLES[subject]?.assetColor},
      rgba(255, 255, 255, 0) 100%
    );
  }

  .hots-header {
    background-color: rgba(29, 29, 29, 0.7);
    text-align: center;
    color: #ffffff;
    height: 42px;
    line-height: 42px;
    font-weight: bold;
    font-size: 18px;
  }

  .hots-content {
    background-color: rgba(29, 29, 29, 0.35);
    height: calc(100% - 43px);
    padding: ${({ subject }) =>
      subjectIDs[subject] === SUBJECTS.MATH ? '24px 24px' : '24px 19px'};
    color: #ffffff;
    h6 {
      line-height: 1.4;
      p {
        margin-bottom: 0;
        display: inline;
        color: #ffeb00;
        font-weight: 700;
      }
    }
  }

  .hots-score-content {
    background-color: rgba(29, 29, 29, 0.35);
    height: calc(100% - 43px);
    padding: 36px 66px;
    color: #ffffff;
  }

  .hots-heightlight {
    color: #ffeb00;
  }

  .hots-print {
    background-color: #1aa5ff;
    color: #fff;
    width: 225px;
    height: 60px;
    text-align: center;
    border-radius: 50px;
    position: relative;
    font-weight: bold;
    font-size: 24px;
    border: 0;

    & > img {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .hots-items-center {
    display: flex;
    align-items: center;
  }

  .hots-font-bold {
    font-weight: 700;
  }

  .hots- @media (max-width: 1024px) {
    max-width: 290px;

    .hots-score-content {
      padding: 27px 43px;
    }
  }
`;
