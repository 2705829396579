// challenge cards
import SVClose from 'assets/img/dailychallenge/super-vision-close.png';
import SSClose from 'assets/img/dailychallenge/super-speed-close.png';
import KooCards from 'assets/img/superhero/parentChallenge/koo-cards.png';
import SpellingBits from 'assets/img/superhero/parentChallenge/spelling-bits.png';
import { subjectNames, SUBJECTS } from 'constants/products';
import DCClose from './imgs/dc-close.svg';
import DCOpen from './imgs/dc-open.jpg';
import SVOpen from './imgs/super-vision.png';
import SSOpen from './imgs/super-speed.png';
import ScienceDCOpen from './imgs/science-dc-open.png';
import ScienceDCClosed from './imgs/science-dc-closed.png';
import EnglishDCOpen from './imgs/english-dc-open.png';
import EnglishDCClosed from './imgs/english-dc-closed.png';

export const dailyChallengeList = {
  challenge: [
    {
      id: 1,
      name: 'Daily Challenge - Math',
      nameTranslation: 'DC',
      imgOpen: DCOpen,
      imgClose: DCClose,
      desc: '10 personalized questions per day',
      schedule: '6am to 10pm, Everyday',
      scheduleTranslation: 'schedule',
      type: 'daily-challenge',
      link: '/challenges/daily-challenge',
      subjectID: subjectNames[SUBJECTS.MATH],
      totalQns: 10,
      cpAmt: 17,
    },
    {
      id: 4,
      name: 'Daily Challenge - Science',
      nameTranslation: 'ScienceDC',
      imgOpen: ScienceDCOpen,
      imgClose: ScienceDCClosed,
      desc: '5 personalized questions per day',
      schedule: '6am to 10pm, Everyday',
      scheduleTranslation: 'schedule',
      type: 'daily-challenge',
      link: '/challenges/science',
      subjectID: subjectNames[SUBJECTS.SCIENCE],
      totalQns: 5,
      cpAmt: 9,
    },
    {
      id: 5,
      name: 'Daily Challenge - English',
      nameTranslation: 'EnglishDC',
      imgOpen: EnglishDCOpen,
      imgClose: EnglishDCClosed,
      desc: '10 personalized questions per day',
      schedule: '6am to 10pm, Everyday',
      scheduleTranslation: 'schedule',
      type: 'daily-challenge',
      link: '/challenges/daily-challenge',
      subjectID: subjectNames[SUBJECTS.ENGLISH],
      totalQns: 10,
      cpAmt: 17,
    },
    {
      id: 2,
      name: 'Super Vision Challenge',
      nameTranslation: 'SV',
      imgOpen: SVOpen,
      imgClose: SVClose,
      desc: '',
      schedule: '6am to 10pm, Monday to Saturday',
      scheduleTranslation: 'schedule',
      type: 'super-vision-challenge',
      link: '/superhero/supervision',
    },
    {
      id: 3,
      name: 'Super Speed Challenge',
      nameTranslation: 'SS',
      imgOpen: SSOpen,
      imgClose: SSClose,
      desc: '',
      scheduleTranslation: 'schedule',
      schedule: '6am to 10pm, Monday to Saturday',
      type: 'super-speed-challenge',
      link: '/superhero/superspeed',
    },
  ],
  parentAppChallenge: [
    {
      id: 1,
      subjectID: subjectNames[SUBJECTS.ENGLISH],
      img: KooCards,
      nameTranslation: 'kooCards',
      title: 'KooCards',
      desc: 'Visual Vocabulary',
      videoUrl: '-i_TyKHiYh0',
    },
    {
      id: 2,
      subjectID: subjectNames[SUBJECTS.ENGLISH],
      img: SpellingBits,
      nameTranslation: 'sb',
      title: 'Spelling Bits',
      desc: 'Learn, Practise, Test',
      videoUrl: 'ykwaP3wj80s',
    },
  ],
};
