import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { equals, isEmpty, isNil, sort } from 'ramda';
import moment from 'moment';

import { subjectIDs } from 'constants/products';

import { fetchWrapper } from 'services/login';
import { fetchBundleTypes, fetchPrices } from 'services/payment';

import { ReactComponent as IconClose } from 'assets/img/trial/icon-close.svg';

import { CheckBox, Spinner, FaqButton } from 'components/Shared';
import { DISCOUNT_TYPE, DISCOUNT_TYPE_NAME, ICONS } from './constants';
import Bundles from './Bundles';
import ErrorDisplay from './ErrorDisplay';
import Summary from './Summary';

import {
  StyledContainer,
  StyledSelection,
  StyledSubject,
  StyledResult,
  StyledFloatBtn,
} from './TrialSelector.styles';

const TrialSelector = ({
  onClose,
  userId,
  campaignCode,
  isPopup = true,
  ...props
}) => {
  const { t } = useTranslation(['trial', 'common']);

  const [subjectList, setSubjectList] = useState([]);
  const [bundleList, setBundleList] = useState([]);
  const [isB2B, setIsB2B] = useState(false);
  const [hasExistingSubject, setHasExistingSubject] = useState(false);
  const [bundleLoading, setBundleLoading] = useState(false);
  const [priceList, setPriceList] = useState([]);
  const [priceLoading, setPriceLoading] = useState(false);
  const [moreThanOneSingleSubjects, setMoreThanOneSingleSubjects] =
    useState(false);
  const [isAllSubjectsActive, setIsAllSubjectsActive] = useState(false);
  const [existingSubjects, setExistingSubjects] = useState([]);
  const subjectIdOrder = (a, b) => a - b;

  const currentSelectedSubjects = useCallback(() => {
    return (
      subjectList.filter((item) => item.isSelected).map((a) => a.subjectId) ??
      []
    );
  }, [subjectList]);

  const currentPricing = useCallback(() => {
    const result =
      priceList?.find((price) =>
        equals(
          sort(subjectIdOrder, price.subjectIds),
          sort(subjectIdOrder, currentSelectedSubjects())
        )
      ) ?? null;
    return result;
  }, [currentSelectedSubjects, priceList]);

  const getBundlesList = useCallback(async () => {
    setBundleLoading(true);
    try {
      const bundles = await fetchWrapper(fetchBundleTypes, userId);
      const result = bundles?.data;
      const subjects = result?.availableSubjects?.map((subject) => ({
        subject: subjectIDs[subject.subjectId]?.toLowerCase(),
        subjectId: subject?.subjectId,
        isSelected: subject?.hasActiveSubscription,
        hasActiveSubscription: subject?.hasActiveSubscription,
      }));
      const activeSubsExist = !isNil(
        subjects?.find((item) => item.hasActiveSubscription)
      );
      const allActive =
        subjects.every((item) => item.hasActiveSubscription) &&
        !result?.doesUserHasMoreThanOneSingleSubjectSubscription;
      const existSubjects = subjects
        ?.filter((item) => item.hasActiveSubscription)
        .map((sub) => sub.subjectId);

      setSubjectList(subjects);
      setBundleList(result?.availableBundleTypes);
      setIsB2B(result?.isB2BUser);
      setMoreThanOneSingleSubjects(
        result?.doesUserHasMoreThanOneSingleSubjectSubscription
      );
      setHasExistingSubject(activeSubsExist);
      setIsAllSubjectsActive(allActive);
      setExistingSubjects(existSubjects);
    } catch (e) {
      console.error(e);
    } finally {
      setBundleLoading(false);
    }
  }, [userId]);
  const getPricing = useCallback(async () => {
    setPriceLoading(true);
    try {
      const prices = await fetchWrapper(fetchPrices, userId);
      const filteredPrices =
        moreThanOneSingleSubjects && hasExistingSubject
          ? prices.data
          : prices.data.filter(
              (item) =>
                !equals(
                  sort(subjectIdOrder, item.subjectIds),
                  sort(subjectIdOrder, existingSubjects)
                )
            );
      setPriceList(
        filteredPrices?.map((datum) => ({
          ...datum,
          subjectIds:
            datum.subjectIds?.length > 1
              ? [...datum.subjectIds].sort((a, b) => a - b)
              : datum.subjectIds,
        }))
      );
    } catch (error) {
      console.log({ error }, 'price');
    } finally {
      setPriceLoading(false);
    }
  }, [existingSubjects, hasExistingSubject, moreThanOneSingleSubjects, userId]);
  useEffect(() => {
    if (userId) {
      getBundlesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBundlesList]);
  useEffect(() => {
    if (moreThanOneSingleSubjects && hasExistingSubject) {
      getPricing();
    }
  }, [moreThanOneSingleSubjects, hasExistingSubject, getPricing]);
  const handleSubmit = () => {
    window.location.href = `${
      process.env.REACT_APP_PAYMENT_V2_URL
    }pay/?campaignCode=${campaignCode}&userId=${userId}&bundleId=${
      currentPricing()?.bundleId
    }`;
  };

  const handleClick = async (item) => {
    if (isEmpty(priceList)) {
      getPricing();
    }
    if (!item.hasActiveSubscription) {
      const result = subjectList.map((subject) => ({
        ...subject,
        isSelected:
          subject.subjectId === item.subjectId
            ? !subject.isSelected
            : subject.isSelected,
      }));
      setSubjectList(result);
    }
  };

  const renderSelectTitle = () => {
    if (priceLoading) {
      return <div className="skeleton-container" />;
    }

    if (currentPricing()) {
      return (
        <div>
          <p className="new-label">
            {t('trial:selector.newLabel', 'Your new subscription')}
          </p>
          <p className="title">{currentPricing()?.bundleName}</p>
          <p className="desc-not-selected">
            {t(
              'trial:selector.date',
              {
                startDate: moment(
                  currentPricing()?.newSubscriptionStartDate
                ).format('DD MMM YYYY'),
                endDate: moment(
                  currentPricing()?.newSubscriptionEndDate
                ).format('DD MMM YYYY'),
              },
              `Active from ${moment(
                currentPricing()?.newSubscriptionStartDate
              ).format('DD MMM YYYY')} to ${moment(
                currentPricing()?.newSubscriptionEndDate
              ).format('DD MMM YYYY')}`
            )}
          </p>
        </div>
      );
    }
    return (
      <>
        {hasExistingSubject ? (
          <>
            <p className="title">
              {t('trial:addSubject', 'Add subject(s) to your subscription')}
            </p>
            <p className="desc-not-selected">
              {t(
                'trial:selector.placeholder2',
                'The value of your unused subscription will be used to offset the new subscription price.'
              )}
            </p>
          </>
        ) : (
          <>
            <p className="title">{t('trial:choose', 'Choose Your Subjects')}</p>
            <p className="desc-not-selected">
              {t(
                'trial:selector.placeholder',
                'Select all subjects to enjoy the best price'
              )}
            </p>
          </>
        )}
      </>
    );
  };

  const renderResult = () => {
    if (bundleLoading || priceLoading) {
      return <Spinner small position="relative" />;
    }
    if (!isNil(currentPricing())) {
      const currentPrice = currentPricing();
      let everGreenDiscount = null;
      let upgradePrice = null;
      let b2bCoupon = null;

      currentPrice.discountItems.forEach((item) => {
        if (DISCOUNT_TYPE[item.typeId] === DISCOUNT_TYPE_NAME.EVERGREEN) {
          everGreenDiscount = item;
        }
        if (DISCOUNT_TYPE[item.typeId] === DISCOUNT_TYPE_NAME.PRORATION) {
          upgradePrice = item;
        }
        if (DISCOUNT_TYPE[item.typeId] === DISCOUNT_TYPE_NAME.B2B_COUPON) {
          b2bCoupon = item;
        }
      });

      if (currentPrice.isPaymentAmountAboveThreshold) {
        return (
          <Summary
            data={currentPrice}
            submitHandler={handleSubmit}
            noOfSubject={currentPrice.subjectIds.length}
            everGreenDiscount={everGreenDiscount}
            b2bCoupon={b2bCoupon}
            remainingAmount={upgradePrice}
            hasTrial={!isNil(props.hasTrial)}
          />
        );
      }
      return <ErrorDisplay />;
    }
    const hasActiveSubs = subjectList.filter(
      (item) => item.hasActiveSubscription
    );
    return (
      <Bundles
        bundleList={bundleList}
        hasActiveSubs={hasActiveSubs}
        isPopup={isPopup}
        isB2B={isB2B}
      />
    );
  };

  if (isAllSubjectsActive) {
    return null;
  }

  return (
    <StyledContainer isLoading={bundleLoading} {...props} isPopup={isPopup}>
      {onClose && <IconClose className="close-trial" onClick={onClose} />}
      {bundleLoading && <Spinner small position="relative" />}
      {!bundleLoading && !isEmpty(subjectList) && (
        <>
          <StyledSelection>
            {renderSelectTitle()}
            <StyledSubject>
              {subjectList.map((item) => (
                <StyledSubject.Item
                  key={item.subjectId}
                  subject={subjectIDs[item.subjectId].toLowerCase()}
                  onClick={() => handleClick(item)}
                  checked={item.isSelected}
                  disabled={item.hasActiveSubscription}
                >
                  <div className="top">
                    <CheckBox
                      className="checkbox-container"
                      color="#FF5C02"
                      checked={item.isSelected}
                      disabled={item.hasActiveSubscription}
                      onChange={() => {}}
                    />
                    {item.hasActiveSubscription && (
                      <span className="existing">
                        {t('trial:selector.existing', 'Existing')}
                      </span>
                    )}
                  </div>
                  <div className="bottom">
                    <p className="title">
                      {t(
                        `common:subjects.${subjectIDs[
                          item.subjectId
                        ].toLowerCase()}`,
                        subjectIDs[item.subjectId]
                      )}
                    </p>
                    <p className="subject-item-desc">
                      {t('common:plans.premium', 'Premium')}
                    </p>
                    {!isNil(currentPricing()) && item.hasActiveSubscription && (
                      <div className="extend">Extended Access</div>
                    )}
                    <img
                      src={ICONS[subjectIDs[item.subjectId].toLowerCase()]}
                      alt={subjectIDs[item.subjectId]}
                    />
                  </div>
                </StyledSubject.Item>
              ))}
            </StyledSubject>
          </StyledSelection>
          <StyledResult
            isResult={currentPricing()?.topUpAmount > 0}
            isPopup={isPopup}
          >
            {renderResult()}
          </StyledResult>
        </>
      )}
      <StyledFloatBtn isPopup={isPopup}>
        <FaqButton isPopup={isPopup} />
      </StyledFloatBtn>
    </StyledContainer>
  );
};

export default TrialSelector;
