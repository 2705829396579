import { PRODUCT_TYPE } from 'constants/products';
// Helper for getting school name for display
const getSchoolName = ({
  hasPremiumSchool,
  planToShow,
  allNonExpiredSubscriptionProducts,
}) => {
  if (hasPremiumSchool) {
    if (allNonExpiredSubscriptionProducts.length <= 0) return '';
    const schoolPlanIndex = allNonExpiredSubscriptionProducts.findIndex(
      (plan) => plan.productType.toLowerCase().trim() === PRODUCT_TYPE.SCHOOL
    );
    // PlanId === 4 is for Math olympiad
    const premiumPlanIndex = allNonExpiredSubscriptionProducts.findIndex(
      (plan) => plan.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM
    );
    if (planToShow === PRODUCT_TYPE.SCHOOL && schoolPlanIndex !== -1) {
      return allNonExpiredSubscriptionProducts[schoolPlanIndex]
        .subscriptionDetails?.Schoolname;
    }
    if (planToShow === PRODUCT_TYPE.PREMIUM && premiumPlanIndex !== -1) {
      return allNonExpiredSubscriptionProducts[premiumPlanIndex]
        .subscriptionDetails?.Schoolname;
    }
  }
  return (
    allNonExpiredSubscriptionProducts?.[0]?.subscriptionDetails?.Schoolname ||
    ''
  );
};

export default getSchoolName;
