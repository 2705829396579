import React from 'react';
import { useTranslation } from 'react-i18next';
import { LimitReachContainer } from './SubmittedContent.styles';

const LimitReach = () => {
  const { t } = useTranslation(['assignmentSubmitted']);

  return (
    <LimitReachContainer>
      <p>
        {t(
          'assignmentSubmitted:limitKoko.Text1',
          'Looks like you have been very diligent in doing your homework, well done!'
        )}
      </p>
      <p>
        {t(
          'assignmentSubmitted:limitKoko.Text2',
          'You have claimed all 50 KoKo by completing your homework this month and there will be no KoKo awarded for this homework'
        )}
      </p>
    </LimitReachContainer>
  );
};
export default LimitReach;
