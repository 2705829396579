import React from 'react';
import { KoKoMonsterCard, BrainGameCard, StoryCard } from './components';
import { Rewards } from '../components';
import {
  StyledContainer,
  StyledCardContainer,
  StyledRewardsSection,
} from './KooFun.styles';

const KooFun = () => {
  return (
    <div>
      <StyledContainer data-cy="koofun-screen">
        <StyledCardContainer>
          <KoKoMonsterCard />
          <StyledCardContainer.Bottom>
            <BrainGameCard />
            <StoryCard />
          </StyledCardContainer.Bottom>
        </StyledCardContainer>
        <StyledRewardsSection>
          <Rewards types={[2]} />
        </StyledRewardsSection>
      </StyledContainer>
    </div>
  );
};
export default KooFun;
