import styled from 'styled-components';
import { Topbar as PeerChallengeTopBar } from '../MultiplayerChallengeList/MultiplayerChallengeList.styles';

export const Topbar = styled(PeerChallengeTopBar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  min-height: 65px;
  .side {
    flex-basis: 20%;
  }
  .middle {
    display: flex;
    gap: 5px;
  }
  .energy-cards {
    align-self: flex-end;
    display: flex;
    align-items: flex-end;
  }
  .incoming-challenge-icon {
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .side.left {
      flex-basis: 15%;
    }
    .side.right {
      flex-basis: 25%;
    }
    .title {
      font-size: 30px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0 20px;
    .side.left {
      flex-basis: 10%;
    }
    .side.right {
      display: none;
    }
    .title {
      font-size: 24px;
    }
    .incoming-challenge-icon {
      width: 45px;
      margin-bottom: 0.5rem;
    }
    .middle {
      flex: 1;
    }
  }
`;

export const EnergyCard = styled.img`
  margin-right: ${(props) => (props.index <= 1 ? '1.5rem' : '0')};

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    margin-right: ${(props) => (props.index <= 1 ? '0.5rem' : '0')};
    width: 55px;
  }
`;

export const Content = styled.div`
  padding: 3rem 2rem 0 2rem;
  text-align: center;
  background: #f9f9f9;
  min-height: calc(100vh - 124px);
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 5rem 38px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 2rem 20px;
  }
`;

export const ChallengesContainer = styled.div`
  padding: 0 6rem;
  .data-list {
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 1.4375rem;
    color: #141313;
    font-weight: 600;
  }
  .or {
    font-size: 2.5625rem;
    font-weight: 600;
    color: #b4b4b4;
    margin-right: 1rem;
  }
  a {
    font-size: 1.125rem;
    color: #ff7121;
    font-weight: 600;
    text-decoration: underline;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    p {
      font-size: 18px;
    }
  }
`;

export const ChallengeList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 40px;
    margin-bottom: 30px;
    gap: 20px;
    width: 100%;
    grid-template-columns: auto auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 40px;
    margin-bottom: 30px;
    gap: 20px;
    width: 100%;
    grid-template-columns: none;
    margin-top: 20px;
  }
`;

export const EmptyChallengeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: 600;
    font-size: 1.75rem;
    color: #141313;
  }
  img {
    margin-bottom: 3rem;
  }
  button {
    width: 334.52px;
    font-size: 1.5625rem;
    font-family: 'Linotte';
    font-weight: 600;
    background: #ff7121;
    color: white;
    border: none;
    border-radius: 2rem;
    padding: 0.5rem 0;
    :hover {
      background: #ff5801;
    }
  }
`;
