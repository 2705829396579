import styled from 'styled-components';

import HistoryImg from 'assets/img/superhero/supervision-scorehist.png';

export const HistoryButton = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
  background: url(${HistoryImg}) no-repeat;
  display: block;
  float: left;
  padding: 10px 0 10px 50px;
  margin-top: 10px;
  margin-left: 10px;
  font-family: Arial;
  font-size: 13px;
  color: #fff1cf;
  border: none;

  &:hover {
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 45px;
  width: 852px;
  height: 570px;
  background-image: ${({ background }) => `url('${background}')`};
`;

export const IntroText = styled.p`
  font-family: Arial;
  margin-top: 180px;
  font-size: 16px;
  color: #000;
  width: 330px;
  line-height: 1;
  text-align: left;
`;

export const Rules = styled.div`
  color: #000 !important;
  margin-top: 50px;
  margin-bottom: 32px;
  text-align: left;
  font-family: Arial !important;
  & > span {
    font-size: 14px !important;
  }
  & > ul {
    padding: 10px 0 0;
    font-size: 12px !important;
    width: 330px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > button:first-child {
    margin-right: 18px;
  }
`;

export const Button = styled.button`
  border: none;
  margin: auto;
  border-radius: 24px;
  color: ${({ textColor }) => textColor};
  display: inline-block;
  height: ${({ small }) => (small ? `34px` : `44px`)};
  line-height: ${({ small }) => (small ? `34px` : `44px`)};
  font-size: 22px;
  padding: ${({ small }) => (small ? `0 1em` : `0 2em`)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: Arial, sans-serif;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ textColorHover, textColor }) => textColorHover || textColor};
    background-color: ${({ backgroundColorHover, backgroundColor }) =>
      backgroundColorHover || backgroundColor};
  }
  &[disabled] {
    background-color: #c3c3c3;
    cursor: not-allowed;
  }
`;
