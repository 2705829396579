import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendEventTracking } from 'helpers/UserEventTracking';
import { getStoryChapterTiles } from 'store/story/storySlice';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { isNil } from 'ramda';
import { SUBJECT_LOGO } from './constants';
import { Arrow } from './components';
import {
  Page,
  Header,
  MenuWrapper,
  HeaderMenu,
  Menu,
  Content,
  Main,
  Sidebar,
  CarouselWrapper,
  Carousel,
  Image,
  ImageWrapper,
  ArrowWrapper,
  SidebarContent,
  SidebarItem,
  SidebarImage,
  SidebarText,
  SpinnerContainer,
} from './StoryViewer.styles';

const StoryViewer = ({ match }) => {
  const { t } = useTranslation(['storyMath']);
  const { id, subjectId } = match.params;
  const history = useHistory();
  const dispatch = useDispatch();
  const { stories, isLoading } = useSelector((state) => state.story);
  const [index, setIndex] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [sidebarHeight, setSidebarHeight] = useState(0);
  const [sidebarThumbHeight, setSidebarThumbHeight] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const { userID, sessionID } = useSelector((state) => state.login);
  const sidebarRef = useRef();

  const getHeight = useCallback(() => {
    const headerHeightNew = document.getElementById('header').clientHeight;
    const sidebarHeightNew = document.getElementById('sidebar').clientHeight;
    const sidebarThumbHeightNew = document.getElementById('sidebar-content')
      .clientHeight;
    setHeaderHeight(headerHeightNew);
    setSidebarHeight(sidebarHeightNew);
    setSidebarThumbHeight(sidebarThumbHeightNew);
  }, []);

  useEffect(() => {
    if (!isNil(stories)) {
      getHeight();
    }
  }, [getHeight, stories]);

  useEffect(() => {
    dispatch(getStoryChapterTiles(id));
  }, [dispatch, id]);

  useEffect(() => {
    const currentMousePosition = mousePosition.y - headerHeight;
    const percentageCurentTop = currentMousePosition / sidebarHeight;
    const sidebarThumbTop = percentageCurentTop * sidebarThumbHeight;
    if (!isNil(stories) && !isLoading) {
      sidebarRef.current.scrollTo({
        top: sidebarThumbTop - currentMousePosition,
        behavior: 'auto',
      });
    }
  }, [
    headerHeight,
    isLoading,
    mousePosition,
    sidebarHeight,
    sidebarThumbHeight,
    stories,
  ]);
  useEffect(() => {
    if (sessionID && userID && id) {
      sendEventTracking('story', 'story_open', {
        sub: subjectId,
        ui: userID,
        si: sessionID,
        sti: id,
      });
    }
  }, [subjectId, sessionID, userID, id]);
  const handleMouseMove = useCallback((position) => {
    setMousePosition({ x: position.clientX, y: position.clientY });
  }, []);

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Page>
      {isLoading && (
        <SpinnerContainer>
          <Spinner animation="border" />
        </SpinnerContainer>
      )}
      <Header id="header">
        <MenuWrapper>
          <HeaderMenu subjectId={subjectId}>
            <img
              src={SUBJECT_LOGO[subjectId].logo}
              alt="storylogo"
              onClick={onBack}
              aria-hidden
            />
            <Menu onClick={onBack} subjectId={Number(subjectId)}>
              {t(
                `storyMath:backTo${SUBJECT_LOGO[subjectId].title}`,
                `Back to ${SUBJECT_LOGO[subjectId].title}`
              )}
            </Menu>
          </HeaderMenu>
        </MenuWrapper>
      </Header>
      {!isLoading && (
        <Content>
          <Main>
            <CarouselWrapper>
              <ArrowWrapper type="left">
                {index !== 0 && (
                  <Arrow
                    disabled={disabled}
                    type="left"
                    onClickArrow={() => setIndex((prevState) => prevState - 1)}
                  />
                )}
              </ArrowWrapper>
              <Carousel
                onTransitionStart={() => setDisabled(true)}
                onTransitionEnd={() => setDisabled(false)}
                selected={index}
                bullets={false}
                infinite={false}
                buttons={false}
                organicArrows={false}
              >
                {stories.map((story) => {
                  return (
                    <ImageWrapper key={`data${story.ID}`}>
                      <Image src={`data:image/png;base64,${story.Image}`} />
                    </ImageWrapper>
                  );
                })}
              </Carousel>
              <ArrowWrapper type="right">
                {index !== stories.length - 1 && (
                  <Arrow
                    disabled={disabled}
                    type="right"
                    onClickArrow={() => setIndex((prevState) => prevState + 1)}
                  />
                )}
              </ArrowWrapper>
            </CarouselWrapper>
          </Main>
          <Sidebar id="sidebar" onMouseMove={handleMouseMove} ref={sidebarRef}>
            <SidebarContent id="sidebar-content">
              {stories.map((story, i) => {
                return (
                  <SidebarItem
                    key={`data${story.ID || story.Id}`}
                    onClick={() => setIndex(i)}
                  >
                    <SidebarImage
                      src={`data:image/png;base64,${story.Image}`}
                    />
                    <SidebarText>
                      {t('storyMath:page', 'Page')} {story.DisplayOrder}
                    </SidebarText>
                  </SidebarItem>
                );
              })}
            </SidebarContent>
          </Sidebar>
        </Content>
      )}
    </Page>
  );
};

export default React.memo(StoryViewer);
