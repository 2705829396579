import React, { useEffect, useState } from 'react';
import SearchingScene from './SearchingScene';
import OpponentFoundScene from './OpponentFoundScene';
import { StyledOverlayContainer } from './MatchingAnimation.styles';

const MatchingAnimation = ({
  randomOpponents,
  selectedOpponentIndex,
  onFinishAnimation,
}) => {
  const [hasFound, setHasFound] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasFound(true);
    }, 2000);
  }, []);
  return (
    <StyledOverlayContainer>
      {!hasFound && (
        <SearchingScene randomOpponents={randomOpponents} show={false} />
      )}
      {hasFound && (
        <OpponentFoundScene
          randomOpponents={randomOpponents}
          selectedOpponentIndex={selectedOpponentIndex}
          onFinishAnimation={onFinishAnimation}
        />
      )}
    </StyledOverlayContainer>
  );
};

export default MatchingAnimation;
