import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { isNil } from 'ramda';
// slice
import {
  getBadgesByMonthYear,
  getUnseenBadges,
  resetUnseenBadges,
} from 'store/dailyChallenge/hodCalendarSlice';
import { getCurrentDailyChallenge } from 'store/dailyChallenge/dailyChallengeSlice';
// slice end
// hooks
import useDashboard from 'hooks/useDashboard';
// hooks end
// imgs
import AchievementButton from 'assets/img/dailychallenge/achievement-icon.png';
import LeaderboardButton from 'assets/img/dailychallenge/leaderboard-icon.png';
import CPIcon from 'assets/img/icon-challenge-point.svg';
import ChallengeText from 'assets/img/dailychallenge/challenge.png';
import HeroBadgeBanner from './imgs/heros-badge-banner.png';
// imgs end
// components
import { Modal } from 'react-bootstrap';
import { Calendar } from './components';
import { HeroOfTheDayModal } from '../components';
import {
  PopupModal,
  Spinner,
  DatePicker,
  ErrorModal,
  HeroOfTheDayProgress,
  EarnMoreCPsModal,
} from 'components/Shared';
// components end
// styles
import {
  DefaultHeader,
  EarnMoreCPsButton,
  Content,
} from '../DailyChallenge/DailyChallenge.styles';
import { TopSection, MonthYearSwitcher } from './HeroBadgeCalendar.styles';
// styles end
const HeroBadgeCalendar = () => {
  const { t } = useTranslation(['dailyChallenge']);
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux States
  const { currDailyChallenge, currDailyChallengeLoading } = useSelector(
    (state) => state.dailyChallenge
  );
  const { unseenBadges, error, unseenBadgesError } = useSelector(
    (state) => state.hodCalendar
  );
  const { isDashboardv2 } = useDashboard();
  const todaysUnseenBadges = unseenBadges.filter((badge) => {
    const currentDateTime = moment().format('D-MM-YYYY');
    const badgeDateTime = moment(
      badge.uEntity.Date,
      'YYYY-MM-DDTHH:mm:ss'
    ).format('D-MM-YYYY');
    return currentDateTime === badgeDateTime;
  });
  // Component States
  const [showEarnMoreCPsModal, setShowEarnMoreCPsModal] = useState(false);
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    moment().format('MM-YYYY')
  );
  const [showUnseenBadgesModal, setShowUnseenBadgesModal] = useState(false);
  const [markBadgeError, setMarkBadgeError] = useState(null);

  // Event Handlers
  const handleHideModal = () => setShowEarnMoreCPsModal(false);
  const changeMonthYear = (type, selectedMonthYear) => {
    const currentDateTime = moment(`01-${selectedMonthYear}`, 'DD-MM-YYYY');
    if (type === 'prev') {
      const prevMonthYear = currentDateTime
        .subtract(1, 'months')
        .format('MM-YYYY');
      setSelectedMonthYear(prevMonthYear);
    } else if (type === 'next') {
      const nextMonthYear = currentDateTime.add(1, 'months').format('MM-YYYY');
      setSelectedMonthYear(nextMonthYear);
    }
  };

  const showAchievementIcon = false;

  // USEEFFECTS
  useEffect(() => {
    dispatch(getCurrentDailyChallenge());
    dispatch(getUnseenBadges());
  }, [dispatch]);
  useEffect(() => {
    if (todaysUnseenBadges.length > 0) {
      setShowUnseenBadgesModal(true);
    }
  }, [todaysUnseenBadges.length]);
  useEffect(() => {
    const splitMonthYearString = selectedMonthYear.split('-');
    dispatch(
      getBadgesByMonthYear({
        year: splitMonthYearString[1],
        month: splitMonthYearString[0],
      })
    );
  }, [dispatch, selectedMonthYear]);
  useEffect(() => {
    return () => {
      dispatch(resetUnseenBadges());
    };
  }, [dispatch]);

  const reloadCalendar = () => {
    const splitMonthYearString = selectedMonthYear.split('-');
    if (!isNil(error)) {
      dispatch(
        getBadgesByMonthYear({
          year: splitMonthYearString[1],
          month: splitMonthYearString[0],
        })
      );
    } else if (!isNil(unseenBadgesError)) {
      dispatch(getUnseenBadges());
    }
  };
  const clearError = () => {
    if (!isNil(markBadgeError)) {
      setMarkBadgeError(null);
    }
  };
  return (
    <>
      {currDailyChallengeLoading && (
        <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
          <Spinner />
        </div>
      )}
      <DefaultHeader>
        <div className="left">
          <img
            onClick={() => {
              if (isDashboardv2) return;
              history.push('/challenges');
            }}
            src={ChallengeText}
            alt="Challenge"
            width="238px"
            height="85px"
          />
          <DatePicker />
        </div>
        <div className="center">
          <HeroOfTheDayProgress
            CPsEarnedToday={currDailyChallenge.TodayCpPoint}
            BadgeUrl={
              currDailyChallenge.BadgeInfo &&
              currDailyChallenge.BadgeInfo.BadgeUrl
            }
          />
          <EarnMoreCPsButton onClick={() => setShowEarnMoreCPsModal(true)}>
            {t(
              'dailyChallenge:heroBadge.earnCP',
              'Click here to earn more CPs'
            )}
            <img src={CPIcon} alt="CPs" width="20px" height="20px" />
          </EarnMoreCPsButton>
        </div>
        <div className="right">
          {showAchievementIcon && (
            <div className="nav-item">
              <img src={AchievementButton} alt="Achievements" />
              <span>
                {t('dailyChallenge:heroBadge.achievement', 'Achievements')}
              </span>
            </div>
          )}
          <div
            className="nav-item"
            onClick={() => history.push('/leaderboard')}
          >
            <img src={LeaderboardButton} alt="Leaderboards" />
            <span>
              {t('dailyChallenge:heroBadge.leaderboard', 'Leaderboards')}
            </span>
          </div>
        </div>
      </DefaultHeader>
      {!currDailyChallengeLoading && (
        <Content>
          <PopupModal
            show={showUnseenBadgesModal}
            backdrop="static"
            hide={() => {
              setShowUnseenBadgesModal(false);
            }}
          >
            <HeroOfTheDayModal
              unseenBadges={todaysUnseenBadges}
              isUnseen
              onHide={() => {
                setShowUnseenBadgesModal(false);
              }}
              setMarkError={setMarkBadgeError}
            />
          </PopupModal>
          <Modal show={showEarnMoreCPsModal} onHide={handleHideModal} centered>
            <EarnMoreCPsModal onHide={handleHideModal} />
          </Modal>
          <TopSection>
            <button
              className="back-btn"
              onClick={() => {
                history.goBack();
              }}
            >
              {t('dailyChallenge:heroBadge.back', 'Back')}
            </button>
            <img
              src={HeroBadgeBanner}
              alt="Hero's Badge"
              width="400px"
              className="banner"
            />
            <MonthYearSwitcher>
              <button
                onClick={() => {
                  if (selectedMonthYear !== '04-2017') {
                    changeMonthYear('prev', selectedMonthYear);
                  }
                }}
                data-cy="prev-month"
              >{`<`}</button>
              <span data-cy="calendar-month">
                {moment(selectedMonthYear, 'MM-YYYY').format(`MMM YYYY`)}
              </span>
              <button
                onClick={() => {
                  changeMonthYear('next', selectedMonthYear);
                }}
                disabled={moment().format('MM-YYYY') === selectedMonthYear}
                data-cy="next-month"
              >{`>`}</button>
            </MonthYearSwitcher>
          </TopSection>
          <Calendar
            selectedMonthYear={selectedMonthYear}
            setMarkError={setMarkBadgeError}
          />
        </Content>
      )}
      {(!isNil(error) || !isNil(markBadgeError)) && (
        <ErrorModal
          errorMessage={!isNil(error) ? error : markBadgeError}
          reloadAction={!isNil(error) && reloadCalendar}
          closeHandler={clearError}
        />
      )}
    </>
  );
};

export default HeroBadgeCalendar;
