import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isNil } from 'ramda';

import { Spinner, Button } from 'components/Shared';
import Bubble from './big-bubble.svg';
import { Container } from './TalkAbout.styles';

const TalkAbout = ({ activityId, courseId }) => {
  const history = useHistory();
  const { t } = useTranslation(['activities']);
  const { lessonId, topicId } = useParams();
  const { currentActivity, currentActivityLoading, activities } = useSelector(
    (state) => state.kooClass
  );

  const checkCurrentIndex =
    activities && activities.findIndex((el) => el.id === activityId);
  const nextItem =
    checkCurrentIndex + 1 < activities.length
      ? activities.find((el, index) => index === checkCurrentIndex + 1)
      : null;

  const onNextActivity = () => {
    history.push(
      `/kooClass/${topicId}/lesson/${lessonId}/activity?activityType=${nextItem.activityType}&activityId=${nextItem.id}&name=${nextItem.name}&courseId=${courseId}`
    );
  };

  return (
    <Container>
      <img src={Bubble} alt="KooClass" />
      <div className="content">
        {currentActivityLoading && <Spinner />}
        {!currentActivityLoading && currentActivity && (
          <>
            <h4>{t('activities:talkAbout.title', 'Let’s Talk About…')}</h4>
            <div className="desc">{currentActivity.description}</div>
            {!isNil(nextItem) && (
              <Button
                variant="primary"
                onClick={onNextActivity}
                dataCy="next-btn"
              >
                {t('activities:talkAbout.btn', 'Next Activity')}
              </Button>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default TalkAbout;
