import styled, { css } from 'styled-components';
import { assignmentTypeStyles } from '../constants';

export const Container = styled.div`
  display: flex;
`;

export const Main = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  ${({ assignmentType }) => {
    const assignmentTypeStyle = assignmentTypeStyles[assignmentType];
    return css`
      background: url(${assignmentTypeStyle.bgImage});
      background-color: ${assignmentTypeStyle.bgColor};
      background-repeat: ${assignmentTypeStyle.repeat};
      background-size: ${assignmentTypeStyle.size};
    `;
  }}
  .wrapper {
    display: flex;
    justify-content: center;
  }
  .summary-box {
    height: fit-content;
    background: #ffffff;
    border: 1px solid #fdad0c;
    border-radius: 8px;
    box-shadow: 10px 10px 0px 0px #fdad0c;
    width: 70%;
    margin-top: 1.875rem;
    padding: 0.2rem 0rem 0rem 0.3rem;
  }
`;

export const AssignerSection = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-left: 14%;
`;
