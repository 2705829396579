import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Content } from 'pages/Assignment/Assignment/Assignment.styles';
import Header from 'components/Header';
import { featureToggle } from 'constants/index';
import useGlobalFeature from 'hooks/useGlobalFeature';
import {
  HeroBadgeCalendar,
  DCScience,
  SubjectDailyChallenge,
  NewChallengeList,
} from '..';

const Index = () => {
  const { isEnabled } = useGlobalFeature('dc');
  return (
    <div>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={[
                '/challenges',
                '/challenges/daily-challenge',
                '/challenges/science',
              ]}
              to="/maintenance-redirect"
            />
          )}
          <Route exact path="/challenges" component={NewChallengeList} />
          <Route
            exact
            path="/challenges/daily-challenge"
            component={SubjectDailyChallenge}
          />
          {featureToggle.heroBadgeCalendar && (
            <Route
              exact
              path="/challenges/daily-challenge/hero-badge"
              component={HeroBadgeCalendar}
            />
          )}
          <Route exact path="/challenges/science" component={DCScience} />
        </Switch>
      </Content>
    </div>
  );
};

export default Index;
