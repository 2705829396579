const redirectionList = [
  'assignment/parent-assign',
  'assignment/start',
  'kooquiz/maths',
  'kooquiz/science',
  'kooquiz/start',
  'kooquiz/solution-preview',
  'superhero/supervision',
  'superhero/superspeed',
];

const regexRedirectionList = [
  /kooclass\/(math|science)\/.*\/lesson\/.*\/activity/,
  /mission\/self-practice\/(math|science)\/\d+\/*/,
  /mission\/self-practice\/plus\/(math|science)\/\d+\/*/,
  /story\/science\?redirect/,
  /story\/math\?redirect/,
  /challenges\/daily-challenge\?plan=(b2b|b2c)/,
  /challenges\/science\?plan=(b2b|b2c)/,
  /multiplayer\/peer-challenge\/new-challenge\?redirect&subject=(math|science)/,
  /trial\/bundle/,
  /expired\/bundle/,
];

const shouldRedirectMapping = (path, search) => {
  const lowercasePath = path.toLowerCase();
  const lowercaseSearch = search.toLowerCase();
  const isMatchRedirectionList = redirectionList.some((redirect) =>
    lowercasePath.includes(redirect)
  );
  const isMatchRegexList = regexRedirectionList.some((redirect) =>
    redirect.test(
      lowercaseSearch ? lowercasePath + lowercaseSearch : lowercasePath
    )
  );
  return isMatchRedirectionList || isMatchRegexList;
};

export default shouldRedirectMapping;
