import React, { useState, useCallback } from 'react';
import BFFSearchBar from '../BFFSearchBar';
import { getFriendSearchResults } from 'services/search';
import { useTranslation } from 'react-i18next';
import { fetchWrapper } from 'services/login';
import DropdownWithSearch from '../DropdownWithSearch';

const MyFriendCardContent = ({
  opponentType,
  setOpponentType,
  setOpponentId,
  selectedOpponentID,
  setNewSelectedOpponentId,
}) => {
  const { t } = useTranslation(['newChallenge']);
  const [isShowingSearchResult, setIsShowingSearchResult] = useState(false);
  const setOpponentTypeAsFriend = () => {
    setOpponentType('4');
  };
  const loadInitialFriendList = useCallback((pageIndex) => {
    return fetchWrapper(getFriendSearchResults, {
      searchString: '',
      pageIndex,
    }).then((res) => ({
      ...res,
      List: res.List.map((user) => ({
        ...user,
        id: user.Id,
        name: user.FullName,
      })),
    }));
  }, []);
  const searchFriend = useCallback((searchString, pageIndex) => {
    return fetchWrapper(getFriendSearchResults, {
      searchString,
      pageIndex,
    });
  }, []);
  return (
    <>
      <BFFSearchBar
        setOpponentType={setOpponentType}
        setOpponentId={setOpponentId}
        selectedOpponent={selectedOpponentID}
        setIsShowingSearchResult={setIsShowingSearchResult}
        setNewSelectedOpponentId={setNewSelectedOpponentId}
      />
      {!isShowingSearchResult && (
        <DropdownWithSearch
          placeholder={t('newChallenge:bff.selectBFF', 'Select My BFF')}
          opponentType={opponentType}
          setOpponentTypeFunc={setOpponentTypeAsFriend}
          setOpponentId={setOpponentId}
          setNewSelectedOpponentId={setNewSelectedOpponentId}
          fetchInitialUserListFunc={loadInitialFriendList}
          fetchSearchResultsFunc={searchFriend}
        />
      )}
    </>
  );
};

export default MyFriendCardContent;
