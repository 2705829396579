import React from 'react';
import { useTranslation } from 'react-i18next';

import congratulations from 'assets/img/superherochallenge/superspeed/congratulations.png';

import { Container, Content } from './Done.styles';

function Done({ score, practice }) {
  const { t } = useTranslation(['superSpeed']);
  const renderText = practice ? (
    <>
      {t(
        'superSpeed:game.practiceCompletedText',
        { score },
        `You've completed this practice, and you've answered ${score} questions correctly. You can click on the button below to practice again.`
      )}
    </>
  ) : (
    <>
      {t(
        'superSpeed:game.challengeCompletedText',
        { score },
        `You've answered ${score} questions correctly and achieved score of`
      )}{' '}
      <strong>{score}</strong>.{' '}
      {t(
        'superSpeed:game.challengeCompletedText2',
        `Please come back tomorrow to improve your score.`
      )}
    </>
  );
  return (
    <Container>
      <img alt="congratulations" src={congratulations} />
      <Content>{renderText}</Content>
    </Container>
  );
}

export default React.memo(Done);
