// Maps subject to corresponding island game folder
export const SUBJECT_ISLANDS = {
  math: {
    folder: 'mathkooislandv4.0',
    skipFolder: 'mathkooislandv4.0-nosound',
    iframe: true,
  },
  science: {
    folder: 'sciencekooisland',
    skipFolder: 'sciencekooisland-nosound',
    iframe: true,
  },
  english: {
    folder: 'englishkooisland',
    skipFolder: 'englishkooisland-nosound',
    iframe: true,
  },
};
