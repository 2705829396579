import styled from 'styled-components';

export const Container = styled.div`
  .proficiency-legend {
    background-color: #e7f0f7;
    padding-top: 60px;
    height: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .proficiency-content {
    display: flex;
    flex-direction: row;
  }
  .year-top {
    span {
      font-size: 16px;
    }
  }

  .year-bottom {
    display: none;
  }
  @media (max-width: 900px) {
    .year-top {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .year-top {
      display: none;
    }

    .year-bottom {
      position: static;
      display: flex;
      font-size: 16px;
      align-items: center;
      margin-left: 0;
      span {
        margin-right: 0.5rem;
      }
    }
  }
  @media (max-width: 500px) {
    .year-bottom {
      font-size: 14px;
      flex-direction: column;
    }
  }
`;
export const TopBar = styled.div`
  position: fixed;
  background: #d1eaff;
  height: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 150px 0px 80px;
  z-index: 10;
  @media (max-width: 880px) {
    justify-content: flex-start;
  }
  @media (max-width: 785px) {
    padding: 0px 50px;
  }
  @media (max-width: 785px) {
    padding: 0px 20px;
  }
  @media (max-width: 610px) {
    padding: 0px 20px;
  }
`;

export const SyllabusDropdown = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 600px;

  .label {
    color: #65a6db;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .syllabus,
  .year {
    margin: 20px 10px;
  }
  .syllabus {
    .dropdown-menu {
      max-height: none;
    }
    #dropdown-custom span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const QuestionDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  font-family: 'Nunito';
  color: #5d5555;
  width: 130px;
  .qn-details {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 110px;
    justify-content: space-between;
    .total {
      font-size: 17px;
      font-weight: 600;
    }
    .no-question {
      color: ${(props) => (props.qnNo ? '#BCBCBC' : '#ff4f00')};
      font-size: 27px;
      font-weight: bold;
    }

    .qn {
      font-size: 12px;
    }
  }
  .validation-text {
    color: #ff0000;
    font-size: 12px;
    line-height: 0.5;
  }
`;

export const StyledSpinner = styled.span`
  margin-top: 200px;
`;

export const Main = styled.div`
  width: ${(props) =>
    props.showSkillDetails ? `calc(100vw - 360px)` : 'none'};
  padding: 4.5rem 0rem;
  padding-left: 3rem;
  padding-top: 0rem;

  .title {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    width: 70%;
    border-bottom: 2px solid #fafafa;
    margin-bottom: 0px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding-left: 0;
    .iconSidebarWrapper {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .titleWrapper {
      display: flex;
      align-items: center;
      position: relative;
    }
    .title {
      width: 100%;
    }
  }
`;

export const MainContent = styled.div`
  display: flex;
`;

export const Sidebar = styled.div`
  width: 300px;
  @media (max-width: 1025px) {
    width: 225px;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0px 10px;
  h4 {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Nunito';
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 70px;
  }
`;

export const BottomMenu = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #d1eaff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 16px;
  max-width: 85%;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobileM}px`}) {
    max-width: 80%;
  }
`;
