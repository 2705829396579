import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedStory } from 'store/story/storySlice';
import { isNil, isEmpty } from 'ramda';
import { featureToggle } from 'constants/index';
import {
  Container,
  Control,
  Placeholder,
  Arrow,
  Menu,
  Option,
} from './ChapterSelector.styles';

const hasMathB2CSubscription = (products) => {
  const mathB2CProduct = products
    .filter((product) => product.subject.toLowerCase().trim() === 'math')
    .find((product) => {
      const nonExpiredB2CSubscriptions = product.nonExpiredSubscriptionProducts.filter(
        (sub) => sub.productType.toLowerCase().trim() !== 'school'
      );
      if (nonExpiredB2CSubscriptions.length <= 0) return false;
      return true;
    });
  if (isNil(mathB2CProduct)) return false;
  return true;
};

const ChapterSelector = () => {
  const dispatch = useDispatch();
  const { storyWorlds, selectedStory } = useSelector((state) => state.story);
  const { isLoading, products } = useSelector((state) => state.plan);
  // Filter out story worlds for math subject id: 1
  const filteredStoryWorlds = featureToggle.science
    ? storyWorlds.filter((world) => world.SubjectId === 1)
    : storyWorlds;
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const [showOptions, setShowOptions] = useState(false);
  const userLevel = !isNil(studentDetails)
    ? studentDetails.Subscriptions[0].LevelId
    : 1;
  const hasMathB2C = isNil(products) ? false : hasMathB2CSubscription(products);
  const handleSelectChapter = useCallback(
    (story) => {
      if (hasMathB2C) {
        dispatch(setSelectedStory(story));
        setShowOptions(false);
      } else if (story.LevelId <= userLevel) {
        dispatch(setSelectedStory(story));
        setShowOptions(false);
      }
    },
    [dispatch, hasMathB2C, userLevel]
  );
  useEffect(() => {
    if (isEmpty(selectedStory) && !isEmpty(filteredStoryWorlds)) {
      const userStory = filteredStoryWorlds.find(
        (st) => st.LevelId === userLevel
      );
      if (!isNil(userStory)) {
        dispatch(setSelectedStory(userStory));
      } else {
        dispatch(setSelectedStory(filteredStoryWorlds[0]));
      }
    }
  }, [dispatch, selectedStory, filteredStoryWorlds, studentDetails, userLevel]);

  return (
    <Container
      onBlur={() => {
        setShowOptions(false);
      }}
      tabIndex="0"
    >
      <Control
        onClick={() => {
          if (isLoading) return;
          setShowOptions(!showOptions);
        }}
      >
        <Placeholder>{selectedStory.Name}</Placeholder>
        <Arrow />
      </Control>
      {showOptions && (
        <Menu>
          {filteredStoryWorlds.map((story) => {
            return (
              <Option
                key={`data${story.Id}`}
                onClick={() => handleSelectChapter(story)}
                clickAble={hasMathB2C ? true : story.LevelId <= userLevel}
              >
                {story.Name}
              </Option>
            );
          })}
        </Menu>
      )}
    </Container>
  );
};

export default React.memo(ChapterSelector);
