import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  height: 32px;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  border-radius: 26px;
  height: 46px;
  padding: 0.5rem;
  justify-content: space-between;
  gap: 0.5rem;
  margin-left: 5rem;
  background: none;
  box-shadow: none;

  @media (min-width: 1250px) {
    margin-left: 0rem;
    gap: 3.5rem;
  }

  @media (max-width: 768px) {
    transform: scale(0.8);
  }

  @media (max-width: 500px) {
    margin-right: -4rem;
  }

  @media (max-width: 400px) {
    margin-right: -3rem;
    transform: scale(0.6);
  }
`;

export const Tab = styled.div`
  border-radius: 18px;
  height: 34px;
  position: relative;
  min-width: initial;
  width: 50%;
  gap: 9px;
  color: ${({ active }) =>
    active ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.8)'};
  background: none;
  font-size: ${({ active }) => (active ? '18px' : '16px')};
  font-weight: 600;
  text-align: center;
  margin-left: 0;
  cursor: pointer;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: ${({ isChinese }) => (isChinese ? '3px' : 0)};
  white-space: nowrap;

  img {
    width: ${({ iconWidth }) => iconWidth};
    margin-right: 4px;
  }
  &::before {
    content: '';
    width: 100%;
    height: 5px;
    display: block;
    position: absolute;
    bottom: -25px;
    left: 0;
    background: ${({ active }) => (active ? '#1F4B7F' : 'transparent')};
    border-radius: 50px;
  }

  & > span {
    display: none;
  }

  img {
    opacity: ${({ active }) => (active ? '1' : '0.8')};
  }

  @media (min-width: 1024px) {
    & > span {
      display: block;
      margin-top: ${({ isChinese }) => (isChinese ? '3px' : 0)};
    }
  }
`;
