import SVOpen from 'assets/img/superhero/superhero-vision.png';
import SVClose from 'assets/img/dailychallenge/super-vision-close.png';
import SSOpen from 'assets/img/superhero/superhero-speed.png';
import SSClose from 'assets/img/dailychallenge/super-speed-close.png';
import KooCards from 'assets/img/superhero/parentChallenge/koo-cards.png';
import SpellingBits from 'assets/img/superhero/parentChallenge/spelling-bits.png';
import { subjectNames, SUBJECTS } from 'constants/products';

export const superHeroChallengeType = [
  'super-vision-challenge',
  'super-speed-challenge',
];

export const superHeroChallengeStatusIDs = {
  1: 'locked',
  2: 'completed',
  3: 'incomplete',
};

export const mappingSuperHeroType = {
  'super-vision-challenge': 'Supervision',
  'super-speed-challenge': 'Superspeed',
};

export const SuperHeroChallengeList = {
  challenge: [
    {
      id: 2,
      name: 'Super Vision Challenge',
      nameTranslation: 'SV',
      imgOpen: SVOpen,
      imgClose: SVClose,
      desc: '',
      schedule: '6am to 10pm, Monday to Saturday',
      scheduleTranslation: 'schedule',
      type: 'super-vision-challenge',
      link: '/superhero/supervision',
    },
    {
      id: 3,
      name: 'Super Speed Challenge',
      nameTranslation: 'SS',
      imgOpen: SSOpen,
      imgClose: SSClose,
      desc: '',
      scheduleTranslation: 'schedule',
      schedule: '6am to 10pm, Monday to Saturday',
      type: 'super-speed-challenge',
      link: '/superhero/superspeed',
    },
  ],
  parentAppChallenge: [
    {
      id: 1,
      subjectID: subjectNames[SUBJECTS.ENGLISH],
      img: KooCards,
      nameTranslation: 'kooCards',
      title: 'KooCards',
      desc: 'Visual Vocabulary',
      videoUrl: '-i_TyKHiYh0',
    },
    {
      id: 2,
      subjectID: subjectNames[SUBJECTS.ENGLISH],
      img: SpellingBits,
      nameTranslation: 'sb',
      title: 'Spelling Bits',
      desc: 'Learn, Practise, Test',
      videoUrl: 'ykwaP3wj80s',
    },
  ],
};
