import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'assets/img/multiplayer/icon-no-card-left.svg';
import { Button } from 'components/Shared';
import { Container } from './NoCardLeftModal.styles';

const NoCardLeftModal = ({ setShowModal, totalEnergyPerDay }) => {
  const { t } = useTranslation(['newChallenge']);

  return (
    <Container>
      <img src={Icon} alt="icon" />
      <div className="title">
        {t(
          'newChallenge:modal.usedChallenge',
          {
            totalEnergyPerDay,
            challenge: totalEnergyPerDay > 1 ? 'challenges' : 'challenge',
          },
          'You have used up 3 challenges today.'
        )}
      </div>
      <div className="desc">
        <p>
          {t(
            'newChallenge:modal.noChallengeRemaining',
            'There are no challenges remaining today.'
          )}
        </p>
        <p>{t('newChallenge:modal.seeYou', 'See you tomorrow.')}</p>
      </div>
      <div className="btn-section">
        <Button
          dataCy="ok-btn"
          variant="primary"
          fontSize="26px"
          onClick={() => setShowModal(false)}
          width="100%"
        >
          {t('newChallenge:modal.ok', 'OK')}
        </Button>
      </div>
    </Container>
  );
};

export default NoCardLeftModal;
