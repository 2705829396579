import styled from 'styled-components';
import { $colors } from 'constants/dashboardv2';

export const StyledContainer = styled.div`
  min-height: ${(props) => props.styles.minHeight};
  height: 100%;
  background: ${(p) => $colors.dashboard[p.subject]?.sidePanelTop};
  width: 193px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  color: white;
  position: -webkit-sticky; /* for Safari users */
  position: sticky;
  top: 0px;
  float: left;
  z-index: 21;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    min-height: auto;
    height: 93px;
    width: 100%;
    flex-direction: row;
    padding: 0px;
    top: calc(100% - 93px);
    bottom: 0px;
    float: none;
    position: fixed;
  }
`;
