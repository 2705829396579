// HELPER
import { isNil } from 'ramda';
import parseWorkingsBarModel from './parseWorkingsBarModel';

export const getWorkings = (currentSubmission, localSavedWorkings) => {
  if (!isNil(localSavedWorkings)) {
    return localSavedWorkings;
  }
  if (!isNil(currentSubmission.SubmissionModel)) {
    return parseWorkingsBarModel(currentSubmission.SubmissionModel.Solution)
      .workings;
  }
  return null;
};
