import { subjectNames, SUBJECTS } from 'constants/products';
import StoryMathLogo from './imgs/koobits-storymath.png';
import StoryScienceLogo from './imgs/koobits-storyscience.png';
import StoryEnglishLogo from './imgs/koobits-storyenglish.png';

export const SUBJECT_LOGO = {
  [subjectNames[SUBJECTS.MATH]]: {
    logo: StoryMathLogo,
    title: 'StoryMath',
  },
  [subjectNames[SUBJECTS.SCIENCE]]: {
    logo: StoryScienceLogo,
    title: 'StoryScience',
  },
  [subjectNames[SUBJECTS.ENGLISH]]: {
    logo: StoryEnglishLogo,
    title: 'StoryEnglish',
  },
};
