import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

// Icon && Image
import CPIcon from './cp-icon.svg';
import DefaultCP from './default-cp.svg';
import ActiveCP from './active-cp.svg';
import Triangle from './triangle.svg';

import { getTodayCPDetails } from 'store/dailyChallenge/cpsDetailSlice';
import {
  Container,
  CPInfo,
  Dropdown,
  DropdownButton,
  DropdownContainer,
  DropdownHeader,
  DropdownContentItem,
  DropdownFooter,
  theme,
} from './BreakdownCP.styles';

/**
 *  Component for Breakdown of CPs
 *
 * @component
 * @param target {string} 'dc' | 'smc' | 'pc', mount on which page
 * @param style {CSSStyle} custom style
 * @example
 *
 * // Custom style
 * const style = { position: 'absolute', top: '10vh', right: '3vw' }
 * return (
 *   <BreakdownCP target="dc style={style} " />
 * )
 */
const BreakdownCP = ({
  target = 'dc',
  style,
  hasFirstLoad,
  setHasFirstLoad,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['breakdownCP', 'common']);
  const [isOpen, setIsOpen] = useState(false);
  const { subject } = useSelector((state) => state.plan);
  const {
    dcCP,
    pcCP,
    smcCP,
    loading,
    TodaysTotalCP,
    smcClaimedOfDate,
    isDisplaySMCClaimed,
  } = useSelector((state) => state.cpsDetail);

  useEffect(() => {
    if (hasFirstLoad) return;
    (async function mounted() {
      await dispatch(getTodayCPDetails());
      setHasFirstLoad(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasFirstLoad]);

  const parseCP = (cp) => (cp === 0 ? '-' : cp);
  const parseCPIcon = (cp) => (cp === 0 ? DefaultCP : ActiveCP);

  const onHeroOfTheDay = () => {
    setIsOpen(false);
    window.open(`/leaderboard/hero-of-the-day/${subject}`, '_blank');
  };

  const currentCP = (targetName) => {
    if (targetName === 'dc') return dcCP;
    if (targetName === 'smc') return smcCP;
    if (targetName === 'pc') return pcCP;
    return TodaysTotalCP;
  };

  return (
    <ThemeProvider theme={theme[target]}>
      <Container style={style}>
        <CPInfo target={target}>
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <span>{currentCP(target)} </span>
          )}
          CP
        </CPInfo>
        <Dropdown>
          <DropdownButton
            type="button"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <img src={Triangle} alt="" />
          </DropdownButton>
          {isOpen && (
            <DropdownContainer>
              <DropdownHeader>
                {t('breakdownCP:todayCP', "Today's CP")}
              </DropdownHeader>
              <DropdownContentItem isHighlight={target === 'dc'}>
                <p>{t('breakdownCP:dailyChallenge', 'Daily Challenge')}</p>
                <div className="cps">
                  <img src={parseCPIcon(dcCP)} alt="" />
                  <span>{parseCP(dcCP)}</span>
                </div>
              </DropdownContentItem>
              <DropdownContentItem isHighlight={target === 'pc'}>
                <p>{t('breakdownCP:peerChallenge', 'Peer Challenge')}</p>
                <div className="cps">
                  <img src={parseCPIcon(pcCP)} alt="" />
                  <span>{parseCP(pcCP)}</span>
                </div>
              </DropdownContentItem>
              <DropdownContentItem isHighlight={target === 'smc'}>
                <p> {t('breakdownCP:SMCChallenge', 'Sunday Mini Challenge')}</p>
                {isDisplaySMCClaimed ? (
                  <div className="cp-text">
                    {t(
                      `breakdownCP:claimedOfDay`,
                      {
                        day: t(`common:${smcClaimedOfDate}`),
                      },
                      `Claimed on ${smcClaimedOfDate}`
                    )}
                  </div>
                ) : (
                  <div className="cps">
                    <img src={parseCPIcon(smcCP)} alt="" />
                    <span>{parseCP(smcCP)}</span>
                  </div>
                )}
              </DropdownContentItem>

              <DropdownFooter>
                <p>{t('breakdownCP:todayTotalCP', "Today's Total  CP")}</p>
                <div>
                  <img src={CPIcon} alt="" />
                  <span>{TodaysTotalCP}</span>
                </div>
                {TodaysTotalCP > 0 && (
                  <button type="button" onClick={onHeroOfTheDay}>
                    {t('breakdownCP:heroDay', 'Hero of the Day')}
                  </button>
                )}
              </DropdownFooter>
            </DropdownContainer>
          )}
        </Dropdown>
      </Container>
    </ThemeProvider>
  );
};

export default BreakdownCP;
