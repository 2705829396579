import { SUBJECTS, subjectNames } from 'constants/products';
import StoryEnglishLogo from './imgs/english/title.png';
import StoryScienceLogo from './imgs/science/storyscience.png';
import SciencePaperSheet from './imgs/science/paper-sheet.png';
import EnglishPaperSheet from './imgs/english/paper-sheet.png';
import StoryEnglishBg from './imgs/english/story-english-bg.png';
import StoryScienceBg from './imgs/science/story-science-bg.png';

export const DEFAULT_SUBJECT_ID = subjectNames[SUBJECTS.SCIENCE];
export const PADDING_TOP = 185;
export const SUBJECT_PARAMS = {
  [subjectNames[SUBJECTS.ENGLISH]]: {
    backgroundImg: StoryEnglishBg,
    bookShadow: EnglishPaperSheet,
    title: StoryEnglishLogo,
    boxShadow: '0px 20px 20px 0px rgba(69, 34, 0, 0.5)',
    sidebar: {
      background: '#E69A31',
      menu: {
        color: '#ffffff',
        activeBg: '#C47C32',
      },
    },
  },
  [subjectNames[SUBJECTS.SCIENCE]]: {
    backgroundImg: StoryScienceBg,
    bookShadow: SciencePaperSheet,
    title: StoryScienceLogo,
    boxShadow: '0px 10px 7px -2px rgba(62, 86, 0, 1)',
    sidebar: {
      background: '#4c5d0d',
      menu: {
        color: '#c0e350',
        activeBg: '#6b8312',
      },
    },
  },
};
