import React, { useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  getHistory,
  clearHistory,
} from 'store/superheroChallenge/superheroChallengeSlice';

import superheroScoreIcon from 'assets/img/superhero/superhero-score-icon-v2.svg';

import {
  Container,
  Content,
  LastWeekButton,
  StyledTable,
} from './HistoryTable.styles';

function HistoryTable() {
  const { t } = useTranslation(['superheroHistory']);
  const { history, week } = useSelector((state) => state.superheroChallenge);
  const dispatch = useDispatch();
  const loadLastWeek = useCallback(() => {
    dispatch(getHistory({ week: 1 }));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getHistory({ week: 0 }));
    return () => {
      dispatch(clearHistory());
    };
  }, [dispatch]);
  const headers = [
    t('superheroHistory:date', 'Date'),
    t('superheroHistory:dailyVisionScore', 'Daily Vision Score'),
    t('superheroHistory:dailySpeedScore', 'Daily Speed Score'),
    t('superheroHistory:totalScore', 'Total Score'),
    t('superheroHistory:achievementTime', 'Achievement Time'),
  ];
  return (
    <Container>
      <Content>
        <StyledTable>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {history.map((h, index) => (
              <Fragment key={index}>
                <tr>
                  <td>{moment(h.LastUpdateDateTime).format('DD/MM/YYYY')}</td>
                  <td>{h.DailyVisionScore}</td>
                  <td>{h.DailySpeedScore}</td>
                  <td>
                    <img
                      width="40px"
                      src={superheroScoreIcon}
                      alt="superheroScoreIcon"
                    />
                    <span>{h.DailySpeedScore + h.DailyVisionScore}</span>
                  </td>
                  <td>{moment(h.LastUpdateDateTime).format('HH:mm:ss')}</td>
                </tr>
                <tr />
              </Fragment>
            ))}
          </tbody>
        </StyledTable>

        {week === 0 && (
          <LastWeekButton onClick={loadLastWeek}>
            <span>
              {t(
                'superheroHistory:showLastWeekButton',
                'Show Last Week’s History'
              )}
            </span>
          </LastWeekButton>
        )}
      </Content>
    </Container>
  );
}

export default React.memo(HistoryTable);
