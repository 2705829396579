import React, { useEffect, useState, useCallback } from 'react';
import { isEmpty } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { Spinner } from 'react-bootstrap';

import { getRewards } from 'store/dashboard/rewardsSlice';
import useDashboard from 'hooks/useDashboard';
import { subjectNames, SUBJECTS } from 'constants/products';
import iconKoKo from 'assets/img/icon-koko-credits-shadow.svg';
import {
  getScienceStoryChapters,
  getStoryWorlds,
  setSelectedStory,
  setSelectedChapter,
  unlockChapterRequest,
  resetUnlockChapterError,
} from 'store/story/storySlice';

import Header from 'components/Header';
import { PopupModal, BackButton, NotEnoughKokoModal } from 'components/Shared';
import { UnlockAlert, BookShelf } from './components';
import { SUBJECT_PARAMS } from './constants';
import {
  Page,
  Content,
  LogoWrapper,
  Logo,
  StoryHeader,
  LeftWrapper,
  Wrapper,
  Sidebar,
  Container,
  ShelfContainer,
  StyledRightWrapper,
} from './StorySubject.styles';

const StorySubject = () => {
  const { t } = useTranslation(['storySubject', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { subject } = useParams();
  const {
    storyWorlds,
    storyChapters,
    selectedStory,
    selectedChapter,
    isLoading,
    unlockChapterError,
  } = useSelector((state) => state.story);
  const { rewards } = useSelector((state) => state.rewards);
  const { isDashboardv2 } = useDashboard();
  const [storyIndex, setStoryIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const isFromShortCut = location.state?.quickAccess;
  const subjectName = subject.toLowerCase().trim();
  const subjectId = subjectNames[subjectName];
  const subjectStoryWorlds = storyWorlds.filter(
    (world) => world.SubjectId === subjectId
  );

  const backHandler = () => {
    if (isFromShortCut) {
      history.goBack();
      return;
    }
    const destination = isDashboardv2 ? '/dashboard?view=koofun' : '/story';
    history.push(destination);
  };

  useEffect(() => {
    dispatch(getRewards());
    dispatch(getStoryWorlds(subjectId))
      .then(unwrapResult)
      .then((promiseResult) => {
        if (!isEmpty(promiseResult)) {
          const filteredWorlds = promiseResult.filter(
            (world) => world.SubjectId === subjectId
          );
          if (isEmpty(filteredWorlds)) return;
          dispatch(setSelectedStory(filteredWorlds[0]));
        }
      });
  }, [dispatch, subjectId]);

  useEffect(() => {
    if (!isEmpty(selectedStory) && selectedStory?.SubjectId === subjectId) {
      const storyIds = subjectStoryWorlds.map((item) => item.LevelId);
      if (storyIds.length <= 0) return;
      dispatch(getScienceStoryChapters(storyIds));
      setStoryIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedStory, subjectId]);

  const onAcceptUnlock = useCallback(async () => {
    if (selectedChapter.Status === 'Locked') {
      setIsUnlocking(true);
      try {
        const unlockResult = await dispatch(
          unlockChapterRequest(selectedChapter.Id)
        );
        unwrapResult(unlockResult);
        const storyIds = storyWorlds.map((item) => item.LevelId);
        await dispatch(getScienceStoryChapters(storyIds));
      } catch (err) {
        console.log(err.message);
      } finally {
        setIsUnlocking(false);
      }
    } else {
      setShowModal(false);
    }
  }, [dispatch, selectedChapter.Id, selectedChapter.Status, storyWorlds]);
  const closeUnlockModal = () => {
    setShowModal(false);
    dispatch(resetUnlockChapterError());
  };
  const onClickImage = useCallback(
    (data) => {
      dispatch(setSelectedChapter(data));
      if (data.Status === 'Locked') {
        setShowModal(true);
      } else if (data.Status === 'Unlocked') {
        history.push(`/story-book/subject/${subjectId}/viewer/${data.Id}`);
      }
    },
    [dispatch, history, subjectId]
  );

  const checkInsufficientKoKoError = () => {
    return unlockChapterError === 'SPM042';
  };

  const renderEmptyBlocks = () => {
    const chapters = storyChapters.filter(
      (chapter) => chapter.LevelId === storyIndex || storyIndex === 0
    );
    // 0 line = 3 blocks underneath
    if (chapters.length === 0) {
      return (
        <ShelfContainer subjectId={subjectId}>
          <div className="line first-line" />
          <div className="line" />
          <div className="line" />
        </ShelfContainer>
      );
    }
    // 1 line = 2 blocks underneath
    if (chapters.length <= 5) {
      return (
        <ShelfContainer subjectId={subjectId}>
          <div className="line first-line" />
          <div className="line" />
        </ShelfContainer>
      );
    }
    // 2 line = 1 blocks underneath
    if (chapters.length <= 10) {
      return (
        <ShelfContainer subjectId={subjectId}>
          <div className="line first-line" />
        </ShelfContainer>
      );
    }
    return <></>;
  };

  return (
    <Page>
      {unlockChapterError && checkInsufficientKoKoError && (
        <PopupModal show backdrop="static">
          <NotEnoughKokoModal
            yesHandle={() => {
              dispatch(resetUnlockChapterError());
            }}
          />
        </PopupModal>
      )}
      <PopupModal show={showModal} hide={closeUnlockModal}>
        <UnlockAlert
          book={selectedChapter}
          onReject={closeUnlockModal}
          error={!checkInsufficientKoKoError && unlockChapterError}
          status={selectedChapter.Status}
          onAccept={() => onAcceptUnlock()}
          isLoading={isUnlocking}
        />
      </PopupModal>
      <Header />
      <StoryHeader>
        <LogoWrapper>
          <LeftWrapper>
            <BackButton
              backText={t('common:back', 'Back')}
              btnBgColor="rgb(244,146,0,0.61)"
              padding="5px 15px"
              styles={{ marginRight: '8px', zIndex: '3' }}
              onClick={backHandler}
            />
          </LeftWrapper>
          <Logo
            style={{ height: '50px', marginTop: '1rem' }}
            src={SUBJECT_PARAMS[subjectId]?.title}
          />
          {subjectName !== SUBJECTS.SCIENCE?.toLowerCase().trim() && (
            <StyledRightWrapper>
              <img src={iconKoKo} alt="koko" />
              <span>{rewards?.TotalKoko}</span>
            </StyledRightWrapper>
          )}
        </LogoWrapper>
      </StoryHeader>
      <Container>
        <Content storyChapters={storyChapters} subjectId={subjectId}>
          <Sidebar subjectId={subjectId}>
            <div
              key={0}
              className={`menu ${storyIndex === 0 && 'selected'}`}
              onClick={() => setStoryIndex(0)}
              aria-hidden="true"
            >
              {t('storySubject:all', 'All')}
            </div>
            {storyWorlds.map((menuItem) => (
              <div
                key={menuItem.LevelId}
                className={`menu ${
                  storyIndex === menuItem.LevelId && 'selected'
                }`}
                onClick={() => setStoryIndex(menuItem.LevelId)}
                aria-hidden="true"
              >
                {t(`storySubject:${menuItem.Name}`, menuItem.Name)}
              </div>
            ))}
          </Sidebar>
          {isLoading && <Spinner animation="border" />}
          {!isLoading && (
            <Wrapper>
              {storyChapters.length > 0 && (
                <BookShelf
                  onClickImage={onClickImage}
                  subjectId={subjectId}
                  data={storyChapters.filter(
                    (chapter) =>
                      chapter.LevelId === storyIndex || storyIndex === 0
                  )}
                />
              )}
              {renderEmptyBlocks()}
            </Wrapper>
          )}
        </Content>
      </Container>
    </Page>
  );
};

export default StorySubject;
