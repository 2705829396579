import React, { useMemo } from 'react';
import useQuery from 'helpers/useQuery';
import useDashboard from 'hooks/useDashboard';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { subjectIDs, SUBJECTS } from 'constants/products';
import { withPlanCheck, withSubscriptionCheck } from 'helpers/hoc';
import { DailyChallenge, DailyChallengev2 } from '..';

const SubjectDailyChallenge = () => {
  const query = useQuery();
  const location = useLocation();
  const { subject } = useSelector((state) => state.plan);
  const subjectName =
    subjectIDs[location.state?.subjectID]?.toLowerCase() || subject;
  const { isDashboardv2 } = useDashboard();
  const DC =
    isDashboardv2 || subjectName === SUBJECTS.ENGLISH.toLowerCase()
      ? DailyChallengev2
      : DailyChallenge;
  const plan = query.get('plan');
  const WrappedComponent = useMemo(() => {
    return ['B2B', 'B2C'].includes(plan)
      ? withPlanCheck(DC, subjectName, plan)
      : withSubscriptionCheck(DC, subjectName);
  }, [DC, plan, subjectName]);
  return <WrappedComponent />;
};

export default SubjectDailyChallenge;
