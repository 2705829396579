import React from 'react';
import SkeletonContainer from './Skeleton.styled';

const Skeleton = ({
  height = '12px',
  width = '30%',
  display = 'block',
  marginBottom = '10px',
  styles,
}) => {
  return (
    <SkeletonContainer
      className="loading-main-text"
      customHeight={height}
      customWidth={width}
      customDisplay={display}
      marginBottom={marginBottom}
      style={styles}
    />
  );
};

export default Skeleton;
