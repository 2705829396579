import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledPointer = styled.div`
  display: flex;
  color: #b5b5b5;
  align-items: center;
  gap: 12px;
  line-height: normal;
  img {
    width: 60px;
    @media (max-width: 1185px) {
      ${({ isPopup }) =>
        !isPopup &&
        css`
          display: none;
        `}
    }
  }
`;

export const StyledPricingExample = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  p {
    margin-bottom: 0;
    font-size: 12px;
    font-family: 'Muli';
  }
  .delete {
    color: #606060;
    font-weight: 400;
    text-decoration: line-through;
    font-size: 10px;
    margin: 0 5px;
  }
  .discount {
    color: #ff5c02;
    font-weight: 900;
    font-size: 14px;
  }
`;

export const StyledCoupon = styled.div`
  background: #fff4e8;
  border-radius: 6px;
  font-size: 10px;
  color: #000000;
  padding: 0.8rem;
  span {
    font-weight: 700;
  }
`;
