import React, { useEffect } from 'react';
import parseMilliseconds from 'parse-ms';
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
  setTimeElapsed,
  setTimerEndTime,
  setTimerID,
  setTimeLeft,
  setLastSavedTime,
  setTimerStartTime,
  resetTimer,
} from 'store/timer/timerSlice';
import { setShowTimesUpModal } from 'store/assignment/assignmentSlice';
import { setEventShowTimesUpModal } from 'store/event/eventSlice';
import moment from 'moment-timezone';
import { getCurrentDateTimeInTimezone } from 'helpers/timezone';

const Timer = ({
  startOffset,
  isCountdown,
  isInvisible,
  type,
  endTime,
  useTimezone,
  styles = {},
}) => {
  const store = useStore();
  const dispatch = useDispatch();
  const { timezone } = useSelector((state) => state.login);
  const { timerEndTime, timeElapsed, timeLeft, timerStartTime } = useSelector(
    (state) => state.timer
  );
  const parsedTime =
    isCountdown === true
      ? parseMilliseconds(timeLeft)
      : parseMilliseconds(timeElapsed + startOffset);
  const parsedHours = parsedTime.days * 24 + parsedTime.hours;

  // Set timerStartTime on component mounted
  const eventsEndTime = endTime;
  useEffect(() => {
    const currentDateTime = Date.now();
    dispatch(setTimerStartTime(currentDateTime));
    dispatch(setLastSavedTime(currentDateTime));
    if (isCountdown) {
      dispatch(
        type === 'event-card'
          ? setTimerEndTime(eventsEndTime)
          : setTimerEndTime(
              store.getState().assignment.startAssignmentInfo.EndDate
            )
      );
    }
  }, [dispatch, eventsEndTime, isCountdown, store, type]);
  // Trigger time's up modal when timeLeft <= 0
  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(store.getState().timer.timerID);
      if (type === 'event-card') dispatch(setEventShowTimesUpModal(true));
      else dispatch(setShowTimesUpModal(true));
    }
  }, [dispatch, store, timeLeft, type]);

  useEffect(() => {
    const interval = 1000;
    const timerID = setInterval(() => {
      if (isCountdown && timerEndTime !== null) {
        let currentDateTime;
        let deadline;
        let newTimeLeft;
        if (useTimezone !== true) {
          currentDateTime = moment();
          deadline = moment(timerEndTime);
        } else {
          currentDateTime = getCurrentDateTimeInTimezone(timezone);
          deadline = moment.tz(timerEndTime, timezone);
        }
        newTimeLeft = moment
          .duration(deadline.diff(currentDateTime))
          .as('milliseconds');
        dispatch(setTimeLeft(newTimeLeft));
      }
      dispatch(setTimeElapsed(Date.now() - timerStartTime));
    }, interval);
    dispatch(setTimerID(timerID));
    return () => {
      clearInterval(timerID);
      dispatch(setTimeElapsed(0));
      dispatch(setTimerID(null));
    };
  }, [
    dispatch,
    isCountdown,
    timerEndTime,
    timerStartTime,
    timezone,
    useTimezone,
  ]);
  // Cleanup when timer component is unmounted
  useEffect(() => {
    return () => {
      clearInterval(store.getState().timer.timerID);
      dispatch(resetTimer());
    };
  }, [dispatch, store]);

  return (
    <span style={styles}>
      {!isInvisible &&
        (type === 'event-card'
          ? `${parsedHours < 10 ? `0${parsedHours}` : parsedHours}h ${
              parsedTime.minutes < 10
                ? `0${parsedTime.minutes}`
                : parsedTime.minutes
            }min ${
              parsedTime.seconds < 10
                ? `0${parsedTime.seconds}`
                : parsedTime.seconds
            }sec`
          : `${parsedHours < 10 ? `0${parsedHours}` : parsedHours}:${
              parsedTime.minutes < 10
                ? `0${parsedTime.minutes}`
                : parsedTime.minutes
            }:${
              parsedTime.seconds < 10
                ? `0${parsedTime.seconds}`
                : parsedTime.seconds
            }`)}
    </span>
  );
};

export default Timer;
