import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateUserPreferences } from 'store/userPreferences/userPreferencesSlice';
import { useTranslation } from 'react-i18next';
import ErrorModal from 'components/Shared/ErrorModal';
import useQuery from 'helpers/useQuery';
import { setCurrentProduct } from 'store/plan/planSlice';
import { ALL_SUBJECT_WITH_PLANS } from 'constants/index';

const withSubjectCheck = (WrappedComponent, subject, noNeedChange) => {
  const HOC = () => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();
    const subjectParam =
      subject?.toLowerCase().trim() ||
      query.get('subject')?.toLowerCase().trim();
    const querySubject = ALL_SUBJECT_WITH_PLANS[subjectParam];
    const [error, setError] = useState(false);

    const { subject: subjectName, products, isRedirect } = useSelector(
      (state) => state.plan
    );
    useEffect(() => {
      if (!querySubject) {
        return history.replace('/dashboard');
      }
      if (products && isRedirect) {
        const findSubjectPlan = products?.find(
          ({ subject }) => subjectParam === subject?.toLowerCase().trim()
        )?.nonExpiredSubscriptionProducts?.[0];
        if (!findSubjectPlan) {
          setError(true);
          return;
        }
        if (subjectName !== findSubjectPlan.subject.toLowerCase()) {
          if (!noNeedChange) {
            dispatch(updateUserPreferences(findSubjectPlan.productId));
            dispatch(setCurrentProduct(findSubjectPlan));
          }
        }
      }
    }, [
      dispatch,
      history,
      products,
      subjectName,
      subjectParam,
      querySubject,
      isRedirect,
    ]);
    if (error) {
      return (
        <ErrorModal
          errorMessage={t(
            'common:noAccess',
            `You don't have access to this feature`
          )}
          closeHandler={() => history.replace('/dashboard')}
        />
      );
    }
    return products ? <WrappedComponent /> : null;
  };
  return HOC;
};

export default withSubjectCheck;
