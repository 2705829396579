import React from 'react';
import Header from 'components/Header';
import { Route, Switch } from 'react-router-dom';
import FriendsContainer from './FriendsContainer';
import { Content } from './index.styles';
import { Page } from 'pages/Dashboard/Dashboard.styles';

const Friends = () => {
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          <Route
            exact
            path="/friends"
            render={(props) => (
              <FriendsContainer {...props} activeTab="myFriends" />
            )}
          />
          <Route
            exact
            path="/friends/incoming"
            render={(props) => (
              <FriendsContainer {...props} activeTab="incoming" />
            )}
          />
          <Route
            exact
            path="/friends/sent"
            render={(props) => <FriendsContainer {...props} activeTab="sent" />}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default Friends;
