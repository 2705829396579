import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import {
  requestCountries,
  fetchStudentParentDetail,
  requestOTPEligibility,
} from 'services/trialSignup';

export const getStudentParentDetail = createAsyncThunk(
  'trialSignup/getStudentParentDetail',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchStudentParentDetail, params);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'get parent detail failed');
    }
  }
);

export const getCountries = createAsyncThunk(
  'trialSignup/countries',
  async () => {
    try {
      const res = await fetchWrapper(requestCountries);
      return handleResponseV2(res);
    } catch (error) {
      throw new Error(error?.message ?? 'get countries failed');
    }
  }
);

export const getOTPEligibility = createAsyncThunk(
  'trialSignup/requestOTPEligibility',
  async (_, { getState }) => {
    const { login, studentDetails } = getState();
    const res = await fetchWrapper(requestOTPEligibility, {
      userId: login.userID,
      countryId: studentDetails?.studentDetails?.CountryId,
    });
    return handleResponseV2(res);
  }
);
const initialOtpInfo = {
  parentUserId: null,
  mobileNumber: null,
  mobileCountryCode: null,
  emailAddress: null,
  parentUserName: null,
};

const initialState = {
  isLoading: false,
  isErr: null,
  requestOTP: {},
  otpInfo: initialOtpInfo,
  otpErr: null,
  countries: {
    isLoading: false,
    isErr: null,
    data: [],
  },
  OTPEligibility: {
    isLoading: false,
    error: null,
    isEligible: false,
  },
  OTPCodeRequestBody: null,
};

const trialSignupSlice = createSlice({
  name: 'trialSignup',
  initialState,
  reducers: {
    setOTPRequestBody: (state, action) => {
      state.OTPCodeRequestBody = action.payload;
    },
  },
  extraReducers: {
    [getStudentParentDetail.pending]: (state) => {
      return {
        ...state,
        isLoading: true,
        isErr: null,
        otpInfo: initialOtpInfo,
      };
    },
    [getStudentParentDetail.fulfilled]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isErr: null,
        otpInfo: {
          ...state.otpInfo,
          ...action.payload,
        },
      };
    },
    [getStudentParentDetail.rejected]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        otpInfo: initialOtpInfo,
        isErr: action.error.message,
      };
    },
    [getCountries.pending]: (state) => {
      state.countries.isLoading = true;
      state.countries.data = [];
      state.countries.isErr = null;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.countries.isLoading = false;
      state.countries.data = action.payload?.sort((a, b) =>
        a.printName.localeCompare(b.printName)
      );
    },
    [getCountries.rejected]: (state, action) => {
      state.countries.isLoading = false;
      state.countries.data = [];
      state.countries.isErr = action.error.message;
    },
    [getOTPEligibility.pending]: (state) => {
      state.OTPEligibility.isLoading = true;
      state.OTPEligibility.error = null;
    },
    [getOTPEligibility.fulfilled]: (state, action) => {
      state.OTPEligibility.isLoading = false;
      state.OTPEligibility.isEligible = action.payload.isEligible;
    },
    [getOTPEligibility.rejected]: (state, action) => {
      state.OTPEligibility.isLoading = false;
      state.OTPEligibility.error = action.error.message;
    },
  },
});

const { actions, reducer } = trialSignupSlice;
export const { setOTPRequestBody } = actions;
export default reducer;
