import React, { useEffect, useState } from 'react';
import { plans } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getRewards,
  getTotalCPsBySubject,
  checkDailyBonusClaimed,
} from 'store/dashboard/rewardsSlice';
import { Spinner } from 'react-bootstrap';
import { isNil } from 'ramda';
import KokoCredit from 'assets/img/sidepanel/kokocredit.svg';
// import KokoShop from 'assets/img/sidepanel/kokoshop.svg';
import DailyBonus from 'assets/img/sidepanel/dailybonus.svg';
import DailyBonusClaimed from 'assets/img/sidepanel/claimedDailyBonus.svg';
import { subjectNames } from 'constants/products';
import { ErrorModal, PopupModal } from 'components/Shared';
import { ClaimDailyBonusModal, SidePanelItem, CpBreakDown } from '..';
import Container from './SidePanel.styles';

const SidePanel = ({ type, overPhoneSize }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { plan, subject } = useSelector((state) => state.plan);
  const { rewards, isLoading, isDailyBonusClaimable } = useSelector(
    (state) => state.rewards
  );

  // Component States
  const [showDailyBonusModal, setShowDailyBonusModal] = useState(false);
  const [claimError, setClaimError] = useState(null);
  useEffect(() => {
    if (type === 'right') {
      dispatch(getRewards());
      dispatch(checkDailyBonusClaimed());
    }
  }, [dispatch, type, subject]);
  useEffect(() => {
    if (type === 'right' && subject?.toLowerCase().trim() !== 'math') {
      dispatch(
        getTotalCPsBySubject(subjectNames[subject?.toLowerCase().trim()])
      );
    }
  }, [dispatch, subject, type]);

  const items =
    type === 'left'
      ? plans[plan].leftPanel
      : [
          {
            name: 'KoKo Credits',
            src: KokoCredit,
            value: isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              `${rewards && rewards.TotalKoko}`
            ),
            onClick: () => {
              history.push('/KoKo/kokohistory');
            },
          },
          {
            name: 'Daily Bonus',
            src:
              isDailyBonusClaimable === false ? DailyBonusClaimed : DailyBonus,
            onClick:
              isDailyBonusClaimable === true
                ? () => {
                    setShowDailyBonusModal(true);
                  }
                : null,
            isClaimable: isDailyBonusClaimable,
          },
          // {
          //   name: 'KoKo Shop',
          //   src: KokoShop,
          //   isFeatureReady: false,
          // },
        ];

  useEffect(() => {
    if (!isNil(claimError)) {
      setShowDailyBonusModal(false);
    }
  }, [claimError]);

  return (
    <Container type={type} overPhoneSize={overPhoneSize}>
      {!isNil(claimError) && (
        <ErrorModal
          errorMessage={claimError}
          closeHandler={() => setClaimError(null)}
        />
      )}
      <PopupModal
        show={showDailyBonusModal}
        backdrop="static"
        hide={() => {
          setShowDailyBonusModal(false);
        }}
      >
        <ClaimDailyBonusModal
          onHide={() => {
            setShowDailyBonusModal(false);
          }}
          setError={setClaimError}
        />
      </PopupModal>
      {type === 'right' && <CpBreakDown />}
      {items.map((item) => (
        <SidePanelItem
          key={item.name}
          name={item.name}
          img={item.src}
          type={type}
          link={item.link}
          value={item.value}
          isFeatureReady={item.isFeatureReady}
          onClickHandler={item.onClick}
          isClaimable={item.isClaimable}
          isDailyBonusClaimable={isDailyBonusClaimable}
        />
      ))}
    </Container>
  );
};

export default SidePanel;
