import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from './Modals.styles';

const WarningModal = ({ onCheckWarning }) => {
  const { t } = useTranslation(['dailyChallengev2']);
  return (
    <ModalContainer>
      <div className="dc-alert-modal">
        <p>
          {t(
            'dailyChallengev2:warning.content',
            'It seems that you have already completed at least one question. You cannot change the difficulty level for today.'
          )}{' '}
        </p>
        <button type="button" onClick={onCheckWarning}>
          {t('dailyChallengev2:warning.ok', 'OK')}
        </button>
      </div>
    </ModalContainer>
  );
};
export default WarningModal;
