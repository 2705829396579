import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import {
  getKooClassSolutions,
  startActivity,
} from 'store/kooClass/kooClassSlice';

import { Button, DetailsInfo } from 'components/Shared';

import { ReactComponent as ScrollIcon } from 'assets/img/icon-scroll.svg';
import { ReactComponent as QnMark } from './icon-qn-mark.svg';

import { Container } from './DoAndLearnDetails.styles';

const DoAndLearnDetails = ({ status, setShowModal, courseId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['activities', 'common']);
  const {
    currentActivity,
    startKooClassInfo,
    kooClassSolutions,
    isKooClassSolutionsLoading,
    error,
    isKooClassSolutionsError,
  } = useSelector((state) => state.kooClass);
  const { lessonId, topicId } = useParams();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const activityId = query.get('activityId');
  const activityType = query.get('activityType');

  const reload = () => {
    return (
      <div
        className="reload"
        onClick={() => dispatch(getKooClassSolutions(activityId))}
      >
        <p>{t('common:reload', 'Reload')}</p>
      </div>
    );
  };

  // get total number of correct qn and total qn
  const totalCorrect =
    kooClassSolutions && kooClassSolutions.userSubmissions.length > 0
      ? kooClassSolutions.userSubmissions.filter(
          (item) =>
            !isNil(item.submissionModel) &&
            item.submissionModel.submissionResult === 1
        ).length
      : 0;

  const totalQuestions = kooClassSolutions
    ? kooClassSolutions.userSubmissions.length
    : 0;

  const totalScore = kooClassSolutions
    ? Math.floor((totalCorrect / totalQuestions) * 100)
    : 0;

  const score = () => {
    return (
      <div className="score">
        <p className="percentage">
          {totalScore} <span>%</span>
        </p>
        <div className="score-detail">
          (<span>{totalCorrect}</span>
          <p>
            {t('activities:doAndLearn.startPage.outOf', ' out of ')}
            <strong>{totalQuestions}</strong>
          </p>
          )
        </div>
      </div>
    );
  };

  const rewards = (maxScroll, scroll, status) => {
    return (
      <div className="scroll-rewards">
        <ScrollIcon style={{ height: '30px' }} />
        {status === 'Completed' ? (
          <p>
            {scroll} / {maxScroll}{' '}
            {t('activities:doAndLearn.startPage.scroll', 'scroll')}
          </p>
        ) : (
          <p>
            {maxScroll} {t('activities:doAndLearn.startPage.scroll', 'scroll')}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (
      status === 'Completed' &&
      (activityType === '3' || activityType === '4')
    ) {
      dispatch(getKooClassSolutions(activityId));
    }
  }, [dispatch, status, activityId, activityType]);

  useEffect(() => {
    if (activityId && status && status !== 'Completed') {
      dispatch(startActivity(activityId));
    }
  }, [dispatch, activityId, status]);

  return (
    <Container status={status} score={totalScore}>
      {!isKooClassSolutionsLoading && (
        <div className="desc">
          <QnMark />
          <p>{currentActivity.description}</p>
        </div>
      )}
      {status !== 'Completed' && (
        <>
          <div className="details-box">
            <div className="details">
              <DetailsInfo
                category="Rewards"
                info={rewards(currentActivity.maxPoints)}
                noSpace
              />
              <DetailsInfo
                category="Total Qns"
                info={
                  startKooClassInfo &&
                  startKooClassInfo.userPayload.userSubmissions.length
                }
                noSpace
              />
              <DetailsInfo category="Score" info="-" noSpace />
            </div>
            <hr />
            <DetailsInfo
              category="Instruction"
              info={
                startKooClassInfo && startKooClassInfo.userPayload.description
              }
              noSpace
            />
          </div>
          <div className="start-btn">
            <Button
              dataCy="start-btn"
              fontSize="26px"
              fontWeight="bold"
              variant="primary"
              width="100%"
              isDisabled={!isNil(error)}
              onClick={() =>
                history.push(
                  `/kooClass/open/${activityId}/${topicId}/${lessonId}/${activityType}?courseId=${courseId}`
                )
              }
            >
              {t('activities:doAndLearn.startPage.startNow', 'Start Now')}
            </Button>
          </div>
        </>
      )}
      {status === 'Completed' && !isKooClassSolutionsLoading && (
        <>
          <div className="details-box">
            <DetailsInfo category="Assigned by" info="KooBits" noSpace />
            <div className="details">
              <DetailsInfo
                category="Rewards"
                info={rewards(
                  currentActivity.maxPoints,
                  currentActivity.currentUserPoints,
                  status
                )}
                noSpace
              />
              <DetailsInfo
                category="Total Qns"
                info={
                  !isNil(isKooClassSolutionsError) ? reload() : totalQuestions
                }
                noSpace
              />
              <DetailsInfo
                category="Score"
                info={!isNil(isKooClassSolutionsError) ? reload() : score()}
                noSpace
              />
            </div>
          </div>
          <div className="start-btn">
            {status === 'Completed' && (
              <>
                <Button
                  dataCy="try-btn"
                  fontSize="22px"
                  fontWeight="600"
                  variant="secondary"
                  width="230px"
                  onClick={() => setShowModal(true)}
                >
                  {t('activities:doAndLearn.startPage.tryAgain', 'Try Again')}
                </Button>
                {totalScore !== 0 && (
                  <Button
                    dataCy="solution-btn"
                    fontSize="22px"
                    fontWeight="600"
                    variant="primary"
                    width="230px"
                    onClick={() =>
                      history.push(
                        `/kooClass/${topicId}/solution/${lessonId}/${activityId}?courseId=${courseId}`
                      )
                    }
                  >
                    {t(
                      'activities:doAndLearn.startPage.showSolution',
                      'Show Solution'
                    )}
                  </Button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default DoAndLearnDetails;
