import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { difficultyLevels } from 'constants/dailyChallenge';
import { isNil } from 'ramda';
import { firstTimeUpdateDifficulty } from 'store/dailyChallenge/dailyChallengeSlice';
import {
  SubmitModal,
  PopupModal,
  Spinner,
  ErrorModal,
} from 'components/Shared';
import { compareValues } from 'helpers/compareValue';
import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import {
  Container,
  CurrentDifficulty,
  DifficultyOptions,
  Button,
} from './DifficultySelection.styles';
import DifficultyStars from './DifficultyStars';
import DifficultyOptionCard from './DifficultyOptionCard';

const DifficultySelection = ({
  difficultyLevel,
  setShowSelection,
  showSelection,
  maxChallengeLvl,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallenge']);
  const { currDailyChallenge, isLoading, isErr } = useSelector(
    (state) => state.dailyChallenge
  );
  // Component States
  const [selectedLevel, setSelectedLevel] = useState(
    difficultyLevel === 0 ? 1 : difficultyLevel
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [callUpdateAction, setCallUpdateAction] = useState(false);
  const levels = Object.values(difficultyLevels).sort(
    compareValues('CPsRequired', 'asc')
  );
  const selectedDifficulty = difficultyLevel === 0 ? 1 : difficultyLevel;
  // responsive
  const isBelowTablet = useIsBelowTablet();
  const wrapIfNotDesktop = (component) =>
    !isBelowTablet ? component : <div>{component}</div>;

  useEffect(() => {
    if (callUpdateAction) {
      if (!isErr) {
        setShowSelection(false);
      }
      setCallUpdateAction(false);
    }
    // eslint-disable-next-line
  }, [callUpdateAction, isErr]);
  const updateDifficultyAction = async () => {
    if (showConfirmationModal) {
      setShowConfirmationModal(false);
    }
    await dispatch(
      firstTimeUpdateDifficulty(difficultyLevels[selectedLevel].challengeLevel)
    );
    setCallUpdateAction(true);
  };
  const updateDifficultyHandle = () => {
    if (currDailyChallenge.IsFirstTimeUser) {
      updateDifficultyAction();
    } else {
      const skippedOrCompletedQns = currDailyChallenge.UserQuestionSubmissions.filter(
        (submission) =>
          submission.SubmissionModel !== null &&
          (submission.SubmissionModel.SubmissionResult === 1 ||
            submission.QuestionStatus === 4)
      );
      if (skippedOrCompletedQns.length > 0) {
        setShowConfirmationModal(true);
      } else {
        updateDifficultyAction();
      }
    }
  };

  const checkUnlock = (name) => {
    if (name === 'Elementary' && maxChallengeLvl >= 0) {
      return true;
    }
    if (name === 'Intermediate' && maxChallengeLvl >= 2) {
      return true;
    }
    if (
      name === 'Advanced' &&
      (maxChallengeLvl === 2 || maxChallengeLvl >= 4)
    ) {
      return true;
    }
    if (name === 'Master' && maxChallengeLvl >= 4) {
      return true;
    }
    return false;
  };
  return (
    <Container>
      <PopupModal show={showConfirmationModal} backdrop="static">
        <SubmitModal
          header={t(
            'dailyChallenge:difficultySelection.modalHeader',
            'Daily Challenge Settings'
          )}
          title={t(
            'dailyChallenge:difficultySelection.modalTitle',
            "The changed settings will be reflected for tomorrow's Daily Challenge."
          )}
          desc={t(
            'dailyChallenge:difficultySelection.modalDesc',
            {
              challengeName: t(
                `dailyChallenge:difficultySelection.${difficultyLevels[selectedLevel].name}`,
                `${difficultyLevels[selectedLevel].name}`
              ),
            },
            `Are you sure you want to select the ${difficultyLevels[selectedLevel].name} Challenge for your Daily Challenge questions? Click Yes to confirm.`
          )}
          noHandle={() => {
            setShowConfirmationModal(false);
          }}
          yesHandle={updateDifficultyAction}
        />
      </PopupModal>
      <CurrentDifficulty>
        {wrapIfNotDesktop(
          <>
            <DifficultyStars
              numOfStars={difficultyLevels[selectedDifficulty].numOfStars}
            />
            <span className="difficulty-level">
              {t(
                `dailyChallenge:difficultySelection.${difficultyLevels[selectedDifficulty].name}Diff`,
                `${difficultyLevels[selectedDifficulty].name} Difficulty`
              )}
            </span>
          </>
        )}

        <span className="difficulty-description">
          {t(
            `dailyChallenge:difficultySelection.${difficultyLevels[selectedDifficulty].translation}`,
            `${difficultyLevels[selectedDifficulty].description}`
          )}
        </span>
      </CurrentDifficulty>
      <DifficultyOptions>
        {levels.map((level, index) => (
          <DifficultyOptionCard
            level={level}
            key={level.name}
            isActive={selectedLevel === level.challengeLevel}
            onClick={() => setSelectedLevel(level.challengeLevel)}
            isUnlocked={checkUnlock(level.name)}
            dataCy={
              selectedLevel === level.challengeLevel
                ? 'select-level'
                : 'unselect-level'
            }
          />
        ))}
      </DifficultyOptions>
      {showSelection ? (
        <>
          <span
            className="cancel-button"
            onClick={() => setShowSelection(false)}
          >
            {t('dailyChallenge:difficultySelection.cancel', 'Cancel')}
          </span>
          <Button
            data-cy="save-btn"
            onClick={updateDifficultyHandle}
            disabled={
              currDailyChallenge.LatestChallengeLevel === selectedLevel ||
              isLoading
            }
          >
            {isLoading ? (
              <Spinner small color="white" top="-1.5rem" />
            ) : (
              t('dailyChallenge:difficultySelection.save', 'Save')
            )}
          </Button>
        </>
      ) : (
        <Button
          data-cy="save-btn"
          onClick={updateDifficultyHandle}
          disabled={isNil(selectedLevel) || isLoading}
        >
          {t('dailyChallenge:difficultySelection.start', "Let's Start")}
        </Button>
      )}

      {isErr && <ErrorModal errorMessage={isErr} />}
    </Container>
  );
};

export default DifficultySelection;
