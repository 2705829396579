import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const StyledContainer = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: baseline;
  span {
    color: #adadad;
    font-size: 12px;
    margin-right: 10px;
  }
`;

export const HideOnMobile = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
export const InfoTooltip = styled(Tooltip)`
  .tooltip-inner {
    border-radius: 14px;
    padding: 14px 22px;
    font-size: 14px;
    font-weight: 700;
    font-family: Linotte;
    display: inline-block;
  }
`;
