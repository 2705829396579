import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import ModalVideo from 'react-modal-video';

import {
  getSelfPracticeQuestion,
  getMissionPlusQuestions,
  incrementSelfPracticeActiveQn,
  resetSaveSelfPracticeAns,
  resetSubmitAns,
} from 'store/mission/missionSlice';
import { clearAll } from 'store/assignment/barModelSlice';
import { hideAllTools } from 'store/assignment/assistToolsSlice';
import { setClearDraw } from 'store/assignment/drawingKitsSlice';

import NextIcon from 'assets/img/assignment/back-icon.svg';
import PlayVideoIcon from 'assets/img/mission/play-video.png';

import { submissionTypeIDs, questionTypeIDs } from 'constants/index';

import { Button, ThatsRight } from 'components/Shared';
import QuestionContent from 'components/Question';
import Solution from 'components/Question/Solution';
import { clearValues } from 'components/Shared/HelperSidebar/AssistTools/FourOpsTool/FourOpsTool';
import { AlertText, DifficultyChillisInfo } from '..';

import { Container, ErrorMessage } from './MissionNoteBookMiddle.styles';

const MissionNoteBookMiddle = ({
  question,
  answer,
  setAnswer,
  topicId,
  setCanClaim,
  qnNo,
  errors,
  submitAnswer,
  isLoading,
  isMissionPlus,
  topicSkillId,
  stageTypeId,
  videoCode = null,
}) => {
  const { t } = useTranslation(['selfPracticeQnView']);
  const dispatch = useDispatch();
  const { submitAns } = useSelector((state) => state.mission);

  const [showSolution, setShowSolution] = useState(false);
  const [toTop, setToTop] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const submissionType = submissionTypeIDs.SkillPracticeMS;
  const qnType = questionTypeIDs[question && question.QuestionType];

  const showSol = () => {
    setShowSolution(!showSolution);
  };

  useEffect(() => {
    if (answer === 'correct') {
      setShowSolution(true);
      setToTop(true);
    } else if (answer === 'wrong') {
      setToTop(true);
    }
  }, [answer]);

  // Ensure banner and that's right section is visible by scrolling to the top
  useEffect(() => {
    if (toTop === true) {
      window.scrollTo({ top: 0 });
    }
  }, [toTop]);
  const handleNextQuestion = () => {
    dispatch(resetSaveSelfPracticeAns());
    dispatch(resetSubmitAns());
    dispatch(hideAllTools());
    dispatch(clearAll());
    clearValues();
    dispatch(incrementSelfPracticeActiveQn());
    if (isMissionPlus) {
      dispatch(getMissionPlusQuestions({ topicSkillId, stageTypeId }));
    } else {
      dispatch(getSelfPracticeQuestion(topicId));
    }
    setCanClaim(false);
    setAnswer('unknown');
    dispatch(setClearDraw(true));
  };

  return (
    <Container isWrong={answer === 'wrong'} isLast={qnNo === 10}>
      <ModalVideo
        channel="youtube"
        isOpen={showVideo}
        youtube={{
          autoplay: 1,
          mute: 0,
          rel: 0,
        }}
        videoId={videoCode}
        onClose={() => setShowVideo(false)}
      />
      {answer === 'wrong' && (
        <div className="alert-container">
          <AlertText
            text={t(
              'selfPracticeQnView:content.sorry',
              'Sorry, your answer is incorrect.'
            )}
            color="#FF3303"
            bgColor="rgb(255, 241, 241, 0.77)"
            fontSize="18px"
          />
        </div>
      )}
      {answer === 'correct' && (
        <>
          <ThatsRight
            loop={false}
            text={t('selfPracticeQnView:content.right', "That's Right")}
            hasSound
          />
          {submitAns && !submitAns.CanClaim && qnNo !== 10 && (
            <div className="centered-next-qn-btn">
              <Button
                dataCy="next-btn"
                variant="primary"
                onClick={handleNextQuestion}
              >
                {t('selfPracticeQnView:content.nextQn', 'Next Question')}
                <span>
                  <img className="next-icon" src={NextIcon} alt="" />
                </span>
              </Button>
            </div>
          )}
        </>
      )}
      <DifficultyChillisInfo
        t={t}
        question={question}
        containerClass="qn-view-mobile"
      />
      <div>
        <QuestionContent
          page="self-practice"
          submissionType={submissionType}
          questionType={qnType}
          qnID={question && question.Id}
          xml={
            question &&
            (answer === 'unknown'
              ? question.Payload
              : submitAns.QuestionSubmissionModel.Payload)
          }
          showSolution={false}
          showSubmissions={answer !== 'unknown'}
          assignmentType="self-practice"
          correctAnswer={answer}
          errors={errors}
        />
        {!isEmpty(errors) && (
          <ErrorMessage data-cy="error-message">{errors.error}</ErrorMessage>
        )}
      </div>
      {answer !== 'unknown' && (
        <div className="bottom-section">
          {answer !== 'correct' && submitAns && (
            <div className="next-qn-btn">
              {!submitAns.CanClaim && qnNo !== 10 && (
                <Button
                  dataCy="next-btn"
                  variant="primary"
                  onClick={handleNextQuestion}
                >
                  {t('selfPracticeQnView:content.nextQn', 'Next Question')}
                  <span>
                    <img className="next-icon" src={NextIcon} alt="" />
                  </span>
                </Button>
              )}
              <div className="secondary-action-btn">
                {!isNil(videoCode) && (
                  <Button
                    variant="blue5"
                    className="video-btn"
                    onClick={() => setShowVideo(true)}
                  >
                    <img
                      src={PlayVideoIcon}
                      alt="Play Video"
                      className="play-video"
                    />
                    Video Tutorial
                  </Button>
                )}
                <Button variant="blue5" onClick={showSol}>
                  {showSolution
                    ? t(
                        'selfPracticeQnView:content.hideSolution',
                        'Hide Solution'
                      )
                    : t(
                        'selfPracticeQnView:content.showSolution',
                        'Show solution'
                      )}
                </Button>
              </div>
            </div>
          )}

          {/* show solution based on status */}
          {showSolution && answer === 'wrong' && (
            <div className="solution-container">
              <p className="title">
                {t('selfPracticeQnView:content.solution', 'Solution')}
              </p>
              <Solution xml={submitAns.QuestionSubmissionModel.Payload} />
            </div>
          )}

          {answer === 'correct' && (
            <div className="show-solution">
              <button
                type="button"
                className="title"
                onClick={showSol}
                data-cy="solution-btn"
              >
                {showSolution
                  ? t(
                      'selfPracticeQnView:content.hideSolution',
                      'Hide Solution'
                    )
                  : t(
                      'selfPracticeQnView:content.showSolution',
                      'Show solution'
                    )}
              </button>
              {showSolution && (
                <Solution xml={submitAns.QuestionSubmissionModel.Payload} />
              )}
            </div>
          )}
        </div>
      )}
      {answer === 'unknown' && (
        <div className="btn">
          <Button
            dataCy="submit-btn"
            variant="primary"
            onClick={submitAnswer}
            isDisabled={isLoading}
          >
            {t('selfPracticeQnView:content.submitAnswer', 'Submit Answer')}
          </Button>
        </div>
      )}
    </Container>
  );
};

export default MissionNoteBookMiddle;
