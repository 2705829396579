import {
  EssentialSkillsIcon,
  TopSchoolPaperIcon,
  HOTSPapersIcon,
  OpenEndedQuestionIcon,
  ClozeIcon,
} from 'assets/img/kooquiz';
import {
  MathStartBg,
  ScienceStartBg,
  EnglishStartBg,
  MathBag,
  MathBox,
  ScienceBag,
  ScienceBox,
  Bag,
} from 'assets/img/kooquiz/kooQuizQn';
import { SUBJECTS } from './products';

export const status = {
  0: 'Not Started',
  1: 'In progress',
  2: 'Completed',
};
export const kooQuizTypes = {
  1: 'KooQuizMaths',
  2: 'KooQuizSci',
  3: 'KooQuizEnglish',
};

export const AVAILABLE_LEVELS = {
  math: [1, 2, 3, 4, 5, 6],
  science: [3, 4, 5, 6],
  english: [1, 2, 3, 4, 5, 6],
};
export const KOO_QUIZ_PAPER = {
  EssentialSkills: 'Essential Skills',
  TopPapers: 'Top Papers',
  HOTS: 'HOTS',
  OED: 'OED',
  VocabularyCloze: 'Vocabulary Cloze',
};
export const KOO_QUIZ_PAPER_TYPE = {
  [KOO_QUIZ_PAPER.EssentialSkills]: 1,
  [KOO_QUIZ_PAPER.TopPapers]: 2,
  [KOO_QUIZ_PAPER.HOTS]: 3,
  [KOO_QUIZ_PAPER.OED]: 4,
  [KOO_QUIZ_PAPER.VocabularyCloze]: 5,
};
export const KOO_QUIZ_PAPER_TYPE_ID = Object.entries(
  KOO_QUIZ_PAPER_TYPE
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});
export const kooQuizPaperTypeInternal = {
  51: KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.EssentialSkills], // 'Essential Skills',
  52: KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.TopPapers], // 'Top Papers',
  53: '', // 'Maths Olympiad',
  54: KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.HOTS], // 'HOTS Papers',
  55: KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.OED], // 'OED',
  56: KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.VocabularyCloze], // 'Cloze',
};

export const FILTER_SUBJECT_STATUS = {
  KooQuizMaths: SUBJECTS.MATH.toLowerCase(),
  KooQuizSci: SUBJECTS.SCIENCE.toLowerCase(),
  KooQuizEnglish: SUBJECTS.ENGLISH.toLowerCase(),
};

export const KOOQUIZ_SUBJECT_ID = {
  KooQuizMaths: 1,
  KooQuizSci: 2,
  KooQuizEnglish: 3,
};

export const KOOQUIZ_PAPERS = [
  {
    id: 1,
    name: 'Essential Skills',
    icon: EssentialSkillsIcon,
    iconWidth: '32px',
    fontSize: '15px',
    subjects: ['math'],
    paramName: 'Essential Skills',
    gift: 'Gift Bag',
    paperAlignment: {
      start: {
        shadowLeft: '12px',
        shadowWidth: '235px',
      },
      preview: {
        shadowLeft: '12px',
        shadowWidth: '235px',
      },
    },
  },
  {
    id: 2,
    name: 'Top School Year-End Paper',
    icon: TopSchoolPaperIcon,
    iconWidth: '26px',
    fontSize: '14px',
    subjects: ['math', 'science'],
    paramName: 'Top Papers',
    gift: 'Gift Box',
    paperAlignment: {
      start: {
        shadowLeft: '35px',
        shadowWidth: '220px',
      },
      preview: { shadowLeft: '35px', shadowWidth: '220px' },
    },
  },
  {
    id: 3,
    name: 'HOTS Papers',
    icon: HOTSPapersIcon,
    iconWidth: '32px',
    fontSize: '14px',
    subjects: ['math'],
    paramName: 'HOTS',
    gift: 'Gift Box',
    paperAlignment: {
      start: {
        shadowLeft: '12px',
        shadowWidth: '235px',
      },
      preview: {
        shadowLeft: '35px',
        shadowWidth: '235px',
      },
    },
  },
  {
    id: 4,
    name: 'Open-Ended Questions',
    icon: OpenEndedQuestionIcon,
    iconWidth: '34px',
    fontSize: '14px',
    subjects: ['science'],
    paramName: 'OED',
    gift: 'Gift Box',
    paperAlignment: {
      start: {
        shadowLeft: '12px',
        shadowWidth: '235px',
      },
      preview: {
        shadowLeft: '35px',
        shadowWidth: '235px',
      },
    },
  },
  {
    id: 5,
    name: 'Vocabulary Cloze',
    icon: ClozeIcon,
    iconWidth: '34px',
    fontSize: '14px',
    subjects: ['english'],
    paramName: 'Vocabulary Cloze',
    gift: 'Gift Bag',
    paperAlignment: {
      start: {
        shadowLeft: '12px',
        shadowWidth: '235px',
      },
      preview: {
        shadowLeft: '12px',
        shadowWidth: '235px',
      },
    },
  },
];

export const KOOQUIZ_STYLES = {
  1: {
    subject: 'math',
    bgColor: '#304091',
    bgImg: MathStartBg,
    boxShadow: 'rgba(30, 41, 96, 0.5)',
    assetColor: 'rgba(31, 42, 98, 1) 0%',
    backButtonColor: '#394EB1',
    hotsCardReflection: 'rgba(31, 42, 98, 1) 0%',
    bag: MathBag,
    box: MathBox,
  },
  2: {
    subject: 'science',
    bgColor: '#007786',
    bgImg: ScienceStartBg,
    boxShadow: 'rgba(0, 86, 106, 0.39)',
    assetColor: 'rgba(5, 88, 103, 1) 0%',
    backButtonColor: '#23C5A8',
    hotsCardReflection: 'rgba(1, 24, 28, 0.38) 0%',
    bag: ScienceBag,
    box: ScienceBox,
  },
  3: {
    subject: 'english',
    bgColor: '#C48A09',
    bgImg: EnglishStartBg,
    boxShadow: 'rgba(153, 101, 51, 0.39)',
    assetColor: 'rgba(153, 101, 51, 1) 0%',
    backButtonColor: '#B37B19',
    hotsCardReflection: 'rgba(153, 101, 51, 1) 0%',
    bag: Bag,
    box: ScienceBox,
  },
};
