import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import { getMultiplayerList } from 'store/multiplayer/multiplayerListSlice';

import { subjectIDs, subjectNames } from 'constants/products';
import { compareValues } from 'helpers/compareValue';
import checkIsOpen from 'helpers/Dashboard/checkIsOpen';
import useDashboard from 'hooks/useDashboard';

import { PeerChallengeCard } from './components';
import { Spinner, ErrorModal, BackButton } from 'components/Shared';
import { Topbar, Content } from './MultiplayerChallengeList.styles';

// HELPERS
const getNonExpiredProductSubjectIds = (products) => {
  if (isNil(products)) return [];
  return products
    .filter((p) => {
      const isSubscribed = p.nonExpiredSubscriptionProducts.length > 0;
      if (!isSubscribed) return false;
      const subProductPCFeature =
        p.nonExpiredSubscriptionProducts[0].features.find(
          (feature) => feature.feature === 'PC'
        );
      const isPCEnabled =
        !isNil(subProductPCFeature) &&
        subProductPCFeature?.parameters?.IsEnabled === true;
      return isPCEnabled;
    })
    .map((p) => {
      const subProductPCFeature =
        p.nonExpiredSubscriptionProducts[0].features.find(
          (feature) => feature.feature === 'PC'
        );
      return {
        subjectId: subjectNames[p.subject.toLowerCase().trim()] ?? 99,
        totalEnergyPerDay: subProductPCFeature.parameters.limitperday ?? 0,
      };
    })
    .sort(compareValues('subjectId', 'asc'));
};

const MultiplayerChallengeList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation(['multiplayerChallengeList', 'common']);
  const { isDashboardv2 } = useDashboard();
  const { timezone, userID } = useSelector((state) => state.login);
  const {
    multiplayerList,
    isLoading: isPCLoading,
    error,
  } = useSelector((state) => state.multiplayerList);

  const { isLoading: isProductsLoading, products } = useSelector(
    (state) => state.plan
  );
  const isLoading = isPCLoading || isProductsLoading;
  const isFromShortCut = location.state?.quickAccess;
  const isOpen = checkIsOpen(timezone);

  useEffect(() => {
    if (!userID || isEmpty(products)) return;
    const subjectIdsWithSubscription = getNonExpiredProductSubjectIds(products);
    if (isEmpty(subjectIdsWithSubscription)) return;
    dispatch(
      getMultiplayerList({
        userID,
        products: subjectIdsWithSubscription,
      })
    );
  }, [dispatch, userID, products]);

  const challengeStatus = (challenge) => {
    if (
      isOpen &&
      challenge.PCNumOfRemainingChallenges > 0 &&
      challenge.PCNumOfIncomingChallenges > 0
    ) {
      return 'view';
    }
    if (isOpen && challenge.PCNumOfRemainingChallenges > 0) {
      return 'open';
    }
    if (isOpen && challenge.PCNumOfRemainingChallenges <= 0) {
      return 'history';
    }
    if (!isOpen) {
      return 'close';
    }
  };

  return (
    <>
      <Topbar>
        <BackButton
          backText={t('common:back', 'Back')}
          btnBgColor="rgb(214,125,17,0.61)"
          padding="5px 20px"
          onClick={() => {
            if (isFromShortCut) {
              history.goBack();
              return;
            }
            const destination = isDashboardv2
              ? '/dashboard?view=koochallenge'
              : '/dashboard';
            history.push(destination);
          }}
          styles={{ position: 'absolute', left: '3%' }}
          isResponsive
        />
        {t('multiplayerChallengeList:header.multiplayer', 'Multiplayer')}
      </Topbar>
      <Content data-cy="list-container">
        {isLoading && <Spinner />}
        {!isLoading &&
          error === null &&
          multiplayerList.map((data) => (
            <PeerChallengeCard
              key={data.ChallengeId}
              id={data.ChallengeId}
              name={data.Name}
              desc={data.Description}
              newChallenge={data.PCNumOfIncomingChallenges}
              challengeRemaining={data.PCNumOfRemainingChallenges}
              challengeStatus={challengeStatus(data)}
              subject={subjectIDs[data.SubjectId]}
              totalEnergyPerDay={data.totalEnergyPerDay}
            />
          ))}
        {error !== null && (
          <ErrorModal backPage="/dashboard" errorMessage={error} />
        )}
      </Content>
    </>
  );
};

export default MultiplayerChallengeList;
