/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Overlay } from 'react-bootstrap';
import { isNil } from 'ramda';
import Avatar from 'assets/img/avatar.png';
import CameraIcon from 'assets/img/icon-camera.svg';
import Camera from 'assets/img/icon-camera-only.svg';
import Button from 'components/Shared/Button';
import Spinner from 'components/Shared/Spinner';
import DropdownMenu from 'components/Shared/DropdownMenu';
import PopupModal from 'components/Shared/PopupModal';
import ResultModal from 'components/Shared/ResultModal';
import ErrorModal from 'components/Shared/ErrorModal';
import usePrimaryLevelsI18n from 'helpers/usePrimaryLevelsI18n';
import useCheckCurrentChinese from 'helpers/useCheckCurrentChinese';
import { genderId } from 'constants/profileSettings';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import {
  updateProfilePicture,
  resetProfilePictureSuccess,
  setBase64String,
  resetError,
} from 'store/profileSettings/profileSettingsSlice';
import {
  updateGender,
  clearUpdateErr,
} from 'store/boysVsGirls/boysVsGirlsSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { PRODUCT_TYPE } from 'constants/products';
import {
  Container,
  ProfileCard,
  DetailsInfo,
  TooltipIcon,
  TooltipMsgContainer,
} from './ProfileSummary.styles';

// Helper
const generateProfilePicUrl = (imgUrl, dateHash) => {
  if (!isNil(dateHash)) {
    return `${imgUrl}?${dateHash}`;
  }
  return imgUrl;
};
const ProfileSummary = () => {
  const { t } = useTranslation(['myProfile', 'errorModal', 'leaderboard']);
  const dispatch = useDispatch();
  const { studentDetails, isLoading, defaultLevel, error } = useSelector(
    (state) => state.studentDetails
  );
  const { plan, allNonExpiredSubscriptionProducts } = useSelector(
    (state) => state.plan
  );
  const i18nLevel = usePrimaryLevelsI18n(defaultLevel);
  const isChinese = useCheckCurrentChinese();
  const {
    profilePictureUpdateSuccess,
    isErr,
    base64String,
    profilePictureDateHash,
  } = useSelector((state) => state.profileSettings);
  const { genderUpdate, isLoadingGenderUpdate, isErrGenderUpdate } =
    useSelector((state) => state.boysVsGirls);

  const [uploadedPhoto, setUploadedPhoto] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [editGender, setEditGender] = useState(false);
  const [gender, setGender] = useState(null);
  const [showSuccessModal, setShowSuccesModal] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const target = useRef(null);
  const genderUpdated = !!(
    studentDetails && studentDetails.GenderUpdateCount > 0
  );

  const genderList = [
    { id: 1, gender: t('myProfile:picture.Male', 'Male') },
    { id: 2, gender: t('myProfile:picture.Female', 'Female') },
  ];

  const uploadPhoto = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      setFileType(file.type);
      setFileSize(file.size / 1024 / 1024);
      reader.onload = (readerEvent) => {
        const binaryString = readerEvent.target.result;
        dispatch(setBase64String(btoa(binaryString)));
        setUploadedPhoto(true);
      };
      reader.readAsBinaryString(file);
    }
  };

  useEffect(() => {
    dispatch(getStudentDetails());
  }, [dispatch, genderUpdate]);

  useEffect(() => {
    if (uploadedPhoto && fileSize < 2) {
      dispatch(
        updateProfilePicture({
          ImageBase64: base64String,
        })
      );
    }
  }, [base64String, dispatch, fileSize, uploadedPhoto]);

  useEffect(() => {
    if (profilePictureUpdateSuccess) {
      dispatch(resetProfilePictureSuccess());
      setUploadedPhoto(false);
    }
  }, [dispatch, profilePictureUpdateSuccess]);

  useEffect(() => {
    return () => {
      dispatch(setBase64String(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (fileSize > 2) {
      setUploadError('We only support JPG, PNG format, below 2 MB.');
    }
    if (!isNil(isErr)) {
      setUploadError(isErr);
    }
  }, [fileSize, isErr]);

  const reloadHandler = () => {
    if (!isNil(error)) {
      dispatch(getStudentDetails());
    }
    if (!isNil(uploadError)) {
      setUploadError(null);
      dispatch(resetError());
      dispatch(getStudentDetails());
    }
    if (!isNil(isErrGenderUpdate)) {
      dispatch(clearUpdateErr());
    }
  };
  const saveGenderHandler = async () => {
    try {
      const resultAction = await dispatch(updateGender(gender.id));
      unwrapResult(resultAction);
      setShowSuccesModal(true);
    } catch (err) {
      setGender(null);
    }
  };
  return (
    <Container
      onClick={() => {
        if (showPopover === true) {
          setShowPopover(false);
        }
      }}
    >
      <PopupModal
        show={showSuccessModal}
        hide={() => {
          setShowSuccesModal(false);
        }}
      >
        <ResultModal
          data={genderUpdate}
          btnText={t('myProfile:password.Confirm', 'Confirm')}
          desc={t(
            'myProfile:picture.GenderSuccesModal',
            'Your gender has been changed successfully.'
          )}
          loading={isLoadingGenderUpdate}
          onClick={() => {
            if (isNil(isErrGenderUpdate)) setEditGender(false);
            setShowSuccesModal(false);
          }}
        />
      </PopupModal>
      {isLoading && <Spinner />}
      {!isLoading && studentDetails && error === null && (
        <>
          <ProfileCard>
            <div className="left-section">
              <div className="tool-tip">
                <TooltipIcon
                  ref={target}
                  onClick={() => setShowPopover(!showPopover)}
                >
                  i
                </TooltipIcon>
                <Overlay
                  target={target.current}
                  placement="right"
                  show={showPopover}
                >
                  {(props) => (
                    <TooltipMsgContainer {...props}>
                      <p>{t('myProfile:picture.Notice', 'Notice')}</p>
                      <p>
                        <Trans i18nKey="myProfile:picture.Upload">
                          Please upload proper profile picture. Your profile
                          picture will be viewed on your
                          <strong>
                            report, certificate and teacher or parents App after
                            verified.
                          </strong>
                        </Trans>
                      </p>
                      <p className="support-img">
                        <Trans i18nKey="myProfile:picture.SupportFormat">
                          We only support <strong>JPG, PNG</strong> and
                          <strong>GIF</strong> format,
                          <strong>below 2 MB.</strong>
                        </Trans>
                      </p>
                    </TooltipMsgContainer>
                  )}
                </Overlay>
                <p>{t('myProfile:picture.Profile', 'Profile Picture')}</p>
              </div>
              <div>
                <img
                  id="photo"
                  src={
                    !isNil(uploadError)
                      ? Avatar
                      : base64String !== ''
                      ? `data:${fileType};base64,${base64String}`
                      : generateProfilePicUrl(
                          studentDetails.ProfilePicture,
                          profilePictureDateHash
                        )
                  }
                  alt="profile-pic"
                  className="profile-picture"
                  onError={(e) => (e.target.src = Avatar)}
                />
                <input
                  id="file"
                  type="file"
                  onChange={uploadPhoto}
                  accept=".jpeg, .png, .jpg"
                  name="image"
                />
                <label className="overlay" htmlFor="file" id="uploadBtn">
                  <img src={Camera} alt="camera" />
                  <p>
                    {t('myProfile:picture.ChangeProfile', 'Change Profile')}
                  </p>
                </label>
                <span className="camera-icon">
                  <img src={CameraIcon} alt="camera" />
                </span>
              </div>
            </div>
            <div className="info">
              <DetailsInfo>
                <p className="title">{t('myProfile:picture.Name', 'Name')}</p>
                <p className="value bold">{studentDetails.FullName}</p>
              </DetailsInfo>
              <DetailsInfo>
                <p className="title">{t('myProfile:picture.Level', 'Level')}</p>
                <p className="value">{i18nLevel}</p>
              </DetailsInfo>
              {plan === PRODUCT_TYPE.SCHOOL && (
                <DetailsInfo>
                  <p className="title">
                    {t('myProfile:picture.School', 'School')}
                  </p>
                  <p className="value">
                    {
                      allNonExpiredSubscriptionProducts?.[0]
                        ?.subscriptionDetails?.Schoolname
                    }
                  </p>
                </DetailsInfo>
              )}
              <DetailsInfo>
                <p className="title">
                  {t('myProfile:picture.Birthday', 'Birthday')}
                </p>
                <p className="value">
                  {!isNil(studentDetails) && studentDetails.ZodiacId !== 0
                    ? `${moment(studentDetails.dob).format(
                        isChinese ? 'YYYY/MM/DD' : 'DD MMM YYYY'
                      )} - ${t(
                        `leaderboard:zodiacRace.${studentDetails.ZodiacName}`,
                        studentDetails.ZodiacName
                      )}`
                    : '-'}
                </p>
              </DetailsInfo>
              <DetailsInfo className="no-bottom">
                <p className="title">
                  {t('myProfile:picture.Gender', 'Gender')}
                </p>
                {!editGender && (
                  <>
                    <p className="value">
                      {isNil(gender)
                        ? isNil(genderId[studentDetails.Gender])
                          ? '-'
                          : t(
                              `myProfile:picture.${
                                genderId[studentDetails.Gender]
                              }`
                            )
                        : gender.gender}
                    </p>
                    {!isNil(genderId[studentDetails.Gender]) &&
                      !genderUpdated && (
                        <Button
                          dataCy="edit-btn"
                          variant="blue-pale"
                          fontSize="12px"
                          padding="0.3rem 1rem"
                          onClick={() => {
                            setEditGender(true);
                          }}
                        >
                          {t('myProfile:picture.Edit', 'Edit')}
                        </Button>
                      )}
                  </>
                )}
                {editGender && (
                  <>
                    <DropdownMenu
                      selectedValue={
                        isNil(gender)
                          ? isNil(genderId[studentDetails.Gender])
                            ? '-'
                            : genderId[studentDetails.Gender]
                          : gender.gender
                      }
                      valueKey="gender"
                      values={genderList}
                      setValue={setGender}
                      width="95px"
                      noXOverflow
                    />
                    <p className="warning-text">
                      {t(
                        'myProfile:picture.ChangeOneTime',
                        'You only can change once'
                      )}
                    </p>
                    <Button
                      dataCy="save-btn"
                      variant={isNil(gender) ? 'disable' : 'primary'}
                      fontSize="12px"
                      padding="0.3rem 1rem"
                      isDisabled={isNil(gender)}
                      onClick={saveGenderHandler}
                    >
                      {t('myProfile:picture.Save', 'Save')}
                    </Button>
                    <Button
                      dataCy="cancel-btn"
                      variant="secondary"
                      fontSize="12px"
                      padding="0.3rem 1rem"
                      margin="0 0.5rem"
                      onClick={() => {
                        setEditGender(false);
                        setGender(null);
                      }}
                    >
                      {t('myProfile:picture.Cancel', 'Cancel')}
                    </Button>
                  </>
                )}
              </DetailsInfo>
            </div>
          </ProfileCard>
          <ProfileCard className="password">
            <DetailsInfo className="no-bottom">
              <p className="title">
                {t('myProfile:password.CurrentPass', 'Current Password')}
              </p>
              <p className="value">********</p>
            </DetailsInfo>
            <Link to="/profile-settings/profile/change-password">
              <Button width="185px" variant="primary" dataCy="pass-btn">
                {t('myProfile:password.ChangePassword', 'Change Password')}
              </Button>
            </Link>
          </ProfileCard>
        </>
      )}
      {((!isLoading && error !== null) ||
        !isNil(uploadError) ||
        !isNil(isErrGenderUpdate)) && (
        <ErrorModal
          errorMessage={
            !isNil(error)
              ? error
              : !isNil(uploadError)
              ? uploadError
              : isErrGenderUpdate
          }
          reloadAction={!isNil(error) && reloadHandler}
          closeHandler={reloadHandler}
        />
      )}
    </Container>
  );
};

export default ProfileSummary;
