import { isNil } from 'ramda';
import { getCurrentDateTimeInTimezone } from 'helpers/timezone';

const isSundayBetween10pmTo12am = (timezone) => {
  const currentDateTime = getCurrentDateTimeInTimezone(timezone);
  const startTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 22,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const endTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
  return (
    currentDateTime.isoWeekday() === 7 &&
    currentDateTime.isBetween(startTime, endTime)
  );
};
const isMondayBetween12amTo6am = (timezone) => {
  const currentDateTime = getCurrentDateTimeInTimezone(timezone);
  const startTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const endTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 5,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
  return (
    currentDateTime.isoWeekday() === 1 &&
    currentDateTime.isBetween(startTime, endTime)
  );
};

const smcState = (smcStatus, timezone) => {
  if (smcStatus.SMCStatus) {
    const isSunday = getCurrentDateTimeInTimezone(timezone)?.isoWeekday() === 7;

    if (isSunday) {
      return smcStatus.SMCSubmitted ? 'submitted' : 'open';
    }
    return smcStatus.SMCSubmitted ? 'submitted' : 'expired';
  }
  if (!smcStatus.SMCSubmitted) return 'not-submitted';
  if (
    isSundayBetween10pmTo12am(timezone) ||
    isMondayBetween12amTo6am(timezone)
  ) {
    return 'submitted';
  }
  return isNil(smcStatus.ClaimedCP)
    ? 'submitted-claimable'
    : 'submitted-claimed';
};
export default smcState;
