import { isNil } from 'ramda';

export const getTotalAttempts = (products, subject) => {
  if (!products) return 0;
  const currentProduct = products.find(
    (product) => product.subject.toLowerCase().trim() === subject
  );
  if (
    !currentProduct ||
    currentProduct?.nonExpiredSubscriptionProducts.length <= 0
  )
    return 0;
  const subscriptionFeatures =
    currentProduct.nonExpiredSubscriptionProducts[0].features;
  const peerChallengeFeatureConfig = subscriptionFeatures.find(
    (feature) => feature.feature.toLowerCase() === 'pc'
  );
  return peerChallengeFeatureConfig?.parameters?.limitperday ?? 0;
};

export const getChallengerInfo = (challengers, currentUserID, userInfo) => {
  const challengerInfo = {};
  if (!challengers || !currentUserID || !userInfo) return challengerInfo;
  challengers.forEach((challenger) => {
    const keyName =
      challenger.UserId === currentUserID ? 'current' : 'opponent';
    const currentChallengerInfo = userInfo[challenger.UserId];
    if (isNil(currentChallengerInfo)) return { Id: challenger.UserId };
    challengerInfo[keyName] = currentChallengerInfo;
  });
  return challengerInfo;
};
