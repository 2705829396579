import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 1.5rem;
  p {
    margin-bottom: 0;
    a {
      color: #000000;
      text-decoration: underline;
    }
  }
  .title {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    span {
      font-weight: 700;
    }
  }
`;
