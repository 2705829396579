import { SUBJECTS } from 'constants/products';
import BrainGameMath from './imgs/brain-game-math.png';
import BrainGameScience from './imgs/brain-game-science.png';
import BrainGameEnglish from './imgs/brain-game-english.png';

export const BRAIN_GAME_LIST = [
  {
    id: 1,
    subject: SUBJECTS.MATH,
    imageUrl: BrainGameMath,
    redirectUrl: '/brain-game/math',
  },
  {
    id: 2,
    subject: SUBJECTS.SCIENCE,
    imageUrl: BrainGameScience,
    redirectUrl: '/brain-game/science',
  },
  {
    id: 3,
    subject: SUBJECTS.ENGLISH,
    imageUrl: BrainGameEnglish,
    redirectUrl: '/brain-game/english',
  },
];
