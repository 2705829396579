import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDashboard from 'hooks/useDashboard';
import {
  LogoContainer,
  Button,
} from 'components/Assignment/AssignmentView/Header.styles';
import KooBitsLogo from 'assets/img/koobits-logo.svg';
import BackIcon from 'assets/img/assignment/back-icon.svg';
import TimerDisplay from './TimerDisplay';

const HeaderLeft = ({ isMobile, isTablet, assignmentType, showButtons }) => {
  const { t } = useTranslation(['common', 'assignmentQnView']);
  const history = useHistory();
  const { isDashboardv2 } = useDashboard();

  const backOnClickHandler = () => {
    let backLocation = '/assignment/assignment-list';
    if (assignmentType === 'sundayMC') {
      backLocation = isDashboardv2 ? '/dashboard?view=koochallenge' : '/events';
    }
    history.replace(backLocation);
  };
  return (
    <>
      <LogoContainer
        assignmentType={assignmentType}
        onClick={() => {
          history.push('/dashboard');
        }}
      >
        <img src={KooBitsLogo} alt="KooBits" width="40px" />
      </LogoContainer>
      <Button
        assignmentType={assignmentType}
        onClick={backOnClickHandler}
        hideText={isMobile}
      >
        <img src={BackIcon} alt="Back" />
        {!isMobile && t('common:back', 'Back')}
      </Button>
      {!isTablet && showButtons && (
        <TimerDisplay assignmentType={assignmentType} />
      )}
    </>
  );
};

export default HeaderLeft;
