import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';

import { kooTrainingIcons } from 'constants/dashboardv2';

import { StyledContainer, StyledContent } from './MissionCard.styles';

const MissionImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(
    kooTrainingIcons[subject].mission,
    kooTrainingIcons[subject].missionTablet,
    kooTrainingIcons[subject].missionMobile
  );
  return <img src={imgSrc} alt="mission" />;
};

const MissionCard = () => {
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const { subject } = useSelector((state) => state.plan);
  const isMissionPlus = useCallback(() => {
    if (subject !== 'math') {
      return true;
    }
  }, [subject]);

  const clickHandler = () => {
    return history.push('/mission/curriculum-list');
  };

  return (
    <StyledContainer
      onClick={clickHandler}
      data-cy="mission-card"
      subject={subject}
    >
      <div>
        <MissionImage isMissionPlus={isMissionPlus()} subject={subject} />
        <StyledContent>
          <h3>
            {t('mission.name', 'Mission')}
            {isMissionPlus() && (
              <img src={kooTrainingIcons[subject].missionPlusIcon} alt="plus" />
            )}
          </h3>
          <p>
            {t(
              'mission.description',
              'Practice by topics. Learn at your own pace.'
            )}
          </p>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};
export default MissionCard;
