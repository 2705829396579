import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { isNil } from 'ramda';

import usePreviousValue from 'hooks/usePreviousValue';
import {
  kooQuizTypes,
  AVAILABLE_LEVELS,
  KOOQUIZ_PAPERS,
  KOO_QUIZ_PAPER_TYPE,
  KOO_QUIZ_PAPER_TYPE_ID,
  KOO_QUIZ_PAPER,
} from 'constants/kooQuiz';
import { subjectNames } from 'constants/products';

import {
  getPapers,
  getCurrentGiftBySubject,
  setKooQuizSelectedPaper,
  setGiftObj,
  setKooQuizType,
  resetSelectedPaper,
} from 'store/kooQuiz/kooQuizSlice';
import { getRewards } from 'store/dashboard/rewardsSlice';

import { ErrorModal, Spinner } from 'components/Shared';
import {
  MainPageTopbar,
  LevelSelectionBar,
  BookShelf,
  GiftBarHeader,
  KooQuizVideoSolution,
} from './components';

import { Container, StyledSpinnerWarper } from './KooQuizMainPage.styles';

const KooQuizMainPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { paper, subject: selectedSubject } = useParams();
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { currentProduct } = useSelector((state) => state.plan);
  const {
    isLoading,
    papers,
    giftsInfo,
    kooQuizSelectedPaper,
    giftObj,
    error,
    errorPapers,
  } = useSelector((state) => state.kooQuiz);
  const paperVal = parseInt(match.params.paper, 10);
  const [selectedPaper, setSelectedPaper] = useState(paperVal);
  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [currentPaperName, setCurrentPaperName] = useState('Essential Skills');
  const allPapers = (papers && papers.List) || [];
  const kooQuizFeatureData = currentProduct.features.filter(
    (item) => item.feature === 'KooQuiz'
  )[0];
  const subject = subjectNames[selectedSubject];
  const levels = AVAILABLE_LEVELS[selectedSubject.toLowerCase()];
  const prevSubject = usePreviousValue(subject);
  const isDefaultLevelAvailable = levels.includes(defaultLevel);

  useEffect(() => {
    dispatch(setKooQuizSelectedPaper(paperVal));
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    if (isDefaultLevelAvailable) {
      setSelectedLevel(defaultLevel);
    } else {
      setSelectedLevel(levels[0]);
    }
    dispatch(setKooQuizType(kooQuizTypes[subject]));
    setSelectedPaper(paperVal);
    // eslint-disable-next-line
  }, [paperVal]);
  useEffect(() => {
    if (prevSubject !== subject) {
      dispatch(getCurrentGiftBySubject(subject));
    }
    // eslint-disable-next-line
  }, [prevSubject, subject]);
  useEffect(() => {
    if (giftsInfo.length > 0) {
      const currentGift = giftsInfo.find(
        (item) =>
          item.RevisionCategoryId === selectedPaper ||
          // this for english
          (item.RevisionCategoryId === 1 &&
            KOO_QUIZ_PAPER_TYPE_ID[selectedPaper] ===
              KOO_QUIZ_PAPER.VocabularyCloze)
      );
      dispatch(setGiftObj(currentGift));
    }
    // eslint-disable-next-line
  }, [giftsInfo, selectedPaper]);

  useEffect(() => {
    dispatch(getRewards());
    const paperName = KOOQUIZ_PAPERS.find((item) => item.id === paperVal)
      .paramName;
    const params = {
      subjectId: subject,
      rawBody: kooQuizFeatureData.parameters.Category[paperName],
    };

    dispatch(getPapers(params));
    dispatch(resetSelectedPaper());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // toggle between Essential Skills and Top School in Maths
  const changePaperType = (name) => {
    const paperDetail = KOOQUIZ_PAPERS.find((item) => item.name === name);

    setCurrentPaperName(paperDetail.paramName);
    history.push(`/kooQuiz/${selectedSubject}/${paperDetail.id}`);
    setSelectedPaper(paperDetail.id);
    dispatch(setKooQuizSelectedPaper(paperDetail.id));
    setSelectedLevel(defaultLevel);
    const params = {
      subjectId: subject,
      rawBody: kooQuizFeatureData.parameters.Category[paperDetail.paramName],
    };
    dispatch(getPapers(params));
  };

  const reloadHandler = () => {
    const params = {
      subjectId: subject,
      rawBody: kooQuizFeatureData.parameters.Category[currentPaperName],
    };
    if (!isNil(errorPapers) || !isNil(error)) {
      dispatch(getPapers(params));
      dispatch(getCurrentGiftBySubject(params.subjectId));
    }
  };
  return (
    <>
      <Container>
        <MainPageTopbar
          kooQuizSelectedPaper={kooQuizSelectedPaper}
          setSelectedPaper={setSelectedPaper}
          clickHandle={changePaperType}
          selectedSubject={selectedSubject}
        />
        <LevelSelectionBar
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          defaultLevel={defaultLevel}
          papers={papers}
          isShowLevel={paper !== '3'}
          levels={levels}
        />
        {isLoading ? (
          <StyledSpinnerWarper>
            <Spinner />
          </StyledSpinnerWarper>
        ) : (
          <>
            <GiftBarHeader
              selectedPaper={kooQuizSelectedPaper}
              giftObj={giftObj}
            />
            {levels.map((level) => (
              <BookShelf
                key={level}
                selectedLevel={level}
                selectedPaper={kooQuizSelectedPaper}
                paperVal={paperVal}
                giftObj={giftObj}
                data={allPapers.filter((e) => e.LevelId === level)}
              />
            ))}
            {paper === '3' && <KooQuizVideoSolution />}
          </>
        )}
      </Container>
      {(!isNil(errorPapers) || !isNil(error)) && (
        <ErrorModal
          reloadAction={reloadHandler}
          errorMessage={errorPapers || error}
        />
      )}
    </>
  );
};
const checkActivePlan = (Component) => (props) => {
  const { subject, paper } = props.match.params;
  const { products, isLoading, getProductsError } = useSelector(
    (state) => state.plan
  );
  const selectedSubject = subject.toLowerCase();

  const paperChecker = {
    science: {
      [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.TopPapers]]: true,
      [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.OED]]: true,
    },
    math: {
      [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.EssentialSkills]]: true,
      [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.TopPapers]]: true,
      [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.HOTS]]: true,
    },
    english: {
      [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.VocabularyCloze]]: true,
      6: true,
    },
  };

  if (!paperChecker[selectedSubject][paper]) {
    return <Redirect to="/dashboard?view=kootraining" />;
  }
  if (isLoading) {
    return <Spinner />;
  }
  if (!isLoading && products && !getProductsError) {
    const findProduct = products.find(
      (product) =>
        product.subject.toLowerCase().trim() === selectedSubject &&
        product.nonExpiredSubscriptionProducts.length
    );
    if (
      findProduct &&
      findProduct.nonExpiredSubscriptionProducts.find(
        (plan) =>
          plan?.features.find(
            ({ feature }) => feature.toLowerCase().trim() === 'kooquiz'
          )?.parameters.IsEnabled === true
      )
    ) {
      return <Component {...props} />;
    }
    return <Redirect to="/dashboard?view=kootraining" />;
  }
  return null;
};
export default checkActivePlan(KooQuizMainPage);
