import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import useGlobalFeature from 'hooks/useGlobalFeature';
import { KooQuizMainPage, KooQuizStart } from '..';

import { withPlanCheck } from 'helpers/hoc';
import { subjectIDs } from 'constants/products';
import Header from 'components/Header';
import { Content, Page } from './KooQuiz.styles';

const KooQuiz = () => {
  const { isEnabled } = useGlobalFeature('kooquiz');
  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={[
                '/kooQuiz',
                '/kooQuiz/start/:subject/:paper/:id',
                '/kooQuiz/:subject/:paper',
              ]}
              to="/maintenance-redirect"
            />
          )}
          <Route
            exact
            path="/kooQuiz/start/:subject/:paper/:id"
            render={({ match }) => {
              const KooQuizWithSubjectCheck = withPlanCheck(
                KooQuizStart,
                subjectIDs[match.params.subject]?.toLowerCase(),
                'B2C'
              );
              return <KooQuizWithSubjectCheck />;
            }}
          />
          <Route
            exact
            path="/kooQuiz/:subject/:paper"
            component={KooQuizMainPage}
          />
        </Switch>
      </Content>
    </Page>
  );
};

export default KooQuiz;
