import CPIcon from 'assets/img/icon-challenge-point.svg';
import ScienceCPIcon from 'assets/img/science-cp-icon.svg';
import { EnglishCP } from 'assets/img/dashboardv2/dashboard';

import {
  MathFullEnergy,
  MathFullEnergyShadow,
  MathEmptyEnergy,
  MathEmptyEnergyShadow,
  MathHistoryIcon,
  MathIncomingIcon,
  MathRulesIcon,
  MathMascot,
  MathHeaderBg,
  ScienceFullEnergy,
  ScienceFullEnergyShadow,
  ScienceEmptyEnergyShadow,
  ScienceHistoryIcon,
  ScienceIncomingIcon,
  ScienceRulesIcon,
  ScienceMascot,
  ScienceHeaderBg,
  IncomingHead,
  MathHistoryHead,
  ScienceHistoryHead,
  ClassMate,
  Friends,
  RandomChallenger,
  EnglishFullEnergyShadow,
  EnglishEmptyEnergyShadow,
  EnglishHistoryIcon,
  EnglishIncomingIcon,
  EnglishRulesIcon,
  EnglishHeaderBg,
  EnglishMascot1,
  EnglishMascot2,
} from 'assets/img/multiplayer';

export const peerChallengeResultStatus = {
  win: {
    text: 'You Win',
    color: '#FF9100',
  },
  lose: {
    text: 'You lose',
    color: '#0FA1D1',
  },
  draw: {
    text: 'Draw',
    color: '#FF9100',
  },
  pending: { text: 'Pending', color: '#0FA1D1' },
};

// For Multiplayer List page
export const multiplayerListStyles = {
  math: {
    card: {
      background: 'linear-gradient(#ffad09, #ff9327)',
    },
    energyIcon: {
      full: MathFullEnergy,
      fullShadow: MathFullEnergyShadow,
      empty: MathEmptyEnergy,
      emptyShadow: MathEmptyEnergyShadow,
    },
    titleIcon: MathHistoryHead,
    incomingIcon: MathIncomingIcon,
    incomingV2Icon: IncomingHead,
    historyIcon: MathHistoryIcon,
    topBar: 'linear-gradient(#ffad09, #ff9327)',
    rulesIcon: MathRulesIcon,
    mascot: [
      {
        no: 1,
        img: MathMascot,
        space: '17%',
        position: 'left',
      },
    ],
    backBtnBgColour: 'rgb(214, 125, 7, 0.51)',
    headerBgImg: MathHeaderBg,
    qnView: {
      bgColour: '#FFA200',
      logoBgColour: '#FF8000',
      btnBgColour: 'rgb(229, 130, 31, 0.62)',
      hasBgImg: true,
      mainBgColour: '#87796d',
      helperSidebarBgColour: 'rgb(80, 68, 57, 0.21)',
    },
    cpIcon: CPIcon,
  },
  science: {
    card: { background: '#65C300' },
    energyIcon: {
      full: ScienceFullEnergy,
      fullShadow: ScienceFullEnergyShadow,
      empty: MathEmptyEnergy,
      emptyShadow: ScienceEmptyEnergyShadow,
    },
    titleIcon: ScienceHistoryHead,
    incomingIcon: ScienceIncomingIcon,
    incomingV2Icon: IncomingHead,
    historyIcon: ScienceHistoryIcon,
    topBar: '#65C300',
    rulesIcon: ScienceRulesIcon,
    mascot: [
      {
        no: 1,
        img: ScienceMascot,
        space: '17%',
        position: 'left',
      },
    ],
    backBtnBgColour: 'rgba(65, 126, 0, 0.57)',
    headerBgImg: ScienceHeaderBg,
    qnView: {
      bgColour: '#6FC316',
      logoBgColour: '#52A000',
      btnBgColour: 'rgba(65, 126, 0, 0.62)',
      hasBgImg: false,
      mainBgColour: '#55655B',
      helperSidebarBgColour: 'rgba(26, 72, 35, 0.21)',
    },
    cpIcon: ScienceCPIcon,
  },
  english: {
    card: { background: '#FEC404' },
    energyIcon: {
      full: MathFullEnergy,
      fullShadow: EnglishFullEnergyShadow,
      empty: MathEmptyEnergy,
      emptyShadow: EnglishEmptyEnergyShadow,
    },
    titleIcon: ScienceHistoryHead,
    incomingIcon: EnglishIncomingIcon,
    incomingV2Icon: IncomingHead,
    historyIcon: EnglishHistoryIcon,
    topBar: '#FFBD29',
    rulesIcon: EnglishRulesIcon,
    mascot: [
      {
        no: 1,
        img: EnglishMascot1,
        space: '24%',
        position: 'left',
      },
      {
        no: 2,
        img: EnglishMascot2,
        space: '29%',
        position: 'right',
      },
    ],
    backBtnBgColour: 'rgba(239, 156, 0, 0.62)',
    headerBgImg: EnglishHeaderBg,
    qnView: {
      bgColour: '#FFBD29',
      logoBgColour: '#FFA701',
      btnBgColour: 'rgb(239, 156, 0, 0.6)',
      hasBgImg: false,
      mainBgColour: '#6E4E33',
      helperSidebarBgColour: 'rgb(110, 78, 51, 0.87)',
    },
    cpIcon: EnglishCP,
  },
};

// New Challenge Page
// 2: My Classmate
// 3: Random Challenger
// 4: My Friend

export const availableOpponents = {
  math: [2, 3, 4],
  science: [3],
  english: [3],
};

export const NewChallengeCardStyles = {
  math: {
    headerBgColour: {
      2: '#F7AB02',
      3: '#FF9A07',
      4: '#F88526',
    },
    headerTitleTextShadow: {
      2: '0 3px 4px rgba(198, 137, 22, 0.51)',
      3: '0 3px 4px rgba(185, 85, 0, 0.16)',
      4: '0 3px 4px rgba(0, 0, 0, 0.08)',
    },
    iconThumbnails: {
      2: ClassMate,
      3: RandomChallenger,
      4: Friends,
    },
    iconThumbnailBgColour: {
      2: '#FFBA00',
      3: '#FFAD3B',
      4: '#FF9134',
    },
    thumbnailHasBgImage: false,
    selectedOutlineStyle: '3px solid #FF7121',
  },
  science: {
    headerBgColour: {
      2: '#4CA500',
      3: '#4CA500',
      4: '#4CA500',
    },
    headerTitleTextShadow: {
      2: '0 3px 4px rgba(185, 85, 0, 0.16)',
      3: '0 3px 4px rgba(185, 85, 0, 0.16)',
      4: '0 3px 4px rgba(185, 85, 0, 0.16)',
    },
    iconThumbnails: {
      2: ClassMate,
      3: RandomChallenger,
      4: Friends,
    },
    iconThumbnailBgColour: {
      2: '#76C92E',
      3: '#76C92E',
      4: '#76C92E',
    },
    thumbnailHasBgImage: false,
    selectedOutlineStyle: '3px solid #6BBF22',
  },
  english: {
    headerBgColour: {
      2: '#FF9A07',
      3: '#FF9A07',
      4: '#FF9A07',
    },
    headerTitleTextShadow: {
      2: '0 3px 4px rgba(185, 85, 0, 0.16)',
      3: '0 3px 4px rgba(185, 85, 0, 0.16)',
      4: '0 3px 4px rgba(185, 85, 0, 0.16)',
    },
    iconThumbnails: {
      2: ClassMate,
      3: RandomChallenger,
      4: Friends,
    },
    iconThumbnailBgColour: {
      2: '#FFAD3B',
      3: '#FFAD3B',
      4: '#FFAD3B',
    },
    thumbnailHasBgImage: true,
    selectedOutlineStyle: '3px solid #FF7121',
  },
};
