import axios from 'axios';
const EVENT_SERVICE = process.env.REACT_APP_EVENTS_SERVICE_URL;

export const fetchEventsProgress = (id) => {
  return axios
    .get(`${EVENT_SERVICE}Events/${id}/Progress`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err?.response?.data || 'Get events progress failed');
    });
};
