import React from 'react';
import { useHistory } from 'react-router-dom';
import { SIDEBAR_LIST } from '../../constants';
import { Container, TabItem } from './Sidebar.styles';

const Sidebar = ({ activeTab }) => {
  const history = useHistory();

  return (
    <Container>
      <div className="tab">
        {SIDEBAR_LIST.map((item) => (
          <TabItem
            lastItem={item.id === SIDEBAR_LIST.length}
            active={item.icon === activeTab}
            key={item.id}
            onClick={() => {
              history.push(`/parent/settings/${item.icon}`);
            }}
          >
            <div className="list">
              <div className={`${item.icon} icon`} />
              <p className="name">{item.name}</p>
            </div>
            {activeTab === item.icon && <div className="nav-icon" />}
          </TabItem>
        ))}
      </div>
    </Container>
  );
};

export default Sidebar;
