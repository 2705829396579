import React from 'react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import currencyLocaleFormatter from 'helpers/currencyLocaleFormatter';
import {
  SUBS_DISPLAY_STATUS,
  PAYMENT_PROVIDER,
  DURATION_TYPE,
  BUNDLE_TYPE_SUBJECT_COUNT,
} from '../../constants';

import { ReactComponent as CC } from './imgs/icon-credit-card.svg';

import { Button } from 'components/Shared';

import {
  SubsBottom,
  SubsTop,
  StatusLabel,
  SubjectPlanLabel,
} from './SubscriptionV2.styles';

const PaidContent = ({
  sub,
  setSelectedSub,
  setShowCancelModal,
  setShowTrialSelectorModal,
  setShowEarlyRenewModal,
  userId,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 610px)` });
  const isSingle = !sub.doesUserHasMoreThanOneSingleSubjectSubscription;
  const { childSubs } = useSelector((state) => state.parentSettings);
  const { data } = childSubs;

  const isEarlyRenewEligible = (endDate) => {
    const targetDate = moment(endDate);
    const currentDate = moment();

    const differenceInDays = targetDate.diff(currentDate, 'days', true);
    return differenceInDays <= 365 ?? false;
  };

  const isAllActive = data.every(
    (item) => SUBS_DISPLAY_STATUS[item.subscriptionStatus] === 'active'
  );

  const buttonDisplay = (single, endDate) => {
    if (!single && isAllActive) {
      return (
        <Button
          borderRadius="2px"
          variant="orange3"
          width={isMobile ? '100%' : '142px'}
          fontSize="16px"
          onClick={() => setShowTrialSelectorModal(true)}
        >
          Upgrade
        </Button>
      );
    }
    return (
      <button
        className="renew-btn"
        type="button"
        onClick={() => {
          if (!single) {
            setShowTrialSelectorModal(true);
          } else if (isEarlyRenewEligible(endDate)) {
            window.location.href = `${process.env.REACT_APP_PAYMENT_V2_URL}pay/?campaignCode=ParentPortal&userId=${userId}&bundleId=${sub.bundleId}`;
          } else {
            setShowEarlyRenewModal(true);
          }
        }}
      >
        Renew Now
      </button>
    );
  };
  return (
    <>
      <SubsTop
        active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
      >
        <div className="bundle-info">
          <p className="bundle-name">
            {BUNDLE_TYPE_SUBJECT_COUNT[sub.bundleTypeId] !== '1' && (
              <SubjectPlanLabel>
                {BUNDLE_TYPE_SUBJECT_COUNT[sub.bundleTypeId]} subjects Plan
              </SubjectPlanLabel>
            )}
            {sub.bundleName}{' '}
            <StatusLabel
              active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
            >
              {SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'
                ? 'Active'
                : 'Expired'}
            </StatusLabel>
          </p>
          <p className="bundle-period">
            Active from {moment(sub.startDate).format('DD MMM YYYY')} to{' '}
            {moment(sub.endDate).format('DD MMM YYYY')}
          </p>
        </div>
        {buttonDisplay(isSingle, sub.endDate)}
      </SubsTop>
      <SubsBottom
        active={SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired'}
      >
        <div className="bundle-price">
          {sub.isAutoRenewalOn &&
            SUBS_DISPLAY_STATUS[sub.subscriptionStatus] !== 'expired' && (
              <>
                <p className="renewal-rate">
                  Auto-renewal is on.
                  {isSingle &&
                    ` You now enjoy our best renewal rate at ${
                      currencyLocaleFormatter(
                        sub.renewalCurrency,
                        sub.renewalAmount
                      ).formattedString
                    } ${sub.renewalCurrency}
              ${DURATION_TYPE[sub.durationTypeId] === 'yearly' ? '/year' : ''}
              .`}
                </p>
                <div className="payment-details">
                  Payment Method:{' '}
                  {PAYMENT_PROVIDER[sub.paymentProviderId] === 'stripe' && (
                    <CC />
                  )}{' '}
                  **************{sub.paymentMethodText} (Upcoming renewal on{' '}
                  {moment(sub.endDate).format('DD MMM YYYY')}）
                </div>
              </>
            )}
          {!sub.isAutoRenewalOn && (
            <div className="payment-details">Auto-Renewal Off.</div>
          )}
        </div>
        {sub.isAutoRenewalOn ? (
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              setSelectedSub(sub);
              setShowCancelModal(true);
            }}
          >
            Cancel Auto-Renewal
          </button>
        ) : (
          <p>
            {isSingle
              ? `Renewal discount, ${
                  currencyLocaleFormatter(
                    sub.renewalCurrency,
                    sub.renewalAmount
                  ).formattedString
                } ${sub.renewalCurrency}
          ${DURATION_TYPE[sub.durationTypeId] === 'yearly' ? '/yr' : ''}
          .`
              : ''}
          </p>
        )}
      </SubsBottom>
    </>
  );
};

export default PaidContent;
