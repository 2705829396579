import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Spinner } from 'react-bootstrap';
import { isNil, isEmpty } from 'ramda';
import { unwrapResult } from '@reduxjs/toolkit';

import { ReactComponent as School } from 'assets/img/plan-toggle/icon-school.svg';
import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';

import { getOTPEligibility } from 'store/trialSignup/trialSignupSlice';
import { setCurrentProduct } from 'store/plan/planSlice';
import { ModalContentXButton, ErrorModal } from 'components/Shared';
import {
  PRODUCT_TYPE,
  subjectIDs,
  SUBJECTS,
  subjectNames,
} from 'constants/products';
import SwitchModalV2 from '../SwitchModalV2';
import ToggleBtn from './ToggleBtn';

import { Container, RadioBtn } from './ToggleV2.styles';

const ToggleV2 = ({ setShowLoader, setRedirectUrl }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    getProductsError,
    products,
    isLoading,
    currentProduct,
    countryEligibility,
    allNonExpiredSubscriptionProducts,
    plan,
    subject,
  } = useSelector((state) => state.plan);
  const [openSwitchModal, setOpenSwitchModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [activePlan, setActivePlan] = useState(PRODUCT_TYPE.PREMIUM);
  const [activeSubjectId, setActiveSubjectId] = useState(
    subjectNames[SUBJECTS.MATH]
  );
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    subjectId: null,
  });
  const schoolProducts =
    products?.filter((item) => item.hasPremiumSchool || item.hasSchoolOnly) ??
    [];
  useEffect(() => {
    const splitPathname = location.pathname.split('/');
    const routeName = splitPathname[1]?.toLowerCase().trim();
    if (routeName === 'trial' || routeName === 'expired') {
      const subjectName = splitPathname[2]?.toLowerCase().trim();
      if (subjectNames[subjectName]) {
        setActiveSubjectId(subjectNames[subjectName]);
        setActivePlan(PRODUCT_TYPE.PREMIUM);
      } else if (subjectName === 'block' && routeName === 'trial') {
        setActivePlan(PRODUCT_TYPE.PREMIUM);
        if (location.state?.subjectId) {
          setActiveSubjectId(location.state.subjectId);
        } else {
          const filterAllIsTrial = allNonExpiredSubscriptionProducts?.reduce(
            (acc, product) => {
              if (product.isTrial) {
                acc[product.subjectId] = true;
              }
              return acc;
            },
            {}
          );
          if (filterAllIsTrial && Object.keys(filterAllIsTrial).length > 0) {
            const findOtherTrialExisting = countryEligibility.data?.eligibleSubjects.find(
              (s) => !filterAllIsTrial[s.subjectId]
            );
            if (findOtherTrialExisting) {
              setActiveSubjectId(findOtherTrialExisting?.subjectId);
            }
          }
        }
      }
    } else if (products?.length) {
      setActivePlan(plan);
      setActiveSubjectId(subjectNames[subject]);
    }
  }, [
    products,
    location,
    subject,
    plan,
    countryEligibility,
    allNonExpiredSubscriptionProducts,
  ]);

  const schoolToggleHandler = () => {
    setRedirectUrl(null);
    const findNonExpiredSchoolProduct = products.find((p) =>
      p.nonExpiredSubscriptionProducts.find(
        (s) => s.productType.toLowerCase().trim() === PRODUCT_TYPE.SCHOOL
      )
    );
    if (findNonExpiredSchoolProduct) {
      const productItem = findNonExpiredSchoolProduct.nonExpiredSubscriptionProducts.find(
        (p) => p.productType.toLowerCase().trim() === PRODUCT_TYPE.SCHOOL
      );
      setSelectedProduct(productItem);
      setOpenSwitchModal(true);
    }
  };

  const premiumToggleHandler = async (subjectId) => {
    setErrorMessage({ message: '', subjectId: null });
    setOpenErrorModal(false);
    const splitPathname = location.pathname.split('/');
    const routeName = splitPathname[1]?.toLowerCase().trim();
    if (
      activeSubjectId === subjectId &&
      activePlan === PRODUCT_TYPE.PREMIUM &&
      routeName !== 'expired-subscription'
    ) {
      if (location.pathname === '/trial/block') {
        return;
      }
      if (!currentProduct.expired && currentProduct.subjectId === subjectId) {
        history.push(`/dashboard`);
        return;
      }
    }

    const subjectProduct = products?.find((p) => p.subjectId === subjectId);
    const subjectPremiumProduct = subjectProduct?.nonExpiredSubscriptionProducts.find(
      (p) => p.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM
    );
    if (subjectPremiumProduct) {
      setOpenSwitchModal(true);
      setSelectedProduct(subjectPremiumProduct);
      return;
    }
    const findOtherTrialExisting = products?.find((p) => {
      if (p.subjectId !== subjectId) {
        return p.nonExpiredSubscriptionProducts.find((s) => s.isTrial);
      }
      return null;
    });
    if (findOtherTrialExisting) {
      history.push(`/trial/block`, {
        subjectId,
      });
      return;
    }
    const subjectName = subjectIDs[subjectId]?.toLowerCase().trim();
    const findCountryEligible = countryEligibility.data?.eligibleSubjects.find(
      (s) => s.subjectId === subjectId
    );

    try {
      const action = await dispatch(getOTPEligibility());
      const result = unwrapResult(action);
      if (subjectProduct) {
        const newestProduct = subjectProduct.subscriptionProducts.find(
          (p) => p.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM
        );
        if (newestProduct) {
          dispatch(setCurrentProduct(newestProduct));
          if (findCountryEligible) {
            history.push(
              result.isEligible
                ? `/trial/${subjectName}`
                : `/expired/${subjectName}`
            );
            return;
          }
          history.push(`/expired/${subjectName}`);
          return;
        }
      }

      history.push(
        findCountryEligible && result.isEligible
          ? `/trial/${subjectName}`
          : `/expired/${subjectName}`
      );
      return;
    } catch (err) {
      setOpenErrorModal(true);
      setErrorMessage({ message: err.message, subjectId });
    }
  };
  const reloadAction = () => {
    premiumToggleHandler(errorMessage.subjectId);
  };
  return (
    <>
      {openErrorModal && (
        <ErrorModal
          reloadAction={reloadAction}
          errorMessage={errorMessage.message}
        />
      )}
      <ModalContentXButton
        showModal={openSwitchModal}
        closeModal={() => setOpenSwitchModal(false)}
        backdrop="static"
        containerMinWidth="388px"
      >
        <SwitchModalV2
          setModal={setOpenSwitchModal}
          setShowLoader={setShowLoader}
          setRedirectUrl={setRedirectUrl}
          selectedProduct={selectedProduct}
        />
      </ModalContentXButton>
      {isLoading && (
        <Spinner animation="border" variant="secondary" size="sm" />
      )}
      {!isLoading &&
        !isNil(products) &&
        isNil(getProductsError) &&
        !isNil(countryEligibility.data) &&
        isNil(countryEligibility.error) && (
          <Container className="styled-toggle">
            <Dropdown.Toggle
              id="custom-toggle"
              as={forwardRef(({ onClick, ...props }, ref) => (
                <ToggleBtn
                  {...props}
                  onClick={onClick}
                  plan={activePlan}
                  subjectId={
                    location.pathname === '/buy/english'
                      ? subjectNames[SUBJECTS.ENGLISH]
                      : activeSubjectId
                  }
                  ref={ref}
                />
              ))}
            />
            <Dropdown.Menu>
              {!isEmpty(schoolProducts) && (
                <>
                  <Dropdown.Item
                    className="first-item"
                    onClick={schoolToggleHandler}
                  >
                    <School width="76px" height="23px" />
                    <RadioBtn active={activePlan === PRODUCT_TYPE.SCHOOL} />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Premium width="76px" height="23px" />
                  </Dropdown.Item>
                </>
              )}
              {Object.entries(subjectIDs)
                // remove this filter at E-day
                .map(([key, subjectName], i, array) => {
                  const subjectId = Number(key);
                  return (
                    <Dropdown.Item
                      key={subjectId}
                      className={`subject-item ${
                        i + 1 === array.length && 'last-item'
                      }`}
                      onClick={() => premiumToggleHandler(subjectId)}
                    >
                      {t(`common:subjects.${subjectName}`, subjectName)}
                      <RadioBtn
                        active={
                          activePlan === PRODUCT_TYPE.PREMIUM &&
                          activeSubjectId === subjectId
                        }
                      />
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Container>
        )}
    </>
  );
};

export default ToggleV2;
