import React from 'react';
import { MissionCard, KooQuizCard, AssignmentCard } from './components';
import { Rewards } from '../components';
import {
  StyledContainer,
  StyledRight,
  StyledRewardsSection,
  StyledCardContainer,
} from './KooTraining.styles';

const KooTraining = () => {
  return (
    <div>
      <StyledContainer data-cy="kootraining-screen">
        <StyledCardContainer>
          <MissionCard />
          <StyledRight>
            <KooQuizCard />
            <AssignmentCard />
          </StyledRight>
        </StyledCardContainer>
        <StyledRewardsSection>
          <Rewards types={[2]} />
        </StyledRewardsSection>
      </StyledContainer>
    </div>
  );
};
export default KooTraining;
