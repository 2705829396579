import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  color: #698f9f;
  font-size: 11px;
  font-weight: 300;
  .qn-difficulty {
    display: flex;
    align-items: center;
    margin-right: 20px;
    p {
      margin-right: 3px;
    }
  }
`;
