import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import KoKo from 'assets/img/icon-koko-credits.svg';
import CPIcon from 'assets/img/icon-challenge-point.svg';
import useIsBelowTablet from 'hooks/responsive/useIsBelowTablet';
import LockedIcon from './locked-icon.svg';
import {
  Container,
  CardHeader,
  InfoSection,
  OverlayContainer,
} from './DifficultyOptionCard.styles';
import DifficultyStars from './DifficultyStars';
import RadioButton from './RadioButton';

const LockedOverlay = ({ CPsRequired }) => {
  const { t } = useTranslation(['dailyChallenge']);

  return (
    <OverlayContainer>
      <div className="info">
        <img src={CPIcon} alt="CPs" />
        <span>
          {t(
            'dailyChallenge:difficultySelection.youNeed',
            { CP: CPsRequired },
            `You need ${CPsRequired} CPs to unlock this challenge`
          )}
        </span>
      </div>
      <img src={LockedIcon} alt="Locked" width="69px" />
    </OverlayContainer>
  );
};

const DifficultyOptionCard = ({
  level,
  isActive,
  isUnlocked,
  onClick,
  dataCy,
}) => {
  const { t } = useTranslation(['dailyChallenge']);
  const isBelowTablet = useIsBelowTablet();

  const renderImage = (
    <img src={level.cardPlaceholder} alt="" className="placeholder" />
  );
  const renderStar = <DifficultyStars numOfStars={level.numOfStars} />;

  const wrapIfNotDesktop = (component) =>
    !isBelowTablet ? component : <div>{component}</div>;
  return (
    <Container
      onClick={() => {
        if (isUnlocked) onClick();
      }}
      isLocked={!isUnlocked}
      isActive={isActive}
      data-cy={dataCy}
    >
      <CardHeader>
        <RadioButton isActive={isActive} />
        {isBelowTablet && renderImage}
        <div>
          {t(
            `dailyChallenge:difficultySelection.${level.name}`,
            `${level.name} Challenge`
          )}
          {isBelowTablet && <span>{renderStar}</span>}
        </div>
      </CardHeader>
      {!isBelowTablet && renderImage}
      {!isBelowTablet && <span>{renderStar}</span>}
      <InfoSection>
        {wrapIfNotDesktop(
          <>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="top"
              overlay={
                <Popover>
                  <Popover.Content>
                    {t(
                      'dailyChallenge:difficultySelection.beRewarded',
                      'Be rewarded with extra KoKos when you answer the 10 challenges correctly.'
                    )}
                  </Popover.Content>
                </Popover>
              }
            >
              <span className="info">?</span>
            </OverlayTrigger>
            <span className="extra">
              {t(
                'dailyChallenge:difficultySelection.extraReward',
                'Extra Reward'
              )}
            </span>
          </>
        )}
        {wrapIfNotDesktop(
          <>
            <img src={KoKo} alt="KoKo" width="17.71px" />
            <span className="koko">{`${level.kokoCredits} KoKo`}</span>
          </>
        )}
      </InfoSection>
      {!isUnlocked && <LockedOverlay CPsRequired={level.CPsRequired} />}
    </Container>
  );
};

export default DifficultyOptionCard;
