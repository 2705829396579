import styled, { css } from 'styled-components';
import Top from 'assets/img/assignment/note-book/note-top.png';
import Middle from 'assets/img/assignment/note-book/note-middle.png';
import Bottom from 'assets/img/assignment/note-book/note-bottom.png';
import { assignmentTypeStyles } from '../constants';

export const Error = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`;

export const Container = styled.div``;
export const Main = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Content = styled.div`
  padding-top: 60px;
  width: 100%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;

  ${({ assignmentType }) => {
    const assignmentTypeStyle = assignmentTypeStyles[assignmentType];
    return css`
      background: url(${assignmentTypeStyle.bgImage});
      background-color: ${assignmentTypeStyle.bgColor};
      background-repeat: ${assignmentTypeStyle.repeat};
      background-size: ${assignmentTypeStyle.size};
    `;
  }}
  .notebook-wrap {
    display: flex;
    justify-content: center;
    padding-left: 160px;
    .notebook {
      width: 850px;
      padding-left: calc(12% - 164px);
      .top {
        height: 97px;
        padding-left: 4rem;
        padding-top: 1rem;
        background: url(${Top});
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;

        .top-content {
          width: 92%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .info-section {
            display: flex;
            align-items: center;
            p {
              margin-bottom: 0rem;
            }
            .title {
              color: #30a8bb;
              text-transform: uppercase;
              font-weight: bold;
              margin-right: 1rem;
            }
            .score {
              color: #48b500;
              font-size: 29px;
              display: flex;
              align-items: center;
              margin-right: 1rem;

              .number {
                font-family: Pacifico;
                font-size: 47px;
                margin-top: -1rem;
              }
            }
            .qn-number {
              color: #95c9d2;
              font-size: 22px;
              margin-right: 1rem;
              p {
                font-size: 14px;
                align-self: center;
              }
            }
            .completed-info {
              color: rgb(0, 0, 0, 0.49);
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
        .leaderboard-btn {
          background: #cff0f5;
          border: none;
          border-radius: 24px;
          color: #3c5572;
          font-size: 14px;
          font-weight: 300;
          padding: 0.3rem 0.8rem;
          margin-right: 1rem;
          pointer-events: auto;
          img {
            width: 25px;
            vertical-align: bottom;
            margin-right: 0.5rem;
          }
        }
      }
      .middle {
        min-height: 435px;
        background: url(${Middle});
        background-repeat: repeat-y;
        background-size: contain;

        .content {
          margin-left: 5rem;
          padding-top: 1rem;
          width: 670px;

          .qn-no {
            font-size: 1.125rem;
            font-weight: 600;
            color: #6ed1e1;
            padding-left: 1rem;
            @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
              margin-bottom: 0;
            }
          }
        }
        .claim-points {
          &.factual-fluency {
            background: #fff2bd;
          }
          margin-left: 5rem;
          padding: 0.5rem;
          width: 670px;
          background: #d4f3ff;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-left: 10px;
          }
          .claim-btn {
            background-color: #ffd500;
            margin: 0 20px;
            border-radius: 20px;
            border: none;
            padding: 3px 15px;
          }
        }
      }
      .bottom {
        height: 100px;
        background: url(${Bottom});
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        bottom: 1rem;
      }
    }
  }
`;

export const QuestionContainer = styled.div`
  margin-bottom: 1rem;
  border-bottom: ${(props) => (props.isLastQn ? null : '2px dashed #cae3e5')};
`;

export const ScoreContainer = styled.div`
  color: ${(props) => props.color};
  font-size: 29px;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  .number {
    font-family: Pacifico;
    font-size: 47px;
    margin-top: -1rem;
  }
`;

export const CorrectAnswerSpan = styled.span`
  color: ${(props) => props.color};
  font-size: 20px;
  align-self: center;
  margin-right: 0.2rem;
  font-weight: bold;
`;

export const QnHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  .qn-no {
    margin: 0;
    line-height: 1;
  }
  .qn-id {
    font-size: 12px;
    font-weight: 300;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
  .video-button {
    cursor: pointer;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  .left {
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: 0rem;
  }
  .title {
    color: #30a8bb;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 1rem;
  }
  .score {
    color: #48b500;
    font-size: 29px;
    display: flex;
    align-items: center;
    margin-right: 1rem;

    .number {
      font-family: Pacifico;
      font-size: 47px;
      margin-top: -1rem;
    }
  }
  .qn-number {
    color: #95c9d2;
    font-size: 22px;
    margin-right: 1rem;
    p {
      font-size: 14px;
      align-self: center;
    }
  }
  .completed-info {
    color: rgb(0, 0, 0, 0.49);
    font-size: 13px;
    font-weight: 300;
  }
`;

export const SolutionButton = styled.button`
  font-size: 14px;
  font-weight: 700;
  background: #1aa5ff;
  border-radius: 38px;
  color: white;
  border: none;
  padding: 8px 12px 8px 8px;
  margin-bottom: 10px;
  .solution-button--text {
    vertical-align: middle;
  }
  svg {
    margin-right: 8px;
    #Ellipse_2328 {
      fill: #0080d3;
    }
  }
  :hover {
    svg {
      #Ellipse_2328 {
        fill: #0072bc;
      }
    }
  }
`;
