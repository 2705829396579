import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { Dropdown } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import useDashboard from 'hooks/useDashboard';
import useIsTablet from 'hooks/responsive/useIsTablet';
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import useIsMobile from 'hooks/responsive/useIsMobile';

import { featureToggle } from 'constants/index';
import { PROFILE_MENU } from 'constants/profileSettings';
import { PRODUCT_TYPE } from 'constants/products';

import logout from 'helpers/logout';

import avatar from 'assets/img/avatar.png';
import ArrowDownIcon from 'assets/img/navigation/arrow-down1.svg';

import QuickAccess from '../QuickAccess';
import QuickAccessV2 from '../QuickAccessV2';
import Bouns from '../Bouns';
import {
  ProfileContainer,
  NavProfileContainer,
  BellContainer,
} from './NavProfile.styles';

const ProfileAvatar = ({ onClick }, ref) => {
  const { isDashboardv2 } = useDashboard();
  return (
    <ProfileContainer isDashboardv2={isDashboardv2}>
      <Link
        to="/"
        className="nav-link-avatar"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <img
          data-cy="settings-arrow"
          className="arrow"
          src={ArrowDownIcon}
          alt=""
        />
      </Link>
    </ProfileContainer>
  );
};

// Helper to check if user id is demo account for showing lang/plan dropdowns
export const isDemoAccount = (username) => {
  switch (username) {
    case 'demo_student4':
      return true;
    case 'demo_student3':
      return true;
    case 'kssmaxlee':
      return true;
    default:
      return false;
  }
};

const NavProfile = ({ parentPortal, toggleAccessMenu }) => {
  const { t } = useTranslation(['navigation']);
  const history = useHistory();
  const location = useLocation();
  const { plan, currentProduct } = useSelector((state) => state.plan);
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { isErrorUserNotification } = useSelector(
    (state) => state.notification
  );
  const { isParentAccount } = useSelector((state) => state.login);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const handleLogout = () => logout();

  const { isDashboardv2 } = useDashboard();
  // component state
  const profilePicture = () => {
    if (studentDetails && studentDetails.ProfileAvatarImage !== null) {
      return studentDetails && studentDetails.ProfileAvatarImage;
    }
    return avatar;
  };

  return (
    <NavProfileContainer isMobile={isMobile}>
      {!isNil(currentProduct) && !currentProduct.expired && isDashboardv2 && (
        <Bouns />
      )}

      {!window.isKooSchool &&
        featureToggle.notification &&
        !isTablet &&
        !parentPortal &&
        (plan !== PRODUCT_TYPE.SCHOOL || isDesktopOrLaptop) && (
          <BellContainer
            onClick={() => history.push('/notification')}
            active={location.pathname === '/notification'}
          >
            <div className="bell-icon" />
            {!isNil(isErrorUserNotification) && <div className="dot">!</div>}
          </BellContainer>
        )}
      {!currentProduct?.expired &&
        !parentPortal &&
        toggleAccessMenu &&
        (plan === PRODUCT_TYPE.SCHOOL
          ? !isDesktopOrLaptop && <QuickAccessV2 profileOnly={false} />
          : plan !== PRODUCT_TYPE.SCHOOL && <QuickAccess />)}
      {plan === PRODUCT_TYPE.SCHOOL && (
        <img
          className="profile-picture"
          src={profilePicture()}
          alt="Profile"
          onError={(e) => (e.target.src = avatar)}
          onClick={() => {
            if (featureToggle.profile && !isMobile)
              history.push('/profile-settings/avatar');
          }}
        />
      )}
      {isTablet && plan !== PRODUCT_TYPE.SCHOOL && (
        <QuickAccessV2 profileOnly Toggle={forwardRef(ProfileAvatar)} />
      )}
      {(isDesktop || (isMobile && isParentAccount)) && (
        <Dropdown className="drop-down">
          <Dropdown.Toggle
            as={forwardRef(ProfileAvatar)}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu>
            {isParentAccount !== true && !currentProduct?.expired && (
              <>
                {PROFILE_MENU.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={() => history.push(item.url)}
                  >
                    {t(`navigation:menu.${item.translationKey}`, item.text)}
                  </Dropdown.Item>
                ))}
              </>
            )}
            <Dropdown.Item onClick={handleLogout}>
              {t('navigation:menu.logout', 'Logout')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </NavProfileContainer>
  );
};

export default NavProfile;
