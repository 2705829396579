import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHasSeenLoader } from 'store/dashboard/studentDetailsSlice';
import { featureToggle } from 'constants/index';
import useGlobalFeature from 'hooks/useGlobalFeature';
import withSubscriptionCheck from 'helpers/hoc/withSubscriptionCheck';
import Header from 'components/Header';
import { Arena, Play, Welcome, IntermediatePage, BrainGameEnglish } from '..';
import { Page, Main } from './BrainGame.styles';

const BrainGame = () => {
  const dispatch = useDispatch();
  const { isEnabled } = useGlobalFeature('braingames');
  useEffect(() => {
    dispatch(setHasSeenLoader(true));
  }, [dispatch]);
  return (
    <Page>
      <Header />
      <Main>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={[
                '/brain-game',
                '/brain-game/:island?',
                '/brain-game/arena/:categoryID',
                '/:feature/play/:game',
              ]}
              to="/maintenance-redirect"
            />
          )}
          {featureToggle.science && (
            <Route exact path="/brain-game" component={IntermediatePage} />
          )}
          <Route exact path="/brain-game/poc" component={BrainGameEnglish} />
          <Route
            exact
            path="/brain-game/:island?"
            render={({ match }) => {
              const WrappedComponent = withSubscriptionCheck(
                Welcome,
                match.params.island
              );
              return <WrappedComponent />;
            }}
          />
          <Route exact path="/brain-game/arena/:categoryID" component={Arena} />
          <Route exact path="/:feature/play/:game" component={Play} />
        </Switch>
      </Main>
    </Page>
  );
};

export default BrainGame;
