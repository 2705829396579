import React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import useQuery from 'helpers/useQuery';

import { KOOQUIZ_SUBJECT_ID } from 'constants/kooQuiz';

import HomeworkContent from 'components/Assignment/AssignmentFinish/HomeworkContent';
import DetailsInfo from 'components/Assignment/StartPage/DetailsInfo';
import { ErrorModal, Spinner } from 'components/Shared';
import { Header } from '../components';

import { Container, Main, AssignerSection } from './KooQuizFinish.styles';

const KooQuizFinish = () => {
  const query = useQuery();
  const hasPrint = query.get('print')?.toLowerCase() !== 'false';
  const {
    kooQuizSubmissionID,
    paperById,
    isKooQuizSaving,
    isKooQuizFinishing,
    kooQuizDuration,
    kooQuizSubmissions,
    kooQuizSaveError,
    kooQuizType,
    errorFinish,
  } = useSelector((state) => state.kooQuiz);

  const assignmentType = kooQuizType;

  return (
    <Container>
      <Header
        page="finish"
        assignmentTitle={paperById && paperById.Name}
        isLoading={isKooQuizSaving || isKooQuizFinishing}
        duration={kooQuizDuration}
        showDuration
        showPrint={hasPrint}
      />
      <Main assignmentType={assignmentType}>
        {!isKooQuizFinishing &&
          !isKooQuizSaving &&
          (!isNil(errorFinish) || kooQuizSaveError !== null) && (
            <ErrorModal errorMessage={errorFinish || kooQuizSaveError} />
          )}
        {(isKooQuizFinishing || isKooQuizSaving) && <Spinner color="#ebf7f9" />}
        {!isKooQuizFinishing &&
          !isKooQuizSaving &&
          isNil(errorFinish) &&
          kooQuizSaveError === null && (
            <>
              <div className="wrapper">
                <div className="summary-box">
                  <HomeworkContent
                    data={kooQuizSubmissions}
                    totalQns={kooQuizSubmissions.length}
                    duration={kooQuizDuration}
                    assignmentId={paperById && paperById.Id}
                    submissionId={kooQuizSubmissionID}
                    feature="kooQuiz"
                    showPrint={hasPrint}
                    subjectId={KOOQUIZ_SUBJECT_ID[kooQuizType]}
                  />
                </div>
              </div>
              <AssignerSection>
                <DetailsInfo
                  page="finish-hw"
                  category="Assigned by"
                  info="KooBits"
                />
              </AssignerSection>
            </>
          )}
      </Main>
    </Container>
  );
};

export default KooQuizFinish;
