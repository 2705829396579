import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ImgTeacherCLose from './teacher-close.jpg';
import {
  ButtonChallenge,
  Content,
  WrapImage,
  ImgClosed,
  Copyright,
} from './RestrictedArena.style';

const RestrictedArena = () => {
  const history = useHistory();
  const handleOnClick = useCallback(() => {
    history.push(`/dashboard`);
  }, [history]);
  return (
    <Content>
      <WrapImage>
        <ImgClosed src={ImgTeacherCLose} />
      </WrapImage>
      <ButtonChallenge onClick={handleOnClick}>Start Challenge</ButtonChallenge>
      <Copyright>
        Copyright © 2013-2021 KooBits Pte Ltd. All Rights Reserved. v 1.0.0
      </Copyright>
    </Content>
  );
};

export default React.memo(RestrictedArena);
