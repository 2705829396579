import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import { kooTrainingIcons } from 'constants/dashboardv2';

import { StyledContainer, StyledContent } from './AssignmentCard.styles';

const AssignmentImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(
    kooTrainingIcons[subject].assignment,
    kooTrainingIcons[subject].assignmentTablet,
    kooTrainingIcons[subject].assignmentMobile
  );
  return <img src={imgSrc} alt="assignment" />;
};

const AssignmentCard = () => {
  const { t } = useTranslation(['dashboardCards']);
  const history = useHistory();
  const { subject } = useSelector((state) => state.plan);

  const clickHandler = () => {
    history.push('/assignment');
  };

  return (
    <StyledContainer
      onClick={clickHandler}
      data-cy="assignment-card"
      subject={subject}
    >
      <div>
        <AssignmentImage subject={subject} />
        <StyledContent>
          <h3>{t('assignment.name', 'Assignment')}</h3>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};
export default AssignmentCard;
