import styled from 'styled-components';

const SkeletonContainer = styled.div`
  display: ${({ customDisplay }) => customDisplay};
  height: ${({ customHeight }) => customHeight};
  width: ${({ customWidth }) => customWidth};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  background: #ececec;
  background-image: -webkit-linear-gradient(
    left,
    #ececec 0px,
    #ddd 40px,
    #ececec 80px
  );
  background-image: -o-linear-gradient(
    left,
    #ececec 0px,
    #ddd 40px,
    #ececec 80px
  );
  background-image: linear-gradient(
    90deg,
    #ececec 0px,
    #ddd 40px,
    #ececec 80px
  );
  background-size: 250px;
  border-radius: 10px;
  -webkit-animation: shine-loading-container-items 2s infinite ease-out;
  animation: shine-loading-container-items 2s infinite ease-out;

  @-webkit-keyframes shine-loading-container-items {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }

  @keyframes shine-loading-container-items {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
`;

export default SkeletonContainer;
