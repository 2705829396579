import React from 'react';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './PopupModal.styles';

/**
 *  Add "animation" prop, refer by: https://stackoverflow.com/questions/60903335/warning-finddomnode-is-deprecated-in-strictmode-finddomnode-was-passed-an-inst
 */
export default function PopupModal({
  children,
  show,
  hide,
  backdrop,
  size,
  animation,
  ...props
}) {
  return (
    <StyledModal
      show={show}
      keyboard={false}
      onHide={hide}
      backdrop={backdrop}
      size={size}
      animation={animation}
      {...props}
    >
      <Modal.Body>{children}</Modal.Body>
    </StyledModal>
  );
}
