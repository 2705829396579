import React from 'react';
import { useTranslation } from 'react-i18next';
import LockedSvg from '../../imgs/science/locked.svg';
// eslint-disable-next-line import/no-cycle
import { Container } from './Book.styles';
import { SUBJECT_PARAMS } from '../../constants';

const Book = ({
  onClickImage,
  item,
  icon,
  submitStatus,
  selectedPaper,
  id,
  subjectId,
}) => {
  const { t } = useTranslation(['storySubject']);
  const { Status } = item;

  return (
    <Container
      data-cy={id}
      essentialSkills={selectedPaper === 1}
      submitStatus={submitStatus}
      subjectId={subjectId}
    >
      <div className="book">
        {Status === 'Locked' && (
          <div className="locked">
            <div
              className="overlay-locked"
              role="button"
              onClick={() => onClickImage(item)}
              aria-hidden="true"
            />
            <img className="book-locked" alt="locked" src={LockedSvg} />
          </div>
        )}
        {Status === 'Unlocked' && (
          <div
            className="overlay-open"
            onClick={() => onClickImage(item)}
            aria-hidden="true"
          >
            <button className="button" type="button">
              {t('storySubject:open', 'Open')}
            </button>
          </div>
        )}
        <img className="paper" src={icon} alt="paper" loading="lazy" />
        <img
          className="paper-sheet"
          alt="paper-sheet"
          src={SUBJECT_PARAMS[subjectId]?.bookShadow}
        />
      </div>
      <div className="line" />
    </Container>
  );
};

export default Book;
