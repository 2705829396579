import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const StyledLoaderWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 59px);
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    overflow: auto;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ITooltip = styled(Tooltip)`
  z-index: 3;

  .tooltip.show {
    opacity: 1;
  }

  .arrow::before {
    border-top-color: #404447 !important;
    border-bottom-color: #404447 !important;
  }

  .tooltip-inner {
    border-radius: 14px;
    color: #ffffff;
    background-color: #404447;
    text-align: left;
    padding: 14px 22px;
    white-space: nowrap;
    font-weight: 700;
    font-family: Linotte;
  }
`;
