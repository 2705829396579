import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import checkIsOpen from 'helpers/Dashboard/checkIsOpen';
import { useIsSMCEnabled } from './components/DailyChallengeCard/DailyChallengeCard';
import {
  StyledContainer,
  Overlay,
  MainSection,
  CardContainer,
  StackedCardsContainer,
  RewardsSection,
  StyledContent,
  StyledVerticalContainer,
} from './KooChallenge.styles';

import {
  SMCCard,
  DailyChallengeCard,
  PeerChallengeCard,
  SuperheroChallengeCard,
} from './components';
import { Rewards } from '../components';

const KooChallenge = () => {
  const { timezone } = useSelector((state) => state.login);
  const { subject } = useSelector((state) => state.plan);
  const { t } = useTranslation(['dashboard']);
  const isOpen = checkIsOpen(timezone);
  const isSMCEnabled = useIsSMCEnabled();

  return (
    <StyledContainer data-cy="koochallenge-screen">
      {!isOpen && (
        <Overlay>
          <p className="title">{t('kooChallenge.rest', `It's time to rest`)}</p>
          <p className="subtitle">
            {t('kooChallenge.kooChallenge', 'KooChallenge')}
          </p>
          <p className="desc">
            <Trans i18nKey="dashboard:kooChallenge.openHours">
              <span>6 am</span>
              <span>10 pm</span>
            </Trans>
          </p>
        </Overlay>
      )}
      <StyledContent>
        <MainSection subject={subject}>
          {isOpen && (
            <>
              <CardContainer data-cy="card-container">
                <DailyChallengeCard />
                <StackedCardsContainer topPadding={0}>
                  <PeerChallengeCard />
                  <StyledVerticalContainer isSMCEnabled={isSMCEnabled}>
                    <SuperheroChallengeCard isSMCEnabled={isSMCEnabled} />
                    {isSMCEnabled && <SMCCard />}
                  </StyledVerticalContainer>
                </StackedCardsContainer>
              </CardContainer>
              <p className="hours-desc">
                {t(
                  'kooChallenge.open',
                  'KooChallenge: Opening Hours: 6am to 10pm'
                )}
              </p>
            </>
          )}
        </MainSection>
        <RewardsSection topPadding={0} data-cy="rewards-section">
          <Rewards types={[1, 2]} />
        </RewardsSection>
      </StyledContent>
    </StyledContainer>
  );
};

export default KooChallenge;
