import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';
import { getRewards } from 'store/dashboard/rewardsSlice';
import ModalBgImg from 'assets/img/level/levelup-modal-bg.png';
import XPImg from 'assets/img/level/xp.png';
import Ribbon from 'components/Mission/Ribbon';
import { PopupModal } from 'components/Shared';
import {
  Container,
  AwesomeButton,
  ModalBg,
  ModalContainer,
} from './LevelUpModal.styles';

const LevelUpModal = () => {
  const { t } = useTranslation(['dailyChallengeQnView']);
  const dispatch = useDispatch();
  // Redux States
  const { totalExp } = useSelector((state) => state.dailyChallenge);
  // States
  const [showLevelUpModal, setShowLevelUpModal] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [upcomingLevels, setUpcomingLevels] = useState([]);
  // UseEffects
  // Get latest level details
  useEffect(() => {
    dispatch(getRewards()).then((result) => {
      if (
        result.payload?.result?.IsSuccessful === true &&
        result.payload?.result?.Result?.EXPData
      ) {
        setCurrentLevel(result.payload.result.Result.EXPData.CurrentLevel.ID);
        setUpcomingLevels(result.payload.result.Result.EXPData.UpcomingLevels);
      }
    });
  }, [dispatch]);
  // Trigger popup
  useEffect(() => {
    if (isNil(currentLevel) || isEmpty(upcomingLevels) || totalExp <= 0) return;
    // Calculate new level based on new total exp from submit response
    const eligibleUpcomingLevels = upcomingLevels.filter(
      (level) => totalExp >= level.MinExp
    );
    const newLevel =
      eligibleUpcomingLevels.length <= 0
        ? currentLevel
        : eligibleUpcomingLevels[eligibleUpcomingLevels.length - 1].ID;
    if (newLevel > currentLevel) {
      setCurrentLevel(newLevel);
      setShowLevelUpModal(true);
    }
  }, [currentLevel, totalExp, upcomingLevels]);

  // Event Handlers
  const hideHandler = () => {
    setShowLevelUpModal(false);
  };
  return (
    <PopupModal show={showLevelUpModal}>
      <ModalContainer data-cy="levelup-modal">
        <Ribbon
          text={t(
            'dailyChallengeQnView:allCorrectModal.congrats',
            'Congratulations!'
          )}
        />
        <Container>
          <p className="new-level">
            {t('dailyChallengeQnView:allCorrectModal.youAreNow', 'You are now')}
          </p>
          <p className="new-level" data-cy="new-level">
            {t('dailyChallengeQnView:allCorrectModal.level', 'Level')}{' '}
            {currentLevel}
          </p>
          <div className="level-badge">
            <img
              src={`https://static.content.koobits.com/coreproduct/EXPLevel/big/${currentLevel}.png`}
              alt="Level Badge"
              width="167px"
              height="167px"
            />
          </div>
          <p className="total-exp">
            {t(
              'dailyChallengeQnView:allCorrectModal.totalOf',
              'You have a total of'
            )}
          </p>
          <div className="exp-amt">
            <span>{totalExp}</span>
            <img src={XPImg} alt="XP" width="39px" height="31px" />
          </div>
          <AwesomeButton onClick={hideHandler}>
            {t('dailyChallengeQnView:allCorrectModal.awesome', 'Awesome')}
          </AwesomeButton>
        </Container>
        <ModalBg src={ModalBgImg} alt="" />
      </ModalContainer>
    </PopupModal>
  );
};

export default LevelUpModal;
