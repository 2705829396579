import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';
import { isNil } from 'ramda';

import placeHolderImg from 'assets/img/kooclass/sample-img/topic-img.svg';

import { Container, TopicCard } from './TopicDetails.styles';

const TopicDetails = ({ data, onClick }) => {
  const { t } = useTranslation(['topicSelection']);
  return (
    <Container onClick={onClick} className="browse-topic-item">
      <TopicCard
        bg={isNil(data.thumbnail) ? placeHolderImg : data.thumbnail}
        data-cy={data.name}
      />
      <div className="progress-details">
        <p className="scroll-progress">
          {data.currentUserProgress} <span>/{data.maxScrolls}</span>{' '}
          {t('topicSelection:topicScroll', 'Topic Scroll')}
        </p>
        <div className="progress-bar-section">
          <ProgressBar
            variant="correct"
            now={(data.currentUserProgress / data.maxScrolls) * 100}
            key={1}
          />
        </div>
      </div>
    </Container>
  );
};

export default TopicDetails;
