import styled from 'styled-components';
import Badge from '../../imgs/badge.svg';
import { mappingUnderline } from '../../constants';
import { ITooltip } from '../../DailyChallengeV2.styles';

export const HeaderLevelContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 0px;
  padding-left: 0px;
  & > span:not(:last-child) {
    display: none;
    width: 50px;
    height: 10px;
    border-radius: 50px;
    background-color: #ffd63c;
    position: relative;
    top: -6%;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      height: 10vw;
      max-height: 100%;
      & > span:not(:last-child) {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      position: relative;
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-start;
      margin-bottom: 0px;
      padding-left: 0px;
      & > span:not(:last-child) {
        width: 9%;
        margin-bottom: 0px;
      }
    }
  }
`;

export const LevelItem = styled.li`
  position: relative;
  height: 100%;
  aspect-ratio: 1;

  min-width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  user-select: none;
  font-size: 10px;

  transition: 200ms;
  cursor: ${(p) => (p.locked ? 'not-allowed' : 'pointer')};
  transform: scale(${(p) => (p.active ? '1.22' : '1')});
  color: #404447;

  &::before {
    content: '';
    width: ${(p) => mappingUnderline[p.currentLevel].width}%;
    height: 2.5%;
    background: ${(p) => (p.active ? '#404447' : 'transparent')};
    position: absolute;
    bottom: 8.5%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px 50px 10px 10px;
  }

  .dc-dot {
    margin-right: 2px;
  }

  & > p {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .dc-levels {
    position: relative;
    width: 64.1%;
  }

  .dc-level-icon {
    width: 100%;
    border-radius: 50%;
    opacity: ${(p) => (p.active ? '1' : '0.5')};
  }

  .dc-locked {
    position: absolute;
    right: -14px;
    bottom: 0;
  }

  .dc-levels::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 116%;
    height: 110%;
    background: ${(p) => (p.active ? '#f0b62a' : 'transparent')};
    z-index: -1;
    left: 50%;
    top: 48%;
    transform: translate(-50%, -50%);
  }

  &:hover .dc-levels::before {
    background: #f0b62a;
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      min-width: 10vw;
      font-size: 12px;
    }
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      aspect-ratio: 1 / 1.7;
      justify-content: center;
      font-size: min(1.2vw, 14px);
      min-width: auto;
      & > p {
        margin-bottom: 0px;
      }
    }
  }
`;
export const LevelTooltip = styled(ITooltip)`
  .dc-tooltip-badge {
    position: relative;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    width: 70px;
    text-align: center;
    background-image: url(${Badge});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
`;
