import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import BackButton from 'components/Shared/BackButton';
import LeftKid from 'assets/img/leaderboard/champion-class/Kids1.svg';
import RightKid from 'assets/img/leaderboard/champion-class/Kid2.svg';
import Gift from 'assets/img/leaderboard/champion-class/gift.svg';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import DropdownMenu from 'components/Shared/DropdownMenu';
import formatI18nDateTime from 'helpers/formatI18nDateTime';
import { getChampionClassMonth } from 'store/championClass/championClassSlice';
import { ClassAchievements } from 'constants/championClass';
import { CenterBar, TermClassic } from '../TopBrain/TopBrainHeader.styles';
import ChallengeType from '../ChallengeType';
import {
  Topbar,
  Terms,
  ClassicStyle,
  ProgressPercent,
  ButtonContainer,
  BrainHeading,
} from './ChampionClassHeader.styles';

const terms = [{ TermId: 1 }, { TermId: 2 }, { TermId: 3 }, { TermId: 4 }];

const ChampionClassHeader = ({
  activeCategory,
  activeTerm,
  setActiveTerm,
  currentMonthSelection,
  changeCurrentTerm,
  currentTerm,
  singaporeSchool,
  isTermlyOpen,
  isClassicOpen,
  changeMonth,
}) => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { months, championClassData, isError } = useSelector(
    (state) => state.championClass
  );

  useEffect(() => {
    dispatch(getChampionClassMonth());
    // eslint-disable-next-line
  }, []);

  const [dropdownItems, setDropdownItems] = useState('');
  // get 12 months data
  const monthsData = months.slice(0, 12).map((item) => item);

  const showMonthYear = (item) => {
    return `${item.Year}-${item.MonthName.substring(0, 3)}`;
  };

  // get year & month from selected dropdown item
  const dropdownValue = (value) => {
    changeMonth(value);
    setDropdownItems(value);
  };

  return (
    <Topbar>
      <ButtonContainer>
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(209, 194, 20, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </ButtonContainer>
      <img height="100%" src={LeftKid} alt="left-kid" className="left-kid" />
      <CenterBar challengeType="CC" activeTerm={activeTerm}>
        <div className="middle-content">
          <BrainHeading
            content={t(
              'leaderboard:championClass.championClass',
              `Champion Class`
            )}
          >
            {t('leaderboard:championClass.championClass', `Champion Class`)}
          </BrainHeading>
          {singaporeSchool && (
            <TermClassic
              active={activeTerm}
              bgActive="#FDE900"
              bgInactive="#DECB2E"
              colorActive="#726800"
              colorInactive="#9A8B01"
            >
              <div
                className="term"
                onClick={() => {
                  if (!isTermlyOpen) {
                    window.open(
                      'https://support.koobits.com/hc/en-gb/articles/4430653191449'
                    );
                    return;
                  }
                  setActiveTerm('term');
                }}
              >
                {t('leaderboard:topBrainBanner.termly', `Termly`)}
              </div>
              <div
                className="classic"
                onClick={() => {
                  if (!isClassicOpen) {
                    window.open(
                      'https://support.koobits.com/hc/en-gb/articles/4430653191449'
                    );
                    return;
                  }
                  setActiveTerm('classic');
                }}
              >
                {t('leaderboard:topBrainBanner.classic', `Classic`)}
              </div>
            </TermClassic>
          )}
        </div>
        {activeTerm === 'term' && (
          <Terms data-cy="term-container">
            {terms.map((item, i) => (
              <div
                className={
                  item.TermId === currentTerm.TermId ? 'active-term' : null
                }
                onClick={() => changeCurrentTerm(item.TermId)}
                key={`item-${i}`}
              >
                {t(
                  'leaderboard:topBrainBanner.term',
                  { number: item.TermId },
                  `Term ${item.TermId}`
                )}
              </div>
            ))}
          </Terms>
        )}
        {activeTerm === 'classic' &&
          activeCategory === 1 &&
          isError === null && (
            <Classics
              name={championClassData[0] && championClassData[0].ClassName}
              cp={championClassData[0] && championClassData[0].TotalCP}
            />
          )}
        {activeTerm === 'term' ? (
          <div className="scoring-period">
            <div>
              <span>
                {t(
                  'leaderboard:topBrainBanner.scoringPeriod',
                  'Scoring Period'
                )}
              </span>{' '}
              {t(
                'leaderboard:topBrainBanner.term',
                { number: currentTerm.TermId },
                `Term ${currentTerm.TermId}`
              )}
              ,{' '}
              {t('leaderboard:topBrainBanner.dateRange', {
                week: `${currentTerm.StartWeek} - ${currentTerm.EndWeek}`,
                start: moment(currentTerm.TermStartDate).format(
                  formatI18nDateTime({
                    language: i18n.language,
                    format: 'DD-MMM',
                    dateOnly: true,
                  })
                ),
                end: moment(currentTerm.TermEndDate).format(
                  formatI18nDateTime({
                    language: i18n.language,
                    format: 'DD-MMM',
                    dateOnly: true,
                  })
                ),
              })}
            </div>
            <div className="physical-prize">
              <img src={Gift} alt="gift" />
              {t(
                'leaderboard:championClass.bannerMsg',
                `Prizes will be sent to your school after the scoring period.`
              )}
            </div>
          </div>
        ) : (
          <div className="curriculum-date">
            <DropdownMenu
              dataCy="dropdown-month"
              selectedValue={
                dropdownItems === ''
                  ? currentMonthSelection
                  : showMonthYear(dropdownItems)
              }
              valueKey={null}
              values={monthsData}
              setValue={dropdownValue}
              valueTemplate={showMonthYear}
              width="150px"
              className="drop-down"
              indexKey
            />
          </div>
        )}

        <img
          className="right-kid"
          src={RightKid}
          alt="right-kid"
          style={{ bottom: '0', right: '-10%' }}
        />
      </CenterBar>
      <ChallengeType styles={{ top: '12%' }} />
    </Topbar>
  );
};

// progress bar function
const ProgressBar = (props) => {
  const currentCPs = props.cp;
  const currentLevelCPs = ClassAchievements(props.cp).nextCP;
  let percentage = 0;
  percentage = Math.round((currentCPs / currentLevelCPs) * 100);
  if (percentage > 100) percentage = 100;
  return (
    <ProgressPercent width={percentage}>
      <div className="current-progress" />
    </ProgressPercent>
  );
};

// classic class achievements comp
function Classics(props) {
  const { t } = useTranslation(['leaderboard']);

  const { startCP } = ClassAchievements(props.cp);
  const currentCP = props.cp;
  const { nextCP } = ClassAchievements(props.cp);

  return (
    <ClassicStyle>
      <div className="classic-banner">{props.name}</div>
      <div className="classic-body">
        <div className="front-container">
          <div className="star-class">
            {t(
              `leaderboard:championClass.classAchievements.${
                ClassAchievements(props.cp).name
              }`,
              `${ClassAchievements(props.cp).name}`
            )}
          </div>
          <div className="start-cps">
            <Trans i18nKey="leaderboard:championClass.starts" startCP={startCP}>
              <span>Starts from {{ startCP }}</span> CPs
            </Trans>
          </div>
        </div>
        <div className="middle-container">
          <div className="progressing">
            <span className="class-name">{props.name}</span>
            <img src={CPIcon} alt="cps" />
            <Trans
              i18nKey="leaderboard:championClass.currentCP"
              currentCP={currentCP}
            >
              <span className="total-cp">{{ currentCP }}</span>
              <span className="cps"> CPs</span>
            </Trans>
          </div>
          <ProgressBar cp={props.cp} />
        </div>
        <div className="end-container">
          <div className="gold-class">
            {t(
              `leaderboard:championClass.classAchievements.${
                ClassAchievements(props.cp).nextName
              }`,
              `${ClassAchievements(props.cp).nextName}`
            )}
          </div>
          <div className="total-cps">
            <Trans i18nKey="leaderboard:championClass.nextCP" nextCP={nextCP}>
              <span>{{ nextCP }}</span> CPs
            </Trans>
          </div>
        </div>
      </div>
    </ClassicStyle>
  );
}

export default ChampionClassHeader;
