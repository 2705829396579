import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import {
  getPeerChallengeSolutionV2,
  resetChallenge,
} from 'store/multiplayer/multiplayerSlice';
import { getUserInfos } from 'store/lookup/lookupSlice';

import { multiplayerListStyles } from 'constants/multiplayer';

import useQuery from 'helpers/useQuery';
import { getChallengerInfo } from '../helpers';

import useIsMobile from 'hooks/responsive/useIsMobile';

import { Notebook, QnViewHeader } from 'components/Shared';
import { PeerChallengeVersus } from '../components';
import { PeerChallengeResultNoteBookMiddle } from './components';
import { Container, Main } from './PeerChallengeResult.styles';
import { PeerChallengeHeader } from '../PeerChallengeQnView/PeerChallengeQnView.styles';

const PeerChallengeResult = ({ match }) => {
  const { t } = useTranslation(['peerChallengeQnView']);
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const subjectParam = query.get('subject');
  const lowercaseSubjectParam = isNil(subjectParam)
    ? 'math'
    : subjectParam.toLowerCase().trim();
  const { userID } = useSelector((state) => state.login);
  const { userInfoCache } = useSelector((state) => state.lookup);
  const peerChallengeId = parseInt(match.params.id, 10);
  const { peerChallengeSolution, solutionLoading, isErr } = useSelector(
    (state) => state.multiplayer
  );
  const challengerInfo = getChallengerInfo(
    peerChallengeSolution?.Challengers,
    userID,
    userInfoCache
  );
  const { page } = match.params;

  // responsive
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!userID) return;
    const params = { peerChallengeId, userID };
    dispatch(getPeerChallengeSolutionV2(params));
  }, [dispatch, peerChallengeId, userID]);
  useEffect(() => {
    if (!peerChallengeSolution) return;
    const challengersUserIds = peerChallengeSolution.Challengers.map(
      (challenger) => challenger.UserId
    );
    dispatch(
      getUserInfos({
        userIDs: challengersUserIds,
        pageIndex: 0,
        pageSize: challengersUserIds.length,
        cache: true,
      })
    );
  }, [dispatch, peerChallengeSolution]);

  const reloadResult = () => {
    if (!userID) return;
    const params = { peerChallengeId, userID };
    dispatch(getPeerChallengeSolutionV2(params));
  };

  return (
    <Container
      bgColour={
        multiplayerListStyles[lowercaseSubjectParam].qnView.mainBgColour
      }
    >
      {solutionLoading && (
        <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
          <Spinner animation="border" variant="warning" />
        </div>
      )}
      <QnViewHeader
        bgColor={multiplayerListStyles[lowercaseSubjectParam].qnView.bgColour}
        logoBgColor={
          multiplayerListStyles[lowercaseSubjectParam].qnView.logoBgColour
        }
        btnBgColor={
          multiplayerListStyles[lowercaseSubjectParam].qnView.btnBgColour
        }
        backText={t('peerChallengeQnView:header.back', 'Back')}
        hasBgImg={multiplayerListStyles[lowercaseSubjectParam].qnView.hasBgImg}
        onClick={() => {
          if (page === 'result') {
            history.push(
              `/multiplayer/peer-challenge/new-challenge?subject=${lowercaseSubjectParam}`
            );
            dispatch(resetChallenge());
          } else {
            history.goBack();
          }
        }}
      >
        <PeerChallengeHeader>
          <PeerChallengeVersus
            userData={challengerInfo.current}
            opponentData={challengerInfo.opponent}
          />
        </PeerChallengeHeader>
      </QnViewHeader>
      {!solutionLoading && (
        <Main>
          <Notebook
            page="peer-challenge-result"
            middleContent={
              <PeerChallengeResultNoteBookMiddle
                data={peerChallengeSolution}
                userID={userID}
                page="peer-challenge-result"
                subjectName={lowercaseSubjectParam}
                firstTry={page === 'result'}
              />
            }
            topContent={isMobile && <div style={{ height: 60 }} />}
            error={isErr}
            errorHandling={reloadResult}
          />
        </Main>
      )}
    </Container>
  );
};

export default PeerChallengeResult;
