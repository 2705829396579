import { useSelector } from 'react-redux';
import { PRODUCT_TYPE } from 'constants/products';
const useDashboard = () => {
  const { plan } = useSelector((state) => state.plan);
  const isDashboardv2 = PRODUCT_TYPE.PREMIUM === plan;

  return {
    isDashboardv2,
  };
};

export default useDashboard;
