import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { getGames, getStatus } from 'store/brainGame/brainGameSlice';

import useQuery from 'helpers/useQuery';
import { CATEGORIES } from './constants';
import { useBrainGameTimer } from '../hooks';

import { CategoryHeader, CardGame } from './components';
import { Content, TimeSpendWrap } from './Arena.style';

const Arena = () => {
  const dispatch = useDispatch();
  const { categoryID } = useParams();
  const query = useQuery();
  const subjectParam = query.get('subject') ?? '';
  const category = CATEGORIES[categoryID];
  const categoryTitle = category.title;
  const { games, startTime, isLoading } = useSelector(
    (state) => state.brainGame
  );

  const { t } = useTranslation(['brainGames']);
  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGames(categoryID));
  }, [categoryID, dispatch]);
  const images = require.context('./imgs', false, /\.(gif|png)$/);
  let BgImg;
  if (images.keys().includes(`./${categoryTitle}-bg.gif`)) {
    BgImg = images(`./${categoryTitle}-bg.gif`);
  } else if (images.keys().includes(`./${categoryTitle}-bg.png`)) {
    BgImg = images(`./${categoryTitle}-bg.png`);
  }

  useEffect(() => {
    document.body.style.backgroundImage = `url(${BgImg})`;
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [BgImg]);

  const timeRemaining = useBrainGameTimer(dispatch);
  return (
    <Content bg={BgImg}>
      <CategoryHeader
        categoryTitle={categoryTitle}
        subject={subjectParam}
        timeRemaining={timeRemaining}
      />
      <div className="container-sm">
        <TimeSpendWrap className="col-md-12 col-sm-12 col-xs-12">
          <p className="time-span">
            {' '}
            {t(
              'brainGames:timeHeader.startedTime',
              'Time You Started Today'
            )}:{' '}
          </p>
          <span>
            {isLoading && (
              <Spinner animation="border" variant="light" size="sm" />
            )}
            {!isLoading && startTime && moment(startTime).format('HH:mm:ss')}
            {!isLoading &&
              !startTime &&
              t(
                'brainGames:timeHeader.notStartedText',
                'You have not started playing'
              )}
          </span>
        </TimeSpendWrap>
        <div className="col-md-12 nopadding">
          <div className="row">
            {!isNil(games) &&
              games.map((val) => {
                return (
                  <div key={val.GameId} className="col-md-3 col-sm-6">
                    <CardGame
                      gameId={val.GameId}
                      name={val.Name}
                      game={val.GameFolder}
                      img={val.ThumbnailUrl}
                      unlock={val.Unlocked}
                      price={val.PriceToUnlock}
                      subject={subjectParam}
                      categoryBtnColor={category.btnColor}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default React.memo(Arena);
