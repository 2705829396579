import styled from 'styled-components';

const openChallengeHeaderColours = {
  math: '#1dc9fd',
  science: '#00C180',
  english: '#FD8B0E',
};

export const Container = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  .header {
    color: ${(props) =>
      props.status === 'close'
        ? '#A3A3A3'
        : openChallengeHeaderColours[props.subject] ?? '#1dc9fd'};
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px dashed
      ${(props) =>
        props.status === 'close'
          ? '#D8D8D8'
          : openChallengeHeaderColours[props.subject] ?? '#1dc9fd'};
    padding-bottom: 0.5rem;
  }
`;

export default {};
