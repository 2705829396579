import React from 'react';
import { useTranslation } from 'react-i18next';

import pauseBoy from 'assets/img/superherochallenge/superspeed/pause-boy.png';
import pauseGirl from 'assets/img/superherochallenge/superspeed/pause-girl.png';

import { Container, Title } from './Paused.styles';

function Paused() {
  const { t } = useTranslation(['superSpeed']);
  return (
    <Container>
      <div>
        <img alt="pause" src={pauseBoy} />
        <img alt="pause" src={pauseGirl} />
      </div>
      <Title>{t('superSpeed:game.pausedText', 'Game Paused')}</Title>
    </Container>
  );
}

export default React.memo(Paused);
