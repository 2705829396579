import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .faq-icon {
    z-index: 1;
  }
  .faq-title {
    display: flex;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: 25px;
    font-family: 'Muli';
    font-weight: 700;
    color: #000000;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0.3rem 1rem 0.3rem 1.5rem;
    z-index: 0;
    margin-left: -1.3rem;
    margin-bottom: 0.3rem;
    font-size: 10px;
    @media (max-width: 1185px) {
      display: ${({ isPopup }) => !isPopup && 'none'};
    }
  }
`;
