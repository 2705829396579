import React from 'react';
import { Book } from '..';
import { Container } from './BookShelf.styles';

const BookShelf = ({ selectedLevel, onClickImage, data, subjectId }) => {
  return (
    data.length > 0 && (
      <Container id={selectedLevel}>
        <div className="books">
          {data.map((item) => (
            <Book
              key={item.Id}
              id={item.Id}
              onClickImage={onClickImage}
              item={item}
              icon={item.Image}
              subjectId={subjectId}
            />
          ))}
        </div>
      </Container>
    )
  );
};

export default BookShelf;
