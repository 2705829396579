import moment from 'moment';
import { isNil } from 'ramda';

const isDateEqualToday = (dateTime) => {
  const currentDateTime = moment().format('D-MM-YYYY');
  if (dateTime === currentDateTime) {
    return true;
  }
  return false;
};
const isDateInFuture = (dateTime) => {
  const dateTimeToCheck = moment(dateTime, 'D-MM-YYYY');
  const currentDateTime = moment();
  const diff = moment
    .duration(dateTimeToCheck.diff(currentDateTime))
    .as('milliseconds');
  return diff > 0;
};

export const getNumOfDaysInMonth = (monthYear) => {
  return moment(monthYear, 'MM-YYYY').daysInMonth();
};
export const createDaysInMonthArray = (numOfDays, monthYear) => {
  const days = {};
  let firstDay = 1;
  for (firstDay; firstDay <= numOfDays; firstDay += 1) {
    // Check week number of date
    let week;
    const isoWeekNum = moment(
      `${firstDay}-${monthYear}`,
      'D-MM-YYYY'
    ).isoWeek();
    if (monthYear.slice(0, 2) === '01') {
      if (isoWeekNum >= 52) {
        week = 0;
      } else {
        week = isoWeekNum;
      }
    } else {
      week = isoWeekNum;
    }
    const dayOfWeek = moment(
      `${firstDay}-${monthYear}`,
      'D-MM-YYYY'
    ).isoWeekday();
    // Check if week number present as key
    if (days.hasOwnProperty(week)) {
      days[week][dayOfWeek - 1] = {
        day: firstDay,
        isToday: isDateEqualToday(`${firstDay}-${monthYear}`),
        isFuture: isDateInFuture(`${firstDay}-${monthYear}`),
      };
    } else {
      days[week] = Array(7).fill(null);
      days[week][dayOfWeek - 1] = {
        day: firstDay,
        isToday: isDateEqualToday(`${firstDay}-${monthYear}`),
        isFuture: isDateInFuture(`${firstDay}-${monthYear}`),
      };
    }
  }
  return days;
};
export const checkIfSelectedBadgeIsUnseen = (selectedBadge, unseenBadges) => {
  const findDayInUnseenBadges = unseenBadges.find((badge) => {
    const badgeDateTime = moment(
      badge.uEntity.Date,
      'YYYY-MM-DDTHH:mm:ss'
    ).format('D-MM-YYYY');
    return (
      badgeDateTime ===
      moment(selectedBadge?.uEntity?.Date, 'YYYY-MM-DDTHH:mm:ss').format(
        'D-MM-YYYY'
      )
    );
  });
  return !isNil(findDayInUnseenBadges);
};
