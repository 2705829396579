export const STYLED_OTP_INPUT = {
  width: '2rem',
  height: '3rem',
  margin: '0.5rem',
  fontSize: '24px',
};

export const RESEND_TIME = 60 * 1000;
export const COUNT_BACKWARDS_NUMBER = 60;
export const RESEND_NUMBER = 1;
export const MAX_TIMES = 3;
