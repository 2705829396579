import iconMath from 'assets/img/trial/icon-math.svg';
import iconScience from 'assets/img/trial/icon-science.svg';
import iconEnglish from 'assets/img/trial/icon-english.svg';
import { subjectIDs } from 'constants/products';

export const ICONS = {
  math: iconMath,
  science: iconScience,
  english: iconEnglish,
};
// selected subjects : bundleTypeId
export const SELECTED_BUNDLE = {
  1: 11,
  2: 12,
  3: 13,
  4: 14,
};
export const BUNDLE_TO_SELECT = Object.entries(SELECTED_BUNDLE).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {}
);
export const SELECT_SUBJECTS_ARR = Object.entries(subjectIDs).map(
  ([subjectId, subjectName]) => ({
    id: subjectId,
    name: subjectName.toLowerCase(),
    isSelected: false,
    isDisabled: false,
    endDate: null,
    expired: false,
  })
);
export const DISCOUNT_TYPE_NAME = {
  EVERGREEN: 'ever-green',
  PRORATION: 'proration',
  B2B_COUPON: 'b2b-coupon',
};

export const DISCOUNT_TYPE = {
  1: DISCOUNT_TYPE_NAME.EVERGREEN,
  2: DISCOUNT_TYPE_NAME.PRORATION,
  3: DISCOUNT_TYPE_NAME.B2B_COUPON,
};

export const PAYMENT_SCENARIO_NAME = {
  EARLY_RENEW: 'early-renew',
  UPGRADE: 'upgrade',
  BUY: 'buy',
};

export const PAYMENT_SCENARIO = {
  1: PAYMENT_SCENARIO_NAME.EARLY_RENEW,
  2: PAYMENT_SCENARIO_NAME.UPGRADE,
  3: PAYMENT_SCENARIO_NAME.BUY,
};
