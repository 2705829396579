import styled from 'styled-components';
import { cpIconColor } from 'constants/cpIcon';

export const Container = styled.div``;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 22px;
    margin-right: 0.25rem;
    color: ${({ subjectID }) => cpIconColor[subjectID]};
  }
`;
export const StyledErrMsg = styled.div`
  display: inline-flex;
`;
export const StyledReloadBtn = styled.button`
  color: #ffffff;
  background-color: #ff7121;
  border-radius: 14px;
  font-size: 14px;
  line-height: 1;
  margin-left: 1rem;
  border: 1px solid #ff7121;
`;
export const LeftContent = styled.div`
  width: 100%;
  .top-content {
    display: flex;
    flex-direction: row;
    .subcard {
      height: 200px;
      width: 200px;
      background: ${(props) =>
        props.status === 'close'
          ? `url(${props.closeImg})`
          : `url(${props.openImg})`};
      background-repeat: no-repeat;
      background-size: contain;
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      .subcard {
        height: 180px;
        width: 180px;
      }
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
      flex-direction: column;
      .subcard {
        height: 100px;
        width: 100px;
      }
    }
    .info-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 1.5rem;
      .top-info-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
      .challenge-name {
        color: #0d0d0d;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        span {
          font-size: 16px;
          font-weight: normal;
        }
      }
      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        margin-left: 0.75rem;
        .challenge-name {
          font-size: 20px;
        }
      }
      @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
        margin-left: 0.375rem;
        .challenge-name {
          font-size: 18px;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .bottom-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #dbdbdb;
    padding-top: 1rem;
    margin-top: 0.75rem;
  }
  .detail-two {
    display: flex;
    flex-direction: row;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-left: 1px solid #dbdbdb;
  .btn-group {
    .btn {
      padding: 6px 12px;

      @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
        padding: 3px 6px;
      }
    }
    img {
      position: absolute;
      right: 0;
      margin-right: -5%;
      z-index: 1;
      @media (max-width: 1441px) {
        width: 30px;
        margin-top: 2%;
        margin-right: 0%;
      }
    }
  }
  .closed {
    text-transform: uppercase;
    color: #ff7121;
    font-size: 17px;
  }
  .text {
    font-size: ${(props) => (props.status === 'close' ? '12px' : '16px')};
    padding: 0rem 1rem;
    margin-bottom: 0.3rem;
    color: ${(props) => props.status === 'locked' && '#FF7324'};
    font-weight: ${(props) => props.status === 'locked' && '600'};
    text-align: ${(props) => props.status === 'locked' && 'center'};
    line-height: ${(props) => props.status === 'locked' && '1.2rem'};
  }
  .btn-message {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: auto;
    border-left: none;
    .text {
      font-size: 12px;
    }
  }
`;

export const UnlockContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  margin-bottom: 0.5rem;
  background-color: #fff7e2;
  .text {
    font-size: 14px;
    margin-bottom: 0px;
    color: #ff6701;
  }
  svg {
    margin-right: 0.75rem;
    max-width: 110px;
    color: ${({ subjectID }) => cpIconColor[subjectID]};
  }
`;
