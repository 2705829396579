import styled from 'styled-components';
import allBadge from 'constants/allBadge';
import { LEADERBOARD_COLORS } from 'pages/Leaderboard/v2/constant';

const DEFAULT_SUBJECT = 'math';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: ${({ width }) => width ?? '70%'};
  height: 100%;
  padding: 20px 30px;
  @media (max-width: 1024px) {
    width: ${({ width }) => width ?? '90%'};
  }
  .list-headings {
    display: flex;
    width: 100%;
    padding: 0 5px 0 35px;
  }
  .nearby-container {
    background-color: ${({ subject }) =>
      LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].nearbyBgColor};
    border: 1px solid transparent;
    border-radius: 20px;
    margin: 5px 0 20px;
    display: flex;
    justify-content: center;
    .nearby {
      border: ${({ subject }) =>
        `1px solid ${
          LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].nearbyBdColor
        }`};
      background-color: ${({ subject }) =>
        LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].nearbyBtnColor};
      border-radius: 20px;
      color: ${({ subject }) =>
        LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].nearbyTxtColor};
      font-size: 18px;
      padding: 5px 10px;
      width: 30%;
      text-align: center;
      cursor: pointer;
    }
  }
  .current-user {
    background-color: #fece18 !important;
  }
`;

export const Headings = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ subject }) =>
    LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].headingColor};
  font-weight: 600;
  flex: ${({ flex }) => flex};
`;

export const Lists = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid black;
  align-items: center;
  padding: 3px 10px 3px 45px;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  :nth-of-type(odd) {
    background-color: ${({ subject }) =>
      LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].oddBgColor};
    border-color: ${({ subject }) =>
      LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].oddBdColor};
  }
  :nth-of-type(even) {
    background-color: ${({ subject }) =>
      LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].evenBgColor};
    border-color: ${({ subject }) =>
      LEADERBOARD_COLORS[subject || DEFAULT_SUBJECT].evenBdColor};
  }
  border-color: ${(props) =>
    props.currPos !== 'default'
      ? allBadge[props.currPos].borderCol
      : 'auto'}!important;
  background-image: ${(props) => allBadge[props.currPos].gradient};
  .positions {
    color: ${(props) => allBadge[props.currPos].badgeColor};
    background: url(${(props) => allBadge[props.currPos].bgImg});
    position: absolute;
    left: ${(props) => (props.currPos === 'default' ? '-8px' : '-12px')};
    height: ${(props) => (props.currPos === 'default' ? '30px' : '40px')};
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    background-repeat: no-repeat;
    background-size: 50px 100%;
  }
  .list-name {
    flex: 2 1 0%;
  }
  .list-school {
    flex: 2 1 0%;
    display: flex;
    align-items: center;
    .school-img-container {
      width: 30px;
      height: 30px;
      border: 1px solid #d4ecf5;
      border-radius: 5px;
      margin-right: 5px;
      background-color: #ffffff;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .list-points {
    flex: 1 1 0%;
    font-size: 17px;
    font-weight: 700;
    padding-left: ${({ subject }) => (subject === 2 ? '0%' : '5%')};
    img {
      margin: 0 5px;
    }
  }
  .list-achievement {
    flex: 1 1 0%;
  }
`;
