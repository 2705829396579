import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { ReactComponent as SuperheroIcon } from 'assets/img/leaderboard/big-superhero.svg';
import SuperHero from 'assets/img/leaderboard/super-hero.svg';

import PopupModal from 'components/Shared/PopupModal';
import Button from 'components/Shared/Button';

import { ModalContent } from './SuperheroIconModal.styles';

const SuperheroIconModal = () => {
  const { t } = useTranslation(['common', 'leaderboard']);
  const [open, setOpen] = useState(false);

  return (
    <>
      <img
        className="superhero-icon"
        src={SuperHero}
        alt="cps"
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer' }}
      />
      <PopupModal show={open} hide={() => setOpen(false)}>
        <ModalContent>
          <SuperheroIcon className="icon" />
          <p className="title">
            {t('leaderboard:superheroModal.title', 'How to earn this badge?')}
          </p>
          <p className="desc">
            <Trans i18nKey="leaderboard:superheroModal.desc">
              Earn at least <span>200 Super Points in a week</span> to unlock
              this badge. You can earn Super Points from
              <span> Super Speed</span> or <span>Super Vision Challenge.</span>
            </Trans>
          </p>
          <Button
            variant="orange4"
            borderRadius="22px"
            onClick={() => setOpen(false)}
            padding="0.5rem 2.5rem"
          >
            {t('common:gotIt', 'Got it')}
          </Button>
        </ModalContent>
      </PopupModal>
    </>
  );
};

export default SuperheroIconModal;
