import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isSundayBetween6amTo10pm } from 'helpers/timezone';

import { PopupModal } from 'components/Shared';
import SMCModal from '../SMCModal';

const CustomSMCModal = ({ timezone }) => {
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const { currentProduct } = useSelector((state) => state.plan);
  const isLocalizedUser =
    (studentDetails && studentDetails.Roles.includes(107)) ?? false;
  const countryId = currentProduct?.subscriptionDetails?.CountryId;
  const isIDUser = countryId === 100;
  const isHKUser = countryId === 96;
  const hasSMC = !isIDUser && !isHKUser;
  const isSundayNow = isSundayBetween6amTo10pm(timezone);
  const [showSMCModal, setShowSMCModal] = useState(
    isSundayNow && (!isLocalizedUser || (isLocalizedUser && hasSMC))
  );

  return (
    <PopupModal show={showSMCModal} backdrop="static">
      <SMCModal setShowSMCModal={setShowSMCModal} />
    </PopupModal>
  );
};

export default CustomSMCModal;
