import React, { useEffect, useState, useCallback } from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getClassmateSearchResults,
  getSameLevelClassesSearchResults,
} from 'services/search';
import { fetchWrapper } from 'services/login';

import { Button } from 'components/Shared';
import DropdownWithSearch from '../DropdownWithSearch';
import { DropdownContainer } from './MyClassmateContent.styles';
const MyClassmateContent = ({
  subjectID,
  opponentType,
  setOpponentType,
  setNewSelectedOpponentId,
}) => {
  const { t } = useTranslation(['newChallenge']);
  const defaultStudentPlaceholder = t(
    'newChallenge:main.selectStudent',
    'Select Student'
  );
  const defaultClassPlaceholder = t(
    'newChallenge:main.selectClass',
    'Select Class'
  );
  const { userID } = useSelector((state) => state.login);
  const { selectedOpponent } = useSelector((state) => state.multiplayer);
  const [loadedClasses, setLoadedClasses] = useState(() => JSON.stringify({}));
  const [classesError, setClassesError] = useState(null);
  const [classGroup, setClassGroup] = useState({});
  const [loadedClassID, setLoadedClassID] = useState({});
  const stringifiedClassData = JSON.stringify(loadedClassID);
  const [placeholderStudent, setPlaceholderStudent] = useState(
    defaultStudentPlaceholder
  );

  const getClasses = useCallback(() => {
    if (isNil(userID)) return;
    setClassesError(null);
    fetchWrapper(getSameLevelClassesSearchResults, {
      userID,
      searchString: '',
      pageIndex: 0,
      pageSize: 10,
    })
      .then((res) => {
        if (res.List.length <= 0) return;

        const stringifiedData = JSON.stringify({
          ...res,
          List: res.List.map((classObj) => ({
            ...classObj,
            id: classObj.ID,
            name: classObj.Name,
          })),
        });
        setLoadedClasses(stringifiedData);
      })
      .catch((err) => setClassesError(err.message));
  }, [userID]);
  const setOpponentTypeAsClassmate = () => {
    setOpponentType('2');
  };
  const loadMoreInitialClassmateList = useCallback(
    (pageIndex) => {
      return new Promise((resolve, reject) => {
        if (isNil(classGroup?.ID))
          return resolve({
            status: 'idle',
            List: [],
            PageIndex: 0,
            PageSize: 0,
            TotalCount: 0,
            TotalPages: 0,
            LastNoInPage: 0,
            HasPreviousPage: false,
            HasNextPage: false,
            error: null,
          });
        fetchWrapper(getClassmateSearchResults, {
          groupID: classGroup.ID,
          subjectID,
          searchString: '',
          pageIndex,
        })
          .then((res) => {
            // Filter out current user from list
            const filteredList = res.List.filter(
              (user) => user.Id !== userID
            ).map((user) => ({ id: user.Id, name: user.FullName }));
            resolve({
              ...res,
              List: filteredList,
            });
          })
          .catch((err) => reject(err));
      });
    },
    [classGroup.ID, subjectID, userID]
  );
  const loadInitialClassmateListFromState = useCallback(() => {
    return new Promise((resolve) => {
      const classData = JSON.parse(stringifiedClassData);
      return resolve(classData);
    });
  }, [stringifiedClassData]);
  const searchClassmate = useCallback(
    (searchString, pageIndex) => {
      return fetchWrapper(getClassmateSearchResults, {
        groupID: classGroup?.ID,
        subjectID,
        searchString,
        pageIndex,
      }).then((res) => ({
        ...res,
        List: res.List.filter((user) => user.Id !== userID),
      }));
    },
    [classGroup.ID, subjectID, userID]
  );
  const loadInitialClassListFromState = useCallback(() => {
    return new Promise((resolve) => {
      const classData = JSON.parse(loadedClasses);
      return resolve(classData);
    });
  }, [loadedClasses]);
  const loadMoreInitialClassList = useCallback(
    (pageIndex) => {
      return new Promise((resolve, reject) => {
        if (isNil(userID))
          return resolve({
            status: 'idle',
            List: [],
            PageIndex: 0,
            PageSize: 0,
            TotalCount: 0,
            TotalPages: 0,
            LastNoInPage: 0,
            HasPreviousPage: false,
            HasNextPage: false,
            error: null,
          });
        fetchWrapper(getSameLevelClassesSearchResults, {
          userID,
          searchString: '',
          pageIndex,
          pageSize: 10,
        })
          .then((res) => {
            resolve({
              ...res,
              List: res.List.map((classObj) => ({
                ...classObj,
                id: classObj.ID,
                name: classObj.Name,
              })),
            });
          })
          .catch((err) => reject(err));
      });
    },
    [userID]
  );
  const searchClassByName = useCallback(
    (searchString, pageIndex) => {
      return fetchWrapper(getSameLevelClassesSearchResults, {
        userID,
        searchString,
        pageIndex,
        pageSize: 10,
      }).then((res) => ({
        ...res,
        List: res.List.map((classObj) => ({
          ...classObj,
          Id: classObj.ID,
          FullName: classObj.Name,
        })),
      }));
    },
    [userID]
  );

  // UseEffects
  useEffect(() => {
    getClasses();
  }, [getClasses]);
  useEffect(() => {
    if (isNil(classGroup?.ID) || !userID) return;
    fetchWrapper(getClassmateSearchResults, {
      groupID: classGroup.ID,
      subjectID,
      searchString: '',
      pageIndex: 0,
    }).then((res) => {
      // Filter out current user from list
      const filteredList = res.List.filter((user) => user.Id !== userID).map(
        (user) => ({ id: user.Id, name: user.FullName })
      );
      const hasResults = filteredList.length > 0;
      const studentName = hasResults
        ? filteredList[0].name
        : defaultStudentPlaceholder;
      const opponentId = hasResults ? filteredList[0].id : '';
      setPlaceholderStudent(studentName);
      setOpponentType((prevState) => {
        if (prevState === '2') {
          setNewSelectedOpponentId('2', opponentId);

          return prevState;
        }
        if (prevState !== '1') return prevState;
        setNewSelectedOpponentId('2', opponentId);
        return '2';
      });
      setLoadedClassID({
        classID: classGroup.ID,
        ...res,
        List: filteredList,
      });
    });
  }, [
    classGroup,
    setOpponentType,
    defaultStudentPlaceholder,
    userID,
    selectedOpponent,
    setNewSelectedOpponentId,
    subjectID,
  ]);
  return (
    <>
      {!isNil(classesError) && (
        <div style={{ width: '100%', padding: '0 12px', textAlign: 'center' }}>
          <p>{classesError}</p>
          <Button
            dataCy="error-btn"
            variant="primary"
            margin="0 auto"
            onClick={getClasses}
          >
            Reload
          </Button>
        </div>
      )}
      <DropdownContainer>
        {isNil(classesError) && (
          <DropdownWithSearch
            placeholder={defaultClassPlaceholder}
            setOpponentTypeFunc={setOpponentTypeAsClassmate}
            fetchInitialUserListFunc={loadInitialClassListFromState}
            loadMoreInitialUserListFunc={loadMoreInitialClassList}
            fetchSearchResultsFunc={searchClassByName}
            customSetItemFunc={setClassGroup}
          />
        )}
        <br />
        <DropdownWithSearch
          placeholder={placeholderStudent}
          opponentType={opponentType}
          setOpponentTypeFunc={setOpponentTypeAsClassmate}
          setNewSelectedOpponentId={setNewSelectedOpponentId}
          fetchInitialUserListFunc={loadInitialClassmateListFromState}
          loadMoreInitialUserListFunc={loadMoreInitialClassmateList}
          fetchSearchResultsFunc={searchClassmate}
          isDisabled={isNil(classGroup?.ID)}
        />
      </DropdownContainer>
    </>
  );
};

export default MyClassmateContent;
