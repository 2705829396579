import styled from 'styled-components';
import MainGreenBg from 'assets/img/assignment/assignment-view/content-green-bg.svg';
import Bubble from './white-bubble.svg';

export const Container = styled.div`
  background: url(${MainGreenBg});
  background-color: #0883a0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  p {
    margin: 0px;
  }
`;

export const Left = styled.div`
  background-color: #ffffff;
  border-radius: 41px;
  width: 45%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .kooClass-info {
    padding: 2rem;
    height: inherit;
    h4 {
      color: #00beeb;
      font-size: 32px;
      font-weight: bold;
    }
    .lesson {
      color: #fca603;
      font-size: 24px;
      font-weight: bold;
    }
    .lesson-desc {
      color: #050505;
      font-size: 18px;
      font-weight: 600;
    }
    hr {
      border-bottom: 1px dashed #219cb9;
      border-top: none;
    }
    h5 {
      color: #49a6d0;
      font-size: 20px;
      font-weight: bold;
    }
    .lessons-list {
      overflow-y: auto;
      height: 75%;
      @media (max-width: 1024px) {
        height: 65%;
      }
    }
    .lesson-learned {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 1rem;
      img {
        margin-right: 0.5rem;
      }
    }
  }
  .lessons-btn {
    background-color: rgba(85, 197, 224, 0.19);
    border-bottom-left-radius: 41px;
    border-bottom-right-radius: 41px;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  height: 80%;
  margin-left: 1rem;
  .mascot {
    cursor: pointer;
    width: 45%;
    @media (max-width: 1024px) {
      width: 70%;
    }
  }
`;

export const BubbleContainer = styled.div`
  width: 100%;
  background: url(${Bubble});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  p {
    font-size: 20px;
    line-height: 1.2;
    padding: 10% 15% 18% 2rem;
    @media (max-width: 1024px) {
      font-size: 15px;
    }
  }
  .mission-text {
    color: #00baff;
    font-size: 29px;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
    }
  }
  img {
    width: 22px;
    height: 22px;
  }
`;
