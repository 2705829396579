import React from 'react';
import { useTranslation } from 'react-i18next';
import IconSuccess from 'assets/img/friends/icon-success.svg';
import IconOps from 'assets/img/friends/icon-ops.svg';
import { Button, Spinner } from 'components/Shared';
import { Container } from './ResultModal.styles';

const ResultModal = ({
  type,
  onClick,
  error,
  desc,
  btnText,
  loading,
  data,
  name,
  errorBtn,
}) => {
  const { t } = useTranslation(['friends']);
  return (
    <Container error={error} data-cy="modal-container">
      {loading && <Spinner />}
      {!loading && (
        <>
          <img className="icon" src={data ? IconSuccess : IconOps} alt="icon" />
          <p className="success-text">
            {data
              ? t('friends:addFriend.success', 'Success')
              : t('friends:addFriend.oops', 'Oops!')}
          </p>
          {data && <p className="desc">{desc}</p>}
          {error && <p className="error-msg">{error}</p>}
          {data && type === 'invite-friend' && (
            <p className="bff-name">{name}</p>
          )}
          {type !== 'incoming' && (
            <Button
              dataCy="modal-btn"
              width="220px"
              onClick={onClick}
              variant="primary"
            >
              {!errorBtn && btnText}
              {errorBtn && data ? btnText : errorBtn}
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

export default ResultModal;
