import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const CardImg = styled.img`
  width: 100%;
  cursor: pointer;
`;
export const WrapUnlock2 = styled.div`
  width: 100%;
  margin: 10px 0 25px 0;
  text-align: center;
  position: relative;
  cursor: pointer;
  > div {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: ${({ unlock }) => (unlock ? 'space-between' : 'center')};
    padding: 0 ${({ unlock }) => (unlock ? 40 : 0)}px;
  }
  span {
    color: #ffffff;
    font-family: arial;
  }
  .koko {
    margin-right: 8px;
    margin-left: 14px;
    width: 38px;
  }
  .tick {
    padding: 7px;
    background: #61e26e;
    border-radius: 50%;
    margin-right: 12px;
  }
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 91%;
    height: ${({ unlock }) => (unlock ? 98 : 94)}%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    background: ${({ categoryBtnColor }) => categoryBtnColor || '#0679D1'};
    z-index: 1;
  }
`;
