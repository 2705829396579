import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { periodOptions } from 'constants/history';
import { ReactComponent as CPIcon } from 'assets/img/icon-challenge-point-current-color.svg';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import BackButton from 'components/Shared/BackButton';
import DropdownMenu from 'components/Shared/DropdownMenu';
import RefreshButton from 'components/Shared/RefreshButton/RefreshButton';
import {
  StyledContainer,
  StyledBackground,
  StyledDropdownWrapper,
  StyledBalance,
  StyledBalanceValue,
  StyledBackButtonWrap,
  StyledDropdownMenuBox,
} from './CPHistoryHeader.styles';
import { useHistory } from 'react-router-dom';
const year = moment().format('YYYY');
const CPHistoryHeader = ({
  selectPeriod,
  setSelectPeriod,
  options,
  searchAction,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['cpHistory']);
  const { subject } = useSelector((state) => state.plan);
  const { Balance, isLoading } = useSelector((state) => state.cpHistory);
  return (
    <StyledBackground subject={subject}>
      <StyledContainer>
        <StyledDropdownWrapper>
          <StyledBackButtonWrap>
            <BackButton
              backText={t('backBtn', 'Back')}
              btnBgColor={subject === 'math' ? '#A0F4FC' : '#B5FF5F'}
              padding="5px 20px"
              color={subject === 'math' ? '#19AFBC' : '#5C911F'}
              onClick={() => history.push('/dashboard')}
            />
          </StyledBackButtonWrap>
          <span>{t('period.label', 'Select Period')}</span>
          <StyledDropdownMenuBox>
            <DropdownMenu
              width="184px"
              values={options}
              setValue={setSelectPeriod}
              selectedValue={t(
                `period.options.${periodOptions[selectPeriod].i18nKey}`,
                `${periodOptions[selectPeriod].label}`
              )}
              valueTemplate={(value) =>
                t(
                  `period.options.${periodOptions[value].i18nKey}`,
                  periodOptions[value].label
                )
              }
            />
            <p>{t('period.limit', '*Only up to the past month')} </p>
          </StyledDropdownMenuBox>

          <RefreshButton
            onClick={searchAction}
            style={{ marginLeft: '12px' }}
          />
        </StyledDropdownWrapper>

        <StyledBalance subject={subject}>
          <span>{t('balance', { year }, `Total CP earned for ${year}`)}</span>
          <CPIcon />
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <StyledBalanceValue data-cy="balance">{Balance}</StyledBalanceValue>
          )}
        </StyledBalance>
      </StyledContainer>
    </StyledBackground>
  );
};

export default CPHistoryHeader;
