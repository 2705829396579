import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CalendarIcon from 'assets/img/events/icon-event.svg';
import { BackButton } from 'components/Shared';
import { Container } from './Topbar.styles';

const Topbar = () => {
  const { t } = useTranslation(['eventList', 'startPage']);
  const history = useHistory();
  const location = useLocation();
  const isFromShortCut = location.state?.quickAccess;

  return (
    <Container>
      <BackButton
        backText={t('startPage:words.back', 'Back')}
        btnBgColor="rgb(240, 83, 71, 0.72)"
        padding="0.3rem 1.2rem"
        onClick={() =>
          isFromShortCut ? history.goBack() : history.push('/dashboard')
        }
        styles={{ position: 'absolute', left: '5%', top: '4.9rem' }}
        isResponsive
      />
      <div className="stars">
        <div className="title">
          <img src={CalendarIcon} alt="event" />
          <p>{t('eventList:header.events', 'Events')}</p>
        </div>
      </div>
    </Container>
  );
};

export default Topbar;
