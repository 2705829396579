import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  height: 32px;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    transform: scale(0.8);
  }

  @media (max-width: 500px) {
    margin-right: -4rem;
  }

  @media (max-width: 400px) {
    margin-right: -3rem;
    transform: scale(0.6);
  }
`;

export const Tab = styled.div`
  border-radius: 18px;
  height: 23px;
  min-width: 77px;
  color: ${(props) => (props.active ? 'white' : '#404447')};
  background: ${(props) => (props.active ? '#95DB48' : 'transparent')};
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  margin-left: ${(props) => (props.index > 0 ? '2rem' : '0')};
  cursor: pointer;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: ${({ iconWidth }) => iconWidth};
    margin-right: 4px;
  }
`;
