import React from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { ERROR_TYPES } from 'helpers/accessFeature';
import PremiumOnlyImg from 'assets/img/access-menu/premium-only.png';
import ClosedImg from 'assets/img/access-menu/closed.png';
import {
  StyledModalContainer,
  StyledButton,
  StyledHeader,
} from './RestrictedFeatureModal.styles';

const ClosedContent = () => {
  const { t } = useTranslation(['shortcutMenu']);
  return (
    <>
      <img src={ClosedImg} alt="Closed" className="closed-img" />
      <p style={{ marginTop: '4rem', marginBottom: '26px' }}>
        {t('shortcutMenu:openingHours', 'Opening Hours: 6am to 10pm')}
      </p>
    </>
  );
};

const PremiumOnlyContent = () => {
  return (
    <img src={PremiumOnlyImg} alt="Premium Only" style={{ margin: '2rem 0' }} />
  );
};

const NotAvailableContent = ({ featureName }) => {
  const { t } = useTranslation(['shortcutMenu']);
  return (
    <>
      <StyledHeader>
        {t('shortcutMenu:notAvailable', 'Not Available')}
      </StyledHeader>
      <p style={{ marginBottom: '45px' }}>
        {t(
          'shortcutMenu:featureNotAvailable',
          { feature: featureName },
          `${featureName} is not available.`
        )}
      </p>
    </>
  );
};

const RestrictedFeatureModal = ({
  setShowPopup,
  setPopupErrCode,
  errCode,
  featureName,
}) => {
  const { t } = useTranslation(['common']);
  const errorObj = ERROR_TYPES[errCode];
  const okHandler = () => {
    setShowPopup(false);
    setPopupErrCode(null);
  };
  if (isNil(errorObj)) return null;
  return (
    <StyledModalContainer>
      {errCode === 'err001' && <ClosedContent />}
      {errCode === 'err003' && <PremiumOnlyContent />}
      {errCode === 'err002' && (
        <NotAvailableContent featureName={featureName} />
      )}
      <StyledButton onClick={okHandler} type="button">
        {t('common:ok', 'OK')}
      </StyledButton>
    </StyledModalContainer>
  );
};

export default RestrictedFeatureModal;
