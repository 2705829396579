import React from 'react';
import { Arrow } from './Arrow.styles';
import ArrowLeft from './left-mouse.png';
import ArrowLeftOver from './left-mouseover.png';
import ArrowRight from './right-mouse.png';
import ArrowRightOver from './right-mouseover.png';

const RenderArrow = ({ disabled, type, onClickArrow }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <Arrow
      disabled={disabled}
      role="button"
      tabIndex={0}
      onClick={onClickArrow}
      onMouseOver={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeftOver : ArrowRightOver;
      }}
      onFocus={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeftOver : ArrowRightOver;
      }}
      onMouseOut={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeft : ArrowRight;
      }}
      onBlur={(e) => {
        e.currentTarget.src = type === 'left' ? ArrowLeft : ArrowRight;
      }}
    >
      <img alt="arrow" src={type === 'left' ? ArrowLeft : ArrowRight} />
    </Arrow>
  );
};
export default RenderArrow;
