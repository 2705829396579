/* eslint-disable import/order */
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { getStatus } from 'store/brainGame/brainGameSlice';

import useDashboard from 'hooks/useDashboard';
import { useBrainGameTimer } from '../hooks';
import { SUBJECT_ISLANDS } from './constants';

import useQuery from 'helpers/useQuery';
import { secondsToMinute } from '../helpers';

import { BackButton, Spinner } from 'components/Shared';
import { GameScene } from '../components';
import { CloseArena, RestrictedArena } from './components';

import { Timer } from './Welcome.styles';

const checkTime = (openTime, closeTime, timezone) => {
  if (isNil(openTime)) {
    return true;
  }

  const time = moment().tz(timezone);
  const beforeTime = moment(openTime).tz(timezone, true);
  const afterTime = moment(closeTime).tz(timezone, true);

  return time.isBetween(beforeTime, afterTime);
};

const Welcome = () => {
  const query = useQuery();
  const skipParam = query.get('skip');
  const { island } = useParams();
  const dispatch = useDispatch();
  const { timezone } = useSelector((state) => state.login);
  const history = useHistory();
  const { t } = useTranslation(['common', 'brainGames']);
  const { isDashboardv2 } = useDashboard();

  const {
    openTime,
    closeTime,
    RestrictedByTeacher,
    isLoading,
    error,
  } = useSelector((state) => state.brainGame);

  const lowercaseSubjectName = island.toLowerCase().trim();
  const isSkipIsland =
    !isNil(skipParam) && skipParam.toLowerCase().trim() === 'true';
  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);
  useEffect(() => {
    if (checkTime(openTime, closeTime, timezone)) {
      document.body.style.backgroundColor = '#04A0C5';
    } else if (RestrictedByTeacher) {
      document.body.style.backgroundColor = '#5DADDE';
    } else {
      document.body.style.backgroundColor = '#154A89';
    }

    return () => {
      document.body.style.backgroundColor = null;
    };
  }, [RestrictedByTeacher, closeTime, dispatch, openTime, timezone]);
  const timeRemaining = useBrainGameTimer(dispatch);

  if (RestrictedByTeacher) {
    return <RestrictedArena />;
  }
  const open = checkTime(openTime, closeTime, timezone);
  return (
    <>
      {isLoading && <Spinner color="white" />}
      {!isLoading && error !== null && (
        <h2 style={{ textAlign: 'center', marginTop: '2rem', width: '100%' }}>
          {error}
        </h2>
      )}
      {!isLoading && (
        <BackButton
          backText={t('common:back', 'Back')}
          btnBgColor="rgba(0,131,180,0.6)"
          padding="0.3rem 1.2rem"
          onClick={() => {
            if (isDashboardv2) {
              history.push('/dashboard?view=koofun');
            } else {
              history.push('/brain-game');
            }
          }}
          styles={{ position: 'absolute', left: '4.5%', top: '5rem' }}
        />
      )}
      {!isLoading && (
        <Timer>
          <p className="header">
            {t('brainGames:welcome.time.remaining', 'Remaining Time')}
          </p>
          <p className="time">{secondsToMinute(timeRemaining)}</p>
          <p className="subtext">
            ({t('brainGames:welcome.time.maxPerDay', 'Max 25 min per day')})
          </p>
        </Timer>
      )}
      {/* use open time because want control re render  */}
      {openTime !== '' && error === null && open === true && (
        <GameScene
          folder={
            isSkipIsland
              ? SUBJECT_ISLANDS[lowercaseSubjectName].skipFolder
              : SUBJECT_ISLANDS[lowercaseSubjectName].folder
          }
          iframe={SUBJECT_ISLANDS[lowercaseSubjectName].iframe}
          skip={isSkipIsland}
        />
      )}
      {!isLoading && error === null && open === false && <CloseArena />}
    </>
  );
};

export default React.memo(Welcome);
