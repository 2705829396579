import AdvancedIcon from 'assets/img/dailychallengev2/advanced.svg';
import MasterIcon from 'assets/img/dailychallengev2/master.svg';
import ElementaryIcon from 'assets/img/dailychallengev2/elementary.svg';
import IntermediateIcon from 'assets/img/dailychallengev2/intermediate.svg';
import { ReactComponent as MissionCircleIcon } from 'assets/img/dailychallengev2/mission-circle.svg';
import { ReactComponent as MissionPolygonIcon } from 'assets/img/dailychallengev2/mission-polygon.svg';
import { ReactComponent as MissionClubIcon } from 'assets/img/dailychallengev2/mission-club.svg';

export const mappingLevelCode = {
  1: 'Elementary',
  3: 'Intermediate',
  2: 'Advanced',
  4: 'Master',
};

export const mockLevelsData = [
  {
    levelCode: 1,
    name: 'Elementary',
    icon: ElementaryIcon,
    locked: false,
    underline: {
      left: '-20',
      width: '115',
    },
  },
  {
    levelCode: 3,
    name: 'Intermediate',
    icon: IntermediateIcon,
    locked: false,
    underline: {
      left: '147',
      width: '130',
    },
  },
  {
    levelCode: 2,
    name: 'Advanced',
    icon: AdvancedIcon,
    locked: false,
    underline: {
      left: '333',
      width: '104',
    },
  },
  {
    levelCode: 4,
    name: 'Master',
    icon: MasterIcon,
    locked: false,
    underline: {
      left: '520',
      width: '80',
    },
  },
];

export const mappingChallengeLevelCodeInToolkit = {
  1: {
    unlockLevel: 1,
  },
  3: {
    unlockLevel: 3,
  },
  2: {
    unlockLevel: 5,
  },
  4: {
    unlockLevel: 10,
  },
};

export const statusInfoData = [
  {
    id: 1,
    title: 'Incomplete',
    color: '#FFFFFF',
  },
  {
    id: 2,
    title: 'Completed',
    color: '#2DC35B',
  },
  {
    id: 3,
    title: 'Skipped',
    color: '#BCBCBC',
  },
];

export const DCMissionsData = [
  {
    missionNo: 1,
    status: 'incompleted',
    position: {
      top: -4,
      left: 10,
    },
    responsivePosition: {
      top: -6,
      left: 15,
    },
    iconType: 'circle',
    reward: 1,
  },
  {
    missionNo: 2,
    status: 'incompleted',
    position: {
      top: -4,
      left: 26,
    },
    responsivePosition: {
      top: -6,
      left: 40,
    },
    iconType: 'circle',
    reward: 1,
  },
  {
    missionNo: 3,
    status: 'incompleted',
    position: {
      top: -4,
      left: 43,
    },
    responsivePosition: {
      top: -6,
      left: 65,
    },
    iconType: 'circle',
    reward: 1,
  },
  {
    missionNo: 4,
    status: 'incompleted',
    position: {
      top: -4,
      left: 60,
    },
    responsivePosition: {
      top: 12,
      left: 86,
    },
    iconType: 'circle',
    reward: 1,
  },
  {
    missionNo: 5,
    status: 'incompleted',
    position: {
      top: -4,
      left: 76,
    },
    responsivePosition: {
      top: 39,
      left: 65,
    },
    iconType: 'circle',
    reward: 1,
  },
  {
    missionNo: 6,
    status: 'incompleted',
    position: {
      top: 38,
      left: 66,
    },
    responsivePosition: {
      top: 39,
      left: 40,
    },
    iconType: 'polygon',
    reward: 2,
  },
  {
    missionNo: 7,
    status: 'incompleted',
    position: {
      top: 38,
      left: 51,
    },
    responsivePosition: {
      top: 39,
      left: 15,
    },
    iconType: 'polygon',
    reward: 2,
  },
  {
    missionNo: 8,
    status: 'incompleted',
    position: {
      top: 38,
      left: 37,
    },
    responsivePosition: {
      top: 62,
      left: -5,
    },
    iconType: 'polygon',
    reward: 2,
  },
  {
    missionNo: 9,
    status: 'incompleted',
    position: {
      top: 81,
      left: 53,
    },
    responsivePosition: {
      top: 85,
      left: 25,
    },
    iconType: 'club',
    reward: 3,
  },
  {
    missionNo: 10,
    status: 'incompleted',
    position: {
      top: 81,
      left: 70,
    },
    responsivePosition: {
      top: 85,
      left: 50,
    },
    iconType: 'club',
    reward: 3,
  },
];

export const mappingMissionBgType = {
  circle: MissionCircleIcon,
  club: MissionClubIcon,
  polygon: MissionPolygonIcon,
};

export const mappingQuestionStatus = {
  1: 'completed',
  3: 'incompleted',
  4: 'skipped',
};
