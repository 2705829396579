import React from 'react';
import { useTranslation } from 'react-i18next';

import { subjectIDs } from 'constants/products';

import DisableCPIcon from 'assets/img/multiplayer/disable-cp.svg';
import { cpIconsShadow } from 'constants/dashboardv2';
import {
  Container,
  Number,
  QnStatus,
  QnNumber,
  StatusInfo,
  BorderVertical,
} from './QnNav.styles';

const QnNav = ({
  number,
  status,
  active,
  collapse,
  clickHandler,
  totalQns,
  desc,
  CP,
  subjectID,
}) => {
  const { t } = useTranslation(['assignmentQnView']);
  const statusText = () => {
    if (status === 'correct') {
      return (
        <span>
          <img
            src={cpIconsShadow[subjectIDs[subjectID].toLowerCase()].icon}
            alt="CPIcon"
          />{' '}
          +{CP}
        </span>
      );
    }
    if (status === 'incomplete') {
      return (
        <span>
          <img src={DisableCPIcon} alt="CPIcon" /> {CP}
        </span>
      );
    }
    return 'Skipped';
  };
  return (
    <Container
      status={status}
      collapse={collapse}
      active={active}
      onClick={clickHandler}
    >
      <div className="number">
        {number > 1 && <BorderVertical top active={active} />}
        <Number status={status} active={active}>
          {status === 'correct' ? '' : number}
        </Number>
        {totalQns !== number && <BorderVertical top={false} active={active} />}
      </div>

      <QnStatus collapse={collapse}>
        <QnNumber active={active}>
          {t('dailyChallengeQnView:question', 'Question')} {number}
        </QnNumber>
        <span className="related-text">{desc}</span>
        <StatusInfo
          status={status}
          subject={subjectIDs[subjectID].toLowerCase()}
        >
          {statusText()}
        </StatusInfo>
      </QnStatus>
    </Container>
  );
};

export default QnNav;
