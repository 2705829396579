import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useResponsiveImage from 'helpers/Dashboard/useResponsiveImage';
import accessFeature from 'helpers/accessFeature';
import { kooTrainingIcons } from 'constants/dashboardv2';

import {
  StyledContainer,
  StyledContent,
  StyledPremiumOnly,
} from './KooQuizCard.styles';
import { PremiumOnlyIcon } from '../../../components';

const QuizImage = ({ subject }) => {
  const imgSrc = useResponsiveImage(
    kooTrainingIcons[subject].kooQuiz,
    kooTrainingIcons[subject].kooQuizTablet,
    kooTrainingIcons[subject].kooQuiz
  );
  return <img src={imgSrc} alt="kooQuiz" />;
};

const KooQuizCard = () => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const { currentProduct, subject } = useSelector((state) => state.plan);
  const findKooQuizFeature = currentProduct?.features.find(
    (item) => item.feature === 'KooQuiz'
  );
  const disabled = !findKooQuizFeature?.parameters.IsEnabled;

  const clickHandler = () => {
    if (!disabled) {
      const { url, error } = accessFeature({
        type: 'kooquiz',
        subject,
        featuresConfig: currentProduct?.features,
      });
      if (!error) {
        history.push(url);
      }
    }
  };
  return (
    <StyledContainer
      onClick={clickHandler}
      disabled={disabled}
      data-cy="kooquiz-card"
      subject={subject}
    >
      <div>
        <QuizImage subject={subject} />
        {disabled && (
          <StyledPremiumOnly>
            <PremiumOnlyIcon />
          </StyledPremiumOnly>
        )}
        <StyledContent disabled={disabled}>
          <h3>{t('kooQuiz.name', 'KooQuiz')}</h3>
          <p>{t('kooQuiz.description', 'Revisions made easy')}</p>
        </StyledContent>
      </div>
    </StyledContainer>
  );
};
export default KooQuizCard;
