import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  getAssignmentDetails,
  openAssignment,
} from 'store/assignment/assignmentSlice';

import { Spinner } from 'components/Shared';
import { renderTimeType } from 'helpers/timesParser';
import {
  Container,
  CenterMiddle,
  InfoGroup,
  InfoDesc,
  InfoLabel,
  InfoGroupBtn,
  ErrorMsg,
  CloseButton,
} from './FactualFluencyReady.styles';

const FactualFluencyReady = ({ match, onMute }) => {
  const assignmentID = parseInt(match.params.id, 10);

  const dispatch = useDispatch();
  const { startAssignmentInfo, isLoading, error } = useSelector(
    (state) => state.assignment
  );

  useEffect(() => {
    dispatch(getAssignmentDetails(assignmentID));
  }, [assignmentID, dispatch]);

  const history = useHistory();
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);

  const onClick = useCallback(() => {
    dispatch(openAssignment(startAssignmentInfo.Id)).then(() => {
      onMute();
      history.push(`/factual-fluency/start/${assignmentID}`);
    });
  }, [assignmentID, dispatch, history, onMute, startAssignmentInfo]);

  return (
    <Container>
      <CloseButton type="button" className="btn btn-close-rt" onClick={onClose}>
        <i className="icon-close2" />
      </CloseButton>

      {!isLoading && error && (
        <ErrorMsg>
          <h4>Error</h4>
          <p>{error}</p>
        </ErrorMsg>
      )}
      {isLoading && <Spinner top="50px" />}
      {!isLoading && startAssignmentInfo !== null && error === null && (
        <CenterMiddle>
          <div className="container">
            <h1 className="title text-center">
              {startAssignmentInfo && startAssignmentInfo.Title}
            </h1>
            <InfoGroup>
              <InfoLabel>Deadline</InfoLabel>
              <InfoDesc>
                {moment(
                  startAssignmentInfo && startAssignmentInfo.EndDate
                ).format('DD-MMM-YYYY, h:mm a')}
              </InfoDesc>
            </InfoGroup>
            <InfoGroup>
              <InfoLabel>Assigned By</InfoLabel>
              <InfoDesc>
                {startAssignmentInfo && startAssignmentInfo.assignedByEnum === 1
                  ? 'KooBits'
                  : startAssignmentInfo.AssignerName}
              </InfoDesc>
            </InfoGroup>
            <InfoGroup>
              <InfoLabel>Time</InfoLabel>
              <InfoDesc>
                {startAssignmentInfo &&
                  renderTimeType(startAssignmentInfo.PresentationType)}
              </InfoDesc>
            </InfoGroup>
            <InfoGroup>
              <InfoLabel>No. of Qns</InfoLabel>
              <InfoDesc>
                {startAssignmentInfo && startAssignmentInfo.QuestionNo} Qns
              </InfoDesc>
            </InfoGroup>
            <InfoGroupBtn>
              <button
                type="button"
                className="btn btn- btn-block btn-xlg"
                onClick={onClick}
              >
                Ready, Go!
              </button>
            </InfoGroupBtn>
          </div>
        </CenterMiddle>
      )}
    </Container>
  );
};

export default React.memo(FactualFluencyReady);
