import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setLastVisitedTopic } from 'store/kooClass/kooClassSlice';

import TopicListItem from '../TopicListItem';

import { Container } from './TopicList.styles';

const TopicList = ({ data, courseId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['topicSelection']);
  return (
    <Container className="topic-list-container">
      <p className="title">{t('topicSelection:allTopics', 'All Topics')}</p>
      <div className="topic-list">
        {data
          .filter((topic) => topic.totalNumberOfLessons > 0)
          .map((item) => (
            <TopicListItem
              key={item.id}
              topic={item.name}
              onClick={() => {
                dispatch(setLastVisitedTopic(item));
                history.push(
                  `/kooClass/lessons/${item.id}?courseId=${courseId}`
                );
              }}
            />
          ))}
      </div>
    </Container>
  );
};

export default TopicList;
