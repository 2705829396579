import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import { useHistory } from 'react-router';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';
import { getCurrentDailyChallenge } from 'store/dailyChallenge/dailyChallengeSlice';
import BarStart from './bar-start.png';
import BarMiddle from './bar-middle.png';
import BarEnd from './bar-end.png';
import HeroBadge from './hero-badge1.svg';
import {
  Container,
  Progress,
  ProgressBarContainer,
  Base,
  Bar,
  Badge,
} from './HeroOfTheDayProgress.styles';

// Helper
const calculateNumOfMiddleBars = (percentage, barWidth = 400) => {
  const totalBarLength = barWidth;
  const startEndBarLength = 32;
  const middleBarLength = 5;
  const requiredBarLength = totalBarLength * percentage;
  if (requiredBarLength <= startEndBarLength) {
    return 0;
  }
  // TODO: Calculate number of middle bars
  return Math.floor((requiredBarLength - startEndBarLength) / middleBarLength);
};

const ProgressBar = ({ percentage }) => {
  const barWidth = useResponsiveValue({
    desktop: 400,
    tablet: 320,
    mobile: 260,
    mobileM: 220,
  });
  const numOfMiddleBars = calculateNumOfMiddleBars(percentage, barWidth);
  const middleBarsArray = Array(numOfMiddleBars).fill(1);
  return (
    <ProgressBarContainer>
      <Bar>
        {percentage > 0 && (
          <>
            <img src={BarStart} width="17.5px" alt="" />
            {numOfMiddleBars > 0 &&
              middleBarsArray.map((bar, index) => (
                <img src={BarMiddle} key={index} width="5px" alt="" />
              ))}
            <img src={BarEnd} width="14.5px" alt="" />
          </>
        )}
      </Bar>
      <Base />
    </ProgressBarContainer>
  );
};

const HeroOfTheDayProgress = ({ BadgeUrl, CPsEarnedToday }) => {
  const { currDailyChallengeErr } = useSelector(
    (state) => state.dailyChallenge
  );
  const dispatch = useDispatch();
  const history = useHistory();

  let point = 0;
  if (CPsEarnedToday !== undefined)
    point = CPsEarnedToday > 15 ? 15 : CPsEarnedToday;
  return (
    <Container>
      <Progress>
        {isNil(currDailyChallengeErr) && (
          <>
            <span className="cps-earned">
              {isNil(CPsEarnedToday) ? 0 : CPsEarnedToday}
            </span>
            <span className="cps-required">/15 CPs</span>
          </>
        )}
        {!isNil(currDailyChallengeErr) && (
          <span
            className="reload"
            onClick={() => {
              dispatch(getCurrentDailyChallenge());
            }}
          >
            Reload
          </span>
        )}
        <ProgressBar percentage={point / 15} />
      </Progress>
      <Badge
        onClick={() => {
          history.push('/challenges/daily-challenge/hero-badge');
        }}
      >
        <img
          src={point >= 15 ? BadgeUrl : HeroBadge}
          alt="Miko"
          data-cy="badge-img"
        />
      </Badge>
    </Container>
  );
};

export default HeroOfTheDayProgress;
