/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import VideoIcon from 'assets/img/icon-play-video.svg';

import { StageCard, DifficultyBar } from '..';

import {
  Container,
  SkillDetails,
  StageDetails,
  Divider,
} from './SkillCardMissionPlus.styles';

const SkillCardMissionPlus = ({
  data,
  curriculumId,
  order,
  unlockHandler,
  setSelectedVideo,
  setShowVideoModal,
  subjectId,
}) => {
  const { t } = useTranslation(['selfPractice']);

  return (
    <Container order={order} data-cy="skill-card">
      <SkillDetails
        titles={[
          t('selfPractice:skillsList.skillDesc', 'Skill Description'),
          t('selfPractice:skillsList.skillDiff', 'Skill Difficulty'),
        ]}
        order={order}
      >
        <p className="no">{order}</p>
        <div className="desc">
          <p className="title">{data.SkillName}</p>
          <div className="video-container">
            {!isNil(data.VideoCode) && (
              <div
                className="video"
                onClick={() => {
                  setSelectedVideo(data.VideoCode);
                  setShowVideoModal(true);
                }}
              >
                <img src={VideoIcon} alt="see-video" />
                <p>{t('selfPractice:skillsList.learnSkill', 'Learn Skill')}</p>
              </div>
            )}
            <div className="hide-desktop">
              <DifficultyBar value={data.Difficulity} />
            </div>
          </div>
        </div>
        <div className="hide-mobile">
          <DifficultyBar value={data.Difficulity} />
        </div>
      </SkillDetails>
      <Divider />
      <StageDetails>
        {data?.Stages?.map((stage, index2) => (
          <StageCard
            key={stage.StageTypeId}
            details={stage}
            curriculumId={curriculumId}
            topicSkillId={data.TopicSkillId}
            unlockHandler={unlockHandler}
            titles={[
              t('selfPractice:skillsList.stage1', 'Stage 1'),
              t('selfPractice:skillsList.stage2', 'Stage 2'),
              t('selfPractice:skillsList.stage3', 'Stage 3'),
            ]}
            order={order}
            stageIndex={index2}
            subjectId={subjectId}
          />
        ))}
      </StageDetails>
    </Container>
  );
};

export default SkillCardMissionPlus;
