import styled, { keyframes } from 'styled-components';

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContainer = styled.div`
  border: solid 4px #f6c12d;
  background: #03c3d3;
  border-radius: 18px;
  width: 284px;
  position: relative;
  bottom: 2.2rem;
  text-align: center;
  padding: 2.5rem 1.5rem 1rem;
  overflow: hidden;
  .cp-text {
    font-size: 1rem;
    font-weight: 700;
  }
  .content {
    position: relative;
    z-index: 1;
  }
`;
const fadeInOut = keyframes`
  0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    opacity: 0.2;
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    opacity: 0.5;
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;
export const StyledModalBg = styled.img`
  position: absolute;
  width: 1000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${fadeInOut} 12s linear infinite;
`;
