import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { fetchWrapper } from 'services/login';
import { fetchMockTime } from 'services/time';

export const getMockTime = createAsyncThunk('user/mockTime', async () => {
  try {
    const res = await fetchWrapper(fetchMockTime);
    return handleResponseV2(res);
  } catch (error) {
    throw new Error(error?.message ?? 'Get mock time failed');
  }
});
const initialState = {
  timerID: null,
  timerStartTime: null,
  timerEndTime: null,
  timeElapsed: 0,
  timeLeft: 60000,
  lastSavedTime: null,
  mockTime: {
    isLoading: false,
    error: null,
    data: null,
  },
};
const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimerStartTime: (state, action) => {
      state.timerStartTime = action.payload;
    },
    setTimerEndTime: (state, action) => {
      state.timerEndTime = action.payload;
    },
    setLastSavedTime: (state, action) => {
      state.lastSavedTime = action.payload;
    },
    setTimeElapsed: (state, action) => {
      state.timeElapsed = action.payload;
    },
    setTimerID: (state, action) => {
      state.timerID = action.payload;
    },
    setTimeLeft: (state, action) => {
      state.timeLeft = action.payload;
    },
    resetTimer: (state) => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMockTime.pending, (state) => {
        state.mockTime.isLoading = true;
        state.mockTime.isError = null;
      })
      .addCase(getMockTime.fulfilled, (state, action) => {
        state.mockTime.isLoading = false;
        state.mockTime.data = action.payload;
      })
      .addCase(getMockTime.rejected, (state, action) => {
        state.mockTime.isLoading = false;
        state.mockTime.isError = action.payload;
      });
  },
});

const { actions, reducer } = timerSlice;
export const {
  setTimerStartTime,
  setTimerEndTime,
  setLastSavedTime,
  setTimeElapsed,
  setTimerID,
  setTimeLeft,
  resetTimer,
} = actions;
export default reducer;
