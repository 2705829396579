import styled from 'styled-components';
import { KOOQUIZ_STYLES } from 'constants/kooQuiz';
import { subjectIDs, SUBJECTS } from 'constants/products';

export const StyledBackground = styled.div`
  background-color: ${({ subject }) => KOOQUIZ_STYLES[subject]?.bgColor};
  background-image: url(${({ subject }) =>
    `${KOOQUIZ_STYLES[subject]?.bgImg}`});
  background-repeat: repeat;
  padding: 1rem 2.3rem 0;
  box-shadow: 1px -74px 0px -1px
    ${({ subject }) => KOOQUIZ_STYLES[subject]?.boxShadow} inset;
  min-height: 445px;
`;
export const StyledContent = styled.div`
  margin: 0 auto;
  max-width: 880px;
  display: flex;
  padding-top: 44px;
  justify-content: center;
`;
export const StyledPencil = styled.div`
  padding-top: 132px;
  padding-bottom: 55px;
  margin-right: 1rem;
  position: relative;
  img {
    z-index: 2;
    position: relative;
    bottom: 12px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    height: 72px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      ${({ subject }) => KOOQUIZ_STYLES[subject]?.assetColor},
      rgba(255, 255, 255, 0) 100%
    );
  }
`;
export const StyledPaper = styled.div`
  position: relative;
  min-width: 296px;
  img {
    width: 318px;
    position: absolute;
    bottom: 59px;
    left: ${(p) => (p.onlineOffline ? '23px' : '0px')};
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    height: 71px;
    background: linear-gradient(
      180deg,
      ${({ subject }) => KOOQUIZ_STYLES[subject]?.assetColor},
      rgba(255, 255, 255, 0) 100%
    );
    bottom: 0;
    left: ${({ paperType }) => paperType.paperAlignment.start.shadowLeft};
    width: ${({ paperType }) => paperType.paperAlignment.start.shadowWidth};
    ${({ onlineOffline }) =>
      onlineOffline && {
        width: '235px',
      }};
  }
  .hots-questions-text {
    color: #fff;
    position: absolute;
    bottom: 15px;
    z-index: 2;
    left: 50px;
    font-size: 1rem;
  }
`;
export const StyledInstructions = styled.div`
  padding-bottom: 74px;
  h4 {
    font-size: 1.9rem;
    color: white;
    line-height: 1;
    margin-left: 10px;
    margin-bottom: 1.4rem;
  }
  .circle {
    p {
      background: #ff7c7c;
      width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 20px;
      margin-right: 1.1rem;
      line-height: 1;
    }
  }
  .content {
    display: flex;
    padding: 20px 30px 16px;
    background-color: rgb(29 29 29 / 35%);
    min-width: 475px;
    border-radius: 20px;
    color: white;
    margin-bottom: 9px;
    position: relative;
    h5 {
      font-size: 1rem;
      margin-bottom: 0;
      span {
        display: inline-block;
        font-size: 1.4rem;
        margin-left: 0.5rem;
      }
      p {
        margin-bottom: 0;
        display: inline;
        color: #ffeb00;
      }
      &.second-title {
        margin-bottom: 25px;
      }
    }
    .gift-box {
      position: absolute;
      width: 140px;
      right: -85px;
      bottom: -58px;
    }
  }
  .tip {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-bottom: 0;
    color: #ffdf2c;
    img {
      margin-left: -5px;
      margin-bottom: 4px;
    }
  }
`;
export const StyledButtonBox = styled.div`
  margin-top: 40px;
  text-align: center;
`;

export const HOTSContainer = styled.div`
  padding-bottom: 74px;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  position: relative;

  .gift-box {
    position: absolute;
    width: 140px;
    left: 0;
    bottom: 15px;
    z-index: 3;
  }
`;

export const HOTSCard = styled.div`
  width: ${(p) => p.width};
  height: 280px;
  font-size: 16px;
  position: relative;
  bottom: -1px;

  &::after {
    content: '';
    position: absolute;
    bottom: -72px;
    left: 0;
    right: 0;
    display: block;
    height: 73px;
    z-index: 1;
    background: linear-gradient(
      180deg,
      ${({ subject }) => KOOQUIZ_STYLES[subject]?.assetColor},
      rgba(255, 255, 255, 0) 100%
    );
  }

  .hots-header {
    background-color: rgba(29, 29, 29, 0.7);
    text-align: center;
    color: #ffffff;
    height: 42px;
    line-height: 42px;
    font-weight: bold;
    font-size: 18px;
  }

  .hots-content {
    background-color: rgba(29, 29, 29, 0.35);
    height: calc(100% - 43px);
    padding: ${({ subject }) =>
      subjectIDs[subject] === SUBJECTS.MATH ? '24px 24px' : '24px 19px'};
    color: #ffffff;
    h6 {
      line-height: 1.4;
      p {
        margin-bottom: 0;
        display: inline;
        color: #ffeb00;
        font-weight: 700;
      }
    }
  }

  .hots-score-content {
    background-color: rgba(29, 29, 29, 0.35);
    height: calc(100% - 43px);
    padding: 36px 66px;
    color: #ffffff;
  }

  .hots-heightlight {
    color: #ffeb00;
  }

  .hots-print {
    background-color: #1aa5ff;
    color: #fff;
    width: 225px;
    height: 60px;
    text-align: center;
    border-radius: 50px;
    position: relative;
    font-weight: bold;
    font-size: 24px;
    border: 0;

    & > img {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .hots-items-center {
    display: flex;
    align-items: center;
  }

  .hots-font-bold {
    font-weight: 700;
  }

  .hots- @media (max-width: 1024px) {
    max-width: 290px;

    .hots-score-content {
      padding: 27px 43px;
    }
  }
`;
