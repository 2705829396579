import styled from 'styled-components';
import Bg from './qn-bg-doodles.svg';

export const Container = styled.div`
  background: url(${Bg});
  background-color: #344abc;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    position: absolute;
    width: 70%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #111150;
    z-index: 2;
    width: 60%;
    h4 {
      font-size: 40px;
      font-weight: bold;
    }
    .desc {
      font-size: 24px;
      font-weight: bold;
      margin: 3% 0;
      max-height: 230px;
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      @media (max-width: 1024px) {
        max-height: 7rem;
        overflow-y: scroll;
      }
    }
  }
`;
