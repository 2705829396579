import React from 'react';

import Header from 'components/Header';

import { Card1, Card2 } from './imgs';

import { Container } from './SplitCard.styled';

const SplitCard = () => {
  return (
    <div>
      <Header />
      <Container>
        <div className="box">
          <img src={Card1} alt="1" />
        </div>
        <div className="box">
          <img src={Card2} alt="2" />
        </div>
      </Container>
    </div>
  );
};

export default SplitCard;
