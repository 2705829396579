import React from 'react';
import { history } from 'Routes';
import { useTranslation } from 'react-i18next';
import BookClubLogo from '../../imgs/bookclub_logo.png';
import BookClubBack from '../../imgs/bookclub_back.png';
import {
  Container,
  ButtonWrap,
  Wrap,
  InfoWrap,
  TextInfo,
  TextCount,
} from './BookClubHeader.style';

const BookClubHeader = ({ totalKoko }) => {
  const { t } = useTranslation(['bookClub']);
  return (
    <Container>
      <ButtonWrap
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={BookClubBack} alt="Back" />
      </ButtonWrap>
      <Wrap>
        <img src={BookClubLogo} alt="KooBits Book Club" />
      </Wrap>
      <InfoWrap>
        <TextInfo>
          {t('bookClub:words.total', 'Total KoKo Credits')}
          <br />
          {t('bookClub:words.spent', 'Spent on StoryMath')}
        </TextInfo>
        <TextCount>{totalKoko}</TextCount>
      </InfoWrap>
    </Container>
  );
};

export default React.memo(BookClubHeader);
