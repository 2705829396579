import React from 'react';
import { useTranslation } from 'react-i18next';

import SuperHeroIcon from 'assets/img/superhero/superhero-score-icon.png';
import Life from 'assets/img/superhero/supervision-life.png';
import LifeLost from 'assets/img/superhero/supervision-life-lost.png';

import {
  Container,
  LifeList,
  ScoreWrapper,
  ScoreText,
  TimerWrapper,
  TimerLabel,
  ShowAnswerButton,
  TimerText,
  LifeItem,
  CompleteChallengeText,
} from './StatusBar.styles';

const StatusBar = ({
  lives,
  score,
  remainingTime,
  showAnswer,
  completed,
  toggleShowAnswer,
}) => {
  const { t } = useTranslation(['superVision']);
  const RenderLife = ({ values }) => {
    const Lives = [];
    for (let index = 0; index < 5; index += 1) {
      Lives.push(
        <LifeItem
          key={`found-${index}`}
          className={index >= values ? 'lost-life' : ''}
        >
          <img src={Life} className="life" alt="Life" />
          <img src={LifeLost} className="lost" alt="Lost Life" />
        </LifeItem>
      );
    }
    return Lives;
  };
  return (
    <Container>
      {!completed && (
        <>
          <LifeList>
            <RenderLife values={lives} />
          </LifeList>
          <ScoreWrapper>
            <img src={SuperHeroIcon} alt="Score" />
            <ScoreText>{score}</ScoreText>
          </ScoreWrapper>
          <TimerWrapper>
            <TimerLabel>
              {t('superVision:game.timerLabel', `Time Left`)}
            </TimerLabel>
            <TimerText>{remainingTime}</TimerText>
          </TimerWrapper>
        </>
      )}
      {completed && (
        <>
          <CompleteChallengeText>
            {t(
              'superVision:game.completeChallengeText',
              `You've completed the challenge for today.`
            )}
          </CompleteChallengeText>
          <ShowAnswerButton onClick={toggleShowAnswer}>
            {!showAnswer
              ? t('superVision:game.showAnswerBtnText', `Show Answer`)
              : t('superVision:game.hideAnswerBtnText', `Hide Answer`)}
          </ShowAnswerButton>
        </>
      )}
    </Container>
  );
};

export default React.memo(StatusBar);
