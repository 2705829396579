import styled from 'styled-components';
import { PopupModal } from 'components/Shared';

export const StyledButtonContainer = styled.div`
  position: absolute;
  top: 7rem;
  left: 1.875rem;
  z-index: 1;
`;
export const StyledTrialPopupModal = styled(PopupModal)`
  .modal-body {
    width: 100%;
  }
`;
