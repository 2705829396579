import styled from 'styled-components';

export const SideNavButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  right: 3%;
  display: none;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: block;
  }
`;

export const StyledNavBar = styled.div`
  height: 100%;
  background: #f2f2f2;
  width: 291px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 80%;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  margin-right: 25px;
`;

export const StyledHeader = styled.div`
  width: 100%;
  height: 60px;
  background-color: #1bbfe6;
  display: flex;
  padding-left: 15px;
`;

export const HideOnMobile = styled.div`
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
