import { SUBJECTS, subjectNames } from './products';
// plz use import { ReactComponent as IconCP } from 'assets/img/icon-cp.svg';

export const cpIconColor = {
  [SUBJECTS.MATH]: '#57CFFC',
  [SUBJECTS.SCIENCE]: '#00EF00',
  [SUBJECTS.ENGLISH]: '#FEBA01',
  [subjectNames[SUBJECTS.MATH]]: '#57CFFC',
  [subjectNames[SUBJECTS.SCIENCE]]: '#00EF00',
  [subjectNames[SUBJECTS.ENGLISH]]: '#FEBA01',
};
