import styled, { css } from 'styled-components';
import HeaderBg from 'assets/img/dailychallengev2/header.png';
import { subjectIDs, SUBJECTS } from 'constants/products';
import { BackButton } from 'components/Shared';

export const StyledHeader = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2rem;
  border-bottom: 2px solid #404447;
  z-index: 2;

  button.responsive {
    position: absolute;
    z-index: 99;
    top: 1.5rem;
    left: 1.5rem;
  }

  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    & {
      height: 18%;
      padding: 0px 2.28vw 0px 2.85vw;
      align-items: center;
      justify-content: space-between;
      button.responsive {
        position: initial;
        margin-top: -3%;
      }
    }
  }
  ${({ subjectId }) => {
    if (subjectIDs[subjectId] === SUBJECTS.ENGLISH) {
      return css`
        padding: 15px 2.28vw 15px 2.85vw;
        @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          height: auto;
        }
        @media (min-width: ${({ theme }) => `${theme.rwd.laptop}px`}) {
          padding: 0 2.28vw 0 2.85vw;
        }
      `;
    }
  }}
`;
export const StyledBackButton = styled(BackButton)`
  ${({ subjectId }) => {
    if (subjectIDs[subjectId] === SUBJECTS.ENGLISH) {
      return css`
        @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          &&.responsive {
            margin-top: -1%;
          }
        }
      `;
    }
  }}
`;
export const HeaderRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  align-items: center;

  .dc-text {
    width: 60%;
    max-width: 280px;
    min-height: 78px;
    margin-bottom: 0.5rem;
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    & {
      .dc-text {
        margin-bottom: 1rem;
      }
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 70%;
    height: 100%;
    gap: 2vw;
    .dc-text {
      position: initial;
      width: 20%;
      top: 0px;
      transform: translateY(0%);
      right: 0px;
      margin-bottom: 0px;
      max-width: 1000px;
    }
  }
  @media (min-width: ${({ theme }) => `${theme.rwd.laptop}px`}) {
    .dc-text {
      width: 25%;
    }
  }
  ${({ subjectId }) => {
    if (subjectIDs[subjectId] === SUBJECTS.ENGLISH) {
      return css`
        width: 100%;
        justify-content: flex-end;
        @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          width: 100%;
          justify-content: flex-end;
        }
        .dc-text {
          margin-bottom: 0;
          margin-left: auto;
          @media (min-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
            width: 330px;
          }
        }
      `;
    }
  }}
`;
