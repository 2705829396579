import handleResponse from 'helpers/handleAPIResponse';
import store from 'store/index';
import { isNil } from 'ramda';

// const baseUrl = process.env.REACT_APP_API_DOMAIN;
const homeworkApiBaseUrl = process.env.REACT_APP_HOMEWORK_API_DOMAIN;

export const fetchAssignmentDetails = (homeworkID) => {
  return fetch(
    `${homeworkApiBaseUrl}HomeWork/GetHomeworkDetailsById/${homeworkID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get assignment details failed'); // eslint-disable-line
    });
};

export const openAssignmentRequest = (assignmentID) => {
  return fetch(`${homeworkApiBaseUrl}HomeWork/OpenHomework/${assignmentID}`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Open assignment failed'); // eslint-disable-line
    });
};

export const saveQuestionSubmissionRequest = (rawBody) => {
  return fetch(`${homeworkApiBaseUrl}HomeWork/Save`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Save question submission failed'); // eslint-disable-line
    });
};

export const checkAnswerRequest = (rawBody) => {
  return fetch(`${homeworkApiBaseUrl}HomeWork/CheckAnswer`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Timezone: store.getState().login.timezone,
    },
    body: JSON.stringify(rawBody),
  })
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Check answer submission failed'); // eslint-disable-line
    });
};

// For Finish button
export const closeAssignmentRequest = (assignmentSubmissionID) => {
  return fetch(
    `${homeworkApiBaseUrl}HomeWork/CloseHomework/${assignmentSubmissionID}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Close assignment failed'); // eslint-disable-line
    });
};

export const submitAssignmentRequest = (params) => {
  // const { assignmentID, assignmentSubmissionID } = params;
  const { assignmentSubmissionID } = params;
  return fetch(
    `${homeworkApiBaseUrl}HomeWork/HomeworkSubmitToTeacher/${assignmentSubmissionID}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Submit assignment failed');
    });
};

export const fetchAssignmentSolutions = (assignmentSubmissionID) => {
  return fetch(
    `${homeworkApiBaseUrl}HomeWork/GetSolutionBySubmissionId/${assignmentSubmissionID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get assignment solutions failed');
    });
};

export const claimKoKoCreditsRequest = (assignmentSubmissionID) => {
  return fetch(
    `${homeworkApiBaseUrl}HomeWork/ClaimKoko/${assignmentSubmissionID}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Claim KoKo credits failed');
    });
};

export const fetchQnSubmission = ({ assignmentSubmissionID, questionId }) => {
  if (isNil(assignmentSubmissionID) || isNil(questionId))
    throw new Error('Params undefined');
  return fetch(
    `${homeworkApiBaseUrl}HomeWork/HomeWorkSubmissions/${assignmentSubmissionID}/Questions/${questionId}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Timezone: store.getState().login.timezone,
      },
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Get hw submission failed');
    });
};
