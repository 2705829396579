import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import { IconTimer } from 'assets/img/events/brain-master';

import { Card, DetailsInfo, Button } from 'components/Shared';

import {
  Container,
  LeftContent,
  SubCardContainer,
  RightContent,
} from './BrainMasterCard.styles';

const BrainMasterCard = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation(['eventList', 'common']);
  const isTablet = useMediaQuery({ maxWidth: '1024px' });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  const { userID } = useSelector((state) => state.login);

  const brainMasterEnded = moment().isAfter(data.endDate);
  const days =
    moment(data.endDate).diff(moment(), 'days') >= 0
      ? moment(data.endDate).diff(moment(), 'days')
      : 0;

  const sendBMClickTracking = () => {
    window.gtag('event', 'brain_master_click', {
      category: 'Student Portal',
      action: 'Click Brain Master Button on Event List Page',
      label: `${userID}-Click Brain Master`,
    });
  };

  const getButtonFontSize = () => {
    // there are mediaqueries at Button component with calc font-size function. Button Component is a shared component so it is not changed. '19.6px' will yield 18px;
    // button mediaqueries with calc function only for tablet and mobile screen and desktop no calc. so 18px will still yield as 18px
    let size = '18px';
    if (isTablet) {
      size = '1rem';
    }
    if (isMobile) {
      size = '15.5px';
    }
    return size;
  };

  const leftSide = () => {
    return (
      <LeftContent>
        <SubCardContainer>
          {brainMasterEnded && (
            <div className="end-label">
              {t('eventList:eventCard.eventEnd', 'This event has ended.')}
            </div>
          )}
        </SubCardContainer>
        <div className="info-section">
          <div className="challenge-name">
            <p>{data?.name}</p>
            <p className="challenge-desc">{data?.description}</p>
          </div>
          <div className="detail-one">
            <DetailsInfo
              direction="column"
              category="Event Period"
              info={`${moment(data.startDate).format('D MMM YYYY')} - ${moment(
                data.endDate
              ).format('D MMM YYYY')}`}
              fontSizeCategory={isMobile ? '12px' : '14px'}
              fontSizeInfo={isMobile ? '14px' : '16px'}
            />
          </div>
          {!isMobile && (
            <div className="detail-two">
              <DetailsInfo
                direction="column"
                category=""
                info={
                  <div className="duration">
                    <img src={IconTimer} alt="duration" />
                    <p>
                      <Trans i18nKey="eventList:eventCard.daysLeft" days={days}>
                        <span>{{ days }}</span> days left
                      </Trans>
                    </p>
                  </div>
                }
                fontSizeCategory="14px"
                fontSizeInfo="16px"
              />
            </div>
          )}
        </div>
      </LeftContent>
    );
  };

  const rightSide = () => {
    return (
      <RightContent isMobile={isMobile}>
        {isMobile && (
          <div className="detail-two">
            <DetailsInfo
              direction="column"
              category=""
              info={
                <div className="duration">
                  <img src={IconTimer} alt="duration" />
                  <p>
                    <Trans i18nKey="eventList:eventCard.daysLeft" days={days}>
                      <span>{{ days }}</span> days left
                    </Trans>
                  </p>
                </div>
              }
              fontSizeCategory="14px"
              fontSizeInfo="16px"
            />
          </div>
        )}
        <Button
          style={{ display: 'flex', justifyContent: 'center' }}
          variant="primary"
          fontSize={getButtonFontSize()}
          width={isMobile ? '50%' : isTablet ? '100%' : '80%'}
          onClick={() => {
            sendBMClickTracking();
            history.push({
              pathname: `/events/brain-master`,
              state: { id: data?.id },
            });
          }}
        >
          {t('common:seeDetails', 'See Details')}
        </Button>
      </RightContent>
    );
  };
  return (
    <Container>
      <Card
        rightWidth={isMobile ? '100%' : '20%'}
        leftWidth={isMobile ? '100%' : '80%'}
        infoSection={leftSide()}
        buttonSection={rightSide()}
      />
    </Container>
  );
};

export default BrainMasterCard;
