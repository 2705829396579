import {
  MeritAward,
  MeritAwardActive,
  DistinctAward,
  MeritRibbon,
  MeritRibbonActive,
  DistinctRibbon,
  DistinctRibbonActive,
  DistinctAwardActive,
  MeritAward2,
  DistinctAward2,
} from './img';

export const AWARD = {
  'Merit Award': {
    active: MeritAwardActive,
    default: MeritAward,
    top: '-0.5rem',
    activeRibbon: MeritRibbonActive,
    defaultRibbon: MeritRibbon,
    ribbonWidth: '135px',
    ribbonLeft: '-0.2rem',
    color: '#ffffff',
  },
  'Distinction Award': {
    active: DistinctAwardActive,
    default: DistinctAward,
    top: '-1.5rem',
    activeRibbon: DistinctRibbonActive,
    defaultRibbon: DistinctRibbon,
    ribbonWidth: '143px',
    ribbonLeft: '0',
    color: '#000000',
  },
};

export const MILESTONE_POPUP = {
  merit: {
    translateKey: 'merit',
    desc: 'You won the Brain Master Merit Award! Keep going and strive for the Distinction Award.',
    img: MeritAward2,
  },
  distinction: {
    translateKey: 'distinction',
    desc: 'You won the Brain Master Distinction Award!',
    img: DistinctAward2,
  },
};
