import React from 'react';
import RobotAvatar1 from 'assets/img/multiplayer/matching/Mini-Robot-PP-03.png';
import RobotAvatar2 from 'assets/img/multiplayer/matching/Mini-Robot-PP-07.png';
import RobotAvatar3 from 'assets/img/multiplayer/matching/Mini-Robot-PP-10.png';
import { useTranslation } from 'react-i18next';
import SearchIcon from './search-icon.png';
import { StyledContainer } from './SearchingScene.styles';

const SearchingScene = ({ randomOpponents = [], show }) => {
  const { t } = useTranslation(['newChallenge']);
  const opponentsToDisplay =
    randomOpponents.length < 3
      ? new Array(3).map((_, index) => {
          if (randomOpponents[index]) return randomOpponents[index];
          return {};
        })
      : randomOpponents;
  return (
    <StyledContainer toFade={show}>
      <img
        className="avatar"
        src={opponentsToDisplay[0]?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar1;
        }}
        alt=""
      />
      <img
        className="avatar"
        src={opponentsToDisplay[1]?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar2;
        }}
        alt=""
      />
      <img
        className="avatar"
        src={opponentsToDisplay[2]?.UserAvatarImage}
        onError={(e) => {
          e.target.src = RobotAvatar3;
        }}
        alt=""
      />
      <div className="bottom">
        <img src={SearchIcon} alt="" width="36.15px" />
        <span className="text">
          {t('newChallenge:random.matching', 'Matching...')}
        </span>
      </div>
    </StyledContainer>
  );
};
export default SearchingScene;
