import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 18% 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
`;

export const SubsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  p {
    margin: 0;
  }
  .plan {
    font-weight: 600;
    color: #0d0d0d;
    font-size: 1rem;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
  .period {
    color: #949090;
    font-size: 0.8125rem;
    position: relative;
    flex: 1;
    text-align: right;
  }
  .desc {
    color: #ff0000;
    font-size: 0.625rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
  .bundle {
    background-color: #ffeea5;
    padding: 4px;
    border-radius: 4px;
    margin-left: -6px;
    margin-right: 4px;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 10px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
  }
`;
