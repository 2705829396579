import ElementaryPlaceholder from 'assets/img/dailychallenge/elementary-placeholder.svg';
import IntermediatePlaceholder from 'assets/img/dailychallenge/intermediate-placeholder.svg';
import AdvancedPlaceholder from 'assets/img/dailychallenge/advanced-placeholder.svg';
import MasterPlaceholder from 'assets/img/dailychallenge/master-placeholder.svg';
import PieceOfCake from 'assets/img/dailychallenge/challenge-difficulty/piece-of-cake.svg';
import WalkInThePark from 'assets/img/dailychallenge/challenge-difficulty/walk-in-the-park.svg';
import SmallFight from 'assets/img/dailychallenge/challenge-difficulty/small-fight.svg';
import RoughRumble from 'assets/img/dailychallenge/challenge-difficulty/rough-rumble.svg';
import HardTime from 'assets/img/dailychallenge/challenge-difficulty/hard-time.svg';
import BrainDrain from 'assets/img/dailychallenge/challenge-difficulty/brain-drain.svg';

// challenge cards
import DCClose from 'assets/img/dailychallenge/dc-close.svg';
import DCOpen from 'assets/img/dailychallenge/dc-open.jpg';
import SVOpen from 'assets/img/dailychallenge/super-vision.png';
import SVClose from 'assets/img/dailychallenge/super-vision-close.png';
import SSOpen from 'assets/img/dailychallenge/super-speed.png';
import SSClose from 'assets/img/dailychallenge/super-speed-close.png';
import ScienceDCOpen from 'assets/img/dailychallenge/science-dc-open.png';
import ScienceDCClosed from 'assets/img/dailychallenge/science-dc-closed.png';
import MathCPIcon from 'assets/img/icon-challenge-point.svg';
import ScienceCPIcon from 'assets/img/icon-science-challenge-point.svg';

// science
import ChallengeOne from 'assets/img/dailychallenge/science/challenge-1-pending.svg';
import ChallengeTwo from 'assets/img/dailychallenge/science/challenge-2-pending.svg';
import ChallengeThree from 'assets/img/dailychallenge/science/challenge-3-pending.svg';
import ChallengeFour from 'assets/img/dailychallenge/science/challenge-4-pending.svg';
import ChallengeFive from 'assets/img/dailychallenge/science/challenge-5-pending.svg';
import ChallengeOneComplete from 'assets/img/dailychallenge/science/challenge-1-done.svg';
import ChallengeTwoComplete from 'assets/img/dailychallenge/science/challenge-2-done.svg';
import ChallengeThreeComplete from 'assets/img/dailychallenge/science/challenge-3-done.svg';
import ChallengeFourComplete from 'assets/img/dailychallenge/science/challenge-4-done.svg';
import ChallengeFiveComplete from 'assets/img/dailychallenge/science/challenge-5-done.svg';
import ChallengeOneSkipped from 'assets/img/dailychallenge/science/challenge-1-skip.svg';
import ChallengeTwoSkipped from 'assets/img/dailychallenge/science/challenge-2-skip.svg';
import ChallengeThreeSkipped from 'assets/img/dailychallenge/science/challenge-3-skip.svg';
import ChallengeFourSkipped from 'assets/img/dailychallenge/science/challenge-4-skip.svg';
import ChallengeFiveSkipped from 'assets/img/dailychallenge/science/challenge-5-skip.svg';
import AdvancedChestImg from 'assets/img/dailychallenge/mystery-koko/advanced-chest.png';
import MasterChestImg from 'assets/img/dailychallenge/mystery-koko/master-chest.png';

export const QnDifficultyLevels = (level) => {
  if (level <= 1) {
    return {
      name: 'Piece of Cake',
      icon: PieceOfCake,
    };
  }
  if (level <= 2) {
    return {
      name: 'Walk in the Park',
      icon: WalkInThePark,
    };
  }
  if (level <= 4) {
    return {
      name: 'Small Fight',
      icon: SmallFight,
    };
  }
  if (level <= 6) {
    return {
      name: 'Rough Rumble',
      icon: RoughRumble,
    };
  }
  if (level <= 7) {
    return {
      name: 'Hard Time',
      icon: HardTime,
    };
  }
  return {
    name: 'Brain Drain',
    icon: BrainDrain,
  };
};

// Difficulty for Qns for different Daily Challenge levels
export const elementaryQnDifficulties = [
  QnDifficultyLevels(1),
  QnDifficultyLevels(1),
  QnDifficultyLevels(1),
  QnDifficultyLevels(2),
  QnDifficultyLevels(2),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
];

export const intermediateQnDifficulties = [
  QnDifficultyLevels(1),
  QnDifficultyLevels(1),
  QnDifficultyLevels(2),
  QnDifficultyLevels(2),
  QnDifficultyLevels(2),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(6),
];

export const advancedQnDifficulties = [
  QnDifficultyLevels(1),
  QnDifficultyLevels(1),
  QnDifficultyLevels(2),
  QnDifficultyLevels(2),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(6),
  QnDifficultyLevels(6),
];

export const masterQnDifficulties = [
  QnDifficultyLevels(1),
  QnDifficultyLevels(2),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(3),
  QnDifficultyLevels(6),
  QnDifficultyLevels(6),
  QnDifficultyLevels(6),
  QnDifficultyLevels(7),
  QnDifficultyLevels(8),
];

export const difficultyLevels = {
  1: {
    name: 'Elementary',
    description: 'Simple questions for beginners.',
    translation: 'simpleQn', // for translation
    numOfStars: 1,
    kokoCredits: 5,
    CPsRequired: 0,
    cardPlaceholder: ElementaryPlaceholder,
    challengeLevel: 1,
    qnDifficultyLevels: elementaryQnDifficulties,
  },
  3: {
    name: 'Intermediate',
    translation: 'fairEnough', // for translation
    description: 'Fair enough. This is how the game is meant to be played.',
    numOfStars: 2,
    kokoCredits: 10,
    CPsRequired: 15,
    cardPlaceholder: IntermediatePlaceholder,
    challengeLevel: 3,
    qnDifficultyLevels: intermediateQnDifficulties,
  },
  2: {
    name: 'Advanced',
    description:
      'Survival is not guaranteed, but you will definitely have our respect!',
    translation: 'survival', // for translation
    numOfStars: 3,
    kokoCredits: 15,
    CPsRequired: 50,
    cardPlaceholder: AdvancedPlaceholder,
    challengeLevel: 2,
    qnDifficultyLevels: advancedQnDifficulties,
  },
  4: {
    name: 'Master',
    description: 'You are legendary! We worship you!',
    translation: 'legendary', // for translation
    numOfStars: 4,
    kokoCredits: 20,
    CPsRequired: 100,
    cardPlaceholder: MasterPlaceholder,
    challengeLevel: 4,
    qnDifficultyLevels: masterQnDifficulties,
  },
};

export const questionStatuses = {
  completed: {
    name: 'Completed',
    color: '#3DC94E',
  },
  incomplete: {
    name: 'Incomplete',
    color: '#1DC9FD',
  },
  skipped: {
    name: 'Skipped',
    color: '#D3DCDE',
  },
};

export const superHeroChallengeStatusIDs = {
  1: 'locked',
  2: 'completed',
  3: 'incomplete',
};

export const dailyChallengeList = {
  challenge: [
    {
      id: 1,
      name: 'Daily Challenge - Math',
      nameTranslation: 'DC',
      imgOpen: DCOpen,
      imgClose: DCClose,
      desc: '10 personalized questions per day',
      schedule: '6am to 10pm, Everyday',
      scheduleTranslation: 'schedule',
      type: 'daily-challenge',
      link: '/challenges/daily-challenge',
      subjectID: 1,
      totalQns: 10,
      cpAmt: 17,
    },
    {
      id: 4,
      name: 'Daily Challenge - Science',
      nameTranslation: 'ScienceDC',
      imgOpen: ScienceDCOpen,
      imgClose: ScienceDCClosed,
      desc: '5 personalized questions per day',
      schedule: '6am to 10pm, Everyday',
      scheduleTranslation: 'schedule',
      type: 'daily-challenge',
      link: '/challenges/science',
      subjectID: 2,
      totalQns: 5,
      cpAmt: 9,
    },
    {
      id: 2,
      name: 'Super Vision Challenge',
      nameTranslation: 'SV',
      imgOpen: SVOpen,
      imgClose: SVClose,
      desc: '',
      schedule: '6am to 10pm, Monday to Saturday',
      scheduleTranslation: 'schedule',
      type: 'super-vision-challenge',
      link: '/superhero/supervision',
    },
    {
      id: 3,
      name: 'Super Speed Challenge',
      nameTranslation: 'SS',
      imgOpen: SSOpen,
      imgClose: SSClose,
      desc: '',
      scheduleTranslation: 'schedule',
      schedule: '6am to 10pm, Monday to Saturday',
      type: 'super-speed-challenge',
      link: '/superhero/superspeed',
    },
  ],
};

export const soundSpriteMap = {
  silencegap: [1800, 1],
  terrific: [0, 1724],
  thatsright: [3000, 2011],
  sensational: [7000, 2038],
  phenomenal: [11000, 1724],
  greatjob: [14000, 1829],
  amazing: [17000, 1724],
  bravo: [20000, 1724],
  spectacular: [23000, 2038],
  outstanding: [27000, 2273],
  superb: [31000, 2429],
};

export const wordToSoundMapping = {
  'That’s Right!': 'thatsright',
  'Great Job!': 'greatjob',
  'Amazing!': 'amazing',
  'Bravo!': 'bravo',
  'Terrific!': 'terrific',
  'Outstanding!': 'outstanding',
  'Spectacular!': 'spectacular',
  'Phenomenal!': 'phenomenal',
  'Superb!': 'superb',
  'Sensational!': 'sensational',
};

export const subjectStyles = {
  math: {
    cpIcon: MathCPIcon,
  },
  science: {
    cpIcon: ScienceCPIcon,
  },
};

// science & english
export const renderQnDifficultyLevels = (level) => {
  if (level <= 1) {
    return {
      name: 'Piece of Cake',
      pendingImageUrl: ChallengeOne,
      completeImageUrl: ChallengeOneComplete,
      skippedImageUrl: ChallengeOneSkipped,
      colorBg: `rgb(255, 229, 65)`,
      colorRadial: `radial-gradient(
        circle,
        rgba(255, 229, 65, 1) 0%,
        rgba(255, 188, 2, 1) 35%,
        rgba(255, 229, 65, 1) 100%
      )`,
    };
  }
  if (level <= 2) {
    return {
      name: 'Small Fight',
      pendingImageUrl: ChallengeTwo,
      completeImageUrl: ChallengeTwoComplete,
      skippedImageUrl: ChallengeTwoSkipped,
      colorBg: `rgb(252,196,66)`,
      colorRadial: `radial-gradient(circle, rgba(252,196,66,1) 0%, rgba(255,137,0,1) 100%)`,
    };
  }
  if (level <= 3) {
    return {
      name: 'Rough Rumble',
      pendingImageUrl: ChallengeThree,
      completeImageUrl: ChallengeThreeComplete,
      skippedImageUrl: ChallengeThreeSkipped,
      colorBg: `rgb(255,146,0)`,
      colorRadial: `radial-gradient(circle, rgba(255,146,0,1) 0%, rgba(240,103,13,1) 100%)`,
    };
  }
  if (level <= 4) {
    return {
      name: 'Hard Time',
      pendingImageUrl: ChallengeFour,
      completeImageUrl: ChallengeFourComplete,
      skippedImageUrl: ChallengeFourSkipped,
      colorBg: `rgb(228,108,216)`,
      colorRadial: `radial-gradient(circle, rgba(228,108,216,1) 0%, rgba(212,90,173,1) 100%)`,
    };
  }
  return {
    name: 'Brain Drain',
    pendingImageUrl: ChallengeFive,
    completeImageUrl: ChallengeFiveComplete,
    skippedImageUrl: ChallengeFiveSkipped,
    colorBg: `rgb(185,94,190)`,
    colorRadial: `radial-gradient(circle, rgba(185,94,190,1) 0%, rgba(143,72,173,1) 100%)`,
  };
};

export const ScienceQnStatus = {
  1: 'complete',
  3: 'incomplete',
  4: 'skipped',
};

// List of subject IDs & challenge level ids (math) that awards student with mystery koko credits
// upon completion of all questions correctly
export const MysteryKokoSubjectIDs = [1, 2];
export const ChallengeLevelMysteryChests = {
  2: AdvancedChestImg,
  4: MasterChestImg,
};
