import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCurrentGiftsSubject,
  fetchRevisionIdV2,
  postAssignGiftsV2,
  fetchCurrentGiftsV2,
  postClaimGiftV2,
  fetchTotalRevisionKoko,
  postOpenKooQuiz,
  saveKooQuizSubmission,
  checkKooQuizAnswer,
  closeKooQuiz,
  submitKooQuiz,
  fetchkooQuizSolutions,
  fetchMultipleCourseRevisionPapers,
} from 'services/kooQuiz';
import { compareValues } from 'helpers/compareValue';
import { fetchWrapper } from 'services/login';
import xmlParser from 'helpers/xmlParser';
import store from 'store/index';

// ASYNC THUNKS
export const getPapers = createAsyncThunk(
  'kooQuiz/getPapers',
  async ({ subjectId, rawBody }) => {
    try {
      const res = await fetchWrapper(fetchMultipleCourseRevisionPapers, {
        subjectId,
        rawBody,
      });
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get papers failed');
    }
  }
);

export const getCurrentGiftBySubject = createAsyncThunk(
  'kooQuiz/getCurrentGiftBySubject',
  async (subjectId) => {
    try {
      const res = await fetchWrapper(fetchCurrentGiftsSubject, subjectId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current gift by subject failed');
    }
  }
);

export const getRevisionId = createAsyncThunk(
  'kooQuiz/getRevisionId',
  async ({ subjectId, paperId, courseIds }) => {
    try {
      const res = await fetchWrapper(fetchRevisionIdV2, {
        subjectId,
        paperId,
        courseIds,
      });
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get revision failed');
    }
  }
);

export const assignGifts = createAsyncThunk(
  'kooQuiz/assignGifts',
  async ({ paperId, subjectId, rewardId }) => {
    try {
      const res = await fetchWrapper(postAssignGiftsV2, {
        paperId,
        subjectId,
        rewardId,
      });
      return {
        result: res,
        params: { paperId, subjectId, rewardId },
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Assign gift failed');
    }
  }
);

export const getCurrentGift = createAsyncThunk(
  'kooQuiz/getCurrentGift',
  async ({ subjectId, courseIds }) => {
    try {
      const res = await fetchWrapper(fetchCurrentGiftsV2, {
        subjectId,
        courseIds,
      });
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get current gift failed');
    }
  }
);

export const getTotalRevisionKoko = createAsyncThunk(
  'kooQuiz/getTotalRevisionKoko',
  async () => {
    try {
      const res = await fetchWrapper(fetchTotalRevisionKoko);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get Total Revision Koko failed');
    }
  }
);

export const openKooQuiz = createAsyncThunk(
  'kooQuiz/openKooQuiz',
  async (paperId) => {
    try {
      const res = await fetchWrapper(postOpenKooQuiz, paperId);
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Open KooQuiz failed');
    }
  }
);

// Saves & Finish KooQuiz
export const finishKooQuiz = createAsyncThunk(
  'kooQuiz/finishKooQuiz',
  async (params) => {
    const { rawBody, activeQuestion, assignmentSubmissionID } = params;
    try {
      const saveAnswerResponse = await fetchWrapper(
        saveKooQuizSubmission,
        rawBody
      );
      const finishResponse = await fetchWrapper(
        closeKooQuiz,
        assignmentSubmissionID
      );
      return {
        saveResult: saveAnswerResponse,
        activeQuestion,
        finishResult: finishResponse,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Finish KooQuiz failed');
    }
  }
);

// Submit KooQuiz and Claim Koko
export const submitClaimKooQuiz = createAsyncThunk(
  'kooQuiz/submitClaimKooQuiz',
  async (kooQuizSubmissionID) => {
    try {
      const submitResponse = await fetchWrapper(
        submitKooQuiz,
        kooQuizSubmissionID
      );
      const { currentSubjectProduct } = store.getState().plan;
      const subjectCode = currentSubjectProduct?.subjectId;
      const { currentCourseId } = store.getState().kooQuiz;
      const claimResponse =
        store.getState().kooQuiz.paperById.RewardEndDate !== null
          ? await fetchWrapper(postClaimGiftV2, {
              subjectId: subjectCode,
              submissionId: kooQuizSubmissionID,
              rawBody: currentCourseId,
            })
          : null;
      return {
        submitResult: submitResponse,
        claimResult: claimResponse,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Submit & Claim kooQuiz failed');
    }
  }
);

export const saveKooQuizAnswer = createAsyncThunk(
  'kooQuiz/saveKooQuizAnswer',
  async (params) => {
    const { rawBody, activeQuestion } = params;
    try {
      const res = await fetchWrapper(saveKooQuizSubmission, rawBody);
      return {
        result: res,
        activeQuestion,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Save KooQuiz submission failed');
    }
  }
);

export const kooQuizCheckAnswer = createAsyncThunk(
  'kooQuiz/checkKooQuizAnswer',
  async (params) => {
    const { rawBody, activeQuestion } = params;
    try {
      const res = await fetchWrapper(checkKooQuizAnswer, rawBody);
      return {
        result: res,
        activeQuestion,
      };
    } catch (err) {
      throw new Error(err?.message ?? 'Check KooQuiz answer submission failed');
    }
  }
);

export const getKooQuizSolutions = createAsyncThunk(
  'kooQuiz/getKooQuizSolutions',
  async (kooQuizSubmissionId) => {
    try {
      const res = await fetchWrapper(
        fetchkooQuizSolutions,
        kooQuizSubmissionId
      );
      return res;
    } catch (err) {
      throw new Error(err?.message ?? 'Get kooquiz solutions failed');
    }
  }
);

const initialState = {
  isLoading: false,
  error: null,
  papers: null,
  errorPapers: null,
  giftsInfo: [],
  revisionPaper: {},
  paperById: null,
  errorById: null,
  giftAssign: null,
  errorGiftAssign: null,
  currentGift: null,
  revisionGift: null,
  totalRevisionKoko: {},
  kooQuizSelectedPaper: 2,
  giftObj: {},
  // kooquiz qn view state
  kooQuizType: 'KooQuizMaths',
  kooQuizSubmissionID: null,
  startkooQuizDuration: null,
  kooQuizDuration: null,
  kooQuizQuestions: [],
  kooQuizQuestionAnswers: [],
  kooQuizSubmissions: [],
  kooQuizLocalSavedAnswers: [],
  kooQuizSavedAnswers: [],
  kooQuizSavedWorkings: [],
  kooQuizActiveQuestion: 1,
  isKooQuizChecking: [],
  isKooQuizFinishing: false,
  isKooQuizLoading: false,
  isKooQuizSubmitting: false,
  isKooQuizSaving: false,
  kooQuizSaveError: null,
  kooQuizKoKoCreditsDetails: null,
  kooQuizSubmissionResult: null,
  kooQuizSubmissionError: null,
  kooQuizCheckAnswerError: null,
  isSolutionsLoading: false,
  kooQuizSolutions: null,
  openKooQuizError: null,
  errorFinish: null,
  currentCourseId: [],
};

const kooQuizSlice = createSlice({
  name: 'kooQuiz',
  initialState,
  reducers: {
    setKooQuizActiveQuestion: (state, action) => {
      state.kooQuizActiveQuestion = action.payload;
    },
    saveKooQuizAnswerLocally: (state, action) => {
      const newAnswers = [...state.kooQuizLocalSavedAnswers];
      newAnswers[action.payload.index] = action.payload.answers;
      state.kooQuizLocalSavedAnswers = newAnswers;
    },
    saveKooQuizWorkingsLocally: (state, action) => {
      const newWorkings = [...state.kooQuizSavedWorkings];
      newWorkings[action.payload.index] = action.payload.workings;
      state.kooQuizSavedWorkings = newWorkings;
    },
    clearKooQuizSaveError: (state) => {
      state.kooQuizSaveError = null;
    },
    clearKooQuizSavedAnswer: (state, action) => {
      const newSavedAnswers = [...state.kooQuizSavedAnswers];
      newSavedAnswers[action.payload] = null;
      state.kooQuizSavedAnswers = newSavedAnswers;
    },
    reset: (state) => {
      return {
        ...state,
        kooQuizActiveQuestion: 1,
      };
    },
    resetAssignGift: (state) => {
      return {
        ...state,
        giftAssign: null,
        errorGiftAssign: null,
      };
    },
    setKooQuizType: (state, action) => {
      state.kooQuizType = action.payload;
    },
    setKooQuizSelectedPaper: (state, action) => {
      state.kooQuizSelectedPaper = action.payload;
    },
    setGiftObj: (state, action) => {
      state.giftObj = action.payload;
    },
    setCurrentCourseId: (state, action) => {
      state.currentCourseId = action.payload;
    },
    resetSelectedPaper: (state) => {
      state.paperById = null;
    },
  },
  extraReducers: {
    [getPapers.pending]: (state) => {
      state.isLoading = true;
      state.papers = null;
      state.errorPapers = null;
    },
    [getPapers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.papers = action.payload;
      state.errorPapers = null;
    },
    [getPapers.rejected]: (state, action) => {
      state.isLoading = false;
      state.papers = null;
      state.errorPapers = action.error.message;
    },
    [getCurrentGiftBySubject.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getCurrentGiftBySubject.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.giftsInfo = action.payload;
      state.error = null;
    },
    [getCurrentGiftBySubject.rejected]: (state, action) => {
      state.isLoading = false;
      state.giftsInfo = [];
      state.error = action.error.message;
    },
    [getRevisionId.pending]: (state) => {
      state.isLoading = true;
      state.paperById = null;
      state.errorById = null;
    },
    [getRevisionId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paperById = action.payload;
      state.errorById = null;
    },
    [getRevisionId.rejected]: (state, action) => {
      state.isLoading = false;
      state.paperById = null;
      state.errorById = action.error.message;
    },
    [assignGifts.pending]: (state) => {
      state.isLoading = true;
      state.giftAssign = null;
      state.errorGiftAssign = null;
    },
    [assignGifts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.giftAssign = action.payload.params;
    },
    [assignGifts.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorGiftAssign = action.error.message;
      state.giftAssign = {
        paperId: action.meta.arg.paperId,
        subjectId: action.meta.arg.subjectId,
      };
    },
    [getCurrentGift.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getCurrentGift.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.currentGift = action.payload;
    },
    [getCurrentGift.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getTotalRevisionKoko.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getTotalRevisionKoko.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.totalRevisionKoko = action.payload;
    },
    [getTotalRevisionKoko.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [openKooQuiz.pending]: (state) => {
      state.isKooQuizOpening = true;
      state.error = null;
      state.openKooQuizError = null;
    },
    [openKooQuiz.fulfilled]: (state, action) => {
      state.isKooQuizOpening = false;
      state.kooQuizSubmissionID = action.payload.HwSubId;
      state.kooQuizDuration = action.payload.SubmissionDuration;
      state.startkooQuizDuration = action.payload.SubmissionDuration;
      state.kooQuizLocalSavedAnswers = Array(
        action.payload.UserQuestionSubmissions.length
      ).fill(null);
      state.kooQuizSavedWorkings = Array(
        action.payload.UserQuestionSubmissions.length
      ).fill(null);
      const sortedUserQuestionSubmissions = action.payload.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.isKooQuizChecking = Array(
        sortedUserQuestionSubmissions.length
      ).fill(false);
      state.kooQuizSubmissions = sortedUserQuestionSubmissions;
      state.kooQuizSavedAnswers = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.SubmissionModel
      );
      // Populate question answers
      state.kooQuizQuestionAnswers = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => {
          if (userQnSubmission.SubmissionModel !== null) {
            const filteredXml = userQnSubmission.SubmissionModel.Payload.replace(
              /\\/g,
              ''
            );
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
          if (userQnSubmission.Question.QuestionType === 3) {
            const filteredXml = userQnSubmission.Question.Payload.replace(
              /\\/g,
              ''
            );
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
          return null;
        }
      );
      state.kooQuizQuestions = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => userQnSubmission.Question
      );
    },
    [openKooQuiz.rejected]: (state, action) => {
      state.isKooQuizOpening = false;
      state.error = action.error.message;
      state.openKooQuizError = action.error.message;
    },
    [finishKooQuiz.pending]: (state) => {
      state.isKooQuizFinishing = true;
      state.errorFinish = null;
      state.kooQuizKoKoCreditsDetails = null;
    },
    [finishKooQuiz.fulfilled]: (state, action) => {
      state.isKooQuizFinishing = false;
      const newSavedAnswers = [...state.kooQuizSavedAnswers];
      newSavedAnswers[action.payload.activeQuestion - 1] =
        action.payload.saveResult;
      state.kooQuizSavedAnswers = newSavedAnswers;
      state.kooQuizDuration = action.payload.finishResult.SubmissionDuration;
      state.kooQuizSubmissions = action.payload.finishResult.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.kooQuizKoKoCreditsDetails = {
        hwAllocatedCredits: action.payload.finishResult.HWAllocatedKokoCredits,
        currentMonthClaimedCredits:
          action.payload.finishResult.CurrentMonthClaimedKokoCredits,
        canBeClaimed: action.payload.finishResult.CanBeClaimed,
        totalQns: action.payload.finishResult.TotalQuestions,
        totalCorrect: action.payload.finishResult.CorrectAnswers,
      };
    },
    [finishKooQuiz.rejected]: (state, action) => {
      state.isKooQuizFinishing = false;
      state.errorFinish = action.error.message;
    },
    [submitClaimKooQuiz.pending]: (state) => {
      state.isKooQuizSubmitting = true;
      state.kooQuizSubmissionError = null;
      state.kooQuizSubmissionResult = null;
      state.revisionGift = null;
    },
    [submitClaimKooQuiz.fulfilled]: (state, action) => {
      state.isKooQuizSubmitting = false;
      state.kooQuizSubmissionResult = action.payload.submitResult;
      state.revisionGift = action.payload.claimResult;
      state.kooQuizSubmissionResult = null;
    },
    [submitClaimKooQuiz.rejected]: (state, action) => {
      state.isKooQuizSubmitting = false;
      state.kooQuizSubmissionResult = null;
      state.revisionGift = null;
      state.kooQuizSubmissionError = action.error.message;
    },
    [saveKooQuizAnswer.pending]: (state) => {
      state.isKooQuizSaving = true;
      state.kooQuizSaveError = null;
    },
    [saveKooQuizAnswer.fulfilled]: (state, action) => {
      state.isKooQuizSaving = false;
      const newSavedAnswers = [...state.kooQuizSavedAnswers];
      newSavedAnswers[action.payload.activeQuestion - 1] =
        action.payload.result;
      state.kooQuizSavedAnswers = newSavedAnswers;
      state.kooQuizDuration = action.payload.result.SubmissionDuration;
      state.kooQuizSubmissions = action.payload.result.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.kooQuizSaveError = null;
    },
    [saveKooQuizAnswer.rejected]: (state, action) => {
      state.isKooQuizSaving = false;
      state.kooQuizSaveError = action.error.message;
    },
    [kooQuizCheckAnswer.pending]: (state, action) => {
      const newisChecking = [...state.isKooQuizChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = true;
      state.isKooQuizChecking = newisChecking;
      state.kooQuizCheckAnswerError = null;
    },
    [kooQuizCheckAnswer.fulfilled]: (state, action) => {
      const newisChecking = [...state.isKooQuizChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = false;
      state.isKooQuizChecking = newisChecking;
      state.kooQuizCheckAnswerError = null;
      // TODO: Need to save checked answers in redux state?
      const newSavedAnswers = [...state.kooQuizSavedAnswers];
      newSavedAnswers[action.meta.arg.activeQuestion - 1] =
        action.payload.result;
      state.kooQuizSavedAnswers = newSavedAnswers;
      state.kooQuizDuration = action.payload.result.SubmissionDuration;
      const sortedUserQuestionSubmissions = action.payload.result.UserQuestionSubmissions.sort(
        compareValues('DisplayOrder', 'asc')
      );
      state.kooQuizSubmissions = sortedUserQuestionSubmissions;
      state.kooQuizQuestionAnswers = sortedUserQuestionSubmissions.map(
        (userQnSubmission) => {
          if (userQnSubmission.SubmissionModel !== null) {
            const filteredXml = userQnSubmission.SubmissionModel.Payload.replace(
              /\\/g,
              ''
            );
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
          if (userQnSubmission.Question.QuestionType === 3) {
            const filteredXml = userQnSubmission.Question.Payload.replace(
              /\\/g,
              ''
            );
            const parsed = xmlParser(filteredXml);
            let answerKeys = null;
            if (parsed.questionAnswers.length > 0) {
              answerKeys = parsed.questionAnswers.map((answer) => answer.$);
            }
            return answerKeys;
          }
          return null;
        }
      );
    },
    [kooQuizCheckAnswer.rejected]: (state, action) => {
      const newisChecking = [...state.isKooQuizChecking];
      newisChecking[action.meta.arg.activeQuestion - 1] = false;
      state.isKooQuizChecking = newisChecking;
      state.kooQuizCheckAnswerError = action.error.message;
    },
    [getKooQuizSolutions.pending]: (state) => {
      state.isSolutionsLoading = true;
      state.error = null;
    },
    [getKooQuizSolutions.fulfilled]: (state, action) => {
      state.isSolutionsLoading = false;
      state.kooQuizSolutions = action.payload;
      state.kooQuizSubmissionID = action.payload.HwSubId;
      // Populate questionAnswers
      state.kooQuizQuestionAnswers = action.payload.UserQuestionSubmissions.map(
        (qn) => {
          const filteredXml = qn.SubmissionModel.Payload.replace(/\\/g, '');
          const parsed = xmlParser(filteredXml);
          let answerKeys = null;
          if (parsed.questionAnswers.length > 0) {
            answerKeys = parsed.questionAnswers.map((answer) => answer.$);
          }
          return answerKeys;
        }
      );
    },
    [getKooQuizSolutions.rejected]: (state, action) => {
      state.isSolutionsLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = kooQuizSlice;
export const {
  setKooQuizActiveQuestion,
  saveKooQuizAnswerLocally,
  saveKooQuizWorkingsLocally,
  clearKooQuizSaveError,
  clearKooQuizSavedAnswer,
  reset,
  resetAssignGift,
  setKooQuizType,
  setKooQuizSelectedPaper,
  setGiftObj,
  setCurrentCourseId,
  resetSelectedPaper,
} = actions;
export default reducer;
