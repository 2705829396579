import React from 'react';
import Star from '../../imgs/difficulty-star.svg';

const DifficultyStars = ({ numOfStars }) => {
  const stars = Array(numOfStars).fill(Star);
  return (
    <>
      {stars.map((star, index) => (
        <img
          src={star}
          alt=""
          key={index}
          style={{ marginRight: '0.2rem' }}
          data-cy="star-icon"
        />
      ))}
    </>
  );
};

export default DifficultyStars;
