import { KOO_QUIZ_PAPER_TYPE, KOO_QUIZ_PAPER } from 'constants/kooQuiz';
import GiftBox from './gift-box.gif';
import GiftBag from './gift-bag.gif';

export const BOOK_GIFT = {
  [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.HOTS]]: GiftBox,
  [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.OED]]: GiftBox,
  [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.TopPapers]]: GiftBox,
  [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.EssentialSkills]]: GiftBag,
  [KOO_QUIZ_PAPER_TYPE[KOO_QUIZ_PAPER.VocabularyCloze]]: GiftBag,
};
