import styled from 'styled-components';

export const ContainerV2 = styled.div`
  padding: 0 15% 0 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1024px) {
    padding: 0 2.5rem;
  }
  @media (max-width: 610px) {
    padding: 0 1.5rem;
  }
`;

export const ContactPage = styled.div`
  font-size: 0.75rem;
  color: #898989;
  text-align: right;
  margin-right: 5.5rem;
  a {
    color: #000000;
    text-decoration: underline;
    font-weight: 600;
  }
  @media (max-width: 1423px) {
    margin-right: 0;
  }
`;

export const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 0.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.375rem;
`;

export const ChildList = styled.div`
  display: flex;
  border-bottom: 1px solid #cddadd;
`;

export const ChildName = styled.div`
  font-size: 0.875rem;
  color: ${({ selected }) => (selected ? '#000000' : '#8BA4A7')};
  border-bottom: ${({ selected }) => (selected ? '2px solid #93B6BF' : 'none')};
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  cursor: pointer;
`;
