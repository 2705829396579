import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { clone, isNil } from 'ramda';
import { useHistory } from 'react-router-dom';
import { setLastSavedTime, resetTimer } from 'store/timer/timerSlice';
import { finishFactualFluency } from 'store/assignment/assignmentSlice';

import { submissionTypeIDs } from 'constants/index';
import { getTimeType, secondsToTime } from 'helpers/timesParser';

import {
  FactualFluencyHeader,
  FactualFluencyPad,
  FactualFluencyQuestion,
} from './components';
import {
  ButtonSkip,
  Container,
  FactualFluencyBody,
  FactualFluencySideBar,
  Backdrop,
  Countdown,
} from './FactualFluency.style';

const answerSorted = (questions, userID) => {
  const sorted = questions.map((question) => {
    return {
      duration: 0,
      userID,
      questionID: question.Id,
      type: submissionTypeIDs.Factualfluency,
      questionType: question.QuestionType,
      versionId: 1,
      answerkeyValuePairs: [
        {
          answerType: 2,
          key: 'key1',
          value: '',
          userid: userID,
          ismarkedright: true,
        },
      ],
      isFinalSubmit: true,
    };
  });

  return sorted;
};

const FactualFluency = ({ match, onMute, mute }) => {
  const assignmentID = parseInt(match.params.id, 10);

  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  const { userID } = useSelector((state) => state.login);

  const {
    startAssignmentInfo,
    assignmentSubmissionID,
    questions,
    finishFactualFluencyError,
  } = useSelector((state) => state.assignment);

  useEffect(() => {
    if (isNil(startAssignmentInfo) || isNil(questions)) {
      history.push('/assignment');
    }
  }, [history, questions, startAssignmentInfo]);

  let AssignmentTypeID;
  let TimeLimit;

  if (!isNil(startAssignmentInfo)) {
    const presentationParams = JSON.parse(
      startAssignmentInfo.PresentationType.PresentationParameters
    );
    AssignmentTypeID = startAssignmentInfo.PresentationType.AssignmentTypeID;
    TimeLimit = presentationParams.TimeLimit;
  }

  const [isStart, setIsStart] = useState(true);
  const [startTime, setStartTime] = useState(0);
  const [isFinish, setIsFinish] = useState(false);
  const [finished, setFinished] = useState(false);
  const [intervalActive, setIntervalActive] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [time, setTime] = useState(secondsToTime(TimeLimit));
  const [seconds, setSeconds] = useState(TimeLimit);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [savedAnswer, setSavedAnswer] = useState(
    answerSorted(questions, userID)
  );

  const timeType = getTimeType(AssignmentTypeID);

  const saveAnswer = useCallback(() => {
    const now = Date.now();
    const lastTime = store.getState().timer.lastSavedTime ?? startTime;

    const newSavedAnswer = clone(savedAnswer);

    newSavedAnswer[currentQuestion].duration = now - lastTime;
    newSavedAnswer[currentQuestion].answerkeyValuePairs[0].value = inputValue;

    setSavedAnswer(newSavedAnswer);
  }, [currentQuestion, inputValue, savedAnswer, startTime, store]);

  const resetInterval = useCallback(() => {
    setIntervalActive(false);
    setSeconds(TimeLimit);
    setTime(secondsToTime(TimeLimit));
    setTimeout(() => {
      setIntervalActive(true);
    }, 500);
  }, [TimeLimit]);

  const onChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const onKeyChange = useCallback(
    (num) => {
      const number = `${inputValue}${num}`;
      setInputValue(number);
    },
    [inputValue]
  );

  const onClear = useCallback(() => {
    setInputValue('');
  }, []);

  const onBackspace = useCallback(() => {
    const number = inputValue.slice(0, -1);
    setInputValue(number);
  }, [inputValue]);

  const onSkip = useCallback(() => {
    const now = Date.now();
    saveAnswer();
    dispatch(setLastSavedTime(now));
    setInputValue('');

    if (timeType.value === 5) {
      resetInterval();
    }
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }

    if (currentQuestion === questions.length - 1) {
      setIsFinish(true);
    }
  }, [
    saveAnswer,
    dispatch,
    timeType,
    currentQuestion,
    questions.length,
    resetInterval,
  ]);

  const onSubmitAnswer = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        const now = Date.now();
        saveAnswer();
        dispatch(setLastSavedTime(now));
        setInputValue('');

        if (timeType.value === 5) {
          resetInterval();
        }
        if (currentQuestion < questions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        }

        if (currentQuestion === questions.length - 1) {
          setIsFinish(true);
        }
      }
    },
    [saveAnswer, dispatch, timeType, currentQuestion, questions, resetInterval]
  );

  useEffect(() => {
    let interval;
    let timeout;

    switch (timeType.value) {
      case 4:
        if (isStart) {
          interval = setInterval(() => {
            setSeconds(seconds - 1);
            setTime(secondsToTime(seconds - 1));
          }, 1000);
          if (seconds === 0) {
            setIsFinish(true);
            clearInterval(interval);
          }
        }
        break;
      case 5:
        if (isStart) {
          timeout = setTimeout(function () {
            setIntervalActive(true);
          }, 500);
        }
        interval = setInterval(() => {
          setSeconds(seconds - 1);
          setTime(secondsToTime(seconds - 1));
        }, 1000);
        if (seconds <= 0) {
          if (currentQuestion <= questions.length - 1) {
            const now = Date.now();
            saveAnswer();
            dispatch(setLastSavedTime(now));
            setInputValue('');
            resetInterval();

            if (currentQuestion < questions.length - 1) {
              setCurrentQuestion(currentQuestion + 1);
            }

            if (currentQuestion === questions.length - 1) {
              setIsFinish(true);
            }
          } else {
            setIsFinish(true);
            clearInterval(interval);
            clearTimeout(timeout);
          }
        }
        break;
      default:
        break;
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    seconds,
    timeType,
    isStart,
    currentQuestion,
    questions,
    resetInterval,
    saveAnswer,
    dispatch,
    history,
    assignmentSubmissionID,
    savedAnswer,
  ]);

  useEffect(() => {
    let interval;
    setIsStart(false);
    switch (timeType.value) {
      case 4:
        interval = setInterval(() => {
          setCountdown(countdown - 1);
        }, 1000);
        if (countdown === 0) {
          setIsStart(true);
          clearInterval(interval);
        }
        break;
      default:
        setIsStart(true);
        clearInterval(interval);
        break;
    }

    return () => clearInterval(interval);
  }, [countdown, timeType]);
  useEffect(() => {
    if (finishFactualFluencyError) {
      setFinished(false);
    }
  }, [finishFactualFluencyError]);
  useEffect(() => {
    if (isFinish && !finished) {
      setFinished(true);
      dispatch(
        finishFactualFluency({
          assignmentID,
          HomeworkSubmissionId: assignmentSubmissionID,
          rawBody: savedAnswer,
        })
      ).then(() => {
        history.push('/assignment/submitted/claimkoko/0');
      });
    }
  }, [
    assignmentID,
    assignmentSubmissionID,
    dispatch,
    history,
    isFinish,
    savedAnswer,
    finished,
  ]);

  useEffect(() => {
    if (isStart) {
      const now = Date.now();
      setStartTime(now);
    }
  }, [isStart]);

  useEffect(() => {
    return () => {
      dispatch(resetTimer());
    };
  }, [dispatch]);

  return (
    <Container>
      {!isStart && (
        <>
          <Backdrop />
          <Countdown>{countdown}</Countdown>
        </>
      )}

      <FactualFluencyHeader
        timeType={timeType}
        time={time}
        intervalActive={intervalActive}
        limit={TimeLimit}
        onMute={onMute}
        mute={mute}
      />
      <FactualFluencyBody>
        <FactualFluencyQuestion
          questionNumber={currentQuestion + 1}
          question={questions[currentQuestion]}
          value={inputValue}
          onChange={onChange}
          onSubmitAnswer={onSubmitAnswer}
        />
      </FactualFluencyBody>
      <FactualFluencySideBar>
        <FactualFluencyPad
          onKeyChange={onKeyChange}
          onClear={onClear}
          onBackspace={onBackspace}
          onEnter={onSkip}
        />
        <ButtonSkip className="btn" onClick={onSkip}>
          <i className="icon-skip" /> Skip
        </ButtonSkip>
      </FactualFluencySideBar>
    </Container>
  );
};

export default React.memo(FactualFluency);
