import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useIsMobile from 'hooks/responsive/useIsMobile';
import useIsTablet from 'hooks/responsive/useIsTablet';

import IconKooParent from 'assets/img/parent-portal/icon-koobits-parent.svg';
import { ReactComponent as IconNewFlag } from 'assets/img/superhero/parentChallenge/new-flag.svg';
import ImgQrCode from 'assets/img/superhero/parentChallenge/qr-code.svg';
import ImgAppStore from 'assets/img/superhero/parentChallenge/app-store.svg';
import ImgPlayStore from 'assets/img/superhero/parentChallenge/google-play.svg';
import {
  Button,
  Card,
  DetailsInfo,
  ModalContentXButton,
} from 'components/Shared';
import { RightContent } from '../ChallengeCard/ChallengeCard.styles';
import { StyledLeftContent, StyledModal } from './ParentChallengeCard.styles';

const ParentChallengeCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const { t } = useTranslation(['superParentApp']);

  const getButtonFontSize = () => {
    if (isTablet) return '18px';
    if (isMobile) return '16px';
    return '22px';
  };

  const getButtonPadding = () => {
    if (isTablet) return '7.5px 15px';
    if (isMobile) return '5px 10px';
    return '10px 20px';
  };
  const onHide = () => {
    setOpen(false);
  };
  const rightContent = () => {
    return (
      <RightContent>
        <div className="btn-group">
          <div className="btn">
            <Button
              variant="primary"
              fontSize={getButtonFontSize()}
              fontWeight="600"
              padding={getButtonPadding()}
              onClick={() => {
                setOpen(true);
              }}
            >
              {t('superParentApp:seeDetail', 'See Details')}
            </Button>
          </div>
        </div>
      </RightContent>
    );
  };
  return (
    <div>
      <ModalContentXButton
        showModal={open}
        closeModal={onHide}
        backdrop="static"
        containerMinWidth="768px"
      >
        <StyledModal>
          <IconNewFlag className="new" />
          {data.videoUrl && (
            <iframe
              src={`https://www.youtube.com/embed/${data.videoUrl}?autoplay=1`}
              title={data.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          <div className="info-section">
            <div className="app-info">
              <img src={IconKooParent} alt="kooParent" />
              <span>{t('superParentApp:modal.app', 'Koobits Parent')}</span>
            </div>
            <p>
              <Trans i18nKey="superParentApp:modal.desc">
                Hey there! Get our Parent App and dive into
                <span className="name">
                  {{
                    name: t(
                      `superParentApp:${data.nameTranslation}.title`,
                      data.title
                    ),
                  }}
                </span>
                , a super fun English mini-game to improve your vocabulary.
              </Trans>
            </p>
            <div className="img-section">
              <img src={ImgQrCode} alt="qr-code" className="qr-code" />
              <div className="store">
                <img src={ImgAppStore} alt="app-store" />
                <img src={ImgPlayStore} alt="play-store" />
              </div>
            </div>
          </div>
        </StyledModal>
      </ModalContentXButton>
      <Card
        rightWidth="25%"
        buttonSection={rightContent()}
        hideButton={isMobile}
        leftWidth={isMobile ? '100%' : '85%'}
        hideBorder
        infoSection={
          <StyledLeftContent status="open" openImg={data.img}>
            <div className="top-content">
              <div className="subcard" />
              <div className="info-section">
                <div className="challenge-name">
                  {t(
                    `superParentApp:${data.nameTranslation}.title`,
                    data.title
                  )}
                  <span>
                    {t(
                      `superParentApp:${data.nameTranslation}.desc`,
                      data.desc
                    )}
                  </span>
                </div>
                {!isMobile && (
                  <div className="detail-one">
                    <img src={IconKooParent} alt="kooParent" />
                    <DetailsInfo
                      isMobile
                      direction="column"
                      category="available at"
                      info={t(
                        `superParentApp:logo.title`,
                        'KooBits Parent App'
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            {isMobile && (
              <div className="bottom-content">
                <div className="detail-one">
                  <img src={IconKooParent} alt="kooParent" />
                  <DetailsInfo
                    isMobile
                    direction="column"
                    category="available at"
                    info={t(`superParentApp:logo.title`, 'KooBits Parent App')}
                  />
                </div>
                {rightContent()}
              </div>
            )}
          </StyledLeftContent>
        }
      />
    </div>
  );
};

export default ParentChallengeCard;
