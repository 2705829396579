import styled from 'styled-components';

import {
  MathConfetti,
  ScienceConfetti,
  EnglishConfetti,
} from 'assets/img/leaderboard';

export const topBarStyles = {
  math: {
    textColour: '#00B5EB',
    linearGradient: 'linear-gradient(#1db8fd, #67e8ff)',
    img: MathConfetti,
  },
  science: {
    textColour: '#00CC87',
    linearGradient: 'linear-gradient(#21DB70, #67FF7E)',
    img: ScienceConfetti,
  },
  english: {
    textColour: '#FEAA02',
    linearGradient: 'linear-gradient(#FDC60E, #FFD731)',
    img: EnglishConfetti,
  },
};

export const Topbar = styled.div`
  min-height: 120px;
  background-image: ${(props) =>
    topBarStyles[props.subject]?.linearGradient ??
    topBarStyles.math.linearGradient};
  .header {
    display: flex;
    justify-content: center;
    img {
      position: absolute;
    }
    .text {
      margin-top: 1rem;
      margin-left: 9%;
    }
  }
`;

const rulesBgColours = {
  math: '#06b5ef',
  science: '#00C75C',
  english: '#FEBA02',
};

export const RulesButton = styled.a`
  background-color: ${(props) =>
    rulesBgColours[props.subject] ?? rulesBgColours.math};
  color: #ffffff;
  font-weight: 600;
  border-radius: 19px;
  outline: none;
  border: none;
  position: absolute;
  right: 23%;
  margin-top: -1.3rem;
  padding: 0.3rem 1rem;
`;

export const Container = styled.div`
  padding: 0rem 15%;
`;
