import styled from 'styled-components';
import { BrainMasterIcon } from 'assets/img/events/brain-master';

export const Container = styled.div``;

export const LeftContent = styled.div`
  display: flex;

  .info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #0d0d0d;
    margin-left: 1rem;
    .challenge-name {
      font-size: 25px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      .challenge-desc {
        font-size: 16px;
        font-weight: 500;
        margin-top: -8px;
      }
      p {
        margin-bottom: 0;
      }
    }
    .detail-two {
      display: flex;
      .duration {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        font-size: 16px;
        img {
          margin-bottom: 4px;
        }
        p {
          margin-bottom: 0;
          span {
            font-weight: 600;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .info-section {
      .challenge-name {
        font-size: 20px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .info-section {
      .challenge-name {
        font-size: 16px;
      }
    }
  }
`;

export const SubCardContainer = styled.div`
  height: 200px;
  width: 200px;
  background: url(${BrainMasterIcon});
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;

  .end-label {
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    width: 100%;
    padding: 5px;
    text-align: center;
    @media (max-width: 1023px) {
      font-size: 0.8rem;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 160px;
    height: 160px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 140px;
    height: 140px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'center')};
  width: ${({ isMobile }) => isMobile && '100%'};
  align-items: center;
  width: 100%;
  p {
    margin-bottom: 0px;
    text-align: center;
  }

  .detail-two {
    display: flex;
    div {
      margin-bottom: 0;
    }
    .duration {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: 16px;
      img {
        margin-bottom: 4px;
      }
      p {
        margin-bottom: 0;
        span {
          font-weight: 600;
        }
      }
    }
  }
`;
