import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';

import { questionTypeIDs } from 'constants/index';
import useClickOutside from 'hooks/useClickOutside';
import { countCorrectWrongSubmissions } from 'helpers/countCorrectWrongSubmissions';
import {
  checkIfSubmissionIsCorrect,
  checkIsQuestionAnswered,
} from 'components/Assignment/AssignmentView/QnSideBar';

import CloseIcon from 'assets/img/assignment/assignment-view/icon-close.png';
import PrintIcon from 'assets/img/assignment/print-icon.svg';

import QnNav from 'components/Assignment/AssignmentView/QnSideBar/QnNav';
import QnEnd from './QnEnd';

import { PrintButton } from 'components/Assignment/AssignmentView/Header.styles';
import {
  StyledNavBar,
  ActionsContainer,
  CloseButton,
  ProficiencyContainer,
  QuestionsContainer,
} from './AssignmentSideNav.styles';

const AssignmentProficiency = ({ questions = [] }) => {
  const { t } = useTranslation(['assignmentQnView']);
  const totalQns = questions.length;
  const page = '';
  const assignmentType = 'homework';
  const proficiency = countCorrectWrongSubmissions(
    questions,
    page,
    assignmentType
  );
  return (
    <ProficiencyContainer>
      <p style={{ margin: '0', fontSize: '11px', fontWeight: '300' }}>
        {t('assignmentQnView:sidebar.proficiency', 'Proficiency')}
      </p>
      <div className="section">
        <div className="progressbar">
          <ProgressBar>
            <ProgressBar
              variant="correct"
              now={(proficiency.correct / totalQns) * 100}
              key={1}
            />
            <ProgressBar
              variant="wrong"
              now={(proficiency.wrong / totalQns) * 100}
              key={2}
            />
            <ProgressBar
              variant="skipped"
              now={(proficiency.skipped / totalQns) * 100}
              key={3}
            />
          </ProgressBar>
        </div>
        <div className="progress-number">
          <span
            className={proficiency.correct > 0 ? 'num-correct' : ''}
          >{`${proficiency.correct}`}</span>
          <span>{` / ${totalQns}`}</span>
        </div>
      </div>
    </ProficiencyContainer>
  );
};

const isQnCorrect = (currentQnSubmissionModel, assignmentType) => {
  if (assignmentType !== 'homework') return null;
  return checkIfSubmissionIsCorrect(currentQnSubmissionModel);
};
const AssignmentQuestions = ({
  setShow,
  questions = [],
  activeQn,
  setActiveQn,
  assignmentType,
  scrollToHandler,
}) => {
  const dispatch = useDispatch();
  const clickHandler = (qnId, index) => {
    if (index + 1 === activeQn) return;
    scrollToHandler(qnId);
    dispatch(setActiveQn(index + 1));
    setShow(false);
  };
  return (
    <QuestionsContainer>
      {questions.map((qn, index) => (
        <QnNav
          key={`${qn.Id}-${index}`}
          collapse={false}
          number={index + 1}
          correct={isQnCorrect(
            questions[index]?.SubmissionModel,
            assignmentType
          )}
          qnAnswered={checkIsQuestionAnswered(
            null,
            questions[index]?.SubmissionModel,
            questionTypeIDs[qn.QuestionType],
            assignmentType
          )}
          active={index + 1 === activeQn}
          clickHandler={() => clickHandler(qn.Question.Id, index)}
          styles={{
            number: {
              marginRight: '25px',
            },
          }}
        />
      ))}
      {questions.length > 0 && <QnEnd />}
    </QuestionsContainer>
  );
};

const AssignmentSideNavActions = ({ assignmentType, setShow, printUrl }) => {
  const closeSideNavHandler = () => {
    setShow(false);
  };

  return (
    <ActionsContainer assignmentType={assignmentType}>
      <CloseButton onClick={closeSideNavHandler}>
        <img src={CloseIcon} alt="Close" />
      </CloseButton>
      {printUrl.length > 0 && (
        <PrintButton
          assignmentType={assignmentType}
          data-cy="print-icon"
          rel="noopener noreferrer"
          target="_blank"
          href={printUrl}
        >
          <img src={PrintIcon} alt="Print" />
        </PrintButton>
      )}
    </ActionsContainer>
  );
};

const AssignmentSideNav = ({
  activeQuestion,
  printUrl,
  questions,
  assignmentType,
  setShow,
  scrollToHandler,
  setActiveQn,
}) => {
  const navBarRef = useRef();
  useClickOutside(navBarRef, () => {
    setShow(false);
  });

  return (
    <StyledNavBar ref={navBarRef}>
      <AssignmentSideNavActions
        assignmentType={assignmentType}
        setShow={setShow}
        printUrl={printUrl}
      />
      <AssignmentProficiency questions={questions} />
      <AssignmentQuestions
        setShow={setShow}
        activeQn={activeQuestion}
        questions={questions}
        assignmentType={assignmentType}
        scrollToHandler={scrollToHandler}
        setActiveQn={setActiveQn}
      />
    </StyledNavBar>
  );
};

export default AssignmentSideNav;
