import moment from 'moment';

export function* range(start, end, step) {
  while (start >= end) {
    yield start;
    start += step;
  }
}

export const generateYears = () => {
  const currentYear = moment().year();
  return Array.from(range(currentYear, currentYear - 6, -1));
};

function* monthRange(start, end, step) {
  while (start >= end) {
    yield {
      month: start,
      assignments: [],
    };
    start += step;
  }
}

export const generateMonths = (start) => {
  return Array.from(monthRange(start, 0, -1));
};
