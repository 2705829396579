import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getRewards } from 'store/dashboard/rewardsSlice';

import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import CongratsText from 'components/Assignment/AssignmentFinish/CongratsText';

import Plane from 'assets/img/assignment/assignment-view/paper-plane.svg';
import KoKoCredit from 'assets/img/icon-koko-credits.svg';

import {
  Container,
  ClaimKokoContainer,
  PlusKokoContainer,
} from './KokoCreditClaimed.styles';

const ClaimKoko = ({ kokoBalance, isLoading }) => {
  const { t } = useTranslation(['assignmentSubmitted']);

  return (
    <ClaimKokoContainer>
      <div className="claim">
        <p>{t('assignmentSubmitted:claimKoko.totalKoko', 'You have ')}</p>
        <img src={KoKoCredit} alt="KoKo" />
        <p className="amount">
          {!isLoading && kokoBalance && <span>{kokoBalance} </span>}
          KoKo
        </p>
        <p>{t('assignmentSubmitted:claimedKoko.Text2', 'now')}</p>
      </div>
    </ClaimKokoContainer>
  );
};

const PlusKoko = ({ number }) => {
  return (
    <PlusKokoContainer>
      <p className="fade-in">+{number}</p>
    </PlusKokoContainer>
  );
};

const KokoCreditClaimed = ({ allocatedCredits }) => {
  const { t } = useTranslation(['assignmentSubmitted']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { assignmentSubmissionID, startAssignmentInfo } = useSelector(
    (state) => state.assignment
  );
  const { rewards, isLoading } = useSelector((state) => state.rewards);
  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);
  return (
    <Container>
      <div className="content-container">
        <div className="box">
          <img className="plane" src={Plane} alt="" />
          <div className="icon">
            <CongratsText />
          </div>
          <div className="text-box">
            <p className="success-text">
              {t(
                'assignmentSubmitted:claimedKoko.Text1',
                'KoKo claimed successfully!'
              )}
            </p>
            <PlusKoko number={allocatedCredits} />
            <ClaimKoko
              kokoBalance={rewards && rewards.TotalKoko}
              isLoading={isLoading}
            />
            <SubmitButton
              fontSize="26px"
              width="105%"
              onClick={() =>
                history.push(
                  `/assignment/solution/${startAssignmentInfo.Id}/${assignmentSubmissionID}`
                )
              }
            >
              {t('assignmentSubmitted:words.SolutionBtn', 'See Solution')}
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default KokoCreditClaimed;
