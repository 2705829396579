import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { lessonStatus } from 'constants/kooClass';
import LessonCard from '../LessonCard';

import { Container, StyledSwiperButton } from './LessonSwiper.styles';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

function LessonSwiper({ data, courseId, selectedLessonId }) {
  const history = useHistory();

  const slideTo = (i) => {
    const { swiper } = document.querySelector('.swiper-container');
    swiper.slideTo(i);
  };
  const slideNext = () => {
    const { swiper } = document.querySelector('.swiper-container');
    swiper.slideNext();
  };
  const slidePrev = () => {
    const { swiper } = document.querySelector('.swiper-container');
    swiper.slidePrev();
  };
  const openLesson = (topicId, id, i) => {
    const { swiper } = document.querySelector('.swiper-container');
    // if lesson is active then open
    if (i === swiper.activeIndex) {
      history.push(
        `/kooClass/${topicId}/lesson/${id}/activity?courseId=${courseId}`
      );
    }
  };

  useEffect(() => {
    const { swiper } = document.querySelector('.swiper-container');
    const selectedLessonIndex = data.findIndex(
      (lesson) => lesson.id === selectedLessonId
    );
    if (selectedLessonIndex !== -1) swiper.slideTo(selectedLessonIndex);
  }, [data, selectedLessonId]);
  return (
    <Container data-cy="lesson-swiper">
      <Swiper
        id="main"
        navigation
        pagination={{ clickable: true }}
        spaceBetween={0}
        slidesPerView={4}
        centeredSlides
        allowTouchMove
      >
        {data.map((item, i) => (
          <SwiperSlide key={`slide-${i}`} onClick={() => slideTo(i)}>
            {({ isActive }) => (
              <LessonCard
                data={item}
                i={i}
                status={lessonStatus[item.lessonStatus]}
                openLesson={openLesson}
                isActive={isActive}
                selectedLessonId={selectedLessonId}
                courseId={courseId}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledSwiperButton content="prev" onClick={slidePrev} />
      <StyledSwiperButton content="next" onClick={slideNext} />
    </Container>
  );
}

export default LessonSwiper;
