import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { subjectIDs, SUBJECTS } from 'constants/products';
import useDashboard from 'hooks/useDashboard';
import { HeaderLevel } from '..';
import { IMAGES } from '../../constants';
import { StyledHeader, HeaderRight, StyledBackButton } from './Header.styles';

const Header = ({
  currentLevel,
  onChangeLevel,
  currentUserLevel,
  subjectId,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['dailyChallengev2']);
  const { isDashboardv2 } = useDashboard();
  return (
    <StyledHeader subjectId={subjectId}>
      <StyledBackButton
        backText={t('common:back', 'Back')}
        btnBgColor="#FBB32A"
        padding="0.3rem 1.2rem"
        onClick={() =>
          history.push(
            isDashboardv2 ? '/dashboard?view=koochallenge' : '/challenges'
          )
        }
        isResponsive
        subjectId={subjectId}
      />
      <HeaderRight subjectId={subjectId}>
        {subjectIDs[subjectId] !== SUBJECTS.ENGLISH && (
          <HeaderLevel
            currentLevel={currentLevel}
            onChangeLevel={onChangeLevel}
            currentUserLevel={currentUserLevel}
          />
        )}
        <img
          className="dc-text"
          src={IMAGES.DailyChallengeText}
          alt="Daily Challenge"
        />
      </HeaderRight>
    </StyledHeader>
  );
};
export default Header;
