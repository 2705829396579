import React from 'react';
import { KeyboardPad, KeyPad } from './FactualFluencyPad.style';

const FactualFluencyPad = ({ onKeyChange, onClear, onBackspace, onEnter }) => {
  return (
    <KeyboardPad>
      <KeyPad onClick={onClear} className="key-clear">
        Clear
      </KeyPad>
      <KeyPad onClick={onBackspace} className="key-back">
        Back
      </KeyPad>
      <KeyPad onClick={() => onKeyChange(7)}>7</KeyPad>
      <KeyPad onClick={() => onKeyChange(8)}>8</KeyPad>
      <KeyPad onClick={() => onKeyChange(9)}>9</KeyPad>
      <KeyPad onClick={() => onKeyChange(4)}>4</KeyPad>
      <KeyPad onClick={() => onKeyChange(5)}>5</KeyPad>
      <KeyPad onClick={() => onKeyChange(6)}>6</KeyPad>
      <KeyPad onClick={() => onKeyChange(1)}>1</KeyPad>
      <KeyPad onClick={() => onKeyChange(2)}>2</KeyPad>
      <KeyPad onClick={() => onKeyChange(3)}>3</KeyPad>
      <KeyPad onClick={() => onKeyChange(0)}>0</KeyPad>
      <KeyPad onClick={() => onKeyChange('.')}>.</KeyPad>
      <KeyPad onClick={onEnter} className="key-enter">
        Enter
      </KeyPad>
    </KeyboardPad>
  );
};

export default React.memo(FactualFluencyPad);
