import React from 'react';
import { ReasonSelection } from '..';
import { Container } from './SubscriptionModal.styles';

const SubscriptionModal = ({
  primaryOnClick,
  secondaryOnClick,
  closeModal,
  heading,
  subHeading,
  primaryBtnText,
  secondaryBtnText,
  showDropDown,
  isTrial,
  paymentSubsId,
  renewalDate,
  childId,
}) => {
  return (
    <Container>
      <div className="close-btn" onClick={closeModal}>
        X
      </div>
      <div className="top">
        <p>{heading}</p>
      </div>
      <div className="bottom">
        {!isTrial && (
          <div className="expired-info">
            {`Your subscription is still active and only expires on ${renewalDate}.`}
          </div>
        )}
        <div className="question">{subHeading}</div>
        {showDropDown && (
          <ReasonSelection
            closeModal={closeModal}
            isTrial={isTrial}
            paymentSubsId={paymentSubsId}
            childId={childId}
          />
        )}
        {!showDropDown && (
          <div className="btn-section">
            <button onClick={primaryOnClick} className="btn orange">
              {primaryBtnText}
            </button>
            <button onClick={secondaryOnClick} className="btn white">
              {secondaryBtnText}
            </button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default SubscriptionModal;
