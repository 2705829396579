import React from 'react';
import { useParams } from 'react-router-dom';

import HODScience from './HODScience';
import HODMath from './HODMath';
import HODEnglish from './HODEnglish';

const HOD = () => {
  const { subject } = useParams();

  if (subject === 'science') {
    return <HODScience />;
  }

  if (subject === 'english') {
    return <HODEnglish />;
  }

  return <HODMath />;
};

export default HOD;
