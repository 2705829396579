import React, { useCallback, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from 'store/login/loginSlice';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import { getOTPEligibility } from 'store/trialSignup/trialSignupSlice';
import { PRODUCT_TYPE, subjectNames, SUBJECTS } from 'constants/products';
import { getProducts, getCountryEligibleSubjects } from 'store/plan/planSlice';
import shouldRedirectMapping from 'helpers/redirectionMapping';

// Helper
const checkIfLoggedIn = (env) => {
  const cookies = Cookies.get();
  let isLoggedIn;
  if (cookies?.source === process.env.REACT_APP_MEMBERS_LOGIN_URL) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    Cookies.remove('source', { path: '/', domain: 'koobits.com' });
  }
  if (env === 'dev') {
    if (!isNil(localStorage.getItem('access_token'))) {
      isLoggedIn = true;
    } else if (isNil(cookies?.sp_access_token)) {
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
      localStorage.setItem('access_token', cookies?.sp_access_token);
      localStorage.setItem('refresh_token', cookies?.sp_refresh_token);
    }
  } // env = prod
  else if (!isNil(cookies?.sp_access_token)) {
    isLoggedIn = true;
    localStorage.setItem('access_token', cookies?.sp_access_token);
    localStorage.setItem('refresh_token', cookies?.sp_refresh_token);
  } else if (!isNil(localStorage.getItem('access_token'))) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }
  return isLoggedIn;
};

// Custom Hooks
export const useGetAPIAfterStudentLoaded = (hasLoad) => {
  const dispatch = useDispatch();
  const { isParentAccount } = useSelector((state) => state.login);
  const { isLoading, studentDetails, error } = useSelector(
    (state) => state.studentDetails
  );
  const hasStudentDetailsLoaded =
    !isLoading && (studentDetails !== null || error !== null);
  const callback = useCallback(async () => {
    if (!hasLoad && hasStudentDetailsLoaded === true) {
      dispatch(getProducts());
    }
    if (!isParentAccount && hasStudentDetailsLoaded === true) {
      dispatch(getOTPEligibility());
      dispatch(getCountryEligibleSubjects(studentDetails.CountryId));
    }
  }, [
    dispatch,
    studentDetails,
    hasStudentDetailsLoaded,
    isParentAccount,
    hasLoad,
  ]);
  useEffect(() => {
    callback();
  }, [callback]);
};

const AuthenticatedRoute = ({
  component: Component,
  isParentRoute,
  isExpiredRoute = false,
  path,
  ...rest
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading } = useSelector((state) => state.studentDetails);
  const {
    subject,
    products,
    currentProduct,
    allNonExpiredSubscriptionProducts,
    countryEligibility,
    currentSubjectProduct,
    isLoading: planIsLoading,
  } = useSelector((state) => state.plan);
  const { userID, isParentAccount } = useSelector((state) => state.login);
  const { OTPEligibility } = useSelector((state) => state.trialSignup);
  const env =
    process.env.REACT_APP_DEV === 'true' ||
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? 'dev'
      : 'prod';
  const isLoggedIn = checkIfLoggedIn(env);
  const allLoading =
    isLoading ||
    OTPEligibility.isLoading ||
    countryEligibility.isLoading ||
    planIsLoading;
  let loginRoute = env === 'dev' ? '/login' : '/members-login';
  const shouldRedirect = shouldRedirectMapping(
    location.pathname,
    location.search
  );
  if (shouldRedirect) {
    loginRoute += `?redirect=${location.pathname.substring(1)}`;
    if (location.search) {
      loginRoute += location.search;
    }
  }

  useEffect(() => {
    if (userID === null) {
      dispatch(
        refreshToken({
          redirect: shouldRedirect
            ? location.pathname.substring(1) + location.search
            : null,
        })
      );
    }
  }, [dispatch, location.pathname, location.search, userID, shouldRedirect]);
  useEffect(() => {
    if (isParentAccount === false) {
      dispatch(getStudentDetails());
    }
  }, [dispatch, isParentAccount]);
  useGetAPIAfterStudentLoaded(Boolean(products));

  const expiredRedirectUrl = () => {
    const subjectName =
      currentProduct?.subject.toLowerCase().trim() ||
      countryEligibility.data?.eligibleSubjects?.[0]?.subject
        ?.toLowerCase()
        .trim() ||
      SUBJECTS.MATH.toLowerCase();
    if (!currentSubjectProduct?.hasPremium) {
      // trial expired
      return OTPEligibility.isEligible
        ? `/trial/${subjectName}`
        : `/expired/${subjectName}`;
    }
    return `/expired-subscription`;
  };
  const renderComponent = (props) => {
    if (isLoggedIn) {
      if (!allLoading && products) {
        const subjectName = location.pathname
          .split('/')[2]
          ?.toLowerCase()
          .trim();
        const subjectId = subjectNames[subjectName];
        const findCountryEligible =
          countryEligibility.data?.eligibleSubjects.find(
            (s) => s.subjectId === subjectId
          );
        if (
          !products?.length &&
          !path?.includes('/expired') &&
          !path?.includes('/trial')
        ) {
          return (
            <Redirect
              to={{
                pathname: expiredRedirectUrl(),
                state: { from: props.location },
              }}
            />
          );
        }
        if (isExpiredRoute && products?.length) {
          if (path?.includes('/expired')) {
            const findNonExpiredProduct =
              allNonExpiredSubscriptionProducts.find(
                (p) =>
                  p.subject.toLowerCase().trim() === subjectName &&
                  p.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM
              );
            if (findNonExpiredProduct) {
              return (
                <Redirect
                  to={{
                    pathname: `/dashboard`,
                    state: { from: props.location },
                  }}
                />
              );
            }
            if (findCountryEligible && OTPEligibility.isEligible) {
              return (
                <Redirect
                  to={{
                    pathname: `/trial/${subjectName}`,
                    state: { from: props.location },
                  }}
                />
              );
            }
          }
        }

        if (path?.includes('/trial')) {
          const findPremiumAndNotExpired =
            allNonExpiredSubscriptionProducts.find(
              (p) =>
                p.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM &&
                !p.expired &&
                p.subject.toLowerCase().trim() === subjectName
            );
          if (findPremiumAndNotExpired) {
            return (
              <Redirect
                to={{
                  pathname: `/dashboard`,
                  state: { from: props.location },
                }}
              />
            );
          }

          if (findCountryEligible) {
            const findOtherTrialExisting = products?.find((p) => {
              if (p.subjectId !== subjectId) {
                return p.nonExpiredSubscriptionProducts.find((s) => s.isTrial);
              }
              return null;
            });
            if (findOtherTrialExisting) {
              const findOtherCountryEligibility =
                countryEligibility.data?.eligibleSubjects.find(
                  (s) => s.subjectId !== findOtherTrialExisting.subjectId
                );

              return (
                <Redirect
                  to={{
                    pathname: `/trial/block`,
                    state: {
                      from: props.location,
                      subjectId: findOtherCountryEligibility?.subjectId,
                    },
                  }}
                />
              );
            }
            if (!OTPEligibility.isEligible) {
              return (
                <Redirect
                  to={{
                    pathname: `/expired/${findCountryEligible.subject?.toLowerCase()}`,
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
            return <Component {...props} />;
          }
          if (path !== '/trial/block') {
            return (
              <Redirect
                to={{
                  pathname: `/dashboard`,
                  state: { from: props.location },
                }}
              />
            );
          }
        }
      }
      if (
        !allLoading &&
        products &&
        currentProduct?.expired &&
        isExpiredRoute !== true &&
        (path?.includes('/expired') === false ||
          path !== `/expired-subscription` ||
          path !== '/bundle/expired' ||
          path !== `/expired/${subject}`)
      ) {
        return (
          <Redirect
            to={{
              pathname: expiredRedirectUrl(),
              state: { from: props.location },
            }}
          />
        );
      }
      return <Component {...props} />;
    }
    return <Redirect to={loginRoute} />;
  };
  // Check if this route is supposed to be accessible to parent account
  if (isParentRoute !== true && isParentAccount === true) {
    return (
      <Redirect
        to={{
          pathname: '/parent/settings/bill-subscription',
        }}
      />
    );
  }
  if (isParentRoute === true && isParentAccount === false) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }
  return <Route {...rest} render={renderComponent} />;
};

export default AuthenticatedRoute;
