import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { convertToTimezone } from 'pages/Leaderboard/v2/helper';
import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import CPGreenIcon from 'assets/img/dailychallenge/enable-cp-green.svg';

import { LABEL_SCHOOL, LABEL_COUNTRY } from '../constants';

import { Container, Headings, Lists } from '../List.styles';

const TopBrainList = ({ category, data }) => {
  const { t } = useTranslation(['leaderboard']);
  const { subject } = useParams();
  const { userID } = useSelector((state) => state.login);

  const [isNearBy, setIsNearBy] = useState(false);
  const [topBrainList, setTopBrainList] = useState([]);
  const [isTop50, setIsTop50] = useState(false);

  const currentLabel = category === 2 ? LABEL_COUNTRY : LABEL_SCHOOL;

  useEffect(() => {
    if (!isEmpty(data)) {
      const isUser = data?.find((stu) => stu.userId === userID);
      if (isUser) {
        if (isUser.leaderboardRank <= 50) {
          setIsTop50(true);
          setTopBrainList(data);
        } else {
          let tempData = [...data];
          tempData = data.slice(2);
          setIsNearBy(true);
          setIsTop50(false);
          setTopBrainList(tempData);
        }
      } else {
        setIsTop50(true);
        setTopBrainList(data);
      }
    } else {
      setTopBrainList([]);
    }
  }, [data, userID]);

  const nearByHandler = () => {
    setTopBrainList(data);
    setIsNearBy(false);
  };

  const showPosition = (pos) => {
    if (isTop50 && pos < 4) {
      if (pos === 1) return 'first';
      if (pos === 2) return 'second';
      if (pos === 3) return 'third';
    }
    if (subject === 'science') {
      return 'scDefault';
    }
    return 'default';
  };

  return (
    <Container subject={subject}>
      <div className="list-headings">
        {currentLabel.map((item) => (
          <Headings key={item.name} flex={item.flex} subject={subject}>
            {t(
              `leaderboard:leaderboardRanking.${item.translateKey}`,
              item.name
            )}
          </Headings>
        ))}
      </div>
      {isNearBy && !isTop50 && (
        <div className="nearby-container">
          <div onClick={nearByHandler} role="button" className="nearby">
            {t('leaderboard:buttons.nearby', 'See Who is nearby')}
          </div>
        </div>
      )}
      {topBrainList?.map((item, i) => (
        <Lists
          subject={subject}
          data-cy={`data-rank-${i}`}
          className={item.userId === userID && 'current-user'}
          currPos={showPosition(item.leaderboardRank)}
          key={item.userId}
        >
          {isTop50 && <div className="positions">{item.leaderboardRank}</div>}
          <div className="list-name">{item.userName}</div>
          <div className="list-school">
            <div className="school-img-container">
              <img
                src={`https://static.koobits.com/eduresources/schlogo/${item.schoolLogo}.png`}
                alt="flag"
              />
            </div>
            {category === 2 ? item.schoolName : item.className}
          </div>
          <div className="list-points">
            <img src={subject === 'science' ? CPGreenIcon : CPIcon} alt="cps" />
            {item.cpPoints}
            {item.isSuperHero && <SuperheroIconModal />}
          </div>
          <div className="list-achievement">
            {convertToTimezone(item.lastSubmissionDate).format(
              'DD-MMM-YYYY HH:mm'
            )}
          </div>
        </Lists>
      ))}
    </Container>
  );
};

export default TopBrainList;
