import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { history } from 'Routes';

import { openEvent, setActiveEventQuestion } from 'store/event/eventSlice';

import formatI18nDateTime from 'helpers/formatI18nDateTime';

import CPIcon from 'assets/img/events/cps.svg';

import { DetailsInfo } from 'components/Assignment';

import {
  Container,
  Content,
  KoKoCredits,
  Column,
  ColumnWrapper,
  StyledSection,
  StyledButton,
} from './EventDetails.styles';

const AssignmentDetails = () => {
  const { t, i18n } = useTranslation(['startPage']);
  const dispatch = useDispatch();
  const { startEventInfo } = useSelector((state) => state.event);
  const startNow = () => {
    dispatch(setActiveEventQuestion(1));
    dispatch(openEvent(startEventInfo.EventId));
    history.push('/events/open');
  };

  const KoKo = (
    <KoKoCredits>
      <span>
        <img src={CPIcon} alt="CP" />
      </span>
      <p>{t(`startPage:words.fullScore`, '20 CPs (Full Score)')}</p>
    </KoKoCredits>
  );

  return (
    <Container>
      <Content>
        <DetailsInfo
          category="Assigned by"
          info={startEventInfo && startEventInfo.AssignerName}
          style={{ flex: 1 }}
        />
        <ColumnWrapper>
          <Column>
            <DetailsInfo
              category="Deadline"
              info={`${moment(
                startEventInfo && startEventInfo.EventEndDate
              ).format(
                formatI18nDateTime({
                  format: 'DD-MMM-YYYY, h:mm a',
                  value: startEventInfo && startEventInfo.EventEndDate,
                  time: true,
                  language: i18n.language,
                })
              )}`}
              style={{ flex: 1 }}
            />
            <DetailsInfo
              category="Total Qns"
              info={startEventInfo && startEventInfo.SMCQuestionNo}
              style={{ flex: 1 }}
            />
          </Column>
          <Column>
            <DetailsInfo
              category="Result Release"
              info={`${moment(
                startEventInfo && startEventInfo.TestResultDate
              ).format(
                formatI18nDateTime({
                  format: 'DD-MMM-YYYY, h:mm a',
                  value: startEventInfo && startEventInfo.TestResultDate,
                  time: true,
                  language: i18n.language,
                })
              )}`}
              style={{ flex: 1 }}
            />
            <DetailsInfo category="Rewards" info={KoKo} style={{ flex: 1 }} />
          </Column>
        </ColumnWrapper>
      </Content>
      <StyledSection>
        <StyledButton
          variant="orange3"
          onClick={startNow}
          data-cy="SMC-startPage"
        >
          {t(`startPage:words.start`, 'Start Now')}
        </StyledButton>
      </StyledSection>
    </Container>
  );
};

export default AssignmentDetails;
