import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import { finishAssignment } from 'store/assignment/assignmentSlice';

import { assignmentTypeIDs } from 'constants/index';

import HomeworkContent from 'components/Assignment/AssignmentFinish/HomeworkContent';
import DetailsInfo from 'components/Assignment/StartPage/DetailsInfo';
import { TestContent } from './components';
import { Header } from '../components';
import { Spinner, ErrorModal } from 'components/Shared';
import { Container, Main, AssignerSection } from './AssignmentFinish.style';

const AssignmentFinish = () => {
  const {
    assignmentSubmissionID,
    startAssignmentInfo,
    isSaving,
    isFinishing,
    assignmentDuration,
    submissions,
    error,
    saveError,
    finishAssignmentError,
    finishAssignmentParams,
  } = useSelector((state) => state.assignment);
  const dispatch = useDispatch();
  const assignmentType =
    assignmentTypeIDs[startAssignmentInfo && startAssignmentInfo.Type];
  const reloadAction = () => {
    if (finishAssignmentError) {
      dispatch(finishAssignment(finishAssignmentParams));
    }
  };
  const isMobile = useMediaQuery({ query: '(max-width: 502px)' });

  const renderAssigner = () => (
    <AssignerSection type={assignmentType}>
      <DetailsInfo
        page="finish-hw"
        category="Assigned by"
        info={startAssignmentInfo && startAssignmentInfo.AssignerName}
      />
      <DetailsInfo
        page="finish-hw"
        category="Deadline"
        info={moment(startAssignmentInfo && startAssignmentInfo.EndDate).format(
          'DD-MMM-yy'
        )}
      />
    </AssignerSection>
  );

  return (
    <Container>
      <Header
        page="finish"
        type={assignmentType}
        assignmentTitle={startAssignmentInfo && startAssignmentInfo.Title}
        isLoading={isSaving || isFinishing}
        isMobile={isMobile}
      />
      <Main type={assignmentType}>
        {!isFinishing &&
          !isSaving &&
          (error !== null ||
            saveError !== null ||
            finishAssignmentError !== null) && (
            <ErrorModal
              reloadAction={reloadAction}
              errorMessage={finishAssignmentError || saveError || error}
            />
          )}
        {(isFinishing || isSaving) && <Spinner color="#ebf7f9" />}
        {!isFinishing &&
          !isSaving &&
          error === null &&
          saveError === null &&
          finishAssignmentError === null && (
            <>
              <div className="wrapper">
                <div className="summary-box">
                  {assignmentType === 'homework' && (
                    <HomeworkContent
                      data={submissions}
                      totalQns={submissions.length}
                      duration={assignmentDuration}
                      assignmentId={startAssignmentInfo.Id}
                      submissionId={assignmentSubmissionID}
                      isMobile={isMobile}
                      renderAssigner={renderAssigner}
                    />
                  )}
                  {(assignmentType === 'mocktest' ||
                    assignmentType === 'test') && (
                    <TestContent
                      duration={assignmentDuration}
                      type={assignmentType}
                      resultDate={new Date(startAssignmentInfo.ResultDate)}
                      assignmentId={startAssignmentInfo.Id}
                      submissionId={assignmentSubmissionID}
                    />
                  )}
                </div>
              </div>
              {(!isMobile || assignmentType !== 'homework') && renderAssigner()}
            </>
          )}
      </Main>
    </Container>
  );
};

export default AssignmentFinish;
