import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import useDashboard from 'hooks/useDashboard';

import HODTitle from 'assets/img/leaderboard/hod/english/english-hod-title.svg';

import { BackButton } from 'components/Shared';
import CurrentDate from '../../CurrentDate';
import { ChallengeType } from '../../../../../components';

import { Container } from './HeaderEnglish.styles';

const HeaderEnglish = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  const { subject } = useParams();
  const isTablet = useMediaQuery({ maxWidth: '1024px' });

  const { isDashboardv2 } = useDashboard();

  return (
    <Container>
      <div className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(227, 151, 0, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.goBack()}
          styles={{ marginTop: '1rem' }}
        />
      </div>
      <div className="middle-content">
        <img src={HODTitle} width="450px" alt="hod-title" />
        <div className="hod-desc">
          <CurrentDate />
          <div className="desc">
            <p>
              <Trans i18nKey="leaderboard:hodBanner.hodDesc1">
                <Link
                  to={
                    isDashboardv2
                      ? '/challenges/daily-challenge'
                      : '/challenges'
                  }
                >
                  Daily Challenge
                </Link>
                <Link
                  to={
                    isDashboardv2
                      ? '/multiplayer/peer-challenge/new-challenge?subject=English'
                      : '/multiplayer'
                  }
                >
                  Peer Challenge
                </Link>
              </Trans>
            </p>
            <p>
              {t(
                'leaderboard:hodBanner.hodDesc2',
                'to start earning some CPs!'
              )}
            </p>
          </div>
        </div>
      </div>
      <ChallengeType
        subject={subject}
        styles={{ top: '12%', right: isTablet ? '20%' : '13%' }}
      />
    </Container>
  );
};

export default HeaderEnglish;
