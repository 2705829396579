import React from 'react';
import AppStore from './imgs/icon-app-store.svg';
import PlayStore from './imgs/icon-play-store.svg';
import QRCode from './imgs/qr-code.svg';
import { Container } from './ParentApp.styles';

const ParentApp = () => {
  return (
    <Container>
      <div className="desc">
        <div className="koobits-parent icon" />
        <p>
          You can download the Parent App below and login with{' '}
          <strong> your email address</strong> to track your child’s progress.
        </p>
      </div>
      <div className="store">
        <a
          href="https://apps.apple.com/sg/app/koobits-parent/id1482084398"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={AppStore} alt="app-store" className="app-store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.koobits.koobits&hl=en_SG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={PlayStore} alt="play-store" />
        </a>
      </div>
      <div>
        <img src={QRCode} alt="qr-code" />
      </div>
    </Container>
  );
};

export default ParentApp;
