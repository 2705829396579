import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setActive } from 'store/dailyChallenge/dailyChallengeSlice';
// hooks
import useIsDesktop from 'hooks/responsive/useIsDesktop';
import useIsMobile from 'hooks/responsive/useIsMobile';
// hooks end
// constants
import { subjectNames, SUBJECTS } from 'constants/products';
import {
  mockLevelsData,
  statusInfoData,
  DCMissionsData,
  mappingMissionBgType,
  mappingQuestionStatus,
} from 'constants/dailyChallengev2';
// constants end

// components
import { Overlay } from 'react-bootstrap';
import { BreakdownCP } from 'components/Shared';
import {
  mappingEnglishMainSectionStyle,
  mappingMathMainSectionStyle,
  mappingMathReward,
  mappingEnglishReward,
  mappingEnglishMissionStyle,
  mappingMathMissionStyle,
} from './constants';
import { IMAGES } from '../../constants';
import { LoadingModal, WarningModal, Curriculum } from '..';
// components end
// styles
import {
  MainContainer,
  StatusItem,
  MissionItem,
  RewardIcon,
  PointingHand,
  RewardToolTip,
  MobileBottomBar,
  StyledMissionCP,
} from './Main.styles';
// styles end
const Main = ({
  currentLevel,
  onChangeLevel,
  currentThemeLevel,
  showChangeDifficultyWarningModal,
  subjectId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['dailyChallengev2']);
  const rewardIconTarget = useRef(null);
  const [missionsData, setMissionsData] = useState(DCMissionsData);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [hasMobileFirstLoad, setHasMobileFirstLoad] = useState(false);

  const {
    currDailyChallenge,
    dailyChallengeSubmissions,
    isLoading,
    currDailyChallengeLoading,
  } = useSelector((state) => state.dailyChallenge);

  const kokoClaimed =
    currDailyChallenge?.IsKokoClaimed ||
    currDailyChallenge?.IsKokoClaimable ||
    false;
  const allLoading = currDailyChallengeLoading || isLoading;
  // responsive
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();

  const onEnterQuestion = (missionNo) => {
    dispatch(setActive(missionNo));
    history.push('/challenges/daily-challenge/question-view');
  };

  const onCheckWarning = () => {
    const currentChallengeLevelData = mockLevelsData.filter(
      (item) => item.levelCode === currDailyChallenge.ChallengeLevel
    )[0];

    onChangeLevel(currentChallengeLevelData, currentLevel);
  };

  const onImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    if (dailyChallengeSubmissions.length === 10) {
      // The science's questions only 5, math is 10
      setMissionsData((prev) => {
        return prev.map((item, index) => {
          const questionStatusCode =
            dailyChallengeSubmissions[index].QuestionStatus;
          return {
            ...item,
            status: mappingQuestionStatus[questionStatusCode],
            reward: dailyChallengeSubmissions[index]?.ChallengePoints || 0,
          };
        });
      });
    }
  }, [dailyChallengeSubmissions]);
  const mappingStyle = useCallback(() => {
    if (subjectId === subjectNames[SUBJECTS.ENGLISH]) {
      return {
        main: mappingEnglishMainSectionStyle,
        reward: mappingEnglishReward,
        mission: mappingEnglishMissionStyle,
      };
    }
    return {
      main: mappingMathMainSectionStyle[currentThemeLevel],
      reward: mappingMathReward[currentThemeLevel],
      mission: mappingMathMissionStyle,
    };
  }, [subjectId, currentThemeLevel]);

  return (
    <MainContainer mappingStyle={mappingStyle().main} subjectId={subjectId}>
      <div className="dc-content">
        {!kokoClaimed && <PointingHand src={IMAGES.PointingHandeIcon} alt="" />}
        {isDesktop ? (
          <IMAGES.Runway className="dc-runway" />
        ) : (
          <IMAGES.RunwayResponsive className="dc-runway" />
        )}
        {subjectId === subjectNames[SUBJECTS.ENGLISH] && (
          <>
            <IMAGES.EnglishFlag className="english-icon flag" />
            <IMAGES.EnglishBook className="english-icon book" />
          </>
        )}
        {missionsData.map((mission) => {
          const MissionBg = mappingMissionBgType[mission.iconType];
          const isCompleted = mission.status === 'completed';
          return (
            <MissionItem
              key={mission.missionNo}
              status={mission.status}
              position={
                isDesktop ? mission.position : mission.responsivePosition
              }
              onClick={() => onEnterQuestion(mission.missionNo)}
              mappingStyle={mappingStyle().mission}
            >
              <div className="mission-icon">
                <MissionBg className="mission-bg" />
                <span className="mission-number">{mission.missionNo}</span>
                {isCompleted && <img src={IMAGES.MissionCheck} alt="" />}
              </div>
              <StyledMissionCP subjectId={subjectId} status={mission.status}>
                <IMAGES.IconCP />
                <span>{mission.reward} CP</span>
              </StyledMissionCP>
            </MissionItem>
          );
        })}
        <RewardIcon
          ref={rewardIconTarget}
          currentLevel={currentThemeLevel}
          subjectId={subjectId}
        >
          <img
            onLoad={onImageLoad}
            src={mappingStyle().reward[kokoClaimed ? 'iconClaimed' : 'icon']}
            alt=""
          />

          {currDailyChallenge?.IsKokoClaimed && (
            <div className="dc-reward-claimed">
              <img src={IMAGES.MissionCheck} alt="" />
            </div>
          )}
        </RewardIcon>
        {!isMobile && (
          <Overlay
            show={!kokoClaimed && rewardIconTarget.current && isImageLoaded}
            target={rewardIconTarget.current}
            placement="top"
            styles={{ zIndex: 10 }}
          >
            {(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <RewardToolTip id="hint-overlay" {...props}>
                {t(
                  `dailyChallengev2:rewardText.complete`,
                  'Complete all questions to'
                )}{' '}
                <br /> {t(`dailyChallengev2:rewardText.earn`, 'earn')}{' '}
                <img src={IMAGES.KoKoIcon} alt="" />{' '}
                {mappingStyle().reward.rewards} KoKo
              </RewardToolTip>
            )}
          </Overlay>
        )}
      </div>

      <div className="dc-info">
        <div>
          {statusInfoData.map((item) => (
            <StatusItem key={item.id} color={item.color}>
              {t(`dailyChallengev2:status.${item.title}`, item.title)}
            </StatusItem>
          ))}
          {!isMobile && subjectId === subjectNames[SUBJECTS.MATH] && (
            <Curriculum
              currDailyChallenge={currDailyChallenge}
              subjectId={subjectId}
            />
          )}
        </div>
        {isMobile && subjectId !== subjectNames[SUBJECTS.ENGLISH] && (
          <MobileBottomBar className="dc">
            <BreakdownCP
              target="dc"
              hasFirstLoad={hasMobileFirstLoad}
              setHasFirstLoad={setHasMobileFirstLoad}
            />
            <Curriculum currDailyChallenge={currDailyChallenge} />
          </MobileBottomBar>
        )}
      </div>
      {allLoading && <LoadingModal />}
      {showChangeDifficultyWarningModal && (
        <WarningModal onCheckWarning={onCheckWarning} />
      )}
    </MainContainer>
  );
};

export default Main;
