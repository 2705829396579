import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import { mappingDateOfWeek } from 'store/dailyChallenge/cpsDetailSlice';

import useDashboard from 'hooks/useDashboard';

import CPIcon from 'assets/img/icon-challenge-point.svg';

import { BreakdownCP } from 'components/Shared';

import { Container } from './SMCScoreBoard.styles';

const SMCScoreBoard = ({ score, date, correct }) => {
  const { t } = useTranslation(['sundayMCResult', 'common']);
  const { isDashboardv2 } = useDashboard();
  const { eventSolution } = useSelector((state) => state.event);
  const { CanBeClaimed } = eventSolution;

  const [hasFirstLoad, setHasFirstLoad] = useState(false);

  const claimedDayIndex = !isNil(eventSolution)
    ? new Date(eventSolution.ClaimDate).getDay()
    : -1;
  const claimedDayName =
    claimedDayIndex !== -1
      ? t(
          `common:${mappingDateOfWeek[claimedDayIndex]}`,
          mappingDateOfWeek[claimedDayIndex]
        )
      : '';
  return (
    <Container className="smc-scoreboard" score={score}>
      <div className="title-score">
        {t('sundayMCResult:mainPage.smc', 'Sunday Mini Challenge')}
      </div>
      <div className="sub-title">
        {t('sundayMCResult:mainPage.result', 'Results')} <span>({date})</span>
      </div>
      <div className="score-board">
        <div className="label">
          <p>{t('sundayMCResult:mainPage.yourScore', 'Your score')}</p>
          {!CanBeClaimed && isDashboardv2 ? (
            <p>
              {t(
                'sundayMCResult:claimedOfDay',
                { day: claimedDayName },
                `You have claimed on this ${claimedDayName}`
              )}
            </p>
          ) : (
            <p>{t('sundayMCResult:mainPage.youCanWin', 'You can win')}</p>
          )}
        </div>
        <div className="score">
          <div className="percentage">
            <p>
              {score}
              <span>%</span>
            </p>
          </div>
          <div className="points">
            {!CanBeClaimed && isDashboardv2 ? (
              <BreakdownCP
                target="smc"
                hasFirstLoad={hasFirstLoad}
                setHasFirstLoad={setHasFirstLoad}
              />
            ) : (
              <p className="points-cp">
                <img src={CPIcon} alt="icon" />
                {t(
                  'sundayMCResult:mainPage.cp',
                  {
                    number: correct * 2,
                  },
                  `${correct * 2} CPs`
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SMCScoreBoard;
