import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { getChildSubs } from 'store/parent/parentSettingsSlice';

import { Spinner } from 'components/Shared';
import { SubscriptionV2 } from '..';
import {
  ContainerV2,
  ContactPage,
  Title,
  Content,
  ChildList,
  ChildName,
} from './BillV2.styles';

const BillV2 = () => {
  const dispatch = useDispatch();
  const { childrenList, childSubs } = useSelector(
    (state) => state.parentSettings
  );
  const {
    data: list,
    isLoading: childrenListLoading,
    isError: childrenListErr,
  } = childrenList;
  const { isLoading: childSubsLoading, isError: childSubsErr } = childSubs;

  const [selectedChild, setSelectedChild] = useState(null);

  const loading = childrenListLoading || childSubsLoading;
  const error = childrenListErr || childSubsErr;

  const nameFormat = (name) => {
    if (name.length > 15) {
      const sliceResult = name.slice(0, 15);
      return `${sliceResult}...`;
    }
    return name;
  };

  useEffect(() => {
    if (!isEmpty(list)) {
      if (isNil(selectedChild)) {
        setSelectedChild(list[0]);
      } else {
        const selectedChildLatestData = list?.find(
          (subs) => subs.studentUserId === selectedChild.studentUserId
        );
        setSelectedChild(selectedChildLatestData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (!isNil(selectedChild)) {
      dispatch(getChildSubs(selectedChild.studentUserId));
    }
  }, [dispatch, selectedChild]);

  return (
    <ContainerV2>
      <Title>Billing & Subscription</Title>
      {childrenListLoading && isNil(selectedChild) ? (
        <Spinner />
      ) : (
        <Content>
          <ChildList>
            {list?.map((item) => (
              <ChildName
                key={item.studentUserId}
                selected={item.studentUserId === selectedChild?.studentUserId}
                onClick={() => setSelectedChild(item)}
              >
                {nameFormat(item.fullName)}
              </ChildName>
            ))}
          </ChildList>
          {childSubsLoading ? (
            <Spinner />
          ) : (
            <SubscriptionV2 childInfo={selectedChild} />
          )}
        </Content>
      )}

      {isNil(error) && !loading && (
        <ContactPage>
          If you need further assistance regarding your subscription or renewal,
          you can reach out to our support team at our{' '}
          <a
            href="https://www.koobits.com/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us page
          </a>
          . Thank you.
        </ContactPage>
      )}
    </ContainerV2>
  );
};

export default BillV2;
