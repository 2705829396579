/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isNil } from 'ramda';

import { claimRewards, resetRewards } from 'store/mission/missionSlice';

import BrainGames from 'assets/img/sidepanel/braingames.svg';
import StoryMath from 'assets/img/sidepanel/storymath.svg';

import { subjectNames } from 'constants/products';
import { history } from 'Routes';

import useDashboard from 'hooks/useDashboard';
import useIsMobile from 'hooks/responsive/useIsMobile';

import { GiftBox } from './components';
import { ErrorModal } from 'components/Shared';

import {
  Page,
  CelebrationContainer,
  GiftBoxesContainer,
  ContinueButton,
  StyledJumpLink,
} from './MysteryKoKo.styles';

const MysteryKoKo = ({ match }) => {
  const { t } = useTranslation(['mysteryKoko']);
  const isMobile = useIsMobile();
  const topicSkillId = parseInt(match.params.id, 10);
  const curriculumId = parseInt(match.params.curriculumId, 10);
  const dispatch = useDispatch();
  const { subject: subjectId } = useLocation()?.state;
  const { claiRewardsResult, claimRewardErr, isClaiming } = useSelector(
    (state) => state.mission
  );
  const {
    rewards,
    isLoading: rewardsIsLoading,
    error: rewardsError,
  } = useSelector((state) => state.rewards);
  const { subject } = useSelector((state) => state.plan);
  const { isDashboardv2 } = useDashboard();
  const [hasOpened, setHasOpened] = useState(false);
  const boxes = Array(8).fill(1);

  useEffect(() => {
    if (isClaiming) {
      setHasOpened(true);
    }
  }, [isClaiming]);

  const claimKoko = () => dispatch(claimRewards(topicSkillId));
  const continueSkill = () => {
    dispatch(resetRewards());
    history.replace({
      pathname: `/mission/self-practice/${curriculumId}`,
      state: { subject: subjectId ?? subjectNames[subject] },
    });
  };
  const handleJumpTo = (path) => {
    dispatch(resetRewards());
    history.replace(path);
  };
  return (
    <Page>
      {!isNil(claimRewardErr) && <ErrorModal errorMessage={claimRewardErr} />}
      {(!hasOpened || isClaiming) && (
        <p>
          {t(
            'mysteryKoko:tapToOpen',
            'Tap to open and find out what treasure lies inside'
          )}
        </p>
      )}
      {hasOpened && !isClaiming && !isNil(claimRewardErr) && (
        <p data-cy="title">
          {t(
            'mysteryKoko:failed',
            'Failed to claim KoKo. Please try again later.'
          )}
        </p>
      )}
      {hasOpened && !isClaiming && isNil(claimRewardErr) && (
        <CelebrationContainer>
          <p>{t('mysteryKoko:congrats', 'Congratulations!')}</p>
          <p>
            <Trans i18nKey="mysteryKoko:youGot">
              You got
              <span>{{ number: claiRewardsResult }}</span>
              rewards.
            </Trans>
          </p>
          {isNil(rewardsError) && !rewardsIsLoading && (
            <p>
              <Trans i18nKey="mysteryKoko:youHave">
                You have
                <span>{{ number: rewards.TotalKoko }}</span>
                in total
              </Trans>
            </p>
          )}
        </CelebrationContainer>
      )}
      <GiftBoxesContainer hasOpened={hasOpened} data-cy="gift-boxes">
        {boxes.map((box, i) => (
          <GiftBox
            // eslint-disable-next-line react/no-array-index-key
            key={box + i}
            hasOpened={hasOpened}
            claimReward={claimKoko}
            kokoPoints={claiRewardsResult}
            isClaiming={isClaiming}
          />
        ))}
      </GiftBoxesContainer>
      {(!isNil(claiRewardsResult) || !isNil(claimRewardErr)) && (
        <>
          <ContinueButton onClick={continueSkill} data-cy="continue-btn">
            {t('mysteryKoko:continue', 'Continue')}
          </ContinueButton>
          {!isMobile && (
            <StyledJumpLink>
              <p>{t('mysteryKoko:jumpLink', 'Jump to Story or Brain Games')}</p>
              <img
                src={StoryMath}
                alt="Story Math"
                onClick={() =>
                  handleJumpTo(isDashboardv2 ? `/story/${subject}` : '/story')
                }
              />
              <img
                src={BrainGames}
                alt="Brain Games"
                onClick={() =>
                  handleJumpTo(
                    isDashboardv2 ? `/brain-game/${subject}` : '/brain-game'
                  )
                }
              />
            </StyledJumpLink>
          )}
        </>
      )}
    </Page>
  );
};

export default MysteryKoKo;
