import React, { useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Overlay } from 'react-bootstrap';
import { SUBJECTS, subjectNames } from 'constants/products';
import useClickOutside from 'hooks/useClickOutside';
import IconTrophy from 'assets/img/mission/missionplus/icon-trophy.svg';
import {
  StyledTooltipContainer,
  StyledTooltipIcon,
  StyledTooltip,
} from './ClaimKoKoModal.styles';

const LelTooltip = ({ open, subject }) => {
  const { t } = useTranslation(['selfPracticeQnView', 'common']);
  const [show, setShow] = useState(false);
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);
  const history = useHistory();

  useClickOutside(tooltipRef, () => {
    setShow(false);
  });
  return (
    <>
      <StyledTooltipIcon
        open={open}
        onClick={() => setShow((prev) => !prev)}
        ref={iconRef}
      >
        i
      </StyledTooltipIcon>
      <Overlay target={iconRef.current} show={show} placement="top">
        {(props) => (
          <StyledTooltipContainer {...props}>
            <StyledTooltip ref={tooltipRef}>
              <Trans i18nKey="selfPracticeQnView:missionPlus.lelTooltip.title">
                Increase your <span>XP Level</span> to obtain a higher level
                bonus by doing
              </Trans>
              <div
                className="dc-button"
                onClick={() =>
                  history.push({
                    pathname:
                      subject === SUBJECTS.SCIENCE.toLowerCase()
                        ? '/challenges/science'
                        : '/challenges/daily-challenge',
                    state: { subjectID: subjectNames[subject] },
                  })
                }
              >
                <img src={IconTrophy} alt="icon-trophy" />
                {t('common:dc', 'Daily Challenge')}
              </div>
            </StyledTooltip>
          </StyledTooltipContainer>
        )}
      </Overlay>
    </>
  );
};

export default LelTooltip;
