import React, { useCallback } from 'react';
import { history } from 'Routes';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { AvatarRendererFromImage } from 'components/AvatarCreator/AvatarRenderer';
import getSchoolName from 'helpers/getSchoolName';
import { subjectToPlanMapping } from 'constants/index';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';
import { Container, Bar, KoKoMonster, ExpLevel } from './StudentDetails.styles';
import XpDetails from './XpDetails';

// Helper for getting school name for display

const StudentDetails = () => {
  // REDUX
  const {
    plan,
    subject,
    currentSubjectProduct,
    allNonExpiredSubscriptionProducts,
  } = useSelector((state) => state.plan);
  const { studentDetails, isLoading, error } = useSelector(
    (state) => state.studentDetails
  );
  const { rewards, error: xpError, isLoading: xpLoading } = useSelector(
    (state) => state.rewards
  );
  const isAvatarExpClickable = !useMediaQuery({ maxWidth: rwd.mobileL });
  // USEEFFECT

  const schoolName = getSchoolName({
    hasPremiumSchool: currentSubjectProduct?.hasPremiumSchool,
    allNonExpiredSubscriptionProducts,
    planToShow: plan,
  });

  const navigateToAvatarCreator = useCallback(() => {
    if (!isAvatarExpClickable) return;
    history.push('/dashboard/KoKoMonster');
  }, [isAvatarExpClickable]);

  const renderDetails = () => {
    if (isLoading) {
      return <Spinner animation="border" style={{ marginTop: '15px' }} />;
    }
    if (error !== null) {
      return <span>{error}</span>;
    }
    return (
      <>
        <span className="name">
          {studentDetails && studentDetails.FullName}
        </span>
        <span className="school">
          {(studentDetails && studentDetails.SchoolLogo === null) || error ? (
            ' '
          ) : (
            <img
              src={studentDetails && studentDetails.SchoolLogo}
              alt="School Logo"
              width="24px"
              height="24px"
              className="logo"
            />
          )}

          {schoolName}
        </span>
      </>
    );
  };

  return (
    <Container>
      <KoKoMonster
        isClickable={isAvatarExpClickable}
        onClick={navigateToAvatarCreator}
        plan={plan}
      >
        <AvatarRendererFromImage
          body={studentDetails && studentDetails.KokoMonster.Body}
          eyes={studentDetails && studentDetails.KokoMonster.Eyes}
          horn={studentDetails && studentDetails.KokoMonster.Horn}
          mouth={studentDetails && studentDetails.KokoMonster.Mouth}
        />
      </KoKoMonster>
      <Bar background={subjectToPlanMapping(subject, plan).studentDetailsBg}>
        <div className="details">{renderDetails()}</div>
      </Bar>
      <ExpLevel>
        <XpDetails
          isLoading={xpLoading}
          error={xpError}
          data={rewards}
          isClickable={isAvatarExpClickable}
          subject={subject}
        />
      </ExpLevel>
    </Container>
  );
};

export default StudentDetails;
