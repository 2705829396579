import React from 'react';
import { useTranslation } from 'react-i18next';
import QnText from 'components/Assignment/AssignmentView/QnText';
import DifficultyChillisInfo from '../DifficultyChillisInfo';
import { Container } from './MissionNoteBookHeader.styles';

const MissionNoteBookHeader = ({ question, topicSkill, qnNo, topicName }) => {
  const { t } = useTranslation(['selfPracticeQnView']);

  return (
    <Container>
      <div className="topic">
        <p className="topic-title">
          {topicSkill && topicSkill.SIO.Description !== ''
            ? topicSkill.SIO.Description
            : topicName}
        </p>
      </div>
      <div className="qn">
        <div className="qn-number">
          <QnText
            fontFam="Grilled Cheese"
            color="#9A7C00"
            borderColor=" #ffce00"
          />
          <div className="number">{qnNo}</div>
        </div>
        <DifficultyChillisInfo
          t={t}
          question={question}
          containerClass="qn-info-desktop"
        />
      </div>
    </Container>
  );
};

export default MissionNoteBookHeader;
