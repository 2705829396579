import React from 'react';
import { useSelector } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import { isNil } from 'ramda';
import { genderId } from 'constants/profileSettings';
import { replaceDoubleBraces } from 'helpers/replaceDoubleBraces';

const ParsedHtml = ({ type }) => {
  const { defaultLevel, studentDetails, country } = useSelector(
    (state) => state.studentDetails
  );
  const { bannerNotif, pageNotif } = useSelector((state) => state.notification);
  const { allNonExpiredSubscriptionProducts } = useSelector(
    (state) => state.plan
  );
  const pageHtmlString = !isNil(pageNotif) ? pageNotif.content : '';
  const bannerHtmlString = !isNil(bannerNotif) ? bannerNotif.content : '';
  const dynamicValues = {
    username: studentDetails && studentDetails.UserName,
    fullname: studentDetails && studentDetails.FullName,
    gender: studentDetails && genderId[studentDetails.Gender],
    parent_name:
      studentDetails &&
      studentDetails.ParentsDetails &&
      studentDetails.ParentsDetails.Name,
    parent_email:
      studentDetails &&
      studentDetails.ParentsDetails &&
      studentDetails.ParentsDetails.Email,
    kokomonster_name: studentDetails && studentDetails.KokoMonster.Name,
    zodiac_name: studentDetails && studentDetails.ZodiacName,
    dob: studentDetails && moment(studentDetails.dob).format('DD MMM YYYY'),
    country,
    school_name:
      allNonExpiredSubscriptionProducts?.[0]?.subscriptionDetails?.Schoolname,
    level: defaultLevel,
    user_id: studentDetails?.UserId,
  };
  const replacedData =
    type === 'page'
      ? replaceDoubleBraces(pageHtmlString, dynamicValues)
      : replaceDoubleBraces(bannerHtmlString, dynamicValues);
  const parsedData = HTMLReactParser(replacedData);

  return <>{parsedData}</>;
};

export default ParsedHtml;
