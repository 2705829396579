import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Tab } from './index.styles';

const PaperTypeTab = ({ className, status, setStatus, tabs, clickHandle }) => {
  const { t, i18n } = useTranslation(['kooQuizBookShelf']);
  const isChinese = i18n.language.toLowerCase().includes('zh');
  return (
    <Container className={className}>
      {tabs.map(({ id, name, icon, iconWidth, fontSize }, index) => (
        <Tab
          data-cy={`tab-${name}`}
          key={name}
          index={index}
          active={id === status}
          onClick={(e) => {
            e.preventDefault();
            setStatus(id);
            clickHandle(name);
          }}
          iconWidth={iconWidth}
          fontSize={fontSize}
          isChinese={isChinese}
        >
          {icon && <img src={icon} title={name} alt={name} />}
          <span>{t(`kooQuizBookShelf:header.${name}`, `${name}`)}</span>
        </Tab>
      ))}
    </Container>
  );
};
export default PaperTypeTab;
