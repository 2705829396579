import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from 'components/Header';
import { IntermediateSuperHero } from '..';
import { Content } from './SuperHero.style';

const Index = () => {
  return (
    <>
      <Header />
      <Content>
        <Switch>
          <Route exact path="/superhero" component={IntermediateSuperHero} />
        </Switch>
      </Content>
    </>
  );
};

export default Index;
