import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import useGlobalFeature from 'hooks/useGlobalFeature';

import Header from 'components/Header';
import { AssignmentSolution } from 'pages/Assignment';

import EventList from './EventList';
import StartEvent from './StartEvent';
import SMCResult from './SMCResult';
import Expired from './Expired';
import BrainMaster from './BrainMaster';
import { Page, Content } from './Events.styles';

const Events = () => {
  const { isEnabled } = useGlobalFeature('smc');
  const { pathname } = useLocation();
  const isBrainMaster = pathname === '/events/brain-master';

  return (
    <Page>
      <Header />
      <Content isBrainMaster={isBrainMaster}>
        <Switch>
          {!isEnabled && (
            <Redirect
              exact
              from={[
                '/events',
                '/events/start/:id',
                '/events/expired',
                '/events/view-result/:smcId',
                '/events/solution',
              ]}
              to="/maintenance-redirect"
            />
          )}
          <Route exact path="/events" component={EventList} />
          <Route exact path="/events/start/:id" component={StartEvent} />
          <Route exact path="/events/expired" component={Expired} />
          <Route
            exact
            path="/events/view-result/:smcId"
            component={SMCResult}
          />
          <Route exact path="/events/solution" component={AssignmentSolution} />
          <Route exact path="/events/brain-master" component={BrainMaster} />
        </Switch>
      </Content>
    </Page>
  );
};

export default Events;
