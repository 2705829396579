import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GlobalStyle from 'components/GlobalStyle';

import Theme from 'Theme';
import useSecret from 'hooks/useSecret';
import Routes from './Routes';

console.log(`App version: ${process.env.REACT_APP_VERSION}`);

function App() {
  const { i18n } = useTranslation(['navigation']);
  const { currentLocale } = useSelector(
    (state) => state.userPreferences.localPreferences
  );
  useSecret();
  useEffect(() => {
    i18n.changeLanguage(currentLocale);
    // eslint-disable-next-line
  }, [currentLocale]);
  return (
    <>
      <GlobalStyle />
      <Theme>
        <Routes />
      </Theme>
    </>
  );
}

export default App;
