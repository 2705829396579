import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRewards } from 'store/dashboard/rewardsSlice';
import { claimKoKoCredits } from 'store/assignment/assignmentSlice';
import SubmitButton from 'components/Assignment/AssignmentFinish/SubmitButton';
import CheckIcon from 'assets/img/assignment/assignment-view/icon-check.svg';
import Plane from 'assets/img/assignment/assignment-view/paper-plane.svg';
import ClaimKoko from './ClaimKoko';
import LimitReach from './LimitReach';
import { Container } from './SubmittedContent.styles';

const SubmittedContent = ({ setClaimKoko, assignmentType, isMobile }) => {
  const { t } = useTranslation(['assignmentSubmitted']);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    assignmentSubmissionID,
    startAssignmentInfo,
    assignmentKoKoCreditsDetails,
  } = useSelector((state) => state.assignment);
  const { rewards, isLoading } = useSelector((state) => state.rewards);
  const canClaimKoko =
    assignmentKoKoCreditsDetails !== null &&
    assignmentKoKoCreditsDetails.hwAllocatedCredits > 0 &&
    assignmentKoKoCreditsDetails.currentMonthClaimedCredits < 50 &&
    assignmentKoKoCreditsDetails.canBeClaimed === true;

  useEffect(() => {
    dispatch(getRewards());
  }, [dispatch]);
  return (
    <Container claimKoko={canClaimKoko}>
      <div className="content-container">
        <div className="box">
          <img className="plane" src={Plane} alt="" />
          <div className="icon">
            <img src={CheckIcon} alt="" />
          </div>
          <div className="text-box">
            <p className="success-text">
              {t(
                'assignmentSubmitted:words.SubmitSuccessText',
                'This assignment has been submitted successfully!'
              )}
            </p>
            {canClaimKoko &&
              (assignmentType === 'homework' ||
                assignmentType === 'factualfluency') && (
                <ClaimKoko
                  kokoCreditsNumber={
                    assignmentKoKoCreditsDetails.hwAllocatedCredits
                  }
                  kokoBalance={rewards && rewards.TotalKoko}
                  isLoading={isLoading}
                />
              )}
            <SubmitButton
              width={isMobile ? '100%' : '70%'}
              onClick={() => {
                if (canClaimKoko) {
                  dispatch(claimKoKoCredits(assignmentSubmissionID));
                  setClaimKoko(true);
                } else {
                  history.push(
                    `/assignment/solution/${
                      startAssignmentInfo && startAssignmentInfo.Id
                    }/${assignmentSubmissionID}`
                  );
                }
              }}
            >
              {canClaimKoko
                ? t('assignmentSubmitted:words.ClaimBtn', 'Claim My KoKo')
                : t('assignmentSubmitted:words.SolutionBtn', 'See Solution')}
            </SubmitButton>
            {assignmentType === 'homework' &&
              assignmentKoKoCreditsDetails.currentMonthClaimedCredits >= 50 &&
              assignmentKoKoCreditsDetails.totalQns ===
                assignmentKoKoCreditsDetails.totalCorrect && <LimitReach />}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SubmittedContent;
