import React from 'react';
import { useTranslation } from 'react-i18next';
import KoKoCredit from 'assets/img/icon-koko-credits.svg';
import { ClaimKokoContainer } from './SubmittedContent.styles';

const ClaimKoko = ({ kokoCreditsNumber, kokoBalance, isLoading }) => {
  const { t } = useTranslation(['assignmentSubmitted']);

  return (
    <ClaimKokoContainer>
      <div className="claim">
        <p>{t('assignmentSubmitted:claimKoko.getKoko', 'You can get ')}</p>
        <img src={KoKoCredit} alt="KoKo Credit" />
        <p className="credit">
          <span>{kokoCreditsNumber}</span> KoKo
        </p>
      </div>
      <div className="total-credit">
        <p className="total">
          {t('assignmentSubmitted:claimKoko.totalKoko', 'You have ')}
        </p>
        <p className="amount">
          {!isLoading && kokoBalance && <span>{kokoBalance} </span>}
          KoKo
        </p>
        <p className="total">
          {t('assignmentSubmitted:claimKoko.total', 'total')}
        </p>
      </div>
    </ClaimKokoContainer>
  );
};
export default ClaimKoko;
