/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// slice
import {
  updateDailyChallengeLevelB2C,
  getDailyChallengeStatusBySubjectID,
  getDailyChallengeDetailsBySubjectID,
} from 'store/dailyChallenge/dailyChallengeSlice';
import { getRewards } from 'store/dashboard/rewardsSlice';
// slice end
// constants
import { mockLevelsData } from 'constants/dailyChallengev2';
import { subjectNames, SUBJECTS } from 'constants/products';
// constants end
// helpers
import { checkIsLock } from './helpers';
// helpers end
// hooks
import useIsMobile from 'hooks/responsive/useIsMobile';
// hooks end
// component
import { Spinner, ErrorModal, BreakdownCP } from 'components/Shared';
import { Header, Main } from './components';
// component end
// styles
import { StyledLoaderWrap, Container } from './DailyChallengeV2.styles';
// styles end

const DailyChallengeV2 = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentLevel, setCurrentLevel] = useState('Elementary');
  const [pageError, setPageError] = useState(false);
  const [currentThemeLevel, serCurrentThemeLevel] = useState(currentLevel);
  const [
    showChangeDifficultyWarningModal,
    setShowChangeDifficultyWarningModal,
  ] = useState(false);
  const { rewards } = useSelector((state) => state.rewards);
  const { currentProduct } = useSelector((state) => state.plan);
  const subjectId =
    location.state?.subjectID ||
    currentProduct?.subjectId ||
    subjectNames[SUBJECTS.MATH];
  const currentUserLevel = rewards?.EXPData?.CurrentLevel?.ID || 1;

  const [isFetchDataAlready, setIsFetchDataAlready] = useState(false);
  const [hasDesktopFirstLoad, setHasDesktopFirstLoad] = useState(false);

  const {
    isErr,
    isLoading,
    currDailyChallenge,
    dailyChallengeStatus,
    currDailyChallengeErr,
  } = useSelector((state) => state.dailyChallenge);

  // responsive
  const isMobile = useIsMobile();

  /**
   * Handle is show the warning modal
   * @async
   * @function handleShowDifficultySelection
   * @param {number} levelCode go to the level's levelCode
   *
   */
  const handleShowDifficultySelection = (levelCode) => {
    if (
      !currDailyChallenge?.UserQuestionSubmissions ||
      !currDailyChallenge?.ChallengeLevel
    ) {
      setPageError(true);
    }

    const { UserQuestionSubmissions, ChallengeLevel } = currDailyChallenge;

    const skippedOrCompletedQns = UserQuestionSubmissions.filter(
      (submission) =>
        submission.SubmissionModel !== null &&
        (submission.SubmissionModel.SubmissionResult === 1 ||
          submission.QuestionStatus === 4)
    );

    const hasActiveQuestion = skippedOrCompletedQns.length > 0;

    if (hasActiveQuestion) {
      if (ChallengeLevel === levelCode) {
        return setShowChangeDifficultyWarningModal(false);
      }
      return setShowChangeDifficultyWarningModal(true);
    }

    setShowChangeDifficultyWarningModal(false);
    return dispatch(
      updateDailyChallengeLevelB2C({ diffLevel: levelCode, subjectId })
    );
  };

  /**
   * Chnage level handler
   * @function
   * @param {object} level - go into the level's data
   * @param {string} currentLevel from this level
   */
  const onChangeLevel = (level, currentLev) => {
    if (isLoading || level.name === currentLev) return null;
    if (!checkIsLock(level.name, currentUserLevel)) {
      handleShowDifficultySelection(level.levelCode);
      setCurrentLevel(level.name);
    }
    return null;
  };

  /**
   * Fetch daily challenge data
   */
  useEffect(() => {
    setIsFetchDataAlready(false);
    if (
      !dailyChallengeStatus ||
      dailyChallengeStatus?.subjectID !== subjectId
    ) {
      (async function mounted() {
        await dispatch(getRewards());
        await dispatch(getDailyChallengeStatusBySubjectID(subjectId));
        await dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
      })();
    } else {
      (async function mounted() {
        await dispatch(getDailyChallengeDetailsBySubjectID(subjectId));
      })();
    }
    setIsFetchDataAlready(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subjectId]);

  useEffect(() => {
    // Handle change cuurentLevel to the ChallengeLevel
    if (currDailyChallenge?.ChallengeLevel) {
      const latestChallengeLevelData = mockLevelsData.filter(
        (item) => item.levelCode === currDailyChallenge.ChallengeLevel
      )[0];

      setCurrentLevel(latestChallengeLevelData.name);
      serCurrentThemeLevel(latestChallengeLevelData.name);
    }

    // Handle first time user
    if (
      currDailyChallenge?.IsFirstTimeUser &&
      currDailyChallenge?.ChallengeLevel === 0
    ) {
      (async function setUserLevel() {
        await dispatch(
          updateDailyChallengeLevelB2C({ diffLevel: 1, subjectId })
        );
      })();
    }
  }, [currDailyChallenge, dispatch, subjectId]);

  useEffect(() => {
    if (currDailyChallengeErr || isErr) setPageError(true);
    else setPageError(false);
  }, [currDailyChallengeErr, isErr]);

  /**
   * Loading spinner
   */
  if (!isFetchDataAlready) {
    return (
      <StyledLoaderWrap>
        <Spinner />
      </StyledLoaderWrap>
    );
  }

  /**
   * Error handler
   */
  if (pageError) {
    return (
      <ErrorModal
        backPage="/dashboard?view=koochallenge"
        errorMessage={currDailyChallengeErr || isErr || 'Unexpect Error'}
      />
    );
  }

  return (
    <Container>
      <Header
        currentLevel={currentLevel}
        onChangeLevel={onChangeLevel}
        currentUserLevel={currentUserLevel}
        subjectId={subjectId}
      />
      <Main
        currentLevel={currentLevel}
        onChangeLevel={onChangeLevel}
        currentThemeLevel={currentThemeLevel}
        showChangeDifficultyWarningModal={showChangeDifficultyWarningModal}
        subjectId={subjectId}
      />
      {!isMobile && subjectId !== subjectNames[SUBJECTS.ENGLISH] && (
        <BreakdownCP
          target="dc"
          style={{ position: 'absolute', right: '3vw', top: '34%' }}
          hasFirstLoad={hasDesktopFirstLoad}
          setHasFirstLoad={setHasDesktopFirstLoad}
        />
      )}
    </Container>
  );
};

export default DailyChallengeV2;
