import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/Shared/BackButton';
import ChallengeType from 'components/Leaderboard/ChallengeType';
import { Container } from './SuperheroHeader.styles';

const SuperheroHeader = () => {
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  return (
    <Container>
      <div className="btn">
        <BackButton
          backText={t('leaderboard:buttons.back', 'Back')}
          btnBgColor="rgb(0, 74, 137, 0.61)"
          padding="0.3rem 1.2rem"
          onClick={() => history.push('/leaderboard')}
          styles={{ marginTop: '1rem' }}
        />
      </div>
      <div className="middle-content">
        <div className="desc">
          <Trans i18nKey="leaderboard:superhero.header">
            <p>Keep being awesome,</p>
            <p>and I’ll keep saying congratulations.</p>
          </Trans>
        </div>
      </div>
      <ChallengeType styles={{ top: '12%', right: '13%' }} />
    </Container>
  );
};

export default SuperheroHeader;
