import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import { Redirect } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import {
  claimKoKoCredits,
  finishSubmitAssignment,
  submitAssignment,
  finishFactualFluency,
} from 'store/assignment/assignmentSlice';

import Mascot from './imgs/mascot-submitted-v2.svg';
import MascotBg from './imgs/mascot-submitted-v2-bg.svg';
import { assignmentTypeIDs } from 'constants/index';
import { Spinner, ErrorModal } from 'components/Shared';
import {
  SubmittedContent,
  KokoCreditClaimed,
  AlreadyClaimKoko,
} from './components';
import { Header } from '../components';

import { Container, Main } from './AssignmentSubmitted.styles';

const AssignmentSubmitted = ({ match }) => {
  const { toClaim } = match.params;
  const dispatch = useDispatch();
  // Redux States
  const {
    assignmentSubmissionID,
    startAssignmentInfo,
    isSubmitting,
    isClaiming,
    claimKoKoCreditsError,
    assignmentSubmissionError,
    assignmentKoKoCreditsDetails,
    solutions,
    finishSubmitAssignmentError,
    finishSubmitAssignmentParams,
    submitAssignmentError,
    submitAssignmentParams,
    finishFactualFluencyError,
    finishFactualFluencyParams,
  } = useSelector((state) => state.assignment);
  const assignmentType =
    assignmentTypeIDs[startAssignmentInfo && startAssignmentInfo.Type];
  const [claimKoko, setClaimKoko] = useState(toClaim === '1');

  const isMobile = useMediaQuery({ query: '(max-width: 502px)' });

  useEffect(() => {
    if (toClaim === '1') {
      dispatch(claimKoKoCredits(assignmentSubmissionID));
    }
  }, [assignmentSubmissionID, dispatch, toClaim]);
  const reloadHandler = () => {
    if (finishSubmitAssignmentError) {
      dispatch(finishSubmitAssignment(finishSubmitAssignmentParams));
    }
    if (submitAssignmentError) {
      dispatch(submitAssignment(submitAssignmentParams));
    }
    if (finishFactualFluencyError) {
      dispatch(finishFactualFluency(finishFactualFluencyParams));
    }
  };
  return (
    <Container>
      <Header
        page="submitted"
        type={assignmentType}
        assignmentTitle={startAssignmentInfo && startAssignmentInfo.Title}
        isLoading={isSubmitting}
        isMobile={isMobile}
      />
      <Main>
        {isSubmitting && <Spinner color="#26B1EF" />}
        {!isSubmitting && assignmentSubmissionError !== null && (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={assignmentSubmissionError}
          />
        )}
        {!isSubmitting && assignmentSubmissionError === null && (
          <>
            {claimKoko && !isClaiming && claimKoKoCreditsError === null && (
              <div className="confetti">
                <Confetti
                  numberOfPieces={1000}
                  opacity={0.8}
                  recycle={false}
                  tweenDuration={8000}
                />
              </div>
            )}
            <div className="mascot">
              <img src={Mascot} className="front" alt="" />
              <img src={MascotBg} className="bg" alt="" />
            </div>
            {isClaiming && <Spinner color="#26B1EF" top="13rem" />}
            {!claimKoko && !isClaiming && (
              <SubmittedContent
                claimKoko={claimKoko}
                setClaimKoko={setClaimKoko}
                assignmentType={assignmentType}
                isMobile={isMobile}
              />
            )}
            {claimKoko && !isClaiming && claimKoKoCreditsError === null && (
              <KokoCreditClaimed
                allocatedCredits={
                  toClaim === '1'
                    ? solutions.HWAllocatedKokoCredits
                    : assignmentKoKoCreditsDetails.hwAllocatedCredits
                }
              />
            )}
            {!isClaiming && claimKoKoCreditsError && <AlreadyClaimKoko />}
          </>
        )}
      </Main>
    </Container>
  );
};
const AssignmentSubmittedHoc = ({ history, ...props }) => {
  if (history.action === 'POP')
    return <Redirect to="/assignment/assignment-list" />;
  return <AssignmentSubmitted {...props} />;
};
export default AssignmentSubmittedHoc;
