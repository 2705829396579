import styled from 'styled-components';

export const TriggerButton = styled.div`
  position: fixed;
  right: 5vw;
  bottom: 5%;
  cursor: pointer;
  z-index: 103;
`;

export const Wrapper = styled.div`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 101;
`;

export const Container = styled.div`
  width: 312px;
  height: 169px;
  background-color: #ffffff;
  border-radius: 14px;
  position: absolute;
  right: 5vw;
  bottom: 5%;
  transform: translate(-13%, -18%);
  color: #000;
  padding: 28px 30px;
  font-size: 14px;
`;

export const Heading = styled.div`
  margin-bottom: 12px;
`;

export const SearchBar = styled.form`
  height: 38px;
  width: 224px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
`;

export const Delete = styled.div`
  width: 20px;
  height: 100%;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 36px;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  font-size: 22px;
  padding-left: 5px;
  border: 1px solid #b7b7b7;
  border-right: none;

  &::placeholder {
    color: #d3d3d3;
    font-size: 18px;
  }
`;

export const SearchButton = styled.button`
  background-color: #fadc5b;
  border: 1px solid #e0ba24;
  height: 38px;
  width: 32px;
`;

export const ErrorMessage = styled.span`
  opacity: ${(p) => (p.isShow ? 1 : 0)};
  color: #ff0000;
  font-size: 12px;
  padding-left: 2px;
`;
