import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDashboard from 'hooks/useDashboard';
import { Button } from 'components/Shared';
import { Container } from './NoSubmit.styles';

const NoSubmit = () => {
  const history = useHistory();
  const { t } = useTranslation(['expiredSMC']);
  const { isDashboardv2 } = useDashboard();
  return (
    <Container>
      <div className="no-submit-desc">
        {t(
          'expiredSMC:didNotSubmit',
          'You did not submit for this Challenge. Please try again next week.'
        )}
        <br />
        {t(
          'expiredSMC:pleaseNote',
          'Please note that Mini Challenge will be open from 6am to 10pm every Sunday.'
        )}
      </div>
      <Button
        fontWeight="bold"
        variant="primary"
        textShadow="0px 3px 6px rgba(0, 0, 0, 0.16)"
        width="250px"
        fontSize="28px"
        onClick={() =>
          history.push(
            isDashboardv2 ? '/dashboard?view=koochallenge' : '/events'
          )
        }
      >
        {t('expiredSMC:ok', 'OK')}
      </Button>
    </Container>
  );
};

export default NoSubmit;
