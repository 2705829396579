import React, { useEffect, useCallback } from 'react';
import Header from 'components/Header';
import { history } from 'Routes';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  initAvatarCreator,
  saveAvatar,
  setBodyLeft,
  setBodyRight,
  setBodyColor,
  setFirstName,
  setLastName,
  setHornLeft,
  setHornRight,
  setEyesLeft,
  setEyesRight,
  setMouthLeft,
  setMouthRight,
} from 'store/avatar/avatarCreatorSlice';
import { getStudentDetails } from 'store/dashboard/studentDetailsSlice';
import titleImage from 'assets/img/avatarcreator/my-koko.png';
import showcaseImage from 'assets/img/avatarcreator/showcase.png';
import { Spinner } from 'react-bootstrap';
import BackButton from 'components/Shared/BackButton';
import NamePicker from 'components/AvatarCreator/NamePicker';
import AvatarRenderer from 'components/AvatarCreator/AvatarRenderer';
import AvatarArrowButton from 'components/AvatarCreator/AvatarArrowButton';
import AvatarButtonPanel from 'components/AvatarCreator/AvatarButtonPanel';
import AvatarBodySelector from 'components/AvatarCreator/AvatarBodySelector';
import AvatarSubmitButton from 'components/AvatarCreator/AvatarSubmitButton';
import { isNil } from 'ramda';
import useAvatarBodyParts from 'helpers/useAvatarBodyParts';
import {
  Page,
  Container,
  ImageTitle,
  AvatarCreatorContainer,
  AvatarContainer,
  AvatarCreatorMonsterContainer,
  AvatarCreatorPanel,
  AvatarCreatorButtonContainer,
  ShowcaseImage,
} from './index.styles';

const AvatarCreator = () => {
  useAvatarBodyParts();
  const { t } = useTranslation(['common']);
  const {
    avatarParts,
    isPartLoaded,
    isLoadingCreator,
    isSavingAvatar,
    firstName,
    lastName,
    body,
    bodyColor,
    eyes,
    horn,
    mouth,
  } = useSelector((state) => state.avatarCreator);
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const FullName = studentDetails && studentDetails.FullName;
  const UserId = studentDetails && studentDetails.UserId;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initAvatarCreator());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getStudentDetails());
  }, [dispatch]);
  const goToShowcase = useCallback(() => history.push('/MonsterShowcase'), []);
  return (
    <Page>
      <Header />
      {!(isPartLoaded && body !== null) && (
        <div style={{ margin: 'auto', marginTop: 150, display: 'block' }}>
          {' '}
          <Spinner
            animation="border"
            style={{ marginTop: '15px', display: 'block' }}
          />
        </div>
      )}
      {isPartLoaded && body !== null && (
        <>
          <Container>
            <BackButton
              backText={t('common:back', 'Back')}
              btnBgColor="rgba(82,213,127,0.6)"
              padding="0.3rem 1.2rem"
              onClick={() => history.push('/dashboard?view=koofun')}
              styles={{
                position: 'absolute',
                left: '4%',
                top: '4.7rem',
                zIndex: 1,
              }}
            />
            <AvatarCreatorContainer>
              <AvatarCreatorPanel>
                <AvatarBodySelector
                  colors={avatarParts.Colours}
                  color={bodyColor}
                  onChangeLeft={() => dispatch(setBodyLeft())}
                  onChangeRight={() => dispatch(setBodyRight())}
                  onChangeColor={(c) => dispatch(setBodyColor(c))}
                  image={avatarParts.Bodys.find((b) => b.ID === body).Img}
                />
              </AvatarCreatorPanel>
              <AvatarCreatorMonsterContainer>
                <AvatarContainer>
                  <ImageTitle src={titleImage} />
                  <AvatarRenderer
                    loading={isLoadingCreator}
                    horn={horn}
                    body={body}
                    eyes={eyes}
                    mouth={mouth}
                    color={bodyColor}
                  />
                </AvatarContainer>
                <AvatarCreatorButtonContainer>
                  <AvatarButtonPanel type="horn" top="5%">
                    <AvatarArrowButton
                      direction="left"
                      isBig
                      onClick={() => dispatch(setHornLeft())}
                    />
                    <AvatarArrowButton
                      direction="right"
                      isBig
                      onClick={() => dispatch(setHornRight())}
                    />
                  </AvatarButtonPanel>
                  <AvatarButtonPanel type="eyes" top="25%">
                    <AvatarArrowButton
                      direction="left"
                      isBig
                      onClick={() => dispatch(setEyesLeft())}
                    />
                    <AvatarArrowButton
                      direction="right"
                      isBig
                      onClick={() => dispatch(setEyesRight())}
                    />
                  </AvatarButtonPanel>
                  <AvatarButtonPanel type="mouth" top="45%">
                    <AvatarArrowButton
                      direction="left"
                      isBig
                      onClick={() => dispatch(setMouthLeft())}
                    />
                    <AvatarArrowButton
                      direction="right"
                      isBig
                      onClick={() => dispatch(setMouthRight())}
                    />
                  </AvatarButtonPanel>
                </AvatarCreatorButtonContainer>
              </AvatarCreatorMonsterContainer>
              <AvatarCreatorPanel start="true">
                <ShowcaseImage
                  onClick={goToShowcase}
                  alt="showcase"
                  src={showcaseImage}
                />
              </AvatarCreatorPanel>
            </AvatarCreatorContainer>
          </Container>
          <NamePicker
            adjective={firstName}
            lastName={lastName}
            onChangeAdjective={(e) => dispatch(setFirstName(e.target.value))}
            onChangeLastName={(e) => dispatch(setLastName(e.target.value))}
            adjectives={avatarParts.Adjectives}
            lastNames={FullName === null ? [] : FullName.split(' ')}
          />
          {!isNil(UserId) && (
            <AvatarSubmitButton
              loading={isSavingAvatar}
              onSubmit={() => dispatch(saveAvatar({ userId: UserId }))}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default React.memo(AvatarCreator);
