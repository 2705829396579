import {
  EnglishHOD,
  ScienceHOD,
  MathHOD,
  HODClose,
  MathTopBrain,
  ScienceTopBrain,
  TopBrainClose,
  MathChampionClass,
  ChampionClassClose,
  MathBFF,
  BFFClose,
  MathSuperHero,
  SuperHeroClose,
  MathZodiacRace,
  ZodiacRaceClose,
  MathWizardSchool,
  WizardSchoolClose,
  MathBoysGirls,
  BoysVsGirlsClose,
  MathLionCity,
  LionCityClose,
} from 'assets/img/leaderboard/leaderboardList';

import SuperHeroPoint from 'assets/img/leaderboard/super-hero/superhero-point.png';
import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import scienceCPIcon from 'assets/img/science-cp-icon.svg';
import { EnglishCP } from 'assets/img/dashboardv2/dashboard';

export const CATEGORIES = (country) => {
  return [
    {
      name: 'My Class',
      value: 4,
      key: 'class',
      translateKey: 'myClass',
    },
    {
      name: 'My School',
      value: 3,
      key: 'school',
      translateKey: 'mySchool',
    },
    {
      name: `${country} Schools`,
      value: 2,
      key: 'national',
      translateKey: 'schools',
    },
    {
      name: 'Global Schools',
      value: 1,
      key: 'global',
      translateKey: 'globalSchools',
    },
    {
      name: 'Overall',
      value: 5, // not exist in backend. for frontend only
      key: 'overall',
      translateKey: 'overAll',
    },
  ];
};

export const LEADERBOARD_ID = {
  hod: {
    math: 1,
    science: 6,
    english: 8,
  },
  topBrain: {
    math: 2,
    science: 7,
  },
  championClass: {
    math: 3,
  },
  bff: 4,
  superhero: 5,
};

export const RANKING_FILTER = {
  regular: 1,
  top50: 2,
  hallOfFame: 3,
};

export const PERIOD_TYPE = {
  daily: 1,
  weekly: 2,
  monthly: 3,
  term: 4,
  season: 5,
  yearly: 6,
};

export const SUBJECT_ID = {
  math: 1,
  science: 2,
  english: 3,
};

export const PRIMARY_LEVELS = [1, 2, 3, 4, 5, 6];
export const LEADERBOARD_LIST = {
  math: [
    {
      name: 'Hero of The Day',
      img: MathHOD,
      imgClose: HODClose,
      schedule: 'Daily Challenge: 6am to 10pm',
      translation: 'Daily Challenge HOD', // for localization
      link: '/leaderboard/hero-of-the-day',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['hod-leaderboard'],
      hasSubject: true,
    },
    {
      name: 'Top Brain',
      img: MathTopBrain,
      imgClose: TopBrainClose,
      schedule: 'Quarterly Challenge',
      translation: 'Quarterly Challenge', // for localization
      link: '/leaderboard/top-brain',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: [
        'topbrain-leaderboard-termly',
        'topbrain-leaderboard-classic',
      ],
      hasSubject: true,
    },
    {
      name: 'Champion Class',
      img: MathChampionClass,
      imgClose: ChampionClassClose,
      schedule: 'Monthly Challenge',
      translation: 'Monthly Challenge', // for localization
      link: '/leaderboard/champion-class',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: [
        'championclass-leaderboard-classic',
        'championclass-leaderboard-termly',
      ],
      hasSubject: true,
    },
    {
      name: 'Best Friends Forever',
      img: MathBFF,
      imgClose: BFFClose,
      schedule: 'Weekly Challenge: Mon to Sun',
      translation: 'Weekly Challenge BFF', // for localization
      link: '/leaderboard/best-friends-forever',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['bff-leaderboard'],
      hasSubject: false,
    },
    {
      name: 'Super Hero',
      img: MathSuperHero,
      imgClose: SuperHeroClose,
      schedule: 'Weekly Challenge: Mon to Sat',
      translation: 'Weekly Challenge SH', // for localization
      link: '/leaderboard/super-hero',
      status: 'open',
      featureReady: true,
      pointsIcon: SuperHeroPoint,
      configFeatureNames: ['superhero-leaderboard'],
      hasSubject: false,
    },
    {
      name: 'Zodiac Race',
      img: MathZodiacRace,
      imgClose: ZodiacRaceClose,
      scheduleOpen: ' 01 May',
      scheduleClose: ' 31 May',
      startDateTime: '05-01',
      endDateTime: '05-31',
      link: '/leaderboard/zodiac-race',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['zodiacrace-leaderboard'],
      hasSubject: false,
    },
    {
      name: 'Boys vs Girls',
      img: MathBoysGirls,
      imgClose: BoysVsGirlsClose,
      scheduleOpen: ' 01 Jun',
      scheduleClose: ' 31 Jul',
      startDateTime: '06-01',
      endDateTime: '07-31',
      link: '/leaderboard/boys-vs-girls',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['boysvsgirls-leaderboard'],
      hasSubject: false,
    },
    {
      name: 'Wizard School',
      img: MathWizardSchool,
      imgClose: WizardSchoolClose,
      scheduleOpen: ' 01 Sep',
      scheduleClose: ' 30 Sep',
      startDateTime: '09-01',
      endDateTime: '09-30',
      link: '/leaderboard/wizard-school',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['wizardschool-leaderboard'],
      hasSubject: false,
    },
    {
      name: 'Lion City',
      img: MathLionCity,
      imgClose: LionCityClose,
      scheduleOpen: ' 01 Dec',
      scheduleClose: ' 31 Dec',
      startDateTime: '12-01',
      endDateTime: '12-31',
      link: '/leaderboard/lion-city',
      status: 'open',
      featureReady: true,
      pointsIcon: CPIcon,
      configFeatureNames: ['lioncity-leaderboard'],
      hasSubject: false,
    },
  ],
  science: [
    {
      name: 'Hero of The Day',
      img: ScienceHOD,
      imgClose: HODClose,
      schedule: 'Daily Challenge: 6am to 10pm',
      translation: 'Daily Challenge HOD', // for localization
      link: '/leaderboard/hero-of-the-day',
      status: 'open',
      featureReady: true,
      pointsIcon: scienceCPIcon,
      configFeatureNames: ['hod-leaderboard'],
      hasSubject: true,
    },
    {
      name: 'Top Brain',
      img: ScienceTopBrain,
      imgClose: TopBrainClose,
      schedule: 'Quarterly Challenge',
      translation: 'Quarterly Challenge', // for localization
      link: '/leaderboard/top-brain',
      status: 'open',
      featureReady: true,
      pointsIcon: scienceCPIcon,
      configFeatureNames: ['science-topbrain-leaderboard'],
      hasSubject: true,
    },
  ],
  english: [
    {
      name: 'Hero of The Day',
      img: EnglishHOD,
      imgClose: HODClose,
      schedule: 'Daily Challenge: 6am to 10pm',
      translation: 'Daily Challenge HOD', // for localization
      link: '/leaderboard/hero-of-the-day',
      status: 'open',
      featureReady: true,
      pointsIcon: EnglishCP,
      configFeatureNames: ['hod-leaderboard'],
      hasSubject: true,
    },
  ],
};

export const LEADERBOARD_COLORS = {
  math: {
    headerColor: '#2fc7ff',
    btnColor: '#2fc7ff',
    textColor: '#ffffff',
    borderColor: '#009dd9',
    hoverColor: '#46d4ff',
    headingColor: '#5ec0eb',
    oddBgColor: '#ffffff',
    oddBdColor: '#d4ecf5',
    evenBgColor: '#def6ff',
    evenBdColor: '#b9e6f7',
    nearbyBgColor: '#f0fcff',
    nearbyBtnColor: '#dbf7ff',
    nearbyBdColor: '#9adcf5',
    nearbyTxtColor: '#0081b1',
  },
  science: {
    headerColor: '#21DB9D',
    btnColor: '#0DD186',
    textColor: '#ffffff',
    borderColor: '#00c976',
    hoverColor: '#0DE693',
    headingColor: '#00aa86',
    oddBgColor: '#ffffff',
    oddBdColor: '#9ee5d0',
    evenBgColor: '#dcfff6',
    evenBdColor: '#9ee5d0',
    nearbyBgColor: '#F0FFFA',
    nearbyBtnColor: '#D3FDF0',
    nearbyBdColor: '#ADE3D1',
    nearbyTxtColor: '#00aa86',
  },
  english: {
    headerColor: '#FF900F',
    btnColor: '#FF860D',
    textColor: '#ffffff',
    borderColor: '#D86F07',
    hoverColor: '#FECE18',
    headingColor: '#8D8D8D',
    oddBgColor: '#ffffff',
    oddBdColor: '#F5F1E1',
    evenBgColor: '#FFF8E3',
    evenBdColor: '#F8F6ED',
    nearbyBgColor: '#FFFBEF',
    nearbyBtnColor: '#FFEEAC',
    nearbyBdColor: '#FFE071',
    nearbyTxtColor: '#655644',
  },
};

export const LOADING = 'Loading...';
