import mathDashboard from 'assets/img/trial/math-trial-dashboard.png';
import scienceDashboard from 'assets/img/trial/science-trial-dashboard.png';
import englishDashboard from 'assets/img/trial/english-trial-dashboard.png';

export const SUBJECTS = {
  1: {
    name: 'Math',
    id: 1,
    src: mathDashboard,
    order: 1,
  },
  2: {
    name: 'Science',
    id: 2,
    src: scienceDashboard,
    order: 3,
  },
  3: {
    name: 'English',
    id: 3,
    src: englishDashboard,
    order: 2,
  },
};
