import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { convertToTimezone } from 'pages/Leaderboard/v2/helper';

import CPIcon from 'assets/img/dailychallenge/enable-cp.svg';
import CPGreenIcon from 'assets/img/dailychallenge/enable-cp-green.svg';

import { SuperheroIconModal } from 'pages/Leaderboard/v2/components';
import { MyClass } from 'pages/Leaderboard/v2/pages/ChampionClass/components';
import { LABEL_CLASS } from '../constants';

import ChampionClassListInner from '../../ListInner/ChampionClassListInner';

import { Container, Headings, Lists } from '../List.styles';

const ChampionClassList = ({
  category,
  toggleHandler,
  showStudents,
  currentTerm,
  currentPeriod,
  getStudents,
}) => {
  const { t } = useTranslation(['leaderboard']);
  const { subject } = useParams();
  const { type, championClass, students: studentsData } = useSelector(
    (state) => state.championClassV2
  );
  const { data } = championClass;
  const { data: students } = studentsData;

  const showPosition = (pos) => {
    if (pos < 4) {
      if (pos === 1) return 'first';
      if (pos === 2) return 'second';
      if (pos === 3) return 'third';
    }
    if (subject === 'science') {
      return 'scDefault';
    }
    return 'default';
  };

  if (category === 4) {
    return <MyClass currentTerm={currentTerm} currentPeriod={currentPeriod} />;
  }

  return (
    <Container>
      <div className="list-headings">
        {LABEL_CLASS.map((item) => (
          <Headings key={item.name} flex={item.flex} subject={subject}>
            {t(
              `leaderboard:leaderboardRanking.${item.translateKey}`,
              item.name
            )}
          </Headings>
        ))}
      </div>
      {data?.map((item, i) => (
        <Fragment key={item.classId}>
          <Lists
            subject={subject}
            data-cy={`data-rank-${i}`}
            currPos={showPosition(item.leaderboardRank)}
            pointer
            onClick={() => toggleHandler(i, item.classId)}
          >
            <div className="positions">{item.leaderboardRank}</div>
            <div className="list-name">{item.className}</div>
            <div className="list-school">
              <div className="school-img-container">
                <img
                  src={`https://static.koobits.com/eduresources/schlogo/${item.schoolLogo}.png`}
                  alt="flag"
                />
              </div>
              {item.schoolName}
            </div>
            <div className="list-points">
              <img
                src={subject === 'science' ? CPGreenIcon : CPIcon}
                alt="cps"
              />
              {item.cpPoints}
              {item.isSuperHero && <SuperheroIconModal />}
            </div>
            <div className="list-achievement">
              {convertToTimezone(item.lastSubmissionDate).format(
                'DD-MMM-YYYY HH:mm'
              )}{' '}
              {type === 'classic' && item.countryCode}
            </div>
          </Lists>
          {showStudents[i] && (
            <ChampionClassListInner
              data={students[item.classId] ?? []}
              top20TotalPoints={item.cpPoints}
              getStudents={() => getStudents(item.classId)}
            />
          )}
        </Fragment>
      ))}
    </Container>
  );
};

export default ChampionClassList;
