import React from 'react';
import { ReactComponent as BackIcon } from 'assets/img/icon-back.svg';
import { Container } from './BackButton.styles';

const BackButton = ({
  top,
  btnBgColor,
  onClick,
  backText,
  fontSize,
  padding,
  noIcon,
  styles,
  color,
  dataCy,
  isResponsive,
  arrowColor,
  iconSize,
  className,
}) => {
  const combinedClassName = `${className || ''} ${
    isResponsive ? 'responsive' : ''
  }`.trim();
  return (
    <Container
      data-cy={dataCy || 'back-btn'}
      padding={padding}
      top={top}
      bgColor={btnBgColor}
      onClick={onClick}
      fontSize={fontSize}
      color={color}
      arrowColor={arrowColor}
      iconSize={iconSize}
      style={{ ...styles }}
      className={combinedClassName}
    >
      {!noIcon && <BackIcon />}
      <span className="button-text">{backText}</span>
    </Container>
  );
};

export default BackButton;
