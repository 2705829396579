import React from 'react';
import { ModalContainer, Loader } from './Modals.styles';

const LoadingModal = () => {
  return (
    <ModalContainer opacity={0.7}>
      <Loader />
    </ModalContainer>
  );
};

export default LoadingModal;
