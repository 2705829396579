import styled from 'styled-components';
import { kooClassSubjectStyles } from 'constants/dashboardv2';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 59px);
  background: ${({ subject }) =>
    kooClassSubjectStyles[subject].mainBgColour ??
    kooClassSubjectStyles.math.mainBgColour};
  position: relative;
`;

export const FillerSideBg = styled.div`
  width: 193px;
  height: inherit;
  position: absolute;
  top: 0px;
  background: ${({ subject }) =>
    kooClassSubjectStyles[subject]?.sidebarBgColour ??
    kooClassSubjectStyles.math.sidebarBgColour};
`;

export const TopSection = styled.section`
  padding: 72px 0 calc(0.75 * 0.051910598413 * 100vw)
    calc(0.039653929343 * 100vw);
  display: flex;
  flex-wrap: nowrap;
  background: ${({ subject }) =>
    `url(${kooClassSubjectStyles[subject]?.bgImage})` ??
    `url(${kooClassSubjectStyles.math.bgImage})`};
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  .topic-list-container {
    flex-basis: 205px;
    max-height: 331px;
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1388px) {
    padding: 72px 0 calc(0.75 * 0.051910598413 * 100vw)
      calc(0.10677083333 * 100vw);
  }
  @media (max-width: 1024px) {
    padding: calc(0.056640625 * 100vw) 0 43px calc(0.130859375 * 100vw);
    .topic-list-container {
      display: none;
    }
  }
  @media (max-width: 768px) {
    padding: calc(0.056640625 * 100vw) 0 43px 80px;
  }
`;

export const ScrollDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ subject }) =>
    kooClassSubjectStyles[subject].scrollBg ?? 'rgba(245, 250, 255, 0.19)'};
  height: fit-content;
  padding: 10px;
  border-radius: 25px;
  text-align: center;
  min-width: 117px;
  .scroll-icon {
    margin-top: -30px;
    height: 55px;
  }
  .no-scroll {
    font-weight: 600;
    font-size: 20px;
    margin-top: -15px;
  }
  .reload {
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    color: ${({ subject }) =>
      kooClassSubjectStyles[subject].scrollText ?? '#ffffff'};
    font-size: 12px;
    margin: 0;
  }
  @media (max-width: 768px) {
    margin-left: 1rem;
  }
`;

export const FeaturedTopic = styled.div`
  position: relative;
  flex-basis: content;
  margin: 0 calc(0.043258832011 * 100vw);
  .play-btn {
    position: absolute;
    top: calc(0.748484848484 * 0.238406463824 * 100vw);
    right: -58px;
  }
  @media (max-width: 1024px) {
    margin: 0 37px 0 0;
  }
  @media (max-width: 768px) {
    margin: 0 6px 0 0;
  }
`;

export const Card = styled.div`
  width: calc(0.397981254506 * 100vw);
  height: calc(0.238406463824 * 100vw);
  background: ${(props) => `url(${props.bg})`};
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  :hover {
    cursor: pointer;
  }
`;

export const TopicsSection = styled.section`
  background: ${({ subject }) =>
    subject ? kooClassSubjectStyles[subject].topicListBg : '#0e1327'};
  padding: 24px 0 24px 273px;
  min-height: 230px;
  color: white;
  .topics-header {
    font-size: 16px;
  }
  .topics-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0.5rem;
  }
  p {
    margin: 0;
  }
  @media (min-width: 1920px) {
    font-size: 22px;
  }
  @media (max-width: 1024px) {
    padding: 12px 0 12px 253px;
  }
  @media (max-width: 768px) {
    padding: 12px 0 12px 233px;
  }
  @media (max-width: 1367px) {
    min-height: 259px;
  }
`;
