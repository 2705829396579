import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { isNil } from 'ramda';
import moment from 'moment';

// Store
import {
  getRevisionId,
  openKooQuiz,
  setKooQuizSelectedPaper,
  getCurrentGiftBySubject,
  assignGifts,
  resetAssignGift,
  setKooQuizType,
  setGiftObj,
  setCurrentCourseId,
} from 'store/kooQuiz/kooQuizSlice';

// Assets
import GiftBoxMath from 'assets/img/kooquiz/kooQuizQn/gift-box-math.gif';
import PencilImg from 'assets/img/kooquiz/kooQuizQn/pencil.svg';
import StarImg from 'assets/img/kooquiz/kooQuizQn/star.svg';
import PrintIcon from 'assets/img/kooquiz/kooQuizQn/print.svg';
import formatI18nDateTime from 'helpers/formatI18nDateTime';

// Constants
import {
  kooQuizTypes,
  status,
  KOOQUIZ_PAPERS,
  KOOQUIZ_STYLES,
  KOO_QUIZ_PAPER_TYPE_ID,
  KOO_QUIZ_PAPER,
} from 'constants/kooQuiz';
import { subjectIDs, SUBJECTS } from 'constants/products';
// Component
import { Button, BackButton, ErrorModal } from 'components/Shared';

import {
  StyledBackground,
  StyledContent,
  StyledPencil,
  StyledPaper,
  StyledInstructions,
  HOTSContainer,
  HOTSCard,
} from './KooQuizStart.styles';

const ONLINE_OFFLINE_ACTIVE_VALUE = {
  3: 'HOTS',
  4: 'OEQs',
};

const KooQuizStart = () => {
  const { t, i18n } = useTranslation(['kooQuizStartPage', 'startPage']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { subject: subjectId, id: paperId, paper: paperVal } = useParams();
  const currentSubject = subjectIDs[subjectId];
  const [isRevisionDataLoaded, setIsRevisionDataLoaded] = useState(false);
  const isOnlineOfflineActive = Boolean(ONLINE_OFFLINE_ACTIVE_VALUE[paperVal]);

  const {
    paperById,
    kooQuizSelectedPaper,
    kooQuizType,
    giftObj,
    giftsInfo,
    errorById,
    isLoading,
    errorGiftAssign,
  } = useSelector((state) => state.kooQuiz);

  const { allFeatures } = useSelector((state) => state.plan);

  const currentPaper = KOOQUIZ_PAPERS.find(
    (item) => item.id === parseInt(paperVal, 10)
  );

  const getRevisionIdData = (kooQuizFeatureData) => {
    if (!kooQuizFeatureData) throw Error('Not get features data');
    const featureCourseIds = kooQuizFeatureData.parameters.Category;

    dispatch(setCurrentCourseId(featureCourseIds[currentPaper.paramName]));

    const getRevisionIdParam = {
      subjectId,
      paperId,
      courseIds: featureCourseIds[currentPaper.paramName],
    };

    dispatch(getRevisionId(getRevisionIdParam));
  };

  useEffect(() => {
    if (subjectId && giftsInfo.length > 0) {
      const selectedPaper = parseInt(paperVal, 10);
      const currentGift = giftsInfo.find(
        (item) =>
          item.RevisionCategoryId === selectedPaper ||
          // this for english
          (item.RevisionCategoryId === 1 &&
            KOO_QUIZ_PAPER_TYPE_ID[selectedPaper] ===
              KOO_QUIZ_PAPER.VocabularyCloze)
      );
      dispatch(setGiftObj(currentGift));
    }
  }, [subjectId, giftsInfo, paperVal, dispatch]);

  useEffect(() => {
    if (kooQuizTypes[subjectId] !== kooQuizType) {
      dispatch(setKooQuizType(kooQuizTypes[subjectId]));
    }
    dispatch(getCurrentGiftBySubject(subjectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId]);

  useEffect(() => {
    dispatch(setKooQuizSelectedPaper(paperVal));
  }, [dispatch, paperVal]);

  useEffect(() => {
    if (!isRevisionDataLoaded && allFeatures) {
      const parseKooQuizFeatureData = allFeatures.filter(
        (kooQuizItem) => kooQuizItem.feature?.toLowerCase().trim() === 'kooquiz'
      )[0];
      getRevisionIdData(parseKooQuizFeatureData);
      setIsRevisionDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeatures]);

  useEffect(() => {
    return () => {
      dispatch(resetAssignGift());
    };
  }, [dispatch]);

  const checkGiftType = KOOQUIZ_PAPERS?.find(
    (item) => item.id === parseInt(kooQuizSelectedPaper, 10)
  )?.gift;

  const giftType =
    checkGiftType === 'Gift Bag'
      ? t('kooQuizStartPage:bag', 'Bag')
      : t('kooQuizStartPage:box', 'Box');
  const isMathSubject = currentSubject === SUBJECTS.MATH;
  const rewardEndDate = paperById?.RewardEndDate
    ? moment(paperById.RewardEndDate).format(
        formatI18nDateTime({ format: 'DD MMM YYYY', language: i18n.language })
      )
    : moment()
        .day(7)
        .format(
          formatI18nDateTime({ format: 'DD MMM YYYY', language: i18n.language })
        );

  const openKooQuizAction = () => {
    dispatch(openKooQuiz(paperId));
    if (paperVal === 3 || paperVal === 4) {
      history.push(`/kooQuiz/open?id=${paperId}&print=false`);
    } else {
      history.push(`/kooQuiz/open?id=${paperId}`);
    }
  };
  const handleAssignGift = async () => {
    const params = {
      paperId,
      subjectId,
      rewardId: giftObj.Id,
    };
    try {
      const result = await dispatch(assignGifts(params));
      unwrapResult(result);
      const parseKooQuizFeatureData = allFeatures.filter(
        (kooQuizItem) => kooQuizItem.feature?.toLowerCase().trim() === 'kooquiz'
      )[0];
      getRevisionIdData(parseKooQuizFeatureData);
      openKooQuizAction();
    } catch (err) {
      console.log(err.message);
    }
  };

  const openKooQuizHandler = () => {
    if (
      giftObj.CurrentUserStatus === 3 &&
      paperById &&
      giftObj.Id !== paperById.rewardId
    ) {
      handleAssignGift();
    } else {
      openKooQuizAction();
    }
  };

  const onPrint = () => {
    if (!paperById?.OfflinePDF) throw new Error('No offline PDF data');
    const offlinePDFData = JSON.parse(paperById.OfflinePDF);

    if (offlinePDFData.PrintedPaper.length > 0) {
      window.open(
        `https://s3.ap-southeast-1.amazonaws.com/static.koobits/${offlinePDFData.PrintedPaper}`,
        '_blank'
      );
    }
  };

  const reloadHandler = () => {
    if (errorById) {
      const parseKooQuizFeatureData = allFeatures.filter(
        (kooQuizItem) => kooQuizItem.feature?.toLowerCase().trim() === 'kooquiz'
      )[0];
      getRevisionIdData(parseKooQuizFeatureData);
      dispatch(setKooQuizSelectedPaper(paperVal));
    }
    if (errorGiftAssign) {
      handleAssignGift();
    }
  };
  const handleGoBack = () => {
    history.push(`/kooQuiz/${currentSubject}/${paperVal}`);
  };
  if (status[paperById?.SubmissionStatus] === 'Completed') {
    history.push(
      `/kooQuiz/solution-preview/${kooQuizTypes[subjectId]}/${paperVal}/${paperId}/${paperById?.HomeworkSubmissionId}`
    );
  }

  if (isOnlineOfflineActive) {
    return (
      <>
        <StyledBackground subject={subjectId}>
          <BackButton
            backText={t('kooQuizStartPage:Back', 'Back')}
            btnBgColor={KOOQUIZ_STYLES[subjectId]?.backButtonColor}
            padding="0.3rem 1.2rem"
            onClick={handleGoBack}
          />
          {paperById && (
            <StyledContent
              style={{ maxWidth: '915px' }}
              onlineOffline={paperVal === 3 || paperVal === 4}
            >
              <StyledPaper
                subject={subjectId}
                paperType={currentPaper}
                onlineOffline={paperVal === 3 || paperVal === 4}
              >
                <p className="hots-questions-text">
                  {t('kooQuizStartPage:TotalQuestions', 'Total Questions')}
                  &nbsp;
                  <strong>{paperById.TotalQuestions}</strong>
                </p>
                <img src={paperById.Icon.IconUrl} alt="paper" />
              </StyledPaper>
              <HOTSContainer>
                <HOTSCard width="350px" subject={subjectId}>
                  <div className="hots-header">
                    {t('kooQuizStartPage:OnlinePractice', 'Online Practice')}
                  </div>
                  <div className="hots-content">
                    {(paperById.RewardEndDate !== null ||
                      giftObj.CurrentUserStatus === 3) && (
                      <>
                        <h6 style={{ marginBottom: '14px' }}>
                          <Trans
                            rewardEndDate={rewardEndDate}
                            i18nKey={
                              checkGiftType === 'Gift Bag'
                                ? 'kooQuizStartPage:GetRewardBag'
                                : 'kooQuizStartPage:GetRewardBox'
                            }
                          >
                            Complete this KooQuiz by <p>{{ rewardEndDate }}</p>{' '}
                            to win a KoKo Gift{' '}
                            {checkGiftType === 'Gift Bag' ? 'Bag' : 'Box'}
                          </Trans>
                        </h6>
                        <p className="hots-heightlight">
                          {t(
                            'kooQuizStartPage:WinKoKo',
                            { giftType },
                            `Win 1 KoKo Gift ${
                              checkGiftType === 'Gift Bag' ? 'Bag' : 'Box'
                            } per week.`
                          )}
                        </p>
                      </>
                    )}
                    {paperById.RewardEndDate === null &&
                      giftObj.CurrentUserStatus !== 3 && (
                        <h6 style={{ marginBottom: '14px' }}>
                          {t(
                            'kooQuizStartPage:NoReward',
                            { giftType: giftType.toLowerCase() },
                            `You have already unlocked your gift ${
                              checkGiftType === 'Gift Bag' ? 'bag' : 'box'
                            } for this week. You may still attempt the quiz but there will be no KoKo rewards given.`
                          )}
                        </h6>
                      )}
                    <div className="hots-heightlight hots-items-center">
                      <img src={StarImg} alt="" />
                      <strong>
                        {t(
                          'kooQuizStartPage:SubmitPaper',
                          'Submit paper to see the solutions.'
                        )}
                      </strong>
                    </div>
                  </div>
                </HOTSCard>
                <HOTSCard width="285px" subject={subjectId}>
                  <div className="hots-header">
                    {t('kooQuizStartPage:OfflinePractice', 'Offline Practice')}
                  </div>
                  <div className="hots-content">
                    <h6
                      style={{ marginBottom: isMathSubject ? '26px' : '44px' }}
                    >
                      {isMathSubject
                        ? t(
                            'kooQuizStartPage:offlineHeading',
                            'Complete this KooQuiz paper by hand, supported with full video solutions.'
                          )
                        : t(
                            'kooQuizStartPage:offlineHeadingNoVideo',
                            'Complete this KooQuiz paper by hand, supported with full solution.'
                          )}
                    </h6>
                    <button
                      className="hots-print"
                      type="button"
                      onClick={onPrint}
                    >
                      <img src={PrintIcon} title="print" alt="" />
                      <span>{t('kooQuizStartPage:print', 'Print')}</span>
                    </button>
                  </div>
                </HOTSCard>
                {(paperById.RewardEndDate !== null ||
                  giftObj.CurrentUserStatus === 3) && (
                  <img src={GiftBoxMath} alt="gift-box" className="gift-box" />
                )}
              </HOTSContainer>

              <StyledPencil style={{ marginLeft: '1rem' }} subject={subjectId}>
                <img src={PencilImg} alt="pencil" />
              </StyledPencil>
            </StyledContent>
          )}
        </StyledBackground>
        {paperById && (
          <Button
            onClick={openKooQuizHandler}
            className="primary"
            width="100%"
            padding="17px 0"
            fontSize="28px"
            isDisabled={isLoading}
            style={{
              margin: '40px auto',
              display: 'flex',
              justifyContent: 'center',
              maxWidth: '380px',
            }}
          >
            {paperById.RewardEndDate === null
              ? t('kooQuizStartPage:Start', 'Start Online')
              : t('kooQuizStartPage:Continue', 'Continue')}
          </Button>
        )}
        {(!isNil(errorById) || !isNil(errorGiftAssign)) && (
          <ErrorModal
            reloadAction={reloadHandler}
            errorMessage={errorById || errorGiftAssign}
          />
        )}
      </>
    );
  }

  return (
    <>
      <StyledBackground subject={subjectId}>
        <BackButton
          backText={t('kooQuizStartPage:Back', 'Back')}
          btnBgColor={KOOQUIZ_STYLES[subjectId]?.backButtonColor}
          padding="0.3rem 1.2rem"
          onClick={handleGoBack}
        />
        {paperById && (
          <StyledContent>
            <StyledPencil subject={subjectId}>
              <img src={PencilImg} alt="pencil" />
            </StyledPencil>
            <StyledPaper subject={subjectId} paperType={currentPaper}>
              <img src={paperById.Icon.IconUrl} alt="paper" />
            </StyledPaper>
            <StyledInstructions>
              <h4>{t('kooQuizStartPage:Instructions', 'Instructions')}</h4>
              <div className="content">
                <div className="circle">
                  <p>1</p>
                </div>
                <div>
                  <h5>
                    {t('kooQuizStartPage:TotalQuestions', 'TotalQuestions')}
                    <span>{paperById.TotalQuestions}</span>
                  </h5>
                  <p className="tip">
                    <img src={StarImg} alt="star" />
                    {t(
                      'kooQuizStartPage:SubmitPaper',
                      'Submit paper to see the solutions.'
                    )}
                  </p>
                </div>
              </div>
              <div className="content">
                <div className="circle">
                  <p>2</p>
                </div>
                <div>
                  <h5 className="second-title">
                    {(paperById.RewardEndDate !== null ||
                      giftObj.CurrentUserStatus === 3) && (
                      <>
                        <Trans
                          rewardEndDate={rewardEndDate}
                          i18nKey={
                            checkGiftType === 'Gift Bag'
                              ? 'kooQuizStartPage:GetRewardBag'
                              : 'kooQuizStartPage:GetRewardBox'
                          }
                        >
                          Complete this KooQuiz by <p>{{ rewardEndDate }}</p> to
                          win a KoKo Gift{' '}
                          {checkGiftType === 'Gift Bag' ? 'Bag' : 'Box'}
                        </Trans>
                      </>
                    )}
                    {paperById.RewardEndDate === null &&
                      giftObj.CurrentUserStatus !== 3 &&
                      t(
                        'kooQuizStartPage:NoReward',
                        { giftType: giftType.toLowerCase() },
                        `You have already unlocked your gift ${
                          checkGiftType === 'Gift Bag' ? 'bag' : 'box'
                        } for this week. You may still attempt the quiz but there will be no KoKo rewards given.`
                      )}
                  </h5>
                  {(paperById.RewardEndDate !== null ||
                    giftObj.CurrentUserStatus === 3) && (
                    <p className="tip">
                      <img src={StarImg} alt="star" />
                      {t(
                        'kooQuizStartPage:WinKoKo',
                        { giftType },
                        `Win 1 KoKo Gift ${
                          checkGiftType === 'Gift Bag' ? 'Bag' : 'Box'
                        } per week.`
                      )}
                    </p>
                  )}
                </div>
                {(paperById.RewardEndDate !== null ||
                  giftObj.CurrentUserStatus === 3) && (
                  <img
                    src={
                      KOOQUIZ_STYLES[subjectId][
                        checkGiftType === 'Gift Bag' ? 'bag' : 'box'
                      ]
                    }
                    alt="gift-box"
                    className="gift-box"
                  />
                )}
              </div>
            </StyledInstructions>
          </StyledContent>
        )}
      </StyledBackground>
      {paperById && (
        <Button
          onClick={openKooQuizHandler}
          className="primary"
          width="100%"
          padding="17px 0"
          fontSize="28px"
          isDisabled={isLoading}
          style={{
            margin: '40px auto',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '380px',
          }}
        >
          {paperById.RewardEndDate === null
            ? t('kooQuizStartPage:Start', 'Start')
            : t('kooQuizStartPage:Continue', 'Continue')}
        </Button>
      )}
      {(!isNil(errorById) || !isNil(errorGiftAssign)) && (
        <ErrorModal
          reloadAction={reloadHandler}
          errorMessage={errorById || errorGiftAssign}
        />
      )}
    </>
  );
};

export default KooQuizStart;
