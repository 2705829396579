import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { fetchWrapper } from 'services/login';
import { claimDailyKoKo } from 'services/dailyBonus';
import {
  getRewards,
  checkDailyBonusClaimed,
} from 'store/dashboard/rewardsSlice';

import { PopupModal } from 'components/Shared';

import KokoGold from 'assets/img/navigation/gold-koko.svg';
import BonusIcon from 'assets/img/navigation/bouns.svg';
import BonusClaimedIcon from 'assets/img/navigation/bounsClaimed.svg';
import {
  ModalContainer,
  IconContainer,
  Container,
  TopBg,
  ModalContent,
  Icon,
} from './Bouns.styles';

const BonusModal = ({ onHide, setError }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['dashboard']);
  const { dailyBonusKoKoToBeClaimed } = useSelector((state) => state.rewards);
  // Component States
  const [isClaiming, setIsClaiming] = useState(false);
  const [hasClaimed, setHasClaimed] = useState(false);

  // Event Handler
  const claimKoKoHandler = async () => {
    setIsClaiming(true);
    setError(null);
    try {
      await fetchWrapper(claimDailyKoKo);
      setHasClaimed(true);
      dispatch(getRewards());
      dispatch(checkDailyBonusClaimed());
    } catch (err) {
      setError(err.message);
    }
    setIsClaiming(false);
  };
  const closeModal = () => {
    onHide();
  };

  return (
    <ModalContainer data-cy="daily-bonus-modal">
      <IconContainer>
        {!hasClaimed && (
          <img
            src={BonusIcon}
            alt=""
            width="89.02px"
            height="85.44px"
            data-cy="close-box"
          />
        )}
        {hasClaimed && (
          <img
            src={BonusClaimedIcon}
            alt=""
            width="96.4px"
            height="105.77px"
            data-cy="open-box"
          />
        )}
        <p data-cy="bonus-title">
          {t(`dashboard:dailyBonusModal.title`, 'Daily Bonus')}
        </p>
      </IconContainer>
      <Container>
        <TopBg />
        <ModalContent>
          {!hasClaimed && (
            <>
              <p className="top-text">
                <span className="orange">
                  {t(`dashboard:dailyBonusModal.Yeah`, 'Yeah!')}
                </span>{' '}
                {t(
                  `dashboard:dailyBonusModal.mainText`,
                  'You are eligible for'
                )}
              </p>
              <p className="eligible-amt">
                {`${t(
                  `dashboard:dailyBonusModal.mainText2ndLinePt1`,
                  { koko: dailyBonusKoKoToBeClaimed },
                  ' free '
                )}`}
                <img
                  src={KokoGold}
                  alt="koko"
                  width="33.8px"
                  height="34.63px"
                  data-cy="koko-gold"
                />
                {t(
                  `dashboard:dailyBonusModal.mainText2ndLinePt2`,
                  ' KoKo today!'
                )}
              </p>
              <button
                type="button"
                disabled={isClaiming}
                onClick={claimKoKoHandler}
                data-cy="claim-bonus"
              >
                {isClaiming
                  ? t(
                      `dashboard:dailyBonusModal.loadingButtonText`,
                      'Claiming...'
                    )
                  : t(`dashboard:dailyBonusModal.buttonText`, 'Claim now')}
              </button>
            </>
          )}
          {hasClaimed && (
            <>
              <p className="congrats" data-cy="congrats-text">
                {t(`dashboard:dailyBonusModal.Congrats`, 'Congratulations!')}
              </p>
              <p className="has-claimed" data-cy="claimed-text">
                {t(
                  `dashboard:dailyBonusModal.claimedText`,
                  'You have claimed the Daily Bonus KoKo Credit.'
                )}
              </p>
              <p className="come-back-tmr" data-cy="claimed-text2">
                {t(
                  `dashboard:dailyBonusModal.claimedText2`,
                  'Please come back tomorrow for more!'
                )}
              </p>
              <button type="button" onClick={closeModal} data-cy="ok-btn">
                {t(`dashboard:dailyBonusModal.okButtonText`, 'OK')}
              </button>
            </>
          )}
        </ModalContent>
      </Container>
    </ModalContainer>
  );
};

const Bonus = () => {
  const dispatch = useDispatch();
  const [claimError, setClaimError] = useState(null);
  const [showBounsModal, setShowBounsModal] = useState(false);
  const { isDailyBonusClaimable } = useSelector((state) => state.rewards);

  const onClickHandler = () => {
    if (isDailyBonusClaimable) return setShowBounsModal(true);
    return null;
  };

  useEffect(() => {
    if (!isNil(claimError)) {
      setShowBounsModal(false);
    }
  }, [claimError]);
  useEffect(() => {
    dispatch(checkDailyBonusClaimed());
  }, [dispatch]);
  return (
    <div>
      <Icon
        onClick={onClickHandler}
        isClaimable={isDailyBonusClaimable ? 1 : 0}
        data-cy="daily-bonus"
      >
        <img
          src={isDailyBonusClaimable ? BonusIcon : BonusClaimedIcon}
          alt=""
          className="daily-bonus-icon"
        />
      </Icon>

      <PopupModal
        animation={false}
        show={showBounsModal}
        backdrop="static"
        hide={() => {
          setShowBounsModal(false);
        }}
      >
        <BonusModal
          onHide={() => {
            setShowBounsModal(false);
          }}
          setError={setClaimError}
        />
      </PopupModal>
    </div>
  );
};

export default Bonus;
