import styled from 'styled-components';
import GreenBg from 'assets/img/assignment/assignment-view/header-green-bg.svg';

export const LogoContainer = styled.div`
  background: #00a49a;
  width: 78px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: 50px;
  width: 100vw;
  padding: 0 1rem 0 0;
  background: url(${GreenBg});
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: fixed;
  z-index: 10;
  top: ${(props) =>
    props.page === 'solution' && !props.noNavBar ? '59px' : '0'};
  left: 0;

  .hw-title {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    font-weight: 600;
    font-size: 1.125rem;
  }
`;

export const Button = styled.button`
  color: white;
  border: none;
  background: #018798;
  border-radius: 1rem;
  margin: 0 1rem;
  padding: 0.25rem 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  flex: 1;

  img {
    margin-right: 0.5rem;
  }
  .button-text {
    margin-left: 0rem;
  }

  :hover {
    background: #016c7a;
  }
  @media (max-width: 955px) {
    margin-right: 0.5rem;
  }

  @media (max-width: 502px) {
    height: 35px;
    width: 35px;
    border-radius: 50%;

    img {
      margin-left: -0.2rem;
    }

    .button-text {
      display: none;
    }
  }
`;

export const ActionButton = styled.button`
  background: ${({ isSaved }) => (isSaved ? 'none' : ' #71FFE4')};
  border: none;
  color: ${({ isSaved }) => (isSaved ? 'rgba(112,112,112,0.5)' : '#199083')};
  font-size: 0.875rem;
  border-radius: 22px;
  padding: 0 0.7rem;
  :hover {
    cursor: ${({ disabled }) =>
      disabled === true ? 'not-allowed' : 'pointer'};
    background: ${({ isSaved }) => (isSaved ? null : '#8dffe9')};
  }
`;

export const Section = styled.div`
  mid-width: 200px;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.page
      ? 'flex-end'
      : props.type === 'actions'
      ? 'space-evenly'
      : 'none'};
  border-left: none;
  height: 100%;
  margin-right: ${(props) => props.page && '1rem'};
  span {
    .text {
      color: #006681;
      @media (max-width: 955px) {
        font-size: 1rem;
      }
      @media (max-width: 850px) {
        font-size: 0.8rem;
      }
    }
    margin-left: 1rem;
    @media (max-width: 955px) {
      margin-left: 0.5rem;
    }
  }
`;
