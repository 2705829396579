import styled from 'styled-components';

export const Container = styled.div``;

export const LeftContent = styled.div`
  display: flex;

  .info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #0d0d0d;
    margin-left: 1rem;
    .challenge-name {
      font-size: 25px;
      font-weight: 600;
    }
    .detail-two {
      display: flex;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    .info-section {
      .challenge-name {
        font-size: 20px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .info-section {
      .challenge-name {
        font-size: 16px;
      }
    }
  }
`;

export const Rewards = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    margin-left: 0.5rem;
  }
`;

export const SubCardContainer = styled.div`
  height: 200px;
  width: 200px;
  background: #f96255;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .icon {
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: ${(props) =>
        props.type === 'upcoming'
          ? '100px'
          : props.type === 'open'
          ? '150px'
          : '80px'};
    }
    @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
      img {
        width: ${({ type }) => (type === 'upcoming' ? '60%' : '90%')};
      }
    }
    .points {
      position: absolute;
      display: flex;
      bottom: 0px;
      right: -50px;
      color: #ffffff;
      font-family: Arial;
      .circle {
        border-radius: 50%;
        width: 55px;
        height: 55px;
        background-color: #ff983d;
        text-align: center;
        font-size: 37px;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        font-family: Linotte;
        font-weight: 600;
      }
      p {
        align-self: flex-end;
        margin-bottom: 0px;
      }
    }
  }
  .text {
    p {
      margin-bottom: 0px;
      color: #ffffff;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
    }
    .title {
      img {
        position: absolute;
        margin-left: ${({ isChinese }) => (isChinese ? '0' : '25px')};
        margin-top: 10px;
      }
      .title-top {
        font-size: 20px;
      }
    }
    .sub-title {
      color: ${(props) => (props.type === 'result' ? '#ffffff' : '#fffc00')};
      text-align: ${(props) => props.type === 'result' && 'left'};
      font-size: 12px;
      margin: ${(props) => props.type === 'result' && '15px 15px 0px'};
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 160px;
    height: 160px;
    .text {
      p {
        font-size: 12px;
      }
      .title {
        img {
          margin-left: ${({ isChinese }) => (isChinese ? '0' : '15px')};
        }
        .title-top {
          font-size: 18px;
        }
      }
      .sub-title {
        font-size: 10px;
      }
    }
    .icon {
      .points {
        right: -33px;
        .circle {
          width: 30px;
          height: 30px;
          font-size: 20px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 140px;
    height: 140px;
    .text {
      p {
        font-size: 10px;
      }
      .title {
        img {
          margin-left: ${({ isChinese }) => (isChinese ? '0' : '10px')};
        }
        .title-top {
          font-size: 14px;
        }
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      img {
        width: 70%;
      }
      .points {
        right: -22px;
        p {
          font-size: 12px;
        }
      }
    }
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
  justify-content: ${({ isMobile }) => (isMobile ? 'space-around' : 'center')};
  width: ${({ isMobile }) => isMobile && '100%'};
  align-items: center;
  width: 100%;
  p {
    margin-bottom: 0px;
    text-align: center;
  }
  .action-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      width: 70%;
    }
  }
  .text {
    margin-bottom: ${({ isMobile }) => (isMobile ? '0' : '10px')};
    .grey-text {
      color: #d8d8d8;
    }
    .orange-text {
      color: #ff7121;
    }
    .submit {
      font-size: 20px;
    }
    .small-grey-text {
      color: #777a76;
      font-size: 14px;
    }
  }
`;
