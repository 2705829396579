import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Button } from 'components/Shared';
import { Container } from './HowToWinModal.styles';

const HowToWinModal = ({ click }) => {
  const { t } = useTranslation(['brainMaster', 'common']);

  return (
    <Container>
      <p className="title">
        {t('brainMaster:howToWinModal.title', 'How to win the trophy？')}
      </p>
      <p>
        {t(
          'brainMaster:howToWinModal.desc1',
          ' Complete any questions correctly (except KooClass) during the campaign to win a trophy!'
        )}
      </p>
      <div className="content">
        <p>
          <Trans i18nKey="brainMaster:howToWinModal.desc2">
            • Answer <span>1000 questions correctly</span> for a{' '}
            <span>“Distinction”</span> Trophy
          </Trans>
        </p>
        <p>
          <Trans i18nKey="brainMaster:howToWinModal.desc3">
            • Answer <span>500 questions correctly</span> for a{' '}
            <span>“Merit”</span> Trophy
          </Trans>
        </p>
      </div>
      <p>
        <Trans i18nKey="brainMaster:howToWinModal.desc4">
          Click the{' '}
          <a
            href="https://support.koobits.com/hc/en-gb/articles/39389118503577-Terms-Conditions-Brain-Master"
            target="_blank"
            rel="noopener noreferrer"
          >
            T&C
          </a>{' '}
          for more details.
        </Trans>
      </p>
      <Button
        variant="orange4"
        padding="0.5rem 2.2rem"
        fontSize="1.125rem"
        onClick={click}
        style={{ alignSelf: 'center' }}
      >
        {t('common:gotIt', 'Got it')}
      </Button>
    </Container>
  );
};

export default HowToWinModal;
