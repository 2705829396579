import styled from 'styled-components';

const subjectTheme = {
  english: {
    background: `rgba(245, 239, 209, 0.3)`,
  },
  science: {
    background: `rgba(207, 227, 220, 0.3)`,
  },
};
export const Container = styled.div`
  position: relative;
  border: 1px solid ${({ medal }) => (medal ? '#EFDEBF' : '#eaeaea')};
  border-radius: 6px;
  background: #ffffff;
  height: 130px;
  width: 122px;
  &:hover {
    cursor: pointer;
  }
  .overlay {
    border-radius: 6px;
    height: 130px;
    width: 122px;
    position: absolute;
    background: ${({ subject }) =>
      subjectTheme[subject]?.background || `rgba(207, 227, 220, 0.3)`};
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 50px;
    }
  }
  .bg {
    background: ${({ medal }) =>
      medal ? 'linear-gradient(#FFFBEF, #FFEB8D)' : '#f8f8f8'};
    border-bottom-left-radius: 120px;
    border-bottom-right-radius: 120px;
    height: 80px;
    width: 7.5rem;
    position: absolute;
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({ status }) =>
      status === 'not-start' ? 'center' : 'flex-end'};
    margin-bottom: 0.5rem;
    height: 7.5rem;
    img {
      position: absolute;
      left: 50%;
      top: 44%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      opacity: 0.8;

      .btn-start {
        color: #ffffff;
        background: #ff6701;
      }
    }
  }
  .progress {
    width: 100%;
    background: #ffffff;
    border: 1px solid #ff6701;
    border-radius: 8px;
    height: 5px;
    .progress-bar {
      background: #ff6701;
    }
  }
  .btn-start {
    border: 1px solid #ff6701;
    color: #ff6701;
    background: #ffffff;
    border-radius: 36px;
    font-size: 0.75rem;
    min-width: 61px;
  }
  .btn-retry {
    border: none;
    background: transparent;
    color: #9d9d9d;
    font-size: 0.75rem;
    text-decoration: underline;
    &:hover {
      color: #000000;
    }
  }

  .title {
    width: max-content;
    font-family: 'Linotte';
    font-size: 0.75rem;
    color: #959595;
    display: block;
    position: absolute;
    left: ${({ i18n }) => (i18n === 'ba' ? '2.2rem' : '2.5rem')};
    top: -32px;
    img {
      margin-left: 0.8rem;
    }
    &:hover {
      cursor: context-menu;
    }
  }
  @media (max-width: ${({ theme }) => `${theme.rwd.tabletS}px`}) {
    height: 120px;
    width: 100px;
    .overlay {
      height: 120px;
      width: 100px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 140px;
    .bg,
    .overlay {
      width: 100%;
    }
    .title {
      display: none;
    }
  }
`;

export default Container;
