import styled from 'styled-components';
import { MISSION_PLUS_STYLES } from 'constants/missionPlus';

export const Header = styled.div`
  background-image: ${({ subject }) =>
    `url(${MISSION_PLUS_STYLES[subject].headerImg}), ${MISSION_PLUS_STYLES[subject].headerBgColor}`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 130px;
  padding: 0.5rem 1rem;
  font-family: 'Linotte';
  display: flex;
  justify-content: center;
  align-items: center;
  .header-title {
    margin-bottom: 0;
    color: #ffffff;
    text-shadow: 0 2px 2px
      ${({ subject }) => MISSION_PLUS_STYLES[subject].headerTextShadow};
    font-size: 1.375rem;
    font-weight: 700;
    br {
      display: none;
    }
    span {
      color: ${({ subject }) => MISSION_PLUS_STYLES[subject].headerSpanColor};
    }
  }

  .backButton-container {
    position: absolute;
    left: 2rem;
    top: 5rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    min-height: 92px;
    .backButton-container {
      left: 1rem;
      top: 4.5rem;
    }
    .header-title {
      font-size: 16px;
      text-align: center;
      br {
        display: block;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 1.5rem 6rem;
  display: flex;
  p {
    margin-bottom: 0;
  }
  @media (max-width: 1223px) {
    padding: 1.5rem 2rem;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 0.8rem 1rem;
  }
`;

export const TopicSection = styled.div`
  margin-right: 1.5rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;

export const StyledDropdownIcon = styled.div`
  color: #4a4a4a;
  padding: 4px 5px;
  border-radius: 4px;
  line-height: 1;
  border: 1px solid
    ${({ subject }) => MISSION_PLUS_STYLES[subject].dropDownBorderColor};
  background: ${({ subject }) => MISSION_PLUS_STYLES[subject].dropDownBgColor};
`;

export const SkillSection = styled.div`
  width: 100%;
  .top-section {
    display: flex;
  }
  .topic-title {
    font-size: 1.375rem;
    color: #000000;
    font-weight: 600;
    text-align: center;
    width: 80%;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    .top-section {
      justify-content: center;
      padding: 0px 20px;
      .legend-container {
        display: none;
      }
    }
    .topic-title {
      font-size: 22px;
    }
  }
`;
