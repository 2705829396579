import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { subjectIDs, SUBJECTS } from 'constants/products';

export const Page = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-sizing: inherit;
  background-color: #2b2424;
  color: #ffffff;
  flex-direction: column;
  display: flex;
`;

export const Header = styled.div`
  position: absolute;
  width: 100%;
  height: 46px;
  padding: 0 35px;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  display: flex;
  box-sizing: inherit;
`;

export const MenuWrapper = styled.ul`
  padding: 0.5em 0;
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  align-items: stretch;
  list-style-type: none;
  box-sizing: inherit;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

export const HeaderMenu = styled.li`
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  box-sizing: inherit;
  text-align: -webkit-match-parent;
  list-style-type: none;
  img {
    width: ${({ subjectId }) =>
      subjectIDs[subjectId] === SUBJECTS.ENGLISH ? '235px' : 'initial'};
  }
`;

export const Menu = styled.a`
  padding-left: 0;
  text-decoration: none;
  padding: ${({ subjectId }) =>
    subjectId === 1 ? '0 2em 0.3rem 1em' : '0 2em 0.1rem 1em'};
  margin-top: 10px;
  color: #ffffff;
  background-color: transparent;
  box-sizing: inherit;
  cursor: pointer;
  text-align: -webkit-match-parent;
  font-size: 14px;
  font-family: sans-serif;

  :hover {
    color: #14bcd7;
    text-decoration: none;
  }
`;

export const Content = styled.div`
  padding: 46px 35px 0;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  box-sizing: inherit;
`;

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  margin-right: 16px;
  overflow: hidden;
  flex: 1 1 auto;
  box-sizing: inherit;
`;

export const Sidebar = styled.div`
  width: 126px;
  padding-right: 16px;
  overflow-y: auto;
  flex: 0 0 auto;
  box-sizing: inherit;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const CarouselWrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: inherit;
  @media (min-width: 1367px) {
    padding: 0 80px;
  }
  @media (max-width: 1024px) {
    padding: 0px;
  }
`;

export const Carousel = styled(AwesomeSlider)`
  flex: 1 1 auto;
  overflow: hidden;
  box-sizing: inherit;
  display: block;
  height: 100%;
  .awssld__content {
    background-color: transparent;
    > div {
      flex: 1;
    }
  }
`;

export const ImageWrapper = styled.div`
  flex: 1 0 100%;
  box-sizing: inherit;
`;

export const Image = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border: 0;
  box-sizing: inherit;
`;

export const ArrowWrapper = styled.div`
  width: 56px;
`;

export const SidebarContent = styled.div`
  overflow-y: hidden;
  box-sizing: inherit;
  display: block;
  position: relative;
`;

export const SidebarItem = styled.a`
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
  background-color: transparent;
  box-sizing: inherit;
  color: -webkit-link;
  cursor: pointer;
`;

export const SidebarImage = styled.img`
  border: 0;
  box-sizing: inherit;
  cursor: pointer;
  height: 66.45px;
  width: 94px;
`;

export const SidebarText = styled.p`
  width: 94px;
  margin: 0;
  text-align: center;
  color: #ffffff;
  box-sizing: inherit;
  display: block;
  cursor: pointer;
  font-size: 14px;
  font-family: sans-serif;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 99;
`;
