import { ReactComponent as ChallangeIcon } from 'assets/img/dashboardv2/challenge-icon.svg';
import { ReactComponent as TraningIcon } from 'assets/img/dashboardv2/training-icon.svg';
import { ReactComponent as FunIcon } from 'assets/img/dashboardv2/fun-icon.svg';
import { ReactComponent as ClassIcon } from 'assets/img/dashboardv2/class-icon.svg';
import { ReactComponent as FriendIcon } from 'assets/img/dashboardv2/dashboard/friend-icon.svg';
// import { ReactComponent as ShopIcon } from 'assets/img/dashboardv2/shop-icon.svg';
// import { ReactComponent as CollectionIcon } from 'assets/img/dashboardv2/collection-icon.svg';
import MathKooClassBg from 'assets/img/kooclass/math-space-bg.png';
import ScienceKooClassBg from 'assets/img/kooclass/science-space-bg.png';
import EnglishKooClassBg from 'assets/img/kooclass/english-space-bg.svg';

// Level
import Lev10 from 'assets/img/dashboardv2/lev10.svg';
import Lev20 from 'assets/img/dashboardv2/lev20.svg';
import Lev30 from 'assets/img/dashboardv2/lev30.svg';
import Lev40 from 'assets/img/dashboardv2/lev40.svg';
import Lev50 from 'assets/img/dashboardv2/lev50.svg';
import Lev60 from 'assets/img/dashboardv2/lev60.svg';
import Lev70 from 'assets/img/dashboardv2/lev70.svg';
import Lev80 from 'assets/img/dashboardv2/lev80.svg';
import Lev99 from 'assets/img/dashboardv2/lev90.svg';
import Lev100 from 'assets/img/dashboardv2/lev100.svg';

import {
  MathDC,
  MathDCMobile,
  ScienceDC,
  ScienceDCMobile,
  EnglishDC,
  EnglishDCTablet,
  EnglishDCMobile,
  PC,
  PCTablet,
  EnglishPC,
  EnglishPCTablet,
  SuperHero,
  SuperHeroSmall,
  SuperHeroTablet,
  SuperHeroTabletSmall,
  EnglishSuperHero,
  EnglishSuperHeroTablet,
} from 'assets/img/dashboardv2/kooChallenge';

import {
  MathMission,
  MathMissionTablet,
  ScienceMission,
  ScienceMissionMobile,
  ScienceMissionPlusIcon,
  EnglishMission,
  EnglishMissionTablet,
  EnglishMissionMobile,
  EnglishMissionPlusIcon,
  KooQuiz,
  KooQuizTablet,
  EnglishKooQuiz,
  EnglishKooQuizTablet,
  Assignment,
  AssignmentTablet,
  EnglishAssignment,
  EnglishAssignmentTablet,
  EnglishAssignmentMobile,
} from 'assets/img/dashboardv2/kooTraining';

import {
  KokoMonster,
  KokoMonsterTablet,
  EnglishKokoMonster,
  EnglishKokoMonsterTablet,
  MathBrainGame,
  MathBrainGameTablet,
  ScienceBrainGame,
  ScienceBrainGameTablet,
  EnglishBrainGame,
  EnglishBrainGameTablet,
  MathStory,
  MathStoryTablet,
  ScienceStory,
  ScienceStoryTablet,
  EnglishStory,
  EnglishStoryTablet,
} from 'assets/img/dashboardv2/kooFun';

import {
  EnglishGrid,
  MathCP,
  ScienceCP,
  EnglishCP,
} from 'assets/img/dashboardv2/dashboard';
import ScienceCPIcon from 'assets/img/science-cp-icon.svg';
import MathCPIcon from 'assets/img/dailychallenge/enable-cp.svg';

export const sidePanelNavigation = [
  {
    id: 1,
    name: 'KooChallenge',
    icon: ChallangeIcon,
  },
  {
    id: 2,
    name: 'KooTraining',
    icon: TraningIcon,
  },
  {
    id: 3,
    name: 'KooClass',
    icon: ClassIcon,
  },
  {
    id: 4,
    name: 'KooFun',
    icon: FunIcon,
  },
];

export const subNavData = [
  {
    id: 1,
    name: 'Friends',
    icon: FriendIcon,
    link: '/friends',
  },
  // {
  //   id: 2,
  //   name: 'KooShop',
  //   icon: ShopIcon,
  //   link: '/friends',
  // },
  // {
  //   id: 3,
  //   name: 'Collection',
  //   icon: CollectionIcon,
  //   link: '/friends',
  // },
];

export const $colors = {
  dashboard: {
    math: {
      bg: '#3d3789',
      primary: '#6c67df',
      secondary: '#6563EA',
      sidePanelBottom: '#4A48B5',
      fourth: '#5754CE',
      level: '#4A48B5',
      textActive: '#ffffff',
      text: '#dfdfdf',
      iconActive: '#ffffff',
      icon: '#dfdfdf',
      arrow: '#FFCC00',
      hover: '#6563EA',
      sidePanelTop: '#6c67df',
      friendsLabelHover: '#5754CE',
      friendsIcon: '#6260E2',
      friendsIconHover: '#6260E2',
    },
    science: {
      bg: '#02684E',
      primary: '#00AF78',
      secondary: '#00C184',
      sidePanelBottom: '#009B76',
      fourth: '#00846B',
      level: '#009B76',
      textActive: '#ffffff',
      text: '#dfdfdf',
      iconActive: '#ffffff',
      icon: '#dfdfdf',
      arrow: '#FFCC00',
      hover: '#00C184',
      sidePanelTop: 'linear-gradient(to bottom, #44D144, #00AF78 10%)',
      friendsLabelHover: '#00846B',
      friendsIcon: '#00B98B',
      friendsIconHover: '#00B98B',
    },
    english: {
      bg: '#F7F1D1',
      primary: '#FFBD29',
      secondary: '#FFC646',
      sidePanelBottom: '#FCAD00',
      fourth: '#E29F00',
      level: '#E8A000',
      textActive: '#000000',
      text: '#000000',
      iconActive: '#000000',
      icon: '#FFFFFF',
      arrow: '#000000',
      hover: '#FFCD45',
      sidePanelTop: 'linear-gradient(to bottom, #FFCE00, #FFBD29 10%)',
      friendsLabelHover: '#FFCD45',
      friendsIcon: '#FFC646',
      friendsIconHover: '#FFC647',
    },
  },
};

/**
 *
 * @param {number} level - current user level
 *
 * @return corresponds to the level background image
 */
export const mappingLevelBgImage = (level) => {
  if (isNaN(level) || Number.isNaN(level)) return Lev10;
  let target = level;
  if (typeof level !== 'number') target = parseInt(level, 10);

  if (target < 11) return Lev10;
  if (target >= 11 && target < 21) return Lev20;
  if (target >= 21 && target < 31) return Lev30;
  if (target >= 31 && target < 41) return Lev40;
  if (target >= 41 && target < 51) return Lev50;
  if (target >= 51 && target < 61) return Lev60;
  if (target >= 61 && target < 71) return Lev70;
  if (target >= 71 && target < 81) return Lev80;
  if (target >= 81 && target < 100) return Lev99;
  return Lev100;
};

export const dailyChallengeCompletionStatusIDs = {
  0: 'not-started',
  1: 'in-progress',
  2: 'completed',
};

export const kooClassSubjectStyles = {
  math: {
    sidebarBgColour: '#4a48b5',
    mainBgColour: '#3d3789',
    bgImage: MathKooClassBg,
    topicListBg: '#0e1327',
    scrollBg: 'rgba(245, 250, 255, 0.19)',
    scrollText: '#ffffff',
  },
  science: {
    sidebarBgColour: '#009E78',
    mainBgColour: '#02664C',
    bgImage: ScienceKooClassBg,
    topicListBg: '#0e1327',
    scrollBg: 'rgba(245, 250, 255, 0.19)',
    scrollText: '#ffffff',
  },
  english: {
    sidebarBgColour: '#FCAD00',
    mainBgColour: `url(${EnglishGrid}), #F7F1D1`,
    bgImage: EnglishKooClassBg,
    topicListBg: '#462A18',
    scrollBg: '#ffffff',
    scrollText: '#404447',
  },
};

export const kooChallengeIcons = {
  math: {
    dc: MathDC,
    dcTablet: MathDCMobile,
    dcMobile: MathDCMobile,
    pc: PC,
    pcTablet: PCTablet,
    superhero: SuperHero,
    superheroSmall: SuperHeroSmall,
    superheroTablet: SuperHeroTablet,
    superheroTabletSmall: SuperHeroTabletSmall,
  },
  science: {
    dc: ScienceDC,
    dcTablet: ScienceDCMobile,
    dcMobile: ScienceDCMobile,
    pc: PC,
    pcTablet: PCTablet,
    superhero: SuperHero,
    superheroSmall: SuperHeroSmall,
    superheroTablet: SuperHeroTablet,
    superheroTabletSmall: SuperHeroTabletSmall,
  },
  english: {
    dc: EnglishDC,
    dcTablet: EnglishDCTablet,
    dcMobile: EnglishDCMobile,
    pc: EnglishPC,
    pcTablet: EnglishPCTablet,
    superhero: EnglishSuperHero,
    superheroSmall: EnglishSuperHeroTablet,
    superheroTablet: EnglishSuperHeroTablet,
    superheroTabletSmall: EnglishSuperHeroTablet,
  },
};

export const kooTrainingIcons = {
  math: {
    mission: MathMission,
    missionTablet: MathMissionTablet,
    missionMobile: MathMissionTablet,
    missionPlusIcon: null,
    kooQuiz: KooQuiz,
    kooQuizTablet: KooQuizTablet,
    assignment: Assignment,
    assignmentTablet: AssignmentTablet,
    assignmentMobile: Assignment,
  },
  science: {
    mission: ScienceMission,
    missionTablet: ScienceMissionMobile,
    missionMobile: ScienceMissionMobile,
    missionPlusIcon: ScienceMissionPlusIcon,
    kooQuiz: KooQuiz,
    kooQuizTablet: KooQuizTablet,
    assignment: Assignment,
    assignmentTablet: AssignmentTablet,
    assignmentMobile: Assignment,
  },
  english: {
    mission: EnglishMission,
    missionTablet: EnglishMissionTablet,
    missionMobile: EnglishMissionMobile,
    missionPlusIcon: EnglishMissionPlusIcon,
    kooQuiz: EnglishKooQuiz,
    kooQuizTablet: EnglishKooQuizTablet,
    assignment: EnglishAssignment,
    assignmentTablet: EnglishAssignmentTablet,
    assignmentMobile: EnglishAssignmentMobile,
  },
};

export const kooFunIcons = {
  math: {
    kokoMonster: KokoMonster,
    kokoMonsterTablet: KokoMonsterTablet,
    brainGame: MathBrainGame,
    brainGameTablet: MathBrainGameTablet,
    story: MathStory,
    storyTablet: MathStoryTablet,
  },
  science: {
    kokoMonster: KokoMonster,
    kokoMonsterTablet: KokoMonsterTablet,
    brainGame: ScienceBrainGame,
    brainGameTablet: ScienceBrainGameTablet,
    story: ScienceStory,
    storyTablet: ScienceStoryTablet,
  },
  english: {
    kokoMonster: EnglishKokoMonster,
    kokoMonsterTablet: EnglishKokoMonsterTablet,
    brainGame: EnglishBrainGame,
    brainGameTablet: EnglishBrainGameTablet,
    story: EnglishStory,
    storyTablet: EnglishStoryTablet,
  },
};

export const iconScale = {
  math: 0.95,
  science: 0.95,
  english: 1.03,
};

export const cpIcons = {
  math: MathCP,
  science: ScienceCP,
  english: EnglishCP,
};

export const cpIconsShadow = {
  math: {
    icon: MathCPIcon,
    width: '24px',
    dcCorrect: {
      width: '38px',
      height: '37px',
    },
  },
  science: {
    icon: ScienceCPIcon,
    width: '24px',
    dcCorrect: {
      width: '38px',
      height: '37px',
    },
  },
  english: {
    icon: EnglishCP,
    width: '18px',
    dcCorrect: {
      width: '30px',
      height: '30px',
    },
  },
};
