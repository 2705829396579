import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { fetchWrapper } from 'services/login';

import { fetchEventsProgress } from 'services/eventV2';

export const getEventsProgress = createAsyncThunk(
  'eventV2/getEventsProgress',
  async (id) => {
    const res = await fetchWrapper(fetchEventsProgress, id);
    return handleResponseV2(res);
  }
);

const initialState = {
  eventProgress: {
    data: null,
    loading: false,
    error: null,
  },
};

const eventV2Slice = createSlice({
  name: 'eventV2',
  initialState,
  reducers: {},
  extraReducers: {
    [getEventsProgress.pending]: (state) => {
      state.eventProgress.loading = true;
      state.eventProgress.error = null;
      state.eventProgress.data = null;
    },
    [getEventsProgress.fulfilled]: (state, action) => {
      state.eventProgress.loading = false;
      state.eventProgress.error = null;
      state.eventProgress.data = action.payload;
    },
    [getEventsProgress.rejected]: (state, action) => {
      state.eventProgress.loading = false;
      state.eventProgress.error = action.error.message;
      state.eventProgress.data = null;
    },
  },
});

const { reducer } = eventV2Slice;
export default reducer;
