import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import {
  superHeroChallengeType,
  superHeroChallengeStatusIDs,
  SuperHeroChallengeList,
} from 'constants/superHero';
import { SUBJECTS } from 'constants/products';

import { isSuperHeroChallengeOpen } from 'helpers/timezone';

import {
  getSuperHeroChallengeStatus,
  getSuperHeroChallengeDataWithoutImage,
} from 'store/dailyChallenge/dailyChallengeSlice';

import { ErrorModal } from 'components/Shared';
import SuperHeroCard from '../SuperHeroCard';
import ParentChallengeCard from '../ParentChallengeCard';

/**
 * @returns - will return challenge status
 *      0 - close
 *      1 - locked
 *      2 - completed
 *      3 - incomplete
 */

const parseSuperHeroChallengeStatus = (
  challengeType,
  superHeroChallengeStatusData,
  timezone
) => {
  if (!isSuperHeroChallengeOpen(timezone)) return 'close';

  if (challengeType === 'super-vision-challenge') {
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Supervision.Status
    ];
  }
  if (challengeType === 'super-speed-challenge') {
    return superHeroChallengeStatusIDs[
      superHeroChallengeStatusData.Superspeed.Status
    ];
  }
  return null;
};

const SuperHeroList = ({ t, timezone }) => {
  const dispatch = useDispatch();
  const { superHeroChallengeStatus, superHeroChallengeIsError } = useSelector(
    (state) => state.dailyChallenge
  );
  const { subject, currentProduct } = useSelector((state) => state.plan);

  const reloadSuperHeroChallengeData = () => {
    dispatch(getSuperHeroChallengeStatus());
  };

  const onReloadScore = () => dispatch(getSuperHeroChallengeDataWithoutImage());

  if (!isNil(currentProduct) && subject === SUBJECTS.ENGLISH.toLowerCase()) {
    return SuperHeroChallengeList.parentAppChallenge.map((data) => (
      <ParentChallengeCard data={data} key={data.id} />
    ));
  }

  if (
    !isNil(currentProduct) &&
    !superHeroChallengeIsError &&
    !isNil(superHeroChallengeStatus)
  ) {
    return SuperHeroChallengeList.challenge
      .filter((challenge) => superHeroChallengeType.includes(challenge.type))
      .map((item) => (
        <SuperHeroCard
          key={item.id}
          challengeStatus={parseSuperHeroChallengeStatus(
            item.type,
            superHeroChallengeStatus,
            timezone
          )}
          challengeName={t(
            `challengeList:${item.nameTranslation}`,
            `${item.name}`
          )}
          challengeDesc={item.desc}
          challengeType={item.type}
          challengeOpenImg={item.imgOpen}
          challengeCloseImg={item.imgClose}
          challengeLink={item.link}
          onReloadScore={onReloadScore}
        />
      ));
  }
  if (
    !isNil(superHeroChallengeIsError) &&
    superHeroChallengeIsError !== 'Unable to get super vision image'
  ) {
    return (
      <ErrorModal
        reloadAction={reloadSuperHeroChallengeData}
        errorMessage={superHeroChallengeIsError}
      />
    );
  }

  return null;
};

export default SuperHeroList;
