import styled from 'styled-components';
import { MISSION_PLUS_STYLES } from 'constants/missionPlus';

export const ContainerScience = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  p {
    font-size: 1.375rem;
    color: white;
    margin: 0;
    font-weight: bold;
    text-shadow: 0 2px 2px
      ${({ subject }) => MISSION_PLUS_STYLES[subject].headerTextShadow};
    .highlighted {
      color: ${({ subject }) => MISSION_PLUS_STYLES[subject].headerSpanColor};
    }
  }
  .skills-no {
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ subject }) => MISSION_PLUS_STYLES[subject].totalSkillTextColor};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    p {
      font-size: 16px;
      .carry-on {
        display: none;
      }
    }
  }
`;
export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  color: white;
  background: ${(props) => props.bgColour || '#6585e2'};
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    padding: 2px 10px;
    .totalSkill,
    .skills-no {
      display: none;
    }
  }
`;
