import styled from 'styled-components';

export const Content = styled.div`
  padding-top: 59px;
  background: #f5f5f5;
  min-height: 100vh;
`;
export const Page = styled.div`
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  visibility: visible;
`;
