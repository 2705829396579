export const assignmentTypeStyles = {
  KooQuizSci: {
    helperBg: 'rgba(0, 65, 78, 0.21)',
  },
  KooQuizEnglish: {
    helperBg: 'rgba(110, 78, 51, 0.87)',
  },
  KooQuizMaths: {
    helperBg: 'rgba(132, 153, 255, 0.21)',
  },
};
