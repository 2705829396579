import {
  DrawDiagrams,
  Makelist,
  WorkBackwards,
  LogicalReasoning,
  BeforeAfter,
  ActItOut,
  GuessCheck,
  MakeSuppositions,
  LookForPatterns,
  RestakeProblem,
  SolveProblem,
  SimplifyProblem,
} from './imgs';
// top-left section
export const representing = [
  {
    title: 'Make a list',
    img: Makelist,
    displayOrder: 11,
  },
  {
    title: 'Draw diagrams',
    img: DrawDiagrams,
    displayOrder: 12,
  },
];

// top-right section
export const goingThroughTheProcess = [
  {
    title: 'Work Ba​​ckwards',
    img: WorkBackwards,
    displayOrder: 1,
  },
  {
    title: 'Logical Reasoning',
    img: LogicalReasoning,
    displayOrder: 2,
  },
  {
    title: 'Before and After',
    img: BeforeAfter,
    displayOrder: 3,
  },
  {
    title: 'Act it Out',
    img: ActItOut,
    displayOrder: 4,
  },
];

// bottom-right section
export const makingCalculatedGuess = [
  {
    title: 'Guess and Check',
    img: GuessCheck,
    displayOrder: 10,
  },
  {
    title: 'Look for Patterns',
    img: LookForPatterns,
    displayOrder: 9,
  },
  {
    title: 'Make Suppositions',
    img: MakeSuppositions,
    displayOrder: 8,
  },
];

// bottom-left section
export const changingTheProblem = [
  {
    title: 'Restate the Problem',
    img: RestakeProblem,
    displayOrder: 5,
  },
  {
    title: 'Solve Part of the Problem',
    img: SolveProblem,
    displayOrder: 6,
  },
  {
    title: 'Form Equations',
    img: SimplifyProblem,
    displayOrder: 7,
  },
];
