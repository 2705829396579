import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import {
  getHodLeaderboard,
  getHodTop50Leaderboard,
} from 'store/leaderboard/hodLeaderboardSlice';
import formatChineseNumber from 'helpers/formatChineseNumber';

import { PRIMARY_LEVELS, LEADERBOARD_ID } from '../../constant';

import { ErrorModal, Spinner, DropdownMenu } from 'components/Shared';
import { Category, NoParticipants, HODList } from '../../components';
import { HeaderEnglish } from './components';
import { Container } from './HOD.styles';

const HODEnglish = () => {
  const { t, i18n } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();
  const { defaultLevel } = useSelector((state) => state.studentDetails);
  const { isLoading, error, data } = useSelector(
    (state) => state.hodV2.hodData
  );

  const [selectedLevel, setSelectedLevel] = useState(defaultLevel);
  const [activeCategory, setActiveCategory] = useState(2);

  const showLevels = (level) => {
    return `${t('leaderboard:tabs.primary', 'Primary')} ${formatChineseNumber({
      value: level,
      language: i18n.language,
    })}`;
  };

  const levelHandler = (value) => {
    setSelectedLevel(value);
  };

  const categoryHandler = (filter) => {
    setActiveCategory(filter);
    if (filter === 4) {
      setSelectedLevel(defaultLevel);
    }
  };

  const getScienceHodData = () => {
    dispatch(
      getHodLeaderboard({
        id: LEADERBOARD_ID.hod.english,
        category: activeCategory,
        level: selectedLevel,
      })
    );
  };

  const top50Handler = () => {
    dispatch(
      getHodTop50Leaderboard({
        id: LEADERBOARD_ID.hod.english,
        category: activeCategory,
        level: selectedLevel,
      })
    );
  };

  useEffect(() => {
    if (isNil(selectedLevel)) {
      setSelectedLevel(defaultLevel);
    }
  }, [defaultLevel, selectedLevel]);

  useEffect(() => {
    if (!isNil(selectedLevel)) {
      getScienceHodData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, dispatch, selectedLevel]);

  return (
    <Container>
      <HeaderEnglish />
      <Category
        top50Available
        availableCategory={['class', 'school', 'national']}
        active={activeCategory}
        categoryHandler={categoryHandler}
        top50Handler={top50Handler}
      >
        <DropdownMenu
          dataCy="dropdown-level"
          selectedValue={showLevels(selectedLevel)}
          values={PRIMARY_LEVELS}
          setValue={levelHandler}
          valueTemplate={showLevels}
          width="150px"
          className="drop-down"
          widthipad="170px"
        />
      </Category>
      {isLoading && <Spinner />}
      {!isLoading && !isEmpty(data) && isNil(error) && (
        <HODList data={data} category={activeCategory} />
      )}
      {!isLoading && isEmpty(data) && isNil(error) && <NoParticipants />}
      {!isLoading && !isNil(error) && (
        <ErrorModal errorMessage={error} reloadAction={getScienceHodData} />
      )}
    </Container>
  );
};

export default HODEnglish;
