import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStudentDetails } from 'services/dashboard';
import { setUserPreferences } from 'store/userPreferences/userPreferencesSlice';
import { fetchWrapper } from 'services/login';

// HELPERS
// Gets the default level id from the subscriptions
const getDefaultLevel = (subscriptions) => {
  if (subscriptions.length <= 0) return 1;
  const premiumPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 3 || plan.PlanId === 4
  );
  const plusPlanIndex = subscriptions.findIndex((plan) => plan.PlanId === 2);
  const schoolPlanIndex = subscriptions.findIndex(
    (plan) => plan.PlanId === 8 || plan.PlanId === 9 || plan.PlanId === 10
  );
  if (premiumPlanIndex !== -1) {
    return subscriptions[premiumPlanIndex].LevelId;
  }
  if (plusPlanIndex !== -1) {
    return subscriptions[plusPlanIndex].LevelId;
  }
  if (schoolPlanIndex !== -1) {
    return subscriptions[schoolPlanIndex].LevelId;
  }
  return subscriptions[0].LevelId;
};

const initialState = {
  studentDetails: null,
  gender: null,
  defaultLevel: null,
  country: 'Singapore',
  countryCode: 'SG',
  groupId: '',
  isLoading: true,
  error: null,
  hasScreenLoaded: false,
  hasSeenLoader: false,
  showPremiumSchoolToggleModal: false,
};

// ASYNC THUNKS
export const getStudentDetails = createAsyncThunk(
  'dashboard/getStudentDetails',
  async (_, { getState, dispatch }) => {
    try {
      const res = await fetchWrapper(fetchStudentDetails);
      dispatch(setUserPreferences(res.Result.UserPreferences));
      return {
        result: res,
        timezone: getState().login.timezone,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get student details failed');
    }
  }
);

const studentDetailsSlice = createSlice({
  name: 'studentDetails',
  initialState,
  reducers: {
    setStudentDetails: (state, action) => {
      state.studentDetails = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setHasScreenLoaded: (state, action) => {
      state.hasScreenLoaded = action.payload;
    },
    setHasSeenLoader: (state, action) => {
      state.hasSeenLoader = action.payload;
    },
    setShowPremiumSchoolToggleModal: (state, action) => {
      state.showPremiumSchoolToggleModal = action.payload;
    },

    setStudentStateFromProduct: (state, action) => {
      const { country, countryCode, groupId } = action.payload;
      return {
        ...state,
        country,
        countryCode,
        groupId,
      };
    },
  },
  extraReducers: {
    [getStudentDetails.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getStudentDetails.fulfilled]: (state, action) => {
      state.studentDetails = action.payload.result.Result;
      state.isLoading = false;
      state.defaultLevel = getDefaultLevel(
        action.payload.result.Result.Subscriptions
      );
      state.gender = action.payload.result.Result.Gender;
    },
    [getStudentDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = studentDetailsSlice;
export const {
  setStudentDetails,
  setGender,
  setHasSeenLoader,
  setHasScreenLoaded,
  setShowPremiumSchoolToggleModal,
  setStudentStateFromProduct,
} = actions;
export default reducer;
