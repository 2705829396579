import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { rwd } from 'Theme';

import { useHistory } from 'react-router-dom';

import { isNil } from 'ramda';
import accessFeature from 'helpers/accessFeature';
import { getDCCompletionStatusbySubjectID } from 'store/dashboard/kooChallengeSlice';
import { subjectNames } from 'constants/products';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  dailyChallengeCompletionStatusIDs,
  kooChallengeIcons,
} from 'constants/dashboardv2';

import {
  StyledContainer,
  StyledContent,
  PrimaryButton,
  ClickOverlay,
} from './DailyChallengeCard.styles';

// React Hooks
export const useIsSMCEnabled = () => {
  const { currentProduct } = useSelector((state) => state.plan);
  if (isNil(currentProduct) || isNil(currentProduct?.features)) return false;
  const smcFeatureConfig = currentProduct.features.find(
    (f) => f.feature.toLowerCase().trim() === 'smc'
  );
  return smcFeatureConfig?.parameters.IsEnabled === true;
};

const DailyChallengeContent = () => {
  const dispatch = useDispatch();
  const { subject } = useSelector((state) => state.plan);
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation(['dashboardCards']);

  const buttonText = isCompleted ? 'View' : 'Start';
  useEffect(() => {
    dispatch(getDCCompletionStatusbySubjectID(subjectNames[subject]))
      .then(unwrapResult)
      .then((promiseResult) => {
        if (promiseResult?.CompletionStatus) {
          setIsCompleted(
            dailyChallengeCompletionStatusIDs[
              promiseResult?.CompletionStatus
            ] === 'completed'
          );
        }
      });
  }, [dispatch, subject]);
  return (
    <StyledContent>
      <PrimaryButton>{t(`hero.${buttonText}`, buttonText)}</PrimaryButton>
    </StyledContent>
  );
};

const HeroImage = ({ subject }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc = kooChallengeIcons[subject].dc;
  if (isTablet) {
    imgSrc = kooChallengeIcons[subject].dcTablet;
  }
  if (isMobile) {
    imgSrc = kooChallengeIcons[subject].dcMobile;
  }

  return <img src={imgSrc} alt="" />;
};

const DailyChallengeCard = () => {
  const history = useHistory();

  const { subject, currentProduct } = useSelector((state) => state.plan);

  // Event Handlers

  const accessFeatureHandler = () => {
    const { url, error } = accessFeature({
      type: 'dailychallenge',
      subject,
      featuresConfig: currentProduct?.features,
    });
    if (!error) {
      history.push(url);
    }
  };

  return (
    <StyledContainer subject={subject}>
      <HeroImage subject={subject} />
      <ClickOverlay data-cy="card-link" onClick={accessFeatureHandler} />

      <DailyChallengeContent />
    </StyledContainer>
  );
};

export default DailyChallengeCard;
