import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { rwd } from 'Theme';
import { useMediaQuery } from 'react-responsive';

import Coins from 'assets/img/events/icon-coins.svg';
import NowOpen from 'assets/img/events/icon-now-open.svg';
import Note from 'assets/img/events/icon-note.svg';
import NoteResult from 'assets/img/events/icon-note-result.svg';
import TickIcon from 'assets/img/events/icon-tick.svg';
import CPIcon from 'assets/img/icon-challenge-point.svg';

import { Card, DetailsInfo, Button } from 'components/Shared';
import { Timer } from 'components/Assignment';

import {
  Container,
  LeftContent,
  Rewards,
  SubCardContainer,
  RightContent,
} from './EventsCard.styles';

const EventCard = ({
  id,
  start,
  totalQn,
  eventStatus,
  cp,
  title,
  submissionId,
  endTime,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(['eventList', 'common']);
  const isChineseLang = i18n.language === 'zh_CN' || i18n.language === 'zh_TW';
  const isTablet = useMediaQuery({ maxWidth: '1024px' });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  const getButtonFontSize = () => {
    // there are mediaqueries at Button component with calc font-size function. Button Component is a shared component so it is not changed. '19.6px' will yield 18px;
    // button mediaqueries with calc function only for tablet and mobile screen and desktop no calc. so 18px will still yield as 18px
    let size = '18px';
    if (isTablet) {
      size = '1rem';
    }
    if (isMobile) {
      size = '15.5px';
    }
    return size;
  };

  const cpRewards = () => {
    const rewardText = () => {
      switch (eventStatus) {
        case 'claimed':
          return `${cp} CPs`;
        case 'open':
          return t(
            'eventList:eventCard.upTo',
            {
              number: totalQn * 2,
            },
            `Up to ${totalQn * 2} CPs`
          );
        default:
          return `${totalQn * 2} ${t(
            'eventList:eventCard.cpFull',
            'CPs (Full Score)'
          )}`;
      }
    };
    return (
      <Rewards>
        <img src={CPIcon} alt="CPs" style={{ marginRight: '0.25rem' }} />
        {rewardText(eventStatus)}
      </Rewards>
    );
  };

  const redirectTo =
    eventStatus === 'expired'
      ? '/events/expired'
      : `/events/view-result/${submissionId}`;
  const viewBtn = (
    <Button
      dataCy="event-card"
      variant="secondary"
      fontSize={getButtonFontSize()}
      onClick={() => history.push(redirectTo)}
      width={isMobile ? '80%' : isTablet ? '100%' : '80%'}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      {isMobile
        ? t('common:view', 'View Details')
        : t('eventList:eventCard.viewDetails', 'View Details')}
    </Button>
  );

  const cardContent = () => {
    if (eventStatus === 'open') {
      return {
        icon: NowOpen,
        subtitle: t(
          'eventList:eventCard.challengeCloses',
          'Challenge closes at 10pm'
        ),
        rewards: cpRewards(),
        text: (
          <>
            <p className="grey-text">
              {t('eventList:eventCard.timeLeft', 'Time Left')}
            </p>
            <p className="orange-text">
              <Timer
                type="event-card"
                isCountdown
                isInvisible={false}
                endTime={endTime}
                useTimezone
              />
            </p>
          </>
        ),
        button: (
          <Button
            dataCy="event-card"
            width={isMobile ? '80%' : isTablet ? '100%' : '80%'}
            variant="primary"
            fontSize={getButtonFontSize()}
            onClick={() => history.push(`/events/start/${id}`)}
          >
            {isMobile
              ? t('common:start', 'Start')
              : t('eventList:eventCard.startNow', 'Start now')}
          </Button>
        ),
      };
    }
    if (eventStatus === 'submitted') {
      return {
        icon: Note,
        subtitle: t(
          'eventList:eventCard.resultRelease',
          'Result will be released on Monday'
        ),
        rewards: isMobile
          ? t('eventList:eventCard.waitToClaim', 'Waiting for claiming')
          : t('eventList:eventCard.pendingResults', 'Pending results'),
        text: (
          <>
            <p className="orange-text submit">
              {t('eventList:eventCard.submitted', 'Submitted')}
            </p>
            <p className="small-grey-text">
              {t(
                'eventList:eventCard.resultRelease',
                'Results will be released on Monday'
              )}
            </p>
          </>
        ),
      };
    }
    if (eventStatus === 'result') {
      return {
        icon: NoteResult,
        subtitle: 'Click to view result & claim your CPs before Fri',
        rewards: isMobile
          ? t('eventList:eventCard.waitToClaim', 'Waiting for claiming')
          : t(
              'eventList:eventCard.readyToClaim',
              'Ready to be claimed (between 6am to 10pm)'
            ),
        text: (
          <p className="small-grey-text">
            {t(
              'eventList:eventCard.clickResult',
              'Click the results & claim your CPs before Fri, 10pm'
            )}
          </p>
        ),
        button: viewBtn,
      };
    }
    if (eventStatus === 'claimed') {
      return {
        icon: Note,
        rewards: cpRewards(),
        button: viewBtn,
      };
    }
    if (eventStatus === 'expired') {
      return {
        icon: Note,
        rewards: t('eventList:eventCard.expired', 'Expired'),
        button: viewBtn,
      };
    }
    if (eventStatus === 'upcoming') {
      return {
        icon: Coins,
        subtitle: `${totalQn * 2} ${t(
          'eventList:eventCard.cpGrab',
          'CPs up for grabs'
        )}`,
        rewards: cpRewards(),
        text: (
          <>
            <p className="grey-text">
              {t('eventList:eventCard.nextChallenge', 'Next challenge')}
            </p>
            <p className="grey-text">
              {t('eventList:eventCard.startAt', 'Start at')}
            </p>
            <p className="orange-text">
              {moment(start).format('LT, DD/MM/YYYY')}
            </p>
          </>
        ),
        button: (
          <Button
            dataCy="event-card"
            variant="disable"
            fontSize={getButtonFontSize()}
            style={{ display: 'flex', justifyContent: 'center' }}
            width={isMobile ? '80%' : isTablet ? '100%' : '80%'}
          >
            {isMobile
              ? t('common:start', 'Start')
              : t('eventList:eventCard.startNow', 'Start now')}
          </Button>
        ),
      };
    }
    return {
      icon: Coins,
      subtitle: `${totalQn * 2} ${t(
        'eventList:eventCard.cpGrab',
        'CPs up for grabs'
      )}`,
      rewards: cpRewards(),
      button: viewBtn,
    };
  };

  const leftSide = () => {
    return (
      <LeftContent>
        <SubCardContainer type={eventStatus} isChinese={isChineseLang}>
          <div className="icon">
            {isMobile ? (
              <img src={Note} alt="icon" />
            ) : (
              <>
                <img src={cardContent(eventStatus).icon} alt="icon" />
                {eventStatus === 'claimed' && (
                  <>
                    <div className="points">
                      <div className="circle">{cp}</div>
                      <p>CP</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="text">
            <div className="title">
              {eventStatus === 'submitted' && !isMobile && (
                <img src={TickIcon} alt="tick" />
              )}
              {(eventStatus !== 'result' || isMobile) && (
                <>
                  <p className="title-top">
                    {t('eventList:eventCard.sunday', 'Sunday')}
                  </p>
                  <p className="title-bottom">
                    {t('eventList:eventCard.miniChallenge', 'Mini Challenge')}
                  </p>
                </>
              )}
            </div>
            {!isMobile && (
              <p className="sub-title">{cardContent(eventStatus).subtitle}</p>
            )}
          </div>
        </SubCardContainer>
        <div className="info-section">
          <div className="challenge-name">
            {t('eventList:eventCard.SMC', `${title}`)}
          </div>
          <div className="detail-one">
            <DetailsInfo
              direction="column"
              category="Opening Hours"
              info={t(
                'eventList:eventCard.everySunday',
                'Every Sunday, 6 am to 10 pm'
              )}
              fontSizeCategory={isMobile ? '12px' : '14px'}
              fontSizeInfo={isMobile ? '14px' : '16px'}
            />
          </div>
          {!isMobile && (
            <div className="detail-two">
              <DetailsInfo
                direction="column"
                category="Total Qns"
                info={totalQn}
                fontSizeCategory="14px"
                fontSizeInfo="16px"
              />
              <DetailsInfo
                dataCy="event-rewards"
                direction="column"
                category="Rewards"
                info={cardContent(eventStatus).rewards}
                fontSizeCategory="14px"
                fontSizeInfo="16px"
              />
            </div>
          )}
        </div>
      </LeftContent>
    );
  };

  const rightSide = () => {
    return (
      <RightContent isMobile={isMobile}>
        {isMobile && (
          <>
            <DetailsInfo
              direction="column"
              category="Total Qns"
              info={totalQn}
              fontSizeCategory="10px"
              fontSizeInfo="12px"
              isMobile
            />
            <DetailsInfo
              dataCy="event-rewards"
              direction="column"
              category="Rewards"
              info={cardContent(eventStatus).rewards}
              fontSizeCategory="10px"
              fontSizeInfo="12px"
              isMobile
            />
          </>
        )}
        <div className="text" data-cy="rightContent">
          {!isMobile && cardContent(eventStatus).text}
          {isMobile && eventStatus === 'submitted' && (
            <p className="orange-text submit">
              {t('eventList:eventCard.submitted', 'Submitted')}
            </p>
          )}
        </div>
        {eventStatus !== 'submitted' && (
          <div className="action-btn">{cardContent(eventStatus).button}</div>
        )}
      </RightContent>
    );
  };
  return (
    <Container>
      <Card
        rightWidth={isMobile ? '100%' : '20%'}
        leftWidth={isMobile ? '100%' : '80%'}
        infoSection={leftSide()}
        buttonSection={rightSide()}
      />
    </Container>
  );
};

export default EventCard;
