import styled, { css } from 'styled-components';
import ClosedSplash from 'assets/img/dashboardv2/closed.png';
import closedTablet from 'assets/img/dashboardv2/closedTablet.png';
import closedMobile from 'assets/img/dashboardv2/closedMobile.png';

import { $colors } from 'constants/dashboardv2';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  padding-top: 84px;
  padding-bottom: 48px;
  padding-right: calc(0.02307137707 * 100vw);
  padding-left: calc(0.08291276135 * 100vw);
  height: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 0;
    flex-direction: column-reverse;
    justify-content: start;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 100%;
    height: 100vh;
  }
`;

export const StyledContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    padding: 0;
    flex-direction: column-reverse;
    justify-content: start;
    width: 502px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    width: 90%;
    padding-top: 20px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${`url(${ClosedSplash})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #15151d;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-top: 2rem;
  z-index: 3;
  p {
    margin: 0;
  }
  .title {
    font-family: 'Grilled Cheese';
    font-size: 4.0625rem;
    color: #03b8ff;
    margin-top: 3rem;
  }
  .subtitle {
    font-size: 2.3125rem;
    font-weight: 700;
  }
  .desc {
    font-size: 0.875rem;
    color: #ffda0d;
    font-weight: 700;
    span {
      font-size: 1.4375rem;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    left: auto;
    right: 0;
    background-image: ${`url(${closedTablet})`};
    background-size: contain;
    background-position: top;
    padding-top: 180px;
    .title {
      font-size: 36px;
    }
    .subtitle {
      font-size: 24px;
    }
    .desc {
      span {
        font-size: 20px;
      }
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    background-image: ${`url(${closedMobile})`};
    width: 100%;
    padding-top: 100px;
    .title {
      font-size: 30px;
    }
  }
`;

export const MainSection = styled.div`
  width: 83%;
  display: flex;
  flex-direction: column;
  text-align: center;
  .hours-desc {
    margin-top: 28px;
    font-size: 1rem;
    font-weight: 600;
    color: ${({ subject }) => $colors.dashboard[subject].textActive};
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    .hours-desc {
      margin-top: 14px;
      margin-bottom: 30px;
    }
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    margin-top: 10px;
    .hours-desc {
      margin-top: 25px;
      font-size: 12px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    flex-direction: column;
  }
`;

export const StackedCardsContainer = styled.div`
  width: 44%;
  padding-top: ${(props) => props.topPadding};
  margin-left: 3%;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-left: 0px;
    padding: 0px 8px;
    margin-top: 3%;
  }
`;

export const RewardsSection = styled.div`
  width: 17%;
  padding-top: ${(props) => props.topPadding};
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
    width: auto;
    padding-top: auto;
    justify-content: end;
    padding-right: 16px;
  }

  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    justify-content: center;
    padding-right: 0px;
  }
`;
export const StyledVerticalContainer = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;

  ${({ isSMCEnabled }) => {
    if (isSMCEnabled) {
      return css`
        gap: calc((24 / 1387) * 100vw);
        width: 96.5%;
        margin: 1.9vw auto 0;
        @media (max-width: ${({ theme }) => `${theme.rwd.tablet}px`}) {
          flex-direction: column;
          margin: 0 auto;
          gap: 0;
          width: auto;
        }
      `;
    }
  }}
  @media (max-width: ${({ theme }) => `${theme.rwd.mobile}px`}) {
    display: none;
  }
`;
